import React, { Component } from "react";
import { PRODUCTION } from "../../utils/constants";
import { postErrorToServer } from "../../utils/errorHandlers";
import { getEnvironment } from "../../utils/misc";
import crashUIImage from "./assets/crash_ui_new.png";

import "./ErrorBoundaryStyles.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    postErrorToServer(error, errorInfo);

    if (getEnvironment() === PRODUCTION) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error_boundary_container">
          <img src={crashUIImage} alt="" />
          <div className="error__text__container">
            <div className="error__header">Something went wrong</div>
            <div className="error__paras__container">
              <p>
                Sorry Something went wrong, You can go back and try again . If
              </p>
              <p>problem persists you can contact our customer support.</p>
            </div>
          </div>
          <div className="error__buttons__container">
            <div>
              <div
                className="go__back__button"
                onClick={() => window.location.reload()}
              >
                GO BACK
              </div>
              <a href="/support" className="support__link">
                <div className="contact__support__button">CONTACT SUPPORT</div>
              </a>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
