import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SlidingTabsStyle.css";
import { Color } from "../../config/constants";

const componentNames = {
  slidingTabs: "SlidingTabs",
  slidingTabPane: "SlidingTabPane",
  slidingTabRightHeaderPan: "SlidingTabRightHeaderPan",
};

/**
 * SlidingTabs component
 * @augments {Component<Props, State>}
 */
export default class SlidingTabs extends Component {
  state = {
    activeKey: this.props.activeTabKey,
    indicatorPosition: 0,
    hoveredTabKey: null,
  };

  componentDidMount = () => {
    this.getIndicatorPosition();
  };

  getIndicatorPosition = () => {
    const activeTab = document.getElementsByClassName(
      "link__sliding__tab__active"
    )[0];
    if (!activeTab) {
      return null;
    }
    const left = activeTab.offsetLeft;
    this.setState({ indicatorPosition: left });
  };

  handleTabClick = (key) => {
    const { activeKey } = this.state;
    if (key === activeKey) {
      return;
    }
    this.setState({ activeKey: key }, () => this.getIndicatorPosition());
    this.props.onChange(key);
  };

  isValidChild = (child, componentName) => {
    if (!child || child.type.displayName !== componentName) {
      return false;
    }
    const { tabkey, tabname } = child.props;
    if (!tabkey || !tabname) {
      return false;
    }
    return true;
  };

  render() {
    const { activeKey, indicatorPosition, hoveredTabKey } = this.state;
    const {
      children,
      headerWithSeparator,
      activeTabTextColor,
      inActiveTabTextColor,
      tabHeaderBgColorOnHover,
      inactiveTabHeaderBgColor,
      showHeader,
      className,
    } = this.props;
    const separatorClass = headerWithSeparator ? "with__separator" : null;

    /**
     * when header is hidden then show separator (only top) on wrapper
     * as header is hidden so header separator will not render
     */
    const wrapperTopSeparatorClass = !showHeader
      ? "with__top__separator"
      : null;
    return (
      <div
        className={`link__sliding__tab__wrapper ${this.props.wrapperClassName} ${wrapperTopSeparatorClass}`}
      >
        {showHeader && (
          <div
            className={`link__sliding__tab__header__wrapper ${this.props.headerClassName} ${separatorClass} `}
          >
            <div className="link__sliding__tab__header">
              <div className="link__sliding__tab__lhs">
                {React.Children.map(children, (item) => {
                  if (!this.isValidChild(item, componentNames.slidingTabPane)) {
                    return;
                  }
                  const { tabkey, tabname } = item.props;
                  const isActiveTab = tabkey === activeKey;
                  const active = isActiveTab
                    ? "link__sliding__tab__active"
                    : "link__sliding__tab__inactive";
                  const textColor = isActiveTab
                    ? activeTabTextColor
                    : inActiveTabTextColor;
                  return (
                    <div
                      className={`link__sliding__tab ${active}`}
                      key={tabkey}
                      onClick={() => this.handleTabClick(tabkey)}
                      style={{
                        backgroundColor:
                          hoveredTabKey === tabkey && !isActiveTab
                            ? tabHeaderBgColorOnHover
                            : hoveredTabKey !== tabkey && !isActiveTab
                            ? inactiveTabHeaderBgColor
                            : null,
                      }}
                      onMouseEnter={() =>
                        !isActiveTab &&
                        this.setState({
                          hoveredTabKey: tabkey,
                        })
                      }
                      onMouseLeave={() =>
                        !isActiveTab &&
                        this.setState({
                          hoveredTabKey: null,
                        })
                      }
                    >
                      <p
                        className={`link__sliding__tab__name ${className}`}
                        style={{ color: textColor }}
                      >
                        {tabname}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div
                className="link__sliding__tab__indicator"
                style={{ left: indicatorPosition }}
              ></div>
              <div className="link__sliding__tab__rhs">
                {React.Children.map(children, (child) => {
                  if (
                    !child ||
                    child?.type?.displayName !== "SlidingTabRightHeaderPan"
                  ) {
                    return;
                  }
                  return <>{child}</>;
                })}
              </div>
            </div>
          </div>
        )}
        <div className="link__sliding__tab__body__wrapper">
          {React.Children.map(children, (item, index) => {
            if (!this.isValidChild(item, componentNames.slidingTabPane)) {
              return;
            }
            const { tabkey, tabname } = item.props;
            if (tabkey !== activeKey) {
              return;
            }
            return (
              <div className="link__sliding__tab__body" key={index}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

SlidingTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeTabKey: PropTypes.any.isRequired,
  headerWithSeparator: PropTypes.bool,
  activeTabTextColor: PropTypes.string,
  inActiveTabTextColor: PropTypes.string,
  tabHeaderBgColorOnHover: PropTypes.string,
  inactiveTabHeaderBgColor: PropTypes.string,
  showHeader: PropTypes.bool,
  wrapperClassName: PropTypes.string /** custom class name for wrapper */,
  headerClassName: PropTypes.string /** custom class name for header */,
};

SlidingTabs.defaultProps = {
  headerWithSeparator: true, // puts the top and bottom border to tab header
  activeTabTextColor: "#fff",
  inActiveTabTextColor: "#fff",
  tabHeaderBgColorOnHover: Color.lightBlue,
  inactiveTabHeaderBgColor: "var(--copy-link-container-bg-color)",
  showHeader: true,
  wrapperClassName: "",
  headerClassName: "",
};

export class SlidingTabRightHeaderPan extends Component {
  render() {
    return (
      <div className="sliding__tab__right__header__pan">
        {this.props.children}
      </div>
    );
  }
}

export class SlidingTabPane extends Component {
  render() {
    return (
      <div
        className="link__sliding__tabs__tab__pane"
        tabname={this.props.tabname}
        tabkey={this.props.tabkey}
      >
        {this.props.children}
      </div>
    );
  }
}

SlidingTabPane.propTypes = {
  tabname: PropTypes.string.isRequired,
  tabkey: PropTypes.any.isRequired,
};

SlidingTabs.displayName = componentNames.slidingTabs;
SlidingTabPane.displayName = componentNames.slidingTabPane;
SlidingTabRightHeaderPan.displayName = componentNames.slidingTabRightHeaderPan;
