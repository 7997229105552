import {
  SET_SOCKET,
  SET_SOCKET_CONNECTION,
  SET_SERVER_TIME,
  UPDATE_LIVE_CLASS_STATUS,
  REMOVE_CLASS,
  ADD_INVITED_USERS,
  REMOVE_INVITATION,
  REMOVE_INVITED_USERS,
  SET_CLICKED_TRANSFER,
  ADD_LIVECLASS_ENDED_EVENT_DATA,
  SET_LIVE_LECTURE,
  REMOVE_LIVE_LECTURE,
} from "../types";
import {
  liveEvents,
  USER_JOIN_ACCEPT,
  USER_JOIN_CLASS_ACCEPT,
  USER_JOIN_CLASS_REJECT,
  USER_JOIN_REJECT,
  REQUEST_MIC_OR_VIDEO,
  ACCEPT_MIC_OR_VIDEO_REQUEST,
  REJECT_MIC_OR_VIDEO_REQUEST,
  CANCEL_MIC_OR_VIDEO_REQUEST,
  REQUEST_SCREENSHARE,
  ACCEPT_SCREENSHARE_REQUEST,
  REJECT_SCREENSHARE_REQUEST,
  CANCEL_SCREENSHARE_REQUEST,
  TRANSFER_LIVECLASS,
  KICK_PARTICIPANT,
} from "../../utils/socketEvents";
import {
  approveStudentJoinRequest,
  rejectStudentJoinRequest,
} from "./classActions";
import { errorAlert } from "./utils";
import {
  addMicRequest,
  addScreenshareRequest,
  addVideoRequest,
  cleanupBeforeLeaving,
  removeMicRequest,
  removeRequest,
  removeScreenshareRequest,
  removeVideoRequest,
} from "./liveClassActions";
import { sendKickParticipant } from "./jitsiActions";
import { STUDENT } from "../../utils/constants";
import { screenNames } from "../../components/AppRouter/routes";
import { emit } from "../../utils/socketio";
import routerUtils from "../../components/AppRouter/routerUtils";

export const setSocketObject = (socket) => (dispatch) => {
  dispatch({ type: SET_SOCKET, payload: socket });
};

export const setSocketConnection = (socketConnection) => (dispatch) => {
  dispatch({ type: SET_SOCKET_CONNECTION, payload: socketConnection });
};

export const setServerTime = (serverTime) => (dispatch) => {
  dispatch({ type: SET_SERVER_TIME, payload: serverTime });
};

export const handleAcceptEntry =
  (classId, requestId) => async (dispatch, getState) => {
    const {
      liveClass: { requests },
      socket: { socket },
    } = getState();
    const request = requests[requestId];
    if (!request || request?.status !== "PENDING") return;

    const { type } = request;
    let event;
    if (type === liveEvents.userJoined) {
      event = USER_JOIN_ACCEPT;
    } else if (type === liveEvents.userClassJoin) {
      const success = await dispatch(
        approveStudentJoinRequest(classId, requestId, true)
      );
      if (!success) return;
      event = USER_JOIN_CLASS_ACCEPT;
    } else {
      return;
    }

    await emit(event, requestId);
    dispatch(removeRequest(requestId));
  };

export const handleRejectEntry =
  (classId, requestId) => async (dispatch, getState) => {
    const {
      liveClass: { requests },
      socket: { socket },
    } = getState();
    const request = requests[requestId];
    if (!request || request?.status !== "PENDING") return;

    const { type } = request;
    let event;
    if (type === liveEvents.userJoined) {
      event = USER_JOIN_REJECT;
    } else if (type === liveEvents.userClassJoin) {
      const success = await dispatch(
        rejectStudentJoinRequest(classId, requestId, true)
      );
      if (!success) return;
      event = USER_JOIN_CLASS_REJECT;
    } else {
      return;
    }

    await emit(event, requestId);
    dispatch(removeRequest(requestId));
  };

export const classIsLive = (classId, liveLecture) => (dispatch, getState) => {
  const {
    liveClass: { classId: liveClassId, lectureId: liveLectureId },
  } = getState();

  dispatch({
    type: UPDATE_LIVE_CLASS_STATUS,
    payload: { classId, live: true, liveLecture },
  });

  if (
    liveLecture &&
    liveClassId === classId &&
    liveLectureId === liveLecture.id
  ) {
    dispatch({
      type: SET_LIVE_LECTURE,
      payload: liveLecture,
    });
  }
};

export const liveClassEnded = (classId, lectureId) => (dispatch, getState) => {
  const {
    user: { role },
    klass: { classes },
  } = getState();

  const payload = { classId, live: false };

  if (role && role.toUpperCase() === STUDENT) {
    payload.joinedBefore = false;
  }

  dispatch({
    type: ADD_LIVECLASS_ENDED_EVENT_DATA,
    payload: { classId, lectureId },
  });

  dispatch({
    type: UPDATE_LIVE_CLASS_STATUS,
    payload,
  });

  dispatch({
    type: REMOVE_LIVE_LECTURE,
    payload: { classId },
  });
};

export const removeClass = (classId) => (dispatch, getState) => {
  const {
    klass: { currentClass },
  } = getState();

  const isBatchClassesScreen = routerUtils()
    .path(window.location.pathname)
    .matchesTo([screenNames.batchClasses]);

  /**
   * If this event is received while user is in Batch classes
   * screen, then do not reload the page.
   */
  if (!isBatchClassesScreen && currentClass === classId) {
    window.location.pathname = screenNames.class;
  }

  dispatch({
    type: REMOVE_CLASS,
    payload: { classId },
  });
};

export const addNewInvites = (invitations) => (dispatch) => {
  dispatch({ type: ADD_INVITED_USERS, payload: invitations });
};

export const removeInvitedUsers = (invitationIds) => (dispatch) => {
  dispatch({ type: REMOVE_INVITED_USERS, payload: invitationIds });
};

export const removeInvite = (invitationId) => (dispatch) => {
  dispatch({ type: REMOVE_INVITATION, payload: { invitationId } });
};

export const requestVideoOn =
  (userId, participantId) => (dispatch, getState) => {
    const {
      socket: { socket },
      liveClass: { lectureId },
      user: { id },
    } = getState();

    if (!socket || !userId) {
      return;
    }

    const message = {
      type: liveEvents.videoOnRequest,
      message: liveEvents.messages[liveEvents.videoOnRequest],
      is_chat: false,
      lecture_id: lectureId,
      status: "PENDING",
      user_id: userId,
      requesting_teacher_id: id,
    };

    socket.emit(REQUEST_MIC_OR_VIDEO, message, (success, response) => {
      if (!success) {
        console.log(response.message);
        // dispatch(errorAlert(response.message));
        return;
      }
    });
  };

export const requestMicOn = (userId, participantId) => (dispatch, getState) => {
  const {
    socket: { socket },
    liveClass: { lectureId },
    user: { id },
  } = getState();

  if (!socket || !userId) {
    return;
  }

  const message = {
    type: liveEvents.micOnRequest,
    message: liveEvents.messages[liveEvents.micOnRequest],
    is_chat: false,
    lecture_id: lectureId,
    status: "PENDING",
    user_id: userId,
    requesting_teacher_id: id,
  };

  socket.emit(REQUEST_MIC_OR_VIDEO, message, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

export const requestScreenshare =
  (userId, participantId) => (dispatch, getState) => {
    const {
      socket: { socket },
      liveClass: { lectureId },
      user: { id },
    } = getState();

    if (!socket || !userId) {
      return;
    }

    const message = {
      type: liveEvents.studentScreenshareRequest,
      message: liveEvents.messages[liveEvents.studentScreenshareRequest],
      is_chat: false,
      lecture_id: lectureId,
      status: "PENDING",
      user_id: userId,
      requesting_teacher_id: id,
    };

    socket.emit(REQUEST_SCREENSHARE, message, (success, response) => {
      if (!success) {
        console.log(response.message);
        // dispatch(errorAlert(response.message));
        return;
      }
    });
  };

export const acceptScreenshareRequest = (requestId) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket || !requestId) {
    return;
  }

  socket.emit(ACCEPT_SCREENSHARE_REQUEST, requestId, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

export const rejectScreenshareRequest = (requestId) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket || !requestId) {
    return;
  }

  socket.emit(REJECT_SCREENSHARE_REQUEST, requestId, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

export const cancelScreenshareRequest =
  (participantId) => (dispatch, getState) => {
    const {
      socket: { socket },
      liveClass: { screenshareRequestedParticipantIdList },
    } = getState();

    const request = screenshareRequestedParticipantIdList[participantId];

    if (!request) return;

    const requestId = request.id;

    if (!socket || !requestId) {
      return;
    }

    socket.emit(CANCEL_SCREENSHARE_REQUEST, requestId, (success, response) => {
      if (!success) {
        console.log(response.message);
        // dispatch(errorAlert(response.message));
        return;
      }
    });
  };

export const acceptMicOrVideoRequest = (requestId) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket || !requestId) {
    return;
  }

  socket.emit(ACCEPT_MIC_OR_VIDEO_REQUEST, requestId, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

export const rejectMicOrVideoRequest = (requestId) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket || !requestId) {
    return;
  }

  socket.emit(REJECT_MIC_OR_VIDEO_REQUEST, requestId, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

export const cancelMicOrVideoRequest =
  (participantId, isMic) => (dispatch, getState) => {
    const {
      socket: { socket },
      liveClass: {
        micRequestedParticipantIdList,
        videoRequestedParticipantIdList,
      },
    } = getState();

    const request = isMic
      ? micRequestedParticipantIdList[participantId]
      : videoRequestedParticipantIdList[participantId];

    if (!request) return;

    const requestId = request.id;

    if (!socket || !requestId) {
      return;
    }

    socket.emit(CANCEL_MIC_OR_VIDEO_REQUEST, requestId, (success, response) => {
      if (!success) {
        console.log(response.message);
        // dispatch(errorAlert(response.message));
        return;
      }
      if (isMic) dispatch(removeMicRequest(participantId));
      else dispatch(removeVideoRequest(participantId));
    });
  };

const setClickedTransfer = (value) => (dispatch) => {
  dispatch({ type: SET_CLICKED_TRANSFER, payload: value });
};

export const socketTransferToThisDevice = () => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { sessionId },
  } = getState();

  if (!socket) {
    return;
  }
  dispatch(setClickedTransfer(true));
  dispatch(cleanupBeforeLeaving());
  socket.emit(TRANSFER_LIVECLASS, sessionId, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};

const handleTranferLiveclass = (leaveLiveClass) => (dispatch, getState) => {
  const {
    socket: { clickedTransfer },
  } = getState();

  console.log({ clickedTransfer });
  if (clickedTransfer) {
    dispatch(setClickedTransfer(false));
  } else {
    leaveLiveClass();
  }
};

export const addSocketTransferListener =
  (leaveLiveClass) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }
    socket.on(TRANSFER_LIVECLASS, () =>
      dispatch(handleTranferLiveclass(leaveLiveClass))
    );
    // TODO: when should we off this listener?
  };

export const kickParticipant = (participantId) => (dispatch, getState) => {
  const {
    socket: { socket },
    jitsi: { participants },
    liveClass: { lectureId },
  } = getState();

  const userId = participants[participantId]?.userId;

  if (!socket || !participantId || !userId) {
    return;
  }

  const message = {
    type: liveEvents.kickedOut,
    message: liveEvents.messages[liveEvents.kickedOut],
    is_chat: false,
    lecture_id: lectureId,
    user_id: userId,
  };

  socket.emit(KICK_PARTICIPANT, message, (success, response) => {
    if (!success) {
      console.log(response.message);
      // dispatch(errorAlert(response.message));
      return;
    }
  });
};
