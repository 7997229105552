export const convertToMap = (items = [], key = "id") => {
  return items.reduce(
    (acc, obj) => Object.assign(acc, { [obj[key]]: obj }),
    {}
  );
};

export const filterObjects = (objects, key, value) => {
  return convertToMap(
    Object.values(objects).filter((obj) => obj[key] === value)
  );
};

export const excludeObjects = (objects, key, value) => {
  return convertToMap(
    Object.values(objects).filter((obj) => obj[key] !== value)
  );
};

export const filterPendingRequests = (requests) => {
  return filterObjects(requests, "status", "PENDING");
};
