import React, { Component } from "react";
import { connect } from "react-redux";
import { resetAlert } from "../../store/actions/utils";
import AlertPopup from "../Posts/AlertPopup";
import "./ContainerViewStyle.css";
import { isMobileBrowser } from "../../store/actions/utils";

class ContainerView extends Component {
  render() {
    const isMobile = isMobileBrowser();
    return (
      <div className={`containerView ${isMobile ? "" : "min-dimensions"}`}>
        {this.props.popupMessage && (
          <AlertPopup
            popupTitle={this.props.popupTitle}
            popupMessage={this.props.popupMessage}
            popupMessageExtra1={this.props.popupMessageExtra1}
            onCancel={this.props.resetPopup}
          />
        )}
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = ({ misc }) => ({
  popupTitle: misc.popupTitle,
  popupMessage: misc.popupMessage,
  popupMessageExtra1: misc.popupMessageExtra1,
});
const mapDispatchToProps = (dispatch) => ({
  resetPopup: () => dispatch(resetAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerView);
