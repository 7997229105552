import store from "../store";

export const getParticipantByUserId = (userId) => {
  const {
    jitsi: { participants },
  } = store.getState();
  return Object.values(participants).find(
    (participant) => participant.userId === userId
  );
};
