import { RESET_AUTH_DATA, SET_AUTH_DATA } from "../types";
import produce from "immer";

const initialState = {
  email: "",
  phoneNumber: "",
  setPassword: false,
  countryCode: "+91",
  password: "",
  confirmPassword: "",
  loginType: "",
  otp: "",
  loginPassword: "",
  isFirstOtpSent: false,
  role: "",
  hasSetPassword: false,
};

const authReducer = produce((state, action) => {
  switch (action.type) {
    case SET_AUTH_DATA: {
      const { key, value } = action.payload;
      state[key] = value;
      return;
    }
    case RESET_AUTH_DATA: {
      return initialState;
    }
  }
}, initialState);

export default authReducer;
