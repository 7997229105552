import React, { useState } from "react";
import ReactDOM from "react-dom";

import "./modalWrapper.css";
const modalNode = document.getElementById("modal-root");
export default ({ ModalContent, open, setOpen, overlayBackgroundColor }) => {
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <div
        className="overlay"
        onClick={() => setOpen()}
        style={{ backgroundColor: overlayBackgroundColor }}
      />
      <div className="modalWrapper">
        <ModalContent />
      </div>
    </>,
    modalNode
  );
};
