import React, { useRef, useState, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";
import { getInitials } from "../../utils/misc";
import "./AvatarStyle.css";
import "../StudentTeacherDpScreen/StudentTeacherDpScreenStyles.css";
import { filesKindType } from "../../utils/tinyUtils";
import { getFileExtensionFromMimeType } from "../FileSystemCards/utils";

const getFileExtension = (filename, kind) => {
  switch (kind) {
    case filesKindType.link:
      return "YOUTUBE";
    case filesKindType.file:
      const splitFileList = filename.split(".");
      if (splitFileList.length < 2) {
        return "FILE";
      }
      return splitFileList.pop().toUpperCase();
    default:
      break;
  }
};

const getColorOfFileExtension = (fileExtension) => {
  const colorMap = {
    PDF: "#E8523F",
    PNG: "#F8B849",
  };
  return colorMap[fileExtension.toUpperCase()] || "#1F998E";
};

export const InitialAvatar = ({ name }) => {
  const nameInitial = getInitials(name);
  return (
    <div className="initialAvatar_container">
      <div
        style={{
          fontSize: "8px",
          color: "#fff",
          fontFamily: "GibsonSemiBold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {nameInitial}
      </div>
    </div>
  );
};

export const InitialAvatarGeneral = ({
  name,
  width,
  height,
  borderRadius,
  fontSize = "12px",
  marginRight,
  backgroundColor = "#78849E",
  outerMargin,
  border = null,
}) => {
  const nameInitial = getInitials(name);
  const ref = useRef(null);
  const [fsize, setFsize] = useState(null);

  const onResize = useCallback((width, height) => {
    // on resize, set font size to 40 percent of height. Maximum font size can be 70px
    setFsize(Math.min(70, height * 0.4));
  }, []);

  useResizeDetector({ targetRef: ref, onResize });

  return (
    <div
      style={{
        width,
        height,
        borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor,
        margin: outerMargin ? outerMargin : `0 ${marginRight} 0 0`,
        minWidth: width,
        border,
      }}
    >
      <div
        ref={ref}
        className="initial__avatar__name"
        style={{
          fontSize: fsize,
          color: "#fff",
          fontFamily: "GibsonSemiBold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingTop: "2px",
        }}
      >
        {nameInitial}
      </div>
    </div>
  );
};

export const UserAvatarDP = ({
  profilePhotoUrl,
  name,
  width,
  borderRadius,
  fontSize = "12px",
  style = {},
  showDominant = false,
}) => {
  const [errorOnImageLoad, setErrorOnImageLoad] = React.useState(false);

  return (
    <div
      className="parent-view"
      style={{ paddingTop: width, width, minWidth: width, ...style }}
    >
      <div
        className="child-view"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!profilePhotoUrl || profilePhotoUrl === "" || errorOnImageLoad ? (
          <InitialAvatarGeneral
            name={name}
            height="100%"
            width="100%"
            borderRadius={borderRadius}
            border={showDominant ? "4px solid var(--green)" : null}
            fontSize={fontSize}
          />
        ) : (
          <img
            src={profilePhotoUrl}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: borderRadius,
              border: showDominant ? "4px solid var(--green)" : null,
              overflow: "hidden",
            }}
            alt="avatar"
            onError={() => setErrorOnImageLoad(true)}
          />
        )}
      </div>
    </div>
  );
};

export const FileExtensionAvatar = ({ filename, kind, fileMimeType = "" }) => {
  let fileExtension;
  if (fileMimeType) {
    fileExtension = getFileExtensionFromMimeType(fileMimeType);
  } else {
    fileExtension = getFileExtension(filename, kind);
  }

  return (
    <div
      style={{
        width: "38px",
        height: "38px",
        borderRadius: "10px",
        backgroundColor: getColorOfFileExtension(fileExtension),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontFamily: "PoppinsBold",
          color: "#FFFFFF",
          fontSize: "10px",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          textTransform: "uppercase",
        }}
      >
        {fileExtension}
      </div>
    </div>
  );
};
