import React, { Component } from "react";
import { UserAvatarDP } from "../../Avatar/Avatar";

export default class ImageOrInitial extends Component {
  render() {
    const {
      img,
      name,
      width = 40,
      borderRadius = 12,
      customStyle,
    } = this.props;
    return (
      <div
        style={{
          width,
          height: width,
          borderRadius,
          overflow: "hidden",
          ...customStyle,
        }}
      >
        <UserAvatarDP
          width="100%"
          name={name}
          profilePhotoUrl={img}
          borderRadius={0}
          fontSize="12px"
        />
      </div>
    );
  }
}
