import React from "react";
import { connect } from "react-redux";

import rightArrowIcon from "./assets/rightArrowIcon.svg";
import currentFolderCheckIcon from "./assets/currentFolderCheckIcon.svg";
import {
  getFolderChildElementList,
  setActiveFolderId,
  setTreeSelectedFolderId,
  setClassBrowserVisibility,
  setCurrentFolderId,
  setLibraryFileSystemIds,
} from "../../store/actions/fileSystemActions";
import {
  doesBelongToUser,
  filterUserViewableFilesAndFolder,
  getClassAndLectureOfElement,
  getClassObjByRootFolderId,
  getRootFolderInfo,
  isParentToCurrentFolder,
  isParentToCurrentTreeSelectedFolder,
  isTempFolder,
} from "../../utils/fileSystem";
import { screenNames } from "../AppRouter/routes";
import { setCurrentClass } from "../../store/actions/classActions";
import { filesKindType, filesStatus } from "../../utils/tinyUtils";
import { sortArrayByAlphabeticalOrder } from "../../store/actions/utils";

const CollapsibleElement = (props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => {
    const { treeSelectedFolderId, folderId, fileSystemData, userFolderId } =
      props;
    let expand = false;
    if (treeSelectedFolderId === null) {
      setIsExpanded(expand);
    } else {
      if (doesBelongToUser(props.folderId)) {
        expand =
          folderId === userFolderId ||
          folderId === treeSelectedFolderId ||
          isParentToCurrentTreeSelectedFolder(folderId);
      } else {
        const { classId } = getClassAndLectureOfElement(treeSelectedFolderId);
        expand =
          isParentToCurrentFolder(folderId) ||
          folderId === classId ||
          isParentToCurrentTreeSelectedFolder(folderId);
      }
    }
    setIsExpanded(expand);
  }, [props.treeSelectedFolderId]);

  const onExpand = async (setCurrent = true, isExpandOnly) => {
    setIsExpanded(!isExpanded);
    if (doesBelongToUser(props.folderId)) {
      const { lectureId, classId } = getClassAndLectureOfElement(
        props.folderId
      );
      if (setCurrent) {
        await props.setTreeSelectedFolderId(props.folderId);
      } else if (!isExpandOnly) {
        await props.setTreeSelectedFolderId(props.folderId, true);
        props.setDestinationData &&
          props.setDestinationData(props.folderId, classId);
      } else if (!setCurrent) {
        !isExpandOnly &&
          (await props.setTreeSelectedFolderId(props.currentFolderId, true));
        props.setDestinationData &&
          props.setDestinationData(props.currentFolderId, props.currentClassId);
      }
      await props.getFolderChildElementList(props.folderId, classId);
    } else {
      const { lectureId, classId } = getClassAndLectureOfElement(
        props.folderId
      );
      if (!!props.history) {
        if (props.role === "teacher") {
          !props.dontSetCurrentFolder &&
            (await props.history.push(screenNames.libraryShared));
        } else {
          !props.dontSetCurrentFolder &&
            (await props.history.push(screenNames.studentBagClassFiles));
        }
      }

      /**
       * setCurrent is for whether we want to set classId and lectureID
       */
      if (setCurrent) {
        props.setLibraryFileSystemIds(classId, lectureId);
        await props.setCurrentClass(classId);
        await props.setTreeSelectedFolderId(props.folderId);
        await props.setClassBrowserVisibility(false);
      } else if (!isExpandOnly) {
        await props.setTreeSelectedFolderId(props.folderId, true);
        props.setDestinationData &&
          props.setDestinationData(props.folderId, classId);
      } else if (!setCurrent) {
        await props.setTreeSelectedFolderId(props.currentFolderId, true);
        props.setDestinationData &&
          props.setDestinationData(props.currentFolderId, props.currentClassId);
      }
      await props.getFolderChildElementList(props.folderId, classId);
    }

    if (!!props.onFolderSelectCallback) {
      props.onFolderSelectCallback();
    }
  };

  /**
   * Do not remove below method, may require in future
   */

  // const scrollToSelected = () => {
  //   const element = document.getElementById(
  //     `element__${props.currentFolderId}`
  //   );
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //     });
  //   }
  // };

  // React.useEffect(() => {
  //   console.log("useEffect");
  //   scrollToSelected();
  // }, [props.currentFolderId]);

  return (
    <div
      style={{
        width: "100%",
      }}
      id={`element__${props.folderId}`}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            padding: 4,
            cursor: "pointer",
            marginRight: "4px",
            transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
          }}
          src={rightArrowIcon}
          alt="arrow-icon"
          onClick={() => {
            if (!isExpanded) onExpand(false, true);
            else setIsExpanded(!isExpanded);
          }}
        />
        <div
          style={{
            fontFamily: "GibsonRegular",
            fontSize: "12px",
            color: "#959DAD",
            cursor: "pointer",
          }}
          onClick={() => {
            /**
             * if showMoveAndCopyFilePopup is true then we dont want to set the classId and folderId
             */
            const shouldSetClassAndFolderId = !props.showMoveAndCopyFilePopup;
            onExpand(shouldSetClassAndFolderId);
          }}
        >
          {props.fileSystemData[props.folderId]?.name}
        </div>
        {props.folderId === props.treeSelectedFolderId && (
          <img
            style={{
              marginLeft: "8px",
            }}
            src={currentFolderCheckIcon}
            alt="current-folder-marker"
          />
        )}
      </div>
      {isExpanded && (
        <div
          style={{
            marginLeft: "16px",
          }}
        >
          {/* {props.fileSystemData[props.folderId].children &&
            props.fileSystemData[props.folderId].children
              .filter(
                (childElementId) =>
                  props.fileSystemData[childElementId].kind === "lg#folder" &&
                  !props.fileSystemData[childElementId].isTrashed &&
                  props.fileSystemData[childElementId].name.trim().length > 0 &&
                  !isTempFolder(props.fileSystemData[childElementId].name)
              )
              .map((childFolderId, index) => (
                <CollapsibleElement
                  {...{ ...props, key: index, folderId: childFolderId }}
                />
              ))} */}
          {props.fileSystemData[props.folderId].children &&
            sortArrayByAlphabeticalOrder(
              filterUserViewableFilesAndFolder(
                props.fileSystemData[props.folderId].children.map(
                  (elementId) => props.fileSystemData[elementId]
                )
              )
            )
              .filter(
                (childElement) => childElement.kind === filesKindType.folder
              )
              .map((childElement, index) => (
                <CollapsibleElement
                  {...{
                    ...props,
                    key: index,
                    folderId: childElement.id,
                    // element: props.fileSystemData[childElementId],
                  }}
                />
              ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ fileSystem, user, fileOptions, klass }) => ({
  fileSystemData: fileSystem.fileSystemData,
  currentFolderId: fileSystem.currentFolderId,
  treeSelectedFolderId: fileSystem.treeSelectedFolderId,
  currentFolderChildren: fileSystem.currentFolderChildren,
  role: user.role,
  userFolderId: user.folder_id,
  showMoveAndCopyFilePopup: fileOptions.showMoveAndCopyFilePopup,
  currentClassId: klass.currentClass,
});

const mapDispatchToProps = {
  setCurrentFolderId,
  setActiveFolderId,
  setTreeSelectedFolderId,
  setCurrentClass,
  setClassBrowserVisibility,
  getFolderChildElementList,
  setLibraryFileSystemIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleElement);
