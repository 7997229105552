import React, { Component } from "react";
import "./SuggestionTileStyle.css";
import ImageOrInitial from "../../Live-class-tabs/ImageOrInitial/ImageOrInitial";

export default class SuggestionTile extends Component {
  state = {
    isHovered: false,
  };
  render() {
    const { isHovered } = this.state;
    const { id, img, name, email, phone, countryCode, selected } = this.props;
    const emailName = email?.split("@")[0];
    return (
      <div
        className={
          isHovered || selected
            ? `suggestion__tile__container hovered noselect`
            : `suggestion__tile__container noselect`
        }
        onClick={() => this.props.handleClick(id)}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {/* <div className="suggestion__tile__radio__button"></div> */}
        <div className="details__container">
          <div className="suggestion__left__container">
            <div className="image__container">
              <ImageOrInitial
                img={img}
                name={name ? name : emailName}
                borderRadius="50%"
                width={this.props.imageWidth}
              />
            </div>
            <div className="name__and__email__container">
              <p
                className="name"
                style={{ fontSize: this.props.listfontsize ?? "14px" }}
              >
                {name ? name : email}
              </p>
              <p
                className="email"
                style={{ fontSize: this.props.listfontsize ?? "14px" }}
              >
                {email}
              </p>
            </div>
          </div>
          <div className="suggestion__right__container">
            <p className="phone">{phone ? `${countryCode}${phone}` : null}</p>
          </div>
        </div>
      </div>
    );
  }
}
