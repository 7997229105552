import { CLEAR_SECRET_DETAILS, SET_APP_MICROSERVICES } from "../types";

const initialState = {
  microservices: {
    apiUrl: "",
    rtcUrl: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_MICROSERVICES: {
      return {
        ...state,
        microservices: {
          ...state.microservices,
          ...action.payload,
        },
      };
    }
    case CLEAR_SECRET_DETAILS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
