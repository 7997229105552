import {
  TOGGLE_ON,
  TOGGLE_OFF,
  SELECT_OPTION,
  SET_WHITEBOARD_DIMENSION,
  SET_VIDEO_STATE,
  SET_CLICKED_SLIDE,
  SET_SHOW_SLIDES_POPUP,
  SET_SHOW_WHITEBOARD_SELECTOR_POPUP,
  SET_SLIDE_POPUP_FOR,
  SET_SLIDE_SHARE_STATE,
  SET_SHOW_TOOLBAR,
  SET_HOVER_SMARTBOARD,
  SET_SHOW_CLASS_CODE,
  SET_MAINSCREEN,
  RESET_SMARTBOARD,
  SET_HOVER_RHS_VIEW,
  SET_MEDIA_SHARE_STATE,
  SET_MAINSCREEN_SLIDE_INFO,
  SET_ALREADY_FETCHED_SLIDES_MAP,
  SET_BROADCAST,
  HIDE_YOUTUBE_IN_DOM,
  SET_YOUTUBE_INITIALIZED,
  SET_SHOW_LINK_CONNECT_POPUP,
  SET_IS_MIROBOARD,
  SET_MIRO_WHITEBOARD,
} from "../types";
import {
  SLIDE_SHARE_STATE,
  VIDEO_OPTIONS,
  OPTIONS,
  IMAGE_LOADING_STATUS,
} from "../../utils/constants";
import {
  muteMyVideo,
  resetMyVideoPosition,
  setMyBroadcastType,
  setMyMediaShareType,
  setMyVideoState,
  startDesktopSharing,
  stopDesktopSharing,
} from "./jitsiActions";
import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";

/**
 * Things to remember:
 * 1. Whenever smartboard.broadcast changes, jitsi's `setMyBroadcastType` needs to be called
 * 2. startDesktopSharing and stopDesktopSharing are async and will take time to finish
 * 3. If screenshare is turned off from any place other than smartboard control,
 *    it is handled in `ScreenShareButton` component using React.useEffect
 */

export const turnOn = () => (dispatch, getState) => {
  const { smartboard } = getState();

  dispatch({ type: TOGGLE_ON });

  dispatch(setMyBroadcastType(smartboard.selected));
};

export const turnOff = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_OFF });

  const { smartboard } = getState();
  dispatch(setMyBroadcastType(smartboard.broadcast));
};

export const setBroadcast = (option) => (dispatch, getState) => {
  dispatch(setMyBroadcastType(option));
  dispatch({ type: SET_BROADCAST, payload: option });
};

export const toggleScreenShare = () => (dispatch, getState) => {
  const {
    smartboard: { broadcast, selected, slideShareState },
  } = getState();

  /**
   * when screenshare is being broadcasted and in mainscreen, now at this state another click event
   * should stop the screen share
   */

  if (broadcast === OPTIONS.SCREENSHARE) {
    dispatch(stopDesktopSharing());
    // this condition will automatically call turn off in Files.js
  } else {
    dispatch(startDesktopSharing());
  }
};

export const handleLinkConnect = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LINK_CONNECT_POPUP,
    payload: show,
  });
};

export const toggleSelectedOption = () => async (dispatch, getState) => {
  const {
    smartboard: { broadcast, selected, slideShareState },
  } = getState();

  if (broadcast === OPTIONS.SCREENSHARE) {
    try {
      dispatch(stopDesktopSharing());
    } catch (e) {
      return;
    }
  }

  if (broadcast === selected) {
    dispatch(turnOff());
  } else {
    if (
      selected === OPTIONS.SLIDESHARE &&
      slideShareState !== SLIDE_SHARE_STATE.VIEWING
    )
      return;

    dispatch(turnOn());
  }
};

export const setMainscreen = (option) => (dispatch) => {
  dispatch({ type: SET_MAINSCREEN, payload: option });
};

export const selectOption = (option) => (dispatch, getState) => {
  const { smartboard } = getState();

  if (
    smartboard.videoState === VIDEO_OPTIONS.big &&
    [OPTIONS.SLIDESHARE, OPTIONS.WHITEBOARD].includes(smartboard[option])
  ) {
    dispatch(setVideoState(VIDEO_OPTIONS.small));
  }

  dispatch({ type: SELECT_OPTION, payload: { option } });

  if (
    smartboard[option] === OPTIONS.SLIDESHARE &&
    smartboard.slideShareState !== SLIDE_SHARE_STATE.VIEWING
  ) {
    dispatch(setMainscreen(OPTIONS.INSTRUCTIONS));
  }

  if (
    smartboard[option] === OPTIONS.SCREENSHARE &&
    smartboard.broadcast !== OPTIONS.SCREENSHARE
  ) {
    dispatch(startDesktopSharing());
    dispatch(setMainscreen(OPTIONS.INSTRUCTIONS));
  }
};

export const selectBottomOption = () => (dispatch) => {
  dispatch(selectOption("bottom"));
};

export const selectTopOption = () => (dispatch) => {
  dispatch(selectOption("top"));
};

export const setWhiteBoardDimensions =
  ({ whiteboardContainerHeight, whiteboardContainerWidth }) =>
  (dispatch) => {
    if (whiteboardContainerWidth <= 0 || whiteboardContainerHeight <= 0) {
      return;
    }
    dispatch({
      type: SET_WHITEBOARD_DIMENSION,
      payload: { whiteboardContainerHeight, whiteboardContainerWidth },
    });
  };

export const setVideoStateHandler = (value) => (dispatch) => {
  dispatch({ type: SET_VIDEO_STATE, payload: value });

  dispatch(setMyVideoState(value));

  if (value === VIDEO_OPTIONS.big) {
    dispatch(setShowToolbar(false));
  } else {
    dispatch(setShowToolbar(true));
  }
};

export const setVideoState = (value) => (dispatch) => {
  dispatch(muteMyVideo(value === VIDEO_OPTIONS.off));
  dispatch(setVideoStateHandler(value));
};

export const setMediaShareState = (value) => (dispatch) => {
  dispatch({
    type: SET_MEDIA_SHARE_STATE,
    payload: value,
  });
  dispatch(setMyMediaShareType(value));
};

export const setClickedSlide = (value) => (dispatch) => {
  dispatch({ type: SET_CLICKED_SLIDE, payload: value });
};

export const setShowSlidesPopup = (value) => (dispatch) => {
  dispatch({ type: SET_SHOW_SLIDES_POPUP, payload: value });
};

export const setShowWhiteboardSelectorPopup = (value) => (dispatch) => {
  dispatch({ type: SET_SHOW_WHITEBOARD_SELECTOR_POPUP, payload: value });
};

export const setSlidePopupFor = (value) => (dispatch) => {
  dispatch({ type: SET_SLIDE_POPUP_FOR, payload: value });
};

export const clickedNextSlide = (value) => (dispatch, getState) => {
  const {
    smartboard: { clickedSlide },
  } = getState();
  dispatch(setClickedSlide(clickedSlide + 1));
};

export const clickedPrevSlide = (value) => (dispatch, getState) => {
  const {
    smartboard: { clickedSlide },
  } = getState();
  dispatch(setClickedSlide(clickedSlide - 1));
};

export const setSlideShareState = (value) => (dispatch) => {
  dispatch({
    type: SET_SLIDE_SHARE_STATE,
    payload: value,
  });
};

export const setSlideShareStateToViewing = () => (dispatch, getState) => {
  const {
    smartboard: { slideShareState, selected },
    lecture: { imageLoadingStatus },
  } = getState();
  if (imageLoadingStatus === IMAGE_LOADING_STATUS.FAILED) {
    dispatch(setSlideShareState(SLIDE_SHARE_STATE.FILE_OR_FOLDER));
    return;
  }
  if (slideShareState === SLIDE_SHARE_STATE.LOADING)
    dispatch(setSlideShareState(SLIDE_SHARE_STATE.VIEWING));
  if (selected === OPTIONS.SLIDESHARE) {
    dispatch(setMainscreen(OPTIONS.SLIDESHARE));
  }
};

export const setShowToolbar = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_TOOLBAR,
    payload: show,
  });
};

export const setHoverSmartboard = (isHover) => (dispatch) => {
  dispatch({
    type: SET_HOVER_SMARTBOARD,
    payload: isHover,
  });
};

export const setHoverRHSView = (isHover) => (dispatch) => {
  dispatch({
    type: SET_HOVER_RHS_VIEW,
    payload: isHover,
  });
};

export const setShowClassCode = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_CLASS_CODE,
    payload: show,
  });
};

export const resetSmartboardControls = () => (dispatch) => {
  dispatch({ type: RESET_SMARTBOARD });
};

export const setBroadcastAsSelected = () => (dispatch, getState) => {
  const { smartboard } = getState();
  const { broadcast, top, bottom } = smartboard;

  if (top === broadcast) {
    dispatch(selectTopOption());
  } else if (bottom === broadcast) {
    dispatch(selectBottomOption());
  }
};

export const setMainscreenSlideInfo = (infoObj) => (dispatch) => {
  /**
   * infoObject = {
   * image:,
   * fitWidth:,
   * fitHeight:,
   * ...
   * }
   */
  // const newObject = {};
  // delete Object.assign(newObject, infoObj, { image: infoObj["file_location"] })[
  //   "file_location"
  // ];
  dispatch({
    type: SET_MAINSCREEN_SLIDE_INFO,
    payload: { ...infoObj },
  });
};

export const setAlreadyFetchedSlidesMap =
  (newFileSlidesData) => (dispatch, getState) => {
    const {
      fileSystem: { currentFileId },
    } = getState();
    dispatch({
      type: SET_ALREADY_FETCHED_SLIDES_MAP,
      payload: { fileId: currentFileId, slidesDataOfFile: newFileSlidesData },
    });
  };

export const setHideTeacherYoutube = (hide) => (dispatch, getState) => {
  dispatch({
    type: HIDE_YOUTUBE_IN_DOM,
    payload: hide,
  });
};

export const setYoutubeInitialized = (bool) => (dispatch, getState) => {
  dispatch({
    type: SET_YOUTUBE_INITIALIZED,
    payload: bool,
  });
};

export const setThirdPartyWhiteboard = (bool) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_MIROBOARD,
    payload: bool,
  });
};

export const setMiroWhiteboard = (whiteboard) => (dispatch, getState) => {
  dispatch({ type: SET_MIRO_WHITEBOARD, payload: whiteboard });
};

async function getTokenFromServer() {
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "server/generate/miro-jwt",
    false,
    {}
  );

  if (response.error) {
    return response?.message;
  }
  return response.response.jwt_token;
}

export const initMiroBoard =
  (windowRef, onCancel, onSuccess) => (dispatch, getState) => {
    window.miroBoardsPicker.open({
      clientId: "3458764548480773183",
      action: "access-link",
      allowCreateAnonymousBoards: true,
      windowRef: windowRef,
      getToken: () => getTokenFromServer(),
      success: (data) => {
        dispatch(setMiroWhiteboard(data));
        onSuccess();
      },
      error: (e) => {
        console.log("on error", e);
        onCancel();
      },
      cancel: () => {
        onCancel();
      },
    });
  };
