import { DEVELOPMENT, PRODUCTION } from "./constants";
import cloneDeep from "lodash.clonedeep";
import { getSocket } from "./socketio";
import { screenNames } from "../components/AppRouter/routes";
import { trackEvent, trackType } from "./Tracking";
import { replaceUrl } from "./stringUtils";
import * as NoSleep from "nosleep.js";
export const getInitials = (name, maxLen = 2) => {
  if (!name) {
    return "";
  }

  const initials = name
    .split(" ")
    .filter((item) => item && item.length > 0)
    .map((item) => item[0])
    .join("")
    .toUpperCase();

  return initials.slice(0, maxLen);
};

export const getShareText = (klass) => {
  return replaceUrl(klass?.share_class_text, window.location.origin);
};

export const getShareTextWithCodeLink = (klass) => {
  return `Join my class '${klass?.name}' by entering the code - '${klass?.code}'. \n\n or simply click on the link: ${klass?.dynamic_link}`;
};

export const handleSecondsToHms = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);
  let hour = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  let mint = m > 0 ? m + (m == 1 ? " minute, " : " min, ") : "";
  let sec = s > 0 ? s + (s == 1 ? " second" : " sec") : "";
  return hour + mint + sec;
};

export const getJoinedTeacherNames = (teachers, currentUserId) => {
  if (!teachers || !teachers.length) return "";

  if (currentUserId) {
    const currentUserIndex = teachers.findIndex(
      (teacher) => teacher.id === currentUserId
    );
    if (currentUserIndex !== -1) {
      const currentUser = teachers.splice(currentUserIndex, 1);
      teachers.unshift(currentUser[0]);
    }
    return teachers.length > 1
      ? teachers
          .slice(0, -1)
          .map((teacher) =>
            teacher.id === currentUserId ? "You" : teacher.name
          )
          .join(", ") +
          " and " +
          teachers.slice(-1)[0].name
      : teachers[0].id === currentUserId
      ? "You"
      : teachers[0].name;
  }

  return teachers.length > 1
    ? teachers
        .slice(0, -1)
        .map((teacher) => teacher.name)
        .join(", ") +
        " and " +
        teachers.slice(-1)[0].name
    : teachers[0].name;
};

export const deepClone = (obj) => {
  return cloneDeep(obj);
};

export const getEnvironment = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return DEVELOPMENT;
  } else {
    return PRODUCTION;
  }
};

export const getStudentsString = (numStudents = 0) => {
  if (numStudents === 1) {
    return `${numStudents} Student`;
  }

  return `${numStudents} Students`;
};

const isSocketConnectionAvailable = async () => {
  const socket = getSocket();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // check for connection till 45sec
  for (let i = 0; i < 90; i++) {
    if (socket && socket.connected) return true;
    await sleep(500);
  }

  return false;
};

export async function handleStudentLiveClass(
  isLive,
  classId,
  useStoredParams = false
) {
  if (useStoredParams) {
    isLive = this.handleStudentLiveClassParams.isLive;
    classId = this.handleStudentLiveClassParams.classId;
  } else {
    this.handleStudentLiveClassParams = { isLive, classId };
  }

  if (
    isLive &&
    this.props.isLiveClassActive &&
    classId === this.props.liveClassId
  ) {
    this.props.history.push(screenNames.studentLiveClass);
    return;
  }

  if (!useStoredParams && isLive) {
    this.setState({ loading: true });
    const joined = await this.props.alreadyJoinedLiveClass(classId);

    this.setState({ loading: false });
    if (joined) {
      this.setState({ showTransferPopup: true });
      return;
    }
  }

  if (isLive) {
    this.setLoading(true);
    const socketConnected = await isSocketConnectionAvailable();
    this.setLoading(false);
    if (!socketConnected) {
      // show popup to acknowledge socket connection error & to refresh page
      this.props.setConnectionErrorType("socket");
      return;
    }
    try {
      this.setLoading(true);
      const { success } = await this.props.joinLiveClassStudent(classId);
      if (!success) {
        this.props.getClassStartupDetails(classId);
        return;
      }
    } catch {
      return;
    } finally {
      this.setLoading(false);
    }
    await this.props.setCurrentClass(classId);
    this.props.setLiveClass(classId);
    if (window.location.pathname.includes(screenNames.studentLiveClass)) return; // When a student joins using dynamic link, he is already pushed to liveclass, no need to push again
    this.props.history.push(screenNames.studentLiveClass);
  }
}

export async function handleTeacherLiveButton(
  isLive,
  classId,
  tempClassDetails,
  useStoredParams = false
) {
  if (useStoredParams) {
    isLive = this.handleTeacherLiveButtonParams.isLive;
    classId = this.handleTeacherLiveButtonParams.classId;
    tempClassDetails = this.handleTeacherLiveButtonParams.tempClassDetails;
  }

  if (
    isLive &&
    this.props.isLiveClassActive &&
    classId === this.props.liveClassId
  ) {
    trackEvent(trackType.JOIN_NOW, { classId });
    this.props.history.push(screenNames.liveClass);
    return;
  }

  if (!useStoredParams) {
    this.handleTeacherLiveButtonParams = {
      isLive,
      classId,
      tempClassDetails,
    };
    if (isLive) {
      this.setState({ loading: true });
      const joined = await this.props.alreadyJoinedLiveClass(classId);
      this.setState({ loading: false });
      if (joined) {
        this.setState({ showTransferPopup: true });
        return;
      }
    }
  }

  this.setLoading(true);
  const socketConnected = await isSocketConnectionAvailable();
  if (!socketConnected) {
    // show popup to acknowledge socket connection error & to refresh page
    this.props.setConnectionErrorType("socket");
    this.setLoading(false); // stop loader so that user can interact with error popup
    return;
  }

  const tempId = "32412";
  if (classId !== tempId) {
    await this.props.setCurrentClass(classId);
    if (isLive) {
      trackEvent(trackType.START_NOW, { classId });
      await this.props.getClassStartupDetails(classId, true);
      this.props.setLiveClass(classId);
      this.props.history.push(screenNames.liveClass);
      this.setLoading(false);
    } else {
      await this.props.getClassStartupDetails(classId);
      const discoveryStatus = await this.props.classClicks();
      this.props.history.push({
        pathname: screenNames.planning,
        state: { discoveryStatus },
      });
      this.setLoading(false);
    }
    this.props.setShowClassNavigationState(true);
  } else if (tempClassDetails) {
    const { success, classId } = await this.props.createClass(
      tempClassDetails.batch,
      tempClassDetails.name,
      undefined,
      true
    );
    if (success) {
      await this.props.setCurrentClass(classId);
      await this.props.getClassStartupDetails(classId);
      this.setLoading(false);
      this.props.setLiveClass(classId);
      trackEvent(trackType.START_LIVE_CLASS_NOW, { classId });
      this.props.history.push(screenNames.liveClass);
    }
  }
}

export function cleanupMediaPlayerRef(mediaPlayerRef) {
  if (mediaPlayerRef) {
    // we are doing this so that the chromium limit of total videos/audios tags does not cause problems to us
    // for the remove to work, the element has to be wrapped in some div or something else or else it will throw removenode error.
    mediaPlayerRef.remove();
    mediaPlayerRef.srcObject = null;
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isOverlapping = (el1, el2) => {
  /** if element does not exist in DOM, it cant overlap */
  if (!el1 || !el2) return false;

  var rect1 = el1.getBoundingClientRect();
  var rect2 = el2.getBoundingClientRect();

  const isNotOverlapping =
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right;

  return !isNotOverlapping;
};

export const tableNameHoverInfo = (data) => {
  if (!data) return;
  return `Name: ${data.name}\nEmail: ${data.email}`;
};
export const noSleep = new NoSleep();

export const showRoundedNumber = (num, precision) => {
  if (num < 0) {
    return "NA";
  } else {
    var multiplier = Math.pow(10, precision || 0);
    return (Math.round(num * multiplier) / multiplier).toFixed(precision);
  }
};

export const convertIndexToLetter = (index) => {
  // convert index to letter in alphabet
  const letter = String.fromCharCode(index + "A".charCodeAt(0));

  return letter;
};

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "-500px";
  textArea.style.left = "-500";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Could not copy text", err);
  }

  document.body.removeChild(textArea);
};

export const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).catch(function (err) {
    console.error("Could not copy text: ", err);
  });
};

export const getQueryParams = (query = window.location.search) => {
  const url = new URLSearchParams(decodeURIComponent(query));
  return Array.from(url.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: url.get(val) }),
    {}
  );
};
