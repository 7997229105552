import produce from "immer";
import {
  SET_POST,
  SET_CLASS_POSTS,
  SET_CLASS_DRAFT_POSTS,
  SET_POST_REPLIES,
  UPDATE_POST_LIKE_REACTION,
  ADD_CLASS_POST,
  UPDATE_POST_STAR_REACTION,
  REMOVE_POST,
  REMOVE_DRAFT_POST,
  SET_QA_POSTS,
  ADD_QA_POST,
  SET_QA_DRAFT_POSTS,
  CLEAR_POSTS_DETAILS,
  UPDATE_CLASS_POSTS,
  UPDATE_CLASS_DRAFT_POSTS,
  USER_REORTED,
} from "../types";

const initialState = {
  lecturePosts: {}, // {lectureId: [postIds]}
  lectureDrafts: {}, // {lectureId: [draftIds]}
  classPosts: {}, // {classId: [postIds]}
  classDrafts: {}, // {classId: [draftIds]}
  posts: {}, // {postId: {postDetails}}
  replies: {}, // {postId: [replyIds]}
  drafts: {}, // {postId: {postDetails}}
};

export default produce((state = initialState, action) => {
  switch (action.type) {
    case SET_POST: {
      const post = action.payload;
      state.posts[post.id] = post;
      return;
    }
    case SET_CLASS_POSTS: {
      const { classId, posts } = action.payload;

      state.classPosts[classId] = [];
      posts.forEach((post) => {
        state.posts[post.id] = post;
        state.classPosts[classId].push(post.id);
      });

      return;
    }
    case UPDATE_CLASS_POSTS: {
      const { classId, posts } = action.payload;
      posts.forEach((post) => {
        state.posts[post.id] = post;
        state.classPosts[classId].push(post.id);
      });
      return;
    }
    case SET_CLASS_DRAFT_POSTS: {
      const { classId, drafts } = action.payload;
      state.classDrafts[classId] = [];
      drafts.forEach((draft) => {
        state.drafts[draft.id] = draft;
        state.classDrafts[classId].push(draft.id);
      });

      return;
    }
    case UPDATE_CLASS_DRAFT_POSTS: {
      const { classId, drafts } = action.payload;
      drafts.forEach((draft) => {
        state.drafts[draft.id] = draft;
        state.classDrafts[classId].push(draft.id);
      });
      return;
    }
    case SET_POST_REPLIES: {
      const { postId, replies } = action.payload;

      state.replies[postId] = [];
      replies.forEach((reply) => {
        state.posts[reply.id] = reply;
        state.replies[postId].push(reply.id);
      });

      return;
    }
    case ADD_CLASS_POST: {
      const { classId, post } = action.payload;
      let classPostMap;

      if (post.is_draft) {
        state.drafts[post.id] = post;
        classPostMap = state.classDrafts;
      } else {
        state.posts[post.id] = post;
        classPostMap = state.classPosts;
      }

      classPostMap[classId]
        ? classPostMap[classId].unshift(post.id)
        : (classPostMap[classId] = [post.id]);

      return;
    }
    case ADD_QA_POST: {
      const { lectureId, post } = action.payload;
      let lecturePostMap;

      if (post.is_draft) {
        state.drafts[post.id] = post;
        lecturePostMap = state.lectureDrafts;
      } else {
        state.posts[post.id] = post;
        lecturePostMap = state.lecturePosts;
      }

      lecturePostMap[lectureId]
        ? lecturePostMap[lectureId].unshift(post.id)
        : (lecturePostMap[lectureId] = [post.id]);

      return;
    }
    case SET_QA_POSTS: {
      const { lectureId, posts } = action.payload;

      state.lecturePosts[lectureId] = [];
      posts.forEach((post) => {
        state.posts[post.id] = post;
        state.lecturePosts[lectureId].push(post.id);
      });

      return;
    }
    case SET_QA_DRAFT_POSTS: {
      const { lectureId, drafts } = action.payload;

      state.lectureDrafts[lectureId] = [];
      drafts.forEach((post) => {
        state.drafts[post.id] = post;
        state.lectureDrafts[lectureId].push(post.id);
      });

      return;
    }
    case UPDATE_POST_LIKE_REACTION: {
      const { postId, liked, likesCount, likedTeacherIds, likedStudentIds } =
        action.payload;
      state.posts[postId].user_liked = liked;
      state.posts[postId].likes_count = likesCount;
      state.posts[postId].liked_by_teachers = likedTeacherIds;
      state.posts[postId].liked_by_students = likedStudentIds;
      return;
    }
    case UPDATE_POST_STAR_REACTION: {
      const { postId, starred } = action.payload;
      state.posts[postId].user_starred = starred;
      return;
    }
    case REMOVE_DRAFT_POST: {
      const { postId } = action.payload;
      delete state.drafts[postId];
      return;
    }
    case REMOVE_POST: {
      const { postId } = action.payload;
      delete state.posts[postId];
      return;
    }
    case USER_REORTED: {
      const { postId, report } = action.payload;
      state.posts[postId].user_reported = report;
      return;
    }
    case CLEAR_POSTS_DETAILS: {
      return {
        ...initialState,
      };
    }
    default:
      return;
  }
}, initialState);
