import React from "react";

const SoundLevelBars = ({ soundLevel }) => {
  const micBarsArray = [];
  const alpha = 100 / 20;
  for (let i = 1; i <= 20; i++) {
    micBarsArray.push(i * alpha);
  }

  return (
    <>
      {micBarsArray.map((i, idx) => (
        <div
          key={idx}
          style={{
            width: "6px",
            height: "33px",
            backgroundColor:
              soundLevel >= i
                ? idx <= 4
                  ? "#FF5E99"
                  : idx <= 9
                  ? "#FFB900"
                  : "#3AE13A"
                : "white",
            marginLeft: "7px",
            borderRadius: "10px",
          }}
        ></div>
      ))}
    </>
  );
};

export default SoundLevelBars;
