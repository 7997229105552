import produce from "immer";
import {
  SET_CURRENT_STUDENT_ASSIGNMENT,
  SET_CURRENT_STUDENT_ASSIGNMENT_FILE,
  SET_STUDENTS_CURSEWORK,
  SET_ASSIGNMENT_STUDENT_DETAILS,
  SET_LATEST_SUBMISSION_DETAILS,
  SET_LATEST_SUBMISSION_DETAILS_META,
  SET_SUBMISSION_FILES,
  SET_STUDENT_ASSIGNMENT_DATA,
  SET_STUDENT_QUIZ,
  SET_PRACTICE_AGGREGATE_METRIC,
} from "../types";

const initialState = {
  assignmentMetadata: {},
  assignments: {},
  quizzes: {},
  quizMetadata: {},
  topic: {},
  allStudentCurseWork: {},
  allStudentCurseMetaData: {},
  receivedMark: "",
  totalMark: "",
  currentAssignment: {},
  currentAssignmentFiles: [],
  assignmentStudentDetails: {},
  latestSubmissionDetails: {},
  studentSubmissionFiles: {},
  currentStudentMeta: {},
  paracticeQuiz: {},
  practice_aggregate_metric: {},
};

const studentAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT_ASSIGNMENT_DATA: {
      const { assignment_metadata, assignments } = action.payload;
      let assignment = assignments.reduce((acc, obj) => {
        obj["typeOf"] = "assignment";
        return Object.assign(acc, { [obj.id]: obj });
      }, {});
      return {
        ...state,
        assignments: assignment,
        assignmentMetadata: assignment_metadata,
      };
    }
    case SET_STUDENT_QUIZ: {
      const { quiz_metadata, quizzes } = action.payload;
      let quiz = {},
        practiceQuiz = {};
      // let quiz = quizzes.filter((item)=>{
      //   return item?.type=="GRADED"
      // }).reduce((acc, obj) => {
      //   obj["typeOf"] = "quiz";
      //   return Object.assign(acc, { [obj.id]: obj });
      // }, {});
      quizzes.forEach((item) => {
        item["typeOf"] = "quiz";
        if (item.type === "GRADED") {
          quiz[item.id] = item;
        } else if (item.type === "PRACTICE") {
          practiceQuiz[item.id] = item;
        }
      });
      return {
        ...state,
        quizzes: quiz,
        paracticeQuiz: practiceQuiz,
        quizMetadata: quiz_metadata,
      };
    }
    case SET_STUDENTS_CURSEWORK: {
      const {
        assignment_metadata,
        assignments,
        quiz_metadata,
        topics,
        received_marks,
        total_marks,
      } = action.payload;
      let obj = {};
      [...assignments, ...Object.values(state.quizzes)].forEach((element) => {
        if (element.topic_id) {
          if (obj[element.topic_id]) {
            obj[element.topic_id] = [...obj[element.topic_id], element];
          } else {
            obj[element.topic_id] = [element];
          }
        } else {
          if (obj[0]) {
            obj[0] = [...obj[0], element];
          } else {
            obj[0] = [element];
          }
        }
      });

      return {
        ...state,
        allStudentCurseMetaData: { ...assignment_metadata, ...quiz_metadata },
        topic: topics,
        allStudentCurseWork: obj,
        receivedMark: received_marks,
        totalMark: total_marks,
      };
    }

    case SET_CURRENT_STUDENT_ASSIGNMENT: {
      return {
        ...state,
        currentAssignment: action.payload,
      };
    }
    case SET_CURRENT_STUDENT_ASSIGNMENT_FILE: {
      return {
        ...state,
        currentAssignmentFiles: action.payload,
      };
    }
    case SET_ASSIGNMENT_STUDENT_DETAILS: {
      return {
        ...state,
        assignmentStudentDetails: action.payload,
      };
    }
    case SET_LATEST_SUBMISSION_DETAILS_META: {
      return {
        ...state,
        currentStudentMeta: action.payload,
      };
    }
    case SET_LATEST_SUBMISSION_DETAILS: {
      return {
        ...state,
        latestSubmissionDetails: action.payload,
      };
    }
    case SET_SUBMISSION_FILES: {
      return {
        ...state,
        studentSubmissionFiles: action.payload,
      };
    }
    case SET_PRACTICE_AGGREGATE_METRIC: {
      return {
        ...state,
        practice_aggregate_metric: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default studentAssignmentReducer;
