import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";
import { v4 as uuidv4 } from "uuid";
import {
  SET_LECTURE_STARTUP_DETAILS,
  ADD_LECTURE_FILE_LIST,
  SET_LECTURE_FILE_LIST,
  SET_SLIDE_IMAGE_LIST,
  SET_CURRENT_SLIDE_INDEX,
  SET_CURRENT_LECTURE,
  UPDATE_LECTURE,
  CLONE_LECTURE,
  SET_QA_POSTS,
  SET_LECTURE_CHATS,
  SET_CURRENT_LECTURE_OBJECT,
  SET_UPLOAD_LECTURE_DATA,
  SET_TEMPORARY_LECTURE_OBJECT,
  SET_SUBMISSIONS,
  ADD_LECTURE,
  ADD_LECTURE_CLASS,
  ADD_SLIDE_IMAGE_LIST,
  SET_IMAGE_LOADING_STATUS,
  SET_LECTURE_WHITEBOARD_ARCHIVE,
  SET_CALENDAR_DATE,
  SET_LECTURE_TEMP_STUDENTS,
  UPDATE_LECTURE_ATTENDANCE,
  SET_USERS_OF_LECTURE,
  SET_LIVE_CLASS_SELECTED_FILE_PARAMS,
  SET_ALL_LECTURES,
  SET_REFERENCE_FILES_UPLOAD_LECTURE,
} from "../types";
import {
  addMultipleUploadFiles,
  getElementDownloadUrl,
  getLibraryData,
  getLibraryStartup,
  renameElementInRedux,
  resetFSMultipleUploadedFiles,
  uploadFilesWithMultipleProgress,
} from "./fileSystemActions";
import {
  customAlert,
  errorAlert,
  getImageDimension,
  getSessionId,
  getWidthHeightFromAspectRatio,
  logoutProcedure,
} from "./utils";
import { filterFilesForReference } from "../../utils/fileSystem";
import {
  setOnlySlideboardActiveLayerId,
  setSlideboardActiveLayerId,
  setSlideboardLayerInitializeState,
  setWhiteboardActiveLayerId,
} from "./liveClassActions";
import {
  ATTENDANCE_CALCULATION_STATE_TYPES,
  IMAGE_LOADING_STATUS,
  MEDIA_SHARE_OPTIONS,
  OPTIONS,
} from "../../utils/constants";
import {
  setAlreadyFetchedSlidesMap,
  setMainscreen,
  setMainscreenSlideInfo,
} from "./smartboard";
import _ from "lodash";
import { sleep } from "../../utils/misc";

export const fetchLectureFiles = (lectureId) => async (dispatch, getState) => {
  const { user } = getState();
  const { sessionId } = user;
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "class/lecture/get_files",
    true,
    {
      lecture_id: lectureId,
    },
    sessionId,
    false
  );
  // Case when lecture files retrieval is failed
  if (!response.response) {
    return;
  }

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  const lectureFiles = filterFilesForReference(
    response.response.element.children
  );

  dispatch(setLectureFileList(lectureFiles));
};

export const setLectureFileList = (lectureFileList) => (dispatch) => {
  dispatch({
    type: SET_LECTURE_FILE_LIST,
    payload: lectureFileList,
  });
};

export const uploadLectureFiles =
  (lectureId, selectedFiles) => async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;

    const newFileList = selectedFiles.map((file) => {
      return {
        name: file.name,
        mime_type: file.type,
      };
    });

    dispatch({
      type: ADD_LECTURE_FILE_LIST,
      payload: newFileList,
    });

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "class/lecture/add_files",
      true,
      {
        files: selectedFiles,
        lecture_id: lectureId,
      },
      sessionId,
      true
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    fetchLectureFiles(lectureId)(dispatch, getState);
  };

export const addImageToSlideImageList =
  (fileId) => async (dispatch, getState) => {
    const {
      fileSystem: { fileSystemData },
      liveClass: { classId },
      smartboard: {
        whiteboardContainerWidth,
        whiteboardContainerHeight,
        alreadyFetchedSlidesMap,
        mediaShareState,
        mainscreenSlideInfo,
      },
      lecture: { slideImageList, imageLoadingStatus },
    } = getState();

    /**
     * if pdf or any other file failed to load, and user tries image, then set
     * image loading status from FAILED to DONE. so that when image is ready to be shown,
     * it gets projected to mainscreen.
     * If status will remain failed then this is gonna be blocked by
     * @function setSlideShareStateToViewing action
     */
    if (imageLoadingStatus === IMAGE_LOADING_STATUS.FAILED) {
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.DONE));
    }

    // since there will be only one image, It wont have the "LOADING" state
    dispatch(setLiveClassSelectedFileParams({ id: fileId, loading: false }));

    const file = fileSystemData[fileId];
    if (alreadyFetchedSlidesMap[fileId]) {
      const slidesDataForClickedFile = alreadyFetchedSlidesMap[fileId];
      dispatch(setCurrentSlideIndex(0, false));
      if (mediaShareState !== MEDIA_SHARE_OPTIONS.SLIDES) {
        dispatch(setSlideboardActiveLayerId(slidesDataForClickedFile[0].id));
        dispatch(setMainscreenSlideInfo(slidesDataForClickedFile[0]));
      }
      await dispatch({
        type: SET_SLIDE_IMAGE_LIST,
        payload: slidesDataForClickedFile,
      });
      // STOP HERE
      return;
    }

    const imageUrl = await dispatch(getElementDownloadUrl(fileId, classId));

    const imageDimension = await getImageDimension(imageUrl);
    const fitImageDimension = getWidthHeightFromAspectRatio(
      whiteboardContainerWidth,
      whiteboardContainerHeight,
      imageDimension.width,
      imageDimension.height
    );

    /** this action handles individual image files in smartboard
     * hence slideboardlayer initialize state should be true so that paper can mount one layer for this image
     * and currentSlideIndex should be set to 0
     * and slideboardActiveLayerId should be set to image file id
     */
    const id = uuidv4();
    dispatch(setCurrentSlideIndex(0, false)); // set current slide index to 0 when different file is loading
    if (!mainscreenSlideInfo) {
      dispatch(setSlideboardActiveLayerId(id)); // set 0th index image id to slideboardactivelayerid
    }

    const slideInfo = {
      file_location: imageUrl,
      width: imageDimension.width,
      height: imageDimension.height,
      fitWidth: fitImageDimension.width,
      fitHeight: fitImageDimension.height,
      name: file.name,
      id,
      fileId,
    };

    await dispatch({
      type: SET_SLIDE_IMAGE_LIST,
      payload: [slideInfo],
    });

    /** store the new slide image, from next time if this file is clicked, stored data will be sued instead of
     * calculating it again
     */
    dispatch(setAlreadyFetchedSlidesMap([slideInfo]));

    if (
      !mainscreenSlideInfo ||
      (mainscreenSlideInfo && mediaShareState === MEDIA_SHARE_OPTIONS.YOUTUBE)
    ) {
      dispatch(setMainscreenSlideInfo(slideInfo));
      dispatch(setSlideboardActiveLayerId(id)); // set 0th index image id to slideboardactivelayerid
    }
    dispatch(setSlideboardLayerInitializeState(true));
  };

export const fetchImageHelper =
  (lectureId, classId, elementId, startIndex = 0, endIndex = 10) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    startIndex = Math.max(startIndex, 1);
    endIndex = startIndex + 10;

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/document/images",
      true,
      {
        lecture_id: lectureId,
        class_id: classId,
        element_id: elementId,
        start_page_number: startIndex,
        end_page_number: endIndex,
      },
      sessionId,
      false
    );
    return response;
  };

const fetchImageHelperMaster =
  (lectureId, classId, elementId, numFailedAttempt = 0) =>
  async (dispatch, getState) => {
    if (numFailedAttempt > 60) {
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.FAILED));
      dispatch(
        setLiveClassSelectedFileParams({ id: elementId, loading: false })
      );
      dispatch(errorAlert("Unable to load document"));
      return;
    }

    const {
      lecture: { slideImageList },
    } = getState();

    const numOfflineImages = slideImageList.length;

    const response = await dispatch(
      fetchImageHelper(lectureId, classId, elementId, numOfflineImages)
    );
    // Case when image files retrieval is failed
    if (!response.response) {
      dispatch(errorAlert(response.message));
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.FAILED));
      dispatch(
        setLiveClassSelectedFileParams({ id: undefined, loading: false })
      );
      dispatch(setLiveClassSelectedFileParams({}));
      return;
    }

    if (response.logout) {
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.FAILED));
      dispatch(
        setLiveClassSelectedFileParams({ id: undefined, loading: false })
      );
      dispatch(logoutProcedure(false));
      return;
    }

    if (response.error) {
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.FAILED));
      dispatch(
        setLiveClassSelectedFileParams({ id: undefined, loading: false })
      );
      dispatch(errorAlert(response.message));
      return;
    }

    const imageListNew = response.response.pages;
    const newNumPages = response.response.total_pages;

    const {
      lecture: {
        liveClassSelectedFileParams: { id: selectedFileId },
      },
    } = getState();

    if (selectedFileId !== elementId) {
      return;
    }

    if ((imageListNew?.length || 0) > 0) {
      await dispatch(addImageFiles(imageListNew, elementId));
      dispatch(setSlideboardLayerInitializeState(true));
    }

    const {
      lecture: { slideImageList: newSlideImageList },
    } = getState();

    const newNumOfflineImages = newSlideImageList.length;

    let newNumFailedAttempts = 0;
    if (numOfflineImages === newNumOfflineImages) {
      newNumFailedAttempts = numFailedAttempt + 1;
    }

    if (newNumOfflineImages === newNumPages) {
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.DONE));
      dispatch(
        setLiveClassSelectedFileParams({ id: elementId, loading: false })
      );
      dispatch(setAlreadyFetchedSlidesMap(newSlideImageList));
    } else if (newNumOfflineImages === 0) {
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(500);
      return await dispatch(
        fetchImageHelperMaster(
          lectureId,
          classId,
          elementId,
          newNumFailedAttempts
        )
      );
    } else {
      setTimeout(
        () =>
          dispatch(
            fetchImageHelperMaster(
              lectureId,
              classId,
              elementId,
              newNumFailedAttempts
            )
          ),
        5000
      );
    }
  };

export const fetchImageFiles =
  (lectureId, classId, elementId) => async (dispatch, getState) => {
    const {
      smartboard: { alreadyFetchedSlidesMap, mediaShareState },
    } = getState();
    dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.LOADING));

    /** loading:true because IMAGE_LOADING_STATUS is set to LOADING */
    dispatch(setLiveClassSelectedFileParams({ id: elementId, loading: true }));

    /**
     * If clicked file already has the slides fetched, use the already fetched one
     * so that if slides already have their corresponding layer attached to slideshare board
     * the layer and the drawings can be preserved throughout that live class lifecycle
     */
    if (alreadyFetchedSlidesMap[elementId]) {
      const slidesDataForClickedFile = alreadyFetchedSlidesMap[elementId];
      dispatch(setCurrentSlideIndex(0, false));
      if (mediaShareState !== MEDIA_SHARE_OPTIONS.SLIDES) {
        dispatch(setSlideboardActiveLayerId(slidesDataForClickedFile[0].id));
        dispatch(setMainscreenSlideInfo(slidesDataForClickedFile[0]));
      }
      await dispatch({
        type: SET_SLIDE_IMAGE_LIST,
        payload: slidesDataForClickedFile,
      });
      dispatch(setImageLoadingStatus(IMAGE_LOADING_STATUS.DONE));
      await dispatch(
        setLiveClassSelectedFileParams({ id: elementId, loading: false })
      );
      // STOP HERE
      return;
    }

    await dispatch(setImageFiles([]));

    return await dispatch(
      fetchImageHelperMaster(lectureId, classId, elementId)
    );
  };

export const setImageFiles = (imageList) => async (dispatch) => {
  let sizeList = imageList.map((imgUrl) =>
    getImageDimension(imgUrl.file_location)
  );
  sizeList = await Promise.all(sizeList);
  imageList.forEach((img, i) => {
    const { width, height } = sizeList[i];
    img.width = width;
    img.height = height;
  });
  await dispatch({
    type: SET_SLIDE_IMAGE_LIST,
    payload: imageList,
  });
};

const isPresent = (image, slideImageList) => {
  let i = 0;
  for (i = 0; i < slideImageList.length; i++) {
    if (image.name === slideImageList[i].name) return true;
  }
  return false;
};

const addImageFileHelper = (image, fileId) => async (dispatch, getState) => {
  const metadata = image.element.metadata;
  const { width, height } = metadata;
  const {
    lecture: { slideImageList },
    smartboard: {
      whiteboardContainerWidth,
      whiteboardContainerHeight,
      mainscreenSlideInfo,
      mediaShareState,
      mainscreen,
    },
  } = getState();
  const id = uuidv4();

  if (isPresent(image.element, slideImageList)) return;

  const imageDimension = getWidthHeightFromAspectRatio(
    whiteboardContainerWidth,
    whiteboardContainerHeight,
    width,
    height
  );

  const slideInfo = {
    file_location: image.presigned_url,
    width,
    height,
    fitWidth: imageDimension.width,
    fitHeight: imageDimension.height,
    name: image.element.name,
    id,
    fileId,
  };

  if (slideImageList.length === 0) {
    dispatch(setCurrentSlideIndex(0, false)); // set current slide index to 0 when different file is loading
  }
  await dispatch({
    type: ADD_SLIDE_IMAGE_LIST,
    payload: [slideInfo],
  });

  /**
   * if none of the slide is in the mainscreen then set this slide as initial slide to render in the mainscreen
   * if any pdf file is clicked while youtube is playing then also set the initial details of slides to render in the
   * mainscreen as it switches from youtube to slides
   */
  if (!mainscreenSlideInfo) {
    dispatch(setMainscreenSlideInfo(slideInfo));
    /**
     * if user switched to WHITEBOARD while slides image is extracting, then only set the slideboard layer id and not
     * paper layer id
     */
    if (mainscreen === OPTIONS.SLIDESHARE) {
      dispatch(setSlideboardActiveLayerId(id)); // set 0th index image id to slideboardactivelayerid
    } else {
      dispatch(setOnlySlideboardActiveLayerId(id));
    }
  } else if (
    mainscreenSlideInfo &&
    mediaShareState === MEDIA_SHARE_OPTIONS.YOUTUBE
  ) {
    let layerId;
    if (slideImageList.length === 0) {
      dispatch(setMainscreenSlideInfo(slideInfo));
      layerId = slideInfo.id;
    } else {
      dispatch(setMainscreenSlideInfo(slideImageList[0]));
      layerId = slideImageList[0].id;
    }
    /**
     * if user switched to WHITEBOARD while slides image is extracting, then only set the slideboard layer id and not
     * paper layer id
     */
    if (layerId) {
      if (mainscreen === OPTIONS.SLIDESHARE) {
        dispatch(setSlideboardActiveLayerId(layerId)); // set 0th index image id to slideboardactivelayerid
      } else {
        dispatch(setOnlySlideboardActiveLayerId(layerId));
      }
    }
  }
};

const addImageListHelper = (imageList, fileId) => async (dispatch) => {
  let i = 0;
  for (i = 0; i < imageList.length; i++) {
    await dispatch(addImageFileHelper(imageList[i], fileId));
  }
};

export const addImageFiles =
  (imageList, fileId) => async (dispatch, getState) => {
    const {
      lecture: { slideImageList },
    } = getState();

    const filteredImageList = imageList.filter(
      (image) => !isPresent(image.element, slideImageList)
    );

    if (filteredImageList.length > 0) {
      await dispatch(addImageListHelper(filteredImageList, fileId));
    }
  };

export const setCurrentSlideIndex =
  (slideIndex, shouldSetMainScreenSlideInfo = true) =>
  async (dispatch, getState) => {
    const {
      lecture: { slideImageList },
    } = getState();
    dispatch({
      type: SET_CURRENT_SLIDE_INDEX,
      payload: slideIndex,
    });

    /**
     * when user opens a file, and then opens another file
     * slideImageList gets updated and if user uses discoverable arrow buttons to switch it should start from 0th
     * index, at this we only need to set the index without changing the mainscreenSlideInfo.
     * for this task @param shouldSetMainScreenSlideInfo is needed.
     */

    if (shouldSetMainScreenSlideInfo) {
      dispatch(setMainscreenSlideInfo(slideImageList[slideIndex]));
    }
  };

export const setCurrentLecture = (lectureId) => async (dispatch, getState) => {
  // console.log(lectureId, "lectureId");
  dispatch({
    type: SET_CURRENT_LECTURE,
    payload: lectureId,
  });
  const {
    klass: { lectures },
  } = getState();
  const lectureObj = lectures[lectureId];
  dispatch(setCurrentLectureObject(lectureObj));
};

export const setCurrentLectureObject =
  (lectureObject = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_CURRENT_LECTURE_OBJECT,
      payload: lectureObject,
    });
  };

export const getLectureStartupDetails =
  (lectureId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "lectures/teacher/lecture/startup",
      true,
      { lecture_id: lectureId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    //console.log("getLectureStartUpDetailsData", data);
    dispatch({ type: SET_LECTURE_STARTUP_DETAILS, payload: data });
    dispatch({
      type: SET_QA_POSTS,
      payload: { lectureId, posts: data.qa_posts },
    });

    dispatch({
      type: SET_USERS_OF_LECTURE,
      payload: data.users_of_lecture,
    });
  };

export const getStudentLectureStartupDetails =
  (lectureId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    //console.log("getLectureStartupDetails");
    const response = await makeApiRequest(
      "GET",
      backendApi,
      "lectures/student/lecture/startup",
      true,
      { lecture_id: lectureId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    //console.log("getLectureStartUpDetailsData", data);
    dispatch({ type: SET_LECTURE_STARTUP_DETAILS, payload: data });
    dispatch({
      type: SET_QA_POSTS,
      payload: { lectureId, posts: data.qa_posts },
    });

    dispatch({
      type: SET_USERS_OF_LECTURE,
      payload: data.users_of_lecture,
    });
  };

export const getStudentAttendanceDetails =
  (studentId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass },
    } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "class/student/attendance",
      true,
      { class_id: currentClass, user_id: studentId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    return data;
  };

export const getStudentAttendanceLectureDetails =
  () => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass },
    } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "class/student/my-attendance",
      true,
      { class_id: currentClass },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    return data;
  };

export const updateLecture =
  (
    change_cancelled_to_scheduled,
    change_temp_to_scheduled,
    desc,
    lectureId,
    lecType,
    scheduleEnd,
    scheduleStart,
    topic
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/update",
      !!sessionId,
      {
        change_cancelled_to_scheduled: change_cancelled_to_scheduled ?? false,
        change_temp_to_scheduled: change_temp_to_scheduled,
        description: desc,
        lecture_id: lectureId,
        lecture_type: lecType,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        topic: topic,
      },
      sessionId
    );

    if (response.logout) {
      //console.log("UP LECT Logout");
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      // console.log("UP LECT Error");

      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    //console.log(data);
    dispatch({ type: UPDATE_LECTURE, payload: data });
    dispatch({ type: ADD_LECTURE_CLASS, payload: data });
    //Loader required before getting a session added popup
    await dispatch(getLibraryStartup());
    return data;
  };

export const createLectureGeneral = (props) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/create",
    true,
    props,
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  const data = response.response;

  // await dispatch(getLibraryData());

  return data.lecture;
};

export const updateLectureGeneral = (props) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/update",
    !!sessionId,
    props,
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return false;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }

  const data = response.response;
  dispatch({ type: UPDATE_LECTURE, payload: data });
  dispatch({ type: ADD_LECTURE_CLASS, payload: data });
  //Loader required before getting a session added popup
  // await dispatch(getLibraryData())
  if (props.lectureNameUpdate) {
    dispatch(
      renameElementInRedux({
        newName: data.lecture?.topic,
        elementId: data.lecture?.folder_id,
      })
    );
  }
  return true;
};

export const cloneLecture =
  (lecId, noWeek, topics) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/clone",
      true,
      {
        lecture_id: lecId,
        number_of_weeks: noWeek,
        topics: [...topics],
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    dispatch({ type: CLONE_LECTURE, payload: data });
    //Loader required after clicking Done in a session added popup
    return data;
  };

export const uploadFilesForUploadedLecture =
  (files, folderId, lectureVideo) => async (dispatch, getState) => {
    if (!Object.keys(files).length > 0) return [];

    dispatch(addMultipleUploadFiles(files));
    await dispatch(
      uploadFilesWithMultipleProgress(
        files,
        folderId,
        true,
        lectureVideo,
        false
      )
    );

    const {
      fileSystem: { multipleUploadedIdFileInfoMap },
    } = getState();
    const fileList = Object.values(multipleUploadedIdFileInfoMap).sort(
      (a, b) => b.id - a.id
    );
    // const elementIdList = fileList.map((element) => element.id);
    // await dispatch(moveFilesBulk(elementIdList, post.folder_id, true));
    dispatch(resetFSMultipleUploadedFiles());

    // Add attachments in offline mode
    return fileList;
  };

export const getLectureChat = (lectureId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
    lecture: { currentLecture },
  } = getState();

  if (!lectureId) {
    lectureId = currentLecture;
  }

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "lectures/lecture/chat",
    true,
    { lecture_id: lectureId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  const data = response.response;
  dispatch({ type: SET_LECTURE_CHATS, payload: data.chat_history });
};

export const setUploadLectureData = (uploadLectureData) => async (dispatch) => {
  dispatch({
    type: SET_UPLOAD_LECTURE_DATA,
    payload: uploadLectureData,
  });
};

export const setCalendarDate =
  (calendarActiveDateRange) => async (dispatch) => {
    dispatch({
      type: SET_CALENDAR_DATE,
      payload: calendarActiveDateRange,
    });
  };

export const setTemporaryLectureObject =
  (lectureObject) => async (dispatch) => {
    dispatch({
      type: SET_TEMPORARY_LECTURE_OBJECT,
      payload: lectureObject,
    });
  };

export const getLectureSubmissions =
  (lectureId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      lecture: { currentLecture },
    } = getState();

    if (!lectureId) {
      lectureId = currentLecture;
    }

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "lectures/lecture/submissions",
      true,
      { lecture_id: lectureId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = response.response;
    dispatch({ type: SET_SUBMISSIONS, payload: data.submissions });
  };

export const setLiveClassSelectedFileParams =
  ({ id = null, loading = false }) =>
  (dispatch) => {
    dispatch({
      type: SET_LIVE_CLASS_SELECTED_FILE_PARAMS,
      payload: { id, loading },
    });
  };

export const setImageLoadingStatus = (status) => async (dispatch) => {
  dispatch({
    type: SET_IMAGE_LOADING_STATUS,
    payload: status,
  });
};

export const rateTheSession =
  (lectureId, rating) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/feedback",
      true,
      { lecture_id: lectureId, score: rating },
      sessionId
    );

    if (response.error) {
      console.log(response.message);
    }
  };

export const deleteLecture = (lectureId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/delete",
    true,
    { lecture_id: lectureId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  return response;
};

export const deleteLectureVideo = (lectureId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/video/delete",
    true,
    { lecture_id: lectureId },
    sessionId
  );
  console.log(response, "Response of Lecture Video Delete");
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
};

export const getWhiteboardArchiveOfLecture =
  (lectureId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    console.log({ lectureId });

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "lectures/lecture/whiteboard",
      true,
      { lecture_id: lectureId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    // response.response.whiteboard can be null
    dispatch({
      type: SET_LECTURE_WHITEBOARD_ARCHIVE,
      payload: response.response.whiteboards,
    });

    console.log({
      whiteboardArchiveApiResponse: response.response.whiteboards,
    });
  };

export const getFilteredLectures =
  (classId, filter) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "lectures/filtered/list",
      true,
      {
        class_id: classId,
        filter_by: filter,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    return response.response.lecture_ids;
  };

export const getPolledLectureAttendanceDetails =
  (lectureId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    let response,
      currentTryCount = 0;
    const SLEEP_BETWEEN_POLLS = 1500; // ms

    // As API calls take time, this might take up around 3 minutes though we have given 2 minutes
    const MAX_TRIES = (2 * 60 * 1000) / SLEEP_BETWEEN_POLLS;
    let data;

    while (true) {
      currentTryCount += 1;
      response = await makeApiRequest(
        "GET",
        backendApi,
        "lectures/teacher/lecture/attendance",
        true,
        { lecture_id: lectureId },
        sessionId
      );

      if (response.logout) {
        dispatch(logoutProcedure(false));
      }

      if (response.error) {
        dispatch(errorAlert(response.message));
        return false;
      }

      data = response?.response;

      if (
        data.attendance_calculation_state !==
          ATTENDANCE_CALCULATION_STATE_TYPES.FINISHED &&
        currentTryCount <= MAX_TRIES
      ) {
        await sleep(SLEEP_BETWEEN_POLLS);
        continue;
      } else {
        break;
      }
    }

    if (
      data.attendance_calculation_state !==
      ATTENDANCE_CALCULATION_STATE_TYPES.FINISHED
    ) {
      dispatch(
        customAlert(
          "Info",
          "Attendance calculation is going on. Please check after some time."
        )
      );
      return false;
    }

    dispatch({
      type: UPDATE_LECTURE_ATTENDANCE,
      payload: data.attendance,
    });
    return true;
  };

export const getAllLectures = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { currentClass },
  } = getState();

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "lectures/all",
    true,
    {
      class_id: currentClass,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: SET_ALL_LECTURES,
    payload: response.response.lectures,
  });

  return response.response.lecture_attendance_details;
};

export const setReferenceFilesUploadLecture =
  (referenceFiles) => (dispatch) => {
    dispatch({
      type: SET_REFERENCE_FILES_UPLOAD_LECTURE,
      payload: referenceFiles,
    });
  };
