import produce from "immer";
import {
  SET_ADMIN_STARTUP_DETAILS,
  SET_ADMIN_DASHBOARD_DETAILS,
  SET_TOTAL_LICENSE,
  UPDATE_INSTITUTE_DETAIL,
  UPDATE_INSTITUTE_TEACHER,
  UPDATE_USER_SUBSCRIPTION_DETAILS,
  UPDATE_SUBSCRIPTION_DETAILS,
  UPDATE_INSTITUTE_REQUEST,
  UPDATE_REMOVE_INSTITUTE_INVITATION,
  REMOVE_ADMIN_PENDING_REQUEST,
  ADD_INSTITUTE_INVITATIONS,
  SET_INSTITUTE_TEACHER_BATCH_LIST,
  SET_CLASS_LECTURE_REPORT,
  RESET_CLASS_LECTURE_REPORT,
  SET_INSTITUTE_STUDENTS,
  SET_ADMIN_LECTURES,
  SET_ADMIN_STUDENT_DETAILS,
  SET_ADMIN_TEACHER_DATA,
  SET_ADMIN_PENDING_REQUEST,
  SET_ADMIN_CLASSES,
  SET_INSTITUTE_CLASSES_LIST,
  SET_INSTITUTE_CLASS_BATCHES_LIST,
  SET_ADMIN_PENDING_INVITES,
  SET_INSTITUTE_CLASSES_OBJECT,
} from "../types";

const initialState = {
  admin_lectures: {},
  teachers: [],
  pending_requests: [],
  classes: {},
  totalLicense: {},
  isAdmin: false,
  instituteClasses: [],
  instituteClassesList: {},
  classBatches: {},
};

const adminReducer = produce((state, action) => {
  switch (action.type) {
    case SET_ADMIN_STARTUP_DETAILS: {
      state = { ...state, ...action.payload };
      return state;
    }
    case SET_TOTAL_LICENSE: {
      let totalLic = {};
      for (let i of action.payload) {
        totalLic[i.name] = {
          total: i.available_count + i.consumed_count,
          exDate: i.latest_expiry,
          consumedCount: i.consumed_count,
          availableCount: i.available_count,
        };
      }
      state["totalLicense"] = totalLic;
      return state;
    }
    case SET_ADMIN_DASHBOARD_DETAILS: {
      state["dashboard"] = action.payload;
      return state;
    }
    case UPDATE_INSTITUTE_DETAIL: {
      state["institute"] = action.payload.institute;
      return state;
    }
    case UPDATE_INSTITUTE_TEACHER: {
      state["teachers"] = [...action.payload];
      return;
    }
    case UPDATE_SUBSCRIPTION_DETAILS: {
      state["institute_subscription_details"] =
        action.payload.institute_subscription_details;
      return;
    }
    case UPDATE_USER_SUBSCRIPTION_DETAILS: {
      const { user_subscription_details } = action.payload;
      let index = state["teachers"].findIndex(
        (ele) => ele["details"].id === user_subscription_details.user_id
      );
      state["teachers"][index]["subscription_details"] =
        user_subscription_details;
      return;
    }
    case UPDATE_INSTITUTE_REQUEST: {
      state["pending_requests"] = [
        ...state["pending_requests"],
        action.payload,
      ];
      return;
    }
    case SET_ADMIN_PENDING_REQUEST: {
      state.pending_requests = action.payload;
      return;
    }
    case SET_ADMIN_PENDING_INVITES: {
      state.pending_invitations = action.payload;
      return;
    }
    case UPDATE_REMOVE_INSTITUTE_INVITATION: {
      state["pending_invitations"] = state["pending_invitations"].filter(
        (item) => item.invite_id !== action.payload
      );
      return;
    }
    case ADD_INSTITUTE_INVITATIONS: {
      state["pending_invitations"] = [
        ...state["pending_invitations"],
        ...action.payload,
      ];
      return;
    }
    case SET_CLASS_LECTURE_REPORT: {
      const { classId, lectures } = action.payload;
      state["classLectureReport"] = {
        ...state["classLectureReport"],
        [classId]: lectures,
      };
      return;
    }
    case RESET_CLASS_LECTURE_REPORT: {
      state["classLectureReport"] = {};
      return;
    }
    case SET_INSTITUTE_STUDENTS: {
      state["students"] = action.payload;
      return;
    }
    case SET_INSTITUTE_TEACHER_BATCH_LIST: {
      state["instituteTeacherBatchList"] = action.payload;
      return;
    }
    case SET_ADMIN_LECTURES: {
      state["admin_lectures"] = action.payload;
      return;
    }
    case SET_ADMIN_STUDENT_DETAILS: {
      state["adminStudentDetails"] = action.payload;
      return;
    }
    case SET_ADMIN_TEACHER_DATA: {
      state.teachers = action.payload;
      return;
    }
    case SET_ADMIN_CLASSES: {
      state.classes = action.payload;
      return;
    }
    case SET_INSTITUTE_CLASSES_LIST: {
      state.instituteClasses = action.payload;
      return;
    }
    case SET_INSTITUTE_CLASSES_OBJECT: {
      state.instituteClassesList = action.payload;
      return;
    }
    case SET_INSTITUTE_CLASS_BATCHES_LIST: {
      state.classBatches = action.payload;
      return;
    }
    default:
      return state;
  }
}, initialState);

export default adminReducer;
