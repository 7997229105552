import { backendApi } from "../../config/constants";
import makeApiRequest from "../../utils/makeApiRequest";
import { SET_INSTITUTE_ADMINS_LIST } from "../types";
import { errorAlert, getSessionId, logoutProcedure } from "./utils";

export const setInstituteAdmins = (adminList) => (dispatch) => {
  dispatch({
    type: SET_INSTITUTE_ADMINS_LIST,
    payload: adminList,
  });
};
