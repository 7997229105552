export const TEACHER = "TEACHER";
export const TEMP_TEACHER = "TEMP_TEACHER";
export const STUDENT = "STUDENT";
export const TEMP_STUDENT = "TEMP_STUDENT";
export const RECORDING_BOT = "RECORDING_BOT";
export const WEB_BROWSER = "WEB_BROWSER";
export const MOBILE_APP = "MOBILE_APP";
export const CURRENT_DEVICE = WEB_BROWSER;
export const VIDEO_SHARED = "VIDEO_SHARED";
export const VIDEO_NOT_SHARED = "VIDEO_NOT_SHARED";
export const SHARED_SCREEN_CONTEXT = "SHARED_SCREEN_CONTEXT";
export const PERSONAL_SCREEN_CONTEXT = "PERSONAL_SCREEN_CONTEXT";
export const TRASH_SCREEN_CONTEXT = "TRASH_SCREEN_CONTEXT";
export const LECTURE_CONTEXT = "LECTURE_CONTEXT";
export const PAGINATION_CONTENT_SIZE = 24;
export const DESKTOP = "desktop";
export const CAMERA = "camera";
export const VIDEO = "video";
export const AUDIO = "audio";
export const PRODUCTION = "PRODUCTION";
export const DEVELOPMENT = "DEVELOPMENT";
export const LOCALHOST = "localhost";
export const MAX_CHARS_FOR_NAME_IN_TOAST = 28;
export const MAX_CHARS_FOR_NAME = 128;
export const MAX_CHARS_FOR_DESCRIPTION = 256;
export const BITRATE_CAPS_ENABLED =
  process.env.REACT_APP_BITRATE_CAPS_ENABLED === "true";

export const BACKSPACE = "Backspace";
export const SPACE = " ";
export const ATSIGN = "@";
export const DELETE = "Delete";
export const ENTER = "Enter";
export const EMPTYSTRING = "";
export const CONFLICT = "CONFLICT";

export const CLIENT_ERROR_CODE = "#0080109";
export const ignoreRedirectingSubdomains = [
  "dev-83012",
  "staging-83012",
  "uat-83012",
];

export const ATTENDANCE_CALCULATION_STATE_TYPES = {
  FINISHED: "finished",
  NOT_STARTED: "not_started",
};

export const POST_TYPES = {
  CLASS_POST: "class_post",
  LECTURE_POST: "lecture_post",
  LECTURE_QA: "lecture_qa",
};
export const PARTICIPANT_TYPES = {
  USER: "user",
  DESKTOP: "desktop",
  LINK_CONNECT: "linkConnect",
  // we can add recording bot type too here
};
export const VIDEO_TYPES = {
  VIDEO_NORMAL: "VIDEO_NORMAL",
  VIDEO_SMALL: "VIDEO_SMALL",
  VIDEO_OFF: "VIDEO_OFF",
};
export const WHITEBOARD_TYPES = {
  WHITEBOARD_ON: "WHITEBOARD_ON",
  WHITEBOARD_OFF: "WHITEBOARD_OFF",
};
export const DISCOVERY_TYPES = {
  rhs_preview_slide_arrow: "rhs_preview_slide_arrow",
  rhs_preview_whiteboard_arrow: "rhs_preview_whiteboard_arrow",
  presentation_slide_arrow: "presentation_slide_arrow",
  presentation_whiteboard_arrow: "presentation_whiteboard_arrow",
  whiteboard_drawer: "whiteboard_drawer",
  slide_share_drawer: "slide_share_drawer",
  whiteboard_preview_scroll_arrow: "whiteboard_preview_scroll_arrow",
  slide_preview_scroll_arrow: "slide_preview_scroll_arrow",
};

export const OTP_WAIT_TIME = 20;

export const IMAGE_LOADING_STATUS = {
  DONE: "DONE",
  FAILED: "FAILED",
  LOADING: "LOADING",
};

export const STUDENT_VIDEO_MODE = {
  COMPULSORY: "compulsory",
  OPTIONAL: "optional",
  OFF: "off",
};

export const OPTIONS = {
  SLIDESHARE: "SLIDESHARE",
  WHITEBOARD: "WHITEBOARD",
  SCREENSHARE: "SCREENSHARE",
  VIDEO_OR_DP: "VIDEO_OR_DP",
  INSTRUCTIONS: "INSTRUCTIONS",
};

export const VIDEO_OPTIONS = {
  small: "SMALL",
  big: "BIG",
  off: "OFF",
};

export const MEDIA_SHARE_OPTIONS = {
  SLIDES: "SLIDES",
  YOUTUBE: "YOUTUBE",
};

export const SLIDE_SHARE_STATE = {
  LOADING: "SLIDES_LOADING",
  VIEWING: "VIEWING",
  FILE_OR_FOLDER: "FILE_OR_FOLDER",
  FOLDER_TOP_LEVEL: "FOLDER_TOP_LEVEL",
  FOLDER_CLASS_LEVEL: "FOLDER_CLASS_LEVEL",
};

export const JOIN_CLASS_BUTTON_TYPE = {
  DISABLED: "DISABLED",
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
};

export const STUDENT_VIDEO_QUALITY_OPTIONS = {
  HIGH_QUALITY: "HIGH_QUALITY",
  BALANCED: "BALANCED",
  DATA_SAVER: "DATA_SAVER",
  AUTO: "AUTO",
};

export const STUDENT_VIDEO_QUALITY_VALUES = {
  HIGH_QUALITY: 720,
  BALANCED: 480,
  DATA_SAVER: 360,
  AUTO: 720,
};

export const VOLUME_BUTTON_TYPES = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  ZERO: "ZERO",
};

export const userDetailsTooltip = (user) => {
  if (user?.name && user?.email && user?.platform) {
    return `Name: ${user.name}\nEmail: ${user.email}\nPlatform: ${user.platform}`;
  } else if (user?.name && user?.email) {
    return `Name: ${user.name}\nEmail: ${user.email}`;
  } else if (user?.name && !user?.email) {
    return `Name: ${user.name}`;
  } else if (!user?.name && user?.email) {
    return `Email: ${user.email}`;
  } else {
    return null;
  }
};

export const LIVECLASS_SUPPORTED_FILES = [
  ".xlsx",
  ".xls",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".pdf",
  ".png",
  ".jpg",
  ".jpeg",
  ".bmp",
];

export const ONLY_IMAGES = [".png", ".jpg", ".jpeg", ".bmp"];

export const ALL_FILE_TYPES = "*/*";

export const LIVE_FEEDBACK_CONSTANTS = {
  DEFAULT_STUDENT_FEEDBACK_BOOLEAN: true,
  DEFAULT_ASK_AUTOMATICALLY_BOOLEAN: false,
  DEFAULT_NOTIFICATION_VALUE: 3.5,
  DEFAULT_FREQUENCY: 10,
};

export const OtpContexts = {
  LOGIN_OTP: "LOGIN_OTP",
  ACCOUNT_DELETE_OTP: "ACCOUNT_DELETE_OTP",
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
};

export const COURSEWORK_TYPES = {
  ASSIGNMENT: "Assignment",
  QUIZ: "Quiz",
  FILES: "Files",
  YOUTUBE: "Youtube Video",
  RECORDED_LECTURE: "Recorded Lecture",
  LIVE_LECTURE: "Live Lecture",
  FILE_PREVIEWER: "FilePreviewer",
};

export const COURSEWORK_STATUS = {
  SCHEDULED: "scheduled",
  PUBLISHED: "Published",
  CONDUCTED: "Conducted",
  NOT_CONDUCTED: "Not Conducted",
  CANCELLED: "cancelled",
  FINISHED: "finished",
};

export const COURSEWORK_STATE = {
  DRAFT: "DRAFT",
};

export const requestType = {
  handRaised: "hand_raised",
  userJoined: "user_joined",
  userClassJoined: "user_class_join",
};

export const MODULES = {
  RESOURCES: "RESOURCES",
  LIVECLASS: "LIVECLASS",
};

export const STAGE_ENVIRONMENT = {
  "https://backend.uat-83012.linkclassroom.com":
    "https://uat-83012.linkclassroom.com",
  "https://backend.prod-new.linkclassroom.com": "https://linkclassroom.com",
};
