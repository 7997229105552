import React from "react";

import { ReactComponent as Loader } from "./assets/loader.svg";

const LoaderWrapper = ({ loading, ...props }) => {
  const width = 60,
    height = 60;
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "fixed",
          display: loading ? "flex" : "none",
          zIndex: "var(--zindex-loader)",
        }}
      >
        <Loader width={width} height={height} />
      </div>
      {props.children}
    </div>
  );
};

export default LoaderWrapper;
