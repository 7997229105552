import _, { isNil } from "lodash";
import { backendApi } from "../../config/constants";
import { MODULES } from "../../utils/constants";
import makeApiRequest from "../../utils/makeApiRequest";
import {
  LIVE_QUICKQUESTION_CLOSE,
  LIVE_QUICKQUESTION_END,
  LIVE_QUICKQUESTION_PUBLISH_RESULT,
  LIVE_QUICKQUESTION_START,
  LIVE_QUICKQUESTION_STUDENT_SUBMIT_RESPONSE,
  LIVE_QUICKQUESTION_TEACHER_RESPONSE_REFRESH,
} from "../../utils/socketEvents";
import { emit } from "../../utils/socketio";
import {
  liveQuizPopupStages,
  liveQuizStatus,
  liveQuizType,
} from "../../utils/tinyUtils";
import {
  SET_LIVE_QUIZ_QUESTION_DETAILS,
  SET_LIVE_RESULT_POPUP_DATA,
  SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA,
  SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA,
  SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA,
  SET_CREATE_QUESTION_POPUP_INITIAL_DATA,
  SET_QUICK_QUESTION_LIST,
  SET_QUICK_QUESTION_OPTION_ONCHANGE,
  SET_QUICK_QUESTION_TEXT_ONCHANGE,
  SET_STUDENT_LIVE_QUESTION_POPUP_DATA,
  SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA,
  RESET_LIVE_QUIZ_DATA,
  SET_UNATTEMPTED_USERS,
  SET_STOP_LEADERBOARD_SHARING_POPUP,
  SET_CREATE_QUESTION_POPUP_FILES_DATA,
} from "../types";
import { setStudentLiveClassLoader } from "./liveClassActions";
import {
  errorAlert,
  getSessionId,
  isUserATeacher,
  logoutProcedure,
} from "./utils";

export const getTeacherLeaderboard =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "live-class/teacher/leaderboard",
      true,
      { lecture_id, question_index },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch({
      type: SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA,
      payload: response.response,
    });

    dispatch({
      type: SET_UNATTEMPTED_USERS,
      payload: response.response.unattempted_users,
    });

    return response.response;
  };

export const getStudentLeaderboard =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "live-class/student/leaderboard",
      true,
      { lecture_id, question_index },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch({
      type: SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA,
      payload: response.response,
    });

    return response.response;
  };

/**
 * @typedef {Object} _createQuestionAPIProps
 * @property {number} lecture_id
 * @property {string} question_text
 * @property {"MULTI_CHOICE" | "TRUE_FALSE" | "POLL"} type
 * @property {Array<{is_correct: boolean, text: string}} options
 *
 * @param {_createQuestionAPIProps} param0
 * @returns
 */
export const createLiveQuizQuestion =
  ({
    lecture_id,
    question_text,
    type,
    options,
    state,
    module = MODULES.LIVECLASS,
  }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      module === MODULES.RESOURCES
        ? `/live-class/teacher/schedule/question/create`
        : `/live-class/teacher/question/create`,
      true,
      module === MODULES.RESOURCES
        ? { lecture_id, question_text, type, options }
        : { lecture_id, question_text, type, options, state },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    return apiResponse.response;
  };

/**
 * @param {"MULTI_CHOICE" | "TRUE_FALSE" | "POLL"} type
 * @returns {Array<{is_correct: boolean, text: string}} optionList
 */
const getInitialOptionListFor = (type) => {
  let optionList = [];
  switch (type) {
    case "MULTI_CHOICE":
    case "POLL":
      optionList = Array(4)
        .fill()
        .map((value, index) => {
          return { is_correct: false, text: "", sequence_index: index };
        });
      break;
    case "TRUE_FALSE":
      optionList = Array(2)
        .fill()
        .map((value, index) => {
          return {
            is_correct: false,
            text: index === 0 ? "True" : "False",
            sequence_index: index,
          };
        });
      break;

    default:
      break;
  }

  return optionList;
};

/**
 * @typedef {Object} _createQuestionProps
 * @property {string} question_text
 * @property {"MULTI_CHOICE" | "TRUE_FALSE" | "POLL"} type
 * @property {Array<{is_correct: boolean, text: string}} options
 *
 * @param {_createQuestionProps} param0
 */
export const toggleCreateQuestionPopup =
  ({
    type = "MULTI_CHOICE",
    question_text = "",
    options,
    question_index,
    mode = "CREATE",
    folder_id = mode === "CREATE" ? "temp" : folder_id,
    files = [],
  }) =>
  (dispatch) => {
    if (isNil(options)) {
      options = getInitialOptionListFor(type);
    }

    dispatch({
      type: SET_CREATE_QUESTION_POPUP_INITIAL_DATA,
      payload: {
        type,
        question_text,
        options,
        question_index,
        mode,
        folder_id,
        files,
      },
    });
  };

export const removeFilesFromCreateQuestionPopup =
  ({ fromOption = false, id, folderId }) =>
  (dispatch, getState) => {
    const {
      liveQuiz: { createQuestionPopupData },
    } = getState();

    if (!fromOption) {
      dispatch({
        type: SET_CREATE_QUESTION_POPUP_FILES_DATA,
        payload: {
          files: [],
        },
      });
    } else {
      const filteredOptions = createQuestionPopupData.options.map((option) => {
        if (option.folder_id === folderId) {
          const files = option.files.filter((file) => file.id === id);
          return {
            ...option,
            files: files,
          };
        } else {
          return option;
        }
      });
      dispatch({
        type: SET_CREATE_QUESTION_POPUP_FILES_DATA,
        payload: {
          options: filteredOptions,
        },
      });
    }
  };

export const questionTextOnChange = (text) => (dispatch) => {
  dispatch({
    type: SET_QUICK_QUESTION_TEXT_ONCHANGE,
    payload: text,
  });
};

export const questionOptionOnChange = (option) => (dispatch) => {
  dispatch({
    type: SET_QUICK_QUESTION_OPTION_ONCHANGE,
    payload: option,
  });
};

export const getQuickQuestionsList =
  ({ lectureId, filter = "ALL", module = MODULES.LIVECLASS }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      module === MODULES.RESOURCES
        ? `/live-class/teacher/schedule/questions?lecture_id=${lectureId}`
        : `live-class/teacher/quick-question-list?lecture_id=${lectureId}&filter=${filter}`,
      true,
      {},
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch({
      type: SET_QUICK_QUESTION_LIST,
      payload: {
        published:
          module === MODULES.RESOURCES
            ? Object.values(response?.response?.questions).filter(
                (i) => i.state === "PUBLISHED"
              )
            : response.response.question_created,
        draft:
          module === MODULES.RESOURCES
            ? Object.values(response?.response?.questions).filter(
                (i) => i.state === "DRAFT"
              )
            : response.response.question_drafted,
      },
    });
  };

export const getLiveQuizQuestionDetailByIndex =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "live-class/quick-question",
      true,
      { lecture_id, question_index },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch(
      setLiveQuizQuestionDetails({ payload: response.response.question })
    );

    return response.response.question;
  };

const setLiveQuizQuestionDetails =
  ({ payload }) =>
  (dispatch) => {
    dispatch({
      type: SET_LIVE_QUIZ_QUESTION_DETAILS,
      payload,
    });
  };

/**
 * @param {{isVisible: boolean, activeTabIndex: number, stage: "LIVE" | "ENDED" | "RESULT_PUBLISHED"}} param0
 * @returns
 */
export const setLiveResultPopupData =
  ({ ...args }) =>
  (dispatch) => {
    dispatch({
      type: SET_LIVE_RESULT_POPUP_DATA,
      payload: { ...args },
    });
  };

/**
 * fetch the leader board data and open the popup
 * @param {{role: "student" | "teacher", lecture_id:number, question_index:number}} param0
 * @returns
 */
export const openLiveResultPopup =
  ({ role, lecture_id, question_index }) =>
  async (dispatch, getState) => {
    try {
      let response = {};
      if (role === "teacher") {
        response = await dispatch(
          getTeacherLeaderboard({ lecture_id, question_index })
        );
      } else {
        response = await dispatch(
          getStudentLeaderboard({ lecture_id, question_index })
        );
      }

      if (response.question.status !== liveQuizStatus.ENDED) {
        const isResultPublished =
          response.question.status === liveQuizStatus.ANALYTICS_ACTIVE;
        const isPoll = response.question.type === liveQuizType.POLL;
        const responseTabIndex = 1,
          resultTabIndex = 2;
        dispatch(
          setLiveResultPopupData({
            isVisible: true,
            stage: liveQuizPopupStages[response.question.status],
            activeTabIndex:
              !isPoll && isResultPublished ? resultTabIndex : responseTabIndex,
          })
        );
      }
    } catch (error) {
      console.error(String(error));
    }
  };

export const closeLiveResultPopup =
  ({ lecture_id }) =>
  (dispatch, getState) => {
    const {
      user: { role: userRole },
    } = getState();

    dispatch(
      setLiveResultPopupData({
        isVisible: false,
        stage: "LIVE",
        activeTabIndex: 1,
      })
    );

    if (isUserATeacher(userRole)) {
      dispatch(getQuickQuestionsList({ lectureId: lecture_id }));
    }
  };

export const resetLiveQuizData = () => (dispatch) => {
  dispatch({ type: RESET_LIVE_QUIZ_DATA });
};

/**
 * @param {{isVisible: boolean, selectedOptionIndex: number}} param0
 * @returns
 */
export const setStudentLiveQuestionPopupData =
  ({ ...args }) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_STUDENT_LIVE_QUESTION_POPUP_DATA,
      payload: { ...args },
    });
  };

export const setStudentLiveQuestionResponseRecordedPopupData =
  ({ ...args }) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA,
      payload: { ...args },
    });
  };

export const openStudentQuestionPopup = () => (dispatch) => {
  dispatch(setStudentLiveQuestionPopupData({ isVisible: true }));
};

export const setSelectedOptionOfStudentQuestionPopup =
  ({ selectedOptionIndex }) =>
  (dispatch) => {
    dispatch(setStudentLiveQuestionPopupData({ selectedOptionIndex }));
  };

export const closeStudentQuestionPopup = () => (dispatch) => {
  dispatch(setStudentLiveQuestionPopupData({ isVisible: false }));
};

export const openStudentResponseRecordedPopup =
  ({ lecture_id, question_index }) =>
  async (dispatch) => {
    await dispatch(
      getStudentSubmissionDetails({
        lecture_id,
        question_index,
      })
    );
    dispatch(
      setStudentLiveQuestionResponseRecordedPopupData({ isVisible: true })
    );
  };

export const closeStudentResponseRecordedPopup = () => (dispatch) => {
  dispatch(
    setStudentLiveQuestionResponseRecordedPopupData({
      isVisible: false,
    })
  );
};

export const handleLiveQuizStateFromArchive =
  (quickQuestionArchiveDetails) => async (dispatch, getState) => {
    const {
      user: { role: userRole },
    } = getState();

    /**
     * if there's no archive, then do nothing
     */
    if (isNil(quickQuestionArchiveDetails)) return null;

    if (isUserATeacher(userRole)) {
      // TEACHER
      if (quickQuestionArchiveDetails.status !== liveQuizStatus.ENDED) {
        dispatch(
          openLiveResultPopup({
            role: userRole,
            question_index: quickQuestionArchiveDetails.question_index,
            lecture_id: quickQuestionArchiveDetails.lecture_id,
          })
        );
      }
    } else {
      // STUDENT
      const hasAlreadySubmittedResponse =
        quickQuestionArchiveDetails.is_submitted;

      switch (quickQuestionArchiveDetails.status) {
        case liveQuizStatus.RESPONSE_ACTIVE:
          /*
           * * Student
           *  1. store question and option details
           *  2. open student question popup
           */

          if (hasAlreadySubmittedResponse) {
            dispatch(
              openStudentResponseRecordedPopup({
                lecture_id: quickQuestionArchiveDetails.lecture_id,
                question_index: quickQuestionArchiveDetails.question_index,
              })
            );
          } else {
            dispatch(
              setLiveQuizQuestionDetails({
                payload: quickQuestionArchiveDetails,
              })
            );
            dispatch(openStudentQuestionPopup());
          }
          break;
        case liveQuizStatus.RESPONSE_INACTIVE:
          /**
           * * Student
           *  1. submit response if not submitted already
           *  2. close student question popup
           *  3. open response submitted popup
           */

          if (hasAlreadySubmittedResponse) {
            dispatch(
              openStudentResponseRecordedPopup({
                lecture_id: quickQuestionArchiveDetails.lecture_id,
                question_index: quickQuestionArchiveDetails.question_index,
              })
            );
          }

          break;
        case liveQuizStatus.ANALYTICS_ACTIVE:
          await dispatch(
            openLiveResultPopup({
              role: "student",
              lecture_id: quickQuestionArchiveDetails.lecture_id,
              question_index: quickQuestionArchiveDetails.question_index,
            })
          );

          break;
        default:
          break;
      }
    }
  };

export const emitLiveQuizEvent =
  ({ eventName, question_index, lecture_id }) =>
  async (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();
    if (!socket) {
      return false;
    }

    console.log({ eventName, question_index, lecture_id });
    await emit(eventName, {
      question_index,
      lecture_id,
    })
      .then((success) => console.log({ success }))
      .catch((error) => {
        dispatch(errorAlert(error.message));
      });
  };

export const handleTeacherLiveQuizStart =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    console.log("handleTeacherLiveQuizStart", { lecture_id, question_index });
    await dispatch(
      openLiveResultPopup({ role: "teacher", lecture_id, question_index })
    );
  };

export const handleTeacherLiveQuizRefresh =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    await dispatch(getTeacherLeaderboard({ lecture_id, question_index }));
  };

export const handleTeacherLiveQuizEnd =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    // Final and last call to get the finalized leaderboard
    console.log("handleTeacherLiveQuizEnd", { lecture_id, question_index });
    await dispatch(getTeacherLeaderboard({ lecture_id, question_index }));

    dispatch(setLiveResultPopupData({ stage: "ENDED" }));
  };

export const handleTeacherLiveQuizResultPublished =
  () => (dispatch, getState) => {
    const {
      liveQuiz: { teacherLeaderboard },
    } = getState();

    console.log("handleTeacherLiveQuizResultPublished");
    const isPoll = teacherLeaderboard.question.type === liveQuizType.POLL;
    const resultTabIndex = 2;
    // when teacher publishes result, switch to result tab unless the quiz question type is POLL.

    dispatch(
      setLiveResultPopupData({
        stage: "RESULT_PUBLISHED",
        ...(isPoll ? {} : { activeTabIndex: resultTabIndex }),
      })
    );
  };

export const handleTeacherLiveQuizClose =
  ({ lecture_id }) =>
  (dispatch) => {
    dispatch(closeLiveResultPopup({ lecture_id }));
    dispatch(resetLiveQuizData());
  };

const liveQuizSocketHandlersForTeachers = (dispatch) => {
  const composeD = (f) => (x) => dispatch(f(x));
  return [
    {
      event: LIVE_QUICKQUESTION_START,
      handler: composeD(handleTeacherLiveQuizStart),
    },
    {
      event: LIVE_QUICKQUESTION_TEACHER_RESPONSE_REFRESH,
      handler: composeD(handleTeacherLiveQuizRefresh),
    },
    {
      event: LIVE_QUICKQUESTION_END,
      handler: composeD(handleTeacherLiveQuizEnd),
    },
    {
      event: LIVE_QUICKQUESTION_PUBLISH_RESULT,
      handler: composeD(handleTeacherLiveQuizResultPublished),
    },
    {
      event: LIVE_QUICKQUESTION_CLOSE,
      handler: composeD(handleTeacherLiveQuizClose),
    },
  ];
};

export const addLiveQuizSocketListenersForTeachers =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveQuizSocketHandlersForTeachers(dispatch).forEach(({ event, handler }) =>
      socket.on(event, handler)
    );
  };

export const removeLiveQuizSocketListenersForTeachers =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveQuizSocketHandlersForTeachers(dispatch).forEach(({ event, handler }) =>
      socket.off(event, handler)
    );
  };

export const handleStudentLiveQuizStart =
  ({ question_index, lecture_id }) =>
  async (dispatch, getState) => {
    console.log("handleStudentLiveQuizStart", { question_index, lecture_id });
    await dispatch(
      getLiveQuizQuestionDetailByIndex({ lecture_id, question_index })
    );
    dispatch(openStudentQuestionPopup());
  };

export const handleStudentLiveQuizEnd =
  ({ question_index, lecture_id }) =>
  async (dispatch, getState) => {
    const {
      liveQuiz: { studentLiveQuestionPopup },
    } = getState();

    if (studentLiveQuestionPopup.isVisible) {
      // auto submit response and even if submission fails,
      // close the question popup, as teacher has ended the quiz
      dispatch(setStudentLiveClassLoader(true));
      try {
        const success = await dispatch(
          emitStudentLiveQuizResponseEvent({
            eventName: LIVE_QUICKQUESTION_STUDENT_SUBMIT_RESPONSE,
            lecture_id,
            question_index,
            marked_option_index: studentLiveQuestionPopup.selectedOptionIndex,
          })
        );

        dispatch(setStudentLiveQuestionPopupData({ isVisible: false }));

        /**
         * open response submitted popup only if the submission was successful.
         * else ignore.
         */
        if (success) {
          await dispatch(
            openStudentResponseRecordedPopup({ question_index, lecture_id })
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setStudentLiveClassLoader(false));
      }
    }
  };

export const handleStudentLiveQuizResultPublished =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    await dispatch(closeStudentResponseRecordedPopup());
    await dispatch(
      openLiveResultPopup({ role: "student", lecture_id, question_index })
    );
  };

export const handleStudentLiveQuizClose =
  ({ lecture_id }) =>
  (dispatch, getState) => {
    const {
      liveQuiz: { liveResultPopupData },
    } = getState();
    if (liveResultPopupData.isVisible) {
      dispatch(closeLiveResultPopup({ lecture_id }));
    } else {
      // close response submitted popup
      dispatch(closeStudentResponseRecordedPopup());
    }
    dispatch(resetLiveQuizData());
  };

const liveQuizSocketHandlersForStudents = (dispatch) => {
  const composeD = (f) => (x) => dispatch(f(x));
  return [
    {
      event: LIVE_QUICKQUESTION_START,
      handler: composeD(handleStudentLiveQuizStart),
    },
    {
      event: LIVE_QUICKQUESTION_END,
      handler: composeD(handleStudentLiveQuizEnd),
    },
    {
      event: LIVE_QUICKQUESTION_PUBLISH_RESULT,
      handler: composeD(handleStudentLiveQuizResultPublished),
    },
    {
      event: LIVE_QUICKQUESTION_CLOSE,
      handler: composeD(handleStudentLiveQuizClose),
    },
  ];
};

export const addLiveQuizSocketListenersForStudents =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveQuizSocketHandlersForStudents(dispatch).forEach(({ event, handler }) =>
      socket.on(event, handler)
    );
  };

export const removeLiveQuizSocketListenersForStudents =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveQuizSocketHandlersForStudents(dispatch).forEach(({ event, handler }) =>
      socket.off(event, handler)
    );
  };

/**
 * @typedef {Object} _updateDraftQuestionAPIProps
 * @property {number} lecture_id
 * @property {number} question_index
 * @property {string} question_text
 * @property {"MULTI_CHOICE" | "TRUE_FALSE" | "POLL"} type
 * @property {"DRAFT" | "PUBLISHED"} state
 * @property {Array<{is_correct: boolean, text: string}} options
 *
 * @param {_updateDraftQuestionAPIProps} param0
 * @returns
 */
export const updateDraftQuestion =
  ({
    lecture_id,
    question_text,
    type,
    options,
    state,
    question_index,
    module = MODULES.LIVECLASS,
  }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      module === MODULES.RESOURCES
        ? `/live-class/teacher/schedule/question/edit?question_id=${question_index}`
        : `/live-class/teacher/draft-question/update-draft?question_index=${question_index}`,
      true,
      module === MODULES.RESOURCES
        ? {
            lecture_id,
            question_text,
            type,
            options: options.map((o) => {
              return {
                id: o.id,
                is_correct: o.is_correct ? true : false,
                is_removed: o.text === "" ? true : false,
                text: o.text,
              };
            }),
          }
        : { lecture_id, question_text, type, options, state },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    return apiResponse.response;
  };

/**
 * @typedef {Object} _publishDraftedQuestionAPIProps
 * @property {number} lecture_id
 * @property {number} question_index
 *
 * @param {_publishDraftedQuestionAPIProps} param0
 * @returns
 */
export const publishDraftedQuestion =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      `/live-class/teacher/draft-question/publish?lecture_id=${lecture_id}&question_index=${question_index}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return false;
    }

    return apiResponse.response;
  };

/**
 * @typedef {Object} _studentPerformanceAPIProps
 * @property {number} lecture_id
 * @property {number} question_index
 *
 * @param {_studentPerformanceAPIProps} param0
 * @returns
 */
export const getStudentPerformanceData =
  ({ lecture_id, question_index, module = MODULES.LIVECLASS }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "GET",
      backendApi,
      module === MODULES.RESOURCES
        ? `/live-class/teacher/question/leaderboard?lecture_id=${lecture_id}&question_id=${question_index}`
        : `/live-class/teacher/leaderboard?lecture_id=${lecture_id}&question_index=${question_index}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    const res = apiResponse.response;

    dispatch({
      type: SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA,
      payload: {
        average_time: res.average_time,
        accuracy_percentage: res.accuracy_percentage,
        option_wise_response_count: res.option_wise_response_count,
        total_response_count: res.total_response_count,
        total_student_count: res.total_student_count,
        correct_response_count: res.correct_response_count,
        submissions: res.submissions,
        options: res.options,
        question: res.question,
        totalStudentCount: res.total_student_count,
        correctResponseCount: res.correct_response_count,
      },
    });

    return res.success;
  };

export const emitStudentLiveQuizResponseEvent =
  ({ eventName, question_index, lecture_id, marked_option_index }) =>
  async (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();
    if (!socket) {
      return false;
    }

    return emit(eventName, {
      question_index,
      lecture_id,
      marked_option_index,
    }).catch((error) => {
      dispatch(errorAlert(error.message));
    });
  };

/**
 * @typedef {Object} _studentSubmissionDetailsAPIProps
 * @property {number} lecture_id
 * @property {number} question_index
 *
 * @param {_studentSubmissionDetailsAPIProps} param0
 * @returns
 */
export const getStudentSubmissionDetails =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "GET",
      backendApi,
      `/live-class/quick-question/submission?lecture_id=${lecture_id}&question_index=${question_index}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    console.log({ res: apiResponse.response });

    dispatch(
      setStudentLiveQuestionResponseRecordedPopupData({
        timeTaken: apiResponse.response.submission_response.duration,
      })
    );
  };

export const setShowStopLeaderboardSharing =
  (state) => async (dispatch, getState) => {
    dispatch({
      type: SET_STOP_LEADERBOARD_SHARING_POPUP,
      payload: state,
    });
  };

export const removeQuestion =
  ({ lecture_id, question_id }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      `live-class/teacher/schedule/question/remove?lecture_id=${lecture_id}&question_id=${question_id}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
  };

export const getIndividualQuestion =
  ({ lecture_id, question_index }) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const apiResponse = await makeApiRequest(
      "GET",
      backendApi,
      `/live-class/teacher/schedule/question/view?lecture_id=${lecture_id}&question_id=${question_index}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
  };
