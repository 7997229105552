import _ from "lodash";

import store from "..";
import {
  SET_JITSI_CONNECTION_INITIALIZED,
  SET_JITSI_CONFERENCE_OBJECT,
  SET_JITSI_CONNECTION_OBJECT,
  SET_JITSI_CONFERENCE_JOINED,
  SET_MY_JITSI_PARTICIPANT_DETAILS,
  ADD_JITSI_PARTICIPANT,
  REMOVE_JITSI_PARTICIPANT,
  SET_CHANGED_PARTICIPANT_PROPERTY,
  SET_JITSI_CONFERENCE_ENDED,
  SET_SELECTED_PARTICIPANT_IDS,
  SET_TEACHER_PARTICIPANTS,
  SET_STUDENT_PARTICIPANTS,
  SET_SHARED_PARTICIPANT_DETAILS,
  SET_ALL_PARTICIPANTS,
  SET_CURRENT_TEACHER_PARTICIPANT,
  SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
  SET_JITSI_CONNECTION_DROPPED,
  SET_JITSI_CONNECTION_RECONNECTED,
  SET_DESKTOP_PARTICIPANTS,
  SET_LINK_CONNECT_PARTICIPANTS,
} from "../types";
import {
  STUDENT,
  TEACHER,
  VIDEO_TYPES,
  WHITEBOARD_TYPES,
  CURRENT_DEVICE,
  MOBILE_APP,
  VIDEO_OPTIONS,
  OPTIONS,
  MEDIA_SHARE_OPTIONS,
  STUDENT_VIDEO_QUALITY_VALUES,
  BITRATE_CAPS_ENABLED,
  PARTICIPANT_TYPES,
  TEMP_TEACHER,
  TEMP_STUDENT,
} from "../../utils/constants";
import JitsiMeetJS from "../../utils/JitsiMeetJS";
import { getUserStartupData } from "./userActions";
import { studentSort, teacherSort } from "../../utils/participantSorting";
import { setConnectionErrorType } from "./miscellaneousActions";
import { connectionError } from "../../utils/tinyUtils";

const jitsiConfig = {
  enableNoAudioDetection: false,
  enableNoisyMicDetection: false,
  useIPv6: false,
  disableAudioLevels: true,
  enableWindowOnErrorHandler: false,
  disableThirdPartyRequests: false,
  enableAnalyticsLogging: false,
  enableLayerSuspension: true,
  disableSimulcast: false,
  videoQuality: {
    preferredCodec: "VP8",
    enforcePreferredCodec: false,
  },
  clientNode: "http://jitsi.org/jitsimeet",
  useNicks: false,
  getWiFiStatsMethod: null,
  useStunTurn: true,
  p2p: {
    enabled: false,
    useStunTurn: true,
    stunServers: [{ urls: "stun:meet-jit-si-turnrelay.jitsi.net:443" }],
  },
  analytics: {},
  deploymentInfo: {},
  e2eping: {
    pingInterval: -1,
  },
  disableRtx: true,
};

const customCommands = {
  MUTE_MIC_YOURSELF: "MUTE_MIC_YOURSELF",
  MUTE_VIDEO_YOURSELF: "MUTE_VIDEO_YOURSELF",
  MUTE_ALL_VIDEOS: "MUTE_ALL_VIDEOS",
  MUTE_ALL_MICS: "MUTE_ALL_MICS",
  START_SHARING_YOUR_VIDEO: "START_SHARING_YOUR_VIDEO",
  STOP_SHARING_YOUR_VIDEO: "STOP_SHARING_YOUR_VIDEO",
  KICK_YOURSELF: "KICK_YOURSELF",
  CONFERENCE_ENDED: "CONFERENCE_ENDED",
};

let CURRENT_USER = TEACHER;

const initJitsiConnection =
  (forceReConnect = false) =>
  (dispatch, getState) => {
    const { jitsi, user } = getState();
    const { jitsiRootDomain, jitsiConnectionInitialized, conferenceId } = jitsi;
    const { role = "" } = user;

    if (jitsiConnectionInitialized && !forceReConnect) {
      return;
    }

    dispatch({
      type: SET_JITSI_CONNECTION_INITIALIZED,
      payload: null,
    });

    CURRENT_USER = role.toUpperCase() || TEACHER;

    // jitsi service url and hosts
    jitsiConfig.serviceUrl = `https://${jitsiRootDomain}/http-bind?room=${conferenceId}`;
    jitsiConfig.hosts = {
      domain: jitsiRootDomain,
      muc: `conference.${jitsiRootDomain}`,
    };

    if (BITRATE_CAPS_ENABLED) {
      jitsiConfig.videoQuality.maxBitratesVideo = {
        H264: {
          low: 200000,
          standard: 500000,
          high: 1500000,
        },
        VP8: {
          low: 200000,
          standard: 500000,
          high: 1500000,
        },
        VP9: {
          low: 100000,
          standard: 300000,
          high: 1200000,
        },
      };
    }

    // we are using this line to make sure the initial lastN value is 0. but somehow after we started using the new pagination api, this line is causing new joiners to see grey videos. so instead we are now calling selectParticipants([]) on conference joined.
    // jitsiConfig.channelLastN = 0;

    const jitsiConnection = new JitsiMeetJS.JitsiConnection(
      null,
      null,
      jitsiConfig
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => dispatch(initJitsiConference())
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      (errorCode) => {
        dispatch(onConnectionFailed(errorCode));
      }
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      (...args) => {
        console.log("CONNECTION_DISCONNECTED", { ...args });
      }
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.WRONG_STATE,
      (...args) => {
        console.log("WRONG_STATE", { ...args });
      }
    );

    jitsiConnection.connect();

    dispatch({
      type: SET_JITSI_CONNECTION_OBJECT,
      payload: jitsiConnection,
    });
  };

const initJitsiConference = () => (dispatch, getState) => {
  const {
    jitsi,
    misc: { connectionErrorType },
    liveClass: { connectionIssue: socketConnectionDropped },
  } = getState();
  const { conferenceId, jitsiConnection, connectionDropped } = jitsi;

  const jitsiConference = jitsiConnection.initJitsiConference(
    conferenceId,
    jitsiConfig
  );

  dispatch(addConferenceEventListeners(jitsiConference));

  jitsiConference.join();

  dispatch({
    type: SET_JITSI_CONFERENCE_OBJECT,
    payload: jitsiConference,
  });

  if (connectionDropped) {
    dispatch(connectionReconnected());
  }

  /**
   * if socket & jitsi both got disconnected, and then jitsi got reconnected
   * but socket is not, then update the errorType to "socket"
   * else just hide the connection error popup
   */
  if (
    connectionErrorType === connectionError.socketAndJitsi &&
    socketConnectionDropped
  ) {
    store.dispatch(setConnectionErrorType(connectionError.socket));
  } else if (connectionErrorType) {
    store.dispatch(setConnectionErrorType(null));
  }
};

const addConferenceEventListeners = (jitsiConference) => (dispatch) => {
  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_FAILED,
    (...args) => {
      console.log("CONFERENCE_FAILED", { ...args });
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_ERROR,
    (...args) => {
      console.log("CONFERENCE_ERROR", { ...args });
    }
  );

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
    dispatch(onConferenceJoined());
  });

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_LEFT, () => {});

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_JOINED,
    (participantId) => {
      console.log("jitsi user joined", participantId);
      dispatch(onParticipantJoined(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_LEFT,
    (participantId) => {
      console.log("user_left", { participantId });
      dispatch(onParticipantLeft(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
    (user, key, ignore, value) => {
      dispatch(onParticipantPropertyChanged(user.getId(), key, value));
    }
  );

  jitsiConference.addCommandListener(customCommands.CONFERENCE_ENDED, () => {
    dispatch(onConferenceEnded());
  });
};

const onConnectionFailed = (errorCode) => (dispatch) => {
  console.log("CONNECTION_FAILED", errorCode);

  dispatch({
    type: SET_JITSI_CONNECTION_DROPPED,
    payload: null,
  });

  if (!global.jitsiReconnectionAttemptCount)
    global.jitsiReconnectionAttemptCount = 1;
  else global.jitsiReconnectionAttemptCount += 1;

  global.jitsiReconnectionTimeout = setTimeout(() => {
    dispatch(initJitsiReConnection());
  }, 5000);
};

const initJitsiReConnection = () => (dispatch, getState) => {
  const {
    jitsi,
    misc: { connectionErrorType },
  } = getState();
  const { connectionDropped } = jitsi;
  if (!connectionDropped) {
    // if jitsi gets reconnected, hide the connection error popup
    dispatch(setConnectionErrorType(null));
    dispatch(connectionReconnected());
  } else {
    // 9 iteration with 5sec of timeout equals 45sec
    if (global.jitsiReconnectionAttemptCount === 9) {
      /**
       * if socket is already disconnected and then jitsi gets disconnected, then
       * set the connectionErrorType should be both "socketAndJitsi" else "jitsi"
       */
      if (connectionErrorType === connectionError.socket) {
        store.dispatch(setConnectionErrorType(connectionError.socketAndJitsi));
      } else {
        store.dispatch(setConnectionErrorType(connectionError.jitsi));
      }
    }
    dispatch(initJitsiConnection());
  }
};

const connectionReconnected = () => (dispatch) => {
  dispatch(clearJitsiReconnectionTimeout());

  dispatch({
    type: SET_JITSI_CONNECTION_RECONNECTED,
    payload: null,
  });
};

const clearJitsiReconnectionTimeout = () => (dispatch) => {
  if (global.jitsiReconnectionTimeout) {
    clearTimeout(global.jitsiReconnectionTimeout);
    global.jitsiReconnectionTimeout = undefined;
    global.jitsiReconnectionAttemptCount = 0;
  }
};

const setMyJitsiUserName = (userName) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (!myParticipantId) {
    return;
  }

  dispatch(setJitsiParticipantProperty(myParticipantId, "userName", userName));
};

const setMyJitsiUserEmail = (userEmail) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (!myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userEmail", userEmail)
  );
};

const jitsiConferenceAddTrack = (track) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference, participants, myParticipantId } = jitsi;

  if (!track) {
    return;
  }

  if (!jitsiConference) {
    return;
  }

  try {
    await jitsiConference.addTrack(track);
  } catch (error) {
    console.log(error);
    if (
      error &&
      error.message &&
      error.message.includes("second video track")
    ) {
      const participant = participants[myParticipantId];
      if (participant && participant.tracks) {
        const [toRemoveVideoTrack] = (participant.tracks || []).filter(
          (t) => t.type !== "audio"
        );

        if (!toRemoveVideoTrack || !toRemoveVideoTrack.track) {
          return;
        }

        try {
          await jitsiConference.removeTrack(toRemoveVideoTrack.track);
          await toRemoveVideoTrack.track.dispose();
          await dispatch(jitsiConferenceAddTrack(track));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
};

const onConferenceJoined = () => async (dispatch, getState) => {
  const { jitsi, user } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  dispatch({
    type: SET_JITSI_CONFERENCE_JOINED,
    payload: null,
  });

  global.finishedWaitingTimeout = setTimeout(() => {
    dispatch({
      type: SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
    });
    global.finishedWaitingTimeout = undefined;
  }, 5000);

  const myParticipantId = jitsiConference.myUserId();

  dispatch({
    type: SET_MY_JITSI_PARTICIPANT_DETAILS,
    payload: {
      myParticipantId,
    },
  });

  dispatch(setJitsiParticipantProperty(myParticipantId, "userId", user.id));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "participantType",
      PARTICIPANT_TYPES.USER
    )
  );
  dispatch(setMyJitsiUserName(user.name));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "userImage",
      user.profile_photo_url || user.profilePhotoUrl
    )
  );
  dispatch(setMyJitsiUserEmail(user.email));
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userType", CURRENT_USER)
  );
  dispatch(setMyVideoType(VIDEO_TYPES.VIDEO_OFF));
  dispatch(setMyVideoState(VIDEO_OPTIONS.off));
  if ([TEACHER, TEMP_TEACHER].includes(CURRENT_USER)) {
    dispatch(setMyWhiteboardType(WHITEBOARD_TYPES.WHITEBOARD_OFF));
  }
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "lastStartedSpeaking",
      new Date(0).toISOString()
    )
  );

  dispatch(selectParticipants([]));

  if (CURRENT_DEVICE === MOBILE_APP) {
    jitsiConference.setSenderVideoConstraint(360);
  }
};

const onParticipantJoined = (participantId) => (dispatch) => {
  dispatch({
    type: ADD_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

const setJitsiParticipantProperty =
  (myParticipantId, key, value) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference } = jitsi;

    if (!jitsiConference) {
      return;
    }

    jitsiConference.setLocalParticipantProperty(key, value);
    dispatch(onParticipantPropertyChanged(myParticipantId, key, value));
  };

const onParticipantPropertyChanged =
  (participantId, key, value) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { myParticipantId, participants } = jitsi;

    setTimeout(() => {
      dispatch({
        type: SET_CHANGED_PARTICIPANT_PROPERTY,
        payload: {
          participantId,
          key,
          value: key === "userId" ? Number(value) : value,
        },
      });

      dispatch(calculateParticipantsList());
    }, 1000);
  };

const onParticipantLeft = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

export const sendKickParticipant = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.KICK_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

const onConferenceEnded = () => (dispatch) => {
  dispatch({
    type: SET_JITSI_CONFERENCE_ENDED,
    payload: true,
  });

  if (CURRENT_DEVICE === MOBILE_APP) {
    dispatch(getUserStartupData("student"));
  }
};

export const selectParticipants =
  (participantIds = [], qualityToSet) =>
  (dispatch, getState) => {
    const { jitsi, liveClass, user } = getState();
    const { jitsiConference } = jitsi;

    if (jitsiConference) {
      dispatch({
        type: SET_SELECTED_PARTICIPANT_IDS,
        payload: participantIds,
      });

      let maxHeightToSet;

      if (CURRENT_USER === TEACHER) {
        maxHeightToSet = 480;
      } else if (CURRENT_DEVICE === MOBILE_APP) {
        maxHeightToSet = 480;
      } else {
        if (qualityToSet) {
          maxHeightToSet = STUDENT_VIDEO_QUALITY_VALUES[qualityToSet] || 720;
        } else {
          const { student_preferences } = user;
          const { quality } = student_preferences;
          maxHeightToSet = STUDENT_VIDEO_QUALITY_VALUES[quality] || 720;
        }
      }

      // new pagination api by lib-jitsi-meet
      jitsiConference.setReceiverConstraints({
        lastN: participantIds.length,
        selectedEndpoints: participantIds,
        defaultConstraints: { maxHeight: maxHeightToSet },
      });

      // commenting our old way to achieve pagination using lib-jitsi-meet
      // jitsiConference.selectParticipants(participantIds);
      // dispatch(setLastN(participantIds.length));
    }
  };

const setMyVideoType = (videoType) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoType", videoType)
  );
};

export const setMyWhiteboardType = (whiteboardType) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "whiteboardType",
      whiteboardType
    )
  );
};

export const setMyVideoState = (videoState) => (dispatch, getState) => {
  if (![TEACHER, TEMP_TEACHER].includes(CURRENT_USER)) {
    return;
  }

  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  /*
    videoState values
    - SMALL
    - BIG
    - OFF
  */

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoState", videoState)
  );
};

const calculateParticipantDetails =
  (participantId, participants, handRaises) => (dispatch, getState) => {
    const participant = participants[participantId];

    return {
      videoState: VIDEO_OPTIONS.off,
      broadcastType: OPTIONS.VIDEO_OR_DP,
      mediaShareType: MEDIA_SHARE_OPTIONS.SLIDES,
      lastStartedSpeaking: new Date(0).toISOString(),
      ...participant,
      videoMuted: true,
      videoTrack: null,
      audioMuted: true,
      audioTrack: null,
      handRaised: false,
      isVideoShared: false,
    };
  };

const findCurrentTeacherParticipant =
  (allTeacherParticipants) => (dispatch) => {
    const allTeacherSortedParticipants = _.sortBy(
      allTeacherParticipants,
      teacherSort
    );

    dispatch({
      type: SET_CURRENT_TEACHER_PARTICIPANT,
      payload: allTeacherSortedParticipants[0],
    });
  };

const calculateParticipantsList = (timedOut) => (dispatch, getState) => {
  // added a 500ms timeout to batch the function call.
  // this function gets called lot of times during conference join that might lead to some slowing up of the app and sometimes making it unresponsive.
  if (!global.calculateParticipantsListTimeout) {
    global.calculateParticipantsListTimeout = setTimeout(
      () => {
        dispatch(calculateParticipantsList(true));
        global.calculateParticipantsListTimeout = undefined;
      },
      CURRENT_DEVICE === MOBILE_APP ? 1000 : 500
    );
  }

  if (!timedOut) {
    return;
  }

  const { jitsi, liveClass, user } = getState();
  const { myParticipantId, participantIds, participants } = jitsi;
  const { handRaises } = liveClass;

  const allParticipants = {};
  const desktopParticipants = {};
  const linkConnectParticipants = {};

  participantIds.forEach((pid) => {
    if (
      participants[pid] &&
      participants[pid].userId &&
      participants[pid].participantType === PARTICIPANT_TYPES.DESKTOP &&
      participants[pid]?.tracks?.length > 0
    ) {
      desktopParticipants[participants[pid].userId] = pid;
    }
    if (
      participants[pid] &&
      participants[pid].userId &&
      participants[pid].participantType === PARTICIPANT_TYPES.LINK_CONNECT &&
      participants[pid]?.tracks?.length > 0
    ) {
      linkConnectParticipants[participants[pid].userId] = pid;
    }

    if (
      participants[pid] &&
      participants[pid].userId &&
      pid !== myParticipantId &&
      participants[pid].userId !== user.id &&
      participants[pid].participantType === PARTICIPANT_TYPES.USER
      // in case of instant lecture, there won't be any tracks
      // hence user should be allowed without any track, so commenting below condition
      // participants[pid]?.tracks?.length > 0
    ) {
      allParticipants[participants[pid].userId] = pid;
    }
  });

  const tempParticipantIds = Object.values(allParticipants);

  const tempParticipants = tempParticipantIds.map((pid) =>
    dispatch(calculateParticipantDetails(pid, participants, handRaises))
  );

  const teacherParticipants = tempParticipants.filter((participant) =>
    [TEACHER, TEMP_TEACHER].includes(participant.userType)
  );

  const studentParticipants = tempParticipants.filter((participant) =>
    [STUDENT, TEMP_STUDENT].includes(participant.userType)
  );

  const teacherSortedParticipants = _.sortBy(teacherParticipants, teacherSort);

  const studentSortedParticipants = _.sortBy(studentParticipants, studentSort);

  dispatch({
    type: SET_TEACHER_PARTICIPANTS,
    payload: teacherSortedParticipants,
  });

  dispatch({
    type: SET_STUDENT_PARTICIPANTS,
    payload: studentSortedParticipants,
  });

  dispatch({
    type: SET_ALL_PARTICIPANTS,
    payload: tempParticipants,
  });

  dispatch({
    type: SET_DESKTOP_PARTICIPANTS,
    payload: Object.values(desktopParticipants).map((pid) =>
      dispatch(calculateParticipantDetails(pid, participants, handRaises))
    ),
  });

  const linkConnectParticipantsList = Object.values(
    linkConnectParticipants
  ).map((pid) =>
    dispatch(calculateParticipantDetails(pid, participants, handRaises))
  );

  dispatch({
    type: SET_LINK_CONNECT_PARTICIPANTS,
    payload: linkConnectParticipantsList,
  });

  let myParticipantDetails;

  if (
    myParticipantId &&
    participants[myParticipantId] &&
    participants[myParticipantId].userType
  ) {
    myParticipantDetails = dispatch(
      calculateParticipantDetails(myParticipantId, participants, handRaises)
    );
  }

  let allTeacherParticipants;
  if ([TEACHER, TEMP_TEACHER].includes(CURRENT_USER) && myParticipantDetails) {
    allTeacherParticipants = [
      ...teacherSortedParticipants,
      myParticipantDetails,
    ];
  } else {
    allTeacherParticipants = [...teacherSortedParticipants];
  }

  dispatch(findCurrentTeacherParticipant(allTeacherParticipants));

  let sharedParticipantId = null;
  let sharedParticipantVideoTrack = null;

  const allTempParticipants = [...tempParticipants];
  if (myParticipantDetails) {
    allTempParticipants.push(myParticipantDetails);
  }

  const [sharedParticipant] = [
    ...allTempParticipants,
    ...linkConnectParticipantsList,
  ].filter((participant) => participant.isVideoShared);

  if (sharedParticipant) {
    sharedParticipantId = sharedParticipant.participantId;

    if (sharedParticipantId && !sharedParticipant.videoTrack) {
      const allDesktopParticipants = Object.values(desktopParticipants).map(
        (pid) =>
          dispatch(calculateParticipantDetails(pid, participants, handRaises))
      );
      const sharedDesktopParticipant = allDesktopParticipants.find(
        (participant) => participant.userId === sharedParticipant.userId
      );
      sharedParticipantVideoTrack = sharedDesktopParticipant.videoTrack;
    } else {
      sharedParticipantVideoTrack = sharedParticipant.videoTrack;
    }
  }

  if (
    jitsi.sharedParticipantId !== sharedParticipantId ||
    jitsi.sharedParticipantVideoTrack !== sharedParticipantVideoTrack
  ) {
    dispatch({
      type: SET_SHARED_PARTICIPANT_DETAILS,
      payload: {
        sharedParticipantId,
        sharedParticipantVideoTrack,
      },
    });
  }
};

export const instantJitsi = () => async (dispatch) => {
  return {
    initJitsiConnection: () => dispatch(initJitsiConnection()),
    calculateParticipantsList: () => dispatch(calculateParticipantsList()),
  };
};
