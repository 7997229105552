import produce from "immer";
import {
  liveClassFilesSelectedOption,
  liveClassLHSTabKeys,
  liveVideoStateOptions,
} from "../../utils/tinyUtils";

import {
  SET_LIVE_VIDEO_STATE,
  SET_NEW_WHITEBOARD_ID,
  SET_WHITEBOARD_ACTIVE,
  SET_WHITEBOARD_ACTIVE_LAYER_ID,
  SET_BROADCASTED_ACTIVE_LAYER_ID,
  ADD_MESSAGE,
  ADD_LIVE_EVENT_MESSAGE,
  ADD_CHAT_ARCHIVE_MESSAGES,
  ADD_EVENT_ARCHIVE_MESSAGES,
  ADD_EVENT_REQUEST_ARCHIVE_MESSAGES,
  ADD_BUFFER_MESSAGE,
  REMOVE_BUFFER_MESSAGE,
  UPDATE_BUFFER_MESSAGE,
  BUFFER_TO_MESSAGE,
  ADD_PEOPLE,
  UPDATE_PEOPLE,
  REMOVE_PEOPLE,
  ADD_REQUEST,
  REMOVE_REQUEST,
  REMOVE_REQUESTS_OF_USER,
  SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD,
  RESET_LIVECLASS,
  ADD_HANDRAISE_ARCHIVE,
  ADD_HANDRAISE,
  REMOVE_HANDRAISE,
  SET_SUBMISSION_WHITEBOARD_ACTIVE,
  SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT,
  ADD_LIVECLASS_PEOPLE,
  SET_SUBMISSION_WHITEBOARD_DATA,
  SET_LIVE_CLASS_WHITEBOARD_DATA,
  CLEAR_LIVECLASS_DETAILS,
  SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO,
  SET_WHITEBOARD_DATA,
  SET_LIVE_CLASS_FILES_SELECTED_NONE,
  SET_LARGE_PEOPLE_TAB_ACTIVE,
  SET_SHOW_RHS_PEOPLE_TAB,
  SET_LIVE_CLASS_FILES_SELECTED,
  SET_SLIDEBOARD_LAYER_INTIALIZE_STATE,
  SET_SLIDEBOARD_ACTIVE_LAYER_ID,
  SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID,
  SET_PAPER_ACTIVE_LAYER_ID,
  SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY,
  SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION,
  SET_LIVE_CLASS_ACTIVE,
  SET_LIVE_CLASS_FILES_STATE,
  ADD_SCREENSHARE_REQUEST,
  REMOVE_SCREENSHARE_REQUEST,
  REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID,
  RESET_SCREENSHARE_REQUEST_LIST,
  ADD_MIC_REQUEST,
  REMOVE_MIC_REQUEST,
  RESET_MIC_REQUEST_LIST,
  ADD_VIDEO_REQUEST,
  REMOVE_VIDEO_REQUEST,
  RESET_VIDEO_REQUEST_LIST,
  SET_GRID_ICON_ACTIVE,
  SET_LIVE_CLASS_TIME_ELAPSED,
  SET_LIVE_CLASS_TIMER_DETAILS,
  ADD_NEW_STUDENT_NOTEBOOK,
  SET_STUDENT_LIVE_CLASS_FULL_SCREEN,
  BUFFER_TO_EVENT,
  REMOVE_MIC_REQUEST_FROM_REQUEST_ID,
  REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID,
  SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA,
  SET_STUDENT_LIVE_CLASS_MIRO_WHITEBOARD_DATA,
  SET_STUDENT_VIDEO_MINIMIZED,
  SET_CHAT_SHOWING_CONTEXT_MENU,
  SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION,
  SET_SHOW_RATE_SESSION_POPUP,
  SET_SHOW_KICK_OUT_POPUP,
  SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW,
  SET_SET_LARGE_PRESENTATION_TAB_ACTIVE,
  SET_CLICKED_END_SESSION_FOR_ALL,
  SET_SHOW_JOIN_REQUEST_PENDING,
  SET_SHOW_JOIN_REQUEST_DENIED,
  SET_SHOW_REQUEST_PENDING,
  SET_SHOW_REQUEST_DENIED,
  SET_SHOW_REQUESTING_VIDEO,
  SET_SHOW_REQUESTING_AUDIO,
  SET_SHOW_HAND_RAISE_TIMER,
  SET_STUDENT_LIVE_CLASS_LOADER,
  SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP,
  SET_NOTEBOOK_DELETE_LAYER_ID,
  SET_WHITEBOARD_DELETE_LAYER_ID,
  SET_WHITEBOARD_SLIDE_ID,
  SET_FILE_DELETE_ID,
  UPDATE_STUDENT_NOTEBOOK_IDS,
  UPDATE_WHITEBOARD_IDS,
  SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID,
  SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED,
  SET_HANDRAISE_REQUEST_ID,
  UPDATE_EVENT_STATUS,
  SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP,
  SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP,
  UPDATE_PEOPLE_DETAILS,
  SET_YOUTUBE_VIDEO_SHARE_CONTROLS,
  SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED,
  SET_SHOW_LOAD_MEDIA_POPUP,
  SET_SHOW_REQUESTING_SCREENSHARE,
  SET_LIVE_LECTURE,
  SET_LIVE_CLASS,
  SET_LIVE_CLASS_FILESYSTEM_IDS,
  SET_DISPLAY_PARTICIPANT_ID,
  SET_NOTIFICATION_HEADER,
  SET_CONNECTION_ISSUE,
  UPDATE_LIVECLASS_TOPIC,
  SET_LIVECLASS_LECTURE_ID,
  SET_FEEDBACK_AGGREGATE_SCORE,
  SET_SHOW_FEEDBACK_INPUT,
  SET_USER_PREVIOUS_FEEDBACK_SCORE,
  SET_STUDENT_YOUTUBE_INITIALIZED,
  SET_LIVECLASS_LAST_VIEWED_MESSAGE,
  SET_LIVECLASS_UNREAD_MESSAGES,
  SET_SHOW_LINK_CONNECT_POPUP,
  SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP,
  SET_SHOW_LINK_DEVICE_BROADCAST_POPUP,
  SET_DEVICE_INFO,
  SET_NEW_PARTICIPANTS_LIST,
  SET_LAYER_GRID_STATE,
  SET_ENTRY_REQUESTS_POPUP,
  SET_STUDENTS_REQUESTS_POPUP,
} from "../types";

import WHITEBOARD_PATH_JSON from "../../screens/LiveClass/WHITEBOARD_PATH_JSON.json";
import SlideboardDummyJSON from "../../components/StudentPresentationSlideshareAndWhiteboard/SlideboardDummyData.json";

const initialSubmissionWhiteboard = [
  {
    id: "2",
    type: "Layer",
    belongsTo: "submissionboard",
    undoList: [],
    children: [],
  },
];

const initialWhiteboardData = [
  {
    id: "4",
    type: "Layer",
    belongsTo: "whiteboard",
    undoList: [],
    children: [],
    grid: false,
  },
];

const initialState = {
  isWhiteboardActive: false,
  liveVideoState: liveVideoStateOptions.fullScreen,
  liveclassWhiteboardData: initialWhiteboardData,
  // allWhiteboardData: WHITEBOARD_PATH_JSON,
  allWhiteboardData: WHITEBOARD_PATH_JSON,
  isSmallVideoViewActive: false,
  whiteboardActiveLayerId: "4",
  broadcastedActiveLayerId: "null",
  // slideboardActiveLayerId: null,
  slideboardActiveLayerId: "402446e2-bfa7-431e-8007-edfae494b9af",
  submissionboardActiveLayerId: null,
  // paperActiveLayerId: "4",
  paperActiveLayerId: "402446e2-bfa7-431e-8007-edfae494b9af",
  studentNotebookActiveLayerId: "7",
  whiteboardIds: ["4"],
  studentNotebookIds: [{ id: "7" }],
  whiteboardActiveLayerBelongsTo: null,
  people: {},
  messages: {},
  liveEvents: {},
  buffer: {},
  requests: {},
  handRaises: new Set([]),
  handRaiseRequestId: null,
  showCopyMeetingLinkUiLayerOnWhiteboard: true,
  submissionWhiteboardData: initialSubmissionWhiteboard,
  isSubmissionWhiteboardActive: false,
  submissionActiveCardDataObj: {},
  isLiveClassFilesSelectedNone: true,
  liveClassFilesSelected: liveClassFilesSelectedOption.SELECTED_NONE,
  isLargePeopleTabActive: false,
  showRHSPeopleTiles: false,
  initializeSlideboardLayersOnPaper: false,
  liveClassLHSTabActiveKey: liveClassLHSTabKeys.presentationKey,
  showLiveClassSmartboardInstruction: true,
  isLiveClassActive: false,
  liveClassStartTime: null,
  liveClassInterval: null,
  liveClassTimeElapsed: 0,
  liveClassFilesState: {},
  micRequestedParticipantIdList: {},
  videoRequestedParticipantIdList: {},
  screenshareRequestedParticipantIdList: {},
  gridIconActive: false,
  layerGridState: {},
  studentLiveClassFullScreen: false,
  studentLiveClassWhiteboardData: null,
  studentLiveClassMiroWhiteboardData: {},
  studentVideoMinimized: false,
  chatShowingContextMenu: null,
  studentResizableLayoutDimension: { left: 0, right: 0 },
  showRateSessionPopup: false,
  showKickOutPopup: false,
  teacherClassroomStudentPresentationView: false,
  clickedEndSessionForAll: false,
  showJoinRequestPending: false,
  showRequestPending: false,
  showJoinRequestDenied: false,
  showRequestDenied: false,
  showRequestingVideo: false,
  showRequestingAudio: false,
  showRequestingScreenshare: false,
  showHandRaiseTimer: false,
  studentLiveClassLoader: false,
  showDeleteNotebookConfirmationPopup: false,
  showDeleteWhiteboardConfirmationPopup: false,
  showLoadMediaPopup: false,
  showDeleteFileConfirmationPopup: false,
  notebookDeleteLayerId: null,
  whiteboardDeleteLayerId: null,
  whiteboardSlideId: null,
  fileDeleteId: null,
  isStudentNotebookArchiveDataRecieved: false,
  youtubeVideoControls: null,
  emitYoutubeControlsOnUserJoined: false,
  connectionIssue: false,
  lectureId: 0,
  classId: 0,
  fileSystem: { currentClassId: null, currentLectureId: null },
  displayParticipantId: null,
  headerMessage: null,
  liveLecture: {}, // remove this when class reducer can store lectures of all the classes
  feedbackAggregateScore: null,
  showFeedbackInput: false,
  userPreviousFeedbackScore: null,
  isStudentYoutubeInitialized: false,
  lastViewedMessageId: null,
  unreadMessagesCount: null,
  showLinkConnectPopup: false,
  showDeviceConnectionRequestPopup: false,
  showLinkDeviceBroadcastPopup: false,
  deviceInfo: null,
  newParticipantsList: {},
  studentRequestsPopup: {
    showEntryRequestsPopup: false,
    showHandRaiseRequestsPopup: false,
  },
};

export default produce((state, action) => {
  switch (action.type) {
    case SET_LIVE_CLASS: {
      return {
        ...state,
        classId: action.payload,
      };
    }
    case SET_LIVE_LECTURE: {
      const lecture = action.payload;
      return {
        ...state,
        liveLecture: lecture, // remove this when class reducer can store lectures of all the classes
        lectureId: lecture.id,
        classId: lecture.class_id,
      };
    }
    case SET_LIVECLASS_LECTURE_ID: {
      return {
        ...state,
        lectureId: action.payload,
      };
    }
    case UPDATE_LIVECLASS_TOPIC: {
      // remove this case when class reducer can store lectures of all the classes
      const { topic } = action.payload;
      state.liveLecture = { ...state.liveLecture, topic };
      return;
    }
    case UPDATE_EVENT_STATUS: {
      const { eventId, status } = action.payload;
      const liveEvent = { ...state.liveEvents[eventId], status };
      return {
        ...state,
        liveEvents: {
          ...state.liveEvents,
          [eventId]: liveEvent,
        },
      };
    }
    case SET_HANDRAISE_REQUEST_ID: {
      return {
        ...state,
        handRaiseRequestId: action.payload,
      };
    }
    case SET_WHITEBOARD_ACTIVE: {
      return {
        ...state,
        isWhiteboardActive: action.payload,
      };
    }
    case SET_WHITEBOARD_DATA: {
      return {
        ...state,
        allWhiteboardData: action.payload,
      };
    }
    case SET_SUBMISSION_WHITEBOARD_ACTIVE: {
      return {
        ...state,
        isSubmissionWhiteboardActive: action.payload,
      };
    }
    case SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT: {
      return {
        ...state,
        submissionActiveCardDataObj: action.payload,
      };
    }
    case SET_SUBMISSION_WHITEBOARD_DATA: {
      return {
        ...state,
        submissionWhiteboardData: action.payload,
      };
    }
    case SET_LIVE_CLASS_WHITEBOARD_DATA: {
      return {
        ...state,
        liveclassWhiteboardData: action.payload,
      };
    }
    case SET_LIVE_VIDEO_STATE: {
      return {
        ...state,
        liveVideoState: action.payload,
      };
    }
    case SET_WHITEBOARD_ACTIVE_LAYER_ID: {
      return {
        ...state,
        whiteboardActiveLayerId: action.payload,
      };
    }
    case SET_BROADCASTED_ACTIVE_LAYER_ID: {
      return {
        ...state,
        broadcastedActiveLayerId: action.payload,
      };
    }
    case SET_PAPER_ACTIVE_LAYER_ID: {
      return {
        ...state,
        paperActiveLayerId: action.payload,
      };
    }
    case SET_SLIDEBOARD_LAYER_INTIALIZE_STATE: {
      return {
        ...state,
        initializeSlideboardLayersOnPaper: action.payload,
      };
    }
    case SET_SLIDEBOARD_ACTIVE_LAYER_ID: {
      return {
        ...state,
        slideboardActiveLayerId: action.payload,
      };
    }
    case SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID: {
      return {
        ...state,
        submissionboardActiveLayerId: action.payload,
      };
    }
    case SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO: {
      return {
        ...state,
        whiteboardActiveLayerBelongsTo: action.payload,
      };
    }
    case SET_NEW_WHITEBOARD_ID: {
      return {
        ...state,
        whiteboardIds: [...state.whiteboardIds, action.payload],
      };
    }
    case ADD_NEW_STUDENT_NOTEBOOK: {
      return {
        ...state,
        studentNotebookIds: [...state.studentNotebookIds, action.payload],
      };
    }
    case SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD: {
      return {
        ...state,
        showCopyMeetingLinkUiLayerOnWhiteboard: action.payload,
      };
    }
    case ADD_LIVECLASS_PEOPLE: {
      Object.assign(state.people, action.payload);
      return;
    }
    case ADD_PEOPLE: {
      const user = action.payload;
      return {
        ...state,
        people: {
          ...state.people,
          [user.id]: user,
        },
      };
    }
    case UPDATE_PEOPLE: {
      const { id, info } = action.payload;
      const people = { ...state.people };
      people[id] = { ...people[id], ...info };
      return {
        ...state,
        people,
      };
    }
    case REMOVE_PEOPLE: {
      const user = action.payload;
      let { people } = state;
      delete people[user.id];
      return {
        ...state,
        people,
      };
    }
    case ADD_REQUEST: {
      const request = action.payload;
      return {
        ...state,
        requests: {
          ...state.requests,
          [request.id]: request,
        },
      };
    }
    case REMOVE_REQUEST: {
      const requestId = action.payload;
      let requests = { ...state.requests };
      delete requests[requestId];
      return {
        ...state,
        requests,
      };
    }
    case REMOVE_REQUESTS_OF_USER: {
      const userId = action.payload;
      const requests = { ...state.requests };
      Object.keys(requests).forEach((reqId) => {
        if (requests[reqId].user_id === userId) {
          delete requests[reqId];
        }
      });
      return {
        ...state,
        requests,
      };
    }
    case ADD_MESSAGE: {
      const message = action.payload;
      return {
        ...state,
        messages: {
          ...state.messages,
          [message.id]: message,
        },
      };
    }
    case ADD_LIVE_EVENT_MESSAGE: {
      const message = action.payload;
      return {
        ...state,
        liveEvents: {
          ...state.liveEvents,
          [message.id]: message,
        },
      };
    }
    case ADD_CHAT_ARCHIVE_MESSAGES: {
      const archive = action.payload;
      const merged_messages = archive.reduce(
        (acc, msg) => ({ ...acc, [msg.id]: msg }),
        {}
      );
      return {
        ...state,
        messages: {
          ...state.messages,
          ...merged_messages,
        },
      };
    }
    case ADD_EVENT_ARCHIVE_MESSAGES: {
      const archive = action.payload;
      const merged_messages = archive.reduce(
        (acc, msg) => ({ ...acc, [msg.id]: msg }),
        {}
      );
      return {
        ...state,
        liveEvents: {
          ...state.liveEvents,
          ...merged_messages,
        },
      };
    }
    case ADD_EVENT_REQUEST_ARCHIVE_MESSAGES: {
      const archive = action.payload;
      const merged_messages = archive.reduce(
        (acc, msg) => ({ ...acc, [msg.id]: msg }),
        {}
      );
      return {
        ...state,
        requests: {
          ...state.requests,
          ...merged_messages,
        },
      };
    }
    case ADD_BUFFER_MESSAGE: {
      const { bufferId, message } = action.payload;
      return {
        ...state,
        buffer: {
          ...state.buffer,
          [bufferId]: { id: bufferId, message, is_chat: true },
        },
      };
    }
    case REMOVE_BUFFER_MESSAGE: {
      const bufferId = action.payload;
      const { [bufferId]: toDelete, ...restBuffer } = state.buffer;
      return {
        ...state,
        buffer: restBuffer,
      };
    }
    case UPDATE_BUFFER_MESSAGE: {
      const { bufferId, ...data } = action.payload;
      return {
        ...state,
        buffer: {
          ...state.buffer,
          [bufferId]: { ...state.buffer[bufferId], ...data },
        },
      };
    }
    case BUFFER_TO_MESSAGE: {
      const { messageInfo, bufferId } = action.payload;
      let buffer = { ...state.buffer };
      delete buffer[bufferId];
      return {
        ...state,
        buffer,
        messages: {
          ...state.messages,
          [messageInfo.id]: messageInfo,
        },
      };
    }
    case BUFFER_TO_EVENT: {
      const { messageInfo, bufferId } = action.payload;
      const { buffer } = state;
      delete buffer[bufferId];
      return {
        ...state,
        buffer,
        liveEvents: {
          ...state.liveEvents,
          [messageInfo.id]: messageInfo,
        },
      };
    }
    case ADD_HANDRAISE_ARCHIVE: {
      state.handRaises = new Set([...state.handRaises, ...action.payload]);
      return state;
    }
    case ADD_HANDRAISE: {
      state.handRaises.add(action.payload);
      return state;
    }
    case REMOVE_HANDRAISE: {
      state.handRaises.delete(action.payload);
      return state;
    }
    case SET_LIVE_CLASS_FILES_SELECTED_NONE: {
      return {
        ...state,
        isLiveClassFilesSelectedNone: action.payload,
      };
    }
    case SET_LARGE_PEOPLE_TAB_ACTIVE: {
      return {
        ...state,
        isLargePeopleTabActive: action.payload,
      };
    }
    case SET_SHOW_RHS_PEOPLE_TAB: {
      return {
        ...state,
        showRHSPeopleTiles: action.payload,
      };
    }
    case SET_LIVE_CLASS_FILES_SELECTED: {
      return {
        ...state,
        liveClassFilesSelected: action.payload,
      };
    }
    case SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY: {
      return {
        ...state,
        liveClassLHSTabActiveKey: action.payload,
      };
    }
    case SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION: {
      return {
        ...state,
        showLiveClassSmartboardInstruction: action.payload,
      };
    }
    case SET_LIVE_CLASS_ACTIVE: {
      return {
        ...state,
        isLiveClassActive: true,
        classId: action.payload,
      };
    }
    case SET_LIVE_CLASS_TIME_ELAPSED: {
      return {
        ...state,
        liveClassTimeElapsed: action.payload,
      };
    }
    case SET_LIVE_CLASS_TIMER_DETAILS: {
      const { liveClassStartTime, liveClassInterval } = action.payload;
      return {
        ...state,
        liveClassStartTime,
        liveClassInterval,
      };
    }
    case SET_LIVE_CLASS_FILES_STATE: {
      return {
        ...state,
        liveClassFilesState: {
          ...action.payload,
        },
      };
    }
    case SET_GRID_ICON_ACTIVE: {
      return {
        ...state,
        gridIconActive: action.payload,
      };
    }
    case ADD_SCREENSHARE_REQUEST: {
      state.screenshareRequestedParticipantIdList[
        action.payload.participantId
      ] = action.payload.requestObject;
      return;
    }
    case REMOVE_SCREENSHARE_REQUEST: {
      state.screenshareRequestedParticipantIdList[action.payload] = false;
      return;
    }
    case REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID: {
      state.screenshareRequestedParticipantIdList = Object.keys(
        state.screenshareRequestedParticipantIdList
      )
        .filter(
          (participantId) =>
            state.screenshareRequestedParticipantIdList[participantId].id !==
            action.payload
        )
        .reduce(
          (acc, participantId) => ({
            ...acc,
            [participantId]:
              state.screenshareRequestedParticipantIdList[participantId],
          }),
          {}
        );
      return;
    }
    case RESET_SCREENSHARE_REQUEST_LIST: {
      state.screenshareRequestedParticipantIdList = {};
      return;
    }
    case ADD_MIC_REQUEST: {
      return {
        ...state,
        micRequestedParticipantIdList: {
          ...state.micRequestedParticipantIdList,
          [action.payload.participantId]: action.payload.requestObject,
        },
      };
    }
    case REMOVE_MIC_REQUEST: {
      return {
        ...state,
        micRequestedParticipantIdList: {
          ...state.micRequestedParticipantIdList,
          [action.payload]: false,
        },
      };
    }
    case REMOVE_MIC_REQUEST_FROM_REQUEST_ID: {
      return {
        ...state,
        micRequestedParticipantIdList: Object.keys(
          state.micRequestedParticipantIdList
        )
          .filter(
            (participantId) =>
              state.micRequestedParticipantIdList[participantId].id !==
              action.payload
          )
          .reduce(
            (acc, participantId) => ({
              ...acc,
              [participantId]:
                state.micRequestedParticipantIdList[participantId],
            }),
            {}
          ),
      };
    }
    case RESET_MIC_REQUEST_LIST: {
      return {
        ...state,
        micRequestedParticipantIdList: {},
      };
    }
    case ADD_VIDEO_REQUEST: {
      return {
        ...state,
        videoRequestedParticipantIdList: {
          ...state.videoRequestedParticipantIdList,
          [action.payload.participantId]: action.payload.requestObject,
        },
      };
    }
    case REMOVE_VIDEO_REQUEST: {
      return {
        ...state,
        videoRequestedParticipantIdList: {
          ...state.videoRequestedParticipantIdList,
          [action.payload]: false,
        },
      };
    }
    case REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID: {
      return {
        ...state,
        videoRequestedParticipantIdList: Object.keys(
          state.videoRequestedParticipantIdList
        )
          .filter(
            (participantId) =>
              state.videoRequestedParticipantIdList[participantId].id !==
              action.payload
          )
          .reduce(
            (acc, participantId) => ({
              ...acc,
              [participantId]:
                state.videoRequestedParticipantIdList[participantId],
            }),
            {}
          ),
      };
    }
    case SET_STUDENT_LIVE_CLASS_FULL_SCREEN: {
      return {
        ...state,
        studentLiveClassFullScreen: action.payload,
      };
    }
    case SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA: {
      return {
        ...state,
        studentLiveClassWhiteboardData: {
          ...action.payload,
        },
      };
    }
    case SET_STUDENT_LIVE_CLASS_MIRO_WHITEBOARD_DATA: {
      return {
        ...state,
        studentLiveClassMiroWhiteboardData: action.payload,
      };
    }
    case SET_STUDENT_VIDEO_MINIMIZED: {
      return {
        ...state,
        studentVideoMinimized: action.payload,
      };
    }
    case SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION: {
      return {
        ...state,
        studentResizableLayoutDimension: action.payload,
      };
    }
    case RESET_VIDEO_REQUEST_LIST: {
      return {
        ...state,
        videoRequestedParticipantIdList: {},
      };
    }
    case RESET_LIVECLASS: {
      if (state.liveClassInterval) {
        clearInterval(state.liveClassInterval);
      }

      return initialState;
    }
    case CLEAR_LIVECLASS_DETAILS: {
      if (state.liveClassInterval) {
        clearInterval(state.liveClassInterval);
      }

      return initialState;
    }
    case SET_CHAT_SHOWING_CONTEXT_MENU: {
      return {
        ...state,
        chatShowingContextMenu: action.payload,
      };
    }
    case SET_SHOW_RATE_SESSION_POPUP: {
      return {
        ...state,
        showRateSessionPopup: action.payload,
      };
    }
    case SET_SHOW_KICK_OUT_POPUP: {
      return {
        ...state,
        showKickOutPopup: action.payload,
      };
    }
    case SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW: {
      return {
        ...state,
        teacherClassroomStudentPresentationView: action.payload,
      };
    }
    case SET_CLICKED_END_SESSION_FOR_ALL: {
      return {
        ...state,
        clickedEndSessionForAll: true,
      };
    }
    case SET_SHOW_JOIN_REQUEST_PENDING: {
      return {
        ...state,
        showJoinRequestPending: action.payload,
      };
    }
    case SET_SHOW_JOIN_REQUEST_DENIED: {
      return {
        ...state,
        showJoinRequestDenied: action.payload,
      };
    }
    case SET_SHOW_REQUEST_PENDING: {
      return {
        ...state,
        showRequestPending: action.payload,
      };
    }
    case SET_SHOW_REQUEST_DENIED: {
      return {
        ...state,
        showRequestDenied: action.payload,
      };
    }
    case SET_SHOW_REQUESTING_VIDEO: {
      return {
        ...state,
        showRequestingVideo: action.payload,
      };
    }
    case SET_SHOW_REQUESTING_AUDIO: {
      return {
        ...state,
        showRequestingAudio: action.payload,
      };
    }
    case SET_SHOW_REQUESTING_SCREENSHARE: {
      return {
        ...state,
        showRequestingScreenshare: action.payload,
      };
    }
    case SET_SHOW_HAND_RAISE_TIMER: {
      return {
        ...state,
        showHandRaiseTimer: action.payload,
      };
    }
    case SET_STUDENT_LIVE_CLASS_LOADER: {
      return {
        ...state,
        studentLiveClassLoader: action.payload,
      };
    }
    case SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP: {
      return {
        ...state,
        showDeleteNotebookConfirmationPopup: action.payload,
      };
    }
    case SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP: {
      return {
        ...state,
        showDeleteWhiteboardConfirmationPopup: action.payload,
      };
    }
    case SET_SHOW_LOAD_MEDIA_POPUP: {
      return {
        ...state,
        showLoadMediaPopup: action.payload,
      };
    }
    case SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP: {
      return {
        ...state,
        showDeleteFileConfirmationPopup: action.payload,
      };
    }
    case SET_NOTEBOOK_DELETE_LAYER_ID: {
      return {
        ...state,
        notebookDeleteLayerId: action.payload,
      };
    }
    case SET_WHITEBOARD_DELETE_LAYER_ID: {
      return {
        ...state,
        whiteboardDeleteLayerId: action.payload,
      };
    }
    case SET_WHITEBOARD_SLIDE_ID: {
      return {
        ...state,
        whiteboardSlideId: action.payload,
      };
    }
    case SET_FILE_DELETE_ID: {
      return {
        ...state,
        fileDeleteId: action.payload,
      };
    }
    case UPDATE_STUDENT_NOTEBOOK_IDS: {
      return {
        ...state,
        studentNotebookIds: action.payload,
      };
    }
    case UPDATE_WHITEBOARD_IDS: {
      return {
        ...state,
        whiteboardIds: action.payload,
      };
    }
    case SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID: {
      return {
        ...state,
        studentNotebookActiveLayerId: action.payload,
      };
    }
    case SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED: {
      return {
        ...state,
        isStudentNotebookArchiveDataRecieved: action.payload,
      };
    }
    case UPDATE_PEOPLE_DETAILS: {
      const { id: userId, name: userName } = action.payload;
      state.people[userId].name = userName;
      return;
    }
    case SET_YOUTUBE_VIDEO_SHARE_CONTROLS: {
      return {
        ...state,
        youtubeVideoControls: action.payload,
      };
    }
    case SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED: {
      return {
        ...state,
        emitYoutubeControlsOnUserJoined: action.payload,
      };
    }
    case SET_LIVE_CLASS_FILESYSTEM_IDS: {
      Object.keys(action.payload).forEach((key) => {
        state.fileSystem[key] = action.payload[key];
      });
      return;
    }
    case SET_DISPLAY_PARTICIPANT_ID: {
      state.displayParticipantId = action.payload;
      return;
    }
    case SET_NOTIFICATION_HEADER: {
      state.headerMessage = action.payload;
      return;
    }
    case SET_CONNECTION_ISSUE: {
      state.connectionIssue = action.payload;
      return;
    }
    case SET_FEEDBACK_AGGREGATE_SCORE: {
      state.feedbackAggregateScore = action.payload;
      return;
    }
    case SET_SHOW_FEEDBACK_INPUT: {
      state.showFeedbackInput = action.payload;
      return;
    }
    case SET_USER_PREVIOUS_FEEDBACK_SCORE: {
      state.userPreviousFeedbackScore = action.payload;
      return;
    }
    case SET_STUDENT_YOUTUBE_INITIALIZED: {
      state.isStudentYoutubeInitialized = action.payload;
      return;
    }
    case SET_LIVECLASS_LAST_VIEWED_MESSAGE: {
      state.lastViewedMessageId = action.payload;
      return;
    }
    case SET_LIVECLASS_UNREAD_MESSAGES: {
      state.unreadMessagesCount = action.payload;
      return;
    }
    case SET_SHOW_LINK_CONNECT_POPUP: {
      state.showLinkConnectPopup = action.payload;
      return;
    }
    case SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP: {
      state.showDeviceConnectionRequestPopup = action.payload;
      return;
    }
    case SET_SHOW_LINK_DEVICE_BROADCAST_POPUP: {
      state.showLinkDeviceBroadcastPopup = action.payload;
      return;
    }
    case SET_DEVICE_INFO: {
      state.deviceInfo = action.payload;
      return;
    }
    case SET_NEW_PARTICIPANTS_LIST: {
      state.newParticipantsList = action.payload;
      return;
    }
    case SET_LAYER_GRID_STATE: {
      state.layerGridState = action.payload;
      return;
    }
    case SET_STUDENTS_REQUESTS_POPUP: {
      const { requestCode, popupState } = action.payload;
      if (requestCode === 2) {
        state.studentRequestsPopup.showEntryRequestsPopup = popupState;
      } else if (requestCode === 3) {
        state.studentRequestsPopup.showHandRaiseRequestsPopup = popupState;
      }

      return;
    }
    default: {
      return state;
    }
  }
}, initialState);
