import socketIOClient from "socket.io-client";
import instantLectureUtils from "../components/InstantLectureModule/InstantLectureUtils";

import store from "../store";
import { setConnectionIssue } from "../store/actions/liveClassActions";
import { setConnectionErrorType } from "../store/actions/miscellaneousActions";
import {
  setSocketObject,
  setSocketConnection,
  setServerTime,
} from "../store/actions/socketActions";
import { logger } from "./logging";
import { SERVER_TIME } from "./socketEvents";
import { connectionError } from "./tinyUtils";

const MAX_RECONNECT_ATTEMPT = 3;

export const getSocket = (endpoint, sessionId, forceReConnect = false) => {
  console.log("getSocket");
  const state = store.getState();

  /** If the socket is present & if forceReConnect is true
   * forcefully call the socket.connect() again
   */
  if (state.socket.socket || forceReConnect) {
    if (!state.socket.socket.connected || forceReConnect) {
      state.socket.socket.connect();
    }
    console.log("state.socket", state.socket.socket);
    return state.socket.socket;
  }

  if (!endpoint) {
    endpoint = store.getState().secrets.microservices.rtcUrl;
  }
  if (!sessionId) {
    if (instantLectureUtils().is().currentPageBelongsToInstantClassroom()) {
      sessionId = store.getState().user.iSessionId;
    } else {
      sessionId = store.getState().user.sessionId;
    }
  }

  if (!endpoint || !sessionId) {
    console.log("Missing sessionId or endpoint to connect socket");
    return;
  }

  console.log({ sessionId });
  console.log({ endpoint });

  const options = {
    forceNew: true,
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${sessionId}`,
        },
      },
    },
  };

  const socket = socketIOClient(endpoint, options);

  socket.on("connect", () => {
    console.log(`${socket.id} connected`);
    console.log(`>> CONNECTED: ${socket.connected} <<`);
    logger.info("SOCKET:CONNECT", `socket connected`, { socketId: socket.id });
    store.dispatch(setSocketConnection(true));
    store.dispatch(setConnectionIssue(false));
    /**
     * if socket & jitsi both got disconnected, and then socket got reconnected
     * but jitsi is not, then update the errorType to "jitsi"
     * else just hide the connection error popup
     */
    const {
      misc: { connectionErrorType },
      jitsi,
    } = store.getState();
    if (
      connectionErrorType === connectionError.socketAndJitsi &&
      jitsi.connectionDropped
    ) {
      store.dispatch(setConnectionErrorType(connectionError.jitsi));
    } else {
      store.dispatch(setConnectionErrorType(null));
    }
  });

  socket.on(SERVER_TIME, (time) => {
    console.log(">>SERVER_TIME", time);
    store.dispatch(setServerTime(time));
  });

  socket.on("disconnect", (reason) => {
    console.log(`${socket.id} disconnected`);
    console.log(reason);
    store.dispatch(setSocketConnection(false));
    logger.info("SOCKET:DISCONNECT", reason, { socketId: socket.id });
  });

  socket.on("connect_error", (error) => {
    // TODO: Notify user
    console.log(`${socket.id} connect error`);
    console.log(error);
    logger.info("SOCKET:CONNECT_ERROR", `socket connect error`, {
      socketId: socket.id,
      error,
    });
  });

  socket.on("error", (data) => {
    // TODO: Notify user
    console.log(`${socket.id} connect error`);
    console.log(data);
    store.dispatch(setSocketConnection(false));
    logger.info("SOCKET:ERROR", `socket error`, {
      socketId: socket.id,
      data,
    });
  });

  console.log("socket", socket);
  store.dispatch(setSocketObject(socket));
  socket.on("reconnect_attempt", (attempt) => {
    logger.info("SOCKET:RECONNECT_ATTEMPT", `attempt number ${attempt}`, {
      socketId: socket.id,
      MAX_RECONNECT_ATTEMPT,
    });
    if (attempt > MAX_RECONNECT_ATTEMPT) {
      logger.info("SOCKET:CONNECTION_ISSUE", `attempt number ${attempt}`, {
        socketId: socket.id,
        MAX_RECONNECT_ATTEMPT,
      });
      store.dispatch(setConnectionIssue(true));
    }

    // assuming each attempt takes place in a 3sec of interval
    if (attempt === 15) {
      const {
        misc: { connectionErrorType },
      } = store.getState();
      /**
       * if jitsi is already disconnected and then socket gets disconnected, then
       * set the connectionErrorType should be both "socketAndJitsi" else "socket"
       */
      if (connectionErrorType === connectionError.jitsi) {
        store.dispatch(setConnectionErrorType(connectionError.socketAndJitsi));
      } else {
        store.dispatch(setConnectionErrorType(connectionError.socket));
      }
    }
  });

  return socket;
};

export const emit = (event, data) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    if (!socket) {
      reject({ message: "No socket connection." });
    } else {
      socket.emit(event, data, (success, message) => {
        if (!success) {
          console.error(message);
          reject(message);
        } else {
          resolve(success);
        }
      });
    }
  });
};
