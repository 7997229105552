import { getSocket } from "../socketio";
import {
  CLASS_IS_LIVE,
  LIVE_CLASS_ENDED,
  ADD_CLASS,
  REMOVE_CLASS,
  NEW_INVITED_USERS,
  REMOVE_INVITATION,
  NEW_INVITATION,
  REMOVE_CLASS_INVITATION,
  REMOVE_USER_INVITES,
  UPDATE_USER_DETAILS,
  NEW_INSTITUTE_REQUEST,
  REMOVE_INSTITUTE_INVITATION,
  ACCEPT_INSTITUTE_INVITATION,
  REMOVE_LICENSE,
  TEACHER_INSTITUTE_INVITATION,
  REMOVE_INSTITUTE_REQUEST,
  REMOVE_INSTITUTE_TEACHER,
  ACCEPT_INSTITUTE_REQUEST,
  JOIN_INSTITUTE,
  ASSIGN_LICENSE,
  NEW_INSTITUTE_INVITATIONS,
} from "../socketEvents";
import {
  onClassIsLive,
  onLiveClassEnded,
  onAddClass,
  onRemoveClass,
  onNewInvites,
  onRemoveInvite,
  onNewInvitation,
  onRemoveClassInvitation,
  onRemoveUserInvites,
  onUpdateUserDetails,
  onNewInstituteRequest,
  onRemoveInstituteInvitation,
  onAcceptInstituteInvitation,
  onTeacherInstituteInvitation,
  onRemoveInstituteRequest,
  onRemoveInstituteTeacher,
  onAcceptInstituteRequest,
  onJoinInstitute,
  onRemoveLicense,
  onAssignLicense,
  onNewInstituteInvitations,
} from "./handlers";

const eventListeners = [
  { type: CLASS_IS_LIVE, handler: onClassIsLive },
  { type: LIVE_CLASS_ENDED, handler: onLiveClassEnded },
  { type: ADD_CLASS, handler: onAddClass },
  { type: REMOVE_CLASS, handler: onRemoveClass },
  { type: NEW_INVITED_USERS, handler: onNewInvites },
  { type: REMOVE_INVITATION, handler: onRemoveInvite },
  { type: NEW_INVITATION, handler: onNewInvitation },
  { type: REMOVE_CLASS_INVITATION, handler: onRemoveClassInvitation },
  { type: REMOVE_USER_INVITES, handler: onRemoveUserInvites },
  { type: UPDATE_USER_DETAILS, handler: onUpdateUserDetails },
  { type: NEW_INSTITUTE_REQUEST, handler: onNewInstituteRequest },
  { type: REMOVE_INSTITUTE_INVITATION, handler: onRemoveInstituteInvitation },
  { type: ACCEPT_INSTITUTE_INVITATION, handler: onAcceptInstituteInvitation },
  { type: TEACHER_INSTITUTE_INVITATION, handler: onTeacherInstituteInvitation },
  { type: REMOVE_INSTITUTE_REQUEST, handler: onRemoveInstituteRequest },
  { type: REMOVE_INSTITUTE_TEACHER, handler: onRemoveInstituteTeacher },
  { type: ACCEPT_INSTITUTE_REQUEST, handler: onAcceptInstituteRequest },
  { type: JOIN_INSTITUTE, handler: onJoinInstitute },
  { type: REMOVE_LICENSE, handler: onRemoveLicense },
  { type: ASSIGN_LICENSE, handler: onAssignLicense },
  { type: NEW_INSTITUTE_INVITATIONS, handler: onNewInstituteInvitations },
];

export const addSocketListeners = () => {
  const socket = getSocket();
  if (!socket) {
    return;
  }
  eventListeners.forEach((event) => socket.on(event.type, event.handler));
};

export const addSocketListenersForced = () => {
  const socket = getSocket(null, null, true);
  if (!socket) {
    return;
  }
  eventListeners.forEach((event) => socket.on(event.type, event.handler));
};

export const removeSocketListeners = () => {
  const socket = getSocket();
  if (!socket) {
    return;
  }
  eventListeners.forEach((event) => socket.off(event.type, event.handler));
};
