import React, { Component } from "react";
import { connect } from "react-redux";
import db from "mime-db";
import Cross from "../assets/cross.svg";
import EllipsisText from "../../EllipsisText/EllipsisText";
import "./DetailsPopupStyles.css";
import { closeShowDetailsPopup } from "../../../store/actions/fileOptions";
import {
  convertBytesFormat,
  parseDate,
  getSplitName,
  getTeachersList,
  getFolderNameList,
  getCompleteLocation,
} from "../../../utils/fileSystem";
import { screenNames } from "../../AppRouter/routes";
import { SLIDE_SHARE_STATE } from "../../../utils/constants";
import { checkIfBreadCrumbTextOverflowParentBounds } from "../../../store/actions/utils";
class DetailsPopup extends Component {
  getLocation = (location) => {
    let slashIndex = location.search("/");
    let newLocation = location.slice(slashIndex + 1);
    const locationPath = location?.startsWith(".user")
      ? "My Files/" + newLocation
      : "Class Files/" + location;
    return locationPath;
  };
  render() {
    const { detailsElementId, fileSystemData, location } = this.props;

    const elementData = fileSystemData[detailsElementId];

    const readableFileType =
      elementData.kind === "folder"
        ? "Folder"
        : db[elementData.file_mime_type]?.extensions[0];

    const createdDate = parseDate(elementData.created_at);
    const modifiedDate = parseDate(elementData.updated_at);

    const createdTeacherId = fileSystemData[detailsElementId]?.created_by;
    const modifiedTeacherId = fileSystemData[detailsElementId]?.updated_by;

    const createdBy =
      this.props.userId.toString().trim() === elementData?.created_by
        ? "Me"
        : fileSystemData[detailsElementId]?.file_location_type ===
          "shared_files"
        ? fileSystemData[detailsElementId]?.created_by?.name
        : getTeachersList(createdTeacherId);
    const modifiedBy =
      this.props.userId.toString().trim() === elementData.updated_by
        ? "Me"
        : fileSystemData[detailsElementId]?.file_location_type ===
          "shared_files"
        ? fileSystemData[detailsElementId]?.updated_by?.name
        : getTeachersList(modifiedTeacherId);

    const filename = elementData.name;
    const fileSize = convertBytesFormat(elementData.file_size);
    const fileLocation = elementData.file_path;
    const fileCreatedBy = createdBy;
    const fileModifiedBy = modifiedBy;
    const fileType = readableFileType;
    const fileCreatedDate = createdDate;
    const fileModifiedDate = modifiedDate;
    const preLocation = "https://drive.linkclassroom.com/";
    const filePathLocation = elementData.file_location;
    const { folderNameList, overflow, overflowWithEllipsis, breadCrumbText } =
      location.pathname !== "/dashboard" &&
      location.pathname !== "/trash" &&
      location.pathname !== "/student/trash" &&
      getFolderNameList(this.props.prefix);

    return (
      <div className=" popup__container__wrapper">
        <div className="  details__popup__container">
          <div className="popup__header_details">
            <p className="popup__head">{"Details"}</p>

            <img
              onClick={this.props.closeShowDetailsPopup}
              className="details__popup__close__icon"
              src={Cross}
              alt=""
            />
          </div>
          <div className="details__popup__popup__line"></div>
          <div className="popup__row details__popup__file__name">
            <EllipsisText numberOfLines={1}>{filename}</EllipsisText>
          </div>
          <div className="details__popup__popup__line"></div>
          <div className="details__popup__content">
            <div className=" details__popup__row">
              <div className="details__popup__content__LHS">Type</div>
              <div className="details__popup__content__RHS">
                <EllipsisText numberOfLines={1}>{fileType}</EllipsisText>
              </div>
            </div>
            <div className=" details__popup__row">
              {fileType !== "Folder" && (
                <div className="details__popup__content__LHS">Size</div>
              )}
              {fileType !== "Folder" && (
                <div className="details__popup__content__RHS">
                  <EllipsisText numberOfLines={1}>{fileSize}</EllipsisText>
                </div>
              )}
            </div>
            <div className=" details__popup__row">
              <div className="details__popup__content__LHS">Created</div>
              <div className="details__popup__content__RHS">
                <EllipsisText numberOfLines={1}>
                  {`${fileCreatedDate} by ${fileCreatedBy}`}
                </EllipsisText>
              </div>
            </div>
            <div className=" details__popup__row">
              <div className="details__popup__content__LHS">Modified</div>
              <div className="details__popup__content__RHS">
                <EllipsisText numberOfLines={1}>
                  {`${fileModifiedDate} by ${fileModifiedBy}`}
                </EllipsisText>
              </div>
            </div>
            <div className=" details__popup__row">
              <div className="details__popup__content__LHS">Location</div>
              {location.pathname !== "/dashboard" &&
              location.pathname !== "/trash" &&
              location.pathname !== "/student/trash" ? (
                <div
                  title={getCompleteLocation(folderNameList)}
                  className="details__popup__content__RHS  location_ellipsis_after_two_lines"
                >
                  {/* {preLocation} */}
                  {getCompleteLocation(folderNameList)}
                </div>
              ) : (
                <div
                  title={this.getLocation(filePathLocation)}
                  className="details__popup__content__RHS  location_ellipsis_after_two_lines"
                >
                  {/* {preLocation} */}
                  {this.getLocation(filePathLocation)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, klass, fileOptions, fileSystem }) => ({
  detailsElementId: fileOptions.detailsElementId,
  fileSystemData: fileSystem.fileSystemData,
  currentFolderId: fileSystem.currentFolderId,
  teachers: klass.teachers,
  userId: user.id,
});

const mapDispatchToProps = {
  closeShowDetailsPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPopup);
