import React, { Component } from "react";
import { connect } from "react-redux";

import "./StudentLiveClassPopups.css";
import { startDesktopSharing } from "../../store/actions/jitsiActions";
import {
  acceptScreenshareRequest,
  rejectScreenshareRequest,
} from "../../store/actions/socketActions";
import { setShowRequestingScreenshare } from "../../store/actions/liveClassActions";
import { isNil } from "lodash";
import { getParticipantByUserId } from "../../utils/jitsi";

class ScreenShareRequestPopup extends Component {
  acceptRequest = () => {
    this.props.acceptScreenshareRequest(this.props.showRequestingScreenshare);
  };

  rejectRequest = () => {
    this.props.rejectScreenshareRequest(this.props.showRequestingScreenshare);
  };

  render() {
    const { screenshareRequestedParticipant } = this.props;
    let requestingTeacherParticipant;
    if (!isNil(screenshareRequestedParticipant)) {
      requestingTeacherParticipant = getParticipantByUserId(
        screenshareRequestedParticipant.requesting_teacher_id
      );
    }
    return (
      <div className="student_live_class_popup_background">
        <div className="student_live_class_popup_container screen_share_request_popup_container">
          <p className="student_live_class_popup_title_font screen_share_request_popup_title">
            Screen Share request
          </p>
          <p className="student_live_class_popup_text_font">
            {`Teacher ${
              requestingTeacherParticipant?.userName
                ? requestingTeacherParticipant.userName
                : ""
            } has requested to share your \nscreen.`}
          </p>
          <div className="student_live_class_popup_bottom_button_container">
            <div
              onClick={this.rejectRequest}
              className="student_live_class_popup_button student_live_class_popup_button_reject screen_share_request_popup_button screen_share_request_popup_button_reject"
            >
              REJECT
            </div>
            <div
              onClick={this.acceptRequest}
              className="student_live_class_popup_button student_live_class_popup_button_accept screen_share_request_popup_button screen_share_request_popup_button_accept"
            >
              SHARE
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ liveClass, klass, jitsi }) => ({
  studentLiveClassFullScreen: liveClass.studentLiveClassFullScreen,
  currentClassSettings: klass.currentClassSettings,
  showRequestingScreenshare: liveClass.showRequestingScreenshare,
  screenshareRequestedParticipant:
    liveClass.screenshareRequestedParticipantIdList[jitsi.myParticipantId],
});

const mapDispatchToProps = {
  startDesktopSharing,
  setShowRequestingScreenshare,
  acceptScreenshareRequest,
  rejectScreenshareRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenShareRequestPopup);
