import { assign } from "lodash";
import mixpanel from "mixpanel-browser";
export * from "./types";

export const initTracking = () => {
  const projectToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(projectToken, {
    api_host: process.env.REACT_APP_MIXPANEL_PROXY_URL
  });
  console.log("initTracking", projectToken.substring(0, 6));
  // mixpanel.init(projectToken);
};

/**
 * @param {number} userId
 * @param {object} properties extra params to associate with user profile e.g. name, email etc.
 *
 * creates a user profile in the mixpanel, any further events triggered by this user
 * will be linked to it's user profile.
 * NOTE: user profile creation event should be triggerd once per reload.
 */
export const createUserTrakingProfile = (userId, properties = {}) => {
  mixpanel.identify(userId);
  /**
   * $email & $avatar are few of the reserved properties that mix-panel uses
   * to construct the user table
   */
  const obj = assign(properties, {
    $email: properties.email,
    $avatar: properties.profilePhotoUrl
  });
  mixpanel.people.set(obj);
};

/**
 *
 * @param {string} eventName
 * @param {object} properties extra params to support that event, e.g. classId, lecture obj etc.
 */
export const trackEvent = (eventName, properties = {}) => {
  mixpanel.track(eventName, {
    ...properties,
    env: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });
};
