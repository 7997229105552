import {
  PUSH_TO_COURSEPLANNER_CONTENT,
  PUSH_TO_COURSEPLANNER_UNCAT_CONTENT,
  SET_COURSEPLANNER_LIST,
  SET_COURSEPLANNER_ORDERING,
  SET_COURSEPLANNER_UNCAT_LIST,
  SET_PREVIEW_DETAILS,
  SET_UNCAT_LIST_ORDERING,
  SET_UNCAT_LIVE_LECTURES,
} from "../types";

const initialState = {
  previewSection: {
    enabled: false,
  },
  coursePlannerList: {},
  ordering: [],
  uncategorizedContent: [],
  uncategorizedLiveLectures: [],
  uncategorizedListOrdering: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PREVIEW_DETAILS: {
      return {
        ...state,
        previewSection: { ...action.payload },
      };
    }

    case SET_COURSEPLANNER_LIST: {
      return {
        ...state,
        coursePlannerList: { ...action.payload },
      };
    }

    case SET_COURSEPLANNER_UNCAT_LIST: {
      return {
        ...state,
        uncategorizedContent: action.payload,
      };
    }

    case SET_UNCAT_LIVE_LECTURES: {
      return {
        ...state,
        uncategorizedLiveLectures: action.payload,
      };
    }
    case SET_UNCAT_LIST_ORDERING: {
      return {
        ...state,
        uncategorizedListOrdering: action.payload,
      };
    }
    case SET_COURSEPLANNER_ORDERING: {
      const topic_id = action.payload.topic_id;
      const internal_ordering = action.payload.internal_ordering;

      if (topic_id === -1) {
        return {
          ...state,
          uncategorizedListOrdering: internal_ordering,
        };
      }

      return {
        ...state,
        coursePlannerList: {
          ...state.coursePlannerList,
          [topic_id]: {
            ...state.coursePlannerList[topic_id],
            ordering: internal_ordering,
          },
        },
      };
    }
    case PUSH_TO_COURSEPLANNER_CONTENT: {
      const topic_id = action.payload.topic_id;
      const obj = action.payload.obj;

      return {
        ...state,
        coursePlannerList: {
          ...state.coursePlannerList,
          [topic_id]: {
            ...state.coursePlannerList[topic_id],
            content: [...state.coursePlannerList[topic_id].content, obj],
          },
        },
      };
    }
    case PUSH_TO_COURSEPLANNER_UNCAT_CONTENT: {
      const obj = action.payload.obj;

      return {
        ...state,
        uncategorizedContent: [...state.uncategorizedContent, obj],
      };
    }

    default: {
      return state;
    }
  }
};
