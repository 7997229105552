import {
  RESET_LIVE_QUIZ_DATA,
  SET_CREATE_QUESTION_POPUP_FILES_DATA,
  SET_CREATE_QUESTION_POPUP_INITIAL_DATA,
  SET_QUICK_QUESTION_LIST,
  SET_QUICK_QUESTION_OPTION_ONCHANGE,
  SET_QUICK_QUESTION_TEXT_ONCHANGE,
  SET_STOP_LEADERBOARD_SHARING_POPUP,
  SET_STUDENT_LIVE_QUESTION_POPUP_DATA,
  SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA,
  SET_UNATTEMPTED_USERS,
} from "../types";
// const { default: produce } = require("immer");
import produce from "immer";
import {
  SET_LIVE_QUIZ_QUESTION_DETAILS,
  SET_LIVE_RESULT_POPUP_DATA,
  SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA,
  SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA,
  SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA,
} from "../types";

/**
 * @typedef {object} state
 * @property {{isVisible: boolean, type: "MULTI_CHOICE" | "TRUE_FALSE" | "POLL" ,question_text: string, options: Array<{is_correct: boolean, text: string}}} createQuestionPopupData
 * @property {{isVisible: boolean, activeTabIndex: number, stage: "LIVE" | "ENDED" | "RESULT_PUBLISHED"}} liveResultPopupData
 */

/**
 * @type {state}
 */
const initialResettableState = {
  createQuestionPopupData: {
    isVisible: false,
  },
  teacherLeaderboard: {},
  studentLeaderboard: {},
  questionDetails: {},
  liveResultPopupData: {
    activeTabIndex: 1,
    isVisible: false,
    stage: "LIVE",
  },
  studentLiveQuestionPopup: {
    isVisible: false,
    selectedOptionIndex: null,
  },
  studentLiveQuestionResponseRecordedPopup: {
    isVisible: false,
    timeTaken: 0,
  },
  unattemptedUsers: [],
  showStopLeaderboardSharingPopup: false,
};

const initialState = {
  studentPerformanceData: {
    average_time: null,
    accuracy_percentage: null,
    option_wise_response_count: {},
    total_response_count: null,
    total_student_count: null,
    correct_response_count: null,
    submissions: [],
    options: [],
    question: {},
  },
  quickQuestions: {},
};

const liveQuizReducer = produce(
  (state, action) => {
    switch (action.type) {
      case SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA:
        state.teacherLeaderboard = action.payload;
        break;
      case SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA:
        state.studentLeaderboard = action.payload;
        break;
      case SET_LIVE_QUIZ_QUESTION_DETAILS:
        state.questionDetails = action.payload;
        break;
      case SET_LIVE_RESULT_POPUP_DATA:
        state.liveResultPopupData = {
          ...state.liveResultPopupData,
          ...action.payload,
        };
        break;
      case SET_STUDENT_LIVE_QUESTION_POPUP_DATA:
        state.studentLiveQuestionPopup = {
          ...state.studentLiveQuestionPopup,
          ...action.payload,
        };
        break;

      case SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA:
        state.studentLiveQuestionResponseRecordedPopup = {
          ...state.studentLiveQuestionResponseRecordedPopup,
          ...action.payload,
        };
        break;

      case SET_CREATE_QUESTION_POPUP_INITIAL_DATA:
        state.createQuestionPopupData = {
          ...action.payload,
          isVisible: !state.createQuestionPopupData.isVisible,
        };
        break;
      case SET_CREATE_QUESTION_POPUP_FILES_DATA:
        state.createQuestionPopupData = {
          ...state.createQuestionPopupData,
          ...action.payload,
        };
        break;
      case SET_QUICK_QUESTION_TEXT_ONCHANGE:
        state.createQuestionPopupData = {
          ...state.createQuestionPopupData,
          question_text: action.payload,
        };
        break;
      case SET_QUICK_QUESTION_OPTION_ONCHANGE:
        state.createQuestionPopupData = {
          ...state.createQuestionPopupData,
          options: action.payload,
        };
        break;
      case SET_QUICK_QUESTION_LIST: {
        state.quickQuestions = action.payload;
        return;
      }
      case SET_STOP_LEADERBOARD_SHARING_POPUP: {
        state.showStopLeaderboardSharingPopup = action.payload;
        return;
      }
      case SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA:
        state.studentPerformanceData = {
          ...action.payload,
        };
        break;
      case SET_UNATTEMPTED_USERS:
        state.unattemptedUsers = action.payload;
        break;
      case RESET_LIVE_QUIZ_DATA: {
        // only reset popup related data so quick question tab and student-performance-tab
        // data remains there
        return { ...state, ...initialResettableState };
      }
      default:
        return state;
    }
  },
  { ...initialState, ...initialResettableState }
);

export default liveQuizReducer;
