// customEvents
export const JOIN_LIVE_CLASS = "JOIN_LIVE_CLASS";
export const LEAVE_LIVE_CLASS = "LEAVE_LIVE_CLASS";
export const END_LIVE_CLASS = "END_LIVE_CLASS";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const USER_JOINED = "USER_JOINED";
export const USER_LEFT = "USER_LEFT";
export const CHAT_ARCHIVE = "CHAT_ARCHIVE";
export const EVENT_ARCHIVE = "EVENT_ARCHIVE";
export const EVENT_REQUESTS_ARCHIVE = "EVENT_REQUESTS_ARCHIVE";
export const HAND_RAISE = "HAND_RAISE";
export const HAND_RAISE_REQUEST = "HAND_RAISE_REQUEST";
export const USER_JOIN = "USER_JOIN";
export const USER_JOIN_CLASS_ACCEPT = "USER_JOIN_CLASS_ACCEPT";
export const USER_JOIN_CLASS_REJECT = "USER_JOIN_CLASS_REJECT";
export const USER_JOIN_ACCEPT = "USER_JOIN_ACCEPT";
export const USER_JOIN_REJECT = "USER_JOIN_REJECT";
export const USER_JOIN_REQUEST = "USER_JOIN_REQUEST";
export const HAND_RAISE_ACCEPT = "HAND_RAISE_ACCEPT";
export const HAND_RAISE_REJECT = "HAND_RAISE_REJECT";
export const MIC_ON = "MIC_ON";
export const WHITEBOARD = "WHITEBOARD";
export const SERVER_TIME = "SERVER_TIME";
export const SUBMIT_WHITEBOARD = "SUBMIT_WHITEBOARD";
export const AUTOSAVE_WHITEBOARD = "AUTOSAVE_WHITEBOARD";
export const WHITEBOARD_ARCHIVE = "WHITEBOARD_ARCHIVE";
export const UPDATE_LIVECLASS_TOPIC = "UPDATE_LIVECLASS_TOPIC";
export const NEW_INVITATION = "NEW_INVITATION";
export const REMOVE_CLASS_INVITATION = "REMOVE_CLASS_INVITATION";
export const CLASS_IS_LIVE = "CLASS_IS_LIVE";
export const LIVE_CLASS_ENDED = "LIVE_CLASS_ENDED";
export const ADD_CLASS = "ADD_CLASS";
export const REMOVE_CLASS = "REMOVE_CLASS";
export const REMOVE_USER_INVITES = "REMOVE_USER_INVITES";
export const NEW_INVITED_USERS = "NEW_INVITED_USERS";
export const REMOVE_INVITATION = "REMOVE_INVITATION";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const REQUEST_MIC_OR_VIDEO = "REQUEST_MIC_OR_VIDEO";
export const ACCEPT_MIC_OR_VIDEO_REQUEST = "ACCEPT_MIC_OR_VIDEO_REQUEST";
export const REJECT_MIC_OR_VIDEO_REQUEST = "REJECT_MIC_OR_VIDEO_REQUEST";
export const CANCEL_MIC_OR_VIDEO_REQUEST = "CANCEL_MIC_OR_VIDEO_REQUEST";
export const HAND_RAISE_ARCHIVE = "HAND_RAISE_ARCHIVE";
export const HAND_RAISE_TIMEOUT = "HAND_RAISE_TIMEOUT";
export const HAND_DOWN = "HAND_DOWN";
export const NOTEBOOK_ARCHIVE = "NOTEBOOK_ARCHIVE";
export const CHANGE_CLASS_SETTINGS = "CHANGE_CLASS_SETTINGS";
export const RECORDING_STATE = "RECORDING_STATE";
export const YOUTUBE_VIDEO_SHARE = "YOUTUBE_VIDEO_SHARE";
export const SUBMIT_LIVE_FEEDBACK = "SUBMIT_LIVE_FEEDBACK";
export const REQUEST_SCREENSHARE = "REQUEST_SCREENSHARE";
export const ACCEPT_SCREENSHARE_REQUEST = "ACCEPT_SCREENSHARE_REQUEST";
export const REJECT_SCREENSHARE_REQUEST = "REJECT_SCREENSHARE_REQUEST";
export const CANCEL_SCREENSHARE_REQUEST = "CANCEL_SCREENSHARE_REQUEST";
export const TRANSFER_LIVECLASS = "TRANSFER_LIVECLASS";
export const KICK_PARTICIPANT = "KICK_PARTICIPANT";
export const SLIDESHARE_PREVIEW_AREA_UPDATE = "SLIDESHARE_PREVIEW_AREA_UPDATE";
export const FEEDBACK_POPUP = "FEEDBACK_POPUP";
export const TRIGGER_FEEDBACK = "TRIGGER_FEEDBACK";
export const NEW_INSTITUTE_REQUEST = "NEW_INSTITUTE_REQUEST";
export const REMOVE_INSTITUTE_INVITATION = "REMOVE_INSTITUTE_INVITATION";
export const ACCEPT_INSTITUTE_INVITATION = "ACCEPT_INSTITUTE_INVITATION";
export const TEACHER_INSTITUTE_INVITATION = "TEACHER_INSTITUTE_INVITATION";
export const ACCEPT_INSTITUTE_REQUEST = "ACCEPT_INSTITUTE_REQUEST";
export const REMOVE_INSTITUTE_REQUEST = "REMOVE_INSTITUTE_REQUEST";
export const REMOVE_INSTITUTE_TEACHER = "REMOVE_INSTITUTE_TEACHER";
export const JOIN_INSTITUTE = "JOIN_INSTITUTE";
export const REMOVE_LICENSE = "REMOVE_LICENSE";
export const ASSIGN_LICENSE = "ASSIGN_LICENSE";
export const NEW_INSTITUTE_INVITATIONS = "NEW_INSTITUTE_INVITATIONS";
export const LINK_DEVICE_CONNECTION_REQUEST_APP =
  "LINK_DEVICE_CONNECTION_REQUEST_APP";

export const LINK_DEVICE_CONNECTION_RESPONSE =
  "LINK_DEVICE_CONNECTION_RESPONSE";

export const SCREEN_SHARE_STARTED_APP = "SCREEN_SHARE_STARTED_APP";
export const LINK_DEVICE_DISCONNECTED = "LINK_DEVICE_DISCONNECTED";

// live quiz
export const LIVE_QUICKQUESTION_START = "LIVEQUICKQUESTION_START";
export const LIVE_QUICKQUESTION_TEACHER_RESPONSE_REFRESH =
  "LIVEQUICKQUESTION_TEACHER_RESPONSE_REFRESH";
export const LIVE_QUICKQUESTION_STUDENT_SUBMIT_RESPONSE =
  "LIVEQUICKQUESTION_STUDENT_RESPONSE";
export const LIVE_QUICKQUESTION_END = "LIVEQUICKQUESTION_END";
export const LIVE_QUICKQUESTION_PUBLISH_RESULT =
  "LIVEQUICKQUESTION_START_PUBLISH_RESULT";
export const LIVE_QUICKQUESTION_CLOSE = "LIVEQUICKQUESTION_CLOSE";

// socketEvents
export const CONNECT = "connect";

export const liveEvents = {
  userJoined: "user_joined",
  userLeft: "user_left",
  userClassJoin: "user_class_join",
  handRaise: "hand_raised",
  micOnRequest: "mic_on_request",
  videoOnRequest: "video_on_request",
  studentScreenshareRequest: "student_screenshare_request",
  kickedOut: "kicked_out",
  linkDeviceConnectionRequest: "device_connection_request",
  get messages() {
    return {
      [this.handRaise]: "raised hand",
      [this.userJoined]: "joined",
      [this.userClassJoin]: "joined",
      [this.userLeft]: "left",
      [this.micOnRequest]: "requested to turn on mic",
      [this.videoOnRequest]: "requested to turn on video",
      [this.studentScreenshareRequest]: "requested to share screen",
      [this.kickedOut]: "got removed from live lecture",
      [this.linkDeviceConnectionRequest]: "request to connect device",
    };
  },
};

export const boardType = {
  teacherWhiteboard: "teacher_whiteboard",
  studentSubmission: "student_submission",
  studentNotebook: "student_notebook",
};

// Assignment Submission
export const AUTOSAVE_GRADEBOARD = "AUTOSAVE_GRADEBOARD";
