import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import * as Sentry from "@sentry/react";
import rootReducer from "./reducers";
import { compose } from "redux";
import { getEnvironment } from "../utils/misc";
import { PRODUCTION } from "../utils/constants";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const middleware = [thunk];

const DevEnhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
);

const ProdEnhancer = compose(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
);

const enhancer = getEnvironment() === PRODUCTION ? ProdEnhancer : DevEnhancer;

const store = createStore(rootReducer, enhancer);

export default store;
