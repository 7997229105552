import produce from "immer";
import { isNil } from "lodash";
import { convertToMap } from "../../utils/objects";

import {
  SET_CLASS_STARTUP_DETAILS,
  SET_CLASS_LIST,
  SET_CLASS_LECTURES,
  SET_TIMETABLE,
  SET_CURRENT_CLASS,
  SET_STUDENTS_REMOVED_FROM_CLASS,
  SET_TEACHER_REMOVED_FROM_CLASS,
  ADD_EVENT,
  SET_TIMETABLE_WEEK,
  SET_TIMETABLE_MONTH,
  SET_TIMETABLE_LECTURE,
  REMOVE_TIMETABLE_LECTURE,
  ADD_CLASS,
  UPDATE_CLASS,
  ADD_INVITED_USERS,
  SET_PENDING_USERS,
  SET_INVITED_USERS,
  REMOVE_PENDING_REQUEST,
  SET_REMINDERS,
  SET_CURRENT_WEEK_REMINDERS,
  ADD_STUDENT_SUBMISSION,
  ADD_LECTURE_CLASS,
  CLONE_LECTURE,
  CLEAR_CLASS_DETAILS,
  REMOVE_INVITED_USERS,
  SET_CLASS_INVITES,
  DELETE_CLASS_INVITES,
  UPDATE_CLASS_SETTINGS,
  SET_AUTO_SUGGESTION_LIST_TEACHER,
  SET_AUTO_SUGGESTION_LIST_STUDENT,
  UPDATE_LIVE_CLASS_STATUS,
  REMOVE_CLASS,
  SET_LIVE_CLASS_SETTINGS,
  REMOVE_INVITATION,
  SET_CLASS_STUDENTS_COUNT,
  ADD_CLASS_INVITATION,
  SET_CLASS_LIST_LOADED,
  UPDATE_USER_DETAILS,
  SET_LIVE_LECTURE,
  ADD_LIVECLASS_ENDED_EVENT_DATA,
  RESET_LIVECLASS_ENDED_EVENT_DATA,
  SET_LECTURE_SHARE_STATUS,
  REMOVE_LIVE_LECTURE,
  UPDATE_LIVECLASS_TOPIC,
  SET_LIVE_STATUSES_OF_CLASSES,
  TOGGLE_QUICK_ACTIONS_POPUP,
  SET_CLASS_BATCHES,
} from "../types";

/**
 * @typedef {object} state
 * @property {{[class_id:number]: Array<Object<string, any>>}} classBatches
 *
 */

/**
 * @type state
 */
const initialState = {
  classes: {},
  classBatches: {},
  currentClass: 0,
  students: {},
  teachers: {},
  event: {},
  lectures: {},
  reminders: {},
  currentWeekReminders: {},
  timetable: {},
  timetable_week: {},
  calendarWeekStartDate: null,
  timetable_month: {},
  calendarMonth: null,
  timetable_lecture: {},
  requests: { pending: {}, invited: {} },
  submissions: {},
  invitations: {},
  autoSuggestionListTeacher: null,
  autoSuggestionListStudent: null,
  currentClassSettings: {
    allow_hand_raise: null,
    mute_on_entry: null,
    unrestricted_entry_time: null,
    student_video: null,
    display_student_while_speaking: null,
    live_feedback_enabled: null,
    live_feedback_threshold: null,
    live_feedback_frequency: null,
  },
  isClassListLoaded: false,
  liveClassEndedEventData: {},
  quickActionPopup: {
    isVisible: false,
    wobbleEffect: false,
  },
};

const classReducer = produce((state, action) => {
  switch (action.type) {
    case SET_CLASS_STARTUP_DETAILS: {
      const { klass, students, teachers, batch_students } = action.payload;
      const allStudents = [...students, ...batch_students];
      state.classes[klass.id] = { ...state.classes[klass.id], ...klass };
      // state.currentClass = klass.id;
      state.students[klass.id] = allStudents.reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      state.teachers[klass.id] = teachers.reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      return;
    }
    case SET_CLASS_LIST: {
      const classes = action.payload;
      state.classes = classes.reduce((acc, obj) => {
        if (obj.students) {
          state.students[obj.id] = obj.students.reduce(
            (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
            {}
          );
        }
        if (obj.teachers) {
          state.teachers[obj.id] = obj.teachers.reduce(
            (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
            {}
          );
        }
        return Object.assign(acc, { [obj.id]: obj });
      }, {});
      return;
    }
    case SET_LIVE_STATUSES_OF_CLASSES: {
      const classes = action.payload;
      classes.forEach(
        ({
          id,
          class_is_live,
          live_lecture_id,
          live_lecture,
          joined_before,
        }) => {
          state.classes[id] = {
            ...state.classes[id],
            class_is_live,
            live_lecture_id,
            live_lecture,
            joined_before,
          };
        }
      );
      return;
    }
    case ADD_CLASS: {
      const klass = action.payload;
      state.classes[klass.id] = klass;
      state.teachers[klass.id] = (klass.teachers || []).reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      return;
    }
    case REMOVE_CLASS: {
      const { classId } = action.payload;
      delete state.classes[classId];
      return;
    }
    case SET_LIVE_LECTURE: {
      const lecture = action.payload;
      state.lectures[lecture.id] = lecture;
      if (state.classes[lecture.class_id]) {
        state.classes[lecture.class_id].live_lecture_id = lecture.id;
        state.classes[lecture.class_id].live_lecture = lecture;
      }
      return;
    }
    case REMOVE_LIVE_LECTURE: {
      const { classId } = action.payload;
      if (state.classes[classId]) {
        state.classes[classId].live_lecture_id = null;
        state.classes[classId].live_lecture = null;
      }
      return;
    }
    case SET_CLASS_LECTURES: {
      state.lectures = convertToMap(action.payload);
      return;
    }
    case CLONE_LECTURE: {
      const tempLectures = action.payload.lectures;
      state.lectures = Object.assign(
        state.lectures,
        convertToMap(tempLectures)
      );
      return;
    }
    case ADD_LECTURE_CLASS: {
      const { lecture } = action.payload;
      if (!state.lectures[lecture.id]) {
        state.lectures[lecture.id] = lecture;
      }
      return;
    }
    case SET_TIMETABLE: {
      state.timetable = action.payload;
      return;
    }
    case ADD_EVENT: {
      const { event } = action.payload;
      state.event = event;
      return;
    }
    case SET_CURRENT_CLASS: {
      state.currentClass = action.payload;
      return;
    }
    case SET_STUDENTS_REMOVED_FROM_CLASS: {
      const { classId, studentIds } = action.payload;
      studentIds.forEach((sid) => {
        state.students[classId][sid].class_status = "removed";
      });
      return;
    }
    case SET_TEACHER_REMOVED_FROM_CLASS: {
      const { class_id, user_id } = action.payload;
      delete state.teachers[class_id][user_id];
      return;
    }

    case SET_TIMETABLE_WEEK: {
      state.timetable_week = action.payload;
      return;
    }
    case SET_TIMETABLE_MONTH: {
      state.timetable_month = action.payload;
      return;
    }
    case SET_TIMETABLE_LECTURE: {
      state.timetable_lecture = action.payload;
      return;
    }
    case REMOVE_TIMETABLE_LECTURE: {
      state.timetable_lecture = action.payload;
      return;
    }
    case SET_REMINDERS: {
      state.reminders = action.payload;
      return;
    }
    case SET_CURRENT_WEEK_REMINDERS: {
      state.currentWeekReminders = action.payload;
      return;
    }
    case SET_PENDING_USERS: {
      state.requests.pending = action.payload.reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      return;
    }
    case SET_INVITED_USERS: {
      state.requests.invited = action.payload.reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      return;
    }
    case ADD_INVITED_USERS: {
      action.payload.map(
        (request) => (state.requests.invited[request.id] = request)
      );
      return;
    }
    case REMOVE_INVITATION: {
      const { invitationId } = action.payload;
      delete state.requests.invited[invitationId];
      return;
    }
    case REMOVE_INVITED_USERS: {
      action.payload.map((inviteId) => delete state.requests.invited[inviteId]);
      return;
    }
    case REMOVE_PENDING_REQUEST: {
      const id = action.payload;
      delete state.requests.pending[id];
      return;
    }
    case ADD_STUDENT_SUBMISSION: {
      const { userId, submission } = action.payload;
      if (state.submissions[userId]) {
        state.submissions[userId].push(submission);
      } else {
        state.submissions[userId] = [submission];
      }
      return;
    }
    case UPDATE_CLASS: {
      const { classId, name, batch, description, people_tile_size } =
        action.payload;
      if (name) state.classes[classId].name = name;
      if (batch) state.classes[classId].batch = batch;
      state.classes[classId].description = description;
      if (people_tile_size)
        state.classes[classId].people_tile_size = people_tile_size;
      return;
    }
    case SET_CLASS_STUDENTS_COUNT: {
      const { classId, count } = action.payload;
      state.classes[classId].students_count = count;
      return;
    }
    case UPDATE_LIVE_CLASS_STATUS: {
      const { classId, live, joinedBefore, liveLecture } = action.payload;
      if (state.classes[classId]) {
        state.classes[classId].class_is_live = live;
        if (joinedBefore !== undefined) {
          state.classes[classId].joined_before = joinedBefore;
        }
        if (liveLecture) {
          state.classes[classId].live_lecture = liveLecture;
        }
      }
      return;
    }
    case ADD_CLASS_INVITATION: {
      const invitation = action.payload;
      state.invitations[invitation.class_id] = invitation;
      return;
    }
    case SET_CLASS_INVITES: {
      const invitations = action.payload;
      state.invitations = invitations.reduce(
        (acc, obj) => Object.assign(acc, { [obj.class_id]: obj }),
        {}
      );
      return;
    }
    case DELETE_CLASS_INVITES: {
      const { classId } = action.payload;
      delete state.invitations[classId];
      return;
    }
    case UPDATE_CLASS_SETTINGS: {
      const {
        classId,
        unrestricted_entry_time,
        student_video,
        mute_on_entry,
        allow_hand_raise,
        display_student_while_speaking,
        display_classmates,
        live_feedback_enabled,
        live_feedback_threshold,
        live_feedback_frequency,
      } = action.payload;
      state.classes[classId].unrestricted_entry_time = unrestricted_entry_time;
      state.classes[classId].student_video = student_video;
      state.classes[classId].mute_on_entry = mute_on_entry;
      state.classes[classId].allow_hand_raise = allow_hand_raise;
      state.classes[classId].display_student_while_speaking =
        display_student_while_speaking;
      state.classes[classId].display_classmates = display_classmates;
      state.classes[classId].live_feedback_enabled = live_feedback_enabled;
      state.classes[classId].live_feedback_threshold = live_feedback_threshold;
      state.classes[classId].live_feedback_frequency = live_feedback_frequency;
      return;
    }
    case SET_LIVE_CLASS_SETTINGS: {
      const {
        unrestricted_entry_time,
        student_video,
        mute_on_entry,
        allow_hand_raise,
        display_student_while_speaking,
        display_classmates,
        live_feedback_enabled,
        live_feedback_threshold,
        live_feedback_frequency,
      } = action.payload;
      state.currentClassSettings.unrestricted_entry_time =
        unrestricted_entry_time;
      state.currentClassSettings.student_video = student_video;
      state.currentClassSettings.mute_on_entry = mute_on_entry;
      state.currentClassSettings.allow_hand_raise = allow_hand_raise;
      state.currentClassSettings.display_student_while_speaking =
        display_student_while_speaking;
      state.currentClassSettings.display_classmates = display_classmates;
      state.currentClassSettings.live_feedback_enabled = live_feedback_enabled;
      state.currentClassSettings.live_feedback_threshold =
        live_feedback_threshold;
      state.currentClassSettings.live_feedback_frequency =
        live_feedback_frequency;
      return;
    }
    case SET_AUTO_SUGGESTION_LIST_TEACHER: {
      console.log({ reducer: action.payload });
      state.autoSuggestionListTeacher = action.payload;
      return;
    }
    case SET_AUTO_SUGGESTION_LIST_STUDENT: {
      console.log({ reducer: action.payload });
      state.autoSuggestionListStudent = action.payload;
      return;
    }
    case SET_CLASS_LIST_LOADED: {
      state.isClassListLoaded = action.payload;
      return;
    }
    case UPDATE_USER_DETAILS: {
      const { id, ...details } = action.payload;
      Object.values(state.teachers).forEach((teacherMap) => {
        if (teacherMap[id]) {
          Object.assign(teacherMap[id], details);
        }
      });
      Object.values(state.students).forEach((studentMap) => {
        if (studentMap[id]) {
          Object.assign(studentMap[id], details);
        }
      });
      return;
    }
    case TOGGLE_QUICK_ACTIONS_POPUP: {
      const { isVisible, wobbleEffect } = action.payload;
      if (!isNil(isVisible)) state.quickActionPopup.isVisible = isVisible;
      if (!isNil(wobbleEffect))
        state.quickActionPopup.wobbleEffect = wobbleEffect;
      return;
    }
    case ADD_LIVECLASS_ENDED_EVENT_DATA: {
      const { classId, lectureId } = action.payload;
      state.liveClassEndedEventData[classId] = lectureId;
      return;
    }
    case RESET_LIVECLASS_ENDED_EVENT_DATA: {
      state.liveClassEndedEventData = {};
      return;
    }
    case SET_LECTURE_SHARE_STATUS: {
      const { shareValue, lectureId } = action.payload;
      state.lectures[lectureId].shared_status = shareValue;
      return;
    }
    case UPDATE_LIVECLASS_TOPIC: {
      const { topic, lectureId } = action.payload;
      if (state.lectures[lectureId]) {
        state.lectures[lectureId].topic = topic;
      }
      return;
    }
    case SET_CLASS_BATCHES: {
      const { classBatchesMap } = action.payload;
      state.classBatches = classBatchesMap;
      return;
    }
    case CLEAR_CLASS_DETAILS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}, initialState);

export default classReducer;
