import produce from "immer";
import {
  SET_SHOW_DETAILS_POPUP,
  SET_DETAILS_POPUP_ELEMENT,
  SET_DELETE_POPUP_ELEMENT,
  SET_SHOW_DELETE_POPUP,
  SET_IS_MOVE,
  SET_MOVE_COPY_POPUP_ELEMENT,
  SET_SHOW_MOVE_COPY_POPUP,
  SET_SHOW_SHARE_FILE_FOLDER_POPUP,
} from "../types";

const initialState = {
  showDetailsPopup: false,
  detailsElementId: 0,
  showDeleteFilePopup: false,
  deleteElementId: 0,
  isMove: false,
  showMoveAndCopyFilePopup: false,
  showShareFileFolderPopup: false,
  moveCopyElement: 0,
};

export default produce((state, action) => {
  switch (action.type) {
    case SET_SHOW_DETAILS_POPUP: {
      state.showDetailsPopup = action.payload;
      return;
    }
    case SET_DETAILS_POPUP_ELEMENT: {
      state.detailsElementId = action.payload;
      return;
    }
    case SET_SHOW_DELETE_POPUP: {
      state.showDeleteFilePopup = action.payload;
      return;
    }
    case SET_DELETE_POPUP_ELEMENT: {
      state.deleteElementId = action.payload;
      return;
    }
    case SET_IS_MOVE: {
      state.isMove = action.payload;
      return;
    }
    case SET_MOVE_COPY_POPUP_ELEMENT: {
      state.moveCopyElement = action.payload;
      return;
    }
    case SET_SHOW_MOVE_COPY_POPUP: {
      state.showMoveAndCopyFilePopup = action.payload;
      return;
    }
    case SET_SHOW_SHARE_FILE_FOLDER_POPUP: {
      state.showShareFileFolderPopup = action.payload;
      return;
    }
    default: {
      return;
    }
  }
}, initialState);
