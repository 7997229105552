import _ from "lodash";

import store from "..";
import {
  SET_JITSI_X_CONNECTION_INITIALIZED,
  SET_JITSI_X_CONFERENCE_OBJECT,
  SET_JITSI_X_CONNECTION_OBJECT,
  SET_JITSI_X_CONFERENCE_JOINED,
  SET_MY_JITSI_X_AUDIO_TRACK,
  SET_MY_JITSI_X_VIDEO_TRACK,
  SET_MY_JITSI_X_PARTICIPANT_DETAILS,
  ADD_JITSI_X_TRACK_TO_PARTICIPANT,
  REMOVE_JITSI_X_TRACK_FROM_PARTICIPANT,
  ADD_JITSI_PARTICIPANT,
  REMOVE_JITSI_PARTICIPANT,
  SET_JITSI_TRACK_MUTE_CHANGED,
  SET_MY_JITSI_TRACK_MUTE_CHANGED,
  RESET_JITSI_X_STATE,
  SET_JITSI_TRACKS_INITIALIZED,
  SET_MY_JITSI_X_DESKTOP_TRACK,
  SET_JITSI_AVAILABLE_MEDIA_DEVICES,
  SET_MY_AUDIO_OUTPUT_DEVICE_ID,
  SET_MY_VIDEO_INPUT_DEVICE_ID,
  SET_MY_AUDIO_INPUT_DEVICE_ID,
  SET_CHANGED_PARTICIPANT_PROPERTY,
  SET_KICKED_FROM_CONFERENCE,
  SET_JITSI_CONFERENCE_ENDED,
  SET_SELECTED_PARTICIPANT_IDS,
  SET_TEACHER_PARTICIPANTS,
  SET_STUDENT_PARTICIPANTS,
  SET_DOMINANT_PARTICIPANT,
  SET_SHARED_PARTICIPANT_DETAILS,
  SET_ALL_PARTICIPANTS,
  SET_CURRENT_TEACHER_PARTICIPANT,
  SET_VOLUME_LEVEL,
  SET_MY_AUDIO_PREVIEW_TRACK,
  SET_MY_VIDEO_PREVIEW_TRACK,
  SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED,
  CLEANUP_JITSI_PREVIEW_TRACKS,
  SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
  SET_JITSI_X_CONNECTION_DROPPED,
  SET_JITSI_X_CONNECTION_RECONNECTED,
} from "../types";

import {
  STUDENT,
  TEACHER,
  VIDEO_SHARED,
  VIDEO_NOT_SHARED,
  VIDEO_TYPES,
  WHITEBOARD_TYPES,
  CURRENT_DEVICE,
  WEB_BROWSER,
  MOBILE_APP,
  PAGINATION_CONTENT_SIZE,
  VIDEO_OPTIONS,
  OPTIONS,
  VIDEO,
  AUDIO,
  MEDIA_SHARE_OPTIONS,
  STUDENT_VIDEO_QUALITY_VALUES,
  BITRATE_CAPS_ENABLED,
  PARTICIPANT_TYPES,
} from "../../utils/constants";
import JitsiMeetJS from "../../utils/JitsiMeetJS";
import { getUserStartupData, updateStudentPreferences } from "./userActions";
import { muteUserAudioRecording } from "./recorderActions";
import Resolutions from "../../utils/Resolutions";
import findVideoTrack from "../../components/StudentPresentationView/findVideoTrack";
import { studentSort, teacherSort } from "../../utils/participantSorting";
import { turnOff } from "./smartboard";
import { errorAlert } from "./utils";

const defaultResolutions = {
  [STUDENT]: {
    max: "320",
    min: "180",
  },
  [TEACHER]: {
    max: "640",
    min: "180",
  },
};

const jitsiConfig = {
  enableNoAudioDetection: false,
  enableNoisyMicDetection: false,
  useIPv6: false,
  disableAudioLevels: true,
  enableWindowOnErrorHandler: false,
  disableThirdPartyRequests: false,
  enableAnalyticsLogging: false,
  enableLayerSuspension: true,
  disableSimulcast: false,
  videoQuality: {
    preferredCodec: "VP8",
    enforcePreferredCodec: false,
  },
  clientNode: "http://jitsi.org/jitsimeet",
  useNicks: false,
  getWiFiStatsMethod: null,
  useStunTurn: true,
  p2p: {
    enabled: false,
    useStunTurn: true,
    stunServers: [{ urls: "stun:meet-jit-si-turnrelay.jitsi.net:443" }],
  },
  analytics: {},
  deploymentInfo: {},
  e2eping: {
    pingInterval: -1,
  },
  disableRtx: true,
};

const customCommands = {
  MUTE_MIC_YOURSELF: "MUTE_MIC_YOURSELF",
  MUTE_VIDEO_YOURSELF: "MUTE_VIDEO_YOURSELF",
  MUTE_ALL_VIDEOS: "MUTE_ALL_VIDEOS",
  MUTE_ALL_MICS: "MUTE_ALL_MICS",
  START_SHARING_YOUR_VIDEO: "START_SHARING_YOUR_VIDEO",
  STOP_SHARING_YOUR_VIDEO: "STOP_SHARING_YOUR_VIDEO",
  KICK_YOURSELF: "KICK_YOURSELF",
  CONFERENCE_ENDED: "CONFERENCE_ENDED",
};

let CURRENT_USER = TEACHER;

export const initJitsiLib = () => (dispatch) => {
  JitsiMeetJS.init(jitsiConfig);
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
};

export const initJitsiXConnection = () => (dispatch, getState) => {
  const { jitsi, jitsiX, user } = getState();
  const { jitsiRootDomain, conferenceId } = jitsi;
  const { jitsiConnectionInitialized } = jitsiX;

  const { role = "" } = user;

  if (jitsiConnectionInitialized) {
    dispatch(getUserMedia());
    return;
  }

  dispatch(getUserMedia());

  dispatch({
    type: SET_JITSI_X_CONNECTION_INITIALIZED,
    payload: null,
  });

  CURRENT_USER = role.toUpperCase() || TEACHER;

  // jitsi service url and hosts
  jitsiConfig.serviceUrl = `https://${jitsiRootDomain}/http-bind?room=${conferenceId}`;
  jitsiConfig.hosts = {
    domain: jitsiRootDomain,
    muc: `conference.${jitsiRootDomain}`,
  };

  if (BITRATE_CAPS_ENABLED) {
    jitsiConfig.videoQuality.maxBitratesVideo = {
      H264: {
        low: 200000,
        standard: 500000,
        high: 1500000,
      },
      VP8: {
        low: 200000,
        standard: 500000,
        high: 1500000,
      },
      VP9: {
        low: 100000,
        standard: 300000,
        high: 1200000,
      },
    };
  }

  // we are using this line to make sure the initial lastN value is 0. but somehow after we started using the new pagination api, this line is causing new joiners to see grey videos. so instead we are now calling selectParticipants([]) on conference joined.
  // jitsiConfig.channelLastN = 0;

  const jitsiConnection = new JitsiMeetJS.JitsiConnection(
    null,
    null,
    jitsiConfig
  );

  jitsiConnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    () => dispatch(initJitsiXConference())
  );

  jitsiConnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    (errorCode) => {
      dispatch(onXConnectionFailed(errorCode));
    }
  );

  jitsiConnection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    (...args) => {
      console.log("CONNECTION_DISCONNECTED", { ...args });
    }
  );

  jitsiConnection.addEventListener(
    JitsiMeetJS.events.connection.WRONG_STATE,
    (...args) => {
      console.log("WRONG_STATE", { ...args });
    }
  );

  jitsiConnection.connect();

  dispatch({
    type: SET_JITSI_X_CONNECTION_OBJECT,
    payload: jitsiConnection,
  });
};

const initJitsiXConference = () => (dispatch, getState) => {
  const { jitsi, jitsiX } = getState();
  const { conferenceId } = jitsi;
  const { jitsiConnection, connectionDropped } = jitsiX;

  const jitsiConference = jitsiConnection.initJitsiConference(
    conferenceId,
    jitsiConfig
  );

  dispatch(addConferenceEventListeners(jitsiConference));

  jitsiConference.join();

  dispatch({
    type: SET_JITSI_X_CONFERENCE_OBJECT,
    payload: jitsiConference,
  });

  if (connectionDropped) {
    dispatch(connectionXReconnected());
  }
};

const addConferenceEventListeners = (jitsiConference) => (dispatch) => {
  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_FAILED,
    (...args) => {
      console.log("CONFERENCE_FAILED", { ...args });
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_ERROR,
    (...args) => {
      console.log("CONFERENCE_ERROR", { ...args });
    }
  );

  jitsiConference.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
    dispatch(onTrackAdded(track));
  });

  jitsiConference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
    dispatch(onTrackRemoved(track));
  });

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
    dispatch(onConferenceJoined());
  });

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_LEFT, () => {});

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_JOINED,
    (participantId) => {
      dispatch(onParticipantJoined(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_LEFT,
    (participantId) => {
      dispatch(onParticipantLeft(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
    (track) => {
      dispatch(onTrackMuteChanged(track));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
    (user, key, ignore, value) => {
      dispatch(onParticipantPropertyChanged(user.getId(), key, value));
    }
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_MIC_YOURSELF,
    ({ attributes }) => dispatch(onMuteMicYourself(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_VIDEO_YOURSELF,
    ({ attributes }) => dispatch(onMuteVideoYourself(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_ALL_MICS,
    ({ attributes }) =>
      dispatch(
        onMuteAllMics(
          attributes.participantType,
          attributes.initiatorParticipantId
        )
      )
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_ALL_VIDEOS,
    ({ attributes }) =>
      dispatch(
        onMuteAllVideos(
          attributes.participantType,
          attributes.initiatorParticipantId
        )
      )
  );

  jitsiConference.addCommandListener(
    customCommands.START_SHARING_YOUR_VIDEO,
    ({ attributes }) =>
      dispatch(onStartSharingYourVideo(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.STOP_SHARING_YOUR_VIDEO,
    ({ attributes }) =>
      dispatch(onStopSharingYourVideo(attributes.participantId))
  );

  // kicking yourself is being done using socket now. this listener is not useful anymore
  // jitsiConference.addCommandListener(
  //   customCommands.KICK_YOURSELF,
  //   ({ attributes }) => dispatch(onKickYourself(attributes.participantId))
  // );

  jitsiConference.addCommandListener(customCommands.CONFERENCE_ENDED, () => {
    dispatch(onConferenceEnded());
  });

  jitsiConference.on(
    JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
    (participantId) => {
      dispatch(onDominantSpeakerChanged(participantId));
    }
  );

  JitsiMeetJS.mediaDevices.addEventListener(
    JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
    onDeviceListChangedListener
  );
};

export const onXConnectionFailed = (errorCode) => (dispatch) => {
  console.log("CONNECTION_FAILED", errorCode);

  dispatch({
    type: SET_JITSI_X_CONNECTION_DROPPED,
    payload: null,
  });

  global.jitsiXReconnectionTimeout = setTimeout(() => {
    dispatch(initJitsiXReConnection());
  }, 5000);
};

export const initJitsiXReConnection = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { connectionDropped } = jitsiX;

  if (!connectionDropped) {
    dispatch(connectionXReconnected());
  } else {
    dispatch(initJitsiXConnection());
  }
};

export const connectionXReconnected = () => (dispatch) => {
  dispatch(clearJitsiXReconnectionTimeout());

  dispatch({
    type: SET_JITSI_X_CONNECTION_RECONNECTED,
    payload: null,
  });
};

const clearJitsiXReconnectionTimeout = () => (dispatch) => {
  if (global.jitsiXReconnectionTimeout) {
    clearTimeout(global.jitsiXReconnectionTimeout);
    global.jitsiXReconnectionTimeout = undefined;
  }
};

export const cleanupJitsiX =
  ({ fromLiveClass = false } = {}) =>
  (dispatch, getState) => {
    console.log("cleanupJitsiX");
    const { jitsiX } = getState();
    const {
      jitsiConnection,
      jitsiConference,
      myVideoTrack,
      myAudioTrack,
      myDesktopTrack,
    } = jitsiX;

    dispatch(cleanupJitsiXPreviewTracks());

    dispatch(clearJitsiXReconnectionTimeout());

    JitsiMeetJS.mediaDevices.removeEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      onDeviceListChangedListener
    );

    if (fromLiveClass) {
      // leave and disconnect are causing both jitsi and jitsiX connections getting disconnected.
      // So, only calling it when live class ends and not when screenshare is stopped
      if (jitsiConference) {
        jitsiConference.leave();
      }

      if (jitsiConnection) {
        jitsiConnection.disconnect();
      }

      // we need jitsiConference and jitsiConnection to disconnect when live class ends.
      // so don't reset the state if desktop sharing is stopped.
      dispatch({
        type: RESET_JITSI_X_STATE,
        payload: null,
      });
    }

    if (myVideoTrack) {
      myVideoTrack.dispose();
    }

    if (myAudioTrack) {
      myAudioTrack.dispose();
    }

    if (myDesktopTrack) {
      myDesktopTrack.dispose();
      dispatch(setMyJitsiDesktopTrack(null));
    }
  };

export const cleanupJitsiXPreviewTracks = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myVideoPreviewTrack, myAudioPreviewTrack } = jitsiX;

  if (myVideoPreviewTrack) {
    myVideoPreviewTrack.dispose();
  }

  if (myAudioPreviewTrack) {
    myAudioPreviewTrack.dispose();
  }

  dispatch({
    type: CLEANUP_JITSI_PREVIEW_TRACKS,
    payload: null,
  });
};

export const extractResolutions = () => (dispatch, getState) => {
  let currentClassDetails;
  if (CURRENT_DEVICE === WEB_BROWSER) {
    const { klass, liveClass } = getState();
    const { classes } = klass;
    const { classId } = liveClass;
    currentClassDetails = classes[classId] || {};
  } else {
    const { classes } = getState();
    const { classDetailsMap, currentClass } = classes;
    currentClassDetails = classDetailsMap[currentClass] || {};
  }
  dispatch(setJitsiXResolutions(currentClassDetails));
};

const setJitsiXResolutions = (classDetails) => (dispatch) => {
  const {
    student_video_resolution_max,
    student_video_resolution_min,
    teacher_video_resolution_max,
    teacher_video_resolution_min,
  } = classDetails;

  let maxHeight, maxWidth, minHeight, minWidth, resolution;
  if (CURRENT_USER === STUDENT) {
    resolution = student_video_resolution_max;
    let maxResolutionDetails = Resolutions[resolution];

    if (!maxResolutionDetails) {
      resolution = defaultResolutions[STUDENT].max;
      maxResolutionDetails = Resolutions[resolution];
    }

    const minResolutionDetails =
      Resolutions[student_video_resolution_min] ||
      Resolutions[defaultResolutions[STUDENT].min];

    maxHeight = maxResolutionDetails.height;
    maxWidth = maxResolutionDetails.width;

    minHeight = minResolutionDetails.height;
    minWidth = minResolutionDetails.width;
  } else {
    resolution = teacher_video_resolution_max;
    let maxResolutionDetails = Resolutions[resolution];

    if (!maxResolutionDetails) {
      resolution = defaultResolutions[TEACHER].max;
      maxResolutionDetails = Resolutions[resolution];
    }

    const minResolutionDetails =
      Resolutions[teacher_video_resolution_min] ||
      Resolutions[defaultResolutions[TEACHER].min];

    maxHeight = maxResolutionDetails.height;
    maxWidth = maxResolutionDetails.width;

    minHeight = minResolutionDetails.height;
    minWidth = minResolutionDetails.width;
  }

  jitsiConfig.resolution = resolution;
  jitsiConfig.constraints = {
    video: {
      frameRate: {
        max: 30,
        min: 10,
      },
      height: {
        ideal: maxHeight,
        max: maxHeight,
        min: minHeight,
      },
      width: {
        ideal: maxWidth,
        max: maxWidth,
        min: minWidth,
      },
    },
  };
};

export const getUserMedia = () => async (dispatch) => {
  await dispatch(startDesktopSharing());

  dispatch({
    type: SET_JITSI_TRACKS_INITIALIZED,
    payload: null,
  });
};

export const setMyJitsiUserName = (userName) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (!myParticipantId) {
    return;
  }

  dispatch(setJitsiParticipantProperty(myParticipantId, "userName", userName));
};

export const setMyJitsiUserEmail = (userEmail) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (!myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userEmail", userEmail)
  );
};

const jitsiXConferenceAddTrack = (track) => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference, participants, myParticipantId } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  try {
    await jitsiConference.addTrack(track);
  } catch (error) {
    console.log(error);
    if (
      error &&
      error.message &&
      error.message.includes("second video track")
    ) {
      const participant = participants[myParticipantId];
      if (participant && participant.tracks) {
        const [toRemoveVideoTrack] = (participant.tracks || []).filter(
          (t) => t.type !== "audio"
        );

        if (!toRemoveVideoTrack || !toRemoveVideoTrack.track) {
          return;
        }

        try {
          await jitsiConference.removeTrack(toRemoveVideoTrack.track);
          await toRemoveVideoTrack.track.dispose();
          await dispatch(jitsiXConferenceAddTrack(track));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
};

const onConferenceJoined = () => async (dispatch, getState) => {
  const { jitsiX, user } = getState();
  const { jitsiConference, myDesktopTrack } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  dispatch({
    type: SET_JITSI_X_CONFERENCE_JOINED,
    payload: null,
  });

  const myParticipantId = jitsiConference.myUserId();

  dispatch({
    type: SET_MY_JITSI_X_PARTICIPANT_DETAILS,
    payload: {
      myParticipantId,
    },
  });

  if (myDesktopTrack) {
    await dispatch(jitsiXConferenceAddTrack(myDesktopTrack));
  }
  dispatch(getAvailableMediaDevices());

  dispatch(setJitsiParticipantProperty(myParticipantId, "userId", user.id));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "participantType",
      PARTICIPANT_TYPES.DESKTOP
    )
  );
  dispatch(setMyJitsiUserName(user.name));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "userImage",
      user.profile_photo_url || user.profilePhotoUrl
    )
  );
  dispatch(setMyJitsiUserEmail(user.email));
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userType", CURRENT_USER)
  );
  dispatch(setMyVideoType(VIDEO_TYPES.VIDEO_OFF));
  dispatch(setMyVideoState());
  if (CURRENT_USER === TEACHER) {
    dispatch(setMyWhiteboardType(WHITEBOARD_TYPES.WHITEBOARD_OFF));
  }
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "lastStartedSpeaking",
      new Date(0).toISOString()
    )
  );

  dispatch(selectParticipants([]));

  if (CURRENT_DEVICE === MOBILE_APP) {
    jitsiConference.setSenderVideoConstraint(360);
  }
};

const onParticipantJoined = (participantId) => (dispatch) => {
  dispatch({
    type: ADD_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

const setJitsiParticipantProperty =
  (myParticipantId, key, value) => (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference } = jitsiX;

    if (!jitsiConference) {
      return;
    }

    jitsiConference.setLocalParticipantProperty(key, value);
    dispatch(onParticipantPropertyChanged(myParticipantId, key, value));
  };

const onParticipantPropertyChanged =
  (participantId, key, value) => (dispatch, getState) => {
    return;
    const { jitsiX } = getState();
    const { myParticipantId, participants } = jitsiX;

    setTimeout(() => {
      dispatch({
        type: SET_CHANGED_PARTICIPANT_PROPERTY,
        payload: {
          participantId,
          key,
          value: key === "userId" ? Number(value) : value,
        },
      });

      dispatch(calculateParticipantsList());
    }, 1000);

    /* *
    Stop my own broadcasting if:
      1. some other user has started broadcasting mediashare, whiteboard or screenshare
      or
      2. Teacher casted someone else's video/screenshare to class
    */
    if (
      participantId !== myParticipantId &&
      key === "videoShared" &&
      value === VIDEO_SHARED
    ) {
      if (CURRENT_USER === TEACHER) {
        dispatch(turnOff());
        dispatch(stopDesktopSharing());
      }
    }

    if (
      participantId !== myParticipantId &&
      key === "broadcastType" &&
      [OPTIONS.SLIDESHARE, OPTIONS.WHITEBOARD, OPTIONS.SCREENSHARE].includes(
        value
      )
    ) {
      if (CURRENT_USER === TEACHER) {
        dispatch(turnOff());
        dispatch(stopDesktopSharing());
      }
    }
  };

const onParticipantLeft = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

const onTrackAdded = (track) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  let participantId;
  if (track.isLocal()) {
    participantId = myParticipantId;
  } else {
    participantId = track.getParticipantId();
  }

  dispatch({
    type: ADD_JITSI_X_TRACK_TO_PARTICIPANT,
    payload: {
      participantId,
      track,
    },
  });

  dispatch(calculateParticipantsList());
};

const onTrackRemoved = (track) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  let participantId;
  if (track.isLocal()) {
    participantId = myParticipantId;
  } else {
    participantId = track.getParticipantId();
  }

  dispatch({
    type: REMOVE_JITSI_X_TRACK_FROM_PARTICIPANT,
    payload: {
      participantId,
      track,
    },
  });

  dispatch(calculateParticipantsList());
};

const onTrackMuteChanged = (track) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  const trackParticipantId = track.getParticipantId();

  dispatch({
    type: SET_JITSI_TRACK_MUTE_CHANGED,
    payload: {
      participantId: trackParticipantId,
      track,
    },
  });

  if (myParticipantId === trackParticipantId) {
    dispatch(setMyJitsiTrackMuteChanged());
  }

  dispatch(calculateParticipantsList());
};

export const muteMyVideo = (mute) => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myVideoTrack, myDesktopTrack } = jitsiX;

  if (mute) {
    dispatch(unshareSelfVideo());
  }

  if (!mute && myDesktopTrack) {
    try {
      await dispatch(stopDesktopSharing(false));
    } catch (e) {
      console.log(e);
    }
  }

  if (myVideoTrack) {
    try {
      mute ? await myVideoTrack.mute() : await myVideoTrack.unmute();
    } catch (e) {
      console.log(e);
    }
  }

  dispatch(setMyJitsiTrackMuteChanged());
};

export const muteMyAudio = (mute) => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myAudioTrack } = jitsiX;

  if (!myAudioTrack) {
    return;
  }

  try {
    mute ? await myAudioTrack.mute() : await myAudioTrack.unmute();
  } catch (e) {
    console.log(e);
  }

  dispatch(setMyJitsiTrackMuteChanged());

  dispatch(muteUserAudioRecording(mute));
};

export const startDesktopSharing = () => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference, myVideoTrack } = jitsiX;

  let desktopTracks;

  try {
    desktopTracks = await JitsiMeetJS.createLocalTracks({
      devices: ["desktop"],
    });
  } catch (e) {
    console.log(e);
    if (e && e.name === "gum.permission_denied") {
      dispatch(
        errorAlert(
          "Screenshare permission denied. Please give permissions manually."
        )
      );
    }
  }

  desktopTracks = desktopTracks || [];

  const desktopVideoTracks = desktopTracks.filter((t) => t.type !== "audio");
  const desktopAudioTracks = desktopTracks.filter((t) => t.type === "audio");

  if (desktopAudioTracks[0]) {
    try {
      await desktopAudioTracks[0].dispose();
    } catch (e) {
      console.log(e);
    }
  }

  const desktopTrack = desktopVideoTracks[0];

  if (!desktopTrack) {
    return false;
  }

  desktopTrack.addEventListener(
    JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
    () => dispatch(stopDesktopSharing())
  );

  dispatch(setMyJitsiDesktopTrack(desktopTrack));

  if (myVideoTrack) {
    try {
      await jitsiConference.removeTrack(myVideoTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myVideoTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    dispatch(setMyJitsiVideoTrack(null));
  }

  await dispatch(jitsiXConferenceAddTrack(desktopTrack));
};

export const stopDesktopSharing =
  (muteVideoTrack = true) =>
  async (dispatch, getState) => {
    const { jitsiX } = getState();
    const { myDesktopTrack, jitsiConference } = jitsiX;

    dispatch(unshareSelfVideo());

    if (!jitsiConference || !myDesktopTrack) {
      return;
    }

    try {
      await jitsiConference.removeTrack(myDesktopTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myDesktopTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    dispatch(setMyJitsiDesktopTrack(null));
  };

const setJitsiAvailableMediaDevices = (devices) => (dispatch) => {
  dispatch({
    type: SET_JITSI_AVAILABLE_MEDIA_DEVICES,
    payload: devices,
  });
};

export const getAvailableMediaDevices =
  (mediaDevices = []) =>
  (dispatch, getState) => {
    if (mediaDevices.length === 0) {
      JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
        dispatch(setJitsiAvailableMediaDevices(devices));
      });
    } else {
      dispatch(setJitsiAvailableMediaDevices(mediaDevices));
    }

    const { jitsiX } = getState();
    const { myVideoTrack, myAudioTrack } = jitsiX;

    if (myVideoTrack) {
      dispatch(setMyVideoInputDeviceId(myVideoTrack.getDeviceId()));
    }

    if (myAudioTrack) {
      dispatch(setMyAudioInputDeviceId(myAudioTrack.getDeviceId()));
    }

    dispatch(
      setMyAudioOutputDeviceId(JitsiMeetJS.mediaDevices.getAudioOutputDevice())
    );
  };

export const changeMyVideoInputDevice =
  (deviceId) => async (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, myDesktopTrack, myVideoTrack, myVideoMuted } =
      jitsiX;

    dispatch(setMyVideoInputDeviceId(deviceId));

    if (myDesktopTrack) {
      return;
    }

    const mediaTracksOptions = {
      devices: ["video"],
      cameraDeviceId: deviceId,
    };

    if (CURRENT_DEVICE === MOBILE_APP) {
      // mediaTracksOptions.resolution = jitsiConfig.resolution;
      mediaTracksOptions.resolution = 360;
    }

    let videoTracks;

    try {
      videoTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    videoTracks = videoTracks || [];

    const videoTrack = videoTracks[0];

    if (!videoTrack) {
      return;
    }

    dispatch(setMyJitsiVideoTrack(videoTrack));

    if (!jitsiConference) {
      return;
    }

    try {
      await jitsiConference.removeTrack(myVideoTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myVideoTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    if (myVideoMuted) {
      dispatch(muteMyVideo(true));
    }

    await dispatch(jitsiXConferenceAddTrack(videoTrack));
  };

export const changeMyAudioInputDevice =
  (deviceId) => async (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, myAudioTrack, myAudioMuted } = jitsiX;

    dispatch(setMyAudioInputDeviceId(deviceId));

    const mediaTracksOptions = {
      devices: ["audio"],
      micDeviceId: deviceId,
    };

    let audioTracks;

    try {
      audioTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    audioTracks = audioTracks || [];

    const audioTrack = audioTracks[0];

    if (!audioTrack) {
      return;
    }

    dispatch(setMyJitsiAudioTrack(audioTrack));

    if (!jitsiConference) {
      return;
    }

    try {
      await jitsiConference.removeTrack(myAudioTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myAudioTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    if (myAudioMuted) {
      dispatch(muteMyAudio(true));
    }

    await dispatch(jitsiXConferenceAddTrack(audioTrack));
  };

export const changeMyAudioOutputDevice = (deviceId) => (dispatch) => {
  dispatch(setMyAudioOutputDeviceId(deviceId));
  JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId);
};

export const muteParticipantMic = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.MUTE_MIC_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

const onDeviceListChangedListener = (mediaDevices) => {
  store.dispatch(onDeviceListChanged(mediaDevices));
};

const onDeviceListChanged =
  (mediaDevices = []) =>
  (dispatch, getState) => {
    const { jitsiX } = getState();
    const { availableMediaDevices } = jitsiX;

    dispatch(getAvailableMediaDevices(mediaDevices));

    let prevAudioInputs = availableMediaDevices.filter(
      (d) => d.kind === "audioinput"
    );
    let prevVideoInputs = availableMediaDevices.filter(
      (d) => d.kind === "videoinput"
    );
    let audioInputs = mediaDevices.filter((d) => d.kind === "audioinput");
    let videoInputs = mediaDevices.filter((d) => d.kind === "videoinput");

    if (
      prevAudioInputs.length !== audioInputs.length &&
      audioInputs.length > 0
    ) {
      dispatch(changeMyAudioInputDevice(audioInputs[0].deviceId));
    }

    if (
      prevVideoInputs.length !== videoInputs.length &&
      videoInputs.length > 0
    ) {
      dispatch(changeMyVideoInputDevice(videoInputs[0].deviceId));
    }
  };

export const muteParticipantVideo = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.MUTE_VIDEO_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

const onMuteMicYourself = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId === myParticipantId) {
    dispatch(muteMyAudio(true));
  }
};

const onMuteVideoYourself = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId === myParticipantId) {
    dispatch(muteAnyOfMyVideo());
  }
};

const muteAnyOfMyVideo = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myDesktopTrack } = jitsiX;

  if (myDesktopTrack) {
    dispatch(stopDesktopSharing());
    return;
  }

  dispatch(muteMyVideo(true));
};

export const shareParticipantVideo =
  (participantId) => (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, participantIds, participants } = jitsiX;

    if (!jitsiConference) {
      return;
    }

    const sharedParticipantIds = participantIds
      .filter((pid) => !!participants[pid])
      .filter((pid) => participants[pid].videoShared === VIDEO_SHARED);

    let sendShareCommand = true;

    sharedParticipantIds.forEach((pid) => {
      if (pid === participantId) {
        sendShareCommand = false;
        return;
      }

      jitsiConference.sendCommandOnce(customCommands.STOP_SHARING_YOUR_VIDEO, {
        attributes: {
          participantId: pid,
        },
      });
    });

    if (sendShareCommand) {
      jitsiConference.sendCommandOnce(customCommands.START_SHARING_YOUR_VIDEO, {
        attributes: {
          participantId,
        },
      });
    }
  };

export const unshareParticipantVideo = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference, participantIds, participants } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  const sharedParticipantIds = participantIds
    .filter((pid) => !!participants[pid])
    .filter((pid) => participants[pid].videoShared === VIDEO_SHARED);

  sharedParticipantIds.forEach((pid) => {
    jitsiConference.sendCommandOnce(customCommands.STOP_SHARING_YOUR_VIDEO, {
      attributes: {
        participantId: pid,
      },
    });
  });
};

const onStartSharingYourVideo = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoShared", VIDEO_SHARED)
  );
};

const onStopSharingYourVideo = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "videoShared",
      VIDEO_NOT_SHARED
    )
  );
};

export const sendKickParticipant = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.KICK_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

// kicking yourself is being done using socket now. this function is not useful anymore
const onKickYourself = (participantId) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(setKickedFromConference());
};

export const setKickedFromConference = () => (dispatch) => {
  dispatch({
    type: SET_KICKED_FROM_CONFERENCE,
    payload: true,
  });
};

export const endConferenceForAll = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference } = jitsiX;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.CONFERENCE_ENDED, {
    attributes: {},
  });
};

const onConferenceEnded = () => (dispatch) => {
  dispatch({
    type: SET_JITSI_CONFERENCE_ENDED,
    payload: true,
  });

  if (CURRENT_DEVICE === MOBILE_APP) {
    dispatch(getUserStartupData("student"));
  }
};

const setMyJitsiAudioTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_X_AUDIO_TRACK,
    payload: track,
  });
};

const setMyJitsiVideoTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_X_VIDEO_TRACK,
    payload: track,
  });
};

const setMyJitsiDesktopTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_X_DESKTOP_TRACK,
    payload: track,
  });
};

const setMyJitsiTrackMuteChanged = () => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_TRACK_MUTE_CHANGED,
    payload: null,
  });
};

const setMyAudioOutputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_AUDIO_OUTPUT_DEVICE_ID,
    payload: deviceId,
  });
};

const setMyAudioInputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_AUDIO_INPUT_DEVICE_ID,
    payload: deviceId,
  });
};

const setMyVideoInputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_VIDEO_INPUT_DEVICE_ID,
    payload: deviceId,
  });
};

export const switchCameraTo =
  (facingMode, muteVideo = false) =>
  async (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, myVideoTrack } = jitsiX;

    if (!jitsiConference) {
      return;
    }

    if (facingMode !== "user" && facingMode !== "environment") {
      return;
    }

    if (myVideoTrack) {
      try {
        await jitsiConference.removeTrack(myVideoTrack);
      } catch (e) {
        console.log(e);
      }

      try {
        await myVideoTrack.dispose();
      } catch (e) {
        console.log(e);
      }

      dispatch(setMyJitsiVideoTrack(null));
    }

    const mediaTracksOptions = {
      devices: ["video"],
      facingMode,
    };

    if (CURRENT_DEVICE === MOBILE_APP) {
      // mediaTracksOptions.resolution = jitsiConfig.resolution;
      mediaTracksOptions.resolution = 360;
    }

    let videoTracks;

    try {
      videoTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    videoTracks = videoTracks || [];

    const videoTrack = videoTracks[0];

    if (!videoTrack) {
      return;
    }

    if (muteVideo) {
      try {
        await videoTrack.mute();
      } catch (e) {
        console.log(e);
      }
    }

    dispatch(setMyJitsiVideoTrack(videoTrack));

    await dispatch(jitsiXConferenceAddTrack(videoTrack));
  };

const setLastN =
  (n = -1) =>
  (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference } = jitsiX;

    if (jitsiConference) {
      jitsiConference.setLastN(n);
    }
  };

export const selectParticipants =
  (participantIds = [], qualityToSet) =>
  (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference } = jitsiX;

    if (jitsiConference) {
      // new pagination api by lib-jitsi-meet
      jitsiConference.setReceiverConstraints({
        lastN: 0,
        selectedEndpoints: [],
      });

      // commenting our old way to achieve pagination using lib-jitsi-meet
      // jitsiConference.selectParticipants(participantIds);
      // dispatch(setLastN(participantIds.length));
    }
  };

export const setMyVideoType = (videoType) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoType", videoType)
  );
};

export const setMyWhiteboardType = (whiteboardType) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "whiteboardType",
      whiteboardType
    )
  );
};

export const setMyVideoPosition = (x, y) => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoPosition", `${x},${y}`)
  );
};

export const setMyVideoResizedDimension =
  (detailsObj) => (dispatch, getState) => {
    const { jitsiX } = getState();
    const { myParticipantId } = jitsiX;
    dispatch(
      setJitsiParticipantProperty(
        myParticipantId,
        "videoDimension",
        JSON.stringify(detailsObj)
      )
    );
  };

export const resetMyVideoPosition = () => (dispatch) => {
  dispatch(setMyVideoPosition(0, 0));
};

export const setMyVideoState = (videoState) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsiX, smartboard } = getState();
  const { myParticipantId } = jitsiX;
  const { videoState: videoStateInStore } = smartboard;

  if (!videoState) {
    videoState = videoStateInStore;
  }

  /*
    videoState values
    - SMALL
    - BIG
    - OFF
  */

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoState", videoState)
  );
};

export const setMyBroadcastType = (broadcastType) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  /*
    broadcastType values
    - SLIDESHARE
    - WHITEBOARD
    - SCREENSHARE
    - VIDEO_OR_DP
  */
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "broadcastType", broadcastType)
  );

  // If someone else's screen is casted, unshare them when I've started broadcasting
  if (
    [OPTIONS.SLIDESHARE, OPTIONS.WHITEBOARD, OPTIONS.SCREENSHARE].includes(
      broadcastType
    )
  ) {
    dispatch(unshareParticipantVideo());
  }
};

export const setMyMediaShareType = (mediaShareType) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  /*
    mediaShareType values
    - SLIDES
    - YOUTUBE
  */

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "mediaShareType",
      mediaShareType
    )
  );
};

const calculateParticipantDetails = (
  participantId,
  participants,
  handRaises
) => {
  const participant = participants[participantId];

  const [audioTrack] = (participant.tracks || []).filter(
    (t) => t.type === "audio"
  );
  const [videoTrack] = (participant.tracks || []).filter(
    (t) => t.type !== "audio"
  );

  const videoMuted = videoTrack ? videoTrack.isMuted : true;

  return {
    videoState: videoMuted ? VIDEO_OPTIONS.off : VIDEO_OPTIONS.big,
    broadcastType: OPTIONS.SCREENSHARE,
    mediaShareType: MEDIA_SHARE_OPTIONS.SLIDES,
    lastStartedSpeaking: new Date(0).toISOString(),
    ...participant,
    videoMuted,
    videoTrack: videoTrack || null,
    audioMuted: audioTrack ? audioTrack.isMuted : true,
    audioTrack: audioTrack || null,
    handRaised: handRaises.has(participant.userId),
    isVideoShared: !!(
      participant.videoShared === VIDEO_SHARED &&
      videoTrack &&
      !videoTrack.isMuted
    ),
  };
};

const findCurrentTeacherParticipant =
  (allTeacherParticipants) => (dispatch) => {
    const allTeacherSortedParticipants = _.sortBy(
      allTeacherParticipants,
      teacherSort
    );

    dispatch({
      type: SET_CURRENT_TEACHER_PARTICIPANT,
      payload: allTeacherSortedParticipants[0],
    });
  };

export const calculateParticipantsList = (timedOut) => (dispatch, getState) => {
  // added a 500ms timeout to batch the function call.
  // this function gets called lot of times during conference join that might lead to some slowing up of the app and sometimes making it unresponsive.

  if (!global.calculateXParticipantsListTimeout) {
    global.calculateXParticipantsListTimeout = setTimeout(
      () => {
        dispatch(calculateParticipantsList(true));
        global.calculateXParticipantsListTimeout = undefined;
      },
      CURRENT_DEVICE === MOBILE_APP ? 1000 : 500
    );
  }

  if (!timedOut) {
    return;
  }
  return;

  const { jitsi, jitsiX, liveClass, user } = getState();
  const { myParticipantId, participantIds, participants } = jitsiX;
  const { handRaises } = liveClass;

  const allParticipants = {};

  participantIds.forEach((pid) => {
    if (
      participants[pid] &&
      participants[pid].userId &&
      pid !== myParticipantId &&
      participants[pid].userId !== user.id
    ) {
      allParticipants[participants[pid].userId] = pid;
    }
  });

  const tempParticipantIds = Object.values(allParticipants);

  const tempParticipants = tempParticipantIds.map((pid) =>
    calculateParticipantDetails(pid, participants, handRaises)
  );

  const teacherParticipants = tempParticipants.filter(
    (participant) => participant.userType === TEACHER
  );

  const studentParticipants = tempParticipants.filter(
    (participant) => participant.userType === STUDENT
  );

  const teacherSortedParticipants = _.sortBy(teacherParticipants, teacherSort);

  const studentSortedParticipants = _.sortBy(studentParticipants, studentSort);

  dispatch({
    type: SET_TEACHER_PARTICIPANTS,
    payload: teacherSortedParticipants,
  });

  dispatch({
    type: SET_STUDENT_PARTICIPANTS,
    payload: studentSortedParticipants,
  });

  dispatch({
    type: SET_ALL_PARTICIPANTS,
    payload: tempParticipants,
  });

  let myParticipantDetails;

  if (
    myParticipantId &&
    participants[myParticipantId] &&
    participants[myParticipantId].userType
  ) {
    myParticipantDetails = calculateParticipantDetails(
      myParticipantId,
      participants,
      handRaises
    );
  }

  let allTeacherParticipants;
  if (CURRENT_USER === TEACHER && myParticipantDetails) {
    allTeacherParticipants = [
      ...teacherSortedParticipants,
      myParticipantDetails,
    ];
  } else {
    allTeacherParticipants = [...teacherSortedParticipants];
  }

  dispatch(findCurrentTeacherParticipant(allTeacherParticipants));

  let sharedParticipantId = null;
  let sharedParticipantVideoTrack = null;

  const allTempParticipants = [...tempParticipants];
  if (myParticipantDetails) {
    allTempParticipants.push(myParticipantDetails);
  }

  const [sharedParticipant] = allTempParticipants.filter(
    (participant) => participant.isVideoShared
  );

  if (sharedParticipant) {
    sharedParticipantId = sharedParticipant.participantId;
    sharedParticipantVideoTrack = sharedParticipant.videoTrack;
  }

  if (
    jitsi.sharedParticipantId !== sharedParticipantId ||
    jitsi.sharedParticipantVideoTrack !== sharedParticipantVideoTrack
  ) {
    dispatch({
      type: SET_SHARED_PARTICIPANT_DETAILS,
      payload: {
        sharedParticipantId,
        sharedParticipantVideoTrack,
      },
    });
  }
};

const onDominantSpeakerChanged = (participantId) => (dispatch, getState) => {
  dispatch({
    type: SET_DOMINANT_PARTICIPANT,
    payload: participantId,
  });

  const { jitsiX } = getState();
  const { myParticipantId } = jitsiX;

  if (participantId === myParticipantId) {
    dispatch(
      setJitsiParticipantProperty(
        myParticipantId,
        "lastStartedSpeaking",
        new Date().toISOString()
      )
    );
  }
};

export const unshareSelfVideo = () => (dispatch, getState) => {
  const { jitsiX } = getState();
  const { jitsiConference, participantIds, participants, myParticipantId } =
    jitsiX;

  if (!jitsiConference) {
    return;
  }

  if (
    participants[myParticipantId] &&
    participants[myParticipantId].videoShared === VIDEO_SHARED
  ) {
    dispatch(onStopSharingYourVideo(myParticipantId));
  }
};

export const muteAllMics =
  (participantType = STUDENT) =>
  (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, myParticipantId } = jitsiX;

    if (!jitsiConference || !myParticipantId) {
      return;
    }

    jitsiConference.sendCommandOnce(customCommands.MUTE_ALL_MICS, {
      attributes: {
        participantType,
        initiatorParticipantId: myParticipantId,
      },
    });
  };

export const muteAllVideos =
  (participantType = STUDENT) =>
  (dispatch, getState) => {
    const { jitsiX } = getState();
    const { jitsiConference, myParticipantId } = jitsiX;

    if (!jitsiConference || !myParticipantId) {
      return;
    }

    jitsiConference.sendCommandOnce(customCommands.MUTE_ALL_VIDEOS, {
      attributes: {
        participantType,
        initiatorParticipantId: myParticipantId,
      },
    });
  };

const onMuteAllMics =
  (participantType, initiatorParticipantId) => (dispatch, getState) => {
    const { jitsiX } = getState();
    const { myParticipantId } = jitsiX;

    if (
      participantType === CURRENT_USER &&
      myParticipantId !== initiatorParticipantId
    ) {
      dispatch(muteMyAudio(true));
    }
  };

const onMuteAllVideos =
  (participantType, initiatorParticipantId) => (dispatch, getState) => {
    const { jitsiX } = getState();
    const { myParticipantId } = jitsiX;

    if (
      participantType === CURRENT_USER &&
      myParticipantId !== initiatorParticipantId
    ) {
      dispatch(muteAnyOfMyVideo());
    }
  };

export const calculatePaginatedParticipants =
  (currentPageNumber) => (dispatch, getState) => {
    const { jitsiX, liveClass } = getState();
    const {
      teacherParticipants,
      studentParticipants,
      sharedParticipantId,
      sharedParticipantVideoTrack,
      currentTeacherParticipant,
      selectedParticipantIds,
      myParticipantId,
      dominantParticipantId,
    } = jitsiX;

    const { displayParticipantId } = liveClass;

    let pageNumber = currentPageNumber;
    let showUpButton = false;
    let showDownButton = false;

    const allSortedParticipants = [
      ...teacherParticipants,
      ...studentParticipants,
    ];

    const videoParticipants = allSortedParticipants.filter(
      (participant) => !participant.videoMuted
    );

    const totalPages = Math.ceil(
      videoParticipants.length / PAGINATION_CONTENT_SIZE
    );

    if (pageNumber > 0 && pageNumber > totalPages - 1) {
      pageNumber = totalPages - 1;
    }

    if (pageNumber < 0) {
      pageNumber = 0;
    }

    if (pageNumber > 0) {
      showUpButton = true;
    }

    if (pageNumber < totalPages - 1) {
      showDownButton = true;
    }

    let startIndex;
    let endIndex;

    if (totalPages === 0 || totalPages === 1) {
      startIndex = 0;
      endIndex = Infinity;
    } else {
      startIndex = PAGINATION_CONTENT_SIZE * pageNumber;
      endIndex = startIndex + PAGINATION_CONTENT_SIZE;
    }

    const paginatedParticipantIds = [];
    const toSelectParticipantIds = [];

    const tempVideoPartipantIds = [];
    allSortedParticipants.forEach((participant) => {
      if (!participant.videoMuted) {
        tempVideoPartipantIds.push(participant.participantId);
      }

      if (
        (!tempVideoPartipantIds.length && !startIndex) ||
        (tempVideoPartipantIds.length > startIndex &&
          tempVideoPartipantIds.length <= endIndex)
      ) {
        if (!participant.videoMuted) {
          toSelectParticipantIds.push(participant.participantId);
        }

        paginatedParticipantIds.push(participant.participantId);
      }
    });

    const { toSelectParticipantId, requestVideoTrack } = findVideoTrack(
      sharedParticipantVideoTrack,
      sharedParticipantId,
      currentTeacherParticipant
    );

    if (
      toSelectParticipantId &&
      toSelectParticipantId !== myParticipantId &&
      requestVideoTrack &&
      !toSelectParticipantIds.includes(toSelectParticipantId)
    ) {
      toSelectParticipantIds.push(toSelectParticipantId);
    }

    if (
      displayParticipantId &&
      displayParticipantId !== myParticipantId &&
      !toSelectParticipantIds.includes(displayParticipantId)
    ) {
      toSelectParticipantIds.push(displayParticipantId);
    }

    const dominantSpeaker = dispatch(getDominantSpeaker());

    if (
      dominantParticipantId &&
      dominantParticipantId !== myParticipantId &&
      !toSelectParticipantIds.includes(dominantParticipantId) &&
      !dominantSpeaker?.videoMuted
    ) {
      toSelectParticipantIds.push(dominantParticipantId);
    }

    if (
      !_.isEqual(
        _.sortBy(selectedParticipantIds),
        _.sortBy(toSelectParticipantIds)
      )
    ) {
      dispatch(selectParticipants(toSelectParticipantIds));
    }

    return {
      pageNumber,
      totalPages,
      paginatedParticipantIds,
      showUpButton,
      showDownButton,
    };
  };

export const setVolumeLevel = (volumeLevel) => (dispatch) => {
  dispatch({
    type: SET_VOLUME_LEVEL,
    payload: volumeLevel,
  });
};

export const setStudentQualityOption =
  (quality) => async (dispatch, getState) => {
    const { jitsiX } = getState();
    const { selectedParticipantIds } = jitsiX;

    dispatch(selectParticipants(selectedParticipantIds, quality));

    dispatch(updateStudentPreferences({ quality }));
  };

export const getUserMediaPreview =
  (type = VIDEO, mute = false) =>
  async (dispatch) => {
    const mediaTracksOptions = {
      devices: [type],
    };

    let mediaTracks;

    try {
      mediaTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    mediaTracks = mediaTracks || [];

    const mediaTrack = mediaTracks[0];

    if (!mediaTrack) {
      return;
    }

    if (mute) {
      try {
        await mediaTrack.mute();
      } catch (e) {
        console.log(e);
      }
    }

    if (type === AUDIO) {
      dispatch({
        type: SET_MY_AUDIO_PREVIEW_TRACK,
        payload: mediaTrack,
      });
    } else {
      dispatch({
        type: SET_MY_VIDEO_PREVIEW_TRACK,
        payload: mediaTrack,
      });
    }

    dispatch(setMyJitsiPreviewTrackMuteChanged());
  };

const setMyJitsiPreviewTrackMuteChanged = () => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED,
    payload: null,
  });
};

export const muteMyVideoPreview = (mute) => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myVideoPreviewTrack } = jitsiX;

  if (myVideoPreviewTrack) {
    try {
      mute
        ? await myVideoPreviewTrack.mute()
        : await myVideoPreviewTrack.unmute();
    } catch (e) {
      console.log(e);
    }
  }

  dispatch(setMyJitsiPreviewTrackMuteChanged());
};

export const muteMyAudioPreview = (mute) => async (dispatch, getState) => {
  const { jitsiX } = getState();
  const { myAudioPreviewTrack } = jitsiX;

  if (myAudioPreviewTrack) {
    try {
      mute
        ? await myAudioPreviewTrack.mute()
        : await myAudioPreviewTrack.unmute();
    } catch (e) {
      console.log(e);
    }
  }

  dispatch(setMyJitsiPreviewTrackMuteChanged());
};

export const getDominantSpeaker = () => (dispatch, getState) => {
  const {
    liveClass,
    jitsiX: { dominantParticipantId, participants },
  } = getState();
  const { handRaises } = liveClass;

  if (!dominantParticipantId) return null;
  return calculateParticipantDetails(
    dominantParticipantId,
    participants,
    handRaises
  );
};

export const getMyDesktopParticipant = () => (dispatch, getState) => {
  const {
    liveClass,
    jitsiX: { myParticipantId, participants },
  } = getState();
  const { handRaises } = liveClass;

  if (!myParticipantId) return null;

  return calculateParticipantDetails(myParticipantId, participants, handRaises);
};
