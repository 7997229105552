import React from "react";
import IframeRenderer from "../../screens/IframeRenderer/IframeRenderer";

const StudentLandingMobile = React.lazy(() =>
  import(
    "../../screens/NewLandingPage/StudentLandingMobile/StudentLandingMobile"
  )
);
const TimeTable = React.lazy(() => import("../../screens/TimeTable/TimeTable"));
const StudentTimeTable = React.lazy(() =>
  import("../../screens/TimeTable/StudentTimeTable")
);
const Class = React.lazy(() => import("../../screens/Class_/Class"));
const LiveClass = React.lazy(() => import("../../screens/LiveClass/LiveClass"));
// const People = React.lazy(() => import("../../screens/People/People"));
const Resources = React.lazy(() =>
  import("../../screens/Resources_/Resources")
);
const Pinboard = React.lazy(() => import("../../screens/PinBoard/Pinboard"));
const StudentPinBoard = React.lazy(() =>
  import("../../screens/StudentPinBoard/StudentPinBoard")
);

const Planning = React.lazy(() => import("../../screens/Schedule/Planning"));

const LibraryDashboard = React.lazy(() =>
  import("../../screens/LibraryDashboard/LibraryDashboard")
);
const LibraryShared = React.lazy(() =>
  import("../../screens/LibraryShared/LibraryShared")
);
const LibraryMyFiles = React.lazy(() =>
  import("../../screens/LibraryMyFiles/LibraryMyFiles")
);
const LibraryTrash = React.lazy(() =>
  import("../../screens/LibraryTrash/LibraryTrash")
);

const MyBagShared = React.lazy(() =>
  import("../../screens/MyBagShared/MyBagShared")
);
const ResourcesLecture = React.lazy(() =>
  import("../../screens/Resources_Lecture/Resources_Lecture")
);
// const LoginPage = React.lazy(() => import("../../screens/Login/NewLogin"));
// const NewLanding = React.lazy(() =>
//   import("../../screens/NewLandingPage/NewLanding")
// );
const Website = React.lazy(() => import("../../screens/Website/Website"));

// const StudentLoginWeb = React.lazy(() =>
//   import("../../screens/Login/StudentLoginWeb")
// );
const InProgress = React.lazy(() => import("../../screens/Login/InProgress"));
const StudentLiveClass = React.lazy(() =>
  import("../../screens/StudentLiveClass/StudentLiveClass")
);
const SupportPage = React.lazy(() =>
  import("../../screens/Support/SupportPage")
);

const StudentResources = React.lazy(() =>
  import("../../screens/StudentResources/StudentResources")
);

const StudentMyBag = React.lazy(() =>
  import("../../screens/StudentMyBag/StudentMyBag")
);

const StudentLectureResources = React.lazy(() =>
  import("../../screens/StudentLectureResources/StudentLectureResources")
);

const Report = React.lazy(() => import("../../screens/Report/Report"));

const studentPlanning = React.lazy(() =>
  import("../../screens/StudentSchedule/StudentSchedule")
);

const ClassDetails = React.lazy(() =>
  import("../../screens/ClassDetails/ClassDetails")
);
const Administrator = React.lazy(() =>
  import("../../screens/AdminAdministrator/Administrator")
);

const BatchDetails = React.lazy(() =>
  import("../../screens/AdminAdministrator/Batches/BatchDetails/BatchDetails")
);

const BatchAnnoucements = React.lazy(() =>
  import(
    "../../screens/AdminAdministrator/Batches/BatchAnnouncements/BatchAnnoucements"
  )
);

const BatchClasses = React.lazy(() =>
  import("../../screens/AdminAdministrator/Batches/BatchClasses/BatchClasses")
);

const BatchReports = React.lazy(() =>
  import("../../screens/AdminAdministrator/Batches/BatchReports/BatchReports")
);

const BatchTimings = React.lazy(() =>
  import("../../screens/AdminAdministrator/Batches/BatchTiming/BatchTiming")
);

const CourseWork = React.lazy(() =>
  import("../../screens/CourseWork/CourseWork")
);

const ViewAssignment = React.lazy(() =>
  import("../../screens/CourseWork/ViewAssignment")
);

const StudentSubmission = React.lazy(() =>
  import("../../screens/CourseWork/StudentSubmission")
);

const Assignment = React.lazy(() =>
  import("../../screens/Assignment/Assignment")
);

const CreateEditQuiz = React.lazy(() =>
  import("../../screens/CourseWork/Quiz/CreateEditQuiz")
);

const StudentCourseWork = React.lazy(() =>
  import("../../screens/StudentCourseWork/StudentCourseWork")
);

const ViewAssignmentStudent = React.lazy(() =>
  import("../../screens/CourseWork/ViewAssignmentStudent")
);

const QuizDetails = React.lazy(() =>
  import("../../screens/CourseWork/Quiz/QuizDetails")
);

const QuizQuestion = React.lazy(() =>
  import("../../screens/CourseWork/Quiz/QuizQuestion")
);
const StudentQuiz = React.lazy(() =>
  import("../../screens/StudentCourseWork/Quiz/StudentQuiz")
);
const InstantLiveClass = React.lazy(() =>
  import("../../screens/InstantLiveClass/InstantLiveClass")
);
const StudentInstantLecture = React.lazy(() =>
  import("../../screens/StudentInstantLecture/StudentInstantLecture")
);
const TeacherAdmin = React.lazy(() =>
  import("../../screens/AdminAdministrator/Teachers/Teacher/TeacherAdmin")
);

const MobileBrowser = React.lazy(() =>
  import("../../screens/MobileBrowser/MobileBrowser")
);

const TeacherMobile = React.lazy(() =>
  import("../../screens/TeacherMobile/TeacherMobile")
);
const AdminBatches = React.lazy(() =>
  import("../../screens/AdminAdministrator/Batches/Batches")
);
const StudentDetails = React.lazy(() =>
  import(
    "../../screens/AdminAdministrator/Students/StudentDetails/StudentDetails"
  )
);
const AdminStudents = React.lazy(() =>
  import("../../screens/AdminAdministrator/Students/Students")
);
const TeacherDetails = React.lazy(() =>
  import(
    "../../screens/AdminAdministrator/Teachers/TeacherDetails/TeacherDetails"
  )
);
const Classes = React.lazy(() =>
  import("../../screens/AdminAdministrator/Classes/Classes")
);

export const recordingBotLiveClassRawPath = "/live-class-recording-view/";
export const testingStudentBotLiveClassRawPath =
  "/live-class-testBot-studentView/";
export const testingTeacherBotLiveClassRawPath =
  "/live-class-testBot-teacherView/";
export const screenNames = {
  mobileStudent: "/mobile/student",
  loginPage: "/",
  studentPage: "/student/app",
  timeTable: "/time-table",
  studentTimeTable: "/student/time-table",
  class: "/classroom",
  liveClass: "/live-class",
  studentLiveClass: "/student/live-class",
  studentLiveClassWithCode: "/student/live-class/:code",
  recordingBotLiveClass: recordingBotLiveClassRawPath + ":classId",
  testingStudentBotLiveClass: testingStudentBotLiveClassRawPath + ":classId",
  testingTeacherBotLiveClass: testingTeacherBotLiveClassRawPath + ":classId",
  pinboard: "/pin-board",
  studentPinBoard: "/student/pin-board",
  // people: "/people",
  resources: "/resources",
  libraryDashboard: "/dashboard",
  libraryShared: "/class-files",
  libraryMyFiles: "/my-files",
  libraryTrash: "/trash",
  resourcesLecture: "/resources-lecture",
  inProgress: "/in-progress",
  teacherMobile: "/teacher/mobile",
  // myBag: "/my-bag",
  report: "/report",
  coursework: "/coursework/:filter",
  studentCourseWork: "/student-courseWork",
  // classReport: "/class-report",
  supportPage: "/support",
  studentResources: "/student/resources",
  studentLectureResources: "/student/lecture/resources",
  planning: "/planning",
  studentPlanning: "/student/planning",
  classDetails: "/class-details",
  administrator: "/admin-administrator",
  adminBatches: "/admin/batches",
  batchDetails: "/batches/:batchId/details",
  batchClasses: "/batches/:batchId/classes",
  batchAnnoucements: "/batches/:batchId/annoucements",
  batchTiming: "/batches/:batchId/timing",
  batchReports: "/batches/:batchId/reports",
  adminStudents: "/admin/students/:tabkey",
  adminTeacherDetails: "/teachers/:teacherId/details",
  adminStudentDetails: "/students/:studentId/details",
  viewAssignment: "/coursework/:id/:tab",
  viewAssignmentStudent: "/student-courseWork/:id",
  editAssignment: "/assignment/edit/:id",
  studentSubmission: "/coursework/:id/submission/:asid/student/:sid",
  assignment: "/assignment",
  studentBagDashboard: "/student/dashboard",
  studentBagClassFiles: "/student/class-files",
  studentBagMyFiles: "/student/my-files",
  studentBagTrash: "/student/trash",
  quiz: "/quiz",
  editQuiz: "/quiz/edit/:id",
  quizDetails: "/quiz/:id",
  quizQuestion: "/quiz/:id/question/:qid",
  studentQuiz: "/student/quiz/:id",
  instantLiveClass: "/live-class/instant/teacher",
  studentInstantLecture: "/live-class/instant/student/:lecture_id",
  teacherAdmin: "/admin/teachers/:tabkey",
  iframeRenderer: "/iframe-render",
  adminClasses: "/admin/classes",
};

export const modules = {
  LOGIN: "login",
  CLASS: "class",
  TIMETABLE: "time_table",
  LIBRARY: "library",
  MYBAG: "my_bag",
  CHAT: "chat",
  REPORT: "report",
  PINBOARD: "pinboard",
  COURSEWORK: "coursework",
  SUPPORT: "support",
  ADMINISTRATOR: "administrator",
};

export const routes = {
  [screenNames.mobileStudent]: {
    component: MobileBrowser,
    belongsTo: modules.LOGIN,
  },
  [screenNames.loginPage]: {
    // component: LoginPage,
    component: Website,
    belongsTo: modules.LOGIN,
    displayName: "",
  },
  [screenNames.studentPage]: {
    // component: StudentLoginWeb,
    component: StudentLandingMobile,
    belongsTo: modules.LOGIN,
    displayName: "",
  },
  [screenNames.inProgress]: {
    component: InProgress,
    belongsTo: modules.LOGIN,
    displayName: "IN PROGRESS",
  },
  [screenNames.teacherMobile]: {
    component: TeacherMobile,
    belongsTo: modules.LOGIN,
    displayName: "",
  },
  [screenNames.timeTable]: {
    component: TimeTable,
    belongsTo: modules.TIMETABLE,
    displayName: "TIME TABLE",
  },
  [screenNames.studentTimeTable]: {
    component: StudentTimeTable,
    belongsTo: modules.TIMETABLE,
    displayName: "TIME TABLE",
  },
  [screenNames.class]: {
    component: Class,
    belongsTo: modules.CLASS,
    displayName: "CLASSROOM",
  },
  [screenNames.report]: {
    component: Report,
    belongsTo: modules.CLASS,
  },

  [screenNames.liveClass]: {
    component: LiveClass,
    belongsTo: modules.CLASS,
    displayName: "LIVE CLASS",
  },
  [screenNames.instantLiveClass]: {
    component: InstantLiveClass,
    belongsTo: modules.CLASS,
  },
  [screenNames.testingTeacherBotLiveClass]: {
    component: LiveClass,
    belongsTo: modules.CLASS,
  },
  [screenNames.studentLiveClass]: {
    component: StudentLiveClass,
    belongsTo: modules.CLASS,
    displayName: "LIVE CLASS",
  },
  [screenNames.studentLiveClassWithCode]: {
    component: StudentLiveClass,
    belongsTo: modules.CLASS,
    displayName: "LIVE CLASS",
  },
  [screenNames.studentInstantLecture]: {
    component: StudentInstantLecture,
    belongsTo: modules.CLASS,
  },
  [screenNames.recordingBotLiveClass]: {
    component: StudentLiveClass,
    belongsTo: modules.CLASS,
  },
  [screenNames.testingStudentBotLiveClass]: {
    component: StudentLiveClass,
    belongsTo: modules.CLASS,
  },
  [screenNames.studentPinBoard]: {
    component: StudentPinBoard,
    belongsTo: modules.CLASS,
    displayName: "PINBOARD",
  },
  [screenNames.pinboard]: {
    component: Pinboard,
    belongsTo: modules.CLASS,
    displayName: "PINBOARD",
  },
  // [screenNames.people]: {
  //   component: People,
  //   belongsTo: modules.CLASS,
  // },
  [screenNames.planning]: {
    component: Planning,
    belongsTo: modules.CLASS,
    displayName: "PLANNING",
  },
  [screenNames.coursework]: {
    component: CourseWork,
    belongsTo: modules.CLASS,
    displayName: "Coursework",
    fallback: "/coursework/all",
  },
  [screenNames.studentCourseWork]: {
    component: StudentCourseWork,
    belongsTo: modules.CLASS,
    displayName: "Coursework",
  },
  [screenNames.viewAssignment]: {
    component: ViewAssignment,
    belongsTo: modules.CLASS,
    displayName: "View Assignment",
    fallback: screenNames.coursework,
  },
  [screenNames.viewAssignmentStudent]: {
    component: ViewAssignmentStudent,
    belongsTo: modules.CLASS,
    displayName: "View Assignment Student",
    fallback: screenNames.studentCourseWork,
  },
  [screenNames.studentSubmission]: {
    component: StudentSubmission,
    belongsTo: modules.CLASS,
    displayName: "Student Submission",
    fallback: screenNames.coursework,
  },
  [screenNames.resources]: {
    component: Resources,
    belongsTo: modules.CLASS,
    displayName: "RESOURCES",
  },
  [screenNames.studentPlanning]: {
    component: studentPlanning,
    belongsTo: modules.CLASS,
    displayName: "PLAN",
  },

  [screenNames.resourcesLecture]: {
    component: ResourcesLecture,
    belongsTo: modules.CLASS,
    displayName: "RESOURCES LECTURE",
  },
  // [screenNames.classReport]: {
  //   component: ClassReport,
  //   belongsTo: modules.CLASS,
  // },
  [screenNames.libraryDashboard]: {
    component: LibraryDashboard,
    belongsTo: modules.LIBRARY,
    displayName: "MY LIBRARY",
  },
  [screenNames.libraryShared]: {
    component: LibraryShared,
    belongsTo: modules.LIBRARY,
    displayName: "CLASS FILES",
  },
  [screenNames.libraryMyFiles]: {
    component: LibraryMyFiles,
    belongsTo: modules.LIBRARY,
    displayName: "MY FILES",
  },
  [screenNames.libraryTrash]: {
    component: LibraryTrash,
    belongsTo: modules.LIBRARY,
    displayName: "TRASH",
  },
  // [screenNames.libraryTrash]: {
  //   component: LibraryTrash,
  //   belongsTo: modules.LIBRARY,
  // },
  [screenNames.supportPage]: {
    component: SupportPage,
    belongsTo: modules.SUPPORT,
    displayName: "SUPPORT",
  },
  [screenNames.studentResources]: {
    component: StudentResources,
    belongsTo: modules.CLASS,
    displayName: "RESOURCES",
  },
  [screenNames.studentBagDashboard]: {
    component: StudentMyBag,
    belongsTo: modules.MYBAG,
    displayName: "MY BAG",
  },
  [screenNames.studentBagClassFiles]: {
    component: MyBagShared,
    belongsTo: modules.MYBAG,
    displayName: "CLASS FILES",
  },
  [screenNames.studentBagMyFiles]: {
    component: LibraryMyFiles,
    belongsTo: modules.MYBAG,
    displayName: "MY FILES",
  },
  [screenNames.studentBagTrash]: {
    component: LibraryTrash,
    belongsTo: modules.MYBAG,
    displayName: "TRASH",
  },
  [screenNames.studentLectureResources]: {
    component: StudentLectureResources,
    belongsTo: modules.CLASS,
    displayName: "LECTURE RESOURCES",
  },
  [screenNames.classDetails]: {
    component: ClassDetails,
    belongsTo: modules.CLASS,
  },
  [screenNames.administrator]: {
    component: Administrator,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Administrator",
  },
  [screenNames.adminBatches]: {
    component: AdminBatches,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batches",
  },
  [screenNames.batchDetails]: {
    component: BatchDetails,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batch Details",
  },
  [screenNames.batchClasses]: {
    component: BatchClasses,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batch Classes",
  },
  [screenNames.batchAnnoucements]: {
    component: BatchAnnoucements,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batch Annoucements",
  },
  [screenNames.batchTiming]: {
    component: BatchTimings,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batch Timing",
  },
  [screenNames.batchReports]: {
    component: BatchReports,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Batch Reports",
  },
  [screenNames.adminTeacherDetails]: {
    component: TeacherDetails,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "STUDENTS",
  },
  [screenNames.adminStudents]: {
    component: AdminStudents,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "STUDENTS",
  },
  [screenNames.adminStudentDetails]: {
    component: StudentDetails,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "TEACHERS",
  },
  [screenNames.assignment]: {
    component: Assignment,
    belongsTo: modules.CLASS,
    displayName: "Assignment",
  },
  [screenNames.editAssignment]: {
    component: Assignment,
    belongsTo: modules.CLASS,
    displayName: "Edit Assignment",
    fallback: screenNames.coursework,
  },
  [screenNames.quizDetails]: {
    component: QuizDetails,
    belongsTo: modules.CLASS,
    displayName: "Quiz Details",
    fallback: screenNames.coursework,
  },
  [screenNames.quiz]: {
    component: CreateEditQuiz,
    belongsTo: modules.CLASS,
    displayName: "Quiz",
  },
  [screenNames.editQuiz]: {
    component: CreateEditQuiz,
    belongsTo: modules.CLASS,
    displayName: "Edit Quiz",
    fallback: screenNames.coursework,
  },
  [screenNames.quizQuestion]: {
    component: QuizQuestion,
    belongsTo: modules.CLASS,
    displayName: "Quiz Question Details",
    fallback: screenNames.coursework,
  },
  [screenNames.studentQuiz]: {
    component: StudentQuiz,
    belongsTo: modules.CLASS,
    displayName: "Student Quiz",
    fallback: screenNames.studentCourseWork,
  },
  [screenNames.teacherAdmin]: {
    component: TeacherAdmin,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Teacher Admin",
  },
  [screenNames.adminClasses]: {
    component: Classes,
    belongsTo: modules.ADMINISTRATOR,
    displayName: "Classrooms",
  },
  [screenNames.iframeRenderer]: {
    component: IframeRenderer,
    belongsTo: modules.CLASS,
  },
};
