import { isNil } from "lodash";
import { matchPath } from "react-router-dom";
import { screenNames } from "../../components/AppRouter/routes";
import {
  backendApi,
  FIREBASE_DYNAMICLINK_API,
  INSTANT_LIVE_CLASS_URL,
  LINK_DYNAMICLINK_URL,
} from "../../config/constants";
import { getDeviceDetails } from "../../utils/deviceDetails";
import makeApiRequest from "../../utils/makeApiRequest";
import { getSocket } from "../../utils/socketio";
import {
  SET_CURRENT_CLASS,
  SET_CURRENT_FOLDER_ID,
  SET_INSTANT_LIVECLASS_SESSION_ID,
  SET_JITSI_CONFERENCE_DETAILS,
  SET_LIVE_LECTURE,
} from "../types";
import { setLiveClassSettings } from "./classActions";
import { moveToLiveLectureFolder } from "./fileSystemActions";
import { instantJitsi } from "./instantLectureJitsiActions";
import {
  addLiveClassSocketListenersForTeachers,
  joinLiveClass,
} from "./liveClassActions";
import { addLiveQuizSocketListenersForTeachers } from "./liveQuizActions";
import { setAppMicroservices } from "./secretActions";
import { setSessionId, setUserDetails } from "./userActions";
import { errorAlert, getInstantLiveclassSessionId } from "./utils";

/**
 * @param {{role: "teacher" | "student", name:string}} p0
 * @returns {{session_id: string, user: Object<string, any>}} details
 */
const createTempSession =
  ({ role, name }) =>
  async (dispatch) => {
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `instant-lecture/temporary/${role}/get-temp-token?name=${name}`,
      false
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
    }

    return response.response;
  };

const setInstantLiveclassSessionId = (session_id) => (dispatch) => {
  localStorage.setItem("i_session_id", session_id);
  dispatch({
    type: SET_INSTANT_LIVECLASS_SESSION_ID,
    payload: session_id,
  });
};

const startInstantLectureApi = () => async (dispatch) => {
  const sessionId = await dispatch(getInstantLiveclassSessionId());

  const response = await makeApiRequest(
    "POST",
    backendApi,
    `instant-lecture/teacher/start-instant-lecture`,
    true,
    undefined,
    sessionId
  );

  if (response.error) {
    dispatch(errorAlert(response.error.message));
    return { success: false };
  }

  return response.response;
};

const joinInstantLectureApi =
  ({ lecture_id }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());

    const response = await makeApiRequest(
      "POST",
      backendApi,
      `instant-lecture/teacher/join-live`,
      true,
      { lecture_id },
      sessionId
    );

    if (response.error) {
      const isLectureFinished =
        response.message === "Not a valid instant lecture";
      /**
       * show error alert for erros other than the one mentioned above
       * cause for this error a link expired popup will be shown
       */
      if (!isLectureFinished) {
        dispatch(errorAlert(response.message));
      }
      return response;
    }

    return response.response;
  };

const getFileUploadUrl =
  ({ user_role, class_id, parent_id, file, lecture_id }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());

    const requestBody = {
      class_id,
      parent_id,
      lecture_id,
      name: file.name,
      file_mime_type: file.type,
      file_size: file.size,
      inherit: "abc",
    };

    if (user_role === "student") {
      requestBody.class_id = null;
    }

    const response = await makeApiRequest(
      "POST",
      backendApi,
      `library/instant-lecture/file/create`,
      true,
      requestBody,
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
      return [undefined, undefined];
    }

    const element = response.response.element;
    const uploadUrl = response.response.upload_url;
    const { upload_method, upload_url_request_data } = response.response;
    return [uploadUrl, element, upload_method, upload_url_request_data];
  };

const getFolderChildrenApi =
  ({ folder_id, class_id, lecture_id }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/instant-lecture/folder/children",
      true,
      {
        class_id: class_id ?? null,
        lecture_id,
        element_id: folder_id,
        get_element: true,
      },
      sessionId,
      false
    );

    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }

    return response.response;
  };

const getFormattedUserDetails = (userObject) => {
  const userDetails = {
    id: userObject.id,
    name: userObject.name,
    createdAt: userObject.created_at,
    email: userObject.email,
    details: userObject.details,
    country: userObject.country,
    phoneNumber: userObject.phone_number,
    phoneCountryCode: userObject.phone_country_code,
    notifications: userObject.notifications,
    profilePhotoUrl: userObject.profile_photo_url,
    role: userObject.role,
    status: userObject.status,
    type: userObject.type,
    folder_id: userObject.folder_id,
    userGroupId: userObject.user_group_id,
    floatingUserId: userObject.floating_user_id,
    hasSetPassword: userObject.has_set_password,
  };

  return userDetails;
};

/**
 * @param {{ role: "teacher" | "student", name:string }} p0
 */
const initializeInstantLecture =
  ({ role, name }) =>
  async (dispatch) => {
    const response = await dispatch(createTempSession({ role, name }));
    dispatch(setInstantLiveclassSessionId(response.session_id));
    let userDetails = getFormattedUserDetails(response.user);
    userDetails.isInstantLiveClassEmailAuthenticated = false;
    dispatch(setUserDetails(userDetails));
    // save rtc url to redux
    dispatch(setAppMicroservices({ rtcUrl: response.RTC_URL }));
    // connect socket, this should happen before start/join of instant-lecture
    // for both teacher and student
    getSocket(response.RTC_URL, response.session_id);
    return response;
  };

const startInstantLecture = () => async (dispatch) => {
  const response = await dispatch(startInstantLectureApi());

  if (response.success) {
    dispatch(setLiveClassSettings(response.liveclass_settings));

    dispatch({
      type: SET_LIVE_LECTURE,
      payload: response.lecture,
    });

    await dispatch({
      type: SET_JITSI_CONFERENCE_DETAILS,
      payload: {
        conferenceId: response.conference.conference.jitsi_conference_id,
        jitsiRootDomain: response.conference.jitsiRootDomain,
      },
    });

    // set class_id and folder_id to redux, these will be used
    // by file upload actions
    dispatch({
      type: SET_CURRENT_CLASS,
      payload: response.lecture.class_id,
    });

    dispatch({
      type: SET_CURRENT_FOLDER_ID,
      payload: response.lecture.folder_id,
    });
  }
  return response;
};

const joinInstantLecture =
  ({ lecture_id }) =>
  async (dispatch) => {
    const response = await dispatch(joinInstantLectureApi({ lecture_id }));

    if (response.success) {
      dispatch({
        type: SET_LIVE_LECTURE,
        payload: response.lecture,
      });

      await dispatch({
        type: SET_JITSI_CONFERENCE_DETAILS,
        payload: {
          conferenceId: response.conference.conference.jitsi_conference_id,
          jitsiRootDomain: response.conference.jitsiRootDomain,
        },
      });

      // set class_id and folder_id to redux, these will be used
      // by file upload actions
      dispatch({
        type: SET_CURRENT_CLASS,
        payload: response.lecture.class_id,
      });

      dispatch({
        type: SET_CURRENT_FOLDER_ID,
        payload: response.lecture.folder_id,
      });

      dispatch(setLiveClassSettings(response.liveclass_settings));
    }

    return response;
  };

const studentJoinInstantLectureAPI = (lecture_id) => async (dispatch) => {
  const sessionId = await dispatch(getInstantLiveclassSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "instant-lecture/student/join-live",
    true,
    { lecture_id },
    sessionId
  );

  if (response.error) {
    const isLectureFinished =
      response.message === "Not a valid instant lecture";
    /**
     * show error alert for erros other than the one mentioned above
     * cause for this error a link expired popup will be shown
     */
    if (!isLectureFinished) {
      dispatch(errorAlert(response.message));
    }
    return response;
  }

  dispatch(setLiveClassSettings(response.response.liveclass_settings));

  return response.response;
};

const getLectureTopicName =
  ({ lecture_id }) =>
  async (dispatch) => {
    const response = await makeApiRequest(
      "GET",
      backendApi,
      `instant-lecture/lecture/topic?lecture_id=${lecture_id}`,
      false
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
    }

    return response.response;
  };

const isCurrentPathBelongsToInstantLiveClass = () => {
  const matchResponse = matchPath(window.location?.pathname, {
    path: [screenNames.instantLiveClass, screenNames.studentInstantLecture],
    exact: true,
    strict: true,
  });

  return !isNil(matchResponse) && matchResponse.isExact;
};

/**
 * @param {{ email: "string", userId:int }} p0
 */
const instantLectureTempTeacherAuth =
  ({ email, userId }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());

    const response = await makeApiRequest(
      "POST",
      backendApi,
      `instant-lecture/temporary/teacher/email-auth?email=${email}&user_id=${userId}`,
      true,
      undefined,
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.message));
      return response;
    }

    return response.response;
  };

const verifyInstantTempTeacherOtp =
  ({ email, authType, otp, countryCode, userId, isHost, lectureId }) =>
  async (dispatch, getState) => {
    let body = {};
    if (authType === "email") {
      body = { email: email, otp, user_id: userId };
    } else if (authType === "phone") {
      body = {
        phone: {
          country_code: countryCode,
          number: email,
        },
        otp,
        user_id: userId,
      };
    } else {
      dispatch(errorAlert("Something went wrong. Please try again."));
      return -1;
    }

    body.lecture_id = lectureId;
    body.device_details = getDeviceDetails();

    const signUpResponse = await makeApiRequest(
      "POST",
      backendApi,
      "instant-lecture/temporary/teacher/verify-otp",
      false,
      body
    );
    if (signUpResponse.error) {
      dispatch(errorAlert(signUpResponse.message));
      return -1;
    }

    if (!signUpResponse.response.success && signUpResponse.response.wrong_otp) {
      return { errorCode: 1, ...signUpResponse.response };
    }

    if (!signUpResponse.response.success) {
      dispatch(errorAlert(signUpResponse.message));
      return -1;
    }

    dispatch(setInstantLiveclassSessionId(signUpResponse.response.session_id));
    // after otp verification reconnect socket with the new session id
    getSocket(undefined, signUpResponse.response.session_id, true);
    let userDetails = getFormattedUserDetails(signUpResponse.response.user);
    // userDetails.isInstantLiveClassEmailAuthenticated = true;
    dispatch(setUserDetails(userDetails));
    let studentLink = null;
    let teacherLink = null;
    studentLink = await dispatch(
      generateInstantLiveClassDynamicLink({
        role: "student",
        email: null,
        lectureId,
      })
    );
    /** Generate teacherLink only if teacher is a host */
    if (isHost) {
      teacherLink = await dispatch(
        generateInstantLiveClassDynamicLink({
          role: "teacher",
          email,
          lectureId,
        })
      );
    }
    await dispatch(
      setDynamicLinkDetails({ studentLink, teacherLink, lectureId })
    );
    if (teacherLink && studentLink && isHost) {
      /** If the teacher link is generated successfully and if teacher is a host, then
       *  send re-join link to teacher's email id
       */
      await dispatch(
        inviteSelf({
          lectureId,
          domain: window.location.origin,
          teacherLink,
          studentLink,
        })
      );
    }
    return { errorCode: 0, ...signUpResponse.response };
  };

const setDynamicLinkDetails =
  ({ studentLink, teacherLink, coTeacherLink }) =>
  async (dispatch, getState) => {
    let { user } = getState();
    if (studentLink) user.instantLiveClassStudentLink = studentLink;
    if (teacherLink) user.instantLiveClassTeacherLink = teacherLink;
    if (coTeacherLink) user.instantLiveClassCoTeacherLink = teacherLink;
    dispatch(setUserDetails(user));
  };

const getDynamicLinkInfo = () => {
  // TODO : More data to be added here
  return {
    dynamicLinkInfo: {
      domainUriPrefix: LINK_DYNAMICLINK_URL,
      androidInfo: {
        androidPackageName: "com.learngramlive",
      },
      iosInfo: {
        iosBundleId: "ai.learngram.link",
      },
      socialMetaTagInfo: {
        socialImageLink: "https://linkclassroom.com/mstile-70x70.png",
        socialTitle: "Link Classroom | Instant Classroom links",
        socialDescription:
          "Empower your Zoom or Google Meet classes with our Free interactive tools",
      },
    },
  };
};

const generateInstantLiveClassDynamicLink =
  ({ role, email, lectureId }) =>
  async (dispatch, getState) => {
    let body = {
      ...getDynamicLinkInfo(),
    };

    if (role === "teacher") {
      body.dynamicLinkInfo.link = `${INSTANT_LIVE_CLASS_URL}/${role}?h=t&lectureId=${lectureId}&email=${email}`;
    }

    if (role === "coTeacher") {
      body.dynamicLinkInfo.socialMetaTagInfo.socialTitle =
        "Link Classroom | Invitation to join the Classroom";
      body.dynamicLinkInfo.link = `${INSTANT_LIVE_CLASS_URL}/teacher?lectureId=${lectureId}&email=${email}`;
    }

    if (role === "student") {
      body.dynamicLinkInfo.link = `${INSTANT_LIVE_CLASS_URL}/${role}/${lectureId}`;
      body.dynamicLinkInfo.socialMetaTagInfo.socialTitle =
        "Link Classroom | Invitation to join the Classroom";
      body.dynamicLinkInfo.socialMetaTagInfo.socialDescription =
        "Join Link Classroom along with your Zoom or Google Meet classes for better Engagement and Interactivity";
    }
    const response = await makeApiRequest(
      "POST",
      FIREBASE_DYNAMICLINK_API,
      `?key=${process.env.REACT_APP_FIREBASE_TOKEN}`,
      false,
      body
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
    }

    return response.response.shortLink;
  };

const resendInstantTempTeacherOtp =
  ({ email, authType, userId }) =>
  async (dispatch) => {
    let body = {};
    if (authType === "email") {
      body = { email: email };
    } else if (authType === "phone") {
      body = {
        phone: {
          number: email,
        },
      };
    } else {
      dispatch(errorAlert("Invalid Email/Phone"));
      return false;
    }
    body.user_id = userId;
    body.domain = window.location.origin || "";
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "instant-lecture/temporary/teacher/resend-otp",
      false,
      body
    );
    // if (response.logout) {
    //   dispatch(logoutProcedure(false));
    // }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }

    return response.response;
  };

const inviteSelf =
  ({ lectureId, domain, studentLink, teacherLink }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());
    const body = {
      student_link: studentLink,
      teacher_link: teacherLink,
      lecture_id: lectureId,
      domain,
    };

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "instant-lecture/teacher/creator/email",
      true,
      body,
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
      return { success: false };
    }

    return response.response;
  };

const inviteTeacher =
  ({ lectureId, domain, emailLinks }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());
    const body = {
      email_links: emailLinks,
      lecture_id: lectureId,
      domain,
    };

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "instant-lecture/teacher/invites",
      true,
      body,
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.error.message));
      return { success: false };
    }

    return response.response;
  };

const getInstantLectureTeachers = () => async (dispatch, getState) => {
  const {
    jitsi: { participants },
  } = getState();

  let teachers = [];

  if (participants.length > 0) {
    teachers = participants.filter(
      (participant) => participant.userType === "TEACHER"
    );
  }

  return teachers;
};

const determineInstantLiveClassTeacherName =
  ({ isHostTeacher }) =>
  async (dispatch, getState) => {
    const {
      user: { name },
    } = getState();

    /**
     * If the teacher is the host , the host will always be Teacher 1
     */
    if (isHostTeacher) {
      if (name === "You") {
        dispatch(setUserDetails({ name: "Teacher 1" }));
      }
    } else {
      /** If the teacher is not the host, then get the current teachers count and assign the teacher
       * a teacher name
       */

      /** Check the current teacher name, if it is set to "You", then it means
       * the teacher is a first time user, if the teacher name is not "You" , the
       * account name is not changed
       */
      if (name === "You") {
        const currentInstantLiveClassTeachers = dispatch(
          getInstantLectureTeachers
        );
        dispatch(
          setUserDetails({
            name: `Teacher ${currentInstantLiveClassTeachers.length}`,
          })
        );
      }
    }
  };

export const instantLecture = () => async (dispatch, getState) => {
  const iSessionId = await dispatch(getInstantLiveclassSessionId());
  const {
    user: { isInstantLiveClassEmailAuthenticated },
  } = getState();
  return {
    teacher: () => {
      return {
        // creates temp sessionId, store in local storage and set user details in redux
        initialize: ({ user_name }) =>
          dispatch(
            initializeInstantLecture({ role: "teacher", name: user_name })
          ),

        startLecture: () => dispatch(startInstantLecture()),

        joinLecture: ({ lecture_id }) =>
          dispatch(joinInstantLecture({ lecture_id })),

        addSocketListeners: () => {
          dispatch(moveToLiveLectureFolder());

          /**
           * emit @event JOIN_LIVE_CLASS, if successful, fetch lecture archive
           */
          dispatch(joinLiveClass());
          dispatch(addLiveClassSocketListenersForTeachers());
          dispatch(addLiveQuizSocketListenersForTeachers());
        },

        /**
         * @initJitsiConnection will use its own implementation and is different from
         * usual live class initJitsiConnection.
         */
        initJitsiConnection: async ({ forceReConnect }) => {
          const instantJitsiActions = await dispatch(instantJitsi());
          instantJitsiActions.initJitsiConnection(forceReConnect);
        },
        /**
         * @param {{ email: "string", userId:int }} p0
         */
        auth: ({ email, userId }) =>
          dispatch(instantLectureTempTeacherAuth({ email, userId })),

        verifyOTP: ({
          email,
          authType,
          otp,
          countryCode,
          userId,
          isHost,
          lectureId,
        }) =>
          dispatch(
            verifyInstantTempTeacherOtp({
              email,
              authType,
              otp,
              countryCode,
              userId,
              isHost,
              lectureId: Number(lectureId),
            })
          ),
        inviteSelf: ({ lectureId, domain, studentLink, teacherLink }) =>
          dispatch(
            inviteSelf({
              lectureId,
              domain,
              studentLink,
              teacherLink,
            })
          ),
        invite: ({ lectureId, domain, emailLinks }) =>
          dispatch(
            inviteTeacher({
              lectureId,
              domain,
              emailLinks,
            })
          ),
        generateDynamicLink: ({ role, email, lectureId }) =>
          dispatch(
            generateInstantLiveClassDynamicLink({ role, email, lectureId })
          ),
        resendOTP: ({ email, authType, userId }) =>
          dispatch(
            resendInstantTempTeacherOtp({
              email,
              authType,
              userId,
            })
          ),
        setInstantLiveClassSettings: (settings) =>
          dispatch(setLiveClassSettings(settings)),
        resetSession: () => {
          if (isInstantLiveClassEmailAuthenticated) {
            /**
             * update actual sessionId with iSessionId so that user goes
             * back to classroom page and continues with the logged in session
             * instead of starting over again
             */
            dispatch(setSessionId(iSessionId));
          }
          // clear iSessionId
          dispatch(setInstantLiveclassSessionId(""));
        },
        getFileUploadUrl: ({
          user_role,
          class_id,
          parent_id,
          file,
          lecture_id,
        }) =>
          dispatch(
            getFileUploadUrl({
              user_role,
              class_id,
              parent_id,
              file,
              lecture_id,
            })
          ),
        getFolderChildren: ({ folder_id, class_id, lecture_id }) =>
          dispatch(getFolderChildrenApi({ folder_id, class_id, lecture_id })),
        determineTeacherName: ({ isHostTeacher }) =>
          dispatch(determineInstantLiveClassTeacherName({ isHostTeacher })),
      };
    },
    student: () => {
      return {
        // creates temp sessionId, store in local storage and set user details in redux
        initialize: ({ user_name }) =>
          dispatch(
            initializeInstantLecture({ role: "student", name: user_name })
          ),

        // joins a lecture with the temp sessionId and name
        join: async (lecture_id) => {
          const response = await dispatch(
            studentJoinInstantLectureAPI(lecture_id)
          );

          if (response.success) {
            dispatch({
              type: SET_LIVE_LECTURE,
              payload: response.lecture,
            });

            await dispatch({
              type: SET_JITSI_CONFERENCE_DETAILS,
              payload: {
                conferenceId:
                  response.conference.conference.jitsi_conference_id,
                jitsiRootDomain: response.conference.jitsiRootDomain,
              },
            });
          }

          return response;
        },
        /**
         * @initJitsiConnection will use its own implementation and is different from
         * usual live class initJitsiConnection.
         */
        initJitsiConnection: async () => {
          const instantJitsiActions = await dispatch(instantJitsi());
          instantJitsiActions.initJitsiConnection();
        },

        getLectureTopicName: async ({ lecture_id }) => {
          const response = await dispatch(
            getLectureTopicName({ lecture_id: Number(lecture_id) })
          );
          if (response?.success) {
            dispatch({
              type: SET_LIVE_LECTURE,
              payload: { topic: response.topic },
            });
          }
        },
      };
    },
    isInstantLecturePage: async () => {
      const iSessionId = await dispatch(getInstantLiveclassSessionId());

      // match path name to instant live class
      if (isCurrentPathBelongsToInstantLiveClass() && iSessionId) {
        return true;
      }

      return false;
    },
  };
};

export const editInstantLectureUserProfile =
  (data) => async (dispatch, getState) => {
    const sessionId = await dispatch(getInstantLiveclassSessionId());

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/profile",
      true,
      data,
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.message));
    } else {
      const userDetails = {
        name: response.response.user.name,
        country: response.response.user?.country,
        phone: response.response.user?.phone_number,
      };
      dispatch(setUserDetails(userDetails));
    }

    return { error: response.error, message: response.message };
  };
