import { RECORDING_BOT } from "../../utils/constants";
import {
  SET_LAST_VISITED_ROUTE,
  SET_SIDEBAR_ACTIVE_OPTION,
  SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION,
  SHOW_CLASS_NAVIGATION_OPTIONS,
  SET_COPY_LINK_MESSAGE,
  SHOW_COPY_LINK_MESSAGE,
  PUSH_TO_TOAST_UPCOMING_QUEUE,
  POP_FROM_TOAST_UPCOMING_QUEUE,
  SET_CURRENT_TOAST,
  SET_PRESENTATION_DIMENSIONS,
  SET_CALENDAR_TYPE,
  SET_POST_ON_TOP,
  SET_CONNECTION_ERROR_TYPE,
} from "../types";

export const setSidebarActiveOption = (optionName) => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_ACTIVE_OPTION,
    payload: optionName,
  });
};

export const setShowClassNavigationState = (value) => (dispatch) => {
  dispatch({
    type: SHOW_CLASS_NAVIGATION_OPTIONS,
    payload: value,
  });
};

export const setTopNavigationBarActiveOption = (optionName) => (dispatch) => {
  dispatch({
    type: SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION,
    payload: optionName,
  });
};

export const setCopyLinkMessage = (value) => (dispatch) => {
  dispatch({
    type: SET_COPY_LINK_MESSAGE,
    payload: value,
  });
};

export const setLastVisitedRoute = (route) => (dispatch) => {
  dispatch({
    type: SET_LAST_VISITED_ROUTE,
    payload: route,
  });
};

export const addToastNotification = (message) => (dispatch, getState) => {
  const {
    user: { role: userRole },
  } = getState();
  if (userRole?.toUpperCase() === RECORDING_BOT) {
    return;
  }
  dispatch({
    type: PUSH_TO_TOAST_UPCOMING_QUEUE,
    payload: message,
  });
};

export const popFromToastUpcomingQueue = () => (dispatch) => {
  dispatch({
    type: POP_FROM_TOAST_UPCOMING_QUEUE,
  });
};

export const setCurrentToast = (message) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TOAST,
    payload: message,
  });
};

export const setPresentationDimensions =
  ({ width, height }) =>
  (dispatch) => {
    dispatch({
      type: SET_PRESENTATION_DIMENSIONS,
      payload: { width, height },
    });
  };

export const setCalendarType = (calendarType) => (dispatch) => {
  dispatch({ type: SET_CALENDAR_TYPE, payload: calendarType });
};

export const setPostOnTop = (postId) => (dispatch) => {
  dispatch({ type: SET_POST_ON_TOP, payload: postId });
};

export const setConnectionErrorType = (errorType) => (dispatch) => {
  dispatch({ type: SET_CONNECTION_ERROR_TYPE, payload: errorType });
};
