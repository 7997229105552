import React, { Component } from "react";
import PropTypes from "prop-types";
import "./EllipsisTextStyle.css";

export default class EllipsisText extends Component {
  render() {
    const { numberOfLines, className, style, title } = this.props;
    const classNameProp = className
      ? className
      : ""; /** to prevent undefined or null case */
    return (
      <>
        {numberOfLines > 1 ? (
          <textarea
            rows={numberOfLines}
            readOnly
            disabled
            id="ellipsis__text__wrapper"
            className={`ellipsis__text__wrapper ${classNameProp}`}
            title={this.props.title || this.props.children}
            value={this.props.children}
            style={{ ...style }}
          ></textarea>
        ) : (
          <div
            className={`ellipsis__text__wrapper single__line__ellipsis ${classNameProp}`}
            title={this.props.title || this.props.children}
            style={{ ...style }}
          >
            {this.props.children}
          </div>
        )}
      </>
    );
  }
}

EllipsisText.propTypes = {
  numberOfLines:
    PropTypes.number /** this ensures that ellipsis gets applied after that many line */,
  className: PropTypes.string,
};

EllipsisText.defaultProps = {
  numberOfLines: 1,
};
