import {
  SET_SHOW_DETAILS_POPUP,
  SET_DETAILS_POPUP_ELEMENT,
  SET_DELETE_POPUP_ELEMENT,
  SET_SHOW_DELETE_POPUP,
  SET_IS_MOVE,
  SET_MOVE_COPY_POPUP_ELEMENT,
  SET_SHOW_MOVE_COPY_POPUP,
  SET_SHOW_SHARE_FILE_FOLDER_POPUP,
} from "../types";
import {
  getFolderChildElementList,
  setTreeSelectedFolderId,
} from "./fileSystemActions";

export const openShowDetailsPopup = (elementId) => (dispatch) => {
  dispatch({ type: SET_DETAILS_POPUP_ELEMENT, payload: elementId });
  dispatch({ type: SET_SHOW_DETAILS_POPUP, payload: true });
};

export const closeShowDetailsPopup = () => (dispatch) => {
  dispatch({ type: SET_SHOW_DETAILS_POPUP, payload: false });
};

export const openDeleteFilePopup = (elementId) => (dispatch) => {
  dispatch({ type: SET_DELETE_POPUP_ELEMENT, payload: elementId });
  dispatch({ type: SET_SHOW_DELETE_POPUP, payload: true });
};

export const closeDeleteFilePopup = () => (dispatch) => {
  dispatch({ type: SET_SHOW_DELETE_POPUP, payload: false });
};

export const openMoveAndCopyFilePopup = (isMove, elementId) => (dispatch) => {
  dispatch({ type: SET_IS_MOVE, payload: isMove });
  dispatch({ type: SET_MOVE_COPY_POPUP_ELEMENT, payload: elementId });
  dispatch({ type: SET_SHOW_MOVE_COPY_POPUP, payload: true });
};

export const closeMoveAndCopyFilePopup = () => (dispatch, getState) => {
  const {
    klass: { currentClass },
    fileSystem: { currentFolderId },
  } = getState();
  dispatch(setTreeSelectedFolderId(currentFolderId, true));
  dispatch({ type: SET_SHOW_MOVE_COPY_POPUP, payload: false });
  dispatch(getFolderChildElementList(currentFolderId, currentClass));
};

export const openSharedFileFolderPopup = (isShare, elementId) => (dispatch) => {
  dispatch({ type: SET_SHOW_SHARE_FILE_FOLDER_POPUP, payload: true });
  dispatch({ type: SET_DETAILS_POPUP_ELEMENT, payload: elementId });
};

export const closeShareFileFolderPopup = () => (dispatch) => {
  dispatch({ type: SET_SHOW_SHARE_FILE_FOLDER_POPUP, payload: false });
};

export const updateDetailId = (elementId) => (dispatch) => {
  dispatch({ type: SET_DETAILS_POPUP_ELEMENT, payload: elementId });
};
