import store from "../store";
import dayjs from "dayjs";
import {
  checkIfBreadCrumbTextOverflowParentBounds,
  sortArrayByDateDescending,
} from "../store/actions/utils";
import { filesKindType, filesStatus } from "./tinyUtils";
import { screenNames } from "../components/AppRouter/routes";
import { LIVECLASS_SUPPORTED_FILES, SLIDE_SHARE_STATE } from "./constants";
import fileDownload from "js-file-download";
import axios from "axios";
import mimeDB from "mime-db";
import instantLectureUtils from "../components/InstantLectureModule/InstantLectureUtils";
export const isFolder = (element) => {
  return element.kind === filesKindType.folder;
};

export const isLectureRecording = (element) => {
  return element?.metadata?.recording_video;
};

export const isLectureThumbnail = (element) => {
  return element?.metadata?.recording_thumbnail;
};

export const isTempFolder = (fileName = "") => {
  // DEPRECATED: no need to check if an element is temp for hiding it. just check if it is a system file
  // e.g. .class-40.temp
  return fileName.match(/(.*)(\.temp)/) || fileName.match(/(.*)(\.trash)/);
};

export const isLectureFolder = (element) => {
  return !!element?.metadata?.lecture_id;
};

export const isClassFolder = (element) => {
  return !!element?.metadata?.class_id;
};

export const isSystemFile = (element) => {
  return element?.metadata?.system_file;
};

export const isSpecialFile = (element) => {
  // files that are system generated but are shown to user. Different kind of system files are shown in different places
  // Eg: recording video, recording thumbnail
  return element?.metadata?.special_file;
};

export const isSessionFilesFolder = (element) => {
  return element?.metadata?.session_files;
};

export const isPostAttachmentFolder = (element) => {
  return element?.metadata?.post_attachment_folder;
};

export const isQAFolder = (element) => {
  return element?.metadata?.qa_files;
};

export const isTrashFile = (element) => {
  return element?.status === filesStatus.trash;
};

export const isWhiteboardsFolder = (element) => {
  return !!element?.metadata?.whiteboards_folder;
};

export const isQuizFolder = (element) => {
  return element?.metadata?.is_quiz;
};

export const filterUserViewableFilesAndFolder = (list = [], include = []) => {
  return list
    .filter(
      (element) => element?.name.trim().length > 0 && !isTrashFile(element)
    )
    .filter((element) => {
      if (include.length > 0) {
        return !isSystemFile(element) || include.some((func) => func(element));
      } else {
        return !isSystemFile(element);
      }
    });
};

export const convertDataToFrontEndFormat = (data) => {
  let result = {};
  let dfsStack = [];
  const rootFolderId = data.id;
  dfsStack.push(data);
  while (dfsStack.length > 0) {
    let currentElement = dfsStack.pop();
    result[currentElement.id] = {
      isShared: currentElement.shared,
      isTrashed: currentElement.trashed,
      fileLocation: currentElement.file_location,
      mimeType: currentElement.mime_type,
      thumbnail_link: currentElement.thumbnail_link,
      ...currentElement,
      children: currentElement.children?.map((x) => x.id),
      isTopLevel: currentElement.parent === rootFolderId,
      fetched: false,
    };
    if (currentElement.children)
      dfsStack = dfsStack.concat(currentElement.children);
  }
  return result;
};

export const getTopTrashedElementIdList = (data) => {
  let topTrashedElementIdList = [];
  let dfsQueue = [];
  dfsQueue.push(data);
  while (dfsQueue.length > 0) {
    let currentElement = dfsQueue.pop();
    if (currentElement.trashed) {
      topTrashedElementIdList.push(currentElement.id);
    } else {
      dfsQueue = dfsQueue.concat(currentElement.children);
    }
  }
  return topTrashedElementIdList;
};

export const getHumanReadableSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const doesBelongToUser = (elementId) => {
  const { fileSystem, user } = store.getState();
  const { fileSystemData } = fileSystem;
  const { folder_id } = user;
  let currentElementIndex = elementId;
  while (currentElementIndex !== 1) {
    if (currentElementIndex === folder_id) {
      return true;
    }
    if (!currentElementIndex) {
      return false;
    }
    currentElementIndex = fileSystemData[currentElementIndex]?.parent_id;
  }
  return false;
};

export const getNumFiles = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let numFiles = 0;
  let dfsStack = [];
  dfsStack.push(elementId);
  while (dfsStack.length > 0) {
    let currentElementId = dfsStack.pop();
    const element = fileSystemData[currentElementId];
    if (
      element?.isTrashed ||
      (element?.name?.startsWith("__") && element?.name?.endsWith("__"))
    ) {
      continue;
    }
    if (fileSystemData[currentElementId]?.kind === filesKindType.file) {
      numFiles++;
    } else {
      dfsStack = dfsStack.concat(
        fileSystemData[currentElementId]?.children || []
      );
    }
  }
  return numFiles;
};

export const getNumOfLiveClassSupportedFiles = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let numFiles = 0;
  let dfsStack = [];
  dfsStack.push(elementId);
  while (dfsStack.length > 0) {
    let currentElementId = dfsStack.pop();
    const element = fileSystemData[currentElementId];
    if (
      element?.isTrashed ||
      (element?.name?.startsWith("__") && element?.name?.endsWith("__"))
    ) {
      continue;
    }
    if (
      fileSystemData[currentElementId]?.kind === filesKindType.file &&
      mimeDB[fileSystemData[currentElementId]?.file_mime_type]?.extensions
        .map((ext) => `.${ext}`)
        .some((ext) => LIVECLASS_SUPPORTED_FILES.includes(ext))
    ) {
      numFiles++;
    } else {
      dfsStack = dfsStack.concat(
        fileSystemData[currentElementId]?.children || []
      );
    }
  }
  return numFiles;
};

export const getSizeOfLiveClassSupportedFiles = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let sizeFiles = 0;
  let dfsStack = [];
  dfsStack.push(elementId);
  while (dfsStack.length > 0) {
    let currentElementId = dfsStack.pop();
    const element = fileSystemData[currentElementId];
    if (
      element?.isTrashed ||
      (element.name.startsWith("__") && element.name.endsWith("__"))
    ) {
      continue;
    }
    if (
      element?.kind === filesKindType.file &&
      mimeDB[fileSystemData[currentElementId]?.file_mime_type]?.extensions
        .map((ext) => `.${ext}`)
        .some((ext) => LIVECLASS_SUPPORTED_FILES.includes(ext))
    ) {
      sizeFiles += element.file_size;
    } else {
      dfsStack = dfsStack.concat(element?.children || []);
    }
  }
  return sizeFiles;
};

export const getSizeOfFiles = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let sizeFiles = 0;
  let dfsStack = [];
  dfsStack.push(elementId);
  while (dfsStack.length > 0) {
    let currentElementId = dfsStack.pop();
    const element = fileSystemData[currentElementId];
    if (
      element?.isTrashed ||
      (element.name.startsWith("__") && element.name.endsWith("__"))
    ) {
      continue;
    }
    if (element?.kind === filesKindType.file) {
      sizeFiles += element.size;
    } else {
      dfsStack = dfsStack.concat(element?.children || []);
    }
  }
  return sizeFiles;
};

export const getSizeOfUser = () => {
  const { user } = store.getState();
  const { folder_id } = user;
  return getSizeOfFiles(folder_id);
};

export const getSizeOfLiveClassSupportedFilesOfUser = () => {
  const { user } = store.getState();
  const { folder_id } = user;
  return getSizeOfLiveClassSupportedFiles(folder_id);
};

export const getNumFilesOfUser = () => {
  const { user } = store.getState();
  const { folder_id } = user;
  return getNumFiles(folder_id);
};

export const getNumOfLiveClassSupportedFilesOfUser = () => {
  const { user } = store.getState();
  const { folder_id } = user;
  return getNumOfLiveClassSupportedFiles(folder_id);
};

export const getSizeOfShared = () => {
  const {
    klass: { classes },
  } = store.getState();

  return Object.values(classes)
    .map((klass) => getSizeOfFiles(klass.folder_id))
    .reduce((a, b) => a + b, 0);
};

export const getNumFilesOfShared = () => {
  const {
    klass: { classes },
  } = store.getState();

  return Object.values(classes)
    .map((klass) => getNumFiles(klass.folder_id))
    .reduce((a, b) => a + b, 0);
};

export const startDownload = (fileUrl, fileName) => {
  // let element = document.createElement("a");
  // element.setAttribute("href", fileUrl);
  // element.setAttribute("target", "_blank");
  // element.setAttribute("download", fileName);

  // document.body.appendChild(element);
  // element.click();

  // document.body.removeChild(element);
  axios
    .get(fileUrl, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, fileName);
    });
  // fileDownload(fileUrl, fileName);
};

export const convertBytesFormat = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB"; //1073741824 bytes is 1 GB
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB"; //1048576 bytes is 1 MB
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB"; //1024 bytes is 1 KB
  } else if (bytes >= 1) {
    bytes = bytes + " Bytes";
  } else bytes = "0 Bytes";
  return bytes;
};

export const parseDate = (DateToBeParsed) => {
  const parsedDate =
    new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(new Date(Date.parse(DateToBeParsed))) +
    " " +
    new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(new Date(Date.parse(DateToBeParsed))) +
    " " +
    new Intl.DateTimeFormat("en-US", {
      year: "numeric",
    }).format(new Date(Date.parse(DateToBeParsed)));

  return parsedDate;
};

export const getRootFolderInfo = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let currentElementIndex = elementId;
  while (fileSystemData[currentElementIndex].parent_id !== 1) {
    currentElementIndex = fileSystemData[currentElementIndex].parent_id;
  }
  return fileSystemData[currentElementIndex];
};

export const getClassObjByRootFolderId = (rootFolderId) => {
  const {
    klass: { classes },
  } = store.getState();

  const classObj = Object.values(classes).find(
    (cls) => cls.folder_id === rootFolderId
  );
  return classObj;
};

export const getSplitName = (name = "", maxStringLength = 15) => {
  if (!name) {
    return "";
  }

  if (name.length <= maxStringLength) {
    return name;
  }
  const firstPart = name.slice(0, maxStringLength - 8);
  const secondPart = name.slice(name.length - 5, name.length);
  return `${firstPart}...${secondPart}`;
};

export const getEllipsisName = (name = "", maxStringLength = 15) => {
  if (name.length <= maxStringLength) {
    return name;
  }
  const firstPart = name.slice(0, maxStringLength);
  return `${firstPart}...`;
};

export const isParentToCurrentTreeSelectedFolder = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData, currentFolderId, treeSelectedFolderId } = fileSystem;
  let currentElementIndex = treeSelectedFolderId;

  while (
    currentElementIndex !== 1 &&
    !!currentElementIndex &&
    currentElementIndex !== -1
  ) {
    if (elementId === currentElementIndex) {
      return true;
    }
    currentElementIndex = fileSystemData[currentElementIndex]?.parent_id;
  }
  return false;
};

export const isParentToCurrentFolder = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData, currentFolderId } = fileSystem;
  let currentElementIndex = currentFolderId;
  while (
    currentElementIndex !== 1 &&
    !!currentElementIndex &&
    currentElementIndex !== -1
  ) {
    if (elementId === currentElementIndex) {
      return true;
    }
    currentElementIndex = fileSystemData[currentElementIndex]?.parent_id;
  }
  return false;
};

export const getRemainingUploadTime = (startTime, currentPercent) => {
  if (currentPercent === 0) {
    return "Calculating...";
  }
  const passedMilliSeconds = dayjs().diff(dayjs(startTime));
  const remainingMilliSeconds = Math.ceil(
    ((100 - currentPercent) / currentPercent) * passedMilliSeconds
  );
  const remainingHours = Math.floor(remainingMilliSeconds / 3600000);
  const remainingMinutes = Math.floor(remainingMilliSeconds / 60000);
  const remainingSeconds = Math.floor(remainingMilliSeconds / 1000);
  if (remainingHours > 0)
    return `${remainingHours} Hour${remainingHours > 1 ? "s" : ""} left`;
  if (remainingMinutes > 0)
    return `${remainingHours} Minute${remainingMinutes > 1 ? "s" : ""} left`;
  return `${remainingSeconds} Second${remainingSeconds > 1 ? "s" : ""} left`;
};

export const getVideoDetails = (fileObject) => {
  let video = document.createElement("video");
  video.preload = "metadata";
  video.src = URL.createObjectURL(fileObject);
  return new Promise((resolve, reject) => {
    try {
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
    } catch (e) {
      reject(0);
    }
  });
};

export const filterFilesForReference = (elementList = []) => {
  let filteredReferenceFiles = elementList.filter(
    (element) =>
      // link => to filter the youtube files
      (element.kind === filesKindType.file ||
        element.kind === filesKindType.link) &&
      !isTrashFile(element.status) &&
      !isLectureRecording(element) &&
      !isLectureThumbnail(element) &&
      !isSystemFile(element) &&
      !isQuizFolder(element)
  );

  return sortArrayByDateDescending(filteredReferenceFiles);
};

export const filterElementsForBrowser = (elementList = []) =>
  elementList?.filter(
    (element) =>
      element?.name?.trim().length > 0 &&
      !element.trashed &&
      // !(element.name.startsWith("__") && element.name.endsWith("__"))
      !isSystemFile(element) &&
      !isLectureRecording(element) &&
      !isLectureThumbnail(element) &&
      !isQuizFolder(element)
  ) || [];

export const getPathList = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData, currentFolderId } = fileSystem;
  let pointedFolderId = elementId || currentFolderId;
  if (!fileSystemData[pointedFolderId]) return [];
  let folderNameReverseList = [];
  while (pointedFolderId !== 1) {
    folderNameReverseList.push(fileSystemData[pointedFolderId].name);
    pointedFolderId = fileSystemData[pointedFolderId].parent_id;
  }

  if (doesBelongToUser(elementId || currentFolderId))
    return folderNameReverseList.reverse();
  return folderNameReverseList.concat(["Shared"]).reverse();
};

export const getClassAndLectureOfElement = (elementId) => {
  const {
    fileSystem,
    liveClass: { lectureId: live_lecture_id, classId: live_lecture_class_id },
  } = store.getState();
  const { fileSystemData } = fileSystem;
  let [lectureId, classId] = [null, null];
  let pointedFolderId = elementId;

  if (instantLectureUtils().is().currentPageBelongsToInstantClassroom()) {
    return {
      lectureId: live_lecture_id,
      classId: live_lecture_class_id,
    };
  }

  if (!pointedFolderId) return { lectureId, classId };
  while (pointedFolderId !== 1) {
    if (
      lectureId === null &&
      fileSystemData[pointedFolderId]?.metadata?.lecture_id
    ) {
      lectureId = fileSystemData[pointedFolderId].metadata.lecture_id;
    }
    if (
      classId === null &&
      fileSystemData[pointedFolderId]?.metadata?.class_id
    ) {
      classId = fileSystemData[pointedFolderId].metadata.class_id;
    }
    pointedFolderId = fileSystemData[pointedFolderId].parent_id;
  }

  return { lectureId, classId };
};

export const getVerifySessionChild = (elementId) => {
  const { fileSystem } = store.getState();
  const { fileSystemData } = fileSystem;
  let pointedFolderId = elementId;
  while (pointedFolderId !== 1) {
    if (fileSystemData[pointedFolderId]?.metadata?.session_files) {
      return true;
    }
    if (!fileSystemData[pointedFolderId]?.parent_id) return false;
    pointedFolderId = fileSystemData[pointedFolderId].parent_id;
  }
  return false;
};

export const getTeachersList = (id) => {
  const { klass } = store.getState();
  const { teachers } = klass;
  let teacherListObj = {};

  const teachersList = Object.keys(teachers).map((classId) => {
    let classObj = teachers[classId];
    Object.keys(classObj).map((teacherId) => {
      teacherListObj[teacherId] = classObj[teacherId];
    });
    return teacherListObj;
  });
  return teachersList[0][id]?.name;
};

export const getFolderNameList = (prefix) => {
  const { fileOptions, fileSystem, smartboard } = store.getState();
  const { fileSystemData, currentFolderId } = fileSystem;
  const { slideShareState } = smartboard;
  let folderNameReverseList = [];
  let pointedFolderId = currentFolderId;
  let breadCrumbText = "";
  const currentScreen = window.location?.pathname;
  /**
   * 1. if current-screen is live class then don't fetch folderNameList if user navigated to top level of folders
   * 2. if not live-class then always fetch
   */
  if (
    (currentScreen === screenNames.liveClass &&
      slideShareState !== SLIDE_SHARE_STATE.FOLDER_CLASS_LEVEL) ||
    currentScreen !== screenNames.liveClass
  ) {
    while (pointedFolderId !== 1) {
      folderNameReverseList.push([
        pointedFolderId,
        fileSystemData[pointedFolderId].name,
      ]);
      pointedFolderId =
        fileSystemData[pointedFolderId].parent_id ||
        fileSystemData[pointedFolderId].parent;
    }
  }
  if (prefix) {
    folderNameReverseList.push([null, prefix]);
  }
  const folderNameList = folderNameReverseList.reverse();
  folderNameList.forEach((element, index) => {
    breadCrumbText = breadCrumbText + (index !== 0 ? ">" : "") + element[1];
  });
  const overflow = checkIfBreadCrumbTextOverflowParentBounds(folderNameList);
  return { folderNameList, ...overflow, breadCrumbText };
};

export const getCompleteLocation = (folderNameList) => {
  const maxLevel = 2;
  let location = "";

  folderNameList.map((idNameList, index) => {
    /**
     * @param idNameList stores the id and name of folder, e.g. [35463, some_folder_name]
     */
    const isLastBreadcrumb =
      index === folderNameList.length - 1 || index === maxLevel - 1;
    location = location + `${idNameList[1]}/`;
  });

  return location.slice(0, -1);
};

export const convertFileToUrl = (file) => {
  const url = URL.createObjectURL(file);
  return url;
};

export const convertUrlToFile = async (fileObj) => {
  let file;

  await fetch(fileObj.url)
    .then((r) => r.blob())
    .then(
      (blob) => (file = new File([blob], fileObj.name, { type: blob.type }))
    );

  return file;
};
