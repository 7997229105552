export const backendApi = process.env.REACT_APP_BACKEND_API_URL;
export const APP_PLAYSTORE_URL =
  "https://play.google.com/store/apps/details?id=com.learngramlive";
export const APP_APPSTORE_URL =
  "https://apps.apple.com/in/app/link-classroom-for-learners/id1536150828";
export const FIREBASE_DYNAMICLINK_API =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks";
export const LINK_DYNAMICLINK_URL = "https://link.linkclassroom.com";
export const INSTANT_LIVE_CLASS_URL = `${window.location.origin}/live-class/instant`;
export const OTP_WAIT_TIME = 20;
export const uiVersion = process.env.REACT_APP_UI_VERSION;

export const Color = {
  white: "#fff",
  blueGradient: {
    start: "#3C3C58",
    stop: "#1C1C36",
  },
  darkBlue: "#2A2E43",
  lightBlue: "#454F63",
  gray: "#78849E",
  darkGray: "#61657E",
  purple: "#C840E9",
  red: "#FF4F67",
  matteOrange: "#FF9057",
  yellow: "#FFB900",
  lightOrangeGradient: {
    start: "#FDBB5C",
    stop: "#FFA015",
  },
  green: "#17C8AF",
  orange: "#FB963C",
  darkOrangeGradient: {
    start: "#FB9E58",
    stop: "#FF913E",
  },
  blue: "#0E49EE",
  strokeGreen: "#0AD217",
  strokeOrange: "#FF8A48",
  peopleAttendanceAverageTableLegend: "#e7ecf5",
  paginationCaretLightColor: "#e3e9ef",
  paginationCaretDarkColor: "#959dad",
  calendarHeaderBgColor: "#ebf2f7",
  calendarHeaderSaturdayBgColor: "rgba(255, 185, 0, 0.1)",
  calendarHeaderSundayBgColor: "rgba(255, 79, 103, 0.1)",
  calendarActiveDateColor: "#333333",
  gridIconInactiveColor: "#b5bdcf",
  pinkColor: "#ba3f90",
};
