import _ from "lodash";

import {
  SET_JITSI_CONNECTION_INITIALIZED,
  SET_JITSI_CONFERENCE_DETAILS,
  SET_JITSI_CONFERENCE_OBJECT,
  SET_JITSI_CONNECTION_OBJECT,
  SET_JITSI_CONFERENCE_JOINED,
  SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
  SET_MY_JITSI_AUDIO_TRACK,
  SET_MY_JITSI_VIDEO_TRACK,
  SET_MY_JITSI_PARTICIPANT_DETAILS,
  ADD_JITSI_TRACK_TO_PARTICIPANT,
  REMOVE_JITSI_TRACK_FROM_PARTICIPANT,
  ADD_JITSI_PARTICIPANT,
  REMOVE_JITSI_PARTICIPANT,
  SET_JITSI_TRACK_MUTE_CHANGED,
  SET_MY_JITSI_TRACK_MUTE_CHANGED,
  RESET_JITSI_STATE,
  SET_JITSI_TRACKS_INITIALIZED,
  SET_MY_JITSI_DESKTOP_TRACK,
  SET_JITSI_AVAILABLE_MEDIA_DEVICES,
  SET_MY_VIDEO_INPUT_DEVICE_ID,
  SET_MY_AUDIO_INPUT_DEVICE_ID,
  SET_MY_AUDIO_OUTPUT_DEVICE_ID,
  SET_CHANGED_PARTICIPANT_PROPERTY,
  SET_KICKED_FROM_CONFERENCE,
  SET_JITSI_CONFERENCE_ENDED,
  RESET,
  SET_SELECTED_PARTICIPANT_IDS,
  SET_ALL_PARTICIPANTS,
  SET_TEACHER_PARTICIPANTS,
  SET_STUDENT_PARTICIPANTS,
  SET_DOMINANT_PARTICIPANT,
  SET_SHARED_PARTICIPANT_DETAILS,
  SET_CURRENT_TEACHER_PARTICIPANT,
  SET_VOLUME_LEVEL,
  SET_MY_VIDEO_PREVIEW_TRACK,
  SET_MY_AUDIO_PREVIEW_TRACK,
  SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED,
  CLEANUP_JITSI_PREVIEW_TRACKS,
  SET_JITSI_CONNECTION_DROPPED,
  SET_JITSI_CONNECTION_RECONNECTED,
  SET_DESKTOP_PARTICIPANTS,
  SET_LINK_CONNECT_PARTICIPANTS,
} from "../types";

const initialState = {
  jitsiConnectionInitialized: false,
  jitsiTracksInitialized: false,
  jitsiConnection: null,
  jitsiConference: null,
  conferenceJoined: false,
  conferenceId: null,
  myParticipantId: null,
  jitsiRootDomain: null,
  myVideoMuted: true,
  myAudioMuted: true,
  myVideoTrack: null,
  myAudioTrack: null,
  myDesktopTrack: null,
  participantIds: [],
  participants: {},
  availableMediaDevices: [],
  myVideoInputDeviceId: "",
  myAudioInputDeviceId: "",
  myAudioOutputDeviceId: "",
  kickedFromConference: false,
  conferenceEnded: false,
  selectedParticipantIds: [],
  desktopParticipants: [],
  allParticipants: [],
  teacherParticipants: [],
  studentParticipants: [],
  dominantParticipantId: null,
  sharedParticipantId: null,
  sharedParticipantVideoTrack: null,
  currentTeacherParticipant: null,
  volumeLevel: 1,
  myVideoPreviewTrack: null,
  myAudioPreviewTrack: null,
  myVideoPreviewMuted: true,
  myAudioPreviewMuted: true,
  finishedWaitingAfterConferenceJoined: false,
  connectionDropped: false,
  linkConnectParticipants: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_JITSI_TRACKS_INITIALIZED: {
      return {
        ...state,
        jitsiTracksInitialized: true,
      };
    }
    case SET_JITSI_CONNECTION_INITIALIZED: {
      return {
        ...state,
        jitsiConnectionInitialized: true,
      };
    }
    case SET_JITSI_CONFERENCE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_JITSI_CONNECTION_OBJECT: {
      return {
        ...state,
        jitsiConnection: action.payload,
      };
    }
    case SET_JITSI_CONFERENCE_OBJECT: {
      return {
        ...state,
        jitsiConference: action.payload,
      };
    }
    case SET_JITSI_CONFERENCE_JOINED: {
      return {
        ...state,
        conferenceJoined: true,
      };
    }
    case SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED: {
      return {
        ...state,
        finishedWaitingAfterConferenceJoined: true,
      };
    }
    case SET_MY_JITSI_AUDIO_TRACK: {
      return {
        ...state,
        myAudioTrack: action.payload,
        myAudioMuted: action.payload ? action.payload.isMuted() : true,
      };
    }
    case SET_MY_JITSI_VIDEO_TRACK: {
      return {
        ...state,
        myVideoTrack: action.payload,
        myVideoMuted: action.payload ? action.payload.isMuted() : true,
      };
    }
    case SET_MY_JITSI_DESKTOP_TRACK: {
      return {
        ...state,
        myDesktopTrack: action.payload,
      };
    }
    case SET_MY_JITSI_PARTICIPANT_DETAILS: {
      const { myParticipantId } = action.payload;
      return {
        ...state,
        myParticipantId,
        participantIds: [...state.participantIds, myParticipantId],
        participants: {
          ...state.participants,
          [myParticipantId]: {
            participantId: myParticipantId,
            tracks: [],
          },
        },
      };
    }
    case ADD_JITSI_PARTICIPANT: {
      if (!state.participantIds.includes(action.payload)) {
        return {
          ...state,
          participantIds: [...state.participantIds, action.payload],
          participants: {
            ...state.participants,
            [action.payload]: {
              participantId: action.payload,
              tracks: [],
            },
          },
        };
      }

      return state;
    }
    case SET_CHANGED_PARTICIPANT_PROPERTY: {
      const { participantId, key, value } = action.payload;
      if (state.participantIds.includes(participantId)) {
        return {
          ...state,
          participants: {
            ...state.participants,
            [participantId]: {
              ...state.participants[participantId],
              [key]: value,
            },
          },
        };
      }

      return state;
    }
    case REMOVE_JITSI_PARTICIPANT: {
      const newParticipants = state.participants;
      if (newParticipants[action.payload]) {
        delete newParticipants[action.payload];
      }
      return {
        ...state,
        participantIds: state.participantIds.filter(
          (pid) => pid !== action.payload
        ),
        participants: newParticipants,
      };
    }
    case ADD_JITSI_TRACK_TO_PARTICIPANT: {
      const { participantId, track } = action.payload;

      if (!state.participants[participantId]) {
        return state;
      }

      let newTracks = state.participants[participantId].tracks;

      const trackFound = _.find(
        newTracks,
        (t) => t.track.getId() === track.getId()
      );

      if (!trackFound) {
        newTracks = [
          ...newTracks,
          {
            isMuted: track.isMuted(),
            type: track.getType(),
            videoType: track.videoType,
            track,
          },
        ];
      }

      return {
        ...state,
        participants: {
          ...state.participants,
          [participantId]: {
            ...state.participants[participantId],
            tracks: newTracks,
          },
        },
      };
    }
    case REMOVE_JITSI_TRACK_FROM_PARTICIPANT: {
      const { participantId, track } = action.payload;

      if (!state.participants[participantId]) {
        return state;
      }

      let newTracks = state.participants[participantId].tracks;

      newTracks = newTracks.filter((t) => t.track.getId() !== track.getId());

      return {
        ...state,
        participants: {
          ...state.participants,
          [participantId]: {
            ...state.participants[participantId],
            tracks: newTracks,
          },
        },
      };
    }
    case SET_JITSI_TRACK_MUTE_CHANGED: {
      const { participantId, track } = action.payload;

      if (!state.participants[participantId]) {
        return state;
      }

      let newTracks = state.participants[participantId].tracks;

      const trackFound = _.find(
        newTracks,
        (t) => t.track.getId() === track.getId()
      );
      if (trackFound) {
        trackFound.isMuted = track.isMuted();
      }

      return {
        ...state,
        participants: {
          ...state.participants,
          [participantId]: {
            ...state.participants[participantId],
            tracks: newTracks,
          },
        },
      };
    }
    case SET_MY_JITSI_TRACK_MUTE_CHANGED: {
      return {
        ...state,
        myAudioMuted: state.myAudioTrack ? state.myAudioTrack.isMuted() : true,
        myVideoMuted: state.myVideoTrack ? state.myVideoTrack.isMuted() : true,
      };
    }
    case SET_JITSI_AVAILABLE_MEDIA_DEVICES: {
      return {
        ...state,
        availableMediaDevices: [...action.payload],
      };
    }
    case SET_MY_VIDEO_INPUT_DEVICE_ID: {
      return {
        ...state,
        myVideoInputDeviceId: action.payload,
      };
    }
    case SET_MY_AUDIO_INPUT_DEVICE_ID: {
      return {
        ...state,
        myAudioInputDeviceId: action.payload,
      };
    }
    case SET_MY_AUDIO_OUTPUT_DEVICE_ID: {
      return {
        ...state,
        myAudioOutputDeviceId: action.payload,
      };
    }
    case SET_KICKED_FROM_CONFERENCE: {
      return {
        ...state,
        kickedFromConference: action.payload,
      };
    }
    case SET_JITSI_CONFERENCE_ENDED: {
      return {
        ...state,
        conferenceEnded: action.payload,
      };
    }
    case SET_SELECTED_PARTICIPANT_IDS: {
      return {
        ...state,
        selectedParticipantIds: action.payload,
      };
    }
    case SET_DESKTOP_PARTICIPANTS: {
      return {
        ...state,
        desktopParticipants: action.payload,
      };
    }
    case SET_ALL_PARTICIPANTS: {
      return {
        ...state,
        allParticipants: action.payload,
      };
    }
    case SET_TEACHER_PARTICIPANTS: {
      return {
        ...state,
        teacherParticipants: action.payload,
      };
    }
    case SET_STUDENT_PARTICIPANTS: {
      return {
        ...state,
        studentParticipants: action.payload,
      };
    }
    case SET_DOMINANT_PARTICIPANT: {
      return {
        ...state,
        dominantParticipantId: action.payload,
      };
    }
    case SET_SHARED_PARTICIPANT_DETAILS: {
      const { sharedParticipantId, sharedParticipantVideoTrack } =
        action.payload;

      return {
        ...state,
        sharedParticipantId,
        sharedParticipantVideoTrack,
      };
    }
    case SET_CURRENT_TEACHER_PARTICIPANT: {
      return {
        ...state,
        currentTeacherParticipant: action.payload,
      };
    }
    case SET_VOLUME_LEVEL: {
      return {
        ...state,
        volumeLevel: action.payload,
      };
    }
    case SET_MY_VIDEO_PREVIEW_TRACK: {
      return {
        ...state,
        myVideoPreviewTrack: action.payload,
      };
    }
    case SET_MY_AUDIO_PREVIEW_TRACK: {
      return {
        ...state,
        myAudioPreviewTrack: action.payload,
      };
    }
    case SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED: {
      const { myVideoPreviewTrack, myAudioPreviewTrack } = state;
      return {
        ...state,
        myVideoPreviewMuted: myVideoPreviewTrack
          ? myVideoPreviewTrack.isMuted()
          : true,
        myAudioPreviewMuted: myAudioPreviewTrack
          ? myAudioPreviewTrack.isMuted()
          : true,
      };
    }
    case SET_JITSI_CONNECTION_DROPPED: {
      const {
        jitsiTracksInitialized,
        conferenceId,
        jitsiRootDomain,
        myVideoMuted,
        myAudioMuted,
        myVideoTrack,
        myAudioTrack,
        myDesktopTrack,
        volumeLevel,
        myVideoPreviewTrack,
        myAudioPreviewTrack,
        myVideoPreviewMuted,
        myAudioPreviewMuted,
      } = state;
      return {
        ...initialState,
        jitsiTracksInitialized,
        conferenceId,
        jitsiRootDomain,
        myVideoMuted,
        myAudioMuted,
        myVideoTrack,
        myAudioTrack,
        myDesktopTrack,
        volumeLevel,
        myVideoPreviewTrack,
        myAudioPreviewTrack,
        myVideoPreviewMuted,
        myAudioPreviewMuted,
        connectionDropped: true,
      };
    }
    case SET_JITSI_CONNECTION_RECONNECTED: {
      return {
        ...state,
        connectionDropped: false,
      };
    }
    case SET_LINK_CONNECT_PARTICIPANTS: {
      return {
        ...state,
        linkConnectParticipants: action.payload,
      };
    }
    case CLEANUP_JITSI_PREVIEW_TRACKS: {
      return {
        ...state,
        myVideoPreviewTrack: initialState.myVideoPreviewTrack,
        myVideoPreviewMuted: initialState.myVideoPreviewMuted,
        myAudioPreviewTrack: initialState.myAudioPreviewTrack,
        myAudioPreviewMuted: initialState.myAudioPreviewMuted,
      };
    }

    case RESET_JITSI_STATE: {
      return {
        ...initialState,
      };
    }
    case RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
