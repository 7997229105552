import {
  CLEAR_USER_DETAILS,
  SET_IS_NEW_USER,
  SET_PERFORM_LOGOUT,
  SET_SESSION_ID,
  SET_USER_DETAILS,
  UPDATE_USER_ADDITIONAL_DATA,
  UPDATE_USER_DISCOVERY,
  UPDATE_LIVE_FEEDBACK,
  SET_STUDENT_PREFERENCES,
  SET_TEACHER_PREFERENCES,
  SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP,
  SET_USER_SUBSCRIPTION_DETAILS,
  SET_INSTITUTE_TEACHER_REQUEST,
  SET_INSTITUTE_TEACHER_INVITATION,
  SET_INSTITUTE_TEACHER,
  SET_CLIENT_BRANDING_INFO,
  SET_INSTANT_LIVECLASS_SESSION_ID,
} from "../types";
import { DISCOVERY_TYPES } from "../../utils/constants";

const initialState = {
  sessionId: "",
  iSessionId: "", // instant live-class session id
  email: "",
  phone: "",
  phoneNumber: "",
  phoneCountryCode: "",
  country: "",
  name: "",
  createdAt: "",
  details: {},
  id: null,
  role: null,
  status: { id: null, name: null },
  folder_id: null,
  userGroupId: null,
  type: "",
  profilePhotoUrl: "",
  notifications: false,
  floatingUserId: "",
  class_: {},
  lecture: {
    understandingScore: 4,
  },
  conference: {},
  privacyPolicyAccepted: false,
  performLogout: false,
  isNewUser: false,
  hasSetPassword: false,
  discovery: Object.values(DISCOVERY_TYPES).reduce(
    (acc, type) => ({ ...acc, [type]: false }),
    {}
  ),
  additionalData: {},
  student_preferences: {},
  teacher_preferences: {},
  userSubscription: {},
  instituteTeacher: {},
  instituteTeacherInvitation: {},
  instituteTeacherRequest: {},
  clientBrandingInfo: {},
  isInstantLiveClassEmailAuthenticated: false,
  instantLiveClassStudentLink: null,
  instantLiveClassTeacherLink: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_SESSION_ID: {
      return {
        ...state,
        sessionId: action.payload,
      };
    }
    case SET_INSTANT_LIVECLASS_SESSION_ID: {
      return {
        ...state,
        iSessionId: action.payload,
      };
    }
    case CLEAR_USER_DETAILS: {
      return {
        ...initialState,
        clientBrandingInfo: state.clientBrandingInfo, // this data is responsible for showing client logo, so do not clear client branding details on logout or on any other operation
      };
    }
    case SET_IS_NEW_USER: {
      return {
        ...state,
        isNewUser: action.payload,
      };
    }
    case UPDATE_USER_ADDITIONAL_DATA: {
      return {
        ...state,
        additionalData: action.payload,
      };
    }
    case SET_PERFORM_LOGOUT: {
      return {
        ...state,
        performLogout: action.payload,
      };
    }
    case UPDATE_USER_DISCOVERY: {
      return {
        ...state,
        discovery: { ...initialState.discovery, ...action.payload },
      };
    }
    case UPDATE_LIVE_FEEDBACK: {
      return {
        ...state,
        lecture: { ...state.lecture, understandingScore: action.payload },
      };
    }
    case SET_STUDENT_PREFERENCES: {
      return {
        ...state,
        student_preferences: {
          ...state.student_preferences,
          ...action.payload,
        },
      };
    }
    case SET_TEACHER_PREFERENCES: {
      return {
        ...state,
        teacher_preferences: {
          ...state.teacher_preferences,
          ...action.payload,
        },
      };
    }
    case SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP: {
      return {
        ...state,
        teacher_preferences: {
          ...state.teacher_preferences,
          dont_show_download_popup: action.payload,
        },
      };
    }
    case SET_USER_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        userSubscription: action.payload,
      };
    }

    case SET_INSTITUTE_TEACHER_REQUEST: {
      return {
        ...state,
        instituteTeacherRequest: action.payload,
      };
    }
    case SET_INSTITUTE_TEACHER_INVITATION: {
      return {
        ...state,
        instituteTeacherInvitation: action.payload,
      };
    }
    case SET_INSTITUTE_TEACHER: {
      return {
        ...state,
        instituteTeacher: action.payload,
      };
    }
    case SET_CLIENT_BRANDING_INFO: {
      return {
        ...state,
        clientBrandingInfo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
