import produce from "immer";
import {
  SET_ASSIGNMENTS_LIST,
  SET_CURRENT_ASSIGNMENT,
  SET_ALL_TOPIC,
  SET_ALL_SUBMISSIONS,
  SET_SUBMISSIONS_GRADE,
  SET_ASSIGNED_STUDENTS,
  SET_TURNED_IN,
  SET_REUSE_ASSIGNMENT,
  SET_CURRENT_ASSIGNMENT_FILE,
  SET_CURRENT_STUDENT_SUBMISSION_DETAILS,
  SET_CURRENT_STUDENT_COMMENTS,
  SET_REMOVE_ASSIGNMENT,
  SET_ASSIGNMENT_COMMENTS,
  SET_ASSIGNMED_REPLY_COMMENTS,
  SET_ASSIGNMENT_LINK,
  SET_ALL_SUBMISSION_LAST_COMMENT,
  SET_ALL_SUBMISSION_FILES,
  SET_COURSEWORK_LIST,
  SET_COURSEWORK_QUIZ_LIST,
  SET_ALL_ASSIGNMENT_REPORT,
  SET_ALLOW_PUBLISH_RESULT,
  SET_ASSIGNMENT_SUBMISSION_GRADEBOARD,
  DELETE_GRADEBOARD_FILE_VS_IMAGE_MAP,
  SET_GRADEBOARD_ACTIVE_LAYER_ID,
  SET_GRADEBOARD_16X9_DIMENSION,
  ADD_GRADEBOARD_FILE_VS_IMAGE_MAP,
  ADD_SUBMISSIONS_GRADE,
  SET_CURRENT_REPORT_TAB,
} from "../types";

const initialState = {
  allAssignments: {},
  currentAssignment: {},
  currentAssignmentFiles: [],
  allTopic: {},
  submissionComments: {},
  allAssignmentsTopic: {},
  allSubmissions: {},
  submissionGrades: {},
  removeAssignmentList: {},
  assigned: null,
  turnedIn: null,
  currentStudentSubmissionDetails: {},
  currentStudentComments: [],
  currentReplies: [],
  assignmentComment: {},
  allSubmissionsLastComment: {},
  allSubmissionsFiles: {},
  assignmentReport: {},
  allowPublishResult: true,
  courseWorkQuiz: {},
  courseWorkList: {},
  paracticeQuiz: {},
  assignmentSubmissionGradeboardData: {},
  gradeboardFileVsImagesMap: {}, // { file_id:[list of images extracted from file]}
  gradeboardActiveLayerId: "",
  gradeboard16x9Dimension: { width16x9: 0, height16x9: 0 },
  reportActiveTabKey: 1,
};

const assignmentReducer = produce((state, action) => {
  switch (action.type) {
    case SET_ASSIGNMENTS_LIST: {
      const assignments = action.payload;
      const newAssignments = assignments.reduce((acc, obj) => {
        obj["type"] = "assignment";
        return Object.assign(acc, { [obj.id]: obj });
      }, {});
      return {
        ...state,
        allAssignments: { ...newAssignments },
      };
    }

    case SET_COURSEWORK_QUIZ_LIST: {
      // const quiz = action.payload;
      let quiz = {},
        practiceQuiz = {};

      action.payload.forEach((item, index) => {
        item["typeOf"] = "quiz";
        if (item.type === "GRADED") {
          quiz[item.id] = item;
        } else if (item.type === "PRACTICE") {
          practiceQuiz[item.id] = item;
        }
      });
      // const newQuiz = quiz.reduce((acc, obj) => {
      //   obj["type"] = "quiz";
      //   return Object.assign(acc, { [obj.id]: obj });
      // }, {});

      return {
        ...state,
        courseWorkQuiz: { ...quiz },
        paracticeQuiz: { ...practiceQuiz },
      };
    }

    case SET_COURSEWORK_LIST: {
      const courseWork = action.payload;
      let obj = {};
      [...action.payload, ...Object.values(state.courseWorkQuiz)].forEach(
        (element) => {
          if (element.status !== "REMOVED") {
            if (element.topic_id) {
              if (obj[element.topic_id]) {
                obj[element.topic_id] = [...obj[element.topic_id], element];
              } else {
                obj[element.topic_id] = [element];
              }
            } else {
              if (obj[0]) {
                obj[0] = [...obj[0], element];
              } else {
                obj[0] = [element];
              }
            }
          }
        }
      );
      return {
        ...state,
        courseWorkList: obj,
      };
    }

    case SET_CURRENT_ASSIGNMENT: {
      return {
        ...state,
        currentAssignment: action.payload,
      };
    }
    case SET_CURRENT_ASSIGNMENT_FILE: {
      return {
        ...state,
        currentAssignmentFiles: action.payload,
      };
    }
    case SET_ALL_TOPIC: {
      const topic = action.payload;
      const newTopic = topic.reduce((acc, obj) => {
        return Object.assign(acc, { [obj.id]: obj });
      }, {});
      return {
        ...state,
        allTopic: { ...newTopic },
      };
    }
    case SET_ALL_SUBMISSIONS: {
      return {
        ...state,
        allSubmissions: action.payload,
      };
    }
    case SET_SUBMISSIONS_GRADE: {
      return {
        ...state,
        submissionGrades: action.payload,
      };
    }
    case ADD_SUBMISSIONS_GRADE: {
      return {
        ...state,
        submissionGrades: {
          ...state.submissionGrades,
          [action.payload.submission]: action.payload,
        },
      };
    }
    case SET_ASSIGNED_STUDENTS: {
      return {
        ...state,
        assigned: action.payload,
      };
    }
    case SET_TURNED_IN: {
      return {
        ...state,
        turnedIn: action.payload,
      };
    }
    case SET_CURRENT_STUDENT_SUBMISSION_DETAILS: {
      return {
        ...state,
        currentStudentSubmissionDetails: action.payload,
      };
    }
    case SET_CURRENT_STUDENT_COMMENTS: {
      return {
        ...state,
        currentStudentComments: action.payload,
      };
    }
    case SET_REMOVE_ASSIGNMENT: {
      const assignments = action.payload;
      let obj = {};
      assignments.forEach((element) => {
        if (element.topic_id) {
          if (obj[element.topic_id]) {
            obj[element.topic_id] = [...obj[element.topic_id], element];
          } else {
            obj[element.topic_id] = [element];
          }
        } else {
          if (obj[0]) {
            obj[0] = [...obj[0], element];
          } else {
            obj[0] = [element];
          }
        }
      });

      return {
        ...state,
        removeAssignmentList: obj,
      };
    }
    case SET_REUSE_ASSIGNMENT: {
      let reuse = action.payload;
      state["allAssignments"][reuse.id] = reuse;
      if (reuse?.topic_id) {
        if (state["allAssignmentsTopic"][reuse.topic_id]) {
          state["allAssignmentsTopic"][reuse.topic_id] = [
            ...state.allAssignmentsTopic[reuse.topic_id],
            reuse,
          ];
        } else {
          state["allAssignmentsTopic"][reuse.topic_id] = [reuse];
        }
      } else {
        if (state.allAssignmentsTopic[0]) {
          state["allAssignmentsTopic"][0] = [
            ...state.allAssignmentsTopic[0],
            reuse,
          ];
        } else {
          state["allAssignmentsTopic"][0] = [reuse];
        }
      }
      return state;
    }
    case SET_ASSIGNMENT_COMMENTS: {
      return {
        ...state,
        assignmentComment: action.payload.post,
        currentReplies: action.payload?.replies,
      };
    }
    case SET_ASSIGNMENT_LINK: {
      const { liked, liked_by_teachers, liked_by_students, likes_count } =
        action.payload;
      return {
        ...state,
        assignmentComment: {
          ...state.assignmentComment,
          user_liked: liked,
          liked_by_students: liked_by_students,
          liked_by_teachers: liked_by_teachers,
          likes_count: likes_count,
        },
      };
    }
    case SET_ASSIGNMED_REPLY_COMMENTS: {
      const { replies } = action.payload;
      return {
        ...state,
        currentReplies: replies,
      };
    }
    case SET_ALL_SUBMISSION_LAST_COMMENT: {
      return {
        ...state,
        allSubmissionsLastComment: action.payload,
      };
    }
    case SET_ALL_SUBMISSION_FILES: {
      return {
        ...state,
        allSubmissionsFiles: action.payload,
      };
    }
    case SET_ALL_ASSIGNMENT_REPORT: {
      return {
        ...state,
        assignmentReport: action.payload,
      };
    }
    case SET_ALLOW_PUBLISH_RESULT: {
      return {
        ...state,
        allowPublishResult: action.payload,
      };
    }
    case SET_ASSIGNMENT_SUBMISSION_GRADEBOARD: {
      state.assignmentSubmissionGradeboardData = action.payload;
      break;
    }
    case ADD_GRADEBOARD_FILE_VS_IMAGE_MAP: {
      const { file_id, list_of_images } = action.payload;
      state.gradeboardFileVsImagesMap[file_id] = [
        ...(state.gradeboardFileVsImagesMap[file_id] || []),
        ...list_of_images,
      ];
      break;
    }
    case DELETE_GRADEBOARD_FILE_VS_IMAGE_MAP: {
      const { file_id } = action.payload;
      delete state.gradeboardFileVsImagesMap[file_id];
      break;
    }
    case SET_GRADEBOARD_ACTIVE_LAYER_ID: {
      state.gradeboardActiveLayerId = action.payload;
      break;
    }
    case SET_GRADEBOARD_16X9_DIMENSION: {
      state.gradeboard16x9Dimension = action.payload;
      break;
    }
    case SET_CURRENT_REPORT_TAB: {
      return {
        ...state,
        reportActiveTabKey: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}, initialState);

export default assignmentReducer;
