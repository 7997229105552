import makeApiRequest from "../../utils/makeApiRequest";
import _ from "lodash";
import dayjs from "dayjs";
import { backendApi } from "../../config/constants";
import axios from "axios";
import {
  SET_USER_DETAILS,
  SET_SESSION_ID,
  UPDATE_USER_DISCOVERY,
  SET_CLASS_INVITES,
  SET_IS_NEW_USER,
  UPDATE_USER_ADDITIONAL_DATA,
  SET_APP_MICROSERVICES,
  SET_STUDENT_PREFERENCES,
  SET_TEACHER_PREFERENCES,
  RESET_LIVECLASS_ENDED_EVENT_DATA,
  SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP,
  SET_USER_SUBSCRIPTION_DETAILS,
  SET_INSTITUTE_TEACHER_REQUEST,
  SET_INSTITUTE_TEACHER_INVITATION,
  SET_INSTITUTE_TEACHER,
  SET_CLIENT_BRANDING_INFO,
  CLEAR_USER_DETAILS,
} from "../types";
import {
  getLiveStatusesOfClasses,
  setClassBatches,
  setClassList,
  setClassListLoaded,
} from "./classActions";
import {
  errorAlert,
  logoutProcedure,
  getSessionId,
  customAlert,
} from "./utils";
import { getSocket } from "../../utils/socketio";
import {
  RECORDING_BOT,
  OtpContexts,
  STUDENT,
  TEACHER,
  ignoreRedirectingSubdomains,
} from "../../utils/constants";
import { setMyJitsiUserName } from "./jitsiActions";
import {
  addLibraryData,
  addMultipleUploadFiles,
  permanentDeleteElement,
  setMultipleUploadCancelFunction,
  setMultipleUploadProgress,
} from "./fileSystemActions";
import makeFileUploadRequest from "../../utils/makePutRequest";
import { getDeviceDetails } from "../../utils/deviceDetails";
import { sleep } from "../../utils/misc";
import {
  getApplicationSettings,
  setInstitutionList,
  setShowTrialDetailsPopup,
} from "./applicationActions";
import { getAdminInstituteClasses, getAdminStartup } from "./adminAction";
import { setInstituteAdmins } from "./instituteActions";

const loginProcedure = (apiResponse) => async (dispatch) => {
  // normal login procedure that is repeated in signup, login, oauth, password reset
  // set sesion id in storage and redux
  // set user details in redux

  dispatch(setSessionId(apiResponse.session_id));

  const userDetails = {
    id: apiResponse.user.id,
    name: apiResponse.user.name,
    createdAt: apiResponse.user.created_at,
    email: apiResponse.user.email,
    details: apiResponse.user.details,
    country: apiResponse.user.country,
    phoneNumber: apiResponse.user.phone_number,
    phoneCountryCode: apiResponse.user.phone_country_code,
    notifications: apiResponse.user.notifications,
    profilePhotoUrl: apiResponse.user.profile_photo_url,
    role: apiResponse.user.role,
    status: apiResponse.user.status,
    type: apiResponse.user.type,
    folder_id: apiResponse.user.folder_id,
    userGroupId: apiResponse.user.user_group_id,
    floatingUserId: apiResponse.user.floating_user_id,
    hasSetPassword: apiResponse.user.has_set_password,
  };

  dispatch(setUserDetails(userDetails));

  await dispatch(getUserStartupData(userDetails.role.toLowerCase()));
};

export const setSessionId = (session_id) => (dispatch) => {
  localStorage.setItem("sessionId", session_id);

  dispatch({
    type: SET_SESSION_ID,
    payload: session_id,
  });
};

export const getUserDetails = () => async (dispatch, getState) => {
  const sessionId = await localStorage.getItem("sessionId");
  const trialPopupShownTime = await localStorage.getItem("trialPopupShownTime");
  await dispatch(getApplicationSettings());
  if (!sessionId) {
    dispatch(logoutProcedure(false));
  }
  if (!trialPopupShownTime || dayjs().isAfter(dayjs(trialPopupShownTime))) {
    localStorage.setItem("trialPopupShownTime", dayjs().add(24, "hour"));
    dispatch(setShowTrialDetailsPopup(true));
  }

  const userDetailsResponse = await makeApiRequest(
    "GET",
    backendApi,
    "user/details",
    true,
    {},
    sessionId
  );

  if (userDetailsResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (userDetailsResponse.error) {
    dispatch(errorAlert(userDetailsResponse.message));
    return;
  }

  dispatch({
    type: SET_APP_MICROSERVICES,
    payload: {
      rtcUrl: userDetailsResponse.response.rtc_url,
    },
  });

  getSocket(userDetailsResponse.response.rtc_url, sessionId);

  await dispatch(loginProcedure(userDetailsResponse.response));

  // Add dummy element for the user
  dispatch(
    addLibraryData([
      {
        id: userDetailsResponse.response.user.folder_id,
        name: "My Files",
        parent_id: 1,
        kind: "folder",
        metadata: {
          folder_id: userDetailsResponse.response.user.folder_id,
        },
      },
    ])
  );

  await dispatch(getListOfInstitutes());

  dispatch({
    type: SET_USER_SUBSCRIPTION_DETAILS,
    payload: userDetailsResponse.response.subscription,
  });
  dispatch({
    type: SET_INSTITUTE_TEACHER_REQUEST,
    payload: userDetailsResponse?.response?.institute_teacher_request,
  });

  dispatch({
    type: SET_INSTITUTE_TEACHER_INVITATION,
    payload: userDetailsResponse?.response?.institute_teacher_invitation,
  });

  dispatch({
    type: SET_INSTITUTE_TEACHER,
    payload: userDetailsResponse?.response?.institute_teacher,
  });

  if (userDetailsResponse?.response?.institute_teacher?.is_admin) {
    await dispatch(getAdminStartup());
    await dispatch(getAdminInstituteClasses());
  }

  return userDetailsResponse.response;
};

export const getUserStartupData =
  (role = "") =>
  async (dispatch, getState) => {
    if (role?.toUpperCase() === RECORDING_BOT) return;

    const sessionId = await dispatch(getSessionId());

    const userStartupResponse = await makeApiRequest(
      "GET",
      backendApi,
      `user/${role}/startup`,
      true,
      {},
      sessionId
    );

    if (userStartupResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (userStartupResponse.error) {
      dispatch(errorAlert(userStartupResponse.message));
      return;
    }

    const data = userStartupResponse.response;

    dispatch(setInstituteAdmins(data.institute_admins));
    const institute_adminsIdMap =
      data.institute_admins?.map((admin) => Number(admin.id)) || [];

    let classList = [];
    let maxWhileCount = 5;

    do {
      const {
        klass: { liveClassEndedEventData },
      } = getState();

      classList = data.classes.map((classObj) => {
        // If liveClassEnded event says lecture with class.live_lecture_id has ended,
        // even if startup api says otherwise, consider it as ended
        if (
          classObj.class_is_live &&
          liveClassEndedEventData[classObj.id] &&
          classObj.live_lecture_id === liveClassEndedEventData[classObj.id]
        ) {
          return { ...classObj, live_lecture_id: null, class_is_live: false };
        }

        let teachers = classObj.teachers.map((teacher) => {
          return {
            ...teacher,
            is_admin: institute_adminsIdMap.includes(teacher.id),
          };
        });
        return { ...classObj, teachers };
      });

      // If socketActions.liveClassEnded sets liveClassEndedEventData after classList is processed,
      // then we have to process classList again. For that we are checking if previous and current values of
      // liveClassEndedEventData is same or not
      // But while loop without condition is dangerous and could lead into infinite loop. Hence breaking it after 5 loops at max
      const {
        klass: { liveClassEndedEventData: currentLiveClassEndedEventData },
      } = getState();

      if (_.isEqual(liveClassEndedEventData, currentLiveClassEndedEventData)) {
        break;
      }

      await sleep(100);
      maxWhileCount -= 1;
    } while (maxWhileCount > 0);

    await dispatch(setClassList(classList));
    dispatch(setClassBatches(data.class_batches));

    if (role.toUpperCase() === TEACHER) {
      dispatch(updateUserDiscovery(data.discovery_status));
      dispatch({
        type: SET_CLASS_INVITES,
        payload: data.invitations,
      });
      dispatch({
        type: SET_TEACHER_PREFERENCES,
        payload: data.preferences,
      });
    }

    if (role.toUpperCase() === STUDENT) {
      dispatch({
        type: SET_STUDENT_PREFERENCES,
        payload: data.preferences,
      });
      dispatch({
        type: SET_CLASS_INVITES,
        payload: data.invitations,
      });
    }

    dispatch(setClassListLoaded(true));

    dispatch(
      addLibraryData(
        data.classes.map((classObject) => ({
          id: classObject.folder_id,
          class_id: classObject.id,
          name: classObject.name,
          parent_id: 1,
          kind: "folder",
          modified_time: classObject.joined_at || classObject.created_at,
          metadata: { class_id: classObject.id },
        }))
      )
    );

    dispatch({ type: RESET_LIVECLASS_ENDED_EVENT_DATA });
    dispatch(getLiveStatusesOfClasses(role));
  };

export const sendUserLoginOtp =
  (email, isEmail, countryCode, role) => async (dispatch) => {
    // 🔥 after student web app is added, this optional "role" param can be removed
    let body = {
      role: role,
    };
    if (isEmail === "email") {
      body.email = email;
    } else if (isEmail === "phone") {
      body.phone = {
        country_code: countryCode,
        number: email,
      };
    } else {
      return false;
    }

    body.context = OtpContexts.LOGIN_OTP;
    body.domain = window.location.origin || "";

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/login/send-otp",
      false,
      body
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
    }

    return response;
  };

export const verifyUserLogin =
  (email, isEmail, password, countryCode, role) => async (dispatch) => {
    // 🔥 after student web app is added, this optional "role" param can be removed
    let body = {
      role: role,
      password,
    };

    if (isEmail === "email") {
      body.email = email;
    } else if (isEmail === "phone") {
      body.phone = {
        country_code: countryCode,
        number: email,
      };
    } else {
      dispatch(errorAlert("Something went wrong. Please try again."));
      return -1;
    }

    body.device_details = getDeviceDetails();

    const loginResponse = await makeApiRequest(
      "POST",
      backendApi,
      "user/login",
      false,
      body
    );

    if (loginResponse.error) {
      dispatch(errorAlert(loginResponse.message));
      return -1;
    }

    if (!loginResponse.response.success && loginResponse.response.wrong_otp) {
      return 1;
    }

    if (!loginResponse.response.success) {
      dispatch(errorAlert(loginResponse.message));
      return -1;
    }

    dispatch(loginProcedure(loginResponse.response));
    return 0;
  };

export const signupTeacherUser =
  (email, isEmail, countryCode, role) => async (dispatch) => {
    let body = {};
    if (isEmail === "email") {
      body = { email: email };
    } else if (isEmail === "phone") {
      body = {
        phone: {
          country_code: countryCode,
          number: email,
        },
      };
    } else {
      return false;
    }
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `user/${role}/signup`,
      false,
      body
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.message === "Either email or phone is invalid.") {
      return response;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
    }

    return response;
  };

export const resendTeacherUserOtp =
  (email, isEmail, countryCode) => async (dispatch) => {
    let body = {};
    if (isEmail === "email") {
      body = { email: email };
    } else if (isEmail === "phone") {
      body = {
        phone: {
          country_code: countryCode,
          number: email,
        },
      };
    } else {
      dispatch(errorAlert("Invalid Email/Phone"));
      return false;
    }

    body.context = OtpContexts.LOGIN_OTP;
    body.domain = window.location.origin || "";

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/signup/resend-otp",
      false,
      body
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }

    if (response.response.success) {
      return true;
    }
    return false;
  };

export const verifyTeacherUserOtp =
  (email, isEmail, otp, countryCode) => async (dispatch) => {
    let body = {};
    if (isEmail === "email") {
      body = { email: email, otp };
    } else if (isEmail === "phone") {
      body = {
        phone: {
          country_code: countryCode,
          number: email,
        },
        otp,
      };
    } else {
      dispatch(errorAlert("Something went wrong. Please try again."));
      return -1;
    }

    body.device_details = getDeviceDetails();

    const signUpResponse = await makeApiRequest(
      "POST",
      backendApi,
      "user/signup/verify-otp",
      false,
      body
    );
    if (signUpResponse.error) {
      dispatch(errorAlert(signUpResponse.message));
      return -1;
    }

    if (!signUpResponse.response.success && signUpResponse.response.wrong_otp) {
      return 1;
    }

    if (!signUpResponse.response.success) {
      dispatch(errorAlert(signUpResponse.message));
      return -1;
    }

    dispatch(loginProcedure(signUpResponse.response));
    return 0;
  };

export const setUserPassword =
  (new_password, otp_or_password) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/password",
      true,
      { new_password, otp_or_password },
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.message));
      return response;
    } else {
      if (response.response?.success && !response.response?.wrong_otp)
        dispatch(
          setUserDetails({
            hasSetPassword: true,
          })
        );
      return response;
    }
  };

export const userOauthGoogle = (tokenId, role) => async (dispatch) => {
  const device_details = getDeviceDetails();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/oauth/google",
    false,
    { role: role, token_id: tokenId, device_details }
  );

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }

  dispatch(loginProcedure(response.response));

  if (response.response.new_user) {
    dispatch(setIsNewUser(true));
  }

  return true;
};

export const userOauthFacebook = (tokenId, role) => async (dispatch) => {
  const device_details = getDeviceDetails();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/oauth/facebook",
    false,
    { role: role, token_id: tokenId, device_details }
  );

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }

  dispatch(loginProcedure(response.response));

  if (response.response.new_user) {
    dispatch(setIsNewUser(true));
  }

  return true;
};

export const setIsNewUser = (isNewUser) => async (dispatch) => {
  dispatch({
    type: SET_IS_NEW_USER,
    payload: isNewUser,
  });
};

export const deleteAccount = (otp) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/delete-account",
    true,
    { otp_or_password: otp },
    sessionId
  );
  if (!response.error && !response.response?.wrong_otp) {
    dispatch(logoutProcedure(false));
    dispatch(
      accountSuccessFullyDeleted({ showAccountDeleteSuccessPopup: true })
    );
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
  }

  return response;
};

export const getOTP =
  ({ context = "" }) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/send-otp",
      true,
      {
        context,
        domain: window.location.origin || "",
      },
      sessionId
    );

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }

    return true;
  };

export const resetPassword =
  (otp, new_password) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/password",
      true,
      { otp_or_password: otp, new_password },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return response;
    }

    if (response.response?.success && !response.response?.wrong_otp)
      dispatch(
        setUserDetails({
          hasSetPassword: true,
        })
      );

    return response;
  };

export const editUserProfile = (data) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/profile",
    true,
    data,
    sessionId
  );

  if (response.error) {
    dispatch(errorAlert(response.message));
  } else {
    const userDetails = {
      name: response.response.user.name,
      country: response.response.user?.country,
      phone: response.response.user?.phone_number,
    };
    dispatch(setUserDetails(userDetails));
  }

  return { error: response.error, message: response.message };
};

export const setUserDetails =
  (userDetails = {}) =>
  (dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload: userDetails,
    });

    if (userDetails.name) {
      dispatch(setMyJitsiUserName(userDetails.name));
    }
  };

// Discovery

const updateUserDiscovery = (discoveryObject) => (dispatch, getState) => {
  const {
    user: { discovery },
  } = getState();

  /** if discovery api response data is the same as redux data,
   *  don't update the state */

  if (!_.isEqual(discovery, discoveryObject)) {
    dispatch({
      type: UPDATE_USER_DISCOVERY,
      payload: discoveryObject,
    });
  }
};

const accountSuccessFullyDeleted = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_ADDITIONAL_DATA,
    payload: data,
  });
};

export const incrementDiscoveryClick =
  (discoveryType) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/increment-discovery-clicks",
      true,
      { discovery_type: discoveryType },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return response;
    }

    dispatch(updateUserDiscovery(response.response.discovery_status));

    return true;
  };

export const recordEmailLead = (email) => async (dispatch, getState) => {
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/email-lead",
    false,
    { email: email, domain: window.location.origin }
  );

  return;
};

export const uploadSupportFiles =
  (selectedFiles) => async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;

    const body = selectedFiles.map((file) => ({
      description: file.description,
      file_mime_type: file.type,
      file_size: file.size,
      metadata: file.metadata,
      name: file.name,
    }));

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "library/file/support/create/bulk",
      true,
      body,
      sessionId
    );

    const result = response?.response?.result;

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch(
      addMultipleUploadFiles(
        result.reduce(
          (acc, f) => Object.assign(acc, { [f.file.id]: f.file }),
          {}
        )
      )
    );
    const fileUploadRespones = result.map(
      async (
        { upload_url, file, upload_method, upload_url_request_data },
        index
      ) => {
        const cancelTokenSource = axios.CancelToken.source();

        dispatch(
          setMultipleUploadCancelFunction(file.id, () => {
            cancelTokenSource.cancel();
            dispatch(permanentDeleteElement(file.id));
          })
        );

        return await makeFileUploadRequest(
          upload_method, // PUT or POST
          upload_url,
          selectedFiles[index],
          (progressEvent) => {
            dispatch(
              setMultipleUploadProgress(
                file.id,
                (progressEvent.loaded * 100) / progressEvent.total
              )
            );
          },
          cancelTokenSource.token,
          upload_url_request_data
        );
      }
    );

    await Promise.all(fileUploadRespones);

    return response;
  };

export const submitSupportquery = (data) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "user/support-query",
    false,
    {
      attachment_element_ids: data.attachment_element_ids,
      category: data.category,
      description: data.description,
      email_id: data.email_id,
      user_id: data.user_id,
      user_role: data.user_role,
      sub_domain: window.location.origin,
    }
  );

  if (response.error) {
    dispatch(errorAlert(response.message));
  }

  return response;
};

export const updateStudentPreferences =
  (preferences) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    // the user should not have to wait for the api to be done for preferences to reflect
    dispatch({
      type: SET_STUDENT_PREFERENCES,
      payload: preferences,
    });

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "user/student/preferences",
      true,
      { ...preferences },
      sessionId,
      false
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }
  };

export const updateTeacherPreferences =
  (preferences) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    // the user should not have to wait for the api to be done for preferences to reflect
    dispatch({
      type: SET_TEACHER_PREFERENCES,
      payload: preferences,
    });

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "user/teacher/preferences",
      true,
      { ...preferences },
      sessionId,
      false
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }
  };

export const dontShowDownloadPopup =
  (preferences) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    // the user should not have to wait for the api to be done for preferences to reflect
    dispatch({
      type: SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP,
      payload: preferences,
    });

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "user/teacher/preferences",
      true,
      { dont_show_download_popup: preferences },
      sessionId,
      false
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }
  };
export const startTrialLicense = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const apiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "subscription/teacher/trial/start",
    true,
    {},
    sessionId
  );

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return false;
  }

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.response?.success) {
    dispatch({
      type: SET_USER_SUBSCRIPTION_DETAILS,
      payload: apiResponse.response.subscription,
    });

    return apiResponse.response.success;
  }
};

export const submitSalesQuery = (data) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const apiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "user/sales-query",
    true,
    {
      country: data.country,
      email_id: data.email,
      institute: data.institute,
      message: data.message,
      name: data.name,
      phone: data.phone,
      user_id: data.id,
    },
    sessionId,
    false
  );

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return false;
  }

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  return apiResponse.response;
};

export const getListOfInstitutes = () => async (dispatch, getState) => {
  const sessionId = await localStorage.getItem("sessionId");

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "institute/list",
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch(setInstitutionList(response?.response?.institutes));
};

export const sendInstituteJoinRequest = (id) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/request",
    true,
    {
      institute_id: id,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: SET_INSTITUTE_TEACHER_REQUEST,
    payload: response?.response?.institute_teacher_request,
  });

  if (response?.response?.institute_teacher?.status === "JOINED") {
    dispatch(
      customAlert(
        "Info",
        "If your request gets accepted, you will leave the current institute automatically"
      )
    );
  }
};

export const acceptInstituteJoinInvitation =
  (id) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "institute/teacher/invitation/accept",
      true,
      {
        invitation_id: id,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch({
      type: SET_INSTITUTE_TEACHER,
      payload: response?.response?.institute_teacher,
    });
    dispatch({
      type: SET_INSTITUTE_TEACHER_INVITATION,
      payload: response?.response?.institute_teacher_invitation,
    });
  };

export const rejectInstituteJoinInvitation =
  (id) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "institute/teacher/invitation/reject",
      true,
      {
        invitation_id: id,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch({
      type: SET_INSTITUTE_TEACHER_INVITATION,
      payload: {},
    });
  };

export const createNewInstitute = (name) => async (dispatch, getState) => {
  const {
    user: { sessionId },
    application: { instituteList },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/create",
    true,
    {
      acronym: "",
      country: "",
      name,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  instituteList.push(response.response?.institute);

  dispatch(setInstitutionList(instituteList));
  return response.response;
};

export const setTeacherInvitation =
  (invitation) => async (dispatch, getState) => {
    dispatch({
      type: SET_INSTITUTE_TEACHER_INVITATION,
      payload: invitation,
    });
  };

export const retrieveClientBrandingInfo = () => async (dispatch) => {
  const subDomain = window.location.origin;
  if (ignoreRedirectingSubdomains.includes(subDomain)) {
    return;
  }

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "admin/api/clients/search",
    false,
    { domain: subDomain }
  );

  if (response.logout) {
    // dispatch(logoutProcedure(false));
  }

  if (response.error) {
    // dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: SET_CLIENT_BRANDING_INFO,
    payload: response.response?.client,
  });
};

export const setTeacherRequest = (request) => async (dispatch, getState) => {
  dispatch({
    type: SET_INSTITUTE_TEACHER_REQUEST,
    payload: request,
  });
};

export const setInstituteTeacher = (request) => async (dispatch, getState) => {
  dispatch({
    type: SET_INSTITUTE_TEACHER,
    payload: request,
  });
};

export const clearUserDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USER_DETAILS,
  });
};
