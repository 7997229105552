import {
  SET_ALL_QUIZ_AGGREGATES,
  SET_ALL_STUDENTS_QUIZ_PERFORMANCE,
  SET_ALL_QUIZ_OVERLL_STUDENTS_ANALYTICS,
  SET_TOUGHEST_QUESTION_QUIZ,
  ADD_UPLOADED_QUESTION_FILES,
  SET_ALL_QUESTION_FILES,
  REMOVE_QUESTION_FILE,
  SET_ALL_OPTION_FILES,
  ADD_UPLOADED_OPTION_FILES,
  REMOVE_OPTION_FILE,
  SET_CURRENT_QUESTION_QUIZ_AGGREGATE,
  SET_CURRENT_QUESTION_QUIZ_LIST,
  SET_CURRENT_QUESTION_QUIZ_OPTION,
  SET_CURRENT_QUESTION_QUIZ_MARKED_OPTION,
  SET_STUDENT_QUIZ_DATA,
  SET_QUIZ_RESULT_ANALYTICS,
  SET_STUDENT_QUIZ_META_DATA,
  SET_STUDENT_QUIZ_QUESTION,
  STORE_STUDENT_QUIZ_QUESTION,
  TOGGLE_STUDENT_QUIZ_LOADING,
  SET_PRACTICE_QUIZ_BOX,
  SET_ONGOING_QUIZ_DATA,
  SET_SUBMITTED_QUIZ_DATA,
  SET_STUDENT_QUIZ_QUESTION_STATUS,
  SET_STUDENT_QUIZ_TIMER_START,
  SET_STUDENT_QUIZ_TIMER_STOP,
  SET_STUDENT_QUIZ_TIMER_RESET,
  SET_STUDENT_QUIZ_TIMER_DETAILS,
  UPDATE_STUDENT_QUIZ_TIMER,
  CLEAR_STUDENT_QUIZ_DATA,
  SHOW_STUDENT_QUIZ_SUBMISSION_MODAL,
  HIDE_STUDENT_QUIZ_SUBMISSION_MODAL,
  UPDATE_STUDENT_QUIZ_META_DATA,
  TEACHER_REPORT_QUIZ_DATA,
  SET_ALL_QUIZ_QUESTION,
  SET_CURRENT_QUIZ_QUESTION,
  RESET_STUDENTS_QUIZ_PERFORMANCE_DATA,
} from "../types";

const initialState = {
  quizAggregates: {},
  quizStudentsAnalytic: [],
  quizStudentsList: {},
  overAllStudentsAnalytic: {},
  toughestQuestion: {},
  allQuestionFiles: {},
  allOptionFiles: {},
  currentStudentQuizAggregates: {},
  currentQuestionList: {},
  currentQuestionOption: {},
  currentQuestionMarksOption: {},
  studentQuizData: {},
  quizResultAnalytics: {},
  studentQuizData: {
    questions: [],
  },
  studentQuizMetaData: {},
  currentStudentQuizProgress: {
    isLoading: false,
    question: {
      prevQuestionId: null,
      currentQuestionId: null,
      nextQuestionId: null,
      questionRelationId: null,
      currentQuestionIndex: 0,
    },
  },
  practiceQuizRightSideBox: {
    selectOption: false,
    showBox: false,
  },
  ongoingQuizData: {},
  submittedQuizMetadata: {},
  submittedQuizUserData: {},
  studentQuizInterval: null,
  showSubmissionModal: false,
  time: 0,
  quizReportTable: {
    aggregateDetails: {},
    quizDetails: {},
    userDetails: {},
    quizAccuracy: {},
    userScoreTotal: {},
  },
  question: {},
  currentQuiz: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_QUIZ_AGGREGATES: {
      return {
        ...state,
        quizAggregates: action.payload,
      };
    }
    case SET_ALL_STUDENTS_QUIZ_PERFORMANCE: {
      const quizStu = action.payload.reduce((acc, obj) => {
        return Object.assign(acc, { [obj.id]: obj });
      }, {});
      return {
        ...state,
        quizStudentsAnalytic: action.payload,
        quizStudentsList: { ...quizStu },
      };
    }
    case RESET_STUDENTS_QUIZ_PERFORMANCE_DATA: {
      return {
        ...state,
        quizStudentsAnalytic: [],
        quizStudentsList: {},
      };
    }
    case SET_ALL_QUIZ_OVERLL_STUDENTS_ANALYTICS: {
      return {
        ...state,
        overAllStudentsAnalytic: action.payload,
      };
    }
    case SET_ALL_QUESTION_FILES: {
      return {
        ...state,
        allQuestionFiles: action.payload,
      };
    }
    case SET_ALL_OPTION_FILES: {
      return {
        ...state,
        allOptionFiles: action.payload,
      };
    }
    case REMOVE_QUESTION_FILE: {
      const { id, folderId } = action.payload;
      const updatedFolder = state.allQuestionFiles[folderId].filter(
        (file) => file.id !== id
      );
      return {
        ...state,
        allQuestionFiles: {
          ...state.allQuestionFiles,
          [folderId]: updatedFolder,
        },
      };
    }
    case REMOVE_OPTION_FILE: {
      const { id, folderId } = action.payload;
      const updatedFolder = state.allOptionFiles[folderId].filter(
        (file) => file.id !== id
      );
      return {
        ...state,
        allOptionFiles: {
          ...state.allOptionFiles,
          [folderId]: updatedFolder,
        },
      };
    }
    case ADD_UPLOADED_QUESTION_FILES: {
      const elements = action.payload;
      const parentId = elements[0].parent_id;
      const previousData = state.allQuestionFiles[parentId]
        ? state.allQuestionFiles[parentId]
        : [];
      return {
        ...state,
        allQuestionFiles: {
          ...state.allQuestionFiles,
          [parentId]: [...previousData, ...elements],
        },
      };
    }
    case ADD_UPLOADED_OPTION_FILES: {
      const elements = action.payload;
      const parentId = elements[0].parent_id;
      const previousData = state.allOptionFiles[parentId]
        ? state.allOptionFiles[parentId]
        : [];
      return {
        ...state,
        allOptionFiles: {
          ...state.allOptionFiles,
          [parentId]: [...previousData, ...elements],
        },
      };
    }
    case SET_TOUGHEST_QUESTION_QUIZ: {
      let arr = Object.values(action.payload);
      let toughestQuiz = [];
      if (arr.length === 1) {
        toughestQuiz = arr[0]?.question;
      } else {
        let shorarr = arr.sort((a, b) => {
          return (
            a?.question?.accuracy?.accuracy - b?.question?.accuracy?.accuracy
          );
        });
        const leastAccuracyQuestions = shorarr.filter((i) => {
          if (
            i?.question?.accuracy?.accuracy ===
            shorarr[0]?.question?.accuracy?.accuracy
          ) {
            return true;
          } else {
            return false;
          }
        });

        const questionWithMoreTimeTaken = leastAccuracyQuestions.sort(
          (a, b) => {
            return (
              b?.question?.average_time_taken - a?.question?.average_time_taken
            );
          }
        )[0];

        toughestQuiz = questionWithMoreTimeTaken?.question;
      }
      return {
        ...state,
        toughestQuestion: toughestQuiz,
      };
    }
    case SET_CURRENT_QUESTION_QUIZ_AGGREGATE: {
      return {
        ...state,
        currentStudentQuizAggregates: action.payload,
      };
    }
    case SET_CURRENT_QUESTION_QUIZ_LIST: {
      return {
        ...state,
        currentQuestionList: action.payload,
      };
    }
    case SET_CURRENT_QUESTION_QUIZ_OPTION: {
      return {
        ...state,
        currentQuestionOption: action.payload,
      };
    }
    case SET_ONGOING_QUIZ_DATA: {
      return {
        ...state,
        currentStudentQuizProgress: {
          ...state.currentStudentQuizProgress,
          question: {
            ...state.currentStudentQuizProgress.question,
            quiz_student_rel_id: action.payload.quiz_student_rel_id,
            student_id: action.payload.student_id,
            quiz_student_submission_id: action.payload.id,
          },
        },
        // Need to remove this , no need to store this here
        ongoingQuizData: action.payload,
      };
    }
    case SET_SUBMITTED_QUIZ_DATA: {
      return {
        ...state,
        submittedQuizMetadata: action.payload.submission_metadata,
        submittedQuizUserData: action.payload.user_details,
      };
    }
    case SET_CURRENT_QUESTION_QUIZ_MARKED_OPTION: {
      return {
        ...state,
        currentQuestionMarksOption: action.payload,
      };
    }
    case SET_STUDENT_QUIZ_META_DATA: {
      return {
        ...state,
        studentQuizMetaData: {
          questions: action.payload.questions,
          options: action.payload.options,
          submissions: action.payload.submissions || {},
          responses: action.payload.responses || {},
        },
        studentQuizData: {
          ...state.studentQuizData,
          quiz: action.payload.quiz,
        },
      };
    }
    case UPDATE_STUDENT_QUIZ_META_DATA: {
      return {
        ...state,
        studentQuizMetaData: {
          ...state.studentQuizMetaData,
          ...action.payload,
        },
      };
    }
    case SET_QUIZ_RESULT_ANALYTICS: {
      return {
        ...state,
        quizResultAnalytics: action.payload,
      };
    }
    case STORE_STUDENT_QUIZ_QUESTION: {
      return {
        ...state,
        studentQuizData: {
          ...state.studentQuizData,
          questions: {
            ...state.studentQuizData.questions,
            ...action.payload.question,
          },
          options: {
            ...state.studentQuizData.options,
            ...action.payload.options,
          },
        },
      };
    }
    case TOGGLE_STUDENT_QUIZ_LOADING: {
      return {
        ...state,
        currentStudentQuizProgress: {
          ...state.currentStudentQuizProgress,
          isLoading: !state.currentStudentQuizProgress.isLoading,
        },
      };
    }
    case SET_STUDENT_QUIZ_QUESTION: {
      return {
        ...state,
        currentStudentQuizProgress: {
          ...state.currentStudentQuizProgress,
          question: {
            ...state.currentStudentQuizProgress.question,
            prevQuestionId: action.payload.prevQuestionId,
            currentQuestionId: action.payload.currentQuestionId,
            nextQuestionId: action.payload.nextQuestionId,
            currentQuestionIndex: action.payload.currentQuestionIndex,
            questionRelationId:
              action.payload.questionRelationId ||
              state.currentStudentQuizProgress.question.questionRelationId,
            totalQuestionsCount:
              action.payload.totalQuestionsCount ||
              state.currentStudentQuizProgress.question.totalQuestionsCount,
          },
        },
      };
    }

    case SET_STUDENT_QUIZ_QUESTION_STATUS: {
      return {
        ...state,
        studentQuizMetaData: {
          ...state.studentQuizMetaData,
          responses: {
            ...state.studentQuizMetaData.responses,
            ...action.payload,
          },
        },
      };
    }

    case SET_PRACTICE_QUIZ_BOX: {
      return {
        ...state,
        practiceQuizRightSideBox: {
          selectOption: action.payload.seclected,
          showBox: action.payload.box,
        },
      };
    }

    // Quiz timer reducers

    case SET_STUDENT_QUIZ_TIMER_START: {
      return;
    }
    case SET_STUDENT_QUIZ_TIMER_STOP: {
      clearInterval(state.studentQuizInterval?.studentQuizIntervalFnc);
      return { ...state, studentQuizInterval: null };
    }
    case SET_STUDENT_QUIZ_TIMER_RESET: {
      return;
    }
    case UPDATE_STUDENT_QUIZ_TIMER: {
      return { ...state, time: action.payload.update };
    }
    case SET_STUDENT_QUIZ_TIMER_DETAILS: {
      return { ...state, studentQuizInterval: action.payload };
    }
    case SHOW_STUDENT_QUIZ_SUBMISSION_MODAL: {
      return { ...state, showSubmissionModal: true };
    }
    case HIDE_STUDENT_QUIZ_SUBMISSION_MODAL: {
      return { ...state, showSubmissionModal: false };
    }
    case CLEAR_STUDENT_QUIZ_DATA: {
      return {
        ...initialState,
      };
    }
    case TEACHER_REPORT_QUIZ_DATA: {
      const {
        aggregate_details,
        quiz_details,
        user_details,
        quiz_accuracy,
        user_score_total,
      } = action.payload;
      return {
        ...state,
        quizReportTable: {
          aggregateDetails: { ...aggregate_details },
          quizDetails: { ...quiz_details },
          userDetails: { ...user_details },
          quizAccuracy: { ...quiz_accuracy },
          userScoreTotal: { ...user_score_total },
        },
      };
    }
    case SET_ALL_QUIZ_QUESTION: {
      return {
        ...state,
        question: { ...action.payload },
      };
    }
    case SET_CURRENT_QUIZ_QUESTION: {
      return {
        ...state,
        currentQuiz: { ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
