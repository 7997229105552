import { isNil } from "lodash";
import { matchPath } from "react-router-dom";

function routerUtils() {
  return {
    /**
     * @param {string} pathname
     */
    path: (pathname) => {
      return {
        /**
         * @param {Array<string>} pathnameList list of pathnames to match against
         * @returns {{boolean}} whether the current path matches to any one of the path in the @param pathnameList
         */
        matchesTo: (pathnameList) => {
          const matchResponse = matchPath(pathname, {
            path: [...pathnameList],
            exact: true,
            strict: true,
          });

          return !isNil(matchResponse) && matchResponse.isExact;
        },
      };
    },
  };
}

export default routerUtils;
