import produce from "immer";
import {
  SET_CLASS_STARTUP_DETAILS,
  SET_CLASS_LIST,
  SET_CLASS_LECTURES,
  SET_LECTURE_ATTENDANCE_DETAILS,
  SET_AVERAGE_ATTENDANCE_DETAILS,
  SET_PEOPLE_POPUP_DETAILS,
  CLEAR_PEOPLE_DETAILS,
} from "../types";

const initialState = {
  classes: {},
  currentClass: 0,
  students: {},
  teachers: {},
  lectures: [],
  lectureCount: null,
  averageAttendanceStudents: {},
  popup: {
    visible: false,
    header: null,
    title: null,
    subtitle: null,
    backText: null,
    okText: null,
    backHandler: null,
    okHanlder: null,
  },
};

const peopleReducer = produce((state, action) => {
  switch (action.type) {
    case SET_LECTURE_ATTENDANCE_DETAILS: {
      return {
        ...state,
        lectures: action.payload,
      };
    }
    case SET_AVERAGE_ATTENDANCE_DETAILS: {
      const { lectureCount, students } = action.payload;
      state.lectureCount = lectureCount;
      state.averageAttendanceStudents = students;
      return;
    }
    case SET_PEOPLE_POPUP_DETAILS: {
      return {
        ...state,
        popup: {
          ...action.payload,
          visible: action.payload.visible || false,
        },
      };
    }
    case CLEAR_PEOPLE_DETAILS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}, initialState);

export default peopleReducer;
