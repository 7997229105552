import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import successTick from "./success_tick.svg";

import ModalWrapper from "../../utils/ModalWrapper/ModalWrapper";
import "./deleteAccSuccessPopup.css";

class ResetPassSucessPopup extends Component {
  render() {
    return (
      <div>
        <div
          className="overlay"
          id="asdfgr"
          style={{ backgroundColor: "rgba(150, 150, 150, 0.1)" }}
          onClick={() => this.props.closePopup()}
        />
        <div className="acc_del_success_container modalWrapper">
          <img src={successTick} className="success_tick_img" />
          <div className="Account_Deleted_Successfully">
            Account Successfully Deleted
          </div>
          {/* <div className="otp_text_container">
                        You have to make a new account. In order to login.
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapDispatchToProps)(withRouter(ResetPassSucessPopup));
