import { backendApi } from "../../config/constants";
import store from "../../store";
import { getDeviceDetails } from "../deviceDetails";
import makeApiRequest from "../makeApiRequest";

const getUserDetails = () => {
  const { user } = store.getState();

  return {
    id: user.id,
    sessionId: user.sessionId,
    email: user.email,
    role: user.role,
  };
};

const log = async (type, event, message, extra) => {
  const {
    user: { sessionId },
  } = store.getState();

  const body = {
    type,
    event,
    message,
    user: getUserDetails(),
    device: getDeviceDetails(),
    extra,
  };

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "logger/log",
    true,
    body,
    sessionId
  );

  if (response.logout) {
    return false;
  }

  if (response.error) {
    // TODO: retry
    return false;
  }
};

const info = (event, message, ...extra) => {
  log("INFO", event, message, extra);
};
const error = (event, message, ...extra) => {
  log("ERROR", event, message, extra);
};
const warn = (event, message, ...extra) => {
  log("WARNING", event, message, extra);
};

export const logger = {
  info,
  warn,
  error,
};
