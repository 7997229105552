import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { checkPhoneOrEmail, validateEmail } from "../../store/actions/utils";
import SelectedUserChips from "../SelectedUserChips/SelectedUserChips";
import "./AutoSuggestionInputStyle.css";
import SuggestionTile from "./SuggestionTile/SuggestionTile";
import { deepClone } from "../../utils/misc";

export default class AutoSuggestionInput extends Component {
  state = {
    allInputData: [],
    inputValue: "",
    suggestionList: [],
    invalidCount: 0,
    arrowSelectedId: null,
  };

  inputRef = React.createRef();
  autoSuggestionContainerRef;
  filteredDataCopy = [];

  componentDidMount() {
    const { initialData, suggestionList } = this.props;

    // if suggestionList is null, then no need to set it
    if (suggestionList) {
      this.setState({ suggestionList: suggestionList });
    }

    this.setState({ allInputData: initialData });
    document.addEventListener("click", this.handleClickEvent);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { suggestionList, initialData = [] } = this.props;
    const { allInputData, invalidCount } = this.state;
    if (prevProps.suggestionList === null && suggestionList) {
      this.setState({ suggestionList: suggestionList });
    }

    if (
      // Both arrays (prevProps.initialData, initialData) will be treated as not equal for first time even if both arrays have same objects because of two reasons.
      // 1. each object in array has `id` which is generated using `uuid`. this gives new id each time even if the email is same (for file upload case).
      // 2. isEqual will return true only if both arrays have same objects and order of objects is also same in both arrays.
      // After setState, once again componentDidUpdate will be called and this time both arrays are equal.
      !_.isEqual(prevProps.initialData, initialData) &&
      initialData.length > 0
    ) {
      this.setState({
        allInputData: _.uniqWith(
          [...initialData, ...allInputData],
          (a, b) => a.email === b.email
        ),
      });
    }

    if (prevState.allInputData !== allInputData) {
      const newInvalidCount = allInputData.reduce(
        (acc, obj) => acc + (obj.isValid ? 0 : 1),
        0
      );
      this.props.onUpdate(allInputData, newInvalidCount); // returns all data with updated entry
      if (invalidCount !== newInvalidCount) {
        this.setState({ invalidCount: newInvalidCount });
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleClickEvent);
  };

  handleClickEvent = (event) => {
    const { inputValue } = this.state;
    if (
      !this.autoSuggestionContainerRef.contains(event.target) &&
      inputValue !== ""
    ) {
      // if clicked outside of component and inputvalue is not empty then consider that input
      this.extractInputsbySeparator();
    }
  };

  cleanCopiedText = (value) => {
    const valueArray = value.split(/[\s,;]+/g);
    const validEmails = valueArray.filter((email) => validateEmail(email));
    return validEmails.join(" ");
  };

  handleInputChange = (event, paste = false) => {
    let value = event.target.value;

    if (paste) {
      value = this.cleanCopiedText(value);
    }

    if (value === "," || value === ";") return; // ; and , acts as separator

    this.setState(
      {
        inputValue: value,
        isInput: true,
        arrowSelectedId: null, // reset arrowKeySelection if input is changed
      },
      () => {
        if (paste) {
          this.extractInputsbySeparator();
        }
      }
    );
    event.target.scrollTop = event.target.scrollHeight;
  };

  extractInputsbySeparator = () => {
    const { inputValue } = this.state;
    let trimmedVal = inputValue.trim();
    const splittedValues = trimmedVal.split(/[ \;,\)]+/);
    splittedValues.forEach((value) => {
      if (value === "") {
        // cases when string will end with ; or , splittedString will have a string with value ""
        return;
      }
      if (splittedValues.length > 1) {
        setTimeout(() => {
          this.handleSelection(this.filteredDataCopy, value);
        }, 100);
      } else {
        this.handleSelection(this.filteredDataCopy, value);
      }
    });
  };

  handleSelection = (filteredData = [], value = "") => {
    const { allInputData, suggestionList, arrowSelectedId } = this.state;
    let suggestionSelection;
    if (filteredData?.length > 0 && arrowSelectedId) {
      const selectedTileIndex = filteredData.findIndex(
        (l) => l.id === arrowSelectedId
      );
      // check if entered value is from suggestion
      suggestionSelection = filteredData[selectedTileIndex]; // object

      // add selected field in the suggestion list for the selected object
      const suggestionListCopy = deepClone(suggestionList);
      suggestionListCopy.forEach((element) => {
        if (element.id === suggestionSelection.id) {
          element.selected = true;
        }
      });
      // update allInputData, suggestionList
      this.setState({
        allInputData: [
          ...allInputData,
          { isValid: true, ...suggestionSelection },
        ],
        suggestionList: suggestionListCopy,
        inputValue: "",
      });
    } else {
      // if it is not from suggestion then validate the input and add it in input Data
      const checkValid = checkPhoneOrEmail(value);
      let isValid = false;
      if (checkValid !== "invalid") {
        isValid = true;
      }

      this.setState({
        allInputData: [
          ...allInputData,
          { id: uuid(), name: value, isValid, [checkValid]: value },
        ],
        inputValue: "",
      });
    }
  };

  handleKeyDown = (event, filteredData) => {
    const { allInputData, inputValue } = this.state;
    if (
      (event.key === "Enter" ||
        event.key === "Tab" ||
        event.key === " " ||
        event.key === ";" ||
        event.key === ",") &&
      inputValue.trim() !== ""
    ) {
      if (event.key === "Tab") {
        event.preventDefault();
      }
      this.extractInputsbySeparator();
    } else if (
      event.key === "Backspace" &&
      inputValue.trim() === "" &&
      allInputData.length > 0
    ) {
      // delete the last chip from record
      const length = allInputData.length;
      const deletedChipData = allInputData[length - 1];
      this.handleDelete(length - 1, deletedChipData.id);
    } else if (
      (event.key === "ArrowDown" || event.key === "ArrowUp") &&
      filteredData.length > 0
    ) {
      this.handleArrowKeys(filteredData, event.key);
    }
  };

  handleDelete = (index, id) => {
    const { allInputData, suggestionList } = this.state;
    const allInputDataCopy = [...allInputData];
    const removedItem = allInputDataCopy.splice(index, 1);
    if (removedItem[0].isOriginal) {
      // Call Popup
      this.deleteOriginal(index, id, removedItem[0]);
    } else {
      suggestionList.forEach((element) => {
        // mark not selected in suggestionList
        if (element.id === id && element.hasOwnProperty("selected")) {
          element.selected = false;
        }
      });
      this.setState({
        allInputData: allInputDataCopy,
        suggestionList: suggestionList,
      });
    }
  };

  handleDeleteChip = (id) => {
    const { allInputData } = this.state;
    const index = allInputData.findIndex((l) => l.id === id);
    this.handleDelete(index, id);
    this.inputRef.focus();
  };

  handleSuggestionTile = (id) => {
    const { suggestionList } = this.state;
    const tempSuggestionList = deepClone(suggestionList);
    const obj = tempSuggestionList.find((l) => l.id === id);
    tempSuggestionList.forEach((element) => {
      if (element.id === id) {
        element.selected = true;
        element.isValid = true;
      }
    });
    this.setState({
      allInputData: [...this.state.allInputData, { ...obj }],
      inputValue: "",
      suggestionList: tempSuggestionList,
    });
    this.inputRef.focus();
  };

  deleteTeacher = (id) => {
    return this.props.removeTeacher(this.props.classId, parseInt(id));
  };

  deleteOriginal = (index, id, removedItem) => {
    this.props.setPeoplePopupDetails({
      visible: true,
      header: "Remove Teacher",
      title: "Are you sure you want to remove teacher from this class ?",
      subtitle: `This teacher will lose access to all that contacts, comments, chat history, and all other data linked to this Class`,
      className: removedItem.className,
      batchName: removedItem.batchName,
      teacherName: removedItem.name,
      contactInfo: removedItem.email ? removedItem.email : removedItem.phone,
      imageURL: removedItem.imageURL,
      index: index,
      id: id,
      backText: "BACK",
      okText: "REMOVE",
      backHandler: () => {
        this.props.setPeoplePopupDetails({
          visible: false,
          header: null,
          title: null,
          subtitle: null,
          className: null,
          batchName: null,
          teacherName: null,
          contactInfo: null,
          imageURL: null,
          backText: null,
          okText: null,
          backHandler: null,
          okHanlder: null,
        });
      },
      okHanlder: async () => {
        const { allInputData, suggestionList } = this.state;
        const allInputDataCopy = [...allInputData];
        const removedItem = allInputDataCopy.splice(index, 1);
        suggestionList.forEach((element) => {
          // mark not selected in suggestionList
          if (element.id === id && element.hasOwnProperty("selected")) {
            element.selected = false;
          }
        });
        const successRemove = await this.deleteTeacher(removedItem[0].id);
        console.log(successRemove);
        if (successRemove) {
          this.setState({
            allInputData: allInputDataCopy,
            suggestionList: suggestionList,
          });
        }
        this.props.setPeoplePopupDetails({
          visible: false,
          header: null,
          title: null,
          subtitle: null,
          className: null,
          batchName: null,
          teacherName: null,
          contactInfo: null,
          imageURL: null,
          backText: null,
          okText: null,
          backHandler: null,
          okHanlder: null,
        });
      },
    });
  };

  handleArrowKeys = (filteredData, pressedKey) => {
    const { arrowSelectedId } = this.state;
    let newSelectedId = null;
    if (!arrowSelectedId && pressedKey === "ArrowDown") {
      // above case will prevent suggestions from being selected if up arrow will be clicked for the first time
      newSelectedId = filteredData[0].id;
    } else {
      const selectedTileIndex = filteredData.findIndex(
        (l) => l.id === arrowSelectedId
      );
      const nextIndex =
        pressedKey === "ArrowDown"
          ? selectedTileIndex + 1
          : selectedTileIndex - 1;
      if (nextIndex >= 0 && filteredData.length > nextIndex) {
        newSelectedId = filteredData[nextIndex].id;
      }
    }
    this.setState({ arrowSelectedId: newSelectedId });
  };

  handleAutoSuggestionContainerRef = (ref) => {
    this.autoSuggestionContainerRef = ref;
  };

  handleMouseWheelHorizontalScroll = (e) => {
    var container = document.getElementById("textarea__id");
    var containerScrollPosition = container.scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: "smooth", //if you want smooth scrolling
    });
  };

  render() {
    const {
      inputValue,
      allInputData,
      suggestionList,
      invalidCount,
      arrowSelectedId,
    } = this.state;
    const {
      placeholder,
      role,
      maxHeight,
      minHeight,
      hideUser,
      backgroundColor,
      showDisplayClip = true,
    } = this.props;
    const lowercasedFilter =
      inputValue.trim() !== "" && inputValue.trim().toLowerCase();
    const filteredData = suggestionList?.filter((item, index) => {
      if (item.hasOwnProperty("selected") && item["selected"]) return; // skip the already selected ones
      return Object.keys(item).some((key) => {
        // skip un-necessary field
        if (
          key === "id" ||
          key === "profile_photo_url" ||
          typeof item[key] !== "string"
        ) {
          // if boolean don't check
          return;
        } else if (key === "phone_number") {
          // make a check on combined phone number with country code as that's how it is visible to user
          const phoneWithCountryCode = `${item["phone_country_code"]}${item["phone_number"]}`;
          return phoneWithCountryCode.includes(lowercasedFilter);
        } else {
          return (
            item[key] && item[key].toLowerCase().includes(lowercasedFilter)
          );
        }
      });
    });
    this.filteredDataCopy = filteredData;
    const isInput =
      inputValue?.trim()?.length > 0 && filteredData?.length > 0 ? true : false;

    const availableWidth = this.inputRef.offsetWidth;
    // const calculatedWidth = inputValue.length * 8;
    // if (calculatedWidth > availableWidth) {
    //   this.lastAvailableWidth = availableWidth;
    // }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "15px gibson semibold";
    const width = context.measureText(inputValue).width;
    const formattedWidth = Math.ceil(width);

    return (
      <div
        className="input__box__and__suggestion__list__container"
        ref={this.handleAutoSuggestionContainerRef}
      >
        <div
          className={
            this.state.inputValue === "" && this.state.allInputData.length === 0
              ? "input__and__teacher__count__container empty__background__box"
              : invalidCount
              ? "input__and__teacher__count__container input__and__teacher__count__container_error"
              : "input__and__teacher__count__container"
          }
          style={{ backgroundColor }}
        >
          <div
            className={
              this.state.inputValue === "" &&
              this.state.allInputData.length === 0
                ? "suggestion__input__container"
                : "suggestion__input__container"
            }
            style={{
              maxHeight: maxHeight ? maxHeight - 20 : null,
              minHeight: minHeight ? minHeight - 20 : null,
            }}
            onClick={() => this.inputRef?.focus()}
          >
            {showDisplayClip &&
              allInputData?.map((item, index) => {
                const value = item.name
                  ? item.name
                  : item.email
                  ? item.email
                  : item.phone_number
                  ? `${item.phone_country_code}${item.phone_number}`
                  : "Floating User";

                return hideUser === item.id ? null : (
                  <SelectedUserChips
                    key={item.id}
                    id={item.id}
                    value={value}
                    title={item.email || item.phone_number || value}
                    isCorrect={item.isValid}
                    onChipDelete={(id) => {
                      this.handleDeleteChip(id);
                    }}
                    isAddedTeacherAdmin={item.isAdmin}
                    isUserTeacherAdmin={this.props.isUserAdmin}
                  />
                );
              })}
            {/* {calculatedWidth > this.lastAvailableWidth && (
              // this div will push down the textarea when content will exceed the available area
              <div style={{ width: availableWidth, backgroundColor: "red" }} />
            )} */}

            <textarea
              wrap="off"
              id="textarea__id"
              onWheel={this.handleMouseWheelHorizontalScroll}
              style={{
                // width: `${inputValue.length * 8}px`,
                width: availableWidth,
                backgroundColor,
                minWidth:
                  this.state.inputValue === "" &&
                  this.state.allInputData.length === 0
                    ? "400px"
                    : "10px",
              }}
              className={
                this.state.inputValue === "" &&
                this.state.allInputData.length === 0
                  ? "auto__suggestion__input empty__background__box hide__scrollbar"
                  : "auto__suggestion__input hide__scrollbar"
              }
              spellCheck={"false"}
              ref={(ref) => (this.inputRef = ref)}
              value={inputValue}
              onChange={(e) => this.handleInputChange(e)}
              onKeyDown={(e) => this.handleKeyDown(e, filteredData)}
              placeholder={allInputData.length === 0 ? placeholder : ""}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onPaste={(e) => {
                e.persist();
                setTimeout(() => {
                  this.handleInputChange(e, true);
                }, 250);
              }}
            />
          </div>
          {showDisplayClip && (
            <div className="teacher__count__container">
              <p className="teacher__count__text">
                {invalidCount > 0
                  ? `${invalidCount} invalid email ID(s)`
                  : `${allInputData.length} ${role}(s)`}
              </p>
            </div>
          )}
        </div>
        <div
          className={
            isInput
              ? `suggestion__list__container active`
              : `suggestion__list__container`
          }
        >
          {filteredData?.map((item) => {
            return (
              <SuggestionTile
                key={item.id}
                id={item.id}
                img={item.profile_photo_url}
                name={item.name}
                email={item.email}
                phone={item.phone_number}
                countryCode={item.phone_country_code}
                selected={item.id === arrowSelectedId}
                handleClick={(id) => this.handleSuggestionTile(id)}
                listfontsize={this.props.listfontsize}
                imageWidth={this.props.imageWidth}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
