import { DESKTOP, OPTIONS, VIDEO_OPTIONS } from "../../utils/constants";

const findVideoTrack = (
  sharedParticipantVideoTrack,
  sharedParticipantId,
  currentTeacherParticipant,
  currentTeacherDesktopParticipant
) => {
  let toSelectParticipantId;
  let videoTrack;
  let videoState;
  let videoPosition;

  if (sharedParticipantVideoTrack && sharedParticipantVideoTrack.track) {
    toSelectParticipantId = sharedParticipantId;
    videoTrack = sharedParticipantVideoTrack.track;
    videoState = VIDEO_OPTIONS.big;
  } else if (
    (currentTeacherDesktopParticipant &&
      !currentTeacherDesktopParticipant.videoMuted &&
      currentTeacherDesktopParticipant?.videoTrack?.track) ||
    (currentTeacherParticipant &&
      !currentTeacherParticipant.videoMuted &&
      currentTeacherParticipant.videoTrack &&
      currentTeacherParticipant.videoTrack.track)
  ) {
    toSelectParticipantId = currentTeacherParticipant.participantId;
    videoTrack = currentTeacherParticipant.videoTrack?.track;
    videoPosition = currentTeacherParticipant.videoPosition;
    if (currentTeacherParticipant.broadcastType === OPTIONS.SCREENSHARE) {
      videoTrack = currentTeacherDesktopParticipant?.videoTrack?.track;
      videoState = VIDEO_OPTIONS.big;
    } else if (
      currentTeacherParticipant.broadcastType === OPTIONS.VIDEO_OR_DP
    ) {
      videoState = VIDEO_OPTIONS.big;
    } else {
      videoState = currentTeacherParticipant.videoState;
    }
  } else if (
    currentTeacherParticipant &&
    (currentTeacherParticipant.broadcastType === OPTIONS.WHITEBOARD ||
      currentTeacherParticipant.broadcastType === OPTIONS.SLIDESHARE)
  ) {
    toSelectParticipantId = currentTeacherParticipant.participantId;
  }

  // request video track if selected participant screen is casted or current teacher participant is sharing their desktop.
  const requestVideoTrack =
    toSelectParticipantId === sharedParticipantId ||
    (videoTrack && videoTrack?.videoType === DESKTOP);

  return {
    toSelectParticipantId,
    videoTrack,
    videoState,
    videoPosition,
    requestVideoTrack,
  };
};

export default findVideoTrack;
