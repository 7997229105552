import React, { Component } from "react";
import Cross from "../assets/cross.svg";
import EllipsisText from "../../EllipsisText/EllipsisText";
import "./MoveAndCopyPopupStyles.css";
import TreeComponent from "../../FileSystemCards/TreeComponent";
import {
  moveFiles,
  copyFiles,
  setTreeSelectedFolderId,
} from "../../../store/actions/fileSystemActions";
import { connect } from "react-redux";
import { closeMoveAndCopyFilePopup } from "../../../store/actions/fileOptions";
import LoaderWrapper from "../../Loader";
import SlidingTabs, { SlidingTabPane } from "../../SlidingTabs/SlidingTabs";
class MoveAndCopyPopup extends Component {
  state = {
    destinationParentId: null,
    destinationClassId: null,
    loading: false,
    activeTab:
      this.props.location.pathname === "/my-files" ||
      this.props.role === "student"
        ? 2
        : 1,
  };

  componentDidMount() {
    const { elementId, fileSystemData, location } = this.props;
    if (location.pathname !== "/dashboard") {
      this.props.setTreeSelectedFolderId(
        fileSystemData[elementId].parent_id,
        true
      );
    }

    this.setState({
      activeTab:
        location.pathname === "/my-files" || this.props.role === "student"
          ? 2
          : 1,
    });
  }

  setLoading = (loading) => this.setState({ loading });

  setDestinationData = (destinationParentId, destinationClassId) => {
    this.setState({ destinationParentId, destinationClassId });
  };

  moveData = async () => {
    const { elementId, currentClass, fileSystemData } = this.props;
    const { destinationParentId, destinationClassId } = this.state;
    const classId = fileSystemData[elementId]?.class_id || currentClass;
    this.setLoading(true);

    if (destinationParentId) {
      await this.props.moveFiles(
        [elementId],
        destinationParentId,
        classId,
        destinationClassId
      );
      this.setLoading(false);
      this.props.closeMoveAndCopyFilePopup();
    } else {
      this.setLoading(false);
      this.props.closeMoveAndCopyFilePopup();
    }
  };
  copyData = async () => {
    const { elementId, currentClass, fileSystemData } = this.props;
    const { destinationParentId, destinationClassId } = this.state;
    const classId =
      fileSystemData[elementId]?.file_location_type === "shared_files"
        ? null
        : fileSystemData[elementId]?.class_id || currentClass;
    this.setLoading(true);
    if (destinationParentId) {
      await this.props.copyFiles(
        [elementId],
        destinationParentId,
        classId,
        destinationClassId
      );
      this.setLoading(false);

      this.props.closeMoveAndCopyFilePopup();
    } else {
      this.setLoading(false);
      this.props.closeMoveAndCopyFilePopup();
    }
  };

  render() {
    const { isMove, elementId, fileSystemData, role } = this.props;

    const elementName = fileSystemData[elementId]?.name;

    return (
      <LoaderWrapper loading={this.state.loading}>
        <div className="popup__container__wrapper">
          <div className="move__copy__file__container__wrapper__inner">
            <div className="move__and__copy__file__head">
              <p className="popup__head"> {isMove ? "Move" : "Copy"}</p>

              <img
                onClick={this.props.closeMoveAndCopyFilePopup}
                className="move__and__copy__popup__close__icon"
                src={Cross}
                alt=""
              />
            </div>
            <div className="move__and__copy__popup__line"></div>

            <div className="move__and__copy__file__popup__row">
              <div className="move__and__copy__file__popup__name">
                <EllipsisText numberOfLines={1}>{elementName}</EllipsisText>
              </div>
            </div>
            <div className="move__and__copy__file__popup__content__wrapper">
              <SlidingTabs
                activeTabKey={this.state.activeTab}
                onChange={(key) => {
                  this.setState({ activeTab: key });
                }}
                wrapperClassName="align__center"
              >
                {role === "teacher" && (
                  <SlidingTabPane tabname="CLASS FILES" tabkey={1}>
                    <div className="move__and__copy__file__content__file__browser">
                      <TreeComponent
                        {...this.props}
                        setDestinationData={this.setDestinationData}
                        dontSetCurrentFolder={true}
                        moveorcopy={true}
                        activeTab={this.state.activeTab}
                      />
                    </div>
                  </SlidingTabPane>
                )}
                <SlidingTabPane tabname="MY FILES" tabkey={2}>
                  <div className="move__and__copy__file__content__file__browser">
                    <TreeComponent
                      {...this.props}
                      setDestinationData={this.setDestinationData}
                      dontSetCurrentFolder={true}
                      moveorcopy={true}
                      activeTab={this.state.activeTab}
                    />
                  </div>
                </SlidingTabPane>
              </SlidingTabs>
            </div>
            <div className="move__and__copy__file__popup__button__row__wrapper">
              <button
                className="move__and__copy__file__popup__back__button "
                onClick={this.props.closeMoveAndCopyFilePopup}
              >
                BACK
              </button>

              <button
                className="move__and__copy__file__popup__moveorcopy__button"
                onClick={() => {
                  isMove ? this.moveData() : this.copyData();
                }}
              >
                {isMove ? "MOVE" : "COPY"}
              </button>
            </div>
          </div>
        </div>
      </LoaderWrapper>
    );
  }
}

const mapStateToProps = ({ fileSystem, klass, fileOptions, user }) => ({
  currentClass: klass.currentClass,
  currentFolderId: fileSystem.currentFolderId,
  fileSystemData: fileSystem.fileSystemData,
  isMove: fileOptions.isMove,
  elementId: fileOptions.moveCopyElement,
  role: user.role,
});

const mapDispatchToProps = {
  moveFiles,
  copyFiles,
  closeMoveAndCopyFilePopup,
  setTreeSelectedFolderId,
};
export default connect(mapStateToProps, mapDispatchToProps)(MoveAndCopyPopup);
