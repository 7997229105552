import React, { Component } from "react";
import { connect } from "react-redux";
import Cross from "../assets/cross.svg";
import EllipsisText from "../../EllipsisText/EllipsisText";
import "./DeleteFilePopupStyles.css";
import {
    removeSharedFile,
    trashElement,
    trashStudentElement
} from "../../../store/actions/fileSystemActions";
import { convertBytesFormat, getSplitName } from "../../../utils/fileSystem";
import { closeDeleteFilePopup } from "../../../store/actions/fileOptions";
import LoaderWrapper from "../../Loader";
import { screenNames } from "../../AppRouter/routes";
import { SLIDE_SHARE_STATE } from "../../../utils/constants";

class DeletePopup extends Component {
    state = { loading: false };
    setLoading = (loading) => this.setState({ loading });

    onDelete = async () => {
        this.setLoading(true);
        this.props.closeDeleteFilePopup();
        if (this.props.role === "student") {
            await this.props.trashStudentElement(this.props.deleteElementId);
        } else {
            if (
                this.props.fileSystemData[this.props.deleteElementId]
                    ?.file_location_type === "shared_files"
            ) {
                this.props.removeSharedFile(this.props.deleteElementId);
            } else {
                await this.props.trashElement(this.props.deleteElementId);
            }
        }
        this.setLoading(false);
    };

    checkIfBreadCrumbTextOverflowParentBounds = (folderNameList) => {
        let breadcrumbWithoutEllipsis = "";
        let breadcrumbWithEllipsis = "";
        const container = document.getElementById(
            "link__classroom__multi__level__breadcrumbs"
        );
        const overflowContainer = document.getElementById(
            "link__classroom__multi__level__breadcrumbs__fit__check"
        );
        if (!container || !overflowContainer) return;
        const containerWidth = container?.offsetWidth;
        folderNameList.forEach((element) => {
            breadcrumbWithoutEllipsis =
                breadcrumbWithoutEllipsis + element[1] + ">";
            breadcrumbWithEllipsis =
                breadcrumbWithEllipsis + getSplitName(element[1], 20) + ">";
        });
        overflowContainer.innerHTML = breadcrumbWithoutEllipsis;
        const overflowContainerWidth = overflowContainer.offsetWidth;
        overflowContainer.innerHTML = breadcrumbWithEllipsis;
        const breadcrumbWithEllipsisWidth = overflowContainer.offsetWidth;
        overflowContainer.innerHTML = "";
        if (
            overflowContainerWidth > containerWidth &&
            breadcrumbWithEllipsisWidth > containerWidth
        ) {
            return { overflow: true, overflowWithEllipsis: true };
        } else if (breadcrumbWithEllipsisWidth > containerWidth) {
            return { overflow: false, overflowWithEllipsis: true };
        } else if (overflowContainerWidth > containerWidth) {
            return { overflow: true, overflowWithEllipsis: false };
        }
    };

    getFolderNameList = () => {
        const { fileSystemData, currentFolderId, slideShareState } = this.props;
        let folderNameReverseList = [];
        let pointedFolderId = currentFolderId;
        let breadCrumbText = "";
        const currentScreen = window.location?.pathname;
        /**
         * 1. if current-screen is live class then don't fetch folderNameList if user navigated to top level of folders
         * 2. if not live-class then always fetch
         */
        if (
            (currentScreen === screenNames.liveClass &&
                slideShareState !== SLIDE_SHARE_STATE.FOLDER_CLASS_LEVEL) ||
            currentScreen !== screenNames.liveClass
        ) {
            while (pointedFolderId !== 1) {
                folderNameReverseList.push([
                    pointedFolderId,
                    fileSystemData[pointedFolderId].name
                ]);
                pointedFolderId =
                    fileSystemData[pointedFolderId].parent_id ||
                    fileSystemData[pointedFolderId].parent;
            }
        }
        if (this.props.prefix) {
            folderNameReverseList.push([null, this.props.prefix]);
        }
        const folderNameList = folderNameReverseList.reverse();
        folderNameList.forEach((element, index) => {
            breadCrumbText =
                breadCrumbText + (index !== 0 ? ">" : "") + element[1];
        });
        const overflow =
            this.checkIfBreadCrumbTextOverflowParentBounds(folderNameList);
        return { folderNameList, ...overflow, breadCrumbText };
    };

    getCompleteLocation = (folderNameList) => {
        const maxLevel = 2;
        let location = "";

        folderNameList.map((idNameList, index) => {
            /**
             * @param idNameList stores the id and name of folder, e.g. [35463, some_folder_name]
             */
            const isLastBreadcrumb =
                index === folderNameList.length - 1 || index === maxLevel - 1;
            location = location + `${getSplitName(idNameList[1], 20)}/`;
        });

        return location.slice(0, -1);
    };

    render() {
        const { deleteElementId, fileSystemData, location } = this.props;

        const elementData = fileSystemData[deleteElementId];
        const deleteElementName = elementData.name;
        const deleteElementSize = convertBytesFormat(elementData.file_size);
        const deleteElementLocation = elementData.file_location;

        const {
            folderNameList,
            overflow,
            overflowWithEllipsis,
            breadCrumbText
        } = location.pathname !== "/dashboard" && this.getFolderNameList();

        return (
            <LoaderWrapper loading={this.state.loading}>
                <div className=" popup__container__wrapper">
                    <div className="  delete__file__container__wrapper__inner">
                        <div className="delete__file__popup__header">
                            <p className="popup__head">Delete File</p>
                            <div onClick={this.props.closeDeleteFilePopup}>
                                <img
                                    className="delete__file__popup__close__icon"
                                    src={Cross}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="popup__line" />
                        <div className="delete__file__popup__row">
                            <div className="delete__file__popup__name">
                                <EllipsisText numberOfLines={1}>
                                    {deleteElementName}
                                </EllipsisText>
                            </div>
                            {deleteElementSize !== "0 Bytes" && (
                                <div className="delete__file__popup__size">
                                    <EllipsisText numberOfLines={1}>
                                        {deleteElementSize}
                                    </EllipsisText>
                                </div>
                            )}
                            {location.pathname !== "/dashboard" ? (
                                <div
                                    className="delete__file__popup__location "
                                    title={this.getCompleteLocation(
                                        folderNameList
                                    )}
                                >
                                    <EllipsisText numberOfLines={1}>
                                        {this.getCompleteLocation(
                                            folderNameList
                                        )}
                                    </EllipsisText>
                                </div>
                            ) : (
                                <div
                                    className="delete__file__popup__location "
                                    title={deleteElementLocation}
                                >
                                    <EllipsisText numberOfLines={1}>
                                        {deleteElementLocation}
                                    </EllipsisText>
                                </div>
                            )}
                        </div>
                        <hr className="popup__line" />
                        <div className="delete__file__popup__content__confirmation__container">
                            <p className="delete__file__popup__confirmation">
                                Are you sure you want to delete this File?
                            </p>
                            <p className="delete__file__popup__note">
                                You can still restore this file from trash
                                within 30 days, to delete permanently remove it
                                from trash.
                            </p>
                        </div>

                        <div className="delete__file__popup__button__row__wrapper">
                            <button
                                className="delete__file__popup__back__button "
                                onClick={this.props.closeDeleteFilePopup}
                            >
                                BACK
                            </button>

                            <button
                                className="delete__file__popup__delete__button"
                                onClick={this.onDelete}
                            >
                                DELETE
                            </button>
                        </div>
                    </div>
                </div>
            </LoaderWrapper>
        );
    }
}

const mapStateToProps = ({ fileSystem, fileOptions, klass, user }) => ({
    deleteElementId: fileOptions.deleteElementId,
    fileSystemData: fileSystem.fileSystemData,
    currentFolderId: fileSystem.currentFolderId,
    teachers: klass.teachers,
    userId: user.id,
    role: user.role
});

const mapDispatchToProps = {
    trashElement,
    closeDeleteFilePopup,
    trashStudentElement,
    removeSharedFile
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePopup);
