import React, { Component } from "react";
import { connect } from "react-redux";
import "./RateSessionStyle.css";
import "../EndSessionConfirmationPopup/EndSessionConfirmationStyle.css";
import ClassOverIcon from "./assets/class-over-icon.svg";
import { ReactComponent as RatedStar } from "./assets/rated-star-icon.svg";
import { ReactComponent as UnratedStar } from "./assets/unrated-star-icon.svg";

export default class StudentRateSessionPopup extends Component {
  state = {
    starsSelected: 0,
  };

  selectStars = (stars) => {
    this.setState({ starsSelected: stars });
    if (this.submitTimeout) {
      clearTimeout(this.submitTimeout);
    }
    this.submitTimeout = setTimeout(
      () => this.props.submitSessionRating(stars),
      1000
    );
  };

  render() {
    const { starsSelected } = this.state;
    return (
      <div className="popup__container__wrapper">
        <div className="feedback__popup__contaier">
          <div className="End_Session_Confirmation_Popup_Content">
            <div className="End_Session_Confirmation_Popup_Heading">
              <img src={ClassOverIcon} alt="" />
              <p>Class Over</p>
            </div>
            <div className="End_Session_Confirmation_Popup_Body">
              <p style={{ textAlign: "center", margin: "5px" }}>
                How would you rate this session?
              </p>
              <div className="Rate_Session_Confirmation_Popup_Stars">
                {Array(5)
                  .fill()
                  .map((item, idx) => (
                    <div
                      style={{ margin: 10 }}
                      key={idx}
                      onClick={() => this.selectStars(idx + 1)}
                    >
                      {starsSelected >= idx + 1 ? (
                        <RatedStar />
                      ) : (
                        <UnratedStar />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
