import axios from "axios";
import { CLIENT_ERROR_CODE } from "./constants";
import { deepClone } from "./misc";

const makeApiRequest = async (
  type,
  microservice,
  url,
  auth = false,
  body = {},
  sessionId = "",
  filePresent = false,
  uploadProgressEventHandler,
  cancelToken = undefined
) => {
  if (!type || !url) {
    return {
      error: true,
      logout: false,
      message: "Something went wrong. Please try again.",
      status: 400,
    };
  }

  if (!["PUT", "POST", "DELETE", "GET"].includes(type)) {
    return {
      error: true,
      logout: false,
      message: "Something went wrong. Please try again.",
      status: 400,
    };
  }

  const headers = {};
  let data;
  if (auth) {
    if (!sessionId) {
      return {
        error: true,
        logout: true,
        message: "You have been logged out. Please login again.",
        status: 401,
      };
    }

    headers.Authorization = `Bearer ${sessionId}`;
  }

  if (filePresent) {
    // Reference: https://stackoverflow.com/questions/38458570/multer-uploading-array-of-files-fail
    headers["Content-Type"] = "multipart/form-data";

    data = new FormData();

    Object.keys(body).forEach((key) => {
      if (key !== "files") {
        data.append(key, body[key]);
      }
    });

    body.files.forEach((file) => {
      data.append("files", file);
    });
  } else {
    data = deepClone(body);
  }

  try {
    let response;
    if (type === "POST") {
      response = await axios.post(`${microservice}/${url}`, data, {
        headers,
        onUploadProgress: uploadProgressEventHandler,
        cancelToken: cancelToken,
      });
    } else if (type === "GET") {
      response = await axios.get(`${microservice}/${url}`, {
        params: {
          ...data,
        },
        headers,
      });
    } else if (type === "PUT") {
      response = await axios.put(`${microservice}/${url}`, data, {
        headers,
      });
    } else if (type === "DELETE") {
      response = await axios.delete(`${microservice}/${url}`, {
        headers,
      });
    }

    return {
      error: false,
      logout: false,
      message: response.data.message,
      response: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        return {
          error: true,
          logout: true,
          message: "You have been logged out. Please login again.",
          status: error.response.status,
        };
      }
      return {
        error: true,
        logout: false,
        message:
          error.response.data.message ||
          "Something went wrong. Please try again.",
        status: error.response.status,
        code: error.response.data.code,
        response: error.response,
      };
    }

    if (error.message && error.message.includes("Network Error")) {
      return {
        error: true,
        logout: false,
        message:
          "You are not connected to the internet. Please connect and try again.",
        status: 400,
      };
    }

    return {
      error: true,
      logout: false,
      message: `Something went wrong. Please try again.\n #${CLIENT_ERROR_CODE}`,
      errorCode: CLIENT_ERROR_CODE,
      status: 400,
    };
  }
};

export default makeApiRequest;
