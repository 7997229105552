import React, { PureComponent } from "react";
import { connect } from "react-redux";
import LiveClassAudioPlayer from "./LiveClassAudioPlayer";

class LiveClassAudios extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.volumeLevel !== this.props.volumeLevel) {
      let audioComponentList = document.getElementsByClassName(
        "participant__audio__component"
      );
      for (let i = 0; i < audioComponentList.length; i++) {
        audioComponentList.item(i).volume = this.props.volumeLevel;
      }
    }
  }

  render() {
    const { allParticipants } = this.props;

    return (
      <>
        {allParticipants
          .filter((participant) => !participant.audioMuted)
          .map((participant) => (
            <LiveClassAudioPlayer
              key={participant.participantId}
              audioTrack={participant.audioTrack.track}
            />
          ))}
      </>
    );
  }
}

const mapStateToProps = ({ jitsi }) => ({
  allParticipants: jitsi.allParticipants,
  volumeLevel: jitsi.volumeLevel,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LiveClassAudios);
