import { SET_LIVE_CLASS_TIME_ELAPSED, SET_NOTIFICATION_HEADER } from "../types";

export default (state = {}, action) => {
  if (
    action.type !== SET_LIVE_CLASS_TIME_ELAPSED &&
    action.type !== SET_NOTIFICATION_HEADER
  ) {
    global.reduxLog.push(action);
  }
  // retains only last 30 redux actions
  if (global.reduxLog.length > 30) {
    global.reduxLog.splice(0, global.reduxLog.length - 30);
  }

  return state;
};
