import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";
import {
  SET_APPLICATION_SETTINGS,
  SET_INSTITUTE_LIST,
  SET_SHOW_LICENSE_DETAILS,
  SET_SHOW_TRIAL_DETAILS,
  SET_SHOW_TRIAL_EXPIRED,
} from "../types";
import { errorAlert, logoutProcedure } from "./utils";

export const getApplicationSettings = () => async (dispatch, getState) => {
  const apiResponse = await makeApiRequest(
    "GET",
    backendApi,
    "application-settings/load",
    false,
    {}
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return;
  }

  dispatch({
    type: SET_APPLICATION_SETTINGS,
    payload: apiResponse.response?.application_settings,
  });
};

export const setShowTrialExpiredPopup = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_TRIAL_EXPIRED,
    payload: data,
  });
};

export const setShowTrialDetailsPopup = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_TRIAL_DETAILS,
    payload: data,
  });
};

export const setInstitutionList = (data) => (dispatch) => {
  dispatch({
    type: SET_INSTITUTE_LIST,
    payload: data,
  });
};
