import React, { Component } from "react";
import "./SelectedUserChipsStyle.css";
import { ReactComponent as CloseIcon } from "../../components/Live-class-tabs/assets/deny-icon.svg";

export default class SelectedUserChips extends Component {
  render() {
    const {
      isCorrect,
      value,
      id,
      title,
      isUserTeacherAdmin,
      isAddedTeacherAdmin,
    } = this.props;
    const selectedCorrectClassName = isCorrect
      ? "selected__correct"
      : "selected__incorrect";
    return (
      <div
        className={`selected__user__chip__container noselect ${selectedCorrectClassName}`}
        title={title}
      >
        <div className="selected__user__name">{value}</div>
        {(isUserTeacherAdmin ||
          (!isUserTeacherAdmin && !isAddedTeacherAdmin)) && (
          <div
            className="delete__icon__container"
            onClick={() => this.props.onChipDelete(id)}
          >
            <CloseIcon height={16} width={17} />
          </div>
        )}
      </div>
    );
  }
}
