import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { cleanupMediaPlayerRef } from "../../utils/misc";

class VideoPreview extends PureComponent {
  handleVideoTrackRef = (ref, track) => {
    if (ref) {
      this.videoTrackRef = ref;
      ref.srcObject = track.getOriginalStream();
    }
  };

  componentWillUnmount() {
    cleanupMediaPlayerRef(this.videoTrackRef);
  }

  render() {
    const { myVideoTrack, myVideoPreviewTrack, trackType } = this.props;
    const { objectFit = "contain" } = this.props;

    let videoTrack = myVideoTrack;
    if (trackType === "video-preview") {
      videoTrack = myVideoPreviewTrack;
    }

    if (videoTrack) {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <video
            height="100%"
            width="100%"
            className="video-track"
            ref={(ref) => this.handleVideoTrackRef(ref, videoTrack)}
            autoPlay
            playsInline
            style={{ objectFit, borderRadius: "10px" }}
          />
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ jitsi }) => ({
  myVideoTrack: jitsi.myVideoTrack,
  myVideoPreviewTrack: jitsi.myVideoPreviewTrack,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPreview);
