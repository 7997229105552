import React from "react";
import { connect } from "react-redux";

import CollapsibleElement from "./CollapsibleElement";
import { PERSONAL_SCREEN_CONTEXT } from "../../utils/constants";
import {
  sortArrayByDateDescending,
  sortArrayByAlphabeticalOrder,
} from "../../store/actions/utils";
import { filesKindType } from "../../utils/tinyUtils";
import {
  filterUserViewableFilesAndFolder,
  isFolder,
  isSystemFile,
} from "../../utils/fileSystem";
import { withRouter } from "react-router";
import "./Style.css";
const TreeComponent = (props) => {
  const [isMyFileVisible, setIsMyFileVisible] = React.useState(true);
  const moveorcopy = props.moveorcopy || false;
  React.useEffect(() => {
    setIsMyFileVisible(props.currentScreenContext === PERSONAL_SCREEN_CONTEXT);
    if (props.activeTab === 2 && props.showMoveAndCopyFilePopup) {
      setIsMyFileVisible(true);
    } else if (props.activeTab === 1 && props.showMoveAndCopyFilePopup) {
      setIsMyFileVisible(false);
    }
  }, [props.currentScreenContext]);

  let myFilesTreeList = [];
  myFilesTreeList.push(props.fileSystemData[props.folder_id]);

  const treeFileList = filterUserViewableFilesAndFolder(myFilesTreeList, [
    isSystemFile,
  ]);

  return (
    <div
      className="tree__content__container"
      style={
        moveorcopy
          ? { backgroundColor: "#00000094" }
          : { backgroundColor: "var(--white)" }
      }
    >
      {/* Top Toggle */}
      <div
        style={{
          height: "32px",
          display: "flex",
          justifyContent: "center",
          visibility: `${moveorcopy ? "hidden" : "visible"}`,
          marginTop: `${moveorcopy ? "-50px" : ""}`,
        }}
      >
        {/* Buttons */}
        <div className="tree__content__header__container">
          {/* remove the below div when shared section is enabled */}

          <div className="tree__content__header">
            <div
              className={
                props.location.pathname === "/class-files" ||
                props.location.pathname === "/student/class-files"
                  ? "active__tab"
                  : "non__active__tab"
              }
            >
              Class Files
            </div>
            <div
              className={
                props.location.pathname === "/my-files" ||
                props.location.pathname === "/student/my-files"
                  ? "active__tab"
                  : "non__active__tab"
              }
            >
              {" "}
              My Files
            </div>
          </div>

          {/* uncomment when shared section is enabled */}
          {/* <div
            style={{
              height: "32px",
              width: "90px",
              backgroundColor: isMyFileVisible ? "#FB963C" : "#FFFFFF",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "GibsonSemiBold",
              fontSize: "12px",
              color: isMyFileVisible ? "#FFFFFF" : "#2A2E43",
              zIndex: isMyFileVisible ? 10 : 0,
            }}
            onClick={() => setIsMyFileVisible(true)}
          >
            MY FILES
          </div>
          <div
            style={{
              marginLeft: "-16px",
              height: "32px",
              width: "90px",
              backgroundColor: isMyFileVisible ? "#FFFFFF" : "#FB963C",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "GibsonSemiBold",
              fontSize: "12px",
              color: isMyFileVisible ? "#2A2E43" : "#FFFFFF",
              zIndex: isMyFileVisible ? 0 : 10,
            }}
            onClick={() => setIsMyFileVisible(false)}
          >
            SHARED
          </div> */}
        </div>
      </div>
      {/* Browse Area */}
      <div
        className="box__shadow__container"
        style={
          !moveorcopy
            ? { boxShadow: "var(--border-box-shadow)" }
            : { boxShadow: "none" }
        }
      >
        {isMyFileVisible
          ? sortArrayByAlphabeticalOrder(treeFileList).map(
              (folderObj, index) => {
                const folder_id = folderObj.id;
                return (
                  <CollapsibleElement
                    dontSetCurrentFolder={props.dontSetCurrentFolder}
                    element={folderObj}
                    folderId={folder_id}
                    {...props}
                    key={index}
                  />
                );
              }
            )
          : sortArrayByAlphabeticalOrder(
              Object.values(props.classes).filter((cls) =>
                props.role === "teacher"
                  ? cls.status === "active"
                  : cls.user_class_status === "active" &&
                    cls.status === "active"
              )
            ).map((classObj, index) => {
              const classId = classObj.id;
              return (
                <CollapsibleElement
                  // element={classObj}
                  dontSetCurrentFolder={props.dontSetCurrentFolder}
                  folderId={props.classes[classId].folder_id}
                  {...props}
                  key={index}
                />
              );
            })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, fileSystem, klass, fileOptions }) => ({
  folder_id: user.folder_id,
  fileSystemData: fileSystem.fileSystemData,
  classes: klass.classes,
  showMoveAndCopyFilePopup: fileOptions.showMoveAndCopyFilePopup,
  currentScreenContext: fileSystem.currentScreenContext,
  role: user.role,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreeComponent)
);
