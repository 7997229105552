import { isNil } from "lodash";
import { matchPath } from "react-router-dom";
import { getQueryParams } from "../../utils/misc";
import { screenNames } from "../AppRouter/routes";

const getInstantLiveClassParams = () => {
  const params = getQueryParams();
  const isHostTeacher = params.h === "t";
  const lectureId = params.lectureId;
  const email = params.email;
  return { isHostTeacher, email, lectureId };
};

const isCurrentPathBelongsToInstantLiveClass = () => {
  const matchResponse = matchPath(window.location?.pathname, {
    path: [screenNames.instantLiveClass, screenNames.studentInstantLecture],
    exact: true,
    strict: true,
  });

  return !isNil(matchResponse) && matchResponse.isExact;
};

export default function instantLectureUtils() {
  return {
    is: function () {
      return {
        currentPageBelongsToInstantClassroom: function () {
          return isCurrentPathBelongsToInstantLiveClass();
        },
      };
    },
    getParams: () => getInstantLiveClassParams(),
  };
}
