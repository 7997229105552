import produce from "immer";
import {
  SET_LECTURE_STARTUP_DETAILS,
  ADD_LECTURE_FILE_LIST,
  SET_LECTURE_FILE_LIST,
  SET_SLIDE_IMAGE_LIST,
  SET_CURRENT_SLIDE_INDEX,
  SET_CURRENT_LECTURE,
  SET_CURRENT_LECTURE_OBJECT,
  ADD_LECTURE,
  UPDATE_LECTURE,
  SET_LECTURE_CHATS,
  SET_UPLOAD_LECTURE_DATA,
  SET_TEMPORARY_LECTURE_OBJECT,
  SET_SUBMISSIONS,
  CLEAR_UPLOAD_LECTURE_DATA,
  CLEAR_LECTURE_DETAILS,
  ADD_SLIDE_IMAGE_LIST,
  SET_IMAGE_LOADING_STATUS,
  DELETE_CHAT_MESSAGE,
  UPDATE_LECTURE_ATTENDANCE,
  SET_LECTURE_WHITEBOARD_ARCHIVE,
  SET_CALENDAR_DATE,
  SET_USERS_OF_LECTURE,
  SET_LIVE_CLASS_SELECTED_FILE_PARAMS,
  SET_ALL_LECTURES,
  SET_REFERENCE_FILES_UPLOAD_LECTURE,
} from "../types";
import { IMAGE_LOADING_STATUS } from "../../utils/constants";

const initialState = {
  lectureFileList: [],
  slideImageList: [],
  currentSlideIndex: 0,
  recommendations: [],
  lecture: {},
  attendance: {},
  currentLecture: null,
  temporaryLectureObject: {},
  chatMessages: [],
  uploadLectureData: {},
  submissions: {},
  imageLoadingStatus: IMAGE_LOADING_STATUS.DONE,
  whiteboardArchive: null,
  calendarActiveDateRange: { start: null, end: null },
  usersOfLecture: {},
  liveClassSelectedFileParams: { id: null, loading: false },
  allLectures: [],
};

export default produce((state, action) => {
  switch (action.type) {
    case SET_LECTURE_STARTUP_DETAILS: {
      const { recommendations, lecture, attendance } = action.payload;
      // console.log({ lectureReducer: lecture, attendance, recommendations });
      return {
        ...state,
        recommendations,
        lecture,
        attendance,
      };
    }
    case UPDATE_LECTURE_ATTENDANCE: {
      const attendance = action.payload;
      Object.assign(state.attendance, attendance);
      return;
    }
    case ADD_LECTURE_FILE_LIST: {
      return {
        ...state,
        lectureFileList: [...state.lectureFileList, ...action.payload],
      };
    }
    case SET_LECTURE_FILE_LIST: {
      return {
        ...state,
        lectureFileList: action.payload,
      };
    }
    case SET_SLIDE_IMAGE_LIST: {
      return {
        ...state,
        slideImageList: action.payload,
      };
    }
    case ADD_SLIDE_IMAGE_LIST: {
      return {
        ...state,
        slideImageList: state.slideImageList.concat(action.payload),
      };
    }
    case SET_CURRENT_SLIDE_INDEX: {
      return {
        ...state,
        currentSlideIndex: action.payload,
      };
    }
    case SET_IMAGE_LOADING_STATUS: {
      return {
        ...state,
        imageLoadingStatus: action.payload,
      };
    }
    case SET_CURRENT_LECTURE: {
      return {
        ...state,
        currentLecture: action.payload,
      };
    }
    case SET_CURRENT_LECTURE_OBJECT: {
      return {
        ...state,
        lecture: action.payload,
      };
    }
    case SET_TEMPORARY_LECTURE_OBJECT: {
      return {
        ...state,
        temporaryLectureObject: action.payload,
      };
    }

    case ADD_LECTURE: {
      const { lecture } = action.payload;
      return {
        ...state,
        lecture: lecture,
      };
    }

    case SET_UPLOAD_LECTURE_DATA: {
      state.uploadLectureData = Object.assign(
        state.uploadLectureData,
        action.payload
      );
      return;
    }

    case CLEAR_UPLOAD_LECTURE_DATA: {
      return {
        ...state,
        uploadLectureData: {},
        temporaryLectureObject: {},
      };
    }

    case UPDATE_LECTURE: {
      const { lecture } = action.payload;
      return {
        ...state,
        lecture: lecture,
      };
    }
    case SET_LECTURE_CHATS: {
      return {
        ...state,
        chatMessages: action.payload,
      };
    }
    case DELETE_CHAT_MESSAGE: {
      const messageId = action.payload;
      const index = state.chatMessages.findIndex(
        (message) => message.id === messageId
      );
      if (index !== -1) {
        state.chatMessages.splice(index, 1);
      }
      return;
    }
    case SET_SUBMISSIONS: {
      const submissions = {};
      action.payload.forEach((submission) => {
        if (submissions[submission.user_id]) {
          submissions[submission.user_id].push(submission.layers);
        } else {
          submissions[submission.user_id] = [submission.layers];
        }
      });
      return {
        ...state,
        submissions,
      };
    }
    case CLEAR_LECTURE_DETAILS: {
      return {
        ...initialState,
      };
    }

    case SET_LECTURE_WHITEBOARD_ARCHIVE: {
      return {
        ...state,
        whiteboardArchive: action.payload,
      };
    }
    case SET_CALENDAR_DATE: {
      return {
        ...state,
        calendarActiveDateRange: action.payload,
      };
    }
    case SET_USERS_OF_LECTURE: {
      return {
        ...state,
        usersOfLecture: action.payload,
      };
    }
    case SET_LIVE_CLASS_SELECTED_FILE_PARAMS: {
      return {
        ...state,
        liveClassSelectedFileParams: action.payload,
      };
    }
    case SET_LIVE_CLASS_SELECTED_FILE_PARAMS: {
      return {
        ...state,
        liveClassSelectedFileParams: action.payload,
      };
    }
    case SET_ALL_LECTURES: {
      return { ...state, allLectures: action.payload };
    }
    case SET_REFERENCE_FILES_UPLOAD_LECTURE: {
      return {
        ...state,
        temporaryLectureObject: {
          ...state.temporaryLectureObject,
          reference_files: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}, initialState);
