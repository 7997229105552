import produce from "immer";
import {
  SET_CLASSES_OF_BATCH,
  SET_LECTURES_OF_BATCH,
  SET_SELECTED_BATCH,
  SET_ADMIN_BATCH_LIST,
  UPDATE_BATCH_LIVE_CLASS_STATUS,
  SET_BATCH_LIVE_LECTURE,
  ADD_BATCH_CLASS,
  REMOVE_BATCH_CLASS,
} from "../types";

/**
 * @typedef {object} state
 * @property {{[batchId:number]: Array<Object<string, any>>}} batchLectures list of lectures mapped to their corresponding batchId
 * @property {{[batchId:number]: {[classId:number]: Object<string, any>}}} batchClasses classes mapped to their corresponding batchId
 * @property {Array<{batch: Object<string, any>, no_of_classes: number, no_of_students: number, no_of_teachers: number}>} batchList classes mapped to their corresponding batchId
 */

/**
 * @type {state}
 */
const initialState = {
  batchLectures: {},
  batchClasses: {},
  selectedBatch: {
    name: "",
    id: null,
  },
  batchList: [],
  classBatches: {},
};

const batchReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_LECTURES_OF_BATCH: {
      const { batchId, lectureList } = action.payload;
      state.batchLectures[batchId] = lectureList;
      break;
    }
    case SET_CLASSES_OF_BATCH: {
      const { batchId, classList } = action.payload;
      const batchClasses = {};
      classList.forEach((classItem) => {
        batchClasses[classItem.id] = classItem;
      });
      state.batchClasses[batchId] = batchClasses;
      break;
    }
    case SET_SELECTED_BATCH: {
      state.selectedBatch = action.payload;
      break;
    }
    case SET_ADMIN_BATCH_LIST: {
      const { batchList } = action.payload;
      state.batchList = batchList;
      break;
    }
    // Batch Classes
    case UPDATE_BATCH_LIVE_CLASS_STATUS: {
      const { classId, live, joinedBefore, liveLecture, batchId } =
        action.payload;
      if (state.batchClasses[batchId][classId]) {
        state.batchClasses[batchId][classId].class_is_live = live;
        if (joinedBefore !== undefined) {
          state.batchClasses[batchId][classId].joined_before = joinedBefore;
        }
        if (liveLecture) {
          state.batchClasses[batchId][classId].live_lecture = liveLecture;
        }
      }
      return;
    }
    case SET_BATCH_LIVE_LECTURE: {
      const lecture = action.payload;
      state.batchLectures[lecture.id] = lecture;
      if (state.batchClasses[lecture.class_id]) {
        state.batchClasses[lecture.class_id].live_lecture_id = lecture.id;
        state.batchClasses[lecture.class_id].live_lecture = lecture;
      }
      return;
    }

    case ADD_BATCH_CLASS: {
      const { klass, batchId } = action.payload;
      state.batchClasses[batchId][klass.id] = klass;
      state.batchClasses[batchId][klass.id] = (klass.teachers || []).reduce(
        (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
        {}
      );
      return;
    }
    case REMOVE_BATCH_CLASS: {
      const { classId, batchId } = action.payload;
      delete state.batchClasses[batchId][classId];
      return;
    }
    default:
      return state;
  }
}, initialState);

export default batchReducer;
