import produce from "immer";
import {
  SET_FILE_SYSTEM_DATA,
  SET_CURRENT_FOLDER_ID,
  SET_TREE_SELECTED_FOLDER_ID,
  SET_LOADER,
  SET_CLASS_BROWSER_VISIBILITY,
  SET_ADD_FOLDER_VISIBILITY,
  SET_SCREEN_CONTEXT,
  SET_CLASS_STUDENT_DATA_MAP,
  SET_IS_FILE_SYSTEM_DATA_LOADED,
  SET_FS_RECENT_FILE_DATE_LIST,
  SET_FS_FOLDER_TO_CLASS_ID_MAP,
  SET_FS_TOP_TRASH_ELEMENT_ID_LIST,
  SET_FS_UPLOAD_PROGRESS_PERCENT,
  CLEAR_FILE_SYSTEM_DETAILS,
  SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT,
  ADD_FS_MULTIPLE_UPLOAD_FILES,
  SET_FS_MULTIPLE_INCREMENT_COUNTER,
  SET_FS_MULTIPLE_UPLOAD_COMPLETE,
  RESET_FS_MULTIPLE_UPLOAD,
  SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC,
  REMOVE_FS_MULTIPLE_UPLOAD_FILES,
  ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  ADD_FILE_SYSTEM_DATA,
  SET_TRASH_STATUS,
  SET_CURRENT_FILE_ID,
  SET_CURRENT_FOLDER_CHILDREN,
  SET_CURRENT_FOLDER_PARENT,
  SET_TRASH_ELEMENTS_MAP,
  SET_USER_FOLDER_CHILDREN,
  SET_LIBRARY_FILESYSTEM_IDS,
  REMOVE_FOLDER_CHILDREN,
  RENAME_FILE_FOLDER,
  RENAME_FILE_FOLDER_CHILDREN,
  SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID,
  SET_FOLDER_SHARE,
  SET_FS_STARRED_FILES_LIST,
  SET_FS_SHARED_WITH_USER_FILE_LIST,
  STAR_ELEMENT,
  STAR_RECENT_ELEMENT,
  STAR_STARRED_ELEMENT,
  STAR_SHARED_ELEMENT,
  GET_RECENT_USERS,
  REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA,
  REMOVE_FROM_RECENT,
  REMOVE_FROM_STARRED,
  REMOVE_FROM_SHARED,
  SET_ELEMENT_SHARE,
} from "../types";
import { SHARED_SCREEN_CONTEXT } from "../../utils/constants";
import { element } from "prop-types";
import { convertToMap } from "../../utils/objects";

const initialState = {
  fileSystemData: {},
  currentFolderId: null,
  treeSelectedFolderId: null,
  loader: {
    active: false,
    progress: 0,
    message: "",
  },
  uploadProgress: 0,
  currentScreenContext: SHARED_SCREEN_CONTEXT,
  classStudentDataMap: {},
  isDataLoaded: false,
  recentFileList: {},
  starredFilesList: {},
  sharedWithUserFileList: {},
  currentClassId: undefined,
  folderToClassIdMap: {},
  topTrashElementIdList: [],
  multipleUploadProgress: { incrementCounter: 0, files: {} },
  multipleUploadedIdFileInfoMap: {},
  currentFileId: -1,
  currentFolderChildren: {},
  parentFolderChildren: {},
  trashElementsMap: {},
  userFolderChildren: {},
  classBrowserVisibility: true,
  addFolderVisibility: false,
  library: {
    fileSystem: {
      currentClassId: null,
      currentLectureId: null,
    },
  },
  recentUserList: [],
};

export default produce((state, action) => {
  switch (action.type) {
    case SET_FILE_SYSTEM_DATA: {
      if (Object.keys(action.payload).length === 0) {
        return;
      }

      state.fileSystemData = action.payload;
      return;
    }

    case ADD_FILE_SYSTEM_DATA: {
      const { data, mergeExisting } = action.payload;
      Object.values(data).forEach((element) => {
        state.fileSystemData[element.id] = {
          ...(state.fileSystemData[element.id] || {}),
          ...element,
        };
      });
      const parentChildIdsMap = Object.values(data).reduce((acc, element) => {
        if (element.parent_id) {
          acc[element.parent_id] = acc[element.parent_id] || [];
          acc[element.parent_id].push(element.id);
        }
        return acc;
      }, {});

      Object.keys(parentChildIdsMap).forEach((parentId) => {
        if (state.fileSystemData[parentId]) {
          const currentChildList =
            state.fileSystemData[parentId].children || [];
          const newChildList = parentChildIdsMap[parentId];
          if (mergeExisting) {
            state.fileSystemData[parentId].children = Array.from(
              new Set([...currentChildList, ...newChildList])
            );
          } else {
            state.fileSystemData[parentId].children = newChildList;
          }
        }
      });
      return;
    }
    case REMOVE_FOLDER_CHILDREN: {
      const { folderId, childrenIds } = action.payload;
      const toDeleteChildrenIds = new Set(childrenIds);
      state.fileSystemData[folderId].children = (
        state.fileSystemData[folderId].children || []
      ).filter((childId) => !toDeleteChildrenIds.has(childId));
      return;
    }

    case RENAME_FILE_FOLDER: {
      const { newName, elementId } = action.payload;

      console.log("old name:", action.payload);
      state.fileSystemData[elementId].name = newName;
      return;
    }

    case RENAME_FILE_FOLDER_CHILDREN: {
      const { newName, elementId } = action.payload;
      if (state.currentFolderChildren[elementId])
        state.currentFolderChildren[elementId].name = newName;
      return;
    }

    case SET_CURRENT_FOLDER_ID: {
      state.currentFolderId = action.payload;
      return;
    }

    case SET_TREE_SELECTED_FOLDER_ID: {
      state.treeSelectedFolderId = action.payload;
      return;
    }

    case SET_LOADER: {
      state.loader = { ...state.loader, ...action.payload };
      return;
    }

    case SET_CLASS_BROWSER_VISIBILITY: {
      state.classBrowserVisibility = action.payload;
      return;
    }

    case SET_ADD_FOLDER_VISIBILITY: {
      state.addFolderVisibility = action.payload;
      return;
    }

    case SET_SCREEN_CONTEXT: {
      state.currentScreenContext = action.payload;
      return;
    }

    case SET_CLASS_STUDENT_DATA_MAP: {
      state.classStudentDataMap = action.payload;
      return;
    }

    case SET_IS_FILE_SYSTEM_DATA_LOADED: {
      state.isDataLoaded = action.payload;
      return;
    }

    case SET_FS_UPLOAD_PROGRESS_PERCENT: {
      state.uploadProgress = action.payload;
      return;
    }

    case SET_FS_RECENT_FILE_DATE_LIST: {
      state.recentFileList = convertToMap(action.payload);
      return;
    }

    case SET_FS_SHARED_WITH_USER_FILE_LIST: {
      state.sharedWithUserFileList = convertToMap(action.payload);
      return;
    }

    case SET_FS_STARRED_FILES_LIST: {
      state.starredFilesList = convertToMap(action.payload);
      return;
    }

    case SET_FS_FOLDER_TO_CLASS_ID_MAP: {
      state.folderToClassIdMap = action.payload;
      return;
    }

    case SET_FS_TOP_TRASH_ELEMENT_ID_LIST: {
      state.topTrashElementIdList = action.payload;
      return;
    }

    case ADD_FS_MULTIPLE_UPLOAD_FILES: {
      state.multipleUploadProgress.files = {
        ...state.multipleUploadProgress.files,
        ...action.payload,
      };
      return;
    }

    case REMOVE_FS_MULTIPLE_UPLOAD_FILES: {
      delete state.multipleUploadProgress.files[action.payload];
      return;
    }

    case SET_FS_MULTIPLE_INCREMENT_COUNTER: {
      state.multipleUploadProgress.incrementCounter = action.payload;
      return;
    }

    case SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT: {
      state.multipleUploadProgress.files[
        action.payload.id
      ].uploadProgressPercent = action.payload.value;
      return;
    }

    case SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC: {
      state.multipleUploadProgress.files[action.payload.id].cancelFunction =
        action.payload.value;
      return;
    }

    case SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID: {
      const { id, elementId } = action.payload;
      state.multipleUploadProgress.files[id].elementId = elementId;
      return;
    }

    case SET_FS_MULTIPLE_UPLOAD_COMPLETE: {
      state.multipleUploadProgress.files[action.payload.id].isUploaded =
        action.payload.value;
      return;
    }

    case RESET_FS_MULTIPLE_UPLOAD: {
      state.multipleUploadProgress = initialState.multipleUploadProgress;
      return;
    }

    case ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED: {
      state.multipleUploadedIdFileInfoMap = {
        ...state.multipleUploadedIdFileInfoMap,
        ...action.payload,
      };
      return;
    }

    case REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED: {
      delete state.multipleUploadedIdFileInfoMap[action.payload];
      return;
    }

    case RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED: {
      state.multipleUploadedIdFileInfoMap =
        initialState.multipleUploadedIdFileInfoMap;
      return;
    }

    case SET_TRASH_STATUS: {
      if (state.fileSystemData[action.payload.id])
        state.fileSystemData[action.payload.id].trash =
          action.payload.trashValue;
      return;
    }

    case SET_CURRENT_FILE_ID: {
      state.currentFileId = action.payload;
      return;
    }

    case SET_CURRENT_FOLDER_CHILDREN: {
      state.currentFolderChildren = action.payload;
      return;
    }

    case SET_CURRENT_FOLDER_PARENT: {
      state.parentFolderChildren = action.payload;
      return;
    }

    case SET_TRASH_ELEMENTS_MAP: {
      state.trashElementsMap = action.payload;
      return;
    }

    case SET_USER_FOLDER_CHILDREN: {
      state.userFolderChildren = action.payload;
      return;
    }

    case SET_LIBRARY_FILESYSTEM_IDS: {
      Object.keys(action.payload).forEach((key) => {
        state.library.fileSystem[key] = action.payload[key];
      });
      return;
    }

    case CLEAR_FILE_SYSTEM_DETAILS: {
      state = initialState;
      return;
    }
    case SET_FOLDER_SHARE: {
      const { isShare, folderIds, listOfShareUserList } = action.payload;
      folderIds.forEach((folderId) => {
        state.fileSystemData[folderId].shared = isShare;
        state.fileSystemData[folderId].shared_with = listOfShareUserList;
      });
      return;
    }

    case SET_ELEMENT_SHARE: {
      const { elementId, isShare } = action.payload;
      if (state.currentFolderChildren[elementId]) {
        state.currentFolderChildren[elementId].shared = isShare;
      }
    }
    case STAR_ELEMENT: {
      const { classId, elementId, starred } = action.payload;
      state.fileSystemData[elementId].starred = starred;
      state.starredFilesList[elementId] = state.fileSystemData[elementId];
      return;
    }
    case STAR_RECENT_ELEMENT: {
      const { classId, elementId, starred } = action.payload;
      state.recentFileList[elementId].starred = starred;
      return;
    }
    case STAR_STARRED_ELEMENT: {
      const { classId, elementId, starred } = action.payload;
      delete state.starredFilesList[elementId];
      return;
    }
    case STAR_SHARED_ELEMENT: {
      const { classId, elementId, starred } = action.payload;
      state.sharedWithUserFileList[elementId].starred = starred;
      return;
    }
    case REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA: {
      const elementId = action.payload;
      delete state.fileSystemData[elementId];
      return;
    }
    case GET_RECENT_USERS: {
      state.recentUserList = action.payload;
      return;
    }
    case REMOVE_FROM_RECENT: {
      const elementId = action.payload;
      delete state.recentFileList[elementId];
      return;
    }
    case REMOVE_FROM_STARRED: {
      const elementId = action.payload;
      delete state.starredFilesList[elementId];
      return;
    }
    case REMOVE_FROM_SHARED: {
      const elementId = action.payload;
      delete state.sharedWithUserFileList[elementId];
      return;
    }
    default:
      return state;
  }
}, initialState);
