import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { cleanupMediaPlayerRef } from "../../utils/misc";

class LiveClassAudioPlayer extends PureComponent {
  handleAudioTrackRef = (ref) => {
    if (ref) {
      this.audioTrackRef = ref;
      ref.srcObject = this.props.audioTrack?.getOriginalStream();
      ref.volume = this.props.volumeLevel;
    }
  };

  componentWillUnmount() {
    cleanupMediaPlayerRef(this.audioTrackRef);
  }

  render() {
    return (
      <div style={{ display: "none" }}>
        <audio
          ref={this.handleAudioTrackRef}
          autoPlay
          playsInline
          hidden
          className="participant__audio__component"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jitsi }) => ({
  volumeLevel: jitsi.volumeLevel,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveClassAudioPlayer);
