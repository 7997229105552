import { STAGE_ENVIRONMENT } from "./constants";

export const replaceUrl = (url, replaceUrl) => {
  return replaceText(url, replaceUrl, /\bhttps?:\/\/\S+/gi);
};

export const replaceText = (text, replaceText, regex) => {
  if (!text || !replaceText || !regex) return text;
  return text.replace(regex, `${replaceText}`);
};

export const getOriginFromUrl = (url) => {
  // Remove everything after port number using regex
  const origin = url.replace(/(https?:\/\/[^:]+):\d+.*/, "$1");
  if (Object.keys(STAGE_ENVIRONMENT).includes(origin)) {
    return STAGE_ENVIRONMENT[origin];
  }
  return origin;
};
