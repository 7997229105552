import {
  SET_APPLICATION_SETTINGS,
  SET_SHOW_TRIAL_DETAILS,
  SET_INSTITUTE_LIST,
  SET_SHOW_TRIAL_EXPIRED,
} from "../types";

const initialState = {
  application_settings: {},
  showTrialDetails: false,
  showTrialExpired: false,
  instituteList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLICATION_SETTINGS: {
      return {
        ...state,
        application_settings: action.payload,
      };
    }
    case SET_SHOW_TRIAL_DETAILS: {
      return {
        ...state,
        showTrialDetails: action.payload,
      };
    }
    case SET_SHOW_TRIAL_EXPIRED: {
      return {
        ...state,
        showTrialExpired: action.payload,
      };
    }
    case SET_INSTITUTE_LIST: {
      return {
        ...state,
        instituteList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
