import _ from "lodash";
import * as Sentry from "@sentry/react";
import { backendApi } from "../config/constants";
import store from "../store";
import { logger } from "./logging";
import makeApiRequest from "./makeApiRequest";

const getReduxLogs = () => {
  const reduxState = store.getState();

  const reduxStateCopy = _.cloneDeep(reduxState);

  delete reduxStateCopy.socket.socket;
  delete reduxStateCopy.jitsi.jitsiConnection;
  delete reduxStateCopy.jitsi.jitsiConference;
  delete reduxStateCopy.jitsi.myVideoTrack;
  delete reduxStateCopy.jitsi.myAudioTrack;
  delete reduxStateCopy.jitsi.myDesktopTrack;
  delete reduxStateCopy.jitsi.participants;
  delete reduxStateCopy.jitsi.allParticipants;
  delete reduxStateCopy.jitsi.teacherParticipants;
  delete reduxStateCopy.jitsi.studentParticipants;
  delete reduxStateCopy.jitsi.sharedParticipantVideoTrack;
  delete reduxStateCopy.jitsi.currentTeacherParticipant;
  delete reduxStateCopy.jitsi.myVideoPreviewTrack;
  delete reduxStateCopy.jitsi.myAudioPreviewTrack;

  const reduxActionLog = [];

  global.reduxLog.forEach((action) => {
    try {
      JSON.stringify(action.payload);
      reduxActionLog.push({ type: action.type, payload: action.payload });
    } catch (e) {
      reduxActionLog.push({ type: action.type });
    }
  });

  return {
    reduxFinalState: reduxStateCopy,
    reduxActionLog,
  };
};

export const postErrorToServer = async (error, errorInfo) => {
  const { reduxFinalState, reduxActionLog } = getReduxLogs();
  Sentry.captureException(error);
  logger.error("SITE_CRASH", error?.message, {
    currentHref: window.location.href,
    errorStack: error?.stack,
    errorInfoComponentStack: errorInfo?.componentStack,
    reduxFinalState,
    reduxActionLog,
  });

  await makeApiRequest("POST", backendApi, "error/record", false, {
    errorMessage: error?.message,
    currentHref: window.location.href,
    errorStack: error?.stack,
    errorInfoComponentStack: errorInfo?.componentStack,
    reduxFinalState,
    reduxActionLog,
  });
};

export const getReduxLogFiles = () => {
  const { reduxFinalState, reduxActionLog } = getReduxLogs();

  let reduxFinalStateFile, reduxActionsLogFile;

  try {
    const reduxFinalStateBlob = new Blob([JSON.stringify(reduxFinalState)], {
      type: "text/plain",
    });

    const reduxActionsLogBlob = new Blob([JSON.stringify(reduxActionLog)], {
      type: "text/plain",
    });

    reduxFinalStateFile = new File(
      [reduxFinalStateBlob],
      "redux_final_state.txt"
    );

    reduxActionsLogFile = new File(
      [reduxActionsLogBlob],
      "redux_actions_log.txt"
    );
  } catch (e) {
    console.log(e);
    postErrorToServer(e);
  }

  return {
    reduxFinalStateFile,
    reduxActionsLogFile,
  };
};
