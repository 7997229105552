import React from "react";
import { useLocation } from "react-router-dom";

import style from "./IframeRenderer.module.css";

const styles = `
    html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
`;

const IframeRenderer = () => {
  let query = useQuery();

  const url = decodeURIComponent(query.get("link")) || null;
  const scaledBool = Boolean(Number(query.get("scaled") || 0));
  const absoluteBool = Boolean(Number(query.get("absolute") || 0));
  const noScrollBool = Boolean(Number(query.get("noScroll") || 0));
  const overrideScrollBool = Boolean(Number(query.get("overrideScroll") || 0));

  if (overrideScrollBool) InjectCss();

  if (!url) {
    return (
      <div className={style.errorMessage}>
        Something went wrong. Try Again !
      </div>
    );
  }

  return (
    <iframe
      title="linkclassroom-miro"
      allow="fullscreen; clipboard-read; clipboard-write"
      allowfullscreen
      frameborder="0"
      className={[
        style.iframContainer,
        scaledBool ? style.scaledFrame : style.fullWidthFrame,
        absoluteBool && style.frameAbsolute,
        noScrollBool && style.frameNoScroll,
      ].join(" ")}
      src={url}
    ></iframe>
  );
};

export default IframeRenderer;

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const InjectCss = () => {
  return React.useMemo(() => {
    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);
};
