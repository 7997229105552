import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";
import { v4 as uuid } from "uuid";
import {
  ADD_EVENT,
  ADD_LECTURE,
  SET_CLASS_LECTURES,
  SET_CLASS_LIST,
  SET_CLASS_STARTUP_DETAILS,
  SET_TIMETABLE,
  UPDATE_LECTURE,
  SET_CURRENT_CLASS,
  SET_TIMETABLE_WEEK,
  SET_TIMETABLE_MONTH,
  SET_TIMETABLE_LECTURE,
  REMOVE_TIMETABLE_LECTURE,
  ADD_STUDENT_SUBMISSION,
  SET_APP_MICROSERVICES,
  SET_LIVE_LECTURE,
  SET_JITSI_CONFERENCE_DETAILS,
  ADD_CLASS,
  SET_PENDING_USERS,
  SET_INVITED_USERS,
  REMOVE_PENDING_REQUEST,
  ADD_INVITED_USERS,
  SET_REMINDERS,
  UPDATE_CLASS,
  REMOVE_INVITED_USERS,
  DELETE_CLASS_INVITES,
  UPDATE_CLASS_SETTINGS,
  SET_AUTO_SUGGESTION_LIST_TEACHER,
  SET_AUTO_SUGGESTION_LIST_STUDENT,
  SET_LIVE_CLASS_SETTINGS,
  REMOVE_CLASS,
  ADD_CLASS_INVITATION,
  SET_CLASS_STUDENTS_COUNT,
  SET_CLASS_LIST_LOADED,
  UPDATE_USER_DETAILS,
  SET_TEACHER_REMOVED_FROM_CLASS,
  SET_CURRENT_WEEK_REMINDERS,
  SET_LIVECLASS_LECTURE_ID,
  SET_LIVE_STATUSES_OF_CLASSES,
  TOGGLE_QUICK_ACTIONS_POPUP,
  SET_CLASS_BATCHES,
} from "../types";
import { getSocket } from "../../utils/socketio";
import {
  customAlert,
  errorAlert,
  getSessionId,
  logoutProcedure,
} from "./utils";
import { groupByDate } from "../../utils/time";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { addLibraryData } from "./fileSystemActions";
import { removeRequest } from "./liveClassActions";
import responseCodes from "../../utils/responseCodes";
import { CLIENT_ERROR_CODE, LOCALHOST } from "../../utils/constants";
import { CoursePlannerAddLectureAPi } from "./planningActions";
import { getOriginFromUrl } from "../../utils/stringUtils";

export const setClassList = (classes) => (dispatch) => {
  dispatch({
    type: SET_CLASS_LIST,
    payload: classes,
  });
};

export const setClassBatches = (classBatchesMap) => (dispatch) => {
  dispatch({
    type: SET_CLASS_BATCHES,
    payload: { classBatchesMap },
  });
};

export const getClassStartupDetails =
  (classId, fromLiveclass) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const { institute } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "class/startup",
      true,
      { class_id: classId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    let data = response.response;

    const institute_adminsIdMap =
      institute.instituteAdmins?.map((admin) => Number(admin.id)) || [];

    let teachers = data.teachers.map((teacher) => {
      return {
        ...teacher,
        is_admin: institute_adminsIdMap.includes(teacher.id),
      };
    });
    /** We are combining batch students & class Students in redux,
     * here we are adding an indication to denote batch students with
     * is_batch_student key.
     */
    let batchStudents = data.batch_students.map((student) => {
      return {
        ...student,
        is_batch_student: true,
      };
    });
    data["teachers"] = teachers;
    data["batch_students"] = batchStudents;

    //console.log("getClassStartupDetailsData", data);
    // if (data.live_lecture_id) {
    //   const liveLecture = data.lectures.find(
    //     (item) => item.id === data.live_lecture_id
    //   );
    //   dispatch({ type: SET_LIVE_LECTURE, payload: liveLecture });
    // }
    if (data.live_lecture_id && fromLiveclass) {
      dispatch({
        type: SET_LIVECLASS_LECTURE_ID,
        payload: data.live_lecture_id,
      });
    }
    dispatch({
      type: SET_CLASS_LECTURES,
      payload: data.lectures,
    });
    if (!fromLiveclass) {
      // We are restricting currentClass from updating in redux if getClassStartupDetails() action is called from liveclass so that the change should not affect M2 module
      dispatch({ type: SET_CURRENT_CLASS, payload: data?.klass?.id });
    }
    dispatch({ type: SET_CLASS_STARTUP_DETAILS, payload: data });
    return data.klass;
  };

export const getLiveStatusesOfClasses =
  (role = "") =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "GET",
      backendApi,
      `user/${role}/live-statuses`,
      true,
      {},
      sessionId
    );

    if (response.logout) {
      // dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    const data = response.response;
    dispatch({
      type: SET_LIVE_STATUSES_OF_CLASSES,
      payload: data.live_statuses,
    });
  };

export const updateUserDetails = (id, details) => (dispatch) => {
  dispatch({ type: UPDATE_USER_DETAILS, payload: { id, ...details } });
};

export const addClassInvitation = (invitation) => (dispatch) => {
  dispatch({ type: ADD_CLASS_INVITATION, payload: invitation });
};

export const removeClassInvitation = (classId) => (dispatch) => {
  dispatch({ type: DELETE_CLASS_INVITES, payload: { classId } });
};

export const getClassLectures = (classId) => async (dispatch, getState) => {
  const { user } = getState();
  const { sessionId } = user;
  //console.log("API REQUEST MADE", classId);
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "class/lectures",
    true,
    { class_id: classId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
};

export const getConductedLectures = (classId) => async (dispatch, getState) => {
  const { user } = getState();
  const { sessionId } = user;
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "/lectures/finished/list",
    true,
    { class_id: classId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return false;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }

  return response.response;
};

export const currentDayReminders = () => {};

export const getTimeTable =
  (startDate, endDate, type) => async (dispatch, getState) => {
    let {
      user: { role },
    } = getState();

    if (role === "student") {
      dispatch(getStudentTimeTable(startDate, endDate, type));
    } else {
      dispatch(getTeacherTimeTable(startDate, endDate, type));
    }
  };
export const getTeacherTimeTable =
  (startDate, endDate, type) => async (dispatch, getState) => {
    let {
      user: { sessionId },
    } = getState();

    if (!sessionId) {
      sessionId = localStorage.getItem("sessionId");
    }

    //console.log("getClassStartupDetails", { sessionId });

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "user/teacher/timetable",
      true,
      { start_date: startDate, end_date: endDate },
      sessionId
    );

    //console.log(response.response);
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    const data = response.response;
    // console.log(data.lectures);

    const timetable = groupByDate(data.lectures, "schedule_start");
    const reminders = groupByDate(data.reminders, "schedule_start");

    if (type === "week") {
      dispatch({ type: SET_TIMETABLE_WEEK, payload: timetable });
    } else if (type === "month") {
      dispatch({ type: SET_TIMETABLE_MONTH, payload: timetable });
    } else if (type === "lecture") {
      dispatch({ type: SET_TIMETABLE_LECTURE, payload: timetable });
      dispatch({ type: SET_CURRENT_WEEK_REMINDERS, payload: reminders });
    } else {
      dispatch({ type: SET_TIMETABLE, payload: timetable });
    }
    dispatch({ type: SET_REMINDERS, payload: reminders });
  };

export const getStudentTimeTable =
  (startDate, endDate, type) => async (dispatch, getState) => {
    let {
      user: { sessionId },
    } = getState();

    if (!sessionId) {
      sessionId = localStorage.getItem("sessionId");
    }

    //console.log("getClassStartupDetails", { sessionId });

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "user/student/timetable",
      true,
      { start_date: startDate, end_date: endDate },
      sessionId
    );

    //console.log(response.response);
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    const data = response.response;
    // console.log(data.lectures);

    const timetable = groupByDate(data.lectures, "schedule_start");
    const reminders = groupByDate(data.reminders, "schedule_start");

    if (type === "week") {
      dispatch({ type: SET_TIMETABLE_WEEK, payload: timetable });
    } else if (type === "month") {
      dispatch({ type: SET_TIMETABLE_MONTH, payload: timetable });
    } else if (type === "lecture") {
      dispatch({ type: SET_TIMETABLE_LECTURE, payload: timetable });
      dispatch({ type: SET_CURRENT_WEEK_REMINDERS, payload: reminders });
    } else {
      dispatch({ type: SET_TIMETABLE, payload: timetable });
    }
    dispatch({ type: SET_REMINDERS, payload: reminders });
  };

export const removeLecture =
  (lectureId, scheduledDate, lecturesArray) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/cancel",
      true,
      { lecture_id: lectureId },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    // const timetable = groupByDate(lecturesArray, "schedule_start");

    // dispatch({
    //   type: REMOVE_TIMETABLE_LECTURE,
    //   payload: timetable,
    // });
    return response;
  };

export const cancelReminder = (reminderId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "DELETE",
    backendApi,
    `user/teacher/event/${reminderId}`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  console.log(response);
};

export const studentCancelReminder =
  (reminderId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "DELETE",
      backendApi,
      `user/student/event/${reminderId}`,
      true,
      {},
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
  };

export const updateReminder =
  (reminderId, reminderAllDay, scheduleStart, scheduleEnd, reminderTopic) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "PUT",
      backendApi,
      `user/teacher/event/${reminderId}`,
      true,
      {
        all_day: reminderAllDay,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        topic: reminderTopic,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    console.log(response);
  };

export const studentUpdateReminder =
  (reminderId, reminderAllDay, scheduleStart, scheduleEnd, reminderTopic) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "PUT",
      backendApi,
      `user/student/event/${reminderId}`,
      true,
      {
        all_day: reminderAllDay,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        topic: reminderTopic,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    console.log(response);
  };

export const createTemporaryLecture =
  (classId, scheduleEnd, scheduleStart, lectureType = "live") =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    //console.log({ classId, scheduleStart, scheduleEnd });

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/create",
      true,
      {
        class_id: classId,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        temp_lecture: true,
        lecture_type: lectureType,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    const data = response.response;

    await dispatch({ type: UPDATE_LECTURE, payload: data });

    return data.lecture;
  };

export const createLecture =
  (
    classId,
    description,
    scheduleEnd,
    scheduleStart,
    startNow,
    temp_lecture,
    topic,
    lectureType
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/create",
      true,
      {
        class_id: classId,
        description: description,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        start_now: startNow,
        temp_lecture: temp_lecture,
        ...(lectureType ? { lecture_type: lectureType } : {}),
        topic: topic,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }
    const data = response.response;
    dispatch({ type: ADD_LECTURE, payload: data });

    return response.response;
  };

export const createEvent =
  (all_day, scheduleEnd, scheduleStart, topic) =>
  async (dispatch, getState) => {
    let {
      user: { role },
    } = getState();

    if (role === "student") {
      dispatch(createStudentEvent(all_day, scheduleEnd, scheduleStart, topic));
    } else {
      dispatch(createTeacherEvent(all_day, scheduleEnd, scheduleStart, topic));
    }
  };

export const createStudentEvent =
  (all_day, scheduleEnd, scheduleStart, topic) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/student/event",
      true,
      {
        all_day: all_day,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        topic: topic,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    const data = response.response;
    dispatch({ type: ADD_EVENT, payload: data });
  };

export const createTeacherEvent =
  (all_day, scheduleEnd, scheduleStart, topic) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "user/teacher/event",
      true,
      {
        all_day: all_day,
        schedule_end: scheduleEnd,
        schedule_start: scheduleStart,
        topic: topic,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    const data = response.response;
    //Loader Required before Reminder  Popup
    dispatch({ type: ADD_EVENT, payload: data });
  };

export const removeClass = (classId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { classes },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "class/remove",
    true,
    { class_id: classId },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    if (response.errorCode === CLIENT_ERROR_CODE) {
      console.log("CLIENT_ERROR_CODE", { response });
      return;
    }
    dispatch(errorAlert(response.message));
    return;
  }

  const isSuccess = response.response.success;
  if (isSuccess) {
    const updatedClassList = Object.values(classes).filter(
      (item) => item.id !== classId
    );
    dispatch(setClassList(updatedClassList));
    return true;
  }

  return false;
};

export const removeStudentClass = (classId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { classes },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "class/student/leave",
    true,
    { class_id: classId },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  const isSuccess = response.response.success;
  if (isSuccess) {
    const updatedClassList = Object.values(classes).filter(
      (item) => item.id !== classId
    );
    dispatch(setClassList(updatedClassList));
    return true;
  }

  return false;
};

export const addClass = (klass) => (dispatch) => {
  dispatch({
    type: ADD_CLASS,
    payload: klass,
  });
};

export const getTempClassCode = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { classes },
  } = getState();
  const host =
    window.location.hostname === LOCALHOST
      ? getOriginFromUrl(process.env.REACT_APP_BACKEND_API_URL)
      : window.location.origin;

  const response = await makeApiRequest(
    "POST",
    backendApi,
    `class/code/create?sub_domain=${host}`,
    true,
    {},
    sessionId
  );
  const class_code = response.response.class_code;
  const dynamic_link = response.response.dynamic_link;
  if (!response.error) {
    return { class_code, dynamic_link };
  }
};

export const refreshClassCode = (class_id) => async (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { classes },
  } = getState();
  const host =
    window.location.hostname === LOCALHOST
      ? getOriginFromUrl(process.env.REACT_APP_BACKEND_API_URL)
      : window.location.origin;
  const response = await makeApiRequest(
    "POST",
    backendApi,
    `class/code/refresh?sub_domain=${host}`,
    true,
    { class_id },
    sessionId
  );
  console.log({ response });
  const class_code = response.response.class_code;
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  return class_code;
};

export const createClass =
  (
    batch = "batch",
    name,
    description = undefined,
    is_floating = true,
    class_code,
    dynamic_link,
    is_admin_teacher = false
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { classes },
    } = getState();
    const host =
      window.location.hostname === LOCALHOST
        ? getOriginFromUrl(process.env.REACT_APP_BACKEND_API_URL)
        : window.location.origin;
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `class/create?sub_domain=${host}`,
      true,
      {
        batch: "batch",
        name,
        description,
        is_floating,
        class_code,
        dynamic_link,
        is_admin_teacher,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return { success: false };
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }

    const newClassData = response.response.klass;
    await dispatch(addClass(newClassData));

    // Add dummy element for the class
    dispatch(
      addLibraryData([
        {
          id: newClassData.folder_id,
          name: newClassData.name,
          parent_id: 1,
          kind: "folder",
          modified_time: newClassData.joined_at || newClassData.created_at,
        },
      ])
    );
    return { success: true, classId: newClassData.id };
  };

export const setCurrentClass = (classId) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_CLASS,
    payload: classId,
  });
};

export const getWeekTimetable = (startDate) => (dispatch, getState) => {
  // extract the end date by adding 7 days to the start date and call the get timetable api.
  const { klass } = getState();
  let { calendarWeekStartDate } = klass;

  calendarWeekStartDate =
    calendarWeekStartDate ||
    moment().startOf("week").startOf("day").toDate().toISOString();

  // dispatch(getTimeTable(startDate, endDate, "week"));
};

export const getMonthTimetable = (month) => (dispatch, getState) => {
  // extract the start date and end of the month and call the get timetable api.
  const { klass } = getState();
  let { calendarMonth } = klass;

  calendarMonth = calendarMonth || moment().month();

  // calendarMonth is a number from 0 to 11 representing each month

  // dispatch(getTimeTable(startDate, endDate, "month"));
};

export const getLectureTimetable = () => (dispatch) => {
  // extract the start date from today and end date by adding 15 days and call the get timetable api

  dispatch(
    getTimeTable(
      moment().startOf("day").toDate().toISOString(),
      moment().add(14, "days").endOf("day").toDate().toISOString(),
      "lecture"
    )
  );
};

export const setSubmissionDetails = (data) => (dispatch, getState) => {
  let { userId, submission } = data;
  const id = uuidv4();
  submission = { ...submission, id };
  dispatch({
    type: ADD_STUDENT_SUBMISSION,
    payload: { userId, submission },
  });
};

export const startLiveClass = (details) => async (dispatch, getState) => {
  const {
    klass: { currentClass, classes },
  } = getState();

  const activeClass = classes[currentClass];

  const sessionId = localStorage.getItem("sessionId");
  const apiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "class/teacher/start-live",
    true,
    details,
    sessionId,
    false
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return { success: false };
  }

  dispatch({
    type: SET_APP_MICROSERVICES,
    payload: { rtcUrl: apiResponse.response.rtc_url },
  });
  getSocket(apiResponse.response.rtc_url);
  dispatch({
    type: SET_LIVE_LECTURE,
    payload: apiResponse.response.lecture,
  });

  if (activeClass?.class_is_live) {
    dispatch(setLiveClassSettings(apiResponse.response.liveclass_settings));
  }

  await dispatch({
    type: SET_JITSI_CONFERENCE_DETAILS,
    payload: {
      conferenceId:
        apiResponse.response.conference.conference.jitsi_conference_id,
      jitsiRootDomain: apiResponse.response.conference.jitsiRootDomain,
    },
  });

  // Add dummy element for the class
  dispatch(
    addLibraryData([
      {
        id: activeClass.folder_id,
        name: activeClass.name,
        parent_id: 1,
        kind: "folder",
        modified_time: activeClass.joined_at || activeClass.created_at,
        metadata: { class_id: activeClass.id },
      },
    ])
  );

  // Add dummy element for the lecture
  dispatch(
    addLibraryData([
      {
        id: apiResponse.response.lecture.folder_id,
        name: apiResponse.response.lecture.topic,
        parent_id: activeClass.folder_id,
        kind: "folder",
        metadata: { lecture_id: apiResponse.response.lecture.id },
        fetched: true,
      },
    ])
  );

  // const updatedLectureElementList =

  // Add elements of lecture
  dispatch(addLibraryData(apiResponse.response?.lecture_element_list || []));
  //Add as lecture entity
  if (classes[currentClass]?.status !== "temp") {
    dispatch(
      CoursePlannerAddLectureAPi({
        class_id: currentClass,
        lecture_id: apiResponse.response?.lecture?.id,
        // topic_id: null,
        type: "LIVE",
      })
    );
  }

  return {
    success: true,
    alreadyJoined: apiResponse.response?.already_joined_in_a_liveclass,
  };
};

export const initialJoinLiveClass = (details) => async (dispatch, getState) => {
  const {
    klass: { currentClass, classes },
  } = getState();

  const activeClass = classes[currentClass];

  const sessionId = localStorage.getItem("sessionId");
  const apiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "class/teacher/join-live",
    true,
    details,
    sessionId,
    false
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    console.log({ apiResponse });
    dispatch(errorAlert(apiResponse.message));
    return { success: false };
  }

  dispatch({
    type: SET_APP_MICROSERVICES,
    payload: { rtcUrl: apiResponse.response.rtc_url },
  });
  getSocket(apiResponse.response.rtc_url);
  dispatch({
    type: SET_LIVE_LECTURE,
    payload: apiResponse.response.lecture,
  });

  dispatch(setLiveClassSettings(apiResponse.response.liveclass_settings));

  await dispatch({
    type: SET_JITSI_CONFERENCE_DETAILS,
    payload: {
      conferenceId:
        apiResponse.response.conference.conference.jitsi_conference_id,
      jitsiRootDomain: apiResponse.response.conference.jitsiRootDomain,
    },
  });

  // Add dummy element for the class
  dispatch(
    addLibraryData([
      {
        id: activeClass.folder_id,
        name: activeClass.name,
        parent_id: 1,
        kind: "folder",
        modified_time: activeClass.joined_at || activeClass.created_at,
        metadata: { class_id: activeClass.id },
      },
    ])
  );

  // Add dummy element for the lecture
  dispatch(
    addLibraryData([
      {
        id: apiResponse.response.lecture.folder_id,
        name: apiResponse.response.lecture.topic,
        parent_id: activeClass.folder_id,
        kind: "folder",
        fetched: true,
        metadata: { lecture_id: apiResponse.response.lecture.id },
      },
    ])
  );

  // Add elements of lecture
  dispatch(addLibraryData(apiResponse.response?.lecture_element_list || []));
  return {
    success: true,
    alreadyJoined: apiResponse.response.already_joined_in_a_liveclass,
  };
};

export const alreadyJoinedLiveClass =
  (classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    if (!classId) {
      return;
    }

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "class/user/already-joined-liveclass",
      true,
      { class_id: classId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    return response.response.already_joined_in_a_liveclass;
  };

export const getClassStudentRequests =
  (classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass },
    } = getState();

    if (!classId) {
      classId = currentClass;
    }

    const requestsInvitesResponse = await makeApiRequest(
      "GET",
      backendApi,
      "class/requests-invites",
      true,
      { class_id: classId },
      sessionId
    );

    if (requestsInvitesResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (requestsInvitesResponse.error) {
      dispatch(errorAlert(requestsInvitesResponse.message));
      return;
    }
    dispatch({
      type: SET_PENDING_USERS,
      payload: requestsInvitesResponse.response.requests,
    });

    dispatch({
      type: SET_INVITED_USERS,
      payload: requestsInvitesResponse.response.invites,
    });
  };

export const approveOrRejectStudentJoinRequest =
  (classId, userId, approve, requestId, fromLiveclass = false) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const approveStudentResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/user/approve",
      true,
      {
        approve: approve,
        class_id: classId,
        user_id: userId,
        from_liveclass: fromLiveclass,
      },
      sessionId
    );

    if (approveStudentResponse.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (approveStudentResponse.error) {
      dispatch(errorAlert(approveStudentResponse.message));
      if (approveStudentResponse.code === responseCodes.REQUEST_EXPIRED) {
        dispatch(removeRequest(requestId));
      }
      return false;
    }

    dispatch(removePendingRequest(userId));

    return true;
  };

const removePendingRequest = (userId) => (dispatch) => {
  dispatch({
    type: REMOVE_PENDING_REQUEST,
    payload: userId,
  });
};

export const studentJoinLiveClass =
  (classCode, name) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass: classId },
    } = getState();

    const joinedClassResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/student/join",
      true,
      {
        class_code: classCode,
        name: name,
      },
      sessionId
    );

    if (joinedClassResponse.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (joinedClassResponse.error) {
      if (joinedClassResponse.message === "Please enter a valid class code") {
        return false;
      }
      dispatch(errorAlert(joinedClassResponse.message));
      return true;
    }

    if (joinedClassResponse?.response?.success === false) {
      dispatch(customAlert("Info", joinedClassResponse.message));
      return true;
    }

    await dispatch(addClass(joinedClassResponse.response.klass));

    return joinedClassResponse.response;
  };

export const approveStudentJoinRequest =
  (classId, requestId, fromLiveclass) => async (dispatch, getState) => {
    const {
      liveClass: { requests },
    } = getState();

    const request = requests[requestId];
    if (!request || request.status !== "PENDING") return;

    const userId = request?.user_id;
    if (!userId) return;

    return await dispatch(
      approveOrRejectStudentJoinRequest(
        classId,
        userId,
        true,
        requestId,
        fromLiveclass
      )
    );
  };

export const rejectStudentJoinRequest =
  (classId, requestId, fromLiveclass) => async (dispatch, getState) => {
    const {
      liveClass: { requests },
    } = getState();
    const request = requests[requestId];
    if (!request || request.status !== "PENDING") return;

    const userId = request?.user_id;
    if (!userId) return;

    return await dispatch(
      approveOrRejectStudentJoinRequest(
        classId,
        userId,
        false,
        requestId,
        fromLiveclass
      )
    );
  };

export const inviteUsers =
  (
    emails,
    role,
    initClassId = null,
    invite_to = "klass",
    lecture_id = null,
    batch_ids = []
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass: classId },
    } = getState();
    const inviteUsers = await makeApiRequest(
      "POST",
      backendApi,
      "class/user/invite",
      true,
      {
        class_id: initClassId === null ? classId : initClassId,
        emails,
        role,
        invite_to,
        lecture_id: invite_to === "klass" ? null : lecture_id,
        domain: window.location.origin || "",
        batch_ids: batch_ids,
      },
      sessionId
    );

    if (inviteUsers.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (inviteUsers.error) {
      dispatch(errorAlert(inviteUsers.message));
      return;
    }

    dispatch({
      type: ADD_INVITED_USERS,
      payload: inviteUsers.response.new_invites,
    });

    return inviteUsers.response;
  };

export const removeInvite = (inviteId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const inviteUsers = await makeApiRequest(
    "POST",
    backendApi,
    "class/user/invite/remove",
    true,
    {
      invite_id: inviteId,
    },
    sessionId
  );

  if (inviteUsers.logout) {
    dispatch(logoutProcedure(false));
  }

  if (inviteUsers.error) {
    dispatch(errorAlert(inviteUsers.message));
    return;
  }

  dispatch({
    type: REMOVE_INVITED_USERS,
    payload: [inviteId],
  });
};

export const resendInvite = (inviteId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const resendInviteResponse = await makeApiRequest(
    "POST",
    backendApi,
    "class/user/invite/resend",
    true,
    {
      invite_id: inviteId,
      domain: window.location.origin || "",
    },
    sessionId
  );

  if (resendInviteResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (resendInviteResponse.error) {
    dispatch(errorAlert(inviteUsers.message));
    return;
  }
};

export const updateClass =
  (name, batch, description = "") =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass: classId },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/update",
      true,
      {
        class_id: classId,
        name,
        description,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    dispatch({
      type: UPDATE_CLASS,
      payload: { classId, name, batch, description },
    });
    return apiResponse.response;
  };

export const updateClassSettings =
  (classSettings) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass: classId },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/settings",
      true,
      {
        class_id: classId,
        ...classSettings,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return false;
    }

    dispatch(updateClassSettingsInStore(classId, classSettings));

    dispatch(setLiveClassSettings(classSettings));

    return true;
  };

export const updateClassSettingsInStore =
  (classId, classSettings) => (dispatch) => {
    dispatch({
      type: UPDATE_CLASS_SETTINGS,
      payload: {
        classId,
        ...classSettings,
      },
    });
  };

export const setLiveClassSettings = (settings) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_SETTINGS,
    payload: {
      ...settings,
    },
  });
};

export const updateInvitations =
  (class_id, accept) => async (dispatch, getState) => {
    const {
      user: { sessionId, role },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      `class/${role}/invite/accept`,
      true,
      {
        class_id,
        accept,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      if (apiResponse.code === responseCodes.INVITATION_EXPIRED) {
        dispatch(removeClassInvitation(class_id));
      }
      return;
    }

    if (accept) {
      await dispatch(addClass(apiResponse.response.klass));
    }

    dispatch(removeClassInvitation(class_id));
  };

export const getRecentUsedUsers = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const apiResponse = await makeApiRequest(
    "GET",
    backendApi,
    "user/teacher/recent-used-users",
    true,
    {},
    sessionId
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return;
  }

  let teacherList = apiResponse.response.teacher_list;
  let studentList = apiResponse.response.student_list;

  // add id field to teacher list
  if (teacherList.length > 0) {
    teacherList.forEach((element) => {
      element.id = uuid();
    });
  }

  // add id field to student list
  if (studentList.length > 0) {
    studentList.forEach((element) => {
      element.id = uuid();
    });
  }

  dispatch({
    type: SET_AUTO_SUGGESTION_LIST_TEACHER,
    payload: teacherList,
  });

  dispatch({
    type: SET_AUTO_SUGGESTION_LIST_STUDENT,
    payload: studentList,
  });
};

export const removeStudents =
  (class_id, user_ids) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { classes },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/student/remove",
      true,
      {
        class_id,
        user_ids,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    dispatch({
      type: SET_CLASS_STUDENTS_COUNT,
      payload: {
        classId: class_id,
        count: Math.max(0, classes[class_id].students_count - user_ids.length),
      },
    });
  };

export const removeTeacher =
  (class_id, user_id) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/teacher/remove",
      true,
      {
        class_id,
        teacher_id: user_id,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
    dispatch({
      type: SET_TEACHER_REMOVED_FROM_CLASS,
      payload: { class_id, user_id },
    });
    return true;
  };

export const removeClassFromStore = (classId) => (dispatch) => {
  dispatch({
    type: REMOVE_CLASS,
    payload: { classId },
  });
};

export const classState =
  (class_id, create, name = "", batch = "", description = "") =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/update/persistent",
      true,
      {
        class_id,
        create,
        name,
        batch,
        description,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    if (!create) {
      dispatch(removeClassFromStore(class_id));
    } else {
      dispatch(addClass(apiResponse.response.klass));
    }
  };

export const classAddFromLive =
  (class_id, lecture_id, teacher_ids, student_ids) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/add-users-from-lecture",
      true,
      {
        student_ids,
        teacher_ids,
        class_id,
        lecture_id,
      },
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
  };

export const joinLiveClassStudent = (classId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const joinClassApiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "class/student/join-live",
    true,
    { class_id: classId },
    sessionId
  );

  if (joinClassApiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (joinClassApiResponse.error) {
    dispatch(errorAlert(joinClassApiResponse.message));
    return { success: false };
  }

  await dispatch({
    type: SET_JITSI_CONFERENCE_DETAILS,
    payload: {
      conferenceId:
        joinClassApiResponse.response.conference.conference.jitsi_conference_id,
      jitsiRootDomain: joinClassApiResponse.response.conference.jitsiRootDomain,
    },
  });

  dispatch({
    type: SET_LIVE_LECTURE,
    payload: joinClassApiResponse.response.lecture,
  });

  dispatch(
    setLiveClassSettings(joinClassApiResponse.response.liveclass_settings)
  );

  // dispatch({
  //   type: SET_CURRENT_LECTURE_ID,
  //   payload: joinClassApiResponse.response.lecture.id,
  // });

  // dispatch({
  //   type: ADD_LECTURE_TO_LECTURE_DETAILS_MAP,
  //   payload: joinClassApiResponse.response.lecture,
  // });

  // await dispatch({
  //   type: SET_APP_MICROSERVICES,
  //   payload: { rtcUrl: joinClassApiResponse.response.rtc_url },
  // });
  //
  // getSocket(joinClassApiResponse.response.rtc_url);
  return {
    success: true,
    alreadyJoined: joinClassApiResponse.response.already_joined_in_a_liveclass,
  };
};

export const joinLiveClassRecordingBot =
  (classId) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    // console.log("joinLiveClassRecordingBot", role, sessionId);

    const joinClassApiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/recording-bot/join-live",
      true,
      { class_id: classId },
      sessionId
    );

    if (joinClassApiResponse.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (joinClassApiResponse.error || !joinClassApiResponse.response.success) {
      // TODO: if class_is_live=false, show signup popups
      dispatch(errorAlert(joinClassApiResponse.message));
      return false;
    }

    await dispatch({
      type: SET_JITSI_CONFERENCE_DETAILS,
      payload: {
        conferenceId:
          joinClassApiResponse.response.conference.conference
            .jitsi_conference_id,
        jitsiRootDomain:
          joinClassApiResponse.response.conference.jitsiRootDomain,
      },
    });

    dispatch({
      type: SET_LIVE_LECTURE,
      payload: joinClassApiResponse.response.lecture,
    });

    dispatch(
      setLiveClassSettings(joinClassApiResponse.response.liveclass_settings)
    );

    return true;
  };

export const setPeopleTileSize = (newTileSize) => (dispatch, getState) => {
  const {
    user: { sessionId },
    klass: { currentClass: classId },
  } = getState();

  dispatch({
    type: UPDATE_CLASS,
    payload: {
      classId,
      people_tile_size: newTileSize,
    },
  });

  makeApiRequest(
    "POST",
    backendApi,
    "class/settings",
    true,
    {
      class_id: classId,
      people_tile_size: newTileSize,
    },
    sessionId
  ).then(() => {});
};

export const setClassListLoaded = (isLoaded) => (dispatch) => {
  dispatch({
    type: SET_CLASS_LIST_LOADED,
    payload: isLoaded,
  });
};

export const classClicks = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    `user/increment-class-clicks`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  return response.response?.discovery_status;
};

export const toggleQuickActionsPopup =
  ({ isVisible, wobbleEffect }) =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_QUICK_ACTIONS_POPUP,
      payload: { isVisible, wobbleEffect },
    });
  };

export const bulkApproveOrRejectStudentJoinRequest =
  (obj) => async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "/institute/admin/students/approve/bulk",
      true,
      obj,
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.response));
      return;
    }
    return true;
  };

export const studentJoinLiveClassWithCode =
  (classCode, name) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass: classId },
    } = getState();

    const joinedClassResponse = await makeApiRequest(
      "POST",
      backendApi,
      "class/student/join",
      true,
      {
        class_code: classCode,
        name: name,
      },
      sessionId
    );

    if (joinedClassResponse.logout) {
      dispatch(logoutProcedure(false));
      return joinedClassResponse.response;
    }

    if (joinedClassResponse.error) {
      if (joinedClassResponse.message === "Please enter a valid class code") {
        return joinedClassResponse.response;
      }
      dispatch(errorAlert(joinedClassResponse.message));
      return joinedClassResponse.response;
    }

    if (joinedClassResponse?.response?.success === false) {
      dispatch(customAlert("Info", joinedClassResponse.message));
      return joinedClassResponse.response;
    }

    await dispatch(addClass(joinedClassResponse.response.klass));

    return joinedClassResponse.response;
  };
