import platform from "platform";
import { uiVersion } from "../config/constants";

export const getDeviceDetails = () => {
  return {
    device: "browser",
    appVersion: uiVersion,
    osArchitecture: platform.os?.architecture,
    osName: platform.os?.family,
    osVersion: platform.os?.version,
    platform: navigator.platform,
    hardwareConcurrency: navigator.hardwareConcurrency,
    deviceMemory: navigator.deviceMemory,
    maxTouchPoints: navigator.maxTouchPoints,
    browser: getBrowserDetails(),
    ...getGeographyDetails(),
  };
};

const getBrowserDetails = () => {
  return {
    browser: platform.name,
    version: platform.version,
    description: platform.description,
    userAgent: platform.ua,
    webdriver: navigator.webdriver,
    cookieEnabled: navigator.cookieEnabled,
  };
};

const getGeographyDetails = () => {
  return {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: navigator.language,
    locales: navigator.languages,
  };
};
