//misc
export const SET_SIDEBAR_ACTIVE_OPTION = "SET_SIDEBAR_ACTIVE_OPTION";
export const SHOW_CLASS_NAVIGATION_OPTIONS = "SHOW_CLASS_NAVIGATION_OPTIONS";
export const SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION =
  "SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION";
export const SET_CONNECTION_ERROR_TYPE = "SET_CONNECTION_ERROR_TYPE";
export const TOGGLE_QUICK_ACTIONS_POPUP = "TOGGLE_QUICK_ACTIONS_POPUP";

// live class
export const SET_WHITEBOARD_ACTIVE = "SET_WHITEBOARD_ACTIVE";
export const SET_LIVE_VIDEO_STATE = "SET_LIVE_VIDEO_STATE";
export const SET_APP_MICROSERVICES = "SET_APP_MICROSERVICES";
export const SET_STUDENTS_REQUESTS_POPUP = "SET_STUDENTS_REQUESTS_POPUP";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_LIVE_EVENT_MESSAGE = "ADD_LIVE_EVENT_MESSAGE";
export const ADD_CHAT_ARCHIVE_MESSAGES = "ADD_CHAT_ARCHIVE_MESSAGES";
export const ADD_EVENT_ARCHIVE_MESSAGES = "ADD_EVENT_ARCHIVE_MESSAGES";
export const ADD_EVENT_REQUEST_ARCHIVE_MESSAGES =
  "ADD_EVENT_REQUEST_ARCHIVE_MESSAGES";
export const ADD_BUFFER_MESSAGE = "ADD_BUFFER_MESSAGE";
export const SET_LECTURE_SHARE_STATUS = "SET_LECTURE_SHARE_STATUS";
export const SET_FOLDER_SHARE = "SET_FOLDER_SHARE";
export const SET_ELEMENT_SHARE = "SET_ELEMENT_SHARE";
export const REMOVE_BUFFER_MESSAGE = "REMOVE_BUFFER_MESSAGE";
export const UPDATE_BUFFER_MESSAGE = "UPDATE_BUFFER_MESSAGE";
export const BUFFER_TO_MESSAGE = "BUFFER_TO_MESSAGE";
export const BUFFER_TO_EVENT = "BUFFER_TO_EVENT";
export const REMOVE_PEOPLE = "REMOVE_PEOPLE";
export const ADD_PEOPLE = "ADD_PEOPLE";
export const UPDATE_PEOPLE = "UPDATE_PEOPLE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SET_LIVE_STATUSES_OF_CLASSES = "SET_LIVE_STATUSES_OF_CLASSES";
export const SET_USER_SUBSCRIPTION_DETAILS = "SET_USER_SUBSCRIPTION_DETAILS";
export const UPDATE_PEOPLE_DETAILS = "UPDATE_PEOPLE_DETAILS";
export const ADD_REQUEST = "ADD_REQUEST";
export const REMOVE_REQUEST = "REMOVE_REQUEST";
export const REMOVE_REQUESTS_OF_USER = "REMOVE_REQUESTS_OF_USER";
export const ADD_HANDRAISE_ARCHIVE = "ADD_HANDRAISE_ARCHIVE";
export const ADD_HANDRAISE = "ADD_HANDRAISE";
export const REMOVE_HANDRAISE = "REMOVE_HANDRAISE";
export const RESET_LIVECLASS = "RESET_LIVECLASS";
export const SET_CONNECTION_ISSUE = "SET_CONNECTION_ISSUE";
export const UPDATE_LIVECLASS_TOPIC = "UPDATE_LIVECLASS_TOPIC";
export const SET_NOTIFICATION_HEADER = "SET_NOTIFICATION_HEADER";
export const REMOVE_CLASS = "REMOVE_CLASS";
export const UPDATE_LIVE_CLASS_STATUS = "UPDATE_LIVE_CLASS_STATUS";
export const SET_DISPLAY_PARTICIPANT_ID = "SET_DISPLAY_PARTICIPANT_ID";
export const SET_NEW_PARTICIPANTS_LIST = "SET_NEW_PARTICIPANTS_LIST";

export const SET_JITSI_X_CONNECTION_INITIALIZED =
  "SET_JITSI_X_CONNECTION_INITIALIZED";
export const SET_JITSI_X_CONFERENCE_DETAILS = "SET_JITSI_X_CONFERENCE_DETAILS";
export const SET_JITSI_X_CONFERENCE_OBJECT = "SET_JITSI_X_CONFERENCE_OBJECT";
export const SET_JITSI_X_CONNECTION_OBJECT = "SET_JITSI_X_CONNECTION_OBJECT";
export const SET_JITSI_X_CONFERENCE_JOINED = "SET_JITSI_X_CONFERENCE_JOINED";
export const SET_JITSI_X_CONNECTION_DROPPED = "SET_JITSI_X_CONNECTION_DROPPED";
export const SET_JITSI_X_CONNECTION_RECONNECTED =
  "SET_JITSI_X_CONNECTION_RECONNECTED";
export const ADD_JITSI_X_TRACK_TO_PARTICIPANT =
  "ADD_JITSI_X_TRACK_TO_PARTICIPANT";
export const REMOVE_JITSI_X_TRACK_FROM_PARTICIPANT =
  "REMOVE_JITSI_X_TRACK_FROM_PARTICIPANT";
export const SET_MY_JITSI_X_AUDIO_TRACK = "SET_MY_JITSI_X_AUDIO_TRACK";
export const SET_MY_JITSI_X_VIDEO_TRACK = "SET_MY_JITSI_X_VIDEO_TRACK";
export const SET_MY_JITSI_X_DESKTOP_TRACK = "SET_MY_JITSI_X_DESKTOP_TRACK";
export const SET_MY_JITSI_X_PARTICIPANT_DETAILS =
  "SET_MY_JITSI_X_PARTICIPANT_DETAILS";
export const RESET_JITSI_X_STATE = "RESET_JITSI_X_STATE";

export const SET_JITSI_CONFERENCE_DETAILS = "SET_JITSI_CONFERENCE_DETAILS";
export const SET_JITSI_CONNECTION_OBJECT = "SET_JITSI_CONNECTION_OBJECT";
export const SET_JITSI_CONFERENCE_OBJECT = "SET_JITSI_CONFERENCE_OBJECT";
export const SET_JITSI_CONFERENCE_JOINED = "SET_JITSI_CONFERENCE_JOINED";
export const SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED =
  "SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED";
export const SET_MY_JITSI_AUDIO_TRACK = "SET_MY_JITSI_AUDIO_TRACK";
export const SET_MY_JITSI_VIDEO_TRACK = "SET_MY_JITSI_VIDEO_TRACK";
export const SET_MY_JITSI_DESKTOP_TRACK = "SET_MY_JITSI_DESKTOP_TRACK";
export const SET_MY_JITSI_PARTICIPANT_DETAILS =
  "SET_MY_JITSI_PARTICIPANT_DETAILS";
export const ADD_JITSI_TRACK_TO_PARTICIPANT = "ADD_JITSI_TRACK_TO_PARTICIPANT";
export const REMOVE_JITSI_TRACK_FROM_PARTICIPANT =
  "REMOVE_JITSI_TRACK_FROM_PARTICIPANT";
export const ADD_JITSI_PARTICIPANT = "ADD_JITSI_PARTICIPANT";
export const REMOVE_JITSI_PARTICIPANT = "REMOVE_JITSI_PARTICIPANT";
export const SET_JITSI_TRACK_MUTE_CHANGED = "SET_JITSI_TRACK_MUTE_CHANGED";
export const SET_MY_JITSI_TRACK_MUTE_CHANGED =
  "SET_MY_JITSI_TRACK_MUTE_CHANGED";
export const RESET_JITSI_STATE = "RESET_JITSI_STATE";
export const SET_JITSI_TRACKS_INITIALIZED = "SET_JITSI_TRACKS_INITIALIZED";
export const SET_VOLUME_LEVEL = "SET_VOLUME_LEVEL";
export const SET_WHITEBOARD_ACTIVE_LAYER_ID = "SET_WHITEBOARD_ACTIVE_LAYER_ID";
export const SET_BROADCASTED_ACTIVE_LAYER_ID =
  "SET_BROADCASTED_ACTIVE_LAYER_ID";
export const SET_NEW_WHITEBOARD_ID = "SET_NEW_WHITEBOARD_ID";
export const SET_LAYER_GRID_STATE = "SET_LAYER_GRID_STATE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD =
  "SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD";
export const SET_JITSI_AVAILABLE_MEDIA_DEVICES =
  "SET_JITSI_AVAILABLE_MEDIA_DEVICES";
export const SET_MY_VIDEO_INPUT_DEVICE_ID = "SET_MY_VIDEO_INPUT_DEVICE_ID";
export const SET_MY_AUDIO_INPUT_DEVICE_ID = "SET_MY_AUDIO_INPUT_DEVICE_ID";
export const SET_MY_AUDIO_OUTPUT_DEVICE_ID = "SET_MY_AUDIO_OUTPUT_DEVICE_ID";
export const SET_CHANGED_PARTICIPANT_PROPERTY =
  "SET_CHANGED_PARTICIPANT_PROPERTY";
export const UPDATE_USER_ADDITIONAL_DATA = "UPDATE_USER_ADDITIONAL_DATA";
export const SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA =
  "SET_LIVE_QUIZ_STUDENT_PERFORMANCE_DATA";
export const SET_QUICK_QUESTION_LIST = "SET_QUICK_QUESTION_LIST";
export const SET_QUICK_QUESTION_TEXT_ONCHANGE =
  "SET_QUICK_QUESTION_TEXT_ONCHANGE";
export const SET_QUICK_QUESTION_OPTION_ONCHANGE =
  "SET_QUICK_QUESTION_OPTION_ONCHANGE";
export const SET_STUDENT_LIVE_CLASS_MIRO_WHITEBOARD_DATA =
  "SET_STUDENT_LIVE_CLASS_MIRO_WHITEBOARD_DATA";
// socket
export const SET_SERVER_TIME = "SET_SERVER_TIME";
export const SET_CLICKED_TRANSFER = "SET_CLICKED_TRANSFER";
export const SET_SOCKET = "SET_SOCKET";
export const SET_SOCKET_CONNECTION = "SET_SOCKET_CONNECTION";
export const REMOVE_INVITATION = "REMOVE_INVITATION";
export const SET_KICKED_FROM_CONFERENCE = "SET_KICKED_FROM_CONFERENCE";
export const SET_JITSI_CONFERENCE_ENDED = "SET_JITSI_CONFERENCE_ENDED";
// File System
export const REMOVE_FROM_RECENT = "REMOVE_FROM_RECENT";
export const REMOVE_FROM_STARRED = "REMOVE_FROM_STARRED";
export const REMOVE_FROM_SHARED = "REMOVE_FROM_SHARED";
export const SET_FILE_SYSTEM_DATA = "SET_FILE_SYSTEM_DATA";
export const ADD_FILE_SYSTEM_DATA = "ADD_FILE_SYSTEM_DATA";
export const SET_CURRENT_FOLDER_ID = "SET_CURRENT_FOLDER_ID";
export const SET_TREE_SELECTED_FOLDER_ID = "SET_TREE_SELECTED_FOLDER_ID";
export const SET_LOADER = "SET_LOADER";
export const STAR_ELEMENT = "STAR_ELEMENT";
export const STAR_RECENT_ELEMENT = "STAR_RECENT_ELEMENT";
export const STAR_STARRED_ELEMENT = "STAR_STARRED_ELEMENT";
export const STAR_SHARED_ELEMENT = "STAR_SHARED_ELEMENT";
export const SET_CLASS_BROWSER_VISIBILITY = "SET_CLASS_BROWSER_VISIBILITY";
export const SET_ADD_FOLDER_VISIBILITY = "SET_ADD_FOLDER_VISIBILITY";
export const SET_CLASS_STARTUP_DETAILS = "SET_CLASS_STARTUP_DETAILS";
export const SET_CLASS_LIST = "SET_CLASS_LIST";
export const SET_CLASS_BATCHES = "SET_CLASS_BATCHES";
export const SET_LECTURE_STARTUP_DETAILS = "SET_LECTURE_STARTUP_DETAILS";
export const ADD_LECTURE_FILE_LIST = "ADD_LECTURE_FILE_LIST";
export const SET_LECTURE_FILE_LIST = "SET_LECTURE_FILE_LIST";
export const SET_SLIDE_IMAGE_LIST = "SET_SLIDE_IMAGE_LIST";
export const ADD_SLIDE_IMAGE_LIST = "ADD_SLIDE_IMAGE_LIST";
export const SET_CURRENT_SLIDE_INDEX = "SET_CURRENT_SLIDE_INDEX";
export const SET_IMAGE_LOADING_STATUS = "SET_IMAGE_LOADING_STATUS";
export const SET_CLASS_LIST_LOADED = "SET_CLASS_LIST_LOADED";
export const SET_TRASH_STATUS = "SET_TRASH_STATUS";
export const SET_CURRENT_FILE_ID = "SET_CURRENT_FILE_ID";
export const SET_CURRENT_FOLDER_CHILDREN = "SET_CURRENT_FOLDER_CHILDREN";
export const SET_CURRENT_FOLDER_PARENT = "SET_CURRENT_FOLDER_PARENT";
export const RENAME_FILE_FOLDER = "RENAME_FILE_FOLDER";
export const RENAME_FILE_FOLDER_CHILDREN = "RENAME_FILE_FOLDER_CHILDREN";
export const GET_RECENT_USERS = "GET_RECENT_USERS";
export const SET_REFERENCE_FILES_UPLOAD_LECTURE =
  "SET_REFERENCE_FILES_UPLOAD_LECTURE";
// posts
export const SET_CLASS_POSTS = "SET_CLASS_POSTS";
export const UPDATE_CLASS_POSTS = "UPDATE_CLASS_POSTS";
export const SET_CLASS_DRAFT_POSTS = "SET_CLASS_DRAFT_POSTS";
export const UPDATE_CLASS_DRAFT_POSTS = "UPDATE_CLASS_DRAFT_POSTS";
export const SET_POST_REPLIES = "SET_POST_REPLIES";
export const UPDATE_POST_LIKE_REACTION = "UPDATE_POST_LIKE_REACTION";
export const USER_REORTED = "USER_REORTED";
export const SET_POST = "SET_POST";
export const ADD_CLASS_POST = "ADD_CLASS_POST";
export const REMOVE_DRAFT_POST = "REMOVE_DRAFT_POST";
export const UPDATE_POST_STAR_REACTION = "UPDATE_POST_STAR_REACTION";
export const REMOVE_POST = "REMOVE_POST";
export const ADD_QA_POST = "ADD_QA_POST";
export const SET_QA_POSTS = "SET_QA_POSTS";
export const SET_QA_DRAFT_POSTS = "SET_QA_DRAFT_POSTS";
export const SET_REMINDERS = "SET_REMINDERS";
export const SET_CURRENT_WEEK_REMINDERS = "SET_CURRENT_WEEK_REMINDERS";
export const SET_SUBMISSIONS = "SET_SUBMISSIONS";
export const REMOVE_PENDING_REQUEST = "REMOVE_PENDING_REQUEST";
export const SET_PENDING_USERS = "SET_PENDING_USERS";
export const ADD_INVITED_USERS = "ADD_INVITED_USERS";
export const REMOVE_INVITED_USERS = "REMOVE_INVITED_USERS";
export const SET_INVITED_USERS = "SET_INVITED_USERS";
export const SET_SCREEN_CONTEXT = "SET_SCREEN_CONTEXT";
export const SET_CLASS_STUDENT_DATA_MAP = "SET_CLASS_STUDENT_DATA_MAP";
export const SET_IS_FILE_SYSTEM_DATA_LOADED = "SET_IS_FILE_SYSTEM_DATA_LOADED";
export const SET_FS_RECENT_FILE_DATE_LIST = "SET_FS_RECENT_FILE_DATE_LIST";
export const SET_FS_FOLDER_TO_CLASS_ID_MAP = "SET_FS_FOLDER_TO_CLASS_ID_MAP";
export const SET_FS_TOP_TRASH_ELEMENT_ID_LIST =
  "SET_FS_TOP_TRASH_ELEMENT_ID_LIST";
export const SET_FS_UPLOAD_PROGRESS_PERCENT = "SET_FS_UPLOAD_PROGRESS_PERCENT";
export const SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT =
  "SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT";
export const SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC =
  "SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC";
export const SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID =
  "SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID";
export const SET_FS_MULTIPLE_UPLOAD_COMPLETE =
  "SET_FS_MULTIPLE_UPLOAD_COMPLETE";
export const RESET_FS_MULTIPLE_UPLOAD = "RESET_FS_MULTIPLE_UPLOAD";
export const ADD_FS_MULTIPLE_UPLOAD_FILES = "ADD_FS_MULTIPLE_UPLOAD_FILES";
export const ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED =
  "ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED";
export const REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED =
  "REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED";
export const RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED =
  "RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED";
export const REMOVE_FS_MULTIPLE_UPLOAD_FILES =
  "REMOVE_FS_MULTIPLE_UPLOAD_FILES";
export const SET_FS_MULTIPLE_INCREMENT_COUNTER =
  "SET_FS_MULTIPLE_INCREMENT_COUNTER";
export const SET_CLASS_LECTURES = "SET_CLASS_LECTURES";
export const UPDATE_LECTURE_ATTENDANCE = "UPDATE_LECTURE_ATTENDANCE";
export const SET_LECTURE_ATTENDANCE_DETAILS = "SET_LECTURE_ATTENDANCE_DETAILS";
export const SET_AVERAGE_ATTENDANCE_DETAILS = "SET_AVERAGE_ATTENDANCE_DETAILS";
export const SET_CURRENT_LECTURE = "SET_CURRENT_LECTURE";
export const SET_CURRENT_LECTURE_OBJECT = "SET_CURRENT_LECTURE_OBJECT";
export const SET_TEMPORARY_LECTURE_OBJECT = "SET_TEMPORARY_LECTURE_OBJECT";
export const SET_CURRENT_CLASS = "SET_CURRENT_CLASS";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const CLEAR_CLASS_DETAILS = "CLEAR_CLASS_DETAILS";
export const CLEAR_FILE_SYSTEM_DETAILS = "CLEAR_FILE_SYSTEM_DETAILS";
export const CLEAR_LECTURE_DETAILS = "CLEAR_LECTURE_DETAILS";
export const CLEAR_LIVECLASS_DETAILS = "CLEAR_LIVECLASS_DETAILS";
export const CLEAR_MISCELLANEOUS_DETAILS = "CLEAR_MISCELLANEOUS_DETAILS";
export const SET_POST_ON_TOP = "SET_POST_ON_TOP";
export const CLEAR_PEOPLE_DETAILS = "CLEAR_PEOPLE_DETAILS";
export const CLEAR_POSTS_DETAILS = "CLEAR_POSTS_DETAILS";
export const CLEAR_RECORDER_DETAILS = "CLEAR_RECORDER_DETAILS";
export const CLEAR_SECRET_DETAILS = "CLEAR_SECRET_DETAILS";
export const CLEAR_SOCKET_DETAILS = "CLEAR_SOCKET_DETAILS";
export const SET_PEOPLE_POPUP_DETAILS = "SET_PEOPLE_POPUP_DETAILS";
export const SET_STUDENTS_REMOVED_FROM_CLASS =
  "SET_STUDENTS_REMOVED_FROM_CLASS";
export const SET_TEACHER_REMOVED_FROM_CLASS = "SET_TEACHER_REMOVED_FROM_CLASS";
export const SET_TIMETABLE = "SET_TIMETABLE";
export const REMOVE_LECTURE = "REMOVE_LECTURE";
export const ADD_LECTURE = "ADD_LECTURE";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_LECTURE = "UPDATE_LECTURE";
export const CLONE_LECTURE = "CLONE_LECTURE";
export const ADD_LECTURE_CLASS = "ADD_LECTURE_CLASS";
export const SET_TIMETABLE_WEEK = "SET_TIMETABLE_WEEK";
export const SET_TIMETABLE_MONTH = "SET_TIMETABLE_MONTH";
export const SET_TIMETABLE_LECTURE = "SET_TIMETABLE_LECTURE";
export const REMOVE_TIMETABLE_LECTURE = "REMOVE_TIMETABLE_LECTURE";
export const ADD_STUDENT_SUBMISSION = "ADD_STUDENT_SUBMISSION";
export const SET_SUBMISSION_WHITEBOARD_ACTIVE =
  "SET_SUBMISSION_WHITEBOARD_ACTIVE";
export const SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT =
  "SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT";
export const SET_LIVE_CLASS = "SET_LIVE_CLASS";
export const SET_LIVE_LECTURE = "SET_LIVE_LECTURE";
export const REMOVE_LIVE_LECTURE = "REMOVE_LIVE_LECTURE";
export const SET_LECTURE_CHATS = "SET_LECTURE_CHATS";
export const ADD_LIVECLASS_PEOPLE = "ADD_LIVECLASS_PEOPLE";
export const ADD_CLASS_INVITATION = "ADD_CLASS_INVITATION";
export const ADD_CLASS = "ADD_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const SET_CLASS_STUDENTS_COUNT = "SET_CLASS_STUDENTS_COUNT";
export const SET_RECORDER_DETAILS = "SET_RECORDER_DETAILS";
export const UPDATE_RECORDING_STATE = "UPDATE_RECORDING_STATE";
export const SET_RECORDING_FLAG = "SET_RECORDING_FLAG";
export const SET_RECORDING_PAUSED_FLAG = "SET_RECORDING_PAUSED_FLAG";
export const SET_RECORDING_DESKTOP_VIDEO_TRACK =
  "SET_RECORDING_DESKTOP_VIDEO_TRACK";
export const SET_RECORDING_USER_AUDIO_TRACK = "SET_RECORDING_USER_AUDIO_TRACK";
export const RESET_RECORDER_STATE = "RESET_RECORDER_STATE";
export const SET_RECORDING_START_TIME = "SET_RECORDING_START_TIME";
export const SET_RECORDING_ACCUMULATED_TIME = "SET_RECORDING_ACCUMULATED_TIME";
export const SET_UPLOAD_LECTURE_DATA = "SET_UPLOAD_LECTURE_DATA";
export const CLEAR_UPLOAD_LECTURE_DATA = "CLEAR_UPLOAD_LECTURE_DATA";
export const SET_SHOW_RECORDING_DISCLAIMER = "SET_SHOW_RECORDING_DISCLAIMER";
export const SET_SHOW_RECORDING_ABRUPTLY_ENDED =
  "SET_SHOW_RECORDING_ABRUPTLY_ENDED";
export const SAVE_BLOB_TO_REDUX = "SAVE_BLOB_TO_REDUX";
export const SET_SUBMISSION_WHITEBOARD_DATA = "SET_SUBMISSION_WHITEBOARD_DATA";
export const SET_LIVE_CLASS_WHITEBOARD_DATA = "SET_LIVE_CLASS_WHITEBOARD_DATA";
export const SET_RECORDING_NAMES = "SET_RECORDING_NAMES";
export const SET_SHOW_RECORDING_NOT_UPLOADED =
  "SET_SHOW_RECORDING_NOT_UPLOADED";
export const SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO =
  "SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO";
export const SET_WHITEBOARD_DATA = "SET_WHITEBOARD_DATA";
export const SET_POPUP_DETAILS = "SET_POPUP_DETAILS";
export const SET_LIVE_CLASS_FILES_SELECTED_NONE =
  "SET_LIVE_CLASS_FILES_SELECTED_NONE";
export const SET_LARGE_PEOPLE_TAB_ACTIVE = "SET_LARGE_PEOPLE_TAB_ACTIVE";
export const SET_SHOW_RHS_PEOPLE_TAB = "SET_SHOW_RHS_PEOPLE_TAB";
export const SET_PERFORM_LOGOUT = "SET_PERFORM_LOGOUT";
export const SET_LIVE_CLASS_FILES_SELECTED = "SET_LIVE_CLASS_FILES_SELECTED";
export const SET_JITSI_CONNECTION_INITIALIZED =
  "SET_JITSI_CONNECTION_INITIALIZED";
export const RESET = "RESET";
export const SET_SELECTED_PARTICIPANT_IDS = "SET_SELECTED_PARTICIPANT_IDS";
export const SET_ALL_PARTICIPANTS = "SET_ALL_PARTICIPANTS";
export const SET_TEACHER_PARTICIPANTS = "SET_TEACHER_PARTICIPANTS";
export const SET_STUDENT_PARTICIPANTS = "SET_STUDENT_PARTICIPANTS";
export const SET_DOMINANT_PARTICIPANT = "SET_DOMINANT_PARTICIPANT";
export const SET_SLIDEBOARD_LAYER_INTIALIZE_STATE =
  "SET_SLIDEBOARD_LAYER_INTIALIZE_STATE";
export const SET_SLIDEBOARD_ACTIVE_LAYER_ID = "SET_SLIDEBOARD_ACTIVE_LAYER_ID";
export const SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID =
  "SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID";
export const SET_PAPER_ACTIVE_LAYER_ID = "SET_PAPER_ACTIVE_LAYER_ID";
export const SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY =
  "SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY";
export const SET_SHARED_PARTICIPANT_DETAILS = "SET_SHARED_PARTICIPANT_DETAILS";
export const SET_CLASS_INVITES = "SET_CLASS_INVITES";
export const DELETE_CLASS_INVITES = "DELETE_CLASS_INVITES";
export const RESET_AUTH_DATA = "RESET_AUTH_DATA";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const SET_IS_NEW_USER = "SET_IS_NEW_USER";
export const SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION =
  "SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION";
export const UPDATE_CLASS_SETTINGS = "UPDATE_CLASS_SETTINGS";
export const SET_TEMP_CLASS__DETAILS = "SET_TEMP_CLASS__DETAILS";
export const SET_AUTO_SUGGESTION_LIST_TEACHER =
  "SET_AUTO_SUGGESTION_LIST_TEACHER";
export const SET_AUTO_SUGGESTION_LIST_STUDENT =
  "SET_AUTO_SUGGESTION_LIST_STUDENT";
export const SET_LIVE_CLASS_ACTIVE = "SET_LIVE_CLASS_ACTIVE";
export const SET_LIVECLASS_UNREAD_MESSAGES = "SET_LIVECLASS_UNREAD_MESSAGES";
export const SET_LIVECLASS_LAST_VIEWED_MESSAGE =
  "SET_LIVECLASS_LAST_VIEWED_MESSAGE";
export const SET_LIVE_CLASS_FILES_STATE = "SET_LIVE_CLASS_FILES_STATE";
export const SET_HANDRAISE_REQUEST_ID = "SET_HANDRAISE_REQUEST_ID";
export const SET_COPY_LINK_MESSAGE = "SET_COPY_LINK_MESSAGE";
export const SET_SMALL_VIDEO_VIEW_ACTIVE = "SET_SMALL_VIDEO_VIEW_ACTIVE";
export const SET_LIVE_CLASS_SETTINGS = "SET_LIVE_CLASS_SETTINGS";
export const SET_GRID_ICON_ACTIVE = "SET_GRID_ICON_ACTIVE";
export const DELETE_CHAT_MESSAGE = "DELETE_CHAT_MESSAGE";
export const SET_YOUTUBE_VIDEO_SHARE_CONTROLS =
  "SET_YOUTUBE_VIDEO_SHARE_CONTROLS";
export const SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED =
  "SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED";
// discovery
export const UPDATE_USER_DISCOVERY = "UPDATE_USER_DISCOVERY";
export const SET_LAST_VISITED_ROUTE = "SET_LAST_VISITED_ROUTE";
export const SET_LIVE_CLASS_TIME_ELAPSED = "SET_LIVE_CLASS_TIME_ELAPSED";
export const SET_LIVE_CLASS_TIMER_DETAILS = "SET_LIVE_CLASS_TIMER_DETAILS";
// mic/video/screenshare request types
export const ADD_SCREENSHARE_REQUEST = "ADD_SCREENSHARE_REQUEST";
export const REMOVE_SCREENSHARE_REQUEST = "REMOVE_SCREENSHARE_REQUEST";
export const REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID =
  "REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID";
export const RESET_SCREENSHARE_REQUEST_LIST = "RESET_SCREENSHARE_REQUEST_LIST";
export const ADD_MIC_REQUEST = "ADD_MIC_REQUEST";
export const REMOVE_MIC_REQUEST = "REMOVE_MIC_REQUEST";
export const REMOVE_MIC_REQUEST_FROM_REQUEST_ID =
  "REMOVE_MIC_REQUEST_FROM_REQUEST_ID";
export const RESET_MIC_REQUEST_LIST = "RESET_MIC_REQUEST_LIST";
export const ADD_VIDEO_REQUEST = "ADD_VIDEO_REQUEST";
export const REMOVE_VIDEO_REQUEST = "REMOVE_VIDEO_REQUEST";
export const REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID =
  "REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID";
export const RESET_VIDEO_REQUEST_LIST = "RESET_VIDEO_REQUEST_LIST";
// smartboard control
export const TOGGLE_ON = "TOGGLE_ON";
export const TOGGLE_OFF = "TOGGLE_OFF";
export const SELECT_OPTION = "SELECT_OPTION";
export const SET_BROADCAST = "SET_BROADCAST";
export const SET_MAINSCREEN = "SET_MAINSCREEN";
export const SET_WHITEBOARD_DIMENSION = "SET_WHITEBOARD_DIMENSION";
export const SET_VIDEO_STATE = "SET_VIDEO_STATE";
export const SET_CLICKED_SLIDE = "SET_CLICKED_SLIDE";
export const SET_SHOW_SLIDES_POPUP = "SET_SHOW_SLIDES_POPUP";
export const SET_IS_MIROBOARD = "SET_IS_MIROBOARD";
export const SET_MIRO_WHITEBOARD = "SET_MIRO_WHITEBOARD";
export const SET_SHOW_WHITEBOARD_SELECTOR_POPUP =
  "SET_SHOW_WHITEBOARD_SELECTOR_POPUP";
export const SET_SLIDE_POPUP_FOR = "SET_SLIDE_POPUP_FOR";
export const SET_SLIDE_SHARE_STATE = "SET_SLIDE_SHARE_STATE";
export const SET_SHOW_TOOLBAR = "SET_SHOW_TOOLBAR";
export const SET_HOVER_SMARTBOARD = "SET_HOVER_SMARTBOARD";
export const SET_HOVER_RHS_VIEW = "SET_HOVER_RHS_VIEW";
export const SET_SHOW_CLASS_CODE = "SET_SHOW_CLASS_CODE";
export const RESET_SMARTBOARD = "RESET_SMARTBOARD";
export const SET_MEDIA_SHARE_STATE = "SET_MEDIA_SHARE_STATE";
export const SET_MAINSCREEN_SLIDE_INFO = "SET_MAINSCREEN_SLIDE_INFO";
export const SET_ALREADY_FETCHED_SLIDES_MAP = "SET_ALREADY_FETCHED_SLIDES_MAP";

// student notebook type
export const ADD_NEW_STUDENT_NOTEBOOK = "ADD_NEW_STUDENT_NOTEBOOK";
export const SET_STUDENT_LIVE_CLASS_FULL_SCREEN =
  "SET_STUDENT_LIVE_CLASS_FULL_SCREEN";
export const SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA =
  "SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA";
export const SET_STUDENT_VIDEO_MINIMIZED = "SET_STUDENT_VIDEO_MINIMIZED";
export const SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION =
  "SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION";
export const SET_CURRENT_TEACHER_PARTICIPANT =
  "SET_CURRENT_TEACHER_PARTICIPANT";
export const SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED =
  "SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED";
// related to toast notifications
export const PUSH_TO_TOAST_UPCOMING_QUEUE = "PUSH_TO_TOAST_UPCOMING_QUEUE";
export const POP_FROM_TOAST_UPCOMING_QUEUE = "POP_FROM_TOAST_UPCOMING_QUEUE";
export const SET_CURRENT_TOAST = "SET_CURRENT_TOAST";
// chats related
export const SET_CHAT_SHOWING_CONTEXT_MENU = "SET_CHAT_SHOWING_CONTEXT_MENU";
export const SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW =
  "SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW";
// live class popup related
export const SET_SHOW_RATE_SESSION_POPUP = "SET_SHOW_RATE_SESSION_POPUP";
export const SET_SHOW_KICK_OUT_POPUP = "SET_SHOW_KICK_OUT_POPUP";
export const SET_CLICKED_END_SESSION_FOR_ALL =
  "SET_CLICKED_END_SESSION_FOR_ALL";
export const SET_SHOW_JOIN_REQUEST_PENDING = "SET_SHOW_JOIN_REQUEST_PENDING";
export const SET_SHOW_JOIN_REQUEST_DENIED = "SET_SHOW_JOIN_REQUEST_DENIED";
export const SET_SHOW_REQUEST_PENDING = "SET_SHOW_REQUEST_PENDING";
export const SET_SHOW_REQUEST_DENIED = "SET_SHOW_REQUEST_DENIED";
export const SET_SHOW_REQUESTING_VIDEO = "SET_SHOW_REQUESTING_VIDEO";
export const SET_SHOW_REQUESTING_AUDIO = "SET_SHOW_REQUESTING_AUDIO";
export const SET_SHOW_REQUESTING_SCREENSHARE =
  "SET_SHOW_REQUESTING_SCREENSHARE";
export const SET_SHOW_HAND_RAISE_TIMER = "SET_SHOW_HAND_RAISE_TIMER";
export const SET_STUDENT_LIVE_CLASS_LOADER = "SET_STUDENT_LIVE_CLASS_LOADER";
export const SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP =
  "SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP";
export const SET_NOTEBOOK_DELETE_LAYER_ID = "SET_NOTEBOOK_DELETE_LAYER_ID";
export const UPDATE_STUDENT_NOTEBOOK_IDS = "UPDATE_STUDENT_NOTEBOOK_IDS";
export const SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID =
  "SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID";
export const SET_MY_VIDEO_PREVIEW_TRACK = "SET_MY_VIDEO_PREVIEW_TRACK";
export const SET_MY_AUDIO_PREVIEW_TRACK = "SET_MY_AUDIO_PREVIEW_TRACK";
export const SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED =
  "SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED";
export const CLEANUP_JITSI_PREVIEW_TRACKS = "CLEANUP_JITSI_PREVIEW_TRACKS";
export const UPDATE_EVENT_STATUS = "UPDATE_EVENT_STATUS";
export const SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP =
  "SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP";
export const SET_WHITEBOARD_DELETE_LAYER_ID = "SET_WHITEBOARD_DELETE_LAYER_ID";
export const SET_WHITEBOARD_SLIDE_ID = "SET_WHITEBOARD_SLIDE_ID";
export const UPDATE_WHITEBOARD_IDS = "UPDATE_WHITEBOARD_IDS";
export const SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP =
  "SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP";
export const SET_FILE_DELETE_ID = "SET_FILE_DELETE_ID";
export const SET_SHOW_LOAD_MEDIA_POPUP = "SET_SHOW_LOAD_MEDIA_POPUP";
export const SET_SHOW_LINK_CONNECT_POPUP = "SET_SHOW_LINK_CONNECT_POPUP";
export const SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP =
  "SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP";
export const SET_SHOW_LINK_DEVICE_BROADCAST_POPUP =
  "SET_SHOW_LINK_DEVICE_BROADCAST_POPUP";
//student live feedback
export const UPDATE_LIVE_FEEDBACK = "UPDATE_LIVE_FEEDBACK";
export const SET_PRESENTATION_DIMENSIONS = "SET_PRESENTATION_DIMENSIONS";
export const SET_LECTURE_WHITEBOARD_ARCHIVE = "SET_LECTURE_WHITEBOARD_ARCHIVE";
export const SET_TRASH_ELEMENTS_MAP = "SET_TRASH_ELEMENTS_MAP";
export const SET_USER_FOLDER_CHILDREN = "SET_USER_FOLDER_CHILDREN";
export const SET_LIVE_CLASS_FILESYSTEM_IDS = "SET_LIVE_CLASS_FILESYSTEM_IDS";
export const SET_LIBRARY_FILESYSTEM_IDS = "SET_LIBRARY_FILESYSTEM_IDS";
export const SET_STUDENT_PREFERENCES = "SET_STUDENT_PREFERENCES";
export const SET_TEACHER_PREFERENCES = "SET_TEACHER_PREFERENCES";
export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const SET_CALENDAR_TYPE = "SET_CALENDAR_TYPE";
export const REMOVE_FOLDER_CHILDREN = "REMOVE_FOLDER_CHILDREN";
export const SET_JITSI_CONNECTION_DROPPED = "SET_JITSI_CONNECTION_DROPPED";
export const SET_JITSI_CONNECTION_RECONNECTED =
  "SET_JITSI_CONNECTION_RECONNECTED";
export const SET_DESKTOP_PARTICIPANTS = "SET_DESKTOP_PARTICIPANTS";

export const SET_USERS_OF_LECTURE = "SET_USERS_OF_LECTURE";
export const ADD_LIVECLASS_ENDED_EVENT_DATA = "ADD_LIVECLASS_ENDED_EVENT_DATA";
export const RESET_LIVECLASS_ENDED_EVENT_DATA =
  "RESET_LIVECLASS_ENDED_EVENT_DATA";
export const SET_LIVECLASS_LECTURE_ID = "SET_LIVECLASS_LECTURE_ID";
export const SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP =
  "SET_TEACHER_PREFERENCES_DONT_SHOW_DOWNLOAD_POPUP";

export const SET_SHOW_DETAILS_POPUP = "SET_SHOW_DETAILS_POPUP";
export const SET_DETAILS_POPUP_ELEMENT = "SET_DETAILS_POPUP_ELEMENT";
export const SET_DELETE_POPUP_ELEMENT = "SET_DELETE_POPUP_ELEMENT";
export const SET_SHOW_DELETE_POPUP = "SET_SHOW_DELETE_POPUP";
export const SET_IS_MOVE = "SET_IS_MOVE";
export const SET_MOVE_COPY_POPUP_ELEMENT = "SET_MOVE_COPY_POPUP_ELEMENT";
export const SET_SHOW_MOVE_COPY_POPUP = "SET_SHOW_MOVE_COPY_POPUP";
export const SET_SHOW_SHARE_FILE_FOLDER_POPUP =
  "SET_SHOW_SHARE_FILE_FOLDER_POPUP";
//Live feedback
export const SET_FEEDBACK_AGGREGATE_SCORE = "SET_FEEDBACK_AGGREGATE_SCORE";
export const SET_SHOW_FEEDBACK_INPUT = "SET_SHOW_FEEDBACK_INPUT";
export const SET_USER_PREVIOUS_FEEDBACK_SCORE =
  "SET_USER_PREVIOUS_FEEDBACK_SCORE";
export const HIDE_YOUTUBE_IN_DOM = "HIDE_YOUTUBE_IN_DOM";
export const SET_YOUTUBE_INITIALIZED = "SET_YOUTUBE_INITIALIZED";
export const SET_STUDENT_YOUTUBE_INITIALIZED =
  "SET_STUDENT_YOUTUBE_INITIALIZED";
export const SET_LIVE_CLASS_SELECTED_FILE_PARAMS =
  "SET_LIVE_CLASS_SELECTED_FILE_PARAMS";
export const SET_FS_STARRED_FILES_LIST = "SET_FS_STARRED_FILES_LIST";
export const SET_FS_SHARED_WITH_USER_FILE_LIST =
  "SET_FS_SHARED_WITH_USER_FILE_LIST";

//Application Types
export const SET_APPLICATION_SETTINGS = "SET_APPLICATION_SETTINGS";
export const SET_SHOW_TRIAL_DETAILS = "SET_SHOW_TRIAL_DETAILS";
export const SET_SHOW_TRIAL_EXPIRED = "SET_SHOW_TRIAL_EXPIRED";
export const SET_DEVICE_INFO = "SET_DEVICE_INFO";

// Admin Type

export const SET_ADMIN_STARTUP_DETAILS = "SET_ADMIN_STARTUP_DETAILS";
export const SET_ADMIN_DASHBOARD_DETAILS = "SET_ADMIN_DASHBOARD_DETAILS";
export const SET_TOTAL_LICENSE = "SET_TOTAL_LICENSE";
export const UPDATE_INSTITUTE_DETAIL = "UPDATE_INSTITUTE_DETAIL";
export const UPDATE_INSTITUTE_TEACHER = "UPDATE_INSTITUTE_TEACHER";
export const UPDATE_SUBSCRIPTION_DETAILS = "UPDATE_SUBSCRIPTION_DETAILS";
export const UPDATE_USER_SUBSCRIPTION_DETAILS =
  "UPDATE_USER_SUBSCRIPTION_DETAILS";
export const UPDATE_INSTITUTE_REQUEST = "UPDATE_INSTITUTE_REQUEST";
export const UPDATE_REMOVE_INSTITUTE_INVITATION =
  "UPDATE_REMOVE_INSTITUTE_INVITATION";
export const SET_INSTITUTE_LIST = "SET_INSTITUTE_LIST";
export const SET_INSTITUTE_TEACHER_REQUEST = "SET_INSTITUTE_TEACHER_REQUEST";
export const SET_INSTITUTE_TEACHER_INVITATION =
  "SET_INSTITUTE_TEACHER_INVITATION";
export const SET_INSTITUTE_TEACHER = "SET_INSTITUTE_TEACHER";
export const SET_CLIENT_BRANDING_INFO = "SET_CLIENT_BRANDING_INFO";
export const SET_ADMIN_PENDING_REQUEST = "SET_ADMIN_PENDING_REQUEST";
export const SET_ADMIN_PENDING_INVITES = "SET_ADMIN_PENDING_INVITES";
export const ADD_INSTITUTE_INVITATIONS = "ADD_INSTITUTE_INVITATIONS";
export const SET_CLASS_LECTURE_REPORT = "SET_CLASS_LECTURE_REPORT";
export const RESET_CLASS_LECTURE_REPORT = "RESET_CLASS_LECTURE_REPORT";
export const SET_INSTITUTE_STUDENTS = "SET_INSTITUTE_STUDENTS";
export const SET_INSTITUTE_TEACHER_BATCH_LIST =
  "SET_INSTITUTE_TEACHER_BATCH_LIST";
export const SET_ADMIN_LECTURES = "SET_ADMIN_LECTURES";
export const SET_ADMIN_STUDENT_DETAILS = "SET_ADMIN_STUDENT_DETAILS";
export const SET_ADMIN_TEACHER_DATA = "SET_ADMIN_TEACHER_DATA";
export const SET_ADMIN_CLASSES = "SET_ADMIN_CLASSES";

// Assignment types
export const SET_ASSIGNMENTS_LIST = "SET_ASSIGNMENTS_LIST";
export const SET_COURSEWORK_LIST = "SET_COURSEWORK_LIST";
export const SET_CURRENT_ASSIGNMENT = "SET_CURRENT_ASSIGNMENT";
export const SET_ALL_TOPIC = "SET_ALL_TOPIC";
export const SET_ALL_SUBMISSIONS = "SET_ALL_SUBMISSIONS";
export const SET_SUBMISSIONS_GRADE = "SET_SUBMISSIONS_GRADE";
export const ADD_SUBMISSIONS_GRADE = "ADD_SUBMISSIONS_GRADE";
export const SET_CREATE_ASSIGNMENT = "SET_CREATE_ASSIGNMENT";
export const SET_EDIT_ASSIGNMENT = "SET_EDIT_ASSIGNMENT";
export const SET_ASSIGNED_STUDENTS = "SET_ASSIGNED_STUDENTS";
export const SET_TURNED_IN = "SET_TURNED_IN";
export const SET_REUSE_ASSIGNMENT = "SET_REUSE_ASSIGNMENT";
export const SET_CURRENT_ASSIGNMENT_FILE = "SET_CURRENT_ASSIGNMENT_FILE";
export const SET_ASSIGNMENT_LINK = "SET_ASSIGNMENT_LINK";
export const SET_ALLOW_PUBLISH_RESULT = "SET_ALLOW_PUBLISH_RESULT";
export const SET_COURSEWORK_QUIZ_LIST = "SET_COURSEWORK_QUIZ_LIST";
export const SET_ALL_QUIZ_AGGREGATES = "SET_ALL_QUIZ_AGGREGATES";
export const SET_ALL_STUDENTS_QUIZ_PERFORMANCE =
  "SET_ALL_STUDENTS_QUIZ_PERFORMANCE";
export const SET_ALL_QUIZ_OVERLL_STUDENTS_ANALYTICS =
  "SET_ALL_QUIZ_OVERLL_STUDENTS_ANALYTICS";
export const SET_TOUGHEST_QUESTION_QUIZ = "SET_TOUGHEST_QUESTION_QUIZ";
export const ADD_UPLOADED_QUESTION_FILES = "ADD_UPLOADED_QUESTION_FILES";
export const ADD_UPLOADED_OPTION_FILES = "ADD_UPLOADED_OPTION_FILES";
export const SET_ALL_QUESTION_FILES = "SET_ALL_QUESTION_FILES";
export const SET_ALL_OPTION_FILES = "SET_ALL_OPTION_FILES";
export const REMOVE_QUESTION_FILE = "REMOVE_QUESTION_FILE";
export const REMOVE_OPTION_FILE = "REMOVE_OPTION_FILE";
export const SET_ALL_QUIZ_QUESTION = "SET_ALL_QUIZ_QUESTION";
export const SET_CURRENT_QUIZ_QUESTION = "SET_CURRENT_QUIZ_QUESTION";
export const SET_ASSIGNMENT_SUBMISSION_GRADEBOARD =
  "SET_ASSIGNMENT_SUBMISSION_GRADEBOARD";
export const ADD_GRADEBOARD_FILE_VS_IMAGE_MAP =
  "ADD_GRADEBOARD_FILE_VS_IMAGE_MAP";
export const DELETE_GRADEBOARD_FILE_VS_IMAGE_MAP =
  "DELETE_GRADEBOARD_FILE_VS_IMAGE_MAP";
export const SET_GRADEBOARD_ACTIVE_LAYER_ID = "SET_GRADEBOARD_ACTIVE_LAYER_ID";
export const SET_GRADEBOARD_16X9_DIMENSION = "SET_GRADEBOARD_16X9_DIMENSION";
// Student type

export const SET_STUDENTS_CURSEWORK = "SET_STUDENTS_CURSEWORK";
export const SET_CURRENT_STUDENT_SUBMISSION_DETAILS =
  "SET_CURRENT_STUDENT_SUBMISSION_DETAILS";
export const SET_CURRENT_STUDENT_COMMENTS = "SET_CURRENT_STUDENT_COMMENTS";
export const SET_REMOVE_ASSIGNMENT = "SET_REMOVE_ASSIGNMENT";
export const SET_ASSIGNMENT_COMMENTS = "SET_ASSIGNMENT_COMMENTS";
export const SET_ASSIGNMED_REPLY_COMMENTS = "SET_ASSIGNMED_REPLY_COMMENTS";
export const SET_CURRENT_STUDENT_ASSIGNMENT = "SET_CURRENT_STUDENT_ASSIGNMENT";
export const SET_CURRENT_STUDENT_ASSIGNMENT_FILE =
  "SET_CURRENT_STUDENT_ASSIGNMENT_FILE";
export const SET_ASSIGNMENT_STUDENT_DETAILS = "SET_ASSIGNMENT_STUDENT_DETAILS";
export const SET_ALL_ASSIGNMENT_REPORT = "SET_ALL_ASSIGNMENT_REPORT";
export const SET_LATEST_SUBMISSION_DETAILS = "SET_LATEST_SUBMISSION_DETAILS";
export const SET_SUBMISSION_FILES = "SET_SUBMISSION_FILES";
export const SET_ALL_SUBMISSION_LAST_COMMENT =
  "SET_ALL_SUBMISSION_LAST_COMMENT";
export const SET_ALL_SUBMISSION_FILES = "SET_ALL_SUBMISSION_FILES";
export const SET_LATEST_SUBMISSION_DETAILS_META =
  "SET_LATEST_SUBMISSION_DETAILS_META";
export const REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA =
  "REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA";
export const SET_ALL_LECTURES = "SET_ALL_LECTURES";
export const SET_LINK_CONNECT_PARTICIPANTS = "SET_LINK_CONNECT_PARTICIPANTS";
export const SET_STUDENT_ASSIGNMENT_DATA = "SET_STUDENT_ASSIGNMENT_DATA";
export const SET_CURRENT_QUESTION_QUIZ_AGGREGATE =
  "SET_CURRENT_QUESTION_QUIZ_AGGREGATE";

export const SET_CURRENT_QUESTION_QUIZ_LIST = "SET_CURRENT_QUESTION_QUIZ_LIST";
export const SET_CURRENT_QUESTION_QUIZ_OPTION =
  "SET_CURRENT_QUESTION_QUIZ_OPTION";

export const SET_CURRENT_QUESTION_QUIZ_MARKED_OPTION =
  "SET_CURRENT_QUESTION_QUIZ_MARKED_OPTION";
export const RESET_STUDENTS_QUIZ_PERFORMANCE_DATA =
  "RESET_STUDENTS_QUIZ_PERFORMANCE_DATA";

// student live quiz
export const SET_STUDENT_QUIZ = "SET_STUDENT_QUIZ";
export const SET_QUIZ_RESULT_ANALYTICS = "SET_QUIZ_RESULT_ANALYTICS";
export const SET_STUDENT_QUIZ_DATA = "SET_STUDENT_QUIZ_DATA";
export const SET_STUDENT_QUIZ_QUESTION = "SET_STUDENT_QUIZ_QUESTION";
export const STORE_STUDENT_QUIZ_QUESTION = "STORE_STUDENT_QUIZ_QUESTION";
export const SET_STUDENT_QUIZ_META_DATA = "SET_STUDENT_QUIZ_META_DATA";
export const TOGGLE_STUDENT_QUIZ_LOADING = "TOGGLE_STUDENT_QUIZ_LOADING";
export const SET_PRACTICE_QUIZ_BOX = "SET_PRACTICE_QUIZ_BOX";
export const SET_ONGOING_QUIZ_DATA = "SET_ONGOING_QUIZ_DATA";
export const SET_SUBMITTED_QUIZ_DATA = "SET_SUBMITTED_QUIZ_DATA";
export const SET_STUDENT_QUIZ_QUESTION_STATUS =
  "SET_STUDENT_QUIZ_QUESTION_STATUS";

//student quiz timer
export const SET_STUDENT_QUIZ_TIMER_START = "SET_QUIZ_TIMER_START";
export const SET_STUDENT_QUIZ_TIMER_STOP = "SET_STUDENT_QUIZ_TIMER_STOP";
export const SET_STUDENT_QUIZ_TIMER_RESET = "SET_QUIZ_TIMER_RESET";
export const UPDATE_STUDENT_QUIZ_TIMER = "UPDATE_QUIZ_TIMER";
export const SET_STUDENT_QUIZ_TIMER_DETAILS = "SET_STUDENT_QUIZ_TIMER_DETAILS";
export const CLEAR_STUDENT_QUIZ_DATA = "CLEAR_STUDENT_QUIZ_DATA";
export const SHOW_STUDENT_QUIZ_SUBMISSION_MODAL =
  "SHOW_STUDENT_QUIZ_SUBMISSION_MODAL";
export const HIDE_STUDENT_QUIZ_SUBMISSION_MODAL =
  "HIDE_STUDENT_QUIZ_SUBMISSION_MODAL";
export const SET_PRACTICE_AGGREGATE_METRIC = "SET_PRACTICE_AGGREGATE_METRIC";
export const UPDATE_STUDENT_QUIZ_META_DATA = "UPDATE_STUDENT_QUIZ_META_DATA";
export const TEACHER_REPORT_QUIZ_DATA = "TEACHER_REPORT_QUIZ_DATA";
export const SET_CURRENT_REPORT_TAB = "SET_CURRENT_REPORT_TAB";
// teacher course planner
export const SET_PREVIEW_DETAILS = "SET_PREVIEW_DETAILS ";
export const SET_COURSEPLANNER_LIST = "SET_COURSEPLANNER_LIST ";
export const SET_COURSEPLANNER_UNCAT_LIST = "SET_COURSEPLANNER_UNCAT_LIST ";
export const SET_COURSEPLANNER_ORDERING = "SET_COURSEPLANNER_ORDERING ";
export const PUSH_TO_COURSEPLANNER_CONTENT = "PUSH_TO_COURSEPLANNER_CONTENT ";
export const SET_UNCAT_LIVE_LECTURES = "SET_UNCAT_LIVE_LECTURES ";
export const SET_UNCAT_LIST_ORDERING = "SET_UNCAT_LIST_ORDERING ";
export const PUSH_TO_COURSEPLANNER_UNCAT_CONTENT =
  "PUSH_TO_COURSEPLANNER_UNCAT_CONTENT ";

// live quiz
export const SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA =
  "SET_TEACHER_LIVE_QUIZ_LEADERBOARD_DATA ";
export const SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA =
  "SET_STUDENT_LIVE_QUIZ_LEADERBOARD_DATA ";
export const SET_LIVE_QUIZ_QUESTION_DETAILS = "SET_LIVE_QUIZ_QUESTION_DETAILS";
export const SET_LIVE_RESULT_POPUP_DATA = "SET_LIVE_RESULT_POPUP_DATA";
export const SET_CREATE_QUESTION_POPUP_INITIAL_DATA =
  "SET_CREATE_QUESTION_POPUP_INITIAL_DATA ";
export const SET_CREATE_QUESTION_POPUP_FILES_DATA =
  "SET_CREATE_QUESTION_POPUP_FILES_DATA ";
export const SET_STUDENT_LIVE_QUESTION_POPUP_DATA =
  "SET_STUDENT_LIVE_QUESTION_POPUP_DATA ";
export const SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA =
  "SET_STUDENT_LIVE_QUESTION_RESPONSE_RECORDED_POPUP_DATA";
export const RESET_LIVE_QUIZ_DATA = "RESET_LIVE_QUIZ_DATA ";
export const SET_UNATTEMPTED_USERS = "SET_UNATTEMPTED_USERS";
export const SET_STOP_LEADERBOARD_SHARING_POPUP =
  "SET_STOP_LEADERBOARD_SHARING_POPUP ";

// instant live-class
export const SET_INSTANT_LIVECLASS_SESSION_ID =
  "SET_INSTANT_LIVECLASS_SESSION_ID";

// batches
export const SET_LECTURES_OF_BATCH = "SET_LECTURES_OF_BATCH";
export const SET_CLASSES_OF_BATCH = "SET_CLASSES_OF_BATCH";
export const SET_SELECTED_BATCH = "SET_SELECTED_BATCH";
export const SET_ADMIN_BATCH_LIST = "SET_ADMIN_BATCH_LIST";
export const UPDATE_BATCH_LIVE_CLASS_STATUS = " UPDATE_BATCH_LIVE_CLASS_STATUS";
export const SET_BATCH_LIVE_LECTURE = "SET_BATCH_LIVE_LECTURE";
export const REMOVE_BATCH_CLASS = "REMOVE_BATCH_CLASS";
export const ADD_BATCH_CLASS = "ADD_BATCH_CLASS";

//institutes
export const SET_INSTITUTE_ADMINS_LIST = "SET_INSTITUTE_ADMINS_LIST";
export const SET_INSTITUTE_CLASSES_LIST = "SET_INSTITUTE_CLASSES_LIST";
export const SET_INSTITUTE_CLASSES_OBJECT = "SET_INSTITUTE_CLASSES_OBJECT";
export const SET_INSTITUTE_CLASS_BATCHES_LIST =
  "SET_INSTITUTE_CLASS_BATCHES_LIST";
