import dayjs from "dayjs";

const DAY = 1000 * 60 * 60 * 24;

export const getTime = (datetime, format) => {
  try {
    const oneDayAgo = Date.now() - DAY;

    if (Date(datetime) > oneDayAgo) {
      return dayjs(datetime).fromNow();
    }

    return dayjs(datetime).format(format);
  } catch {
    return "";
  }
};

export const groupByDate = (listOfObjects = [], key) => {
  return listOfObjects.reduce((acc, obj) => {
    const date = dayjs(obj[key]).format("YYYY-MM-DD");
    if (acc[date]) {
      acc[date].push(obj);
    } else {
      acc[date] = [obj];
    }
    return acc;
  }, {});
};
