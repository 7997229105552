import store from "../store";
import { OPTIONS } from "./constants";

// false values float to the top

export const teacherSort = [
  (participant) =>
    !(
      participant.broadcastType === OPTIONS.WHITEBOARD ||
      participant.broadcastType === OPTIONS.SLIDESHARE ||
      participant.broadcastType === OPTIONS.SCREENSHARE
    ),
  "videoMuted",
  "audioMuted",
  (participant) => new Date(0) - new Date(participant.lastStartedSpeaking),
  "userName",
];

export const studentSort = [
  "audioMuted",
  (participant) => !participant.handRaised,
  (participant) => new Date(0) - new Date(participant.lastStartedSpeaking),
  "userName",
];
