import jpgIcon from "./assets/jpg.svg";
import mp3Icon from "./assets/mp3.svg";
import pptIcon from "./assets/ppt.svg";
import zipIcon from "./assets/zip.svg";
import fileIcon from "./assets/file.svg";
import pdfIcon from "./../../assets/icons/pdfIcon.svg";
import xlsIcon from "./../../assets/icons/xlsIcon.svg";
import docIcon from "./../../assets/icons/docIcon.svg";
import pngIcon from "./assets/pngFileIcon.svg";
import bmpIcon from "./assets/bmpFileIcon.svg";

import youtubeIcon from "./../../assets/icons/youtubeVideoIcon.svg";
import { filesKindType } from "../../utils/tinyUtils";
import dayjs from "dayjs";
import { getHumanReadableSize } from "../../utils/fileSystem";
import store from "../../store";
import { getFolderChildElementList } from "../../store/actions/fileSystemActions";

export const getIconOfFile = (filename, kind = "") => {
  let fileExtension = getFileExtension(filename);

  if (kind === filesKindType.link) {
    // youtube
    fileExtension = "youtube";
  }

  switch (fileExtension) {
    case "pdf":
      return pdfIcon;
    case "jpg":
      return jpgIcon;
    case "jpeg":
      return jpgIcon;
    case "mp3":
      return mp3Icon;
    case "ppt":
      return pptIcon;
    case "pptx":
      return pptIcon;
    case "xls":
      return xlsIcon;
    case "xlsx":
      return xlsIcon;
    case "zip":
      return zipIcon;
    case "doc":
      return docIcon;
    case "docx":
      return docIcon;
    case "bmp":
      return bmpIcon;
    case "png":
      return pngIcon;
    case "youtube":
      return youtubeIcon;
    default:
      return fileIcon;
  }
};

export const getSubtitleOfFile = (filename, kind = "") => {
  let fileExtension = getFileExtension(filename);
  if (kind === filesKindType.link) {
    // youtube
    fileExtension = "youtube";
  }
  switch (fileExtension) {
    case "pdf":
      return "PDF Document";
    case "jpg":
      return "Image File";
    case "jpeg":
      return "Image File";
    case "png":
      return "Image File";
    case "bmp":
      return "Image File";
    case "mp3":
      return "Audio File";
    case "ppt":
      return "Presentation File";
    case "pptx":
      return "Presentation File";
    case "xls":
      return "Spreadsheet Document";
    case "xlsx":
      return "Spreadsheet Document";
    case "zip":
      return "Compressed File";
    case "doc":
      return "Word Document";
    case "docx":
      return "Word Document";
    case "youtube":
      return "Youtube Video";
    default:
      return "File";
  }
};

export const getFileExtension = (fileName) => {
  if (!fileName) return "";
  return fileName?.split(".").slice(-1)[0].toLowerCase();
};

export const getFileExtensionFromMimeType = (fileMimeType) => {
  if (!fileMimeType) return "";
  return fileMimeType?.split("/").slice(-1)[0].toLowerCase();
};

export const isVideoFromMimeType = (fileMimeType) => {
  if (!fileMimeType) return "";
  return fileMimeType?.split("/")[0].toUpperCase() === "VIDEO";
};

export const getFileTooltipInfo = (fileObj, isInDashboardArea) => {
  let fileToolTipObj = {};
  const isYoutube = fileObj.kind === filesKindType.link;
  fileToolTipObj["fileName"] = fileObj.name;
  fileToolTipObj["fileType"] = getFileExtension(fileObj.name).toUpperCase();
  fileToolTipObj["dateLoaded"] = dayjs(
    fileObj.created_at || fileObj.created_time
  ).format("D/M/YYYY h:m a");
  fileToolTipObj["fileLocation"] = fileObj.file_location;
  fileToolTipObj["deletedLocation"] = fileObj.file_location;
  fileToolTipObj["deletedDate"] = dayjs(fileObj.last_deleted_at).format(
    "D/M/YYYY h:m a"
  );
  fileToolTipObj["sizeOfFile"] = getHumanReadableSize(fileObj.file_size);
  const slashIndex = fileToolTipObj.deletedLocation?.search("/");
  const newLocation = fileToolTipObj.deletedLocation?.slice(slashIndex + 1);
  const fileDeleteData = `\nDeleted Path: ${
    fileToolTipObj.deletedLocation?.startsWith(".user")
      ? "My Files/" + newLocation
      : "Class Files/" + fileToolTipObj.deletedLocation
  }\nDeleted Date: ${fileToolTipObj.deletedDate}`;
  const fileLocationPath = `\nFile location: ${fileToolTipObj.fileLocation}`;
  const fileTitleForAll = `File name: ${fileToolTipObj.fileName}\nItem type: ${
    fileToolTipObj.fileType
  }\nDate loaded: ${fileToolTipObj.dateLoaded}\nSize: ${
    fileToolTipObj.sizeOfFile
  }${isInDashboardArea ? fileLocationPath : ""}`;
  const fileTitleForTrash = `File name: ${fileToolTipObj.fileName}\nItem type: ${fileToolTipObj.fileType}\nDate loaded: ${fileToolTipObj.dateLoaded}\nSize: ${fileToolTipObj.sizeOfFile}${fileDeleteData}`;
  const fileTitle =
    fileObj.currentScreenContext === "TRASH_SCREEN_CONTEXT"
      ? fileTitleForTrash
      : fileTitleForAll;
  const youtubeTitle = `File name: ${fileToolTipObj.fileName}\nDate loaded: ${fileToolTipObj.dateLoaded}`;
  return isYoutube ? youtubeTitle : fileTitle;
};

export const getFolderTooltipInfo = (folderObj) => {
  let folderToolTipObj = {};
  folderToolTipObj["folderName"] = folderObj.name;
  folderToolTipObj["dateLoaded"] = dayjs(
    folderObj.started_at ||
      folderObj.schedule_start ||
      folderObj.created_at ||
      folderObj.created_time
  ).format("D/M/YYYY h:m a");
  folderToolTipObj["deletedLocation"] = folderObj.file_location;
  folderToolTipObj["deletedDate"] = dayjs(folderObj.last_delated_date).format(
    "D/M/YYYY h:m a"
  );
  const folderDeleteData = `\nDeleted Path: ${folderToolTipObj.deletedLocation}\nDeleted Date: ${folderToolTipObj.deletedDate}`;

  // folderToolTipObj["childrenFolders"] = getFileExtension(
  //   folderObj.name
  // ).toUpperCase();
  // folderToolTipObj["childrenFiles"] = getHumanReadableSize(folderObj.file_size);
  const folderToolTip = `Folder name: ${
    folderToolTipObj.folderName
  }\nDate loaded: ${folderToolTipObj.dateLoaded}${
    folderObj.currentScreenContext === "TRASH_SCREEN_CONTEXT"
      ? folderDeleteData
      : ""
  }`;
  return folderToolTip;
};

export const getFileAndDocTypeTooltip = (fileObj) => {
  let fileToolTipObj = {};
  fileToolTipObj["fileName"] = fileObj.name;
  fileToolTipObj["docType"] = getSubtitleOfFile(fileObj.name, fileObj.kind);
  const tooltipText = `File name: ${fileToolTipObj.fileName}\nDocument Type: ${fileToolTipObj.docType}`;
  return tooltipText;
};
