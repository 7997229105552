import React from "react";
import { createPortal } from "react-dom";

const AlertPopup = ({
  popupTitle,
  popupMessage,
  popupMessageExtra1,
  onCancel,
}) => {
  return createPortal(
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: "var(--zindex-alert)",
        backdropFilter: "blur(7px)",
        WebkitBackdropFilter: "blur(7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => onCancel()}
    >
      <div
        style={{
          // top: "40%",
          // left: "40%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#2A2E43",
          width: 330,
          borderRadius: 12,
          padding: "20px",
          // position: "absolute",
          zIndex: 1001,
        }}
      >
        <p
          style={{
            fontFamily: "GibsonSemiBold",
            fontSize: 24,
            color: "#FFFFFF",
          }}
        >
          {popupTitle}
        </p>
        <p
          style={{
            color: "#FFFFFF",
            fontFamily: "PoppinsRegular",
            fontSize: 14,
            marginTop: 20,
            lineHeight: 1.8,
          }}
        >
          {popupMessage}
        </p>
        {popupMessageExtra1 && (
          <p
            style={{
              color: "#FFFFFF",
              fontFamily: "PoppinsRegular",
              fontSize: 14,
              marginTop: 20,
              lineHeight: 1.8,
            }}
          >
            {popupMessageExtra1}
          </p>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <button
            style={{
              backgroundColor: "#17C8AF",
              color: "#FFFFFF",
              fontFamily: "GibsonSemiBold",
              fontSize: 13,
              textTransform: "uppercase",
              borderWidth: 0,
              padding: 12,
              minWidth: 60,
              margin: 10,
              borderRadius: 12,
            }}
            onClick={onCancel}
          >
            Close
          </button>
        </div>
      </div>
    </div>,
    document.querySelector("body #modal-root")
  );
};

export default AlertPopup;
