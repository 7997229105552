export const liveVideoStateOptions = {
  fullScreen: "full-screen",
  bottomRight: "bottom-right",
  videoOff: "video-off",
};

export const paperboardsLayerBelongsTo = {
  whiteboard: "whiteboard",
  slideboard: "slideboard",
  submissionboard: "submissionboard",
  studentNotebook: "student-notebook",
};

export const liveClassFilesSelectedOption = {
  SELECTED_NONE: "none",
  SELECTED_FILES: "files",
  SELECTED_SLIDE_SHARE: "Media Share",
  SELECTED_WHITE_BOARD: "White Board",
  SELECTED_SCREEN_SHARE: "Screen Share",
};

export const slidePopupClickedFrom = {
  whiteboard: "whiteboard",
  slideboard: "slideboard",
};

export const studentVideoButtons = {
  cumpolsory: "compulsory",
  optional: "optional",
  off: "off",
};

export const liveClassLHSTabKeys = {
  presentationKey: "SMARTBOARD",
  peopleKey: "CLASSROOM",
  livequizKey: "LIVEQUIZ",
  submissionsKey: "CLASSWORK",
};

export const paperjsParent = {
  teacher: "teacher",
  student: "student",
};

export const studentNotebookUtilityButtonsType = {
  delete: "delete",
  move: "move",
  zoomIn: "zoom-in",
  zoomOut: "zoom-out",
};

export const filesKindType = {
  file: "file",
  folder: "folder",
  link: "link",
};

export const filesStatus = {
  active: "active",
  trash: "trash",
  permanentlyDeleted: "permanently_deleted",
};

export const smartboardSupportedImageMimeTypes = [
  "image/bmp",
  "image/jpg",
  "image/jpeg",
  "image/png",
];

export const paperTools = {
  pen: "pen",
  move: "move",
  select: "select", // handles pan/resize of element
  circle: "circle",
  rectangle: "rectangle",
  triangle: "triangle",
  freeForm: "freeform",
  delete: "delete",
  pan: "pan",
};

export const connectionError = {
  socket: "socket",
  jitsi: "jitsi",
  socketAndJitsi: "socket-and-jitsi",
};

export const liveQuizStatus = {
  RESPONSE_ACTIVE: "RESPONSE_ACTIVE",
  RESPONSE_INACTIVE: "RESPONSE_INACTIVE",
  ANALYTICS_ACTIVE: "ANALYTICS_ACTIVE",
  ENDED: "ENDED",
};

export const liveQuizPopupStages = {
  [liveQuizStatus.RESPONSE_ACTIVE]: "LIVE",
  [liveQuizStatus.RESPONSE_INACTIVE]: "ENDED",
  [liveQuizStatus.ANALYTICS_ACTIVE]: "RESULT_PUBLISHED",
  [liveQuizStatus.ENDED]: "CLOSED",
};

export const liveQuizType = {
  MULTI_CHOICE: "MULTI_CHOICE",
  TRUE_FALSE: "TRUE_FALSE",
  POLL: "POLL",
};

export const mediaPreviewerSupportedMimeTypes = ["video/mp4"];
