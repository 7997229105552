import axios from "axios";
import { deepClone } from "./misc";

const makeFileUploadRequest = async (
  type,
  url,
  file,
  uploadProgressEventHandler,
  cancelToken = undefined,
  uploadRequestData = ""
) => {
  if (!type || !url) {
    return {
      error: true,
      logout: false,
      message: "Something went wrong. Please try again.",
      status: 400,
    };
  }

  if (type !== "PUT" && type !== "POST") {
    return {
      error: true,
      logout: false,
      message: "Something went wrong. Please try again.",
      status: 400,
    };
  }
  let headers = { "Content-Type": file.type };
  let data;
  if (type === "POST") {
    // Reference: https://stackoverflow.com/questions/38458570/multer-uploading-array-of-files-fail
    headers["Content-Type"] = "multipart/form-data";

    data = new FormData();

    Object.keys(uploadRequestData).forEach((key) => {
      data.append(key, uploadRequestData[key]);
    });

    data.append("file", file);
  } else {
    data = deepClone(file);
  }

  try {
    let response;
    if (type === "PUT") {
      response = await axios.put(url, data, {
        headers,
        onUploadProgress: uploadProgressEventHandler,
        cancelToken: cancelToken,
      });
    } else if (type === "POST") {
      response = await axios.post(url, data, {
        headers,
        onUploadProgress: uploadProgressEventHandler,
        cancelToken: cancelToken,
      });
    }

    return {
      error: false,
      status: response.status,
    };
  } catch (error) {
    return {
      error: true,
      status: error?.response?.status || 404,
    };
  }
};

export default makeFileUploadRequest;
