import React, { Component } from "react";
import { connect } from "react-redux";
import "../../utils/Styles/aspect.css";
import "./StudentVideoRequestPopupStyles.css";
import { ReactComponent as VideoOn } from "../../components/Live-class-tabs/assets/video-icon.svg";
import { ReactComponent as VideoOff } from "../../components/Live-class-files/assets/videoOff.svg";

import {
  cleanupJitsiPreviewTracks,
  getUserMediaPreview,
  muteMyVideo,
  muteMyVideoPreview,
} from "../../store/actions/jitsiActions";
import { VIDEO } from "../../utils/constants";

import VideoPreview from "../StartNowPopup/VideoPreview";
import {
  acceptMicOrVideoRequest,
  rejectMicOrVideoRequest,
} from "../../store/actions/socketActions";
import { getParticipantByUserId } from "../../utils/jitsi";
import { isNil } from "lodash";

class StudentVideoRequestPopup extends Component {
  state = {};

  componentDidMount() {
    const { myVideoMuted } = this.props;

    if (!myVideoMuted) {
      this.props.acceptMicOrVideoRequest(this.props.showRequestingVideo);
      return;
    }

    this.props.getUserMediaPreview(VIDEO, false);
  }

  componentWillUnmount() {
    this.props.cleanupJitsiPreviewTracks();
  }

  toggleVideoPreview = async () => {
    this.props.muteMyVideoPreview(!this.props.myVideoPreviewMuted);
  };

  acceptRequest = () => {
    this.props.muteMyVideo(false);
    this.props.acceptMicOrVideoRequest(this.props.showRequestingVideo);
  };

  rejectRequest = () => {
    this.props.muteMyVideo(true);
    this.props.rejectMicOrVideoRequest(this.props.showRequestingVideo);
  };

  render() {
    const {
      myVideoPreviewTrack,
      myVideoPreviewMuted,
      videoRequestedParticipant,
    } = this.props;

    let requestingTeacherParticipant;
    if (!isNil(videoRequestedParticipant)) {
      requestingTeacherParticipant = getParticipantByUserId(
        videoRequestedParticipant.requesting_teacher_id
      );
    }

    return (
      <div className="Video_Request_Popup_Modal_Background">
        <div
          className="Video_Request_Popup_Modal_Content"
          style={{ position: "relative" }}
        >
          <p className="Video_Request_Popup_Title">Video Request</p>
          <div className={"Video_Request_Popup_Body"}>
            <p className="Video_Request_Popup_Text">
              {`Teacher ${
                requestingTeacherParticipant?.userName
                  ? requestingTeacherParticipant.userName
                  : ""
              } has requested your video, It might be important. You can preview the video before you share it.`}
            </p>
            <div className="Video_Request_Popup_Preview_Outer">
              <div className="parent-view-16x9">
                <div className="view-16x9">
                  <div
                    className="Start_Now_Popup_Meeting_Video_Space"
                    onClick={this.toggleVideoPreview}
                  >
                    {myVideoPreviewMuted ? (
                      <>
                        <VideoOn
                          style={{ width: "100%", height: "30%" }}
                          stroke="#17c8af"
                        />
                        <p style={{ opacity: "0.47", marginTop: "20px" }}>
                          Click to enable video
                        </p>
                      </>
                    ) : (
                      <>
                        <VideoPreview
                          objectFit="cover"
                          trackType="video-preview"
                        />
                        <VideoOff
                          onClick={() => {
                            this.setState({ video: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "5px",
                            width: "20%",
                            height: "20%",
                          }}
                          stroke="#78849E"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Video_Request_Popup_Footer_Buttons">
              <button
                style={{
                  backgroundColor: myVideoPreviewMuted
                    ? "var(--green)"
                    : "var(--gray)",
                }}
                onClick={this.rejectRequest}
                className="button"
              >
                REJECT
              </button>
              <button
                disabled={!myVideoPreviewTrack}
                style={{
                  backgroundColor: !!myVideoPreviewTrack
                    ? !myVideoPreviewMuted
                      ? "var(--green)"
                      : "var(--gray)"
                    : "var(--gray)",
                }}
                onClick={this.acceptRequest}
                className="button"
              >
                SHARE VIDEO
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ jitsi, klass, user, liveClass }) => ({
  myVideoMuted: jitsi.myVideoMuted,
  showRequestingVideo: liveClass.showRequestingVideo,
  myVideoPreviewTrack: jitsi.myVideoPreviewTrack,
  myVideoPreviewMuted: jitsi.myVideoPreviewMuted,
  videoRequestedParticipant:
    liveClass.videoRequestedParticipantIdList[jitsi.myParticipantId],
});

const mapDispatchToProps = {
  muteMyVideo,
  acceptMicOrVideoRequest,
  rejectMicOrVideoRequest,
  getUserMediaPreview,
  muteMyVideoPreview,
  cleanupJitsiPreviewTracks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentVideoRequestPopup);
