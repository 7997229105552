export const trackType = {
  LOGIN_OTP: "LOGIN_OTP",
  LOGIN_PASSWORD_OR_OTP: "LOGIN_PASSWORD_OR_OTP",
  START_NOW: "START_NOW",
  JOIN_NOW: "JOIN_NOW",
  START_LIVE_CLASS_NOW: "START_LIVE_CLASS_NOW",
  ENTER_WITH_VIDEO: "ENTER_WITH_VIDEO",
  ENTER_WITHOUT_VIDEO: "ENTER_WITHOUT_VIDEO",
  LIVECLASS_TAB_SWITCH: "LIVECLASS_TAB_SWITCH",
  VIDEO_ON: "VIDEO_ON",
  VIDEO_OFF: "VIDEO_OFF",
  TEACHER_MUTED_PARTICIPANT_VIDEO: "TEACHER_MUTED_PARTICIPANT_VIDEO",
  SCREENSHARE_STARTED: "SCREENSHARE_STARTED",
  SCREENSHARE_STOPPED: "SCREENSHARE_STOPPED",
  BROADCAST_OPTION_CHANGED: "BROADCAST_OPTION_CHANGED",
  MAINSCREEN_OPTION_CHANGED: "MAINSCREEN_OPTION_CHANGED",
  RECORDING_START: "RECORDING_START",
  RECORDING_PAUSE: "RECORDING_PAUSE",
  RECORDING_RESUME: "RECORDING_RESUME",
  RHS_PEOPLE_GRID_MODE: "RHS_PEOPLE_GRID_MODE",
  RHS_PEOPLE_LIST_MODE: "RHS_PEOPLE_LIST_MODE",
  LEAVE_SESSION: "LEAVE_SESSION",
  END_SESSION_FOR_ALL: "END_SESSION_FOR_ALL",
  LECTURE_VIDEO_PLAYING: "LECTURE_VIDEO_PLAYING",
  LECTURE_VIDEO_PAUSED: "LECTURE_VIDEO_PAUSED",
  M2_TAB_SWITCHED: "M2_TAB_SWITCHED"
};
