import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { isEmpty, isEqual, isNil } from "lodash";

import {
  setLastVisitedRoute,
  setSidebarActiveOption,
} from "../../store/actions/miscellaneousActions";
import { isMobileBrowser, setPerformLogout } from "../../store/actions/utils";
import LiveClassAudios from "../LiveClassAudios/LiveClassAudios";
import {
  recordingBotLiveClassRawPath,
  testingStudentBotLiveClassRawPath,
  routes,
  screenNames,
  testingTeacherBotLiveClassRawPath,
} from "./routes";
import { cleanupJitsi } from "../../store/actions/jitsiActions";
import {
  cleanupBeforeLeaving,
  cleanupBothJitsis,
  cleanupLiveClass,
  resetLiveClass,
  setShowKickOutPopup,
  setShowRateSessionPopup,
} from "../../store/actions/liveClassActions";
import { addSocketTransferListener } from "../../store/actions/socketActions";
import StudentRateSessionPopup from "../RateSessionPopup/StudentRateSessionPopup";
import { rateTheSession } from "../../store/actions/lectureActions";
import { SentOutOfClassPopup } from "../StudentLiveClassPopups/SentOutOfClassPopup";
import LoaderWrapper from "../Loader";
import StudentVideoRequestPopup from "../StudentVideoRequestPopup/StudentVideoRequestPopup";
import AudioRequestPopup from "../StudentLiveClassPopups/AudioRequestPopup";
import ScreenShareRequestPopup from "../StudentLiveClassPopups/ScreenShareRequestPopup";
import DetailsPopup from "../FileBrowser/FileOptionsPopups/DetailsPopup";
import DeleteFilePopup from "../FileBrowser/FileOptionsPopups/DeleteFilePopup";
import MoveAndCopyPopup from "../FileBrowser/FileOptionsPopups/MoveAndCopyPopup";
import ShareFileFolderPopup from "../FileBrowser/FileOptionsPopups/ShareFileFolderPopup";
import { RECORDING_BOT, STUDENT } from "../../utils/constants";
import { pick } from "lodash";
import { createUserTrakingProfile, initTracking } from "../../utils/Tracking";
import OverlayScrollbars from "overlayscrollbars";
import instantLectureUtils from "../InstantLectureModule/InstantLectureUtils";
import { instantLecture } from "../../store/actions/instantLectureActions";
import { clearUserDetails } from "../../store/actions/userActions";
import { modules } from "../AppRouter/routes";
import routerUtils from "./routerUtils";

class AppRouterSwitch extends Component {
  state = {
    loading: true,
  };

  componentDidMount = () => {
    if (window.location.host.includes("linkclassroom.com")) {
      this.props.history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });

      ReactGA.pageview(this.props.location.pathname);
    }

    /**
     * if user is in mobile browser and using screens like
     * /classroom etc. which needs desktop view, and decides
     * to reload the page, then enable the desktop view again.
     */
    if (
      isMobileBrowser() &&
      !routerUtils()
        .path(this.props.location?.pathname)
        .matchesTo([
          screenNames.loginPage,
          screenNames.teacherMobile,
          screenNames.mobileStudent,
        ])
    ) {
      this.enableDesktopView();
    }

    /**
     * All bot URL's should be compared using 'location.pathname.match' only
     */

    if (
      this.props.location.pathname.match(recordingBotLiveClassRawPath) ||
      this.props.location.pathname.match(testingTeacherBotLiveClassRawPath) ||
      this.props.location.pathname.match(testingStudentBotLiveClassRawPath) ||
      routerUtils()
        .path(this.props.location?.pathname)
        .matchesTo([
          screenNames.instantLiveClass,
          screenNames.studentInstantLecture,
          screenNames.iframeRenderer,
          screenNames.studentLiveClassWithCode,
        ])
    ) {
      //ignore
    } else if (localStorage.getItem("sessionId")) {
      /**
       * This means user is logged in, we used to push user to /classroom screen
       * if they are logged-in.
       * But as of @see 02-12-2022, there won't be auto re-direct to /classroom anymore.
       * @expected_behavior should be:
       * 1. "/" should be the default screen
       * 2. when user clicks on login buttons then one of these 2 things would happen:
       *    a. If logged-in, push them to /classroom
       *    b. If logged-out, take them through the log-in process.
       * 3. If user is in the screen other than login & support page and refreshes
       *    screen then they should be redirected to /classroom
       */
      if (
        !routerUtils()
          .path(this.props.location?.pathname)
          .matchesTo([
            screenNames.supportPage,
            screenNames.loginPage,
            screenNames.teacherMobile,
          ])
      ) {
        if (this.isAdmin()) {
          this.props.setSidebarActiveOption(modules.ADMINISTRATOR);
          this.props.history.push(screenNames.administrator);
        } else {
          this.props.history.push(screenNames.class);
        }
      }
    } else {
      // logged out state
      if (
        ![screenNames.supportPage, screenNames.loginPage].includes(
          this.props.location?.pathname
        )
      ) {
        this.props.history.push(screenNames.loginPage);
      }
    }

    if (
      !routerUtils()
        .path(this.props.location?.pathname)
        .matchesTo([screenNames.loginPage, screenNames.iframeRenderer])
    ) {
      // Attach custom scrollbar to body
      OverlayScrollbars(document.querySelectorAll("body"), {});
    }
    this.setState({ loading: false });
    this.props.addSocketTransferListener(this.leaveSessionAndGoBack);

    // initialize user event tracking
    initTracking();
  };

  componentDidUpdate(prevProps) {
    const { role: userRole } = this.props.user;
    if (!isEqual(prevProps.instituteAdmins, this.props.instituteAdmins)) {
      if (
        !routerUtils()
          .path(this.props.location?.pathname)
          .matchesTo([screenNames.studentLiveClassWithCode])
      ) {
        if (this.isAdmin()) {
          this.props.setSidebarActiveOption(modules.ADMINISTRATOR);
          this.props.history.push(screenNames.administrator);
        }
      }
    }

    if (
      localStorage.getItem("sessionId") &&
      String(userRole).toUpperCase() === STUDENT &&
      isMobileBrowser()
    ) {
      /**
       * if user is loggedIn & is student & using mobile browser, then
       * let them navigate between loginPage & student-mobile page, If they
       * try to visit any other screen than the above two, then redirect them to
       * student mobile page
       */

      if (
        !routerUtils()
          .path(this.props.location?.pathname)
          .matchesTo([screenNames.loginPage, screenNames.mobileStudent])
      ) {
        this.props.history.push(screenNames.mobileStudent);
      }
    }

    if (
      (prevProps.sessionId && !this.props.sessionId) ||
      (!prevProps.performLogout && this.props.performLogout)
    ) {
      if (this.props.location?.pathname !== screenNames.loginPage) {
        window.location.replace(window.location.origin + screenNames.loginPage);
      }

      this.props.setPerformLogout(false);
    }

    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      /**
       * if user comes back to login page, then for some reason
       * the UI gets distorted. To avoid the situation, reload the page
       * as a temporary fix.
       */
      if (
        routerUtils()
          .path(this.props.location?.pathname)
          .matchesTo([screenNames.loginPage])
      ) {
        window.location.reload();
      }

      /**
       * In mobile, if user comes back to these screens from /classroom or any other screens,
       * reset the initial scale from 0.1 to 1 to make the view better
       */
      if (isMobileBrowser()) {
        if (
          routerUtils()
            .path(this.props.location?.pathname)
            .matchesTo([
              screenNames.loginPage,
              screenNames.teacherMobile,
              screenNames.mobileStudent,
            ])
        ) {
          this.disableDesktopView();
        } else {
          this.enableDesktopView();
        }
      }

      this.props.setLastVisitedRoute(prevProps.location?.pathname);

      // if (
      //   this.props.location?.pathname === screenNames.loginPage &&
      //   localStorage.getItem("sessionId")
      // ) {
      //   this.props.history.push(screenNames.class);
      // }

      // extra check to clean jitsi tracks
      if (prevProps.isLiveClassActive && !this.props.isLiveClassActive) {
        setTimeout(() => {
          this.props.cleanupBothJitsis();
        }, 2000);
      }
    }

    //  Exit live class
    if (!prevProps.kickedFromConference && this.props.kickedFromConference) {
      this.leaveSessionAndGoBack();
    }

    if (
      !prevProps.conferenceEnded &&
      this.props.conferenceEnded &&
      !this.props.clickedEndSessionForAll
    ) {
      this.leaveSessionAndGoBack();
    }

    if (!prevProps.socket && this.props.socket) {
      this.props.addSocketTransferListener(this.leaveSessionAndGoBack);
    }

    /** check if userId is available in redux */
    if (!prevProps.userId && this.props.userId) {
      // select properties to attach with user profile
      const obj = pick(this.props.user, [
        "profilePhotoUrl",
        "name",
        "email",
        "role",
        "userSubscription",
        "country",
      ]);

      createUserTrakingProfile(this.props.userId, obj);
    }
  }

  isAdmin = () => {
    const { instituteAdmins, userId } = this.props;
    if (!isNil(instituteAdmins)) {
      const instituteAdminsMap = instituteAdmins.map((admin) => admin.id);
      return instituteAdminsMap.includes(userId);
    }
    return false;
  };

  enableDesktopView = () => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width, initial-scale=0.1");
  };

  disableDesktopView = () => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width, initial-scale=1");
  };

  closeSentOutOfClassPopup = () => {
    this.props.setShowKickOutPopup(false);
    this.goBack();
    this.props.resetLiveClass();
  };

  handleSentOutClassPopup = () => {
    this.props.setShowKickOutPopup(true);
    setTimeout(this.closeSentOutOfClassPopup, 5000);
  };

  getClassCodeFromUrl = () => {
    const pathArray = window.location.pathname.split("/");
    return pathArray?.length === 4 &&
      pathArray[1] === "student" &&
      pathArray[2] === "live-class"
      ? pathArray[3]
      : null;
  };

  goBack = () => {
    const { recordingBot, testingBot } = this.props;
    if (recordingBot || testingBot) {
      this.props.history.push(screenNames.loginPage);
      return;
    }

    if (this.getClassCodeFromUrl) {
      this.props.history.replace(screenNames.class);
    }

    if (
      this.props.location?.pathname === screenNames.studentLiveClass ||
      this.props.location?.pathname === screenNames.liveClass
    ) {
      // this.props.history.push("/classroom");
      window.location.reload(); // reload page to reset jitsi connection if it failed from code
    }

    if (instantLectureUtils().is().currentPageBelongsToInstantClassroom()) {
      this.props.history.push(screenNames.loginPage);
      window.location.reload(); // reload page to reset jitsi connection if it failed from code
    }
  };

  showRateSessionPopup = () => {
    this.props.setShowRateSessionPopup(true);
  };

  leaveSessionAndGoBack = async () => {
    const { liveLecture, recordingBot, testingBot } = this.props;

    if (instantLectureUtils().is().currentPageBelongsToInstantClassroom()) {
      const instantLecture = await this.props.instantLecture();
      instantLecture.teacher().resetSession();

      /**
       * this order is important, cause @resetSession action above
       * uses the authentication status of user for resetting,
       * hence we should only clear the user data after @resetSession action
       */
      this.props.clearUserDetails();
    }
    this.props.cleanupBeforeLeaving(this.goBack);

    if (
      this.props.conferenceEnded &&
      !this.props.clickedEndSessionForAll &&
      !recordingBot
    ) {
      this.showRateSessionPopup(liveLecture.id);
    } else if (
      this.props.kickedFromConference &&
      !(recordingBot || testingBot)
    ) {
      this.handleSentOutClassPopup();
    } else {
      this.goBack();
      this.props.resetLiveClass();
    }
  };

  submitSessionRating = (rating) => {
    const { liveLecture } = this.props;
    this.props.rateTheSession(liveLecture.id, rating);
    this.props.setShowRateSessionPopup(false);
    this.goBack();
    this.props.resetLiveClass();
  };

  render() {
    const { loading } = this.state;
    const {
      showRateSessionPopup,
      showKickOutPopup,
      showRequestingVideo,
      showRequestingAudio,
      showRequestingScreenshare,
      showDetailsPopup,
      showDeleteFilePopup,
      showMoveAndCopyFilePopup,
      showShareFileFolderPopup,
      clientBrandingInfo,
    } = this.props;

    if (loading) {
      return <div />;
    }

    const { isLiveClassActive, jitsiConference } = this.props;
    const blurBackground =
      showRateSessionPopup ||
      showKickOutPopup ||
      showDetailsPopup ||
      showMoveAndCopyFilePopup ||
      showShareFileFolderPopup ||
      showDeleteFilePopup;

    return (
      <>
        {/* to update the meta tags with client site details */}
        {!isEmpty(clientBrandingInfo) ? (
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={clientBrandingInfo.favicon}
              sizes="16x16"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={clientBrandingInfo.favicon}
            />
            {/* safari-pinned-tab */}
            <link
              rel="mask-icon"
              href={clientBrandingInfo.favicon}
              color="#5bbad5"
            />
            <meta property="og:url" content={window.location.origin} />
            <meta property="og:image" content={clientBrandingInfo.favicon} />
          </Helmet>
        ) : (
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={"https://linkclassroom.com/mstile-70x70.png"}
              sizes="16x16"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={"https://linkclassroom.com/mstile-70x70.png"}
            />
            {/* safari-pinned-tab */}
            <link
              rel="mask-icon"
              href={"https://linkclassroom.com/mstile-70x70.png"}
              color="#5bbad5"
            />
            <meta property="og:url" content={window.location.origin} />
            <meta
              property="og:image"
              content={"https://linkclassroom.com/mstile-70x70.png"}
            />
          </Helmet>
        )}
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
          className={
            blurBackground ? "blur_background_screen current__scrollbar" : ""
          }
        >
          <React.Suspense
            fallback={<LoaderWrapper loading={true}></LoaderWrapper>}
          >
            <Switch>
              {Object.values(screenNames).map((path, index) => (
                <Route
                  exact
                  key={index}
                  path={path}
                  component={routes[path].component}
                />
              ))}
            </Switch>
          </React.Suspense>
        </div>
        {isLiveClassActive && jitsiConference && <LiveClassAudios />}

        {/* show only one popup at once. order by priority */}
        {showRateSessionPopup ? (
          <StudentRateSessionPopup
            submitSessionRating={this.submitSessionRating}
          />
        ) : showKickOutPopup ? (
          <SentOutOfClassPopup
            onBackgroundClick={this.closeSentOutOfClassPopup}
          />
        ) : showRequestingVideo ? (
          <StudentVideoRequestPopup />
        ) : showRequestingAudio ? (
          <AudioRequestPopup />
        ) : showRequestingScreenshare ? (
          <ScreenShareRequestPopup />
        ) : null}

        {showDetailsPopup && <DetailsPopup location={this.props.location} />}
        {showDeleteFilePopup && (
          <DeleteFilePopup location={this.props.location} />
        )}
        {showMoveAndCopyFilePopup && (
          <MoveAndCopyPopup location={this.props.location} />
        )}
        {showShareFileFolderPopup && (
          <ShareFileFolderPopup location={this.props.location} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  user,
  liveClass,
  jitsi,
  socket,
  klass,
  fileOptions,
  institute,
}) => ({
  sessionId: user.sessionId,
  userId: user.id,
  user: user,
  performLogout: user.performLogout,
  isLiveClassActive: liveClass.isLiveClassActive,
  jitsiConference: jitsi.jitsiConference,
  kickedFromConference: jitsi.kickedFromConference,
  conferenceEnded: jitsi.conferenceEnded,
  socket: socket.socket,
  liveLecture: liveClass.liveLecture,
  showRateSessionPopup: liveClass.showRateSessionPopup,
  showKickOutPopup: liveClass.showKickOutPopup,
  clickedEndSessionForAll: liveClass.clickedEndSessionForAll,
  showRequestingVideo: liveClass.showRequestingVideo,
  showRequestingAudio: liveClass.showRequestingAudio,
  showRequestingScreenshare: liveClass.showRequestingScreenshare,
  showDetailsPopup: fileOptions.showDetailsPopup,
  showDeleteFilePopup: fileOptions.showDeleteFilePopup,
  showMoveAndCopyFilePopup: fileOptions.showMoveAndCopyFilePopup,
  showShareFileFolderPopup: fileOptions.showShareFileFolderPopup,
  recordingBot: user.role?.toUpperCase() === RECORDING_BOT,
  clientBrandingInfo: user.clientBrandingInfo,
  instituteAdmins: institute.instituteAdmins,
});

const mapDispatchToProps = {
  setPerformLogout,
  setLastVisitedRoute,
  setSidebarActiveOption,
  cleanupJitsi,
  resetLiveClass,
  rateTheSession,
  setShowRateSessionPopup,
  setShowKickOutPopup,
  cleanupLiveClass,
  addSocketTransferListener,
  cleanupBeforeLeaving,
  cleanupBothJitsis,
  instantLecture,
  clearUserDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppRouterSwitch));
