import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";
import { COURSEWORK_TYPES, POST_TYPES } from "../../utils/constants";
import {
  PUSH_TO_COURSEPLANNER_CONTENT,
  PUSH_TO_COURSEPLANNER_UNCAT_CONTENT,
  SET_COURSEPLANNER_LIST,
  SET_COURSEPLANNER_ORDERING,
  SET_COURSEPLANNER_UNCAT_LIST,
  SET_PREVIEW_DETAILS,
  SET_UNCAT_LIST_ORDERING,
  SET_UNCAT_LIVE_LECTURES,
} from "../types";
import { errorAlert, getSessionId, logoutProcedure } from "./utils";

export const enablePreview = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_PREVIEW_DETAILS,
    payload: data,
  });
};

export const addCourseWorkType = (
  uncategorized_assignments,
  uncategorized_quizzes,
  uncategorized_files,
  uncategorized_lecture_videos,
  uncategorized_live_lectures
) => {
  const uncat_ass = [];
  uncategorized_assignments.forEach((element) => {
    if (!element.is_deleted) {
      let obj = {
        ...element,
        courseWorkType: COURSEWORK_TYPES.ASSIGNMENT,
      };
      uncat_ass.push(obj);
    }
  });

  const uncat_quiz = [];
  uncategorized_quizzes.forEach((element) => {
    if (element.state !== "REMOVED") {
      let obj = {
        ...element,
        courseWorkType: COURSEWORK_TYPES.QUIZ,
      };
      uncat_quiz.push(obj);
    }
  });

  const uncat_files = uncategorized_files.map((i) => {
    return {
      ...i,
      courseWorkType:
        i.type === "FILE" ? COURSEWORK_TYPES.FILES : COURSEWORK_TYPES.YOUTUBE,
    };
  });

  const uncat_lv = uncategorized_lecture_videos.map((i) => {
    return {
      ...i,
      courseWorkType: COURSEWORK_TYPES.RECORDED_LECTURE,
    };
  });

  const uncat_lc = uncategorized_live_lectures.map((i) => {
    return {
      ...i,
      courseWorkType: COURSEWORK_TYPES.LIVE_LECTURE,
    };
  });

  let uncategorizedContent = [
    ...uncat_ass,
    ...uncat_quiz,
    ...uncat_files,
    ...uncat_lv,
    ...uncat_lc,
  ];

  return uncategorizedContent;
};

export const fetchCoursePlannerList =
  (classId, filter, role) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const apiResponse = await makeApiRequest(
      "GET",
      backendApi,
      `/course-planner/${role}/list?class_id=${classId}&filter=${filter}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    let coursePlannerList = {};
    let {
      topics,
      quizzes,
      assignments,
      ordering,
      files,
      lecture_videos,
      live_lectures,
      uncategorized_assignments,
      uncategorized_quizzes,
      uncategorized_files,
      uncategorized_lecture_videos,
      uncategorized_live_lectures,
    } = apiResponse.response;
    coursePlannerList = topics;

    let uncategorizedContent = addCourseWorkType(
      uncategorized_assignments,
      uncategorized_quizzes,
      uncategorized_files,
      uncategorized_lecture_videos,
      uncategorized_live_lectures
    );

    Object.values(coursePlannerList).forEach((i) => {
      coursePlannerList[i.id].content = [];
      coursePlannerList[i.id].ordering =
        ordering[i.id]?.sort((a, b) => a[1] - b[1]) || [];

      if (assignments[i.id]) {
        const u_assignment = [];
        assignments[i.id].forEach((element) => {
          if (!element.is_deleted) {
            let obj = {
              ...element,
              courseWorkType: COURSEWORK_TYPES.ASSIGNMENT,
            };
            u_assignment.push(obj);
          }
        });
        coursePlannerList[i.id].content = [
          ...coursePlannerList[i.id].content,
          ...u_assignment,
        ];
      }
      if (quizzes[i.id]) {
        const u_quizzes = [];
        quizzes[i.id].forEach((element) => {
          if (element.state !== "REMOVED") {
            let obj = {
              ...element,
              courseWorkType: COURSEWORK_TYPES.QUIZ,
            };
            u_quizzes.push(obj);
          }
        });
        coursePlannerList[i.id].content = [
          ...coursePlannerList[i.id].content,
          ...u_quizzes,
        ];
      }

      if (files[i.id]) {
        const u_files = files[i.id].map((a) => {
          return {
            ...a,
            courseWorkType:
              a.type === "FILE"
                ? COURSEWORK_TYPES.FILES
                : COURSEWORK_TYPES.YOUTUBE,
          };
        });
        coursePlannerList[i.id].content = [
          ...coursePlannerList[i.id].content,
          ...u_files,
        ];
      }

      if (lecture_videos[i.id]) {
        const u_lecture_videos = lecture_videos[i.id].map((a) => {
          return {
            ...a,
            courseWorkType: COURSEWORK_TYPES.RECORDED_LECTURE,
          };
        });
        coursePlannerList[i.id].content = [
          ...coursePlannerList[i.id].content,
          ...u_lecture_videos,
        ];
      }

      if (live_lectures[i.id]) {
        const u_live_lectures = live_lectures[i.id].map((a) => {
          return {
            ...a,
            courseWorkType: COURSEWORK_TYPES.LIVE_LECTURE,
          };
        });
        coursePlannerList[i.id].content = [
          ...coursePlannerList[i.id].content,
          ...u_live_lectures,
        ];
      }
    });

    dispatch({
      type: SET_COURSEPLANNER_LIST,
      payload: coursePlannerList,
    });

    dispatch({
      type: SET_COURSEPLANNER_UNCAT_LIST,
      payload: uncategorizedContent,
    });

    dispatch({
      type: SET_UNCAT_LIVE_LECTURES,
      payload: uncategorized_live_lectures,
    });

    dispatch({
      type: SET_UNCAT_LIST_ORDERING,
      payload: ordering[-1]?.sort((a, b) => a[1] - b[1]) || [],
    });
  };

export const editCourseContentOrder = (body) => async (dispatch, getState) => {
  const sessionId = await dispatch(getSessionId());
  const apiResponse = await makeApiRequest(
    "POST",
    backendApi,
    `/course-planner/entity/edit-order`,
    true,
    {
      ...body,
      topic_id: body.topic_id === -1 ? null : body.topic_id,
    },
    sessionId
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return;
  }

  dispatch({
    type: SET_COURSEPLANNER_ORDERING,
    payload: {
      internal_ordering: apiResponse.response.internal_ordering?.sort(
        (a, b) => a[1] - b[1]
      ),
      topic_id: body.topic_id,
    },
  });
};

export const localReorder =
  (internal_ordering, topic_id) => async (dispatch, getState) => {
    dispatch({
      type: SET_COURSEPLANNER_ORDERING,
      payload: {
        internal_ordering,
        topic_id,
      },
    });
  };

export const pushToContent = (obj, topic_id) => async (dispatch, getState) => {
  dispatch({
    type: PUSH_TO_COURSEPLANNER_CONTENT,
    payload: {
      obj,
      topic_id,
    },
  });
};

export const pushToUncatContent = (obj) => async (dispatch, getState) => {
  dispatch({
    type: PUSH_TO_COURSEPLANNER_UNCAT_CONTENT,
    payload: {
      obj,
    },
  });
};

export const addEntityToSection =
  (entity_id, topic_id, order_below = null) =>
  async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const path =
      order_below === null
        ? `/course-planner/entity/add-to-section?entity_id=${entity_id}&topic_id=${topic_id}`
        : `/course-planner/entity/add-to-section?entity_id=${entity_id}&topic_id=${topic_id}&order_below=${order_below}`;
    const apiResponse = await makeApiRequest(
      "PUT",
      backendApi,
      path,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
  };

export const removeEntityFromSection =
  (entity_id, order_below) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const path =
      order_below === null
        ? `/course-planner/entity/remove-from-section?entity_id=${entity_id}`
        : `/course-planner/entity/remove-from-section?entity_id=${entity_id}&order_below=${order_below}`;
    const apiResponse = await makeApiRequest(
      "PUT",
      backendApi,
      path,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
  };

export const addMediaCoursePlannerTeacher =
  (data) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "/course-planner/add-file",
      true,
      data,
      sessionId
    );

    return apiResponse;
  };

export const getFileDetails = (file_id) => async (dispatch, getState) => {
  const sessionId = await dispatch(getSessionId());
  const apiResponse = await makeApiRequest(
    "GET",
    backendApi,
    `/course-planner/get-file?file_id=${file_id}`,
    true,
    {},
    sessionId
  );

  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return;
  }

  return apiResponse;
};

export const getLectureDetails =
  (lecture_entity_id) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const apiResponse = await makeApiRequest(
      "GET",
      backendApi,
      `/course-planner/lecture-entity/details?lecture_entity_id=${lecture_entity_id}`,
      true,
      {},
      sessionId
    );

    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }

    return apiResponse;
  };

export const CoursePlannerAddLectureAPi =
  (obj) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    const apiResponse = await makeApiRequest(
      "POST",
      backendApi,
      `/course-planner/lecture-entity/add`,
      true,
      obj,
      sessionId
    );
    if (apiResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (apiResponse.error) {
      dispatch(errorAlert(apiResponse.message));
      return;
    }
    return apiResponse;
  };

export const deleteFileYouTubeVideo = (id) => async (dispatch, getState) => {
  const sessionId = await dispatch(getSessionId());
  const apiResponse = await makeApiRequest(
    "DELETE",
    backendApi,
    `/course-planner/remove-file?file_id=${id} `,
    true,
    {},
    sessionId
  );
  if (apiResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (apiResponse.error) {
    dispatch(errorAlert(apiResponse.message));
    return;
  }
  return apiResponse;
};

export const addLectureEntityBulk =
  (entityData) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "/course-planner/lecture-entity/bulk/add",
      true,
      entityData,
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    return response.response;
  };

export const curriculumFilter = (searchWord, data) => {
  if (searchWord == null || searchWord === "") return data;
  else if (
    data?.file_name?.toLowerCase().includes(searchWord.toLowerCase()) ||
    data?.topic?.toLowerCase().includes(searchWord.toLowerCase()) ||
    data?.title?.toLowerCase().includes(searchWord.toLowerCase())
  ) {
    return data;
  }
};
