import dayjs from "dayjs";
import RecordRTC from "recordrtc";
import getBlobDuration from "get-blob-duration";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";

import {
  RESET_RECORDER_STATE,
  SAVE_BLOB_TO_REDUX,
  SET_RECORDING_ACCUMULATED_TIME,
  SET_RECORDING_DESKTOP_VIDEO_TRACK,
  SET_RECORDING_FLAG,
  SET_RECORDING_NAMES,
  SET_RECORDING_PAUSED_FLAG,
  SET_RECORDING_START_TIME,
  SET_RECORDING_USER_AUDIO_TRACK,
  SET_SHOW_RECORDING_ABRUPTLY_ENDED,
  SET_SHOW_RECORDING_DISCLAIMER,
  SET_SHOW_RECORDING_NOT_UPLOADED,
} from "../types";

import { backendApi } from "../../config/constants";
import makeApiRequest from "../../utils/makeApiRequest";
import { addLectureVideo, shareElementList } from "./fileSystemActions";
import { errorAlert, logoutProcedure } from "./utils";
import { socketSendRecordingState } from "./liveClassActions";
import { sleep } from "../../utils/misc";
import responseCodes from "../../utils/responseCodes";

const isToday = require("dayjs/plugin/isToday");
dayjs.extend(isToday);

const LOCALSTORAGE_RECORDING_DISCLAIMER_TIMES_SHOWN =
  "RECORDING_DISCLAIMER_TIMES_SHOWN";
const LOCALSTORAGE_RECORDING_DISCLAIMER_LAST_SHOWN =
  "RECORDING_DISCLAIMER_LAST_SHOWN";

export const resetRecorderState = () => (dispatch) => {
  dispatch({
    type: RESET_RECORDER_STATE,
    payload: null,
  });
};

const setRecordingFlag = (recording) => (dispatch) => {
  dispatch({
    type: SET_RECORDING_FLAG,
    payload: recording,
  });
};

const setRecordingPausedFlag = (recordingPaused) => (dispatch) => {
  dispatch({
    type: SET_RECORDING_PAUSED_FLAG,
    payload: recordingPaused,
  });
};

const setRecordingDesktopVideoTrack =
  (recordingDesktopVideoTrack) => (dispatch) => {
    dispatch({
      type: SET_RECORDING_DESKTOP_VIDEO_TRACK,
      payload: recordingDesktopVideoTrack,
    });
  };

const setRecordingUserAudioTrack = (recordingUserAudioTrack) => (dispatch) => {
  dispatch({
    type: SET_RECORDING_USER_AUDIO_TRACK,
    payload: recordingUserAudioTrack,
  });
};

const setRecordingStartTime = (recordingStartTime) => (dispatch) => {
  dispatch({
    type: SET_RECORDING_START_TIME,
    payload: new Date(recordingStartTime).valueOf(),
  });
};

const setRecordingAccumulatedTime =
  (recordingEndTime) => (dispatch, getState) => {
    const { recorder } = getState();
    const { recordingStartTime, recordingAccumulatedTime } = recorder;

    if (!recordingStartTime) {
      return;
    }

    const recordingTime =
      new Date(recordingEndTime).valueOf() - recordingStartTime;

    dispatch({
      type: SET_RECORDING_ACCUMULATED_TIME,
      payload: recordingAccumulatedTime + recordingTime,
    });

    dispatch(setRecordingStartTime(null));
  };

export const setShowRecordingDisclaimer = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RECORDING_DISCLAIMER,
    payload: show,
  });
};

export const setShowRecordingAbruptlyEnded = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RECORDING_ABRUPTLY_ENDED,
    payload: show,
  });
};

export const setShowRecordingNotUploaded = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RECORDING_NOT_UPLOADED,
    payload: show,
  });
};

const saveBlobToRedux =
  (blob, setNames = false) =>
  async (dispatch) => {
    const duration = await getBlobDuration(blob);

    const payload = {
      id: uuidv4(),
      blob,
      duration,
    };

    dispatch({
      type: SAVE_BLOB_TO_REDUX,
      payload,
    });

    if (setNames) {
      dispatch(setRecordingNames());
    }
  };

const desktopVideoTrackRecordingEnded = () => async (dispatch) => {
  // await dispatch(stopRecordingProcedure(false));

  setTimeout(() => {
    // TODO: use the flag showRecordingAbruptlyEnded and replace this alert with a real popup in liveclass.js and ok button on it will set the showRecordingAbruptlyEnded to false
    dispatch(setShowRecordingAbruptlyEnded(true));
    dispatch(
      errorAlert(
        "Your recording has abruptly ended. If you still need to record, please start recording again."
      )
    );
  }, 1500);
};

export const startLectureRecording = () => async (dispatch, getState) => {
  const getClassIsLive = () => {
    const {
      klass: { classes },
      liveClass: { classId: liveClassId, liveLecture },
    } = getState();
    return (
      classes[liveClassId]?.class_is_live || liveLecture?.status === "live"
    );
  };

  const {
    user: { sessionId },
    liveClass: { lectureId: liveLectureId },
  } = getState();

  let response,
    currentTryCount = 0;
  const SLEEP_BETWEEN_POLLS = 1500; // ms

  // As API calls take time, this might take up around 3 minutes though we have given 2 minutes
  const MAX_TRIES = (2 * 60 * 1000) / SLEEP_BETWEEN_POLLS;
  const MAX_TRIES_TO_REMOVE_QUEUED_RECORDING = 3;

  while (true) {
    if (!getClassIsLive()) return false;

    const cancelTokenSource = Axios.CancelToken.source();

    currentTryCount += 1;

    const responsePromise = makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/recording/start",
      true,
      { lecture_id: liveLectureId },
      sessionId,
      false,
      null,
      cancelTokenSource.token
    );

    if (!getClassIsLive()) {
      cancelTokenSource.cancel(
        "Cancelling because live class is no longer active"
      );
      return false;
    }

    response = await responsePromise;

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (response.error) {
      if (
        !getClassIsLive() &&
        response.message.code === responseCodes.LECTURE_NOT_LIVE
      ) {
        // This case happens when user ends class when polling is going on
        return false;
      }

      dispatch(errorAlert(response.message));
      return false;
    }

    console.log({ response, currentTryCount });

    if (response?.status === 202 && currentTryCount <= MAX_TRIES) {
      await sleep(SLEEP_BETWEEN_POLLS);
      continue;
    }
    break;
  }

  if (response?.status === 202) {
    for (let i = 0; i < MAX_TRIES_TO_REMOVE_QUEUED_RECORDING; i++) {
      await sleep(SLEEP_BETWEEN_POLLS);
      if (!getClassIsLive()) return false;

      const cancelTokenSource = Axios.CancelToken.source();
      const responsePromise = makeApiRequest(
        "POST",
        backendApi,
        "lectures/lecture/recording/start",
        true,
        { lecture_id: liveLectureId, remove_from_queue_if_failed: true },
        sessionId,
        false,
        null,
        cancelTokenSource.token
      );

      if (!getClassIsLive()) {
        cancelTokenSource.cancel(
          "Cancelling because live class is no longer active"
        );
        return false;
      }

      const response = await responsePromise;

      if (response.logout) {
        dispatch(logoutProcedure(false));
        return false;
      }

      if (response.error) {
        continue;
      }
      break;
    }

    dispatch(errorAlert(response.message));
    return false;
  }

  window.onbeforeunload = () => "";
  dispatch(setRecordingStartTime(response.response.recording.started_at));
  return true;
};

export const endLectureRecording = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
    liveClass: { lectureId: liveLectureId },
  } = getState();

  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/recording/end",
    true,
    { lecture_id: liveLectureId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return false;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }

  dispatch(setRecordingAccumulatedTime(response.response.recording.ended_at));
  return true;
};

const sendRecordingState = () => (dispatch, getState) => {
  const {
    recorder: {
      recording,
      recordingPaused,
      recordingStartTime,
      recordingAccumulatedTime,
    },
  } = getState();
  dispatch(
    socketSendRecordingState({
      recording,
      recordingPaused,
      recordingStartTime,
      recordingAccumulatedTime,
    })
  );
};

export const startRecording = () => async (dispatch) => {
  if (await dispatch(startLectureRecording())) {
    dispatch(setRecordingFlag(true));
    dispatch(setRecordingPausedFlag(false));
    dispatch(setShowRecordingDisclaimer(true));
    dispatch(sendRecordingState());
    return true;
  }
  return false;
};

export const pauseRecording = () => async (dispatch, getState) => {
  const { recorder } = getState();
  const { recording, recordingPaused } = recorder;
  if (!recording) {
    return false;
  }

  if (recordingPaused) {
    return false;
  }

  if (await dispatch(endLectureRecording())) {
    dispatch(setRecordingPausedFlag(true));
    dispatch(sendRecordingState());
    return true;
  }

  return false;
};

export const resumeRecording = () => async (dispatch, getState) => {
  const { recorder } = getState();
  const { recording, recordingPaused } = recorder;

  if (!recording) {
    return false;
  }

  if (!recordingPaused) {
    return false;
  }

  if (await dispatch(startLectureRecording())) {
    dispatch(setRecordingPausedFlag(false));
    dispatch(sendRecordingState());
    return true;
  }

  return false;
};

export const stopRecording = () => async (dispatch, getState) => {
  const { recorder } = getState();
  const { recording } = recorder;
  if (!recording) {
    return;
  }

  if (await dispatch(endLectureRecording())) {
    dispatch(setRecordingFlag(false));
    dispatch(setRecordingPausedFlag(false));
    dispatch(sendRecordingState());
  }
};

// export const stopRecording = (setNames = false) => async (dispatch) => {
// await dispatch(stopRecordingProcedure(setNames));
// window.onbeforeunload = () => {
//   // empty function body will not trigger the "Changes you made may not be saved." popup
// };
// dispatch(resetRecorderState());
// };

export const muteUserAudioRecording = (mute) => (dispatch, getState) => {
  const { recorder } = getState();
  const { recordingUserAudioTrack } = recorder;

  if (recordingUserAudioTrack) {
    recordingUserAudioTrack.enabled = !mute;
  }
};

export const downloadAllRecordedBlobs = () => (dispatch, getState) => {
  const { recorder } = getState();
  const { recordedBlobs } = recorder;

  recordedBlobs.forEach((b, index) => {
    setTimeout(() => {
      RecordRTC.invokeSaveAsDialog(b.blob, b.fileName);
    }, index * 1000);
  });
};

export const uploadAllRecordedBlobs =
  (share = false, shareElementIdList, shareVideo = false, classId, lectureId) =>
  async (dispatch, getState) => {
    const { recorder, user, klass } = getState();
    const { recordedBlobs } = recorder;
    const { sessionId } = user;
    const { lectures } = klass;

    const lecture = lectures[lectureId];

    let lectureVideoElementId = undefined;

    // Not used. Needs checking before removing
    if (recordedBlobs.length !== 0) {
      const fileList = recordedBlobs.map(
        (b) =>
          new File([b.blob], b.fileName, {
            type: b.blob.type,
          })
      );

      const lectureVideoUploadResponse = await makeApiRequest(
        "POST",
        backendApi,
        "library/add_files",
        true,
        {
          files: fileList,
          parent_id: lecture.folder_id,
          extract_thumbnail: "true",
        },
        sessionId,
        true,
        (progressEvent) => {}
      );

      lectureVideoElementId =
        lectureVideoUploadResponse.response.element_list[0].id;
      await dispatch(addLectureVideo(lectureVideoElementId, lecture.id, false));

      if (lectureVideoUploadResponse.error) {
        // TODO: use the flag showRecordingNotUploaded and replace this alert with a real popup in liveclass.js and ok button on it will set the showRecordingNotUploaded to false
        dispatch(setShowRecordingNotUploaded(true));
        dispatch(
          errorAlert(
            "Your recordings have not been uploaded. Please download them now and upload again later."
          )
        );

        return { error: true };
      }
    }

    if (share) {
      dispatch(shareElementList(shareElementIdList, true, classId));
    }

    if (shareVideo) {
      dispatch(lectureRecordingToBeShared(lecture.id, true));
    }

    return { error: false };
  };

export const setRecordingNames = () => (dispatch, getState) => {
  const { klass, liveClass } = getState();
  const { lectures = {} } = klass;
  const { lectureId } = liveClass;
  const { topic } = lectures[lectureId] || {};

  dispatch({
    type: SET_RECORDING_NAMES,
    payload: {
      namePrefix: (topic || "lecture") + " - recording",
      extension: "webm",
    },
  });
};

export const removeSiteCloseRestriction = () => (dispatch) => {
  window.onbeforeunload = () => {
    // empty function body will not trigger the "Changes you made may not be saved." popup
  };
};

export const lectureRecordingToBeShared =
  (lectureId, toBeShared) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/recording/to-be-shared",
      true,
      {
        lecture_id: lectureId,
        to_be_shared: toBeShared,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    return true;
  };
