import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRouterSwitch from "./AppRouterSwitch";
import {
  addSocketListeners,
  removeSocketListeners,
} from "../../utils/socket/listeners";
import DeleteAccSuccessPopup from "../DeleteAccountPopup/deleteAccSuccessPopup";

class AppRouter extends Component {
  state = {
    showDeleteAccSuccessPopup: false,
    changed: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) addSocketListeners();
    if (
      this.props.additionalData.showAccountDeleteSuccessPopup &&
      !this.state.changed
    ) {
      setTimeout(() => {
        this.setState({
          showDeleteAccSuccessPopup: true,
          changed: true,
        });
        setTimeout(() => {
          this.setState({
            showDeleteAccSuccessPopup: false,
          });
        }, 5000);
      }, 1500);
    }
  }

  closePopup = () => {
    this.setState({ showDeleteAccSuccessPopup: false });
  };

  componentWillUnmount() {
    removeSocketListeners();
  }

  render() {
    const { showDeleteAccSuccessPopup } = this.state;
    return (
      <BrowserRouter>
        {showDeleteAccSuccessPopup && (
          <DeleteAccSuccessPopup closePopup={this.closePopup} />
        )}
        <AppRouterSwitch />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ user, socket }) => ({
  additionalData: user.additionalData,
  sessionId: user.sessionId,
  socket: socket.socket,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
