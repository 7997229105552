import produce from "immer";

import {
  RESET_RECORDER_STATE,
  SET_RECORDER_DETAILS,
  SET_RECORDING_ACCUMULATED_TIME,
  SET_RECORDING_DESKTOP_VIDEO_TRACK,
  SET_RECORDING_FLAG,
  SET_RECORDING_PAUSED_FLAG,
  SET_RECORDING_START_TIME,
  SET_RECORDING_USER_AUDIO_TRACK,
  SET_SHOW_RECORDING_DISCLAIMER,
  SET_SHOW_RECORDING_ABRUPTLY_ENDED,
  SAVE_BLOB_TO_REDUX,
  SET_RECORDING_NAMES,
  SET_SHOW_RECORDING_NOT_UPLOADED,
  CLEAR_RECORDER_DETAILS,
  UPDATE_RECORDING_STATE,
} from "../types";

const initialState = {
  recording: false,
  recordingPaused: false,
  recorderObject: null,
  customStream: null,
  recordingDesktopVideoTrack: null,
  recordingUserAudioTrack: null,
  recordingStartTime: null,
  recordingAccumulatedTime: 0,
  showRecordingDisclaimer: false,
  showRecordingAbruptlyEnded: false,
  showRecordingNotUploaded: false,
  recordedBlobs: [],
};

const recorderReducer = produce((state, action) => {
  switch (action.type) {
    case UPDATE_RECORDING_STATE: {
      return { ...state, ...action.payload };
    }
    case SET_RECORDING_FLAG: {
      state.recording = action.payload;
      return;
    }
    case SET_RECORDING_PAUSED_FLAG: {
      state.recordingPaused = action.payload;
      return;
    }
    case SET_RECORDER_DETAILS: {
      const { recorderObject, customStream } = action.payload;
      state.recorderObject = recorderObject;
      state.customStream = customStream;
      return;
    }
    case SET_RECORDING_DESKTOP_VIDEO_TRACK: {
      state.recordingDesktopVideoTrack = action.payload;
      return;
    }
    case SET_RECORDING_USER_AUDIO_TRACK: {
      state.recordingUserAudioTrack = action.payload;
      return;
    }
    case SET_RECORDING_START_TIME: {
      state.recordingStartTime = action.payload;
      return;
    }
    case SET_RECORDING_ACCUMULATED_TIME: {
      state.recordingAccumulatedTime = action.payload;
      return;
    }
    case SET_SHOW_RECORDING_DISCLAIMER: {
      state.showRecordingDisclaimer = action.payload;
      return;
    }
    case SET_SHOW_RECORDING_ABRUPTLY_ENDED: {
      state.showRecordingAbruptlyEnded = action.payload;
      return;
    }
    case SET_SHOW_RECORDING_NOT_UPLOADED: {
      state.showRecordingNotUploaded = action.payload;
      return;
    }
    case SAVE_BLOB_TO_REDUX: {
      state.recordedBlobs.push(action.payload);
      return;
    }
    case SET_RECORDING_NAMES: {
      const { namePrefix, extension } = action.payload;
      const numRecordedBlobs = state.recordedBlobs.length;
      state.recordedBlobs = state.recordedBlobs.map((b, index) => {
        b.fileName =
          numRecordedBlobs > 1
            ? `${namePrefix}-${index + 1}.${extension}`
            : `${namePrefix}.${extension}`;
        return b;
      });
      return;
    }
    case RESET_RECORDER_STATE: {
      return initialState;
    }
    case CLEAR_RECORDER_DETAILS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}, initialState);

export default recorderReducer;
