import React, { Component } from "react";
import { connect } from "react-redux";
import "./ShareFileFolderPopupStyle.css";
import crossIcon from "../assets/cross.svg";
import sharedClassIcon from "../assets/classSharedIcon.svg";
import { closeShareFileFolderPopup } from "../../../store/actions/fileOptions";
import AutoSuggestionInput from "../../AutoSuggestionInput/AutoSuggestionInput";
import ImageOrInitial from "../../Live-class-tabs/ImageOrInitial/ImageOrInitial";
import {
  shareFolderFile,
  shareStudentFolderFile,
  setFsRecentUsers,
} from "../../../store/actions/fileSystemActions";
import LoaderWrapper from "../../Loader";
import { validateEmail } from "../../../store/actions/utils";
import _ from "lodash";

class ShareFileFolderPopup extends Component {
  state = {
    sharedUserList: [],
    isShare: true,
    selectedData: [],
    loading: false,
  };

  componentDidMount = async () => {
    const { fileSystemData, detailsElementId } = this.props;
    this.setState({
      isShare: fileSystemData[detailsElementId].shared,
    });
    this.setState({
      sharedUserList: fileSystemData[detailsElementId].shared_with,
    });
    await this.props.setFsRecentUsers();
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading,
    });
  };

  handleToggle = () => {
    this.setState({
      isShare: !this.state.isShare,
    });
  };

  handleRemoveUser = (email) => {
    const sharedUserList = this.state.sharedUserList.filter((stud) => {
      return stud.email !== email;
    });

    this.setState({ sharedUserList });
  };

  handleShare = async () => {
    const { isShare, sharedUserList } = this.state;
    const { classId, detailsElementId, fileSystemData } = this.props;
    this.setLoading(true);
    const newClassId = fileSystemData[detailsElementId]?.class_id;
    const sharedUserListEmails = sharedUserList
      .filter((user) => user.email)
      .map((item) => item.email);

    const previouslySharedUsers = (
      this.props.fileSystemData[this.props.detailsElementId].shared_with || []
    ).map((item) => item.email);
    let shareWithUsers = sharedUserListEmails.filter(
      (email) => !previouslySharedUsers.includes(email) && !!email
    );
    let unshareWithUsers = previouslySharedUsers.filter(
      (email) => !sharedUserListEmails.includes(email) && !!email
    );

    if (
      shareWithUsers.length !== 0 ||
      unshareWithUsers.length !== 0 ||
      this.props.fileSystemData[detailsElementId]?.shared !== isShare
    ) {
      if (this.props.role === "student") {
        await this.props.shareStudentFolderFile(
          [detailsElementId],
          // classId,
          shareWithUsers,
          unshareWithUsers
        );
      } else {
        await this.props.shareFolderFile(
          [detailsElementId],
          newClassId || classId,
          isShare,
          shareWithUsers,
          unshareWithUsers
        );
      }
      this.props.closeShareFileFolderPopup();
      this.setLoading(false);
    } else {
      this.props.closeShareFileFolderPopup();
      this.setLoading(false);
    }
  };

  handleUnshareForAll = () => {
    this.setState({
      isShare: false,
      sharedUserList: [],
    });
  };

  render() {
    const { classId, students, recentUserList, location } = this.props;
    const { isShare, sharedUserList, loading } = this.state;

    const suggestionList =
      location.pathname !== "class-files"
        ? [...recentUserList]
        : [...Object.values(students)];

    return (
      <LoaderWrapper loading={loading}>
        <div className="popup__container__wrapper">
          <div className="share__file__folder__popup__container">
            <div className="share__file__folder__popup__header">
              <h4>Share</h4>
              <img
                src={crossIcon}
                alt=""
                className="share__file__folder__popup__close__icon"
                onClick={this.props.closeShareFileFolderPopup}
              />
            </div>
            <div className="shared__with__container">
              <p>Shared with </p>
              <button onClick={() => this.handleUnshareForAll()}>
                Unshare from All
              </button>
            </div>
            <AutoSuggestionInput
              classId={classId}
              suggestionList={
                suggestionList.length === 0 ? null : suggestionList
              }
              onUpdate={(data) => {
                this.setState({
                  sharedUserList: _.uniqBy(
                    [...this.state.sharedUserList, ...data],
                    "email"
                  ),
                });
              }}
              onWrongEntry={(data) => {}}
              placeholder="Enter name or Email ID and press Enter"
              initialData={[]}
              showDisplayClip={false}
            />
            <div className="shared__user__container hide__scrollbar">
              {location.pathname !== "/my-files" &&
                location.pathname !== "/student/my-files" && (
                  <div className="fixed__text__container">
                    <div className="normal__text">
                      <img
                        src={sharedClassIcon}
                        alt=""
                        style={{ width: "40px" }}
                      />
                      <div className="text__container">
                        <p>Shared with Class Students</p>
                        <p>Access to Class and Lecture resources only</p>
                      </div>
                    </div>
                    <div className="toggle__button">
                      <div id="switch" onChange={this.handleToggle}>
                        <label className="switch">
                          <input type="checkbox" checked={isShare} />
                          <div></div>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              {sharedUserList
                .filter((student) => validateEmail(student.email))
                .map((user) => (
                  <div key={user.id} className="contact__info__shared__student">
                    <div className="image__formatter__container__student__delete">
                      <div className="image">
                        <ImageOrInitial
                          img={user.image}
                          name={user.name || user.email?.split("@")[0]}
                          borderRadius="50%"
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="student__shared__personal">
                      <div className="student__shared__name">{user.name}</div>
                      <div className="student__shared__detail__contact">
                        <div>{user.email}</div>
                      </div>
                    </div>
                    <img
                      src={crossIcon}
                      alt=""
                      style={{
                        marginRight: "16px",
                        cursor: "pointer",
                        position: "absolute",
                        right: "0px",
                      }}
                      onClick={() => this.handleRemoveUser(user.email)}
                    />
                  </div>
                ))}
            </div>
            <div className="shared__popup__button__container">
              <button
                className="cancel__button"
                onClick={this.props.closeShareFileFolderPopup}
              >
                Cancel
              </button>
              <button
                className="done__button"
                onClick={() => this.handleShare()}
              >
                Done
              </button>
            </div>{" "}
          </div>
        </div>
      </LoaderWrapper>
    );
  }
}

const mapStateToProps = ({ klass, fileSystem, fileOptions, user }) => ({
  students: klass.students[klass.currentClass],
  classId: klass.currentClass,
  currentFolderId: fileSystem.currentFolderId,
  fileSystemData: fileSystem.fileSystemData,
  detailsElementId: fileOptions.detailsElementId,
  role: user.role,
  recentUserList: fileSystem.recentUserList,
});

const mapDispatchToProps = {
  closeShareFileFolderPopup,
  shareFolderFile,
  shareStudentFolderFile,
  setFsRecentUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareFileFolderPopup);
