import React, { Component } from "react";
import "./StudentLiveClassPopups.css";
import sentOutOfClassIcon from "./assets/sentOutOfClass.svg";

export class SentOutOfClassPopup extends Component {
  render() {
    return (
      <div
        className="student_live_class_popup_background"
        onClick={this.props.onBackgroundClick}
      >
        <div className="student_live_class_popup_container sent_out_of_class_popup_container">
          <img
            className="sent_out_of_class_popup_icon"
            src={sentOutOfClassIcon}
            alt=""
          />
          <p className="student_live_class_popup_title_font sent_out_of_class_popup_title">
            Sent Out of class
          </p>
          <p className="student_live_class_popup_text_font sent_out_of_class_popup_text">
            {`Sorry you are sent out of the class by \nteacher.`}
          </p>
        </div>
      </div>
    );
  }
}
