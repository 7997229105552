import { combineReducers } from "redux";
import { enableMapSet } from "immer";

import lectureReducer from "./lectureReducer";
import classReducer from "./classReducer";
import liveClassReducer from "./liveClassReducer";
import miscellaneousReducer from "./miscellaneousReducer";
import jitsiReducer from "./jitsiReducer";
import jitsiXReducer from "./jitsiXReducer";
import secretsReducer from "./secretsReducer";
import userReducer from "./userReducer";
import socketReducer from "./socketReducer";
import fileSystemReducer from "./fileSystemReducer";
import peopleReducer from "./peopleReducer";
import postsReducer from "./postsReducer";
import recorderReducer from "./recorderReducer";
import authReducer from "./authReducer";
import smartboard from "./smartboard";
import logReducer from "./logReducer";
import fileOptions from "./fileOptions";
import applicationReducer from "./applicationReducer";
import adminReducer from "./adminReducer";
import assignmentReducer from "./assignmentReducer";
import studentAssignmentReducer from "./studentAssignmentReducer";
import quizReducer from "./quizReducer";
import planningReducer from "./planningReducer";
import liveQuizReducer from "./liveQuizReducer";
import batchReducer from "./batchReducer";
import instituteReducer from "./instituteReducer";

enableMapSet();

global.reduxLog = [];

export default combineReducers({
  log: logReducer,
  misc: miscellaneousReducer,
  liveClass: liveClassReducer,
  jitsi: jitsiReducer,
  jitsiX: jitsiXReducer,
  secrets: secretsReducer,
  user: userReducer,
  lecture: lectureReducer,
  klass: classReducer,
  socket: socketReducer,
  fileSystem: fileSystemReducer,
  people: peopleReducer,
  posts: postsReducer,
  recorder: recorderReducer,
  auth: authReducer,
  smartboard,
  fileOptions,
  application: applicationReducer,
  admin: adminReducer,
  assignment: assignmentReducer,
  studentAssignment: studentAssignmentReducer,
  quiz: quizReducer,
  planning: planningReducer,
  liveQuiz: liveQuizReducer,
  batches: batchReducer,
  institute: instituteReducer,
});
