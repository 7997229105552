import axios from "axios";
import {
  SET_FILE_SYSTEM_DATA,
  SET_CURRENT_FOLDER_ID,
  SET_CLASS_BROWSER_VISIBILITY,
  SET_ADD_FOLDER_VISIBILITY,
  SET_SCREEN_CONTEXT,
  SET_CLASS_STUDENT_DATA_MAP,
  SET_IS_FILE_SYSTEM_DATA_LOADED,
  SET_FS_RECENT_FILE_DATE_LIST,
  SET_FS_FOLDER_TO_CLASS_ID_MAP,
  SET_FS_TOP_TRASH_ELEMENT_ID_LIST,
  SET_FS_UPLOAD_PROGRESS_PERCENT,
  CLEAR_UPLOAD_LECTURE_DATA,
  SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT,
  ADD_FS_MULTIPLE_UPLOAD_FILES,
  SET_FS_MULTIPLE_INCREMENT_COUNTER,
  SET_FS_MULTIPLE_UPLOAD_COMPLETE,
  RESET_FS_MULTIPLE_UPLOAD,
  SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC,
  REMOVE_FS_MULTIPLE_UPLOAD_FILES,
  ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  ADD_FILE_SYSTEM_DATA,
  SET_TRASH_STATUS,
  SET_CURRENT_FILE_ID,
  SET_CURRENT_FOLDER_CHILDREN,
  SET_CURRENT_FOLDER_PARENT,
  SET_TRASH_ELEMENTS_MAP,
  SET_USER_FOLDER_CHILDREN,
  SET_LIBRARY_FILESYSTEM_IDS,
  REMOVE_FOLDER_CHILDREN,
  RENAME_FILE_FOLDER,
  SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID,
  RENAME_FILE_FOLDER_CHILDREN,
  SET_LECTURE_SHARE_STATUS,
  SET_FS_STARRED_FILES_LIST,
  SET_FS_SHARED_WITH_USER_FILE_LIST,
  SET_TREE_SELECTED_FOLDER_ID,
  STAR_ELEMENT,
  GET_RECENT_USERS,
  REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA,
  REMOVE_FROM_RECENT,
  REMOVE_FROM_STARRED,
  REMOVE_FROM_SHARED,
  STAR_RECENT_ELEMENT,
  STAR_STARRED_ELEMENT,
  STAR_SHARED_ELEMENT,
  SET_ELEMENT_SHARE,
} from "../types";
import { backendApi } from "../../config/constants";
import makeApiRequest from "../../utils/makeApiRequest";
import { errorAlert, getSessionId, logoutProcedure, setLoader } from "./utils";
import {
  convertDataToFrontEndFormat,
  getClassAndLectureOfElement,
  getRootFolderInfo,
  getTopTrashedElementIdList,
} from "../../utils/fileSystem";
import { setCurrentClass } from "./classActions";
import { setSlideShareState } from "./smartboard";
import { SLIDE_SHARE_STATE, TEACHER } from "../../utils/constants";
import makeFileUploadRequest from "../../utils/makePutRequest";
import { setUploadLectureData } from "./lectureActions";
import { setLiveClassFileSystemIds } from "./liveClassActions";
import {
  modules,
  routes,
  screenNames,
} from "../../components/AppRouter/routes";
import instantLectureUtils from "../../components/InstantLectureModule/InstantLectureUtils";
import { instantLecture } from "./instantLectureActions";

const getPathName = async () => {
  let pathName = window.location.pathname;
  if (/\/\d+/g.test(pathName)) {
    pathName = pathName.replace(/\/\d+/g, "/:id");
    return pathName;
  }
  return pathName;
};

export const createFolder =
  (folderDetails, classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      fileSystem: { currentFolderId },
      klass: { currentClass },
    } = getState();

    classId = classId || currentClass;
    const pathName = await getPathName();

    const createFolderResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/folder/create",
      true,
      pathName === "/my-files" || pathName === "/student/my-files"
        ? {
            class_id: null,
            parent_id: currentFolderId,
            name: folderDetails.name,
          }
        : {
            class_id: classId ? classId : null,
            parent_id: currentFolderId,
            name: folderDetails.name,
          },
      sessionId
    );

    if (createFolderResponse.error) {
      return;
    }

    await dispatch(getFolderChildElementList());
  };

export const starElement =
  (classId, elementId, starred, title) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const pathName = await getPathName();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "library/file/star",
      true,

      {
        class_id: classId || null,
        element_id: elementId,
        star: starred,
      },

      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    const data = response.response;

    dispatch({
      type: STAR_ELEMENT,
      payload: { classId, elementId, starred: data.starred },
    });
    if (title === "Recent Files") {
      dispatch({
        type: STAR_RECENT_ELEMENT,
        payload: { classId, elementId, starred: data.starred },
      });
    } else if (title === "Starred Files") {
      dispatch({
        type: STAR_STARRED_ELEMENT,
        payload: { classId, elementId, starred: data.starred },
      });
    } else if (title === "Shared with Me") {
      dispatch({
        type: STAR_SHARED_ELEMENT,
        payload: { classId, elementId, starred: data.starred },
      });
    }
  };

export const setUploadProgress = (value) => (dispatch) => {
  dispatch({
    type: SET_FS_UPLOAD_PROGRESS_PERCENT,
    payload: value,
  });
};

export const setMultipleUploadProgress = (id, value) => (dispatch) => {
  dispatch({
    type: SET_FS_MULTIPLE_UPLOAD_PROGRESS_PERCENT,
    payload: {
      id,
      value,
    },
  });
};

export const setMultipleUploadCancelFunction = (id, value) => (dispatch) => {
  dispatch({
    type: SET_FS_MULTIPLE_UPLOAD_CANCEL_FUNC,
    payload: {
      id,
      value,
    },
  });
};

export const setMultipleUploadFileElementId = (id, elementId) => (dispatch) => {
  dispatch({
    type: SET_FS_MULTIPLE_UPLOAD_FILE_ELEMENT_ID,
    payload: {
      id,
      elementId,
    },
  });
};

export const addMultipleUploadFiles = (idFileInfoMap) => (dispatch) => {
  dispatch({
    type: ADD_FS_MULTIPLE_UPLOAD_FILES,
    payload: idFileInfoMap,
  });
};

export const removeMultipleUploadFiles = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_FS_MULTIPLE_UPLOAD_FILES,
    payload: id,
  });
};

export const setFSMultipleIncrementCounter = (value) => (dispatch) => {
  dispatch({
    type: SET_FS_MULTIPLE_INCREMENT_COUNTER,
    payload: value,
  });
};

export const setFSMultipleUploadComplete = (id, value) => (dispatch) => {
  dispatch({
    type: SET_FS_MULTIPLE_UPLOAD_COMPLETE,
    payload: { id, value },
  });
};

export const resetFSMultipleUpload = () => (dispatch) => {
  dispatch({
    type: RESET_FS_MULTIPLE_UPLOAD,
  });
};

export const addFSMultipleUploadedFiles = (idFileInfoMap) => (dispatch) => {
  dispatch({
    type: ADD_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
    payload: idFileInfoMap,
  });
};

export const removeFSMultipleUploadedFiles = (fileId) => (dispatch) => {
  dispatch({
    type: REMOVE_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
    payload: fileId,
  });
};

export const resetFSMultipleUploadedFiles = () => (dispatch) => {
  dispatch({
    type: RESET_FS_MULTIPLE_UPLOAD_FILES_UPLOADED,
  });
};

const getFileUploadUrl =
  (file, parentId, classId, description, metadata, excludeClassId = false) =>
  async (dispatch, getState) => {
    let {
      user: { role },
      klass: { currentClass },
      liveClass,
      fileSystem: { library },
    } = getState();

    const sessionId = await dispatch(getSessionId());

    classId = classId || currentClass;

    const pathName = await getPathName();
    const module = routes[pathName]?.belongsTo;

    if (
      pathName === screenNames.liveClass &&
      liveClass.fileSystem?.currentClassId
    ) {
      // when user navigates from liveclass control panel and tries to upload some files
      /** @field liveClass.fileSystem?.currentClassId should be used */
      classId = liveClass.fileSystem?.currentClassId;
    } else if (module === modules.LIBRARY) {
      // when this api gets called from library
      /** @field library.fileSystem?.currentClassId should be used */
      classId = library.fileSystem?.currentClassId;
    }

    const requestBody =
      excludeClassId || role === "student"
        ? {
            name: file.name,
            class_id: null,
            file_mime_type: file.type,
            file_size: file.size,
            parent_id: parentId,
            description,
            metadata,
            inherit: "abc",
          }
        : {
            class_id: classId,
            name: file.name,
            file_mime_type: file.type,
            file_size: file.size,
            parent_id: parentId,
            description,
            metadata,
            inherit: "abc",
          };

    const response = await makeApiRequest(
      "POST",
      backendApi,
      role === "student"
        ? "library/student/file/create"
        : "library/file/create",
      true,
      requestBody,
      sessionId,
      false
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return [undefined, undefined];
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return [undefined, undefined];
    }

    const element = response.response.element;
    const uploadUrl = response.response.upload_url;
    const { upload_method, upload_url_request_data } = response.response;
    return [uploadUrl, element, upload_method, upload_url_request_data];
  };

const getLectureVideoUploadUrl =
  (file, parentId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      lecture: { temporaryLectureObject },
      klass: { currentClass },
      fileSystem: { library },
      lecture: { currentLecture },
    } = getState();

    let classId = currentClass;
    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    if (module === modules.LIBRARY) {
      classId = library.fileSystem.currentClassId;
    }

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "lectures/lecture/video/create",
      true,
      {
        class_id: classId,
        file_mime_type: file.type,
        file_size: file.size,
        lecture_id: temporaryLectureObject.id ?? currentLecture,
        // parent_id: parentId,
        description: "",
      },
      sessionId,
      false
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return [undefined, undefined, undefined, undefined];
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return [undefined, undefined, undefined, undefined];
    }
    const element = response.response.element;
    const uploadUrl = response.response.upload_url;
    const { upload_method, upload_url_request_data } = response.response;
    return [uploadUrl, element, upload_method, upload_url_request_data];
  };

export const uploadFilesWithMultipleProgress =
  (
    selectedIdFileMap,
    folderId,
    skipLibraryDataReload = false,
    isLectureVideo = false,
    isPost = false
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId, role },
      liveClass: { classId: liveClassId, lectureId: liveLectureId },
    } = getState();

    const isInstantLecture = instantLectureUtils()
      .is()
      .currentPageBelongsToInstantClassroom();

    const instantLectureFnc = await dispatch(instantLecture());

    const fileUploadResponseList = Object.keys(selectedIdFileMap).map(
      async (fileId) => {
        const file = selectedIdFileMap[fileId];
        const cancelTokenSource = axios.CancelToken.source();

        const [uploadUrl, element, uploadMethod, uploadRequestData] =
          isInstantLecture
            ? await instantLectureFnc.teacher().getFileUploadUrl({
                user_role: role,
                class_id: liveClassId,
                parent_id: folderId,
                file,
                lecture_id: liveLectureId,
              })
            : isLectureVideo
            ? await dispatch(getLectureVideoUploadUrl(file, folderId))
            : isPost
            ? await dispatch(
                getFileUploadUrl(
                  file,
                  folderId,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              )
            : await dispatch(getFileUploadUrl(file, folderId));

        if (!uploadUrl || !element) {
          dispatch(removeMultipleUploadFiles(fileId));
          return;
        }

        dispatch(
          setMultipleUploadCancelFunction(fileId, () => {
            cancelTokenSource.cancel();
            dispatch(permanentDeleteElement(element.id));
          })
        );

        dispatch(setMultipleUploadFileElementId(fileId, element.id));

        if (isLectureVideo) {
          dispatch(
            setUploadLectureData({
              videoElement: element,
            })
          );
        }

        // Make PUT request to upload files
        const response = await makeFileUploadRequest(
          uploadMethod, // PUT or POST
          uploadUrl,
          file,
          (progressEvent) => {
            dispatch(
              setMultipleUploadProgress(
                fileId,
                (progressEvent.loaded * 100) / progressEvent.total
              )
            );
          },
          cancelTokenSource.token,
          uploadRequestData
        );

        if (response.status >= 200 && response.status <= 299) {
          dispatch(setFSMultipleUploadComplete(fileId, true));
          dispatch(
            addFSMultipleUploadedFiles({
              [element.id]: element,
            })
          );
          await dispatch(getFolderChildElementList(folderId));
          dispatch(addLibraryData(convertDataToFrontEndFormat(element)));
        } else {
          // dispatch(errorAlert("Error occurred while uploading file"));
          dispatch(removeMultipleUploadFiles(fileId));
          dispatch(permanentDeleteElement(element.id, undefined, true));
        }
        return element;
      }
    );

    const elements = await Promise.all(fileUploadResponseList);

    if (!skipLibraryDataReload) {
      await dispatch(getFolderChildElementList());
    }
    return elements;
  };

export const uploadLectureVideo =
  (selectedFiles) => async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;

    const lectureVideoUploadResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/add_files",
      true,
      {
        files: selectedFiles,
        parent_id: 1,
        extract_thumbnail: "true",
      },
      sessionId,
      true,
      (progressEvent) => {
        dispatch(
          setUploadProgress((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    );
    if (lectureVideoUploadResponse.error) {
      return;
    }

    return lectureVideoUploadResponse.response.element_list;
  };

export const uploadYoutubeVideo =
  (name, url, classId, folderId, inherit = 1) =>
  async (dispatch, getState) => {
    const {
      fileSystem: { currentFolderId },
      klass: { currentClass },
      liveClass,
    } = getState();

    const sessionId = await dispatch(getSessionId());

    folderId = folderId || currentFolderId;
    classId = classId || currentClass;

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    let apiUrl = "library/link/create";

    const isInstantLecture = instantLectureUtils()
      .is()
      .currentPageBelongsToInstantClassroom();

    if (
      (pathName === screenNames.liveClass || isInstantLecture) &&
      liveClass.fileSystem?.currentClassId
    ) {
      // when user navigates from liveclass control panel and tries to upload youtube files
      /** @field liveClass.fileSystem?.currentClassId should be used */
      classId = liveClass.fileSystem?.currentClassId;
    }

    if (isInstantLecture) {
      apiUrl = "library/instant-lecture/link/create";
    }

    const lectureYoutubeVideoUploadResponse = await makeApiRequest(
      "POST",
      backendApi,
      apiUrl,
      true,
      {
        class_id: classId,
        name: name,
        link_url: url,
        parent_id: folderId,
        inherit: inherit,
        description: "description",
      },
      sessionId
    );

    if (lectureYoutubeVideoUploadResponse.error) {
      return;
    }

    dispatch(getFolderChildElementList());

    dispatch(
      addLibraryData(
        convertDataToFrontEndFormat(
          lectureYoutubeVideoUploadResponse.response.element
        )
      )
    );
    return lectureYoutubeVideoUploadResponse.response.element;
  };

export const addLectureVideo =
  (elementId, lectureId, refreshLibraryData = true) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;

    const lectureVideoUploadResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/add_lecture_video",
      true,
      { element_id: elementId, lecture_id: lectureId },
      sessionId
    );

    if (lectureVideoUploadResponse.error) {
      return;
    }

    if (refreshLibraryData) await dispatch(getLibraryData());
  };

export const getLibraryData =
  (skipReload = false) =>
  async (dispatch, getState) => {
    const {
      user,
      klass,
      fileSystem: { isDataLoaded },
    } = getState();
    const { sessionId } = user;

    if (skipReload && isDataLoaded) return;

    const startupApiResponse = await makeApiRequest(
      "GET",
      backendApi,
      "library/startup",
      true,
      { class_id_list: Object.keys(klass.classes) },
      sessionId
    );

    if (startupApiResponse.error) {
      return;
    }

    let topTrashElementIdList = [];
    startupApiResponse.response.user_folder_data.name = "My Files";
    let modifiedFileSystemData = convertDataToFrontEndFormat(
      startupApiResponse.response.user_folder_data
    );
    topTrashElementIdList = topTrashElementIdList.concat(
      getTopTrashedElementIdList(startupApiResponse.response.user_folder_data)
    );

    let class_folder_data_map =
      startupApiResponse.response.class_folder_data_map;
    let folderToClassIdMap = {};
    for (let classId in class_folder_data_map) {
      class_folder_data_map[classId].name = klass.classes[classId].name;
      const modifiedDataCurrentClass = convertDataToFrontEndFormat(
        class_folder_data_map[classId]
      );
      for (let elementId in modifiedDataCurrentClass) {
        folderToClassIdMap[elementId] = parseInt(classId);
      }
      modifiedFileSystemData = {
        ...modifiedFileSystemData,
        ...modifiedDataCurrentClass,
      };
      topTrashElementIdList = topTrashElementIdList.concat(
        getTopTrashedElementIdList(class_folder_data_map[classId])
      );
    }

    await dispatch({
      type: SET_FS_FOLDER_TO_CLASS_ID_MAP,
      payload: folderToClassIdMap,
    });

    await dispatch(setTopTrashElementIdList(topTrashElementIdList));

    await dispatch({
      type: SET_FILE_SYSTEM_DATA,
      payload: modifiedFileSystemData,
    });

    await dispatch({
      type: SET_CLASS_STUDENT_DATA_MAP,
      payload: startupApiResponse.response.class_student_data_map,
    });

    await dispatch({
      type: SET_IS_FILE_SYSTEM_DATA_LOADED,
      payload: true,
    });

    // await dispatch(getRecentFiles());
  };

export const getFileLocation =
  (elementId, classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/parents-till-root",
      true,
      { element_id: elementId, class_id: classId },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    dispatch(addLibraryData(response.response?.parents));
  };

export const addLibraryData =
  (data, mergeExisting = true) =>
  (dispatch, getState) => {
    const {
      user: { folder_id: userFolderId },
    } = getState();

    let children = [];

    if (Object.values(data).length <= 0) {
      return;
    }

    const updatedData = Object.values(data).map((element) => {
      if (element?.id === userFolderId) {
        return { ...element, name: "My Files" };
      }
      if (element?.children) {
        children.push(...element.children);

        return { ...element, children: [] };
      }
      return { ...element };
    });

    dispatch({
      type: ADD_FILE_SYSTEM_DATA,
      payload: { data: updatedData, mergeExisting },
    });

    dispatch(addLibraryData(children, mergeExisting));
  };

export const removeFromDashboard = (elementId) => async (dispatch) => {
  dispatch({
    type: REMOVE_FROM_RECENT,
    payload: elementId,
  });
  dispatch({
    type: REMOVE_FROM_STARRED,
    payload: elementId,
  });
  dispatch({
    type: REMOVE_FROM_SHARED,
    payload: elementId,
  });
};

export const trashElement =
  (elementId, classId) => async (dispatch, getState) => {
    const {
      klass: { currentClass },
      fileSystem: { library, currentFolderId },
      liveClass: { lectureId: liveLectureId },
    } = getState();

    const sessionId = await dispatch(getSessionId());

    classId = classId || currentClass;

    const pathName = await getPathName();
    const module = routes[pathName]?.belongsTo;

    if (module === modules.LIBRARY) {
      // when this api gets called from library
      /** @field library.fileSystem?.currentClassId should be used */
      classId = library.fileSystem?.currentClassId;
    }

    let body = { element_id: elementId };
    let apiUrl = "";

    const isInstantLecture = instantLectureUtils()
      .is()
      .currentPageBelongsToInstantClassroom();

    if (isInstantLecture) {
      apiUrl = "library/instant-lecture/element/trash";
      body.class_id = classId;
      body.lecture_id = liveLectureId;
    } else if (pathName === "/dashboard") {
      apiUrl = "library/file/remove-recent";
    } else {
      apiUrl = "library/element/trash";
      body.class_id = classId;
    }

    const elementDeleteResponse = await makeApiRequest(
      "POST",
      backendApi,
      apiUrl,
      true,
      body,
      sessionId,
      false
    );

    if (elementDeleteResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (elementDeleteResponse.error) {
      dispatch(errorAlert(elementDeleteResponse.message));
      return;
    }

    if (pathName !== "/dashboard") {
      dispatch(removeCurrentFolderChildren(currentFolderId, [elementId]));
      dispatch(getFolderChildElementList());
    } else {
      dispatch(removeFromDashboard(elementId));
    }

    dispatch({
      type: REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA,
      payload: elementId,
    });

    return elementDeleteResponse.response.success;

    // if (!skipLibraryDataReload) await dispatch(getLibraryData());
    // else {
    //   if (elementDeleteResponse.status === 200) {
    //     dispatch(trashElementInRedux(elementId, trashValue));
    //   }
    // }
  };

export const removeSharedFile = (elementId) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();

  const elementDeleteResponse = await makeApiRequest(
    "POST",
    backendApi,
    "library/file/remove-from-shared",
    true,
    { element_ids: [elementId] },
    sessionId,
    false
  );
  if (elementDeleteResponse.logout) {
    dispatch(logoutProcedure(false));
  }

  if (elementDeleteResponse.error) {
    dispatch(errorAlert(elementDeleteResponse.message));
    return;
  }

  dispatch(removeFromDashboard(elementId));

  dispatch({
    type: REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA,
    payload: elementId,
  });

  return elementDeleteResponse.response.success;
};

export const trashStudentElement =
  (elementId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      fileSystem: { library, currentFolderId },
    } = getState();

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    // if (module === modules.LIBRARY) {
    //   // when this api gets called from library
    //   /** @field library.fileSystem?.currentClassId should be used */
    //   classId = library.fileSystem?.currentClassId;
    // }

    const elementDeleteResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/student/element/trash",
      true,
      { element_id: elementId },
      sessionId,
      false
    );

    if (elementDeleteResponse.logout) {
      dispatch(logoutProcedure(false));
    }

    if (elementDeleteResponse.error) {
      dispatch(errorAlert(elementDeleteResponse.message));
      return;
    }

    dispatch(removeCurrentFolderChildren(currentFolderId, [elementId])); // only works if the deleted element's parent is currentFolderId
    dispatch(getFolderChildElementList());

    dispatch({
      type: REMOVE_TRASH_ELEMENT_FILE_SYSTEM_DATA,
      payload: elementId,
    });

    return elementDeleteResponse.response.success;

    // if (!skipLibraryDataReload) await dispatch(getLibraryData());
    // else {
    //   if (elementDeleteResponse.status === 200) {
    //     dispatch(trashElementInRedux(elementId, trashValue));
    //   }
    // }
  };

export const removeCurrentFolderChildren =
  (folderId, childrenIds = []) =>
  (dispatch, getState) => {
    const {
      fileSystem: { currentFolderId },
    } = getState();
    folderId = folderId || currentFolderId;

    dispatch({
      type: REMOVE_FOLDER_CHILDREN,
      payload: { folderId, childrenIds },
    });
  };

export const trashElementInRedux = (elementId, trashValue) => (dispatch) => {
  dispatch({
    type: SET_TRASH_STATUS,
    payload: {
      id: elementId,
      trashValue,
    },
  });
};

export const trashElementBulk =
  (elementIdList, classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass },
      filesystem: { currentFolderId },
    } = getState();

    classId = classId || currentClass;

    const elementDeleteResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/element/trash/bulk",
      true,
      { element_ids: elementIdList, class_id: classId },
      sessionId,
      false
    );

    if (elementDeleteResponse.error) {
      return;
    }

    dispatch(removeCurrentFolderChildren(currentFolderId, [elementIdList])); // only works if all the elements have parent folder id as currentFolderId
    await dispatch(getFolderChildElementList());
  };

export const shareElement =
  (elementId, shareValue, classId, lectureId) => async (dispatch, getState) => {
    const {
      klass: { currentClass },
      fileSystem: { currentFolderId, library },
    } = getState();

    classId = classId || currentClass;

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    if (module === modules.LIBRARY) {
      // when this api gets called from library
      /** @field library.fileSystem?.currentClassId should be used */
      classId = library.fileSystem?.currentClassId;
    }

    const shareElementResponse = await dispatch(
      shareFolderFile([elementId], classId, shareValue, null, null)
    );

    if (shareElementResponse.error) {
      return;
    }
    if (lectureId) {
      dispatch({
        type: SET_LECTURE_SHARE_STATUS,
        payload: { shareValue, lectureId },
      });
    }
    await dispatch(getFolderChildElementList(currentFolderId));
  };

export const shareElementList =
  (elementIdList, shareValue, classId, recursive) => async (dispatch) => {
    const shareElementResponse = await dispatch(
      shareFolderFile(elementIdList, classId, shareValue, null, null, recursive)
    );

    if (shareElementResponse.logout) {
      dispatch(logoutProcedure(false));

      return false;
    }

    if (shareElementResponse.error) {
      return false;
    }
    return true;
  };

export const setCurrentFolderParent = (parent) => (dispatch, getState) => {
  const {
    fileSystem: { currentFolderChildren },
  } = getState();

  parent = parent || currentFolderChildren;
  dispatch({
    type: SET_CURRENT_FOLDER_PARENT,
    payload: parent,
  });
};

const getchildrenApiResponse =
  (folderId, currentClassId) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());
    let { classId } = currentClassId
      ? {
          classId: currentClassId,
        }
      : getClassAndLectureOfElement(folderId);

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/folder/children",
      true,
      {
        element_id: folderId,
        class_id: classId ? classId : null,
        get_element: true,
      },
      sessionId,
      false
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }

    return response;
  };

export const getFolderChildElementList =
  (folderId, classId) => async (dispatch, getState) => {
    const {
      fileSystem: { currentFolderId, library },
      klass: { currentClass },
      user: { role },
      fileOptions: { showMoveAndCopyFilePopup },
      liveClass,
    } = getState();

    // classId = classId || currentClass;
    folderId = folderId || currentFolderId;

    const pathName = await getPathName();
    const module = routes[pathName]?.belongsTo;
    if (!classId) {
      if (
        pathName === screenNames.liveClass &&
        liveClass.fileSystem?.currentClassId
      ) {
        // when this api gets called from live class
        /** @field liveClass.fileSystem?.currentClassId should be used */
        classId = liveClass.fileSystem?.currentClassId;
      } else if (module === modules.LIBRARY) {
        // when this api gets called from library
        /** @field library.fileSystem?.currentClassId should be used */
        classId = currentClass
          ? currentClass
          : library.fileSystem?.currentClassId;
      } else {
        classId = currentClass;
      }
    }

    let response = {};

    const isInstantLecture = instantLectureUtils()
      .is()
      .currentPageBelongsToInstantClassroom();
    if (isInstantLecture) {
      const instantLectureAction = await dispatch(instantLecture());
      const lectureId = liveClass.lectureId;
      classId = liveClass.classId;

      const res = await instantLectureAction.teacher().getFolderChildren({
        folder_id: folderId,
        class_id: classId,
        lecture_id: lectureId,
      });
      /**
       * above api returns the final response, but this action requires
       * the response in response.response format, hence we are changing the format here
       */
      response = { response: res };
    } else {
      response =
        pathName === "/dashboard" && !showMoveAndCopyFilePopup
          ? {}
          : await dispatch(getchildrenApiResponse(folderId, classId));
    }

    let fileDataAndIdMap = response.response?.children?.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    dispatch({
      type: SET_CURRENT_FOLDER_CHILDREN,
      payload: fileDataAndIdMap || {},
    });

    const elements = response.response?.children || [];
    dispatch(addLibraryData(elements, false));

    if (response.response?.element) {
      dispatch(addLibraryData([response.response?.element]));
    }
  };

export const getFolderElementData =
  (folderId, classId) => async (dispatch, getState) => {
    const {
      user: { sessionId },
      fileSystem: { currentFolderId, library },
      klass: { currentClass },
      liveClass,
    } = getState();

    classId = classId || currentClass;
    folderId = folderId || currentFolderId;
    const pathName = await getPathName();
    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/element",
      true,
      pathName === "/my-files" || pathName === "/student/my-files"
        ? {
            element_id: folderId,
            class_id: null,
          }
        : {
            element_id: folderId,
            class_id: classId || null,
          },
      sessionId,
      false
    );

    dispatch(addLibraryData([response.response.element] || []));
  };

export const shareFolderFile =
  (
    elementIds,
    classId,
    isShare,
    listOfSharedUsers,
    listOfUnshareUsers,
    recursive = true
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const pathName = await getPathName();
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "library/element/teacher/share/bulk",

      true,
      pathName !== "/my-files"
        ? {
            element_ids: elementIds,
            class_id: classId ? classId : null,
            recursive: recursive,
            share_with_class: isShare,
            share_with_users: listOfSharedUsers,
            unshare_with_users: listOfUnshareUsers,
          }
        : {
            element_ids: elementIds,
            class_id: null,
            recursive: recursive,
            share_with_class: null,
            share_with_users: listOfSharedUsers,
            unshare_with_users: listOfUnshareUsers,
          },
      sessionId,
      false
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    if (elementIds.length > 0) {
      elementIds.forEach((elementId) => {
        dispatch(getFolderElementData(elementId, classId));
      });
    }

    dispatch({
      type: SET_ELEMENT_SHARE,
      payload: { elementId: elementIds[0], isShare },
    });
    return response;
  };

export const shareStudentFolderFile =
  (
    elementIds,
    // classId,
    listOfSharedUsers,
    listOfUnshareUsers,
    recursive = true
  ) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();

    const response = await makeApiRequest(
      "POST",
      backendApi,
      "library/element/student/share/bulk",
      true,
      {
        element_ids: elementIds,
        // class_id: classId,
        recursive: recursive,
        share_with_users: listOfSharedUsers,
        unshare_with_users: listOfUnshareUsers,
      },
      sessionId,
      false
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return false;
    }
    if (elementIds.length > 0) {
      elementIds.forEach((elementId) => {
        dispatch(getFolderElementData(elementId));
      });
    }
    return response;
  };

export const setFsRecentUsers = () => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "library/recently-used-users",
    true,
    {},
    sessionId,
    false
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
    return false;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return false;
  }
  dispatch({
    type: GET_RECENT_USERS,
    payload: response.response.users,
  });
};

export const setCurrentFolderId =
  (folderId, skipSettingClass = false, currentClassId) =>
  async (dispatch, getState) => {
    const {
      fileSystem,
      user: { sessionId },
      klass: { classes, currentClass },
      smartboard: { slideShareState: prevSlideShareState },
    } = getState();
    const { folderToClassIdMap, currentFolderId } = fileSystem;

    if (!folderId) {
      folderId = currentFolderId;
    }

    let { classId } = currentClassId
      ? {
          classId: currentClassId,
        }
      : getClassAndLectureOfElement(folderId);
    dispatch({
      type: SET_CURRENT_FOLDER_ID,
      payload: folderId,
    });

    // fetch elements of the folder

    await dispatch({
      type: SET_IS_FILE_SYSTEM_DATA_LOADED,
      payload: false,
    });

    classId = classId || currentClass;

    dispatch(setSlideShareState(SLIDE_SHARE_STATE.LOADING));
    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/folder/children",
      true,
      {
        element_id: folderId,
        class_id: classId ? classId : null,
        // shared_to_group: classes[classId]?.student_group_id,
      },
      sessionId,
      false
    );
    const {
      smartboard: { slideShareState },
    } = getState();

    if (slideShareState === SLIDE_SHARE_STATE.LOADING)
      dispatch(setSlideShareState(prevSlideShareState));
    let finalElementList = (response.response?.element_list || []).reduce(
      (acc, value) =>
        acc.concat(Object.values(convertDataToFrontEndFormat(value))),
      []
    );

    dispatch(addLibraryData(finalElementList, false));

    dispatch(
      addLibraryData([
        {
          id: folderId,
          fetched: true,
        },
      ])
    );
    await dispatch({
      type: SET_IS_FILE_SYSTEM_DATA_LOADED,
      payload: true,
    });

    if (!skipSettingClass && folderToClassIdMap[folderId]) {
      dispatch(setCurrentClass(folderToClassIdMap[folderId]));
    }
  };

export const setClassBrowserVisibility = (newValue) => (dispatch) => {
  dispatch({
    type: SET_CLASS_BROWSER_VISIBILITY,
    payload: newValue,
  });
};

export const setAddFolderVisibility = (newValue) => (dispatch) => {
  dispatch({
    type: SET_ADD_FOLDER_VISIBILITY,
    payload: newValue,
  });
};

export const setScreenContext = (newValue) => (dispatch) => {
  dispatch({
    type: SET_SCREEN_CONTEXT,
    payload: newValue,
  });
};

export const setTopTrashElementIdList =
  (topTrashElementIdList) => async (dispatch) => {
    await dispatch({
      type: SET_FS_TOP_TRASH_ELEMENT_ID_LIST,
      payload: topTrashElementIdList,
    });
  };

export const getRecentFiles = () => async (dispatch, getState) => {
  const { user } = getState();
  const { sessionId } = user;
  dispatch(setLoader({ active: true, message: "Fetching recent files..." }));

  const recentApiResponse = await makeApiRequest(
    "POST",
    backendApi,
    "library/get_recent",
    true,
    {},
    sessionId
  );

  dispatch(setLoader({ active: false }));

  if (recentApiResponse.error) {
    return;
  }

  dispatch({
    type: SET_FS_RECENT_FILE_DATE_LIST,
    payload: recentApiResponse.response.recent_elements,
  });
};

export const permanentDeleteElement =
  (elementId, classId, skipLibraryDataReload = false) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
      klass: { currentClass, classes },
      fileSystem: { library },
    } = getState();

    classId = classId || currentClass;

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    if (module === modules.LIBRARY) {
      classId = library.fileSystem.currentClassId;
    }

    const elementDeleteResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/element/delete/permanent",
      true,
      {
        class_id: classId,
        element_id: elementId,
      },
      sessionId,
      false
    );

    if (elementDeleteResponse.error) {
      return;
    }

    if (!skipLibraryDataReload) await dispatch(getTrashFileList());
  };

export const permanentDeleteElementBulk =
  (elementIdList) => async (dispatch, getState) => {
    const {
      user: { sessionId, role },
      klass: { classes },
    } = getState();

    // parse to array of Int

    elementIdList = elementIdList?.reduce((acc, id) => {
      acc.push(parseInt(id, 10));
      return acc;
    }, []);

    let classIds = Object.keys(classes)?.reduce((acc, id) => {
      acc.push(parseInt(id, 10));
      return acc;
    }, []);

    const elementDeleteResponse = await makeApiRequest(
      "POST",
      backendApi,
      role === "teacher"
        ? "library/element/delete/permanent/bulk"
        : "library/student/element/delete/permanent",
      true,
      { element_ids: elementIdList, class_ids: classIds },
      sessionId,
      false
    );

    if (elementDeleteResponse.error) {
      return;
    }

    dispatch(getTrashFileList());
  };

export const clearLectureUploadData = () => async (dispatch) => {
  await dispatch({
    type: CLEAR_UPLOAD_LECTURE_DATA,
  });
};

export const moveFilesBulk =
  (elementIdList, targetParentId, skipLibraryDataReload = false) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;
    await makeApiRequest(
      "POST",
      backendApi,
      "library/move_files",
      true,
      {
        element_id_list: elementIdList,
        target_parent_id: targetParentId,
      },
      sessionId
    );
    if (!skipLibraryDataReload) await dispatch(getLibraryData());
  };

export const moveFiles =
  (elementIdList, targetParentId, currentClassId, targetClassId) =>
  async (dispatch, getState) => {
    const {
      user: { sessionId },
      fileSystem: { fileSystemData, currentFolderId },
    } = getState();

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    let { classId } = currentClassId
      ? {
          classId: currentClassId,
        }
      : getClassAndLectureOfElement(elementIdList[0]);

    const moveApiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "library/element/move/bulk",
      true,
      module === modules.MYBAG
        ? {
            element_ids: elementIdList,
            destination_parent_id: targetParentId,
          }
        : {
            element_ids: elementIdList,
            destination_parent_id: targetParentId,
            class_id: classId || null,
            destination_class_id: targetClassId,
          },
      sessionId
    );

    if (moveApiResponse.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (moveApiResponse.error) {
      dispatch(errorAlert(moveApiResponse.message));
      return;
    }

    if (pathName !== "/dashboard") {
      dispatch(removeCurrentFolderChildren(currentFolderId, elementIdList)); // only works if the deleted element's parent is currentFolderId
      dispatch(getFolderChildElementList());
    } else {
      dispatch(removeFromDashboard(elementIdList[0]));
    }
  };

export const copyFiles =
  (elementIdList, targetParentId, currentClassId, targetClassId, inherit) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const { sessionId } = user;

    const pathName = await getPathName();
    const module = routes[pathName].belongsTo;

    if (!elementIdList || elementIdList.length == 0) return;

    let { classId } = currentClassId
      ? {
          classId: currentClassId,
        }
      : pathName === "/dashboard"
      ? { classId: null }
      : getClassAndLectureOfElement(elementIdList[0]);

    const copyApiResponse = await makeApiRequest(
      "POST",
      backendApi,
      "/library/element/copy/bulk",
      true,
      module === modules.MYBAG
        ? {
            element_ids: elementIdList,
            destination_parent_id: targetParentId,
            class_id: classId,
          }
        : {
            element_ids: elementIdList,
            destination_parent_id: targetParentId,
            class_id: classId,
            destination_class_id: targetClassId,
            inherit,
          },
      sessionId
    );

    if (copyApiResponse.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (copyApiResponse.error) {
      dispatch(errorAlert(copyApiResponse.message));
      return;
    }
  };

export const getElementDownloadUrlResponse =
  (elementId, classId) => async (dispatch, getState) => {
    const sessionId = await dispatch(getSessionId());

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "library/file",
      true,
      { element_id: elementId, class_id: classId || null },
      sessionId
    );

    return response;
  };

export const getElementDownloadUrl =
  (elementId, classId, fallbackToCurrentClass = true) =>
  async (dispatch, getState) => {
    const {
      klass: { currentClass },
    } = getState();

    if (fallbackToCurrentClass) {
      classId = classId || currentClass;
    }

    const elementDownloadUrlResponse = await dispatch(
      getElementDownloadUrlResponse(elementId, classId)
    );
    const { download_url } = elementDownloadUrlResponse.response;
    return download_url;
  };

export const moveToLiveLectureFolder = () => (dispatch, getState) => {
  const {
    fileSystem: { fileSystemData },
    user: { folder_id },
    klass: { lectures },
    liveClass: { classId, lectureId },
  } = getState();

  const isInstantLecture = instantLectureUtils()
    .is()
    .currentPageBelongsToInstantClassroom();

  // when user starts or joins live class,
  // then currentClass and lecture details are already set before this action gets called
  // we will be using those details to set the liveClass.fileSystem ids
  dispatch(setLiveClassFileSystemIds(classId, lectureId));

  const lecture_folder_id = lectures[lectureId]?.folder_id;
  if (fileSystemData[lecture_folder_id] || isInstantLecture) {
    dispatch(setActiveFolderId(lecture_folder_id));
    dispatch(getFolderChildElementList());
  } else {
    dispatch(setActiveFolderId(folder_id));
    dispatch(getFolderChildElementList());
  }
};

export const setCurrentFileId = (id) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_FILE_ID,
    payload: id,
  });
};

export const getLibraryStartup = () => async (dispatch, getState) => {
  const {
    user: { sessionId, role },
    klass: { classes },
    fileSystem: { isDataLoaded },
  } = getState();

  /**
   * if teacher, filter out temp classes
   * if student, filter out classes to which student does not belongs
   */
  const userClassList = Object.keys(classes).filter((id) =>
    role === "teacher"
      ? classes[id].status === "active"
      : classes[id].user_class_status === "active" &&
        classes[id].status === "active"
  );
  if (userClassList.length <= 0) {
    return;
  }

  const startupApiResponse = await makeApiRequest(
    "GET",
    backendApi,
    "library/startup",
    true,
    { class_ids: userClassList.toString() },
    sessionId
  );

  if (startupApiResponse.logout) {
    dispatch(logoutProcedure(false));
    return;
  }

  if (startupApiResponse.error) {
    dispatch(errorAlert(startupApiResponse.message));
    return;
  }

  let recentFiles = startupApiResponse.response.recent_files;
  let starredFiles = startupApiResponse.response.starred_files;
  let sharedWithUser = startupApiResponse.response.shared_with_user;

  dispatch(addLibraryData(recentFiles));
  dispatch(addLibraryData(starredFiles));
  dispatch(addLibraryData(sharedWithUser));

  dispatch({
    type: SET_FS_RECENT_FILE_DATE_LIST,
    payload: recentFiles,
  });

  dispatch({
    type: SET_FS_STARRED_FILES_LIST,
    payload: starredFiles,
  });

  dispatch({
    type: SET_FS_SHARED_WITH_USER_FILE_LIST,
    payload: sharedWithUser,
  });
};

export const getTrashFileList = () => async (dispatch, getState) => {
  const {
    user: { sessionId, role: userRole },
    klass: { classes },
  } = getState();

  const classIds = Object.keys(classes).filter((id) =>
    userRole.toUpperCase() === TEACHER
      ? classes[id].status === "active"
      : classes[id].user_class_status === "active" &&
        classes[id].status === "active"
  );

  if (classIds.length <= 0) {
    // when user has 0 class and tries to see trash details
    // no need to call the api in this case
    return;
  }

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "library/element/trashed",
    true,
    { class_ids: classIds.toString() },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  let trashMap = response.response.trashed_elements?.reduce((acc, file) => {
    acc[file.id] = file;
    return acc;
  }, {});

  dispatch({
    type: SET_TRASH_ELEMENTS_MAP,
    payload: trashMap || {},
  });

  dispatch(addLibraryData(response.response?.trashed_elements || []));
};

export const setActiveFolderId = (folderId) => (dispatch, getState) => {
  const {
    fileSystem: { currentFolderId },
  } = getState();

  folderId = folderId || currentFolderId;
  dispatch({
    type: SET_CURRENT_FOLDER_ID,
    payload: folderId,
  });
};

export const setTreeSelectedFolderId =
  (folderId, dontSetActiveFolder = false) =>
  (dispatch) => {
    dispatch({ type: SET_TREE_SELECTED_FOLDER_ID, payload: folderId });
    if (dontSetActiveFolder) {
      return;
    }
    dispatch(setActiveFolderId(folderId));
  };

export const restoreElement =
  (elementId, classId) => async (dispatch, getState) => {
    const {
      user: { sessionId, role },
      klass: { currentClass },
    } = getState();

    classId = classId || currentClass;

    const response = await makeApiRequest(
      "POST",
      backendApi,
      role === "teacher"
        ? "library/element/restore"
        : "library/student/element/restore",
      true,
      { class_id: classId, element_id: elementId },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return;
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    dispatch(getTrashFileList());
  };

export const restoreAll = (elementIdList) => async (dispatch, getState) => {
  const {
    user: { sessionId, role },
    klass: { classes },
  } = getState();

  // parse to array of Int

  elementIdList = elementIdList?.reduce((acc, id) => {
    acc.push(parseInt(id, 10));
    return acc;
  }, []);

  let classIds = Object.keys(classes)?.reduce((acc, id) => {
    acc.push(parseInt(id, 10));
    return acc;
  }, []);

  const response = await makeApiRequest(
    "POST",
    backendApi,
    role === "teacher"
      ? "library/element/restore/bulk"
      : "library/student/element/restore",

    true,
    {
      element_ids: elementIdList,
      class_ids: classIds,
    },
    sessionId,
    false
  );

  if (response.error) {
    return;
  }

  dispatch(getTrashFileList());
};

export const renameElementInRedux =
  ({ newName, elementId }) =>
  (dispatch) => {
    dispatch({
      type: RENAME_FILE_FOLDER,
      payload: {
        newName,
        elementId,
      },
    });
  };

export const renameElementFromChildrenInRedux =
  ({ newName, elementId }) =>
  (dispatch) => {
    dispatch({
      type: RENAME_FILE_FOLDER_CHILDREN,
      payload: {
        newName,
        elementId,
      },
    });
  };

export const renameFileFolder =
  (classId, elementId, newName) => async (dispatch, getState) => {
    const {
      user: { sessionId },
    } = getState();
    const pathName = await getPathName();

    const response = await makeApiRequest(
      "PUT",
      backendApi,
      "/library/element",
      true,
      pathName === "/my-files" || pathName === "/student/my-files"
        ? {
            element_id: elementId,
            class_id: null,
            name: newName,
          }
        : {
            element_id: elementId,
            class_id: classId || null,
            name: newName,
          },

      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return false;
    }
    if (response.error) {
      return false;
    }
    dispatch(renameElementInRedux({ newName, elementId }));
    dispatch(renameElementFromChildrenInRedux({ newName, elementId }));
    return true;
  };

export const setLibraryFileSystemIds =
  (currentClassId = null, currentLectureId = null) =>
  (dispatch, getState) => {
    const idsObj = {
      currentClassId,
      currentLectureId,
    };
    dispatch({
      type: SET_LIBRARY_FILESYSTEM_IDS,
      payload: idsObj,
    });
  };

export const getFileDetailsById =
  (elementId, currentClassId) => async (dispatch, getState) => {
    let {
      user: { sessionId },
      klass: { currentClass },
    } = getState();

    if (currentClassId) {
      currentClass = currentClassId;
    }

    const response = await makeApiRequest(
      "GET",
      backendApi,
      "/library/element",
      true,
      { element_id: elementId, class_id: currentClass },
      sessionId
    );
    return response;
  };
