import produce from "immer";

import {
  TOGGLE_ON,
  TOGGLE_OFF,
  SELECT_OPTION,
  SET_BROADCAST,
  SET_MAINSCREEN,
  SET_WHITEBOARD_DIMENSION,
  SET_VIDEO_STATE,
  SET_CLICKED_SLIDE,
  SET_SHOW_SLIDES_POPUP,
  SET_SHOW_WHITEBOARD_SELECTOR_POPUP,
  SET_SLIDE_POPUP_FOR,
  SET_SLIDE_SHARE_STATE,
  SET_SHOW_TOOLBAR,
  SET_HOVER_SMARTBOARD,
  SET_SHOW_CLASS_CODE,
  RESET_SMARTBOARD,
  SET_HOVER_RHS_VIEW,
  SET_MEDIA_SHARE_STATE,
  SET_MAINSCREEN_SLIDE_INFO,
  SET_ALREADY_FETCHED_SLIDES_MAP,
  HIDE_YOUTUBE_IN_DOM,
  SET_YOUTUBE_INITIALIZED,
  SET_IS_MIROBOARD,
  SET_MIRO_WHITEBOARD,
} from "../types";
import {
  MEDIA_SHARE_OPTIONS,
  OPTIONS,
  SLIDE_SHARE_STATE,
  VIDEO_OPTIONS,
} from "../../utils/constants";

const initialState = {
  // smartboard control panel options
  selected: OPTIONS.SLIDESHARE,
  top: OPTIONS.SCREENSHARE,
  bottom: OPTIONS.WHITEBOARD,

  broadcast: OPTIONS.VIDEO_OR_DP,
  mainscreen: OPTIONS.INSTRUCTIONS,

  videoState: VIDEO_OPTIONS.small,

  mediaShareState: MEDIA_SHARE_OPTIONS.SLIDES,

  selectedSlide: null,
  selectedWhiteboard: null,

  whiteboardContainerWidth: 0,
  whiteboardContainerHeight: 0,

  showSlidesPopup: false,
  showWhiteboardSelectorPopup: false,
  clickedSlide: 0,
  slidePopupFor: null,
  isSlideImageListFetched: false,
  slideShareState: SLIDE_SHARE_STATE.FILE_OR_FOLDER,
  showToolbar: true,
  hoverSmartBoard: false,
  hoverRHSView: false,
  showClassCode: true,
  mainscreenSlideInfo: null, // check out below description
  alreadyFetchedSlidesMap: {},
  hideTeacherYoutubeInDom: false,
  isYoutubeInitialized: false,
  isMiroboard: false,
  miroWhiteboard: {},
};

/**
 * @param mainscreenSlideInfo is responsible for a particular slide being visible in smartboard mainscreen
 * It ensures that if new slides are fetched then mainscreen slide shouldn't get affected until and unless user
 * selects any of newly fetched slides.
 * ALL FUNCTIONALITY HANDLING THE SLIDE CHANGE IN THE SLIDESHARE BOARD SHOULD ALSO CHANGE THIS STATE
 */

const classReducer = produce((state, action) => {
  switch (action.type) {
    case TOGGLE_ON: {
      state.broadcast = state.selected;
      return;
    }
    case TOGGLE_OFF: {
      state.broadcast = OPTIONS.VIDEO_OR_DP;
      return;
    }
    case SET_BROADCAST: {
      state.broadcast = action.payload;
      return;
    }
    case SELECT_OPTION: {
      const { option } = action.payload;

      const currentSelection = state.selected;
      const newSelection = state[option];

      const newOption =
        state[option] === OPTIONS.SCREENSHARE
          ? OPTIONS.SCREENSHARE
          : state[option] === OPTIONS.WHITEBOARD
          ? OPTIONS.SLIDESHARE
          : OPTIONS.WHITEBOARD;

      state.selected = newSelection;
      state[option] = newOption;
      state.mainscreen = newSelection;

      // const newBroadcast =
      //   newSelection === OPTIONS.SCREENSHARE
      //     ? OPTIONS.SCREENSHARE
      //     : state.broadcast;
      // state.broadcast = newBroadcast;
      return;
    }
    case SET_MAINSCREEN: {
      state.mainscreen = action.payload;
      return;
    }
    case SET_WHITEBOARD_DIMENSION: {
      const { whiteboardContainerWidth, whiteboardContainerHeight } =
        action.payload;
      state.whiteboardContainerWidth = whiteboardContainerWidth;
      state.whiteboardContainerHeight = whiteboardContainerHeight;
      return;
    }
    case SET_VIDEO_STATE: {
      state.videoState = action.payload;
      return;
    }
    case SET_CLICKED_SLIDE: {
      state.clickedSlide = action.payload;
      return;
    }
    case SET_SHOW_SLIDES_POPUP: {
      state.showSlidesPopup = action.payload;
      return;
    }
    case SET_SHOW_WHITEBOARD_SELECTOR_POPUP: {
      state.showWhiteboardSelectorPopup = action.payload;
      return;
    }
    case SET_SLIDE_POPUP_FOR: {
      state.slidePopupFor = action.payload;
      return;
    }
    case SET_SLIDE_SHARE_STATE: {
      state.slideShareState = action.payload;
      return;
    }
    case SET_SHOW_TOOLBAR: {
      state.showToolbar = action.payload;
      return;
    }
    case SET_HOVER_SMARTBOARD: {
      state.hoverSmartBoard = action.payload;
      return;
    }
    case SET_HOVER_RHS_VIEW: {
      state.hoverRHSView = action.payload;
      return;
    }
    case SET_SHOW_CLASS_CODE: {
      state.showClassCode = action.payload;
      return;
    }
    case SET_MEDIA_SHARE_STATE: {
      state.mediaShareState = action.payload;
      return;
    }
    case SET_MAINSCREEN_SLIDE_INFO: {
      state.mainscreenSlideInfo = action.payload;
      return;
    }
    case SET_ALREADY_FETCHED_SLIDES_MAP: {
      const { fileId, slidesDataOfFile } = action.payload;
      state.alreadyFetchedSlidesMap[fileId] = slidesDataOfFile;
      return;
    }
    case HIDE_YOUTUBE_IN_DOM: {
      state.hideTeacherYoutubeInDom = action.payload;
      return;
    }
    case SET_YOUTUBE_INITIALIZED: {
      state.isYoutubeInitialized = action.payload;
      return;
    }
    case SET_IS_MIROBOARD: {
      state.isMiroboard = action.payload;
      return;
    }
    case SET_MIRO_WHITEBOARD: {
      state.miroWhiteboard = action.payload;
      return;
    }
    case RESET_SMARTBOARD: {
      return initialState;
    }
    default:
      return;
  }
}, initialState);

export default classReducer;
