import produce from "immer";
import { SET_INSTITUTE_ADMINS_LIST } from "../types";

const initialState = {
  instituteAdmins: [],
};

const instituteReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_INSTITUTE_ADMINS_LIST: {
      state.instituteAdmins = action.payload;
      break;
    }
    default:
      return state;
  }
}, initialState);

export default instituteReducer;
