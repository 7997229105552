import {
  CLEAR_MISCELLANEOUS_DETAILS,
  POP_FROM_TOAST_UPCOMING_QUEUE,
  PUSH_TO_TOAST_UPCOMING_QUEUE,
  SET_COPY_LINK_MESSAGE,
  SET_CURRENT_TOAST,
  SET_LAST_VISITED_ROUTE,
  SET_POPUP_DETAILS,
  SET_PRESENTATION_DIMENSIONS,
  SET_SIDEBAR_ACTIVE_OPTION,
  SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION,
  SHOW_CLASS_NAVIGATION_OPTIONS,
  SET_CALENDAR_TYPE,
  SET_POST_ON_TOP,
  SET_CONNECTION_ERROR_TYPE,
} from "../types";
import { modules, screenNames } from "../../components/AppRouter/routes";
import produce from "immer";

const initialState = {
  sidebarActiveOption: modules.CLASS,
  showClassNavigationOptions: false,
  classNavigationActiveOption: screenNames.liveClass,
  showCopiedContainer: false,
  lastVisitedRoute: null,
  upcomingToastQueue: [],
  currentToast: null,
  popupTitle: "",
  popupMessage: "",
  popupMessageExtra1: "",
  presentationDimensions: { width: 0, height: 0 },
  calendarType: "Weekly",
  clickedPostId: null,
  connectionErrorType: null, // to show popup for socket/jitsi connection error
};

const miscReducer = produce((state, action) => {
  switch (action.type) {
    case SET_SIDEBAR_ACTIVE_OPTION: {
      return {
        ...state,
        sidebarActiveOption: action.payload,
      };
    }
    case SHOW_CLASS_NAVIGATION_OPTIONS: {
      return {
        ...state,
        showClassNavigationOptions: action.payload,
      };
    }
    case SET_TOP_NAVIGATION_BAR_ACTIVE_OPTION: {
      return {
        ...state,
        classNavigationActiveOption: action.payload,
      };
    }
    case SET_LAST_VISITED_ROUTE: {
      return {
        ...state,
        lastVisitedRoute: action.payload,
      };
    }
    case CLEAR_MISCELLANEOUS_DETAILS: {
      return {
        ...initialState,
      };
    }
    case SET_COPY_LINK_MESSAGE: {
      return {
        ...state,
        showCopiedContainer: action.payload,
      };
    }
    case PUSH_TO_TOAST_UPCOMING_QUEUE: {
      return {
        ...state,
        upcomingToastQueue: state.upcomingToastQueue.concat([action.payload]),
      };
    }
    case POP_FROM_TOAST_UPCOMING_QUEUE: {
      return {
        ...state,
        upcomingToastQueue: state.upcomingToastQueue.slice(1),
      };
    }
    case SET_CURRENT_TOAST: {
      return {
        ...state,
        currentToast: action.payload,
      };
    }
    case SET_POPUP_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_PRESENTATION_DIMENSIONS: {
      return {
        ...state,
        presentationDimensions: action.payload,
      };
    }
    case SET_CALENDAR_TYPE: {
      return {
        ...state,
        calendarType: action.payload,
      };
    }
    case SET_POST_ON_TOP: {
      return {
        ...state,
        clickedPostId: action.payload,
      };
    }
    case SET_CONNECTION_ERROR_TYPE: {
      state.connectionErrorType = action.payload;
    }
    default: {
      return state;
    }
  }
}, initialState);

export default miscReducer;
