import { SET_APP_MICROSERVICES } from "../types";

export const setAppMicroservices =
  ({ rtcUrl }) =>
  (dispatch) => {
    dispatch({
      type: SET_APP_MICROSERVICES,
      payload: {
        rtcUrl,
      },
    });
  };
