import _ from "lodash";
import makeApiRequest from "../../utils/makeApiRequest";
import {
  errorAlert,
  getSessionId,
  logoutProcedure,
  playNotificationSound,
} from "./utils";
import { backendApi } from "../../config/constants";
import {
  ADD_BUFFER_MESSAGE,
  REMOVE_BUFFER_MESSAGE,
  ADD_CHAT_ARCHIVE_MESSAGES,
  ADD_EVENT_ARCHIVE_MESSAGES,
  ADD_EVENT_REQUEST_ARCHIVE_MESSAGES,
  ADD_HANDRAISE,
  ADD_HANDRAISE_ARCHIVE,
  ADD_LIVE_EVENT_MESSAGE,
  ADD_MESSAGE,
  ADD_MIC_REQUEST,
  ADD_NEW_STUDENT_NOTEBOOK,
  ADD_PEOPLE,
  ADD_LIVECLASS_PEOPLE,
  ADD_REQUEST,
  ADD_VIDEO_REQUEST,
  BUFFER_TO_EVENT,
  BUFFER_TO_MESSAGE,
  ADD_SCREENSHARE_REQUEST,
  REMOVE_SCREENSHARE_REQUEST,
  REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID,
  RESET_SCREENSHARE_REQUEST_LIST,
  REMOVE_HANDRAISE,
  REMOVE_MIC_REQUEST,
  REMOVE_MIC_REQUEST_FROM_REQUEST_ID,
  REMOVE_REQUEST,
  REMOVE_REQUESTS_OF_USER,
  REMOVE_VIDEO_REQUEST,
  REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID,
  RESET_LIVECLASS,
  RESET_MIC_REQUEST_LIST,
  RESET_VIDEO_REQUEST_LIST,
  SET_CHAT_SHOWING_CONTEXT_MENU,
  SET_CLICKED_END_SESSION_FOR_ALL,
  SET_GRID_ICON_ACTIVE,
  SET_LARGE_PEOPLE_TAB_ACTIVE,
  SET_SHOW_RHS_PEOPLE_TAB,
  SET_LIVE_CLASS_ACTIVE,
  SET_LIVE_CLASS_FILES_SELECTED,
  SET_LIVE_CLASS_FILES_SELECTED_NONE,
  SET_LIVE_CLASS_FILES_STATE,
  SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY,
  SET_LIVE_CLASS_TIME_ELAPSED,
  SET_LIVE_CLASS_TIMER_DETAILS,
  SET_LIVE_CLASS_WHITEBOARD_DATA,
  SET_LIVE_VIDEO_STATE,
  SET_NEW_WHITEBOARD_ID,
  SET_PAPER_ACTIVE_LAYER_ID,
  SET_SET_LARGE_PRESENTATION_TAB_ACTIVE,
  SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD,
  SET_SHOW_HAND_RAISE_TIMER,
  SET_SHOW_JOIN_REQUEST_DENIED,
  SET_SHOW_JOIN_REQUEST_PENDING,
  SET_SHOW_KICK_OUT_POPUP,
  SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION,
  SET_SHOW_RATE_SESSION_POPUP,
  SET_SHOW_REQUESTING_SCREENSHARE,
  SET_SHOW_REQUEST_DENIED,
  SET_SHOW_REQUEST_PENDING,
  SET_SHOW_REQUESTING_AUDIO,
  SET_SHOW_REQUESTING_VIDEO,
  SET_SLIDEBOARD_ACTIVE_LAYER_ID,
  SET_SLIDEBOARD_LAYER_INTIALIZE_STATE,
  SET_SMALL_VIDEO_VIEW_ACTIVE,
  SET_STUDENT_LIVE_CLASS_FULL_SCREEN,
  SET_STUDENT_LIVE_CLASS_LOADER,
  SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA,
  SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION,
  SET_STUDENT_VIDEO_MINIMIZED,
  SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT,
  SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID,
  SET_SUBMISSION_WHITEBOARD_ACTIVE,
  SET_SUBMISSION_WHITEBOARD_DATA,
  SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW,
  SET_WHITEBOARD_ACTIVE,
  SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO,
  SET_WHITEBOARD_ACTIVE_LAYER_ID,
  SET_WHITEBOARD_DATA,
  UPDATE_BUFFER_MESSAGE,
  UPDATE_LIVECLASS_TOPIC,
  UPDATE_PEOPLE,
  SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP,
  SET_NOTEBOOK_DELETE_LAYER_ID,
  SET_WHITEBOARD_DELETE_LAYER_ID,
  SET_WHITEBOARD_SLIDE_ID,
  SET_FILE_DELETE_ID,
  UPDATE_STUDENT_NOTEBOOK_IDS,
  UPDATE_WHITEBOARD_IDS,
  SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID,
  SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED,
  SET_HANDRAISE_REQUEST_ID,
  UPDATE_EVENT_STATUS,
  SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP,
  SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP,
  UPDATE_RECORDING_STATE,
  DELETE_CHAT_MESSAGE,
  SET_YOUTUBE_VIDEO_SHARE_CONTROLS,
  SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED,
  SET_SHOW_LOAD_MEDIA_POPUP,
  SET_BROADCASTED_ACTIVE_LAYER_ID,
  SET_LIVE_CLASS,
  SET_LIVE_CLASS_FILESYSTEM_IDS,
  SET_DISPLAY_PARTICIPANT_ID,
  SET_NOTIFICATION_HEADER,
  SET_CONNECTION_ISSUE,
  SET_FEEDBACK_AGGREGATE_SCORE,
  SET_SHOW_FEEDBACK_INPUT,
  SET_USER_PREVIOUS_FEEDBACK_SCORE,
  SET_STUDENT_YOUTUBE_INITIALIZED,
  SET_LIVECLASS_LAST_VIEWED_MESSAGE,
  SET_LIVECLASS_UNREAD_MESSAGES,
  SET_SHOW_LINK_CONNECT_POPUP,
  SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP,
  SET_SHOW_LINK_DEVICE_BROADCAST_POPUP,
  SET_DEVICE_INFO,
  SET_NEW_PARTICIPANTS_LIST,
  SET_LAYER_GRID_STATE,
  SET_ENTRY_REQUESTS_POPUP,
  SET_STUDENTS_REQUESTS_POPUP,
} from "../types";

import {
  ACCEPT_MIC_OR_VIDEO_REQUEST,
  CANCEL_MIC_OR_VIDEO_REQUEST,
  CHANGE_CLASS_SETTINGS,
  CHAT_ARCHIVE,
  CHAT_MESSAGE,
  CONNECT,
  EVENT_ARCHIVE,
  EVENT_REQUESTS_ARCHIVE,
  HAND_DOWN,
  HAND_RAISE,
  HAND_RAISE_ARCHIVE,
  HAND_RAISE_ACCEPT,
  HAND_RAISE_REJECT,
  HAND_RAISE_REQUEST,
  HAND_RAISE_TIMEOUT,
  JOIN_LIVE_CLASS,
  LEAVE_LIVE_CLASS,
  liveEvents,
  MIC_ON,
  REJECT_MIC_OR_VIDEO_REQUEST,
  REQUEST_MIC_OR_VIDEO,
  ACCEPT_SCREENSHARE_REQUEST,
  REJECT_SCREENSHARE_REQUEST,
  CANCEL_SCREENSHARE_REQUEST,
  SUBMIT_WHITEBOARD,
  USER_JOIN_ACCEPT,
  USER_JOIN_CLASS_ACCEPT,
  USER_JOIN_CLASS_REJECT,
  USER_JOIN_REJECT,
  USER_JOIN_REQUEST,
  USER_JOINED,
  USER_LEFT,
  WHITEBOARD,
  WHITEBOARD_ARCHIVE,
  NOTEBOOK_ARCHIVE,
  boardType,
  AUTOSAVE_WHITEBOARD,
  RECORDING_STATE,
  YOUTUBE_VIDEO_SHARE,
  SUBMIT_LIVE_FEEDBACK,
  REQUEST_SCREENSHARE,
  KICK_PARTICIPANT,
  SLIDESHARE_PREVIEW_AREA_UPDATE,
  FEEDBACK_POPUP,
  TRIGGER_FEEDBACK,
  LINK_DEVICE_CONNECTION_REQUEST_APP,
  LINK_DEVICE_CONNECTION_RESPONSE,
  SCREEN_SHARE_STARTED_APP,
  LINK_DEVICE_DISCONNECTED,
} from "../../utils/socketEvents";
import {
  calculateParticipantsList,
  cleanupJitsi,
  initJitsiConnection,
  muteMyAudio,
  muteMyVideo,
  setKickedFromConference,
  startDesktopSharing,
  setMyJitsiUserName,
} from "./jitsiActions";
import {
  MEDIA_SHARE_OPTIONS,
  OPTIONS,
  RECORDING_BOT,
  SLIDE_SHARE_STATE,
  STUDENT,
  TEACHER,
  VIDEO_TYPES,
} from "../../utils/constants";
import {
  liveVideoStateOptions,
  paperboardsLayerBelongsTo,
  slidePopupClickedFrom,
} from "../../utils/tinyUtils";
import { addToastNotification } from "./miscellaneousActions";

import { setCurrentSlideIndex, setLectureFileList } from "./lectureActions";
import { resetSmartboardControls, setSlidePopupFor } from "./smartboard";
import {
  setLiveClassSettings,
  setSubmissionDetails,
  updateClassSettingsInStore,
} from "./classActions";
import {
  acceptMicOrVideoRequest,
  rejectMicOrVideoRequest,
} from "./socketActions";
import dayjs from "dayjs";
import {
  removeSiteCloseRestriction,
  resetRecorderState,
  stopRecording,
} from "./recorderActions";
import { getParticipantByUserId } from "../../utils/jitsi";
import {
  getFolderChildElementList,
  removeCurrentFolderChildren,
} from "./fileSystemActions";
import { cleanupJitsiX } from "./jitsiXActions";
import { noSleep } from "../../utils/misc";
import {
  handleLiveQuizStateFromArchive,
  removeLiveQuizSocketListenersForStudents,
  removeLiveQuizSocketListenersForTeachers,
} from "./liveQuizActions";
import { getSocket } from "../../utils/socketio";
import {
  addSocketListenersForced,
  removeSocketListeners,
} from "../../utils/socket/listeners";

export const setLiveClass = (classId) => (dispatch) => {
  dispatch({ type: SET_LIVE_CLASS, payload: classId });
};

export const getLiveClassArchive = () => async (dispatch, getState) => {
  let {
    user: { role },
    liveClass: { lectureId: liveLectureId },
  } = getState();

  const sessionId = await dispatch(getSessionId());

  /**
   * use v2/archive to get the `quick_question` archive data as well
   */
  const endpoint =
    role?.toUpperCase() === RECORDING_BOT
      ? "lectures/lecture/archive-for-recording-bot"
      : "lectures/lecture/v2/archive";

  const response = await makeApiRequest(
    "GET",
    backendApi,
    endpoint,
    true,
    { lecture_id: liveLectureId },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  const archive = response.response.archive;
  let people = {};
  for (const [userId, person] of Object.entries(archive.people)) {
    if (userId in archive.platform) {
      person.platform = archive.platform[userId];
    }
    people[userId] = person;
  }
  dispatch(addPeopleFromArchive(people));
  dispatch(addChatArchive(archive.chat));
  dispatch(addEventArchive(archive.events));
  dispatch(addEventRequestsArchive(archive.requests));
  dispatch(setStudentLiveClassWhiteboardData(archive.presenting_whiteboard));
  dispatch(handleRecordingState(archive.recording_state));
  dispatch(setUnreadMessagesCount(archive.chat.length));
  dispatch(handleLiveQuizStateFromArchive(archive.quick_question));
  if (role.toUpperCase() === TEACHER) {
    // when there is already a teacher in class, other teachers join in viewing mode
    // Below code has been moved from liveclass.js to here because of the re-renders caused by the liveclass.people prop
    // everytime participants enter or leave the live class.
    const liveClassPeople = archive.people;
    const teachersInClass = Object.values(liveClassPeople).filter(
      (person) => person.role && person.role.toUpperCase() === TEACHER
    );
    if (teachersInClass.length > 1) {
      dispatch(enterClassroomStudentPresentationView());
    }
    // whiteboard is not present for teachers
    dispatch(getWhiteboardArchive(archive.whiteboard));
    dispatch({
      type: SET_FEEDBACK_AGGREGATE_SCORE,
      payload: archive.live_feedback,
    });
  }
  if (role.toUpperCase() === STUDENT) {
    // notebook is not present for teachers
    dispatch(handleStudentNotebookArchiveData(archive.notebook));
    dispatch({
      type: SET_USER_PREVIOUS_FEEDBACK_SCORE,
      payload: archive.live_feedback,
    });
  }
};

export const setConnectionIssue = (value) => (dispatch) => {
  dispatch({ type: SET_CONNECTION_ISSUE, payload: value });
};

export const deleteMessage = (id) => async (dispatch, getState) => {
  const {
    user: { sessionId },
  } = getState();
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "lectures/lecture/chat/delete",
    true,
    { chat_id: id },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return;
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({ type: DELETE_CHAT_MESSAGE, payload: id });
};

export const resetLiveClass = () => (dispatch) => {
  dispatch({
    type: RESET_LIVECLASS,
  });
};

export const setLiveLecture = (liveLecture) => (dispatch) => {
  dispatch({
    type: UPDATE_LIVECLASS_TOPIC,
    payload: liveLecture,
  });
};

export const setWhiteboardActive = (isActive) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_ACTIVE,
    payload: isActive,
  });
};

export const setWhiteboardData = (data) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_DATA,
    payload: data,
  });
};

export const setLiveVideoState = (updatedState) => (dispatch) => {
  dispatch({
    type: SET_LIVE_VIDEO_STATE,
    payload: updatedState,
  });

  let videoType;
  if (updatedState === liveVideoStateOptions.bottomRight) {
    // bottom_right
    videoType = VIDEO_TYPES.VIDEO_SMALL;
  } else if (updatedState === liveVideoStateOptions.videoOff) {
    // off
    videoType = VIDEO_TYPES.VIDEO_SMALL;
  } else {
    // full_screen
    videoType = VIDEO_TYPES.VIDEO_NORMAL;
  }
};

export const setLiveClassWhiteboardData = (data) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_WHITEBOARD_DATA,
    payload: data,
  });
};

export const setShowLiveClassSmartboardInstruction = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LIVE_CLASS_SMARTBOARD_INSTRUCTION,
    payload: show,
  });
};

export const setSubmissionWhiteboardActive = (updatedState) => (dispatch) => {
  dispatch({
    type: SET_SUBMISSION_WHITEBOARD_ACTIVE,
    payload: updatedState,
  });
};

export const setSubmissionActiveCardDataObject = (dataObj) => (dispatch) => {
  dispatch({
    type: SET_SUBMISSION_ACTIVE_CARD_DATA_OBJECT,
    payload: dataObj,
  });
};

export const setSubmissionWhiteboardData = (data) => (dispatch) => {
  dispatch({
    type: SET_SUBMISSION_WHITEBOARD_DATA,
    payload: data,
  });
};

export const setSlideboardLayerInitializeState = (boolVal) => (dispatch) => {
  dispatch({
    type: SET_SLIDEBOARD_LAYER_INTIALIZE_STATE,
    payload: boolVal,
  });
};

export const setSmallVideoViewActive = (isActive) => (dispatch) => {
  dispatch({
    type: SET_SMALL_VIDEO_VIEW_ACTIVE,
    payload: isActive,
  });
};

export const setBroadcastedSlideLayerId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_SLIDEBOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch({
    type: SET_BROADCASTED_ACTIVE_LAYER_ID,
    payload: layerId,
  });
};

export const setBroadcastedWhiteboardLayerId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch({
    type: SET_BROADCASTED_ACTIVE_LAYER_ID,
    payload: layerId,
  });
};

export const setSlideboardActiveLayerId = (layerId) => (dispatch) => {
  // console.log({ slideboardId: layerId });
  dispatch({
    type: SET_SLIDEBOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch(setPaperActiveLayerId(layerId));
};

export const setOnlySlideboardActiveLayerId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_SLIDEBOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
};

export const setWhiteboardActiveLayerId = (layerId) => (dispatch) => {
  // console.log("setWhiteboardActiveLayerId", { layerId });
  dispatch({
    type: SET_WHITEBOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch(setPaperActiveLayerId(layerId));
};

export const setLiveClassLHSTabActiveKey = (key) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_LHS_TAB_ACTIVE_KEY,
    payload: key,
  });
};

export const setGridIconActive = (clicked) => (dispatch) => {
  dispatch({
    type: SET_GRID_ICON_ACTIVE,
    payload: clicked,
  });
};

export const setLayerGridState =
  ({ isGridVisible, layerId } = {}) =>
  (dispatch) => {
    dispatch({
      type: SET_LAYER_GRID_STATE,
      payload: { isGridVisible, layerId },
    });
  };

export const setSubmissionboardActiveLayerId = (layerId) => (dispatch) => {
  // console.log({ slideboardId: layerId });
  dispatch({
    type: SET_SUBMISSION_BOARD_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch(setPaperActiveLayerId(layerId));
};

export const setPaperActiveLayerId = (layerId) => (dispatch) => {
  // console.log({ slideboardId: layerId });
  dispatch({
    type: SET_PAPER_ACTIVE_LAYER_ID,
    payload: layerId,
  });
};

export const setWhiteboardActiveLayerBelongsTo = (belongsTo) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_ACTIVE_LAYER_BELONGS_TO,
    payload: belongsTo,
  });
};

export const addNewWhiteboard = (id) => (dispatch) => {
  dispatch({
    type: SET_NEW_WHITEBOARD_ID,
    payload: id,
  });
};

export const addNewStudentNotebook = (id) => (dispatch) => {
  dispatch({
    type: ADD_NEW_STUDENT_NOTEBOOK,
    payload: { id },
  });
};

export const setLiveClassFilesSelectedNone = (isNone) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_FILES_SELECTED_NONE,
    payload: isNone,
  });
};

export const setLiveClassFilesSelected = (selected) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_FILES_SELECTED,
    payload: selected,
  });
};

export const setLargePeopleTabActive = (isActive) => (dispatch) => {
  dispatch({
    type: SET_LARGE_PEOPLE_TAB_ACTIVE,
    payload: isActive,
  });
};

export const setShowRHSPeopleTiles = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RHS_PEOPLE_TAB,
    payload: show,
  });
};

export const setShowCopyMeetingLinkUiLayerOnWhiteboard =
  (shouldShow) => (dispatch) => {
    dispatch({
      type: SET_SHOW_COPY_MEETING_LINK_UI_LAYER_ON_WHITEBOARD,
      payload: shouldShow,
    });
  };

export const addChatMessage = (message) => (dispatch, getState) => {
  const {
    liveClass: { people = {}, studentLiveClassFullScreen, lastViewedMessageId },
  } = getState();
  if (studentLiveClassFullScreen) {
    const toastMessage = `${
      people[message?.user_id]?.name
    } has sent a message in the chat`;
    dispatch(addToastNotification({ message: toastMessage }));
  }
  dispatch({
    type: ADD_MESSAGE,
    payload: message,
  });
  if (lastViewedMessageId) {
    const {
      liveClass: { messages },
    } = getState();
    const lastViewedMessageIndex = Object.values(messages).findIndex(
      (msg) => msg.id === lastViewedMessageId
    );
    const msgCount = Object.values(messages).slice(
      lastViewedMessageIndex + 1
    ).length;
    dispatch(setUnreadMessagesCount(msgCount));
  } else {
    const {
      liveClass: { messages },
    } = getState();
    const msgCount = Object.keys(messages).length;
    dispatch(setUnreadMessagesCount(msgCount));
  }
};

export const addLiveEventMessage = (message) => (dispatch) => {
  dispatch({
    type: ADD_LIVE_EVENT_MESSAGE,
    payload: message,
  });
};

export const addChatArchive = (archive) => async (dispatch) => {
  dispatch({
    type: ADD_CHAT_ARCHIVE_MESSAGES,
    payload: archive,
  });
};

export const addEventArchive = (archive) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_ARCHIVE_MESSAGES,
    payload: archive,
  });
};

export const addEventRequestsArchive = (archive) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_REQUEST_ARCHIVE_MESSAGES,
    payload: archive,
  });
  const handRaises = archive.filter((req) => req.type === HAND_RAISE);
  dispatch(addHandRaiseArchive(handRaises));
};

export const addBufferMessage = (message) => (dispatch) => {
  // TODO: find better way for buffer id
  const bufferId = new Date().getTime();

  dispatch({
    type: ADD_BUFFER_MESSAGE,
    payload: { bufferId, message },
  });

  return bufferId;
};

export const removeBufferMessage = (bufferId) => (dispatch) => {
  dispatch({
    type: REMOVE_BUFFER_MESSAGE,
    payload: bufferId,
  });
};

export const updateBufferStatus = (bufferId, status) => (dispatch) => {
  dispatch({
    type: UPDATE_BUFFER_MESSAGE,
    payload: { bufferId, status },
  });
};

export const moveBufferToMessage = (bufferId, messageInfo) => (dispatch) => {
  dispatch({
    type: BUFFER_TO_MESSAGE,
    payload: { bufferId, messageInfo },
  });
};

export const moveBufferToEvent = (bufferId, messageInfo) => (dispatch) => {
  dispatch({
    type: BUFFER_TO_EVENT,
    payload: { bufferId, messageInfo },
  });
};

export const addRequest = (request) => (dispatch) => {
  dispatch({
    type: ADD_REQUEST,
    payload: request,
  });
};

export const removeRequest = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_REQUEST,
    payload: id,
  });
};

export const removeRequestsOfUser = (userId) => (dispatch) => {
  dispatch({
    type: REMOVE_HANDRAISE,
    payload: userId,
  });
  dispatch({
    type: REMOVE_REQUESTS_OF_USER,
    payload: userId,
  });
};

export const addHandRaiseRequest = (request) => (dispatch) => {
  dispatch(addRequest(request));
  dispatch(addLiveEventMessage(request));
  dispatch(updatePeople(request?.user_id, { raiseHand: true }));
  dispatch({
    type: ADD_HANDRAISE,
    payload: request?.user_id,
  });

  dispatch(calculateParticipantsList());
};

export const addUserJoinRequest = (request, callback) => (dispatch) => {
  dispatch({
    type: ADD_PEOPLE,
    payload: request.user,
  });
  dispatch(addRequest(request));
  dispatch(addLiveEventMessage(request));
  dispatch(playNotificationSound());
};

export const addUserJoinedMessage = (message) => (dispatch) => {
  dispatch({
    type: ADD_PEOPLE,
    payload: { ...message.user, platform: message?.extra?.platform },
  });
  dispatch(addLiveEventMessage(message));
  dispatch(setEmitYoutubeControlsOnUsersJoined(true));
};

export const updatePeople = (id, info) => (dispatch, getState) => {
  const { user } = getState();
  const { name } = info;

  if (name && id === user.id) {
    dispatch(setMyJitsiUserName(name));
  }
  dispatch({
    type: UPDATE_PEOPLE,
    payload: { id, info },
  });
};

export const addUserLeftMessage = (message) => (dispatch, getState) => {
  const {
    jitsi: { participants = {} },
    liveClass: { displayParticipantId },
  } = getState();
  dispatch(addLiveEventMessage(message));
  dispatch(removeRequestsOfUser(message?.user_id));
  if (message?.user_id === participants[displayParticipantId]?.userId) {
    dispatch(setTeacherClassroomStudentPresentationView(false));
  }
};

export const addHandRaiseArchive = (archive) => async (dispatch) => {
  dispatch({
    type: ADD_HANDRAISE_ARCHIVE,
    payload: archive.map((message) => message?.user_id),
  });

  dispatch(calculateParticipantsList());
};

export const addHandRaise = (request) => (dispatch) => {
  dispatch(addRequest(request));
  dispatch(updatePeople(request?.user_id, { raiseHand: true }));

  dispatch({
    type: ADD_HANDRAISE,
    payload: request?.user_id,
  });

  dispatch(calculateParticipantsList());
};

export const addHandRaiseMessage = (message) => (dispatch, getState) => {
  const {
    user: { id: userId },
  } = getState();
  if (message.user_id === userId) {
    dispatch(setHandRaiseRequestId(message.id));
  }
  dispatch(addHandRaise(message));
  dispatch(addLiveEventMessage(message));
};

export const setHandRaiseRequestId = (id) => (dispatch) => {
  dispatch({ type: SET_HANDRAISE_REQUEST_ID, payload: id });
};

export const removeHandRaise = (requestId) => (dispatch, getState) => {
  const {
    liveClass: { requests },
  } = getState();
  const request = requests[requestId];
  if (!request || request?.status !== "PENDING") return;

  const userId = request.user_id;

  dispatch({
    type: REMOVE_HANDRAISE,
    payload: userId,
  });
  dispatch(removeRequest(requestId));
  dispatch(updatePeople(userId, { raiseHand: false }));

  dispatch(calculateParticipantsList());
};

export const removeAllHandRaiseRequests = () => (dispatch, getState) => {
  const {
    liveClass: { requests },
  } = getState();
  Object.keys(requests)
    .filter(
      (requestId) =>
        requests[requestId].type === liveEvents.handRaise &&
        requests[requestId].status === "PENDING"
    )
    .forEach((requestId) => {
      dispatch(removeHandRaise(requestId));
    });
  dispatch(setShowHandRaiseTimer(false));
};

export const handleHandDown = (requestId) => (dispatch, getState) => {
  dispatch(removeHandRaise(requestId));
  dispatch(updateEventStatus(requestId, "DOWN"));
};

export const addPeopleFromArchive = (people) => async (dispatch) => {
  await dispatch({
    type: ADD_LIVECLASS_PEOPLE,
    payload: people,
  });
};

const removeJoinRequestsOfUser = (requestId) => (dispatch, getState) => {
  const {
    liveClass: { requests },
  } = getState();

  const request = requests[requestId];
  if (!request || request?.status !== "PENDING") return;

  const sameUserOtherJoinRequests = Object.keys(requests).filter(
    (reqId) =>
      requests[reqId].user_id === request.user_id &&
      requests[reqId].type === request.type &&
      requests[reqId].status === "PENDING"
  );
  sameUserOtherJoinRequests.map((i) => dispatch(removeRequest(i)));
};

export const handleUserJoinAccept = (requestId) => async (dispatch) => {
  dispatch(removeJoinRequestsOfUser(requestId));
  dispatch(updateEventStatus(requestId, "ACCEPTED"));
  dispatch(setEmitYoutubeControlsOnUsersJoined(true));
};

export const handleUserJoinReject = (requestId) => async (dispatch) => {
  dispatch(removeJoinRequestsOfUser(requestId));
  dispatch(updateEventStatus(requestId, "REJECTED"));
};

export const handleUserJoinClassAccept = (requestId) => async (dispatch) => {
  dispatch(removeJoinRequestsOfUser(requestId));
  dispatch(updateEventStatus(requestId, "ACCEPTED"));
  dispatch(setEmitYoutubeControlsOnUsersJoined(true));
};

export const handleUserJoinClassReject = (requestId) => async (dispatch) => {
  dispatch(removeJoinRequestsOfUser(requestId));
  dispatch(removeRequest(requestId));
  dispatch(updateEventStatus(requestId, "REJECTED"));
};

export const handleHandRaiseAccept = (requestId) => async (dispatch) => {
  dispatch(removeHandRaise(requestId));
  dispatch(updateEventStatus(requestId, "ACCEPTED"));
};

export const handleHandRaiseReject = (requestId) => async (dispatch) => {
  dispatch(removeHandRaise(requestId));
  dispatch(updateEventStatus(requestId, "REJECTED"));
};

export const updateLiveclassTopic = (topic) => (dispatch, getState) => {
  const {
    liveClass: { lectureId },
  } = getState();
  dispatch({
    type: UPDATE_LIVECLASS_TOPIC,
    payload: { topic, lectureId },
  });
};

export const setLiveClassActive = (classId, startedAt) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CLASS_ACTIVE,
    payload: classId,
  });

  dispatch(startLiveClassTimer(startedAt ? new Date(startedAt) : new Date()));
};

const startLiveClassTimer = (liveClassStartTime) => (dispatch) => {
  const liveClassInterval = setInterval(() => {
    dispatch({
      type: SET_LIVE_CLASS_TIME_ELAPSED,
      payload: (new Date() - liveClassStartTime) / 1000,
    });
  }, 500);
  dispatch({
    type: SET_LIVE_CLASS_TIMER_DETAILS,
    payload: {
      liveClassStartTime,
      liveClassInterval,
    },
  });
};

export const setLiveClassFilesState =
  (state = {}) =>
  (dispatch) => {
    dispatch({
      type: SET_LIVE_CLASS_FILES_STATE,
      payload: state,
    });
  };

export const addScreenshareRequest =
  (participantId, requestObject) => (dispatch) => {
    dispatch({
      type: ADD_SCREENSHARE_REQUEST,
      payload: { participantId, requestObject },
    });
  };

export const removeScreenshareRequest = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_SCREENSHARE_REQUEST,
    payload: participantId,
  });
};

export const removeScreenshareRequestFromRequestId =
  (requestId) => (dispatch) => {
    dispatch({
      type: REMOVE_SCREENSHARE_REQUEST_FROM_REQUEST_ID,
      payload: requestId,
    });
  };

export const resetScreenshareRequestList = () => (dispatch) => {
  dispatch({
    type: RESET_SCREENSHARE_REQUEST_LIST,
  });
};

export const addMicRequest = (participantId, requestObject) => (dispatch) => {
  dispatch({
    type: ADD_MIC_REQUEST,
    payload: { participantId, requestObject },
  });
};

export const removeMicRequest = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_MIC_REQUEST,
    payload: participantId,
  });
};

export const removeMicRequestFromRequestId = (requestId) => (dispatch) => {
  dispatch({
    type: REMOVE_MIC_REQUEST_FROM_REQUEST_ID,
    payload: requestId,
  });
};

export const resetMicRequestList = () => (dispatch) => {
  dispatch({
    type: RESET_MIC_REQUEST_LIST,
  });
};

export const addVideoRequest = (participantId, requestObject) => (dispatch) => {
  dispatch({
    type: ADD_VIDEO_REQUEST,
    payload: { participantId, requestObject },
  });
};

export const removeVideoRequest = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_VIDEO_REQUEST,
    payload: participantId,
  });
};

export const removeVideoRequestFromRequestId = (requestId) => (dispatch) => {
  dispatch({
    type: REMOVE_VIDEO_REQUEST_FROM_REQUEST_ID,
    payload: requestId,
  });
};

export const resetVideoRequestList = () => (dispatch) => {
  dispatch({
    type: RESET_VIDEO_REQUEST_LIST,
  });
};

export const setStudentLiveClassFullScreen = (isFullScreen) => (dispatch) => {
  dispatch({
    type: SET_STUDENT_LIVE_CLASS_FULL_SCREEN,
    payload: isFullScreen,
  });
};

export const setStudentLiveClassWhiteboardData = (data) => (dispatch) => {
  dispatch({
    type: SET_STUDENT_LIVE_CLASS_WHITEBOARD_DATA,
    payload: data,
  });
};

export const setStudentVideoMinimized = (isMinimized) => (dispatch) => {
  dispatch({
    type: SET_STUDENT_VIDEO_MINIMIZED,
    payload: isMinimized,
  });
};

export const setChatShowingContextMenu = (chatId) => (dispatch) => {
  dispatch({
    type: SET_CHAT_SHOWING_CONTEXT_MENU,
    payload: chatId,
  });
};

export const setStudentResizableLayoutDimension =
  (left, right) => (dispatch) => {
    dispatch({
      type: SET_STUDENT_RESIZABLE_LAYOUT_DIMENSION,
      payload: { left, right },
    });
  };

export const setShowRateSessionPopup = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_RATE_SESSION_POPUP,
    payload: value,
  });
};

export const setShowKickOutPopup = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_KICK_OUT_POPUP,
    payload: value,
  });
};

export const setShowJoinRequestPending = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_JOIN_REQUEST_PENDING,
    payload: value,
  });
};

export const setShowJoinRequestDenied = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_JOIN_REQUEST_DENIED,
    payload: value,
  });
};

export const setShowRequestPending = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_REQUEST_PENDING,
    payload: value,
  });
};

export const setShowRequestDenied = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_REQUEST_DENIED,
    payload: value,
  });
};

export const setShowRequestingVideo = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_REQUESTING_VIDEO,
    payload: value,
  });
};

export const setShowRequestingAudio = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_REQUESTING_AUDIO,
    payload: value,
  });
};

export const setShowRequestingScreenshare = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_REQUESTING_SCREENSHARE,
    payload: value,
  });
};

export const setShowHandRaiseTimer = (value) => (dispatch) => {
  dispatch({
    type: SET_SHOW_HAND_RAISE_TIMER,
    payload: value,
  });
};

export const setStudentLiveClassLoader = (value) => (dispatch) => {
  dispatch({
    type: SET_STUDENT_LIVE_CLASS_LOADER,
    payload: value,
  });
};

export const setTeacherClassroomStudentPresentationView =
  (fullScreen) => (dispatch) => {
    dispatch({
      type: SET_TEACHER_CLASSROOM_STUDENT_PRESENTATION_VIEW,
      payload: fullScreen,
    });
  };

// export const sendConnectionRequest = () => async (dispatch, getState) => {
//   const {
//     socket: { socket },
//     user: { id },
//   } = getState();

//   console.log("send connection request line 32");

//   if (!socket) return;
//   console.log("send request");

//   // const deviceDetails = await getDeviceDetails();

//   // emit(LINK_DEVICE_CONNECTION_REQUEST, {
//   //   device: "deviceDetails",
//   //   message: "device_connection_request",
//   //   user_id: id,
//   // });

//   socket.emit(
//     LINK_DEVICE_CONNECTION_REQUEST_APP,
//     {
//       device: "deviceDetails",
//       message: "device_connection_request",
//       user_id: id,
//     },
//     (success, response) => {
//       console.log("in emit connect", { response });
//       if (!success) {
//         console.log(response.message, "line 34");
//         // TODO: alert user
//         return;
//       }
//     }
//   );
// };

export const cleanupSocket = (goBackHandler) => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { role },
    liveClass: { lectureId },
  } = getState();

  if (socket) {
    socket.emit(
      LEAVE_LIVE_CLASS,
      {
        type: liveEvents.userLeft,
        message: liveEvents.messages[liveEvents.userLeft],
        is_chat: false,
        lecture_id: lectureId,
      },
      (success, response) => {
        if (!success) {
          console.log(response.message);
          // dispatch(errorAlert(response.message));
        }
      }
    );

    if (role?.toUpperCase() === TEACHER) {
      dispatch(removeLiveQuizSocketListenersForTeachers());
      dispatch(removeLiveClassSocketListenersForTeachers());
    } else if (role?.toUpperCase() === STUDENT) {
      dispatch(removeLiveQuizSocketListenersForStudents());
      dispatch(removeLiveClassSocketListenersForStudents(goBackHandler));
    }
  }
};

export const cleanupBothJitsis = () => (dispatch) => {
  dispatch(cleanupJitsi());
  dispatch(cleanupJitsiX({ fromLiveClass: true }));
};

export const cleanupLiveClass = (goBackHandler) => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { role },
    liveClass: { lectureId },
  } = getState();

  dispatch(cleanupBothJitsis());

  if (socket) {
    socket.emit(
      LEAVE_LIVE_CLASS,
      {
        type: liveEvents.userLeft,
        message: liveEvents.messages[liveEvents.userLeft],
        is_chat: false,
        lecture_id: lectureId,
      },
      (success, response) => {
        if (!success) {
          console.log(response.message);
          // dispatch(errorAlert(response.message));
        }
      }
    );

    if (role?.toUpperCase() === TEACHER) {
      dispatch(removeLiveQuizSocketListenersForTeachers());
      dispatch(removeLiveClassSocketListenersForTeachers());
    } else if (role?.toUpperCase() === STUDENT) {
      dispatch(removeLiveQuizSocketListenersForStudents());
      dispatch(removeLiveClassSocketListenersForStudents(goBackHandler));
    }
  }

  dispatch(setLectureFileList([]));
  dispatch(resetSmartboardControls());

  // disbale noSleep.js
  noSleep.disable();
  return lectureId;
};

export const setClickedEndSessionForAll = (value) => (dispatch) => {
  dispatch({
    type: SET_CLICKED_END_SESSION_FOR_ALL,
    payload: value,
  });
};

export const joinLiveClass = () => (dispatch, getState) => {
  const {
    socket: { socket },
    liveClass: { classId, lectureId },
  } = getState();

  if (!socket) {
    return;
  }

  socket.emit(
    JOIN_LIVE_CLASS,
    {
      classId: classId,
      lectureId: lectureId,
      message: liveEvents.messages[liveEvents.userJoined],
    },
    (success, response) => {
      if (!success) {
        console.log(response.message);
        // TODO: alert user
        return;
      }
      dispatch(getLiveClassArchive());
    }
  );
};

export const getWhiteboardArchive = (archiveData) => (dispatch, getState) => {
  const {
    liveClass: { whiteboardIds },
  } = getState();

  const data = archiveData?.layers;
  if (data) {
    dispatch(setWhiteboardData(data));
    data.forEach((item) => {
      // add ids to redux so that whiteboard preview can be updated
      if (
        item.belongsTo === paperboardsLayerBelongsTo.whiteboard &&
        !whiteboardIds.includes(item.id)
      ) {
        dispatch(addNewWhiteboard(item.id));
      }
    });
  }
};

export const handleUpdateClassSettings = (response) => (dispatch, getState) => {
  const {
    liveClass: { classId },
  } = getState();

  const { setDefault, settings } = response;
  _.debounce(() => {
    if (setDefault) {
      dispatch(updateClassSettingsInStore(classId, settings));
    }
    if (!settings.allow_hand_raise) {
      dispatch(removeAllHandRaiseRequests());
    }
    dispatch(setLiveClassSettings(settings));
  }, 2000)();
};

export const handleAcceptScreenshareRequest = (id) => (dispatch, getState) => {
  const {
    liveClass: { showRequestingScreenshare },
  } = getState();

  if (showRequestingScreenshare === id) {
    dispatch(startDesktopSharing());
    dispatch(setShowRequestingScreenshare(false));
  }

  dispatch(removeScreenshareRequestFromRequestId(id));
  dispatch(updateEventStatus(id, "ACCEPTED"));
};

export const handleRejectScreenshareRequest = (id) => (dispatch, getState) => {
  const {
    liveClass: { showRequestingScreenshare },
  } = getState();

  if (showRequestingScreenshare === id) {
    dispatch(setShowRequestingScreenshare(false));
  }

  dispatch(removeScreenshareRequestFromRequestId(id));
  dispatch(updateEventStatus(id, "REJECTED"));
};

export const handleCancelScreenshareRequest =
  (id) => async (dispatch, getState) => {
    const {
      liveClass: { showRequestingScreenshare },
    } = getState();
    if (showRequestingScreenshare === id) {
      dispatch(setShowRequestingScreenshare(false));
    }
    dispatch(removeScreenshareRequestFromRequestId(id));
    dispatch(updateEventStatus(id, "CANCELLED"));
  };

export const handleAcceptMicOrVideoRequest = (id) => (dispatch, getState) => {
  const {
    liveClass: { showRequestingAudio, showRequestingVideo },
  } = getState();
  if (showRequestingAudio === id) {
    dispatch(setShowRequestingAudio(false));
  }
  if (showRequestingVideo === id) {
    dispatch(setShowRequestingVideo(false));
  }
  dispatch(removeVideoRequestFromRequestId(id));
  dispatch(removeMicRequestFromRequestId(id));
  dispatch(updateEventStatus(id, "ACCEPTED"));
};

export const handleRejectMicOrVideoRequest = (id) => (dispatch, getState) => {
  const {
    liveClass: { showRequestingAudio, showRequestingVideo },
  } = getState();
  if (showRequestingAudio === id) {
    dispatch(setShowRequestingAudio(false));
  }
  if (showRequestingVideo === id) {
    dispatch(setShowRequestingVideo(false));
  }

  dispatch(removeVideoRequestFromRequestId(id));
  dispatch(removeMicRequestFromRequestId(id));
  dispatch(updateEventStatus(id, "REJECTED"));
};

export const handleCancelMicOrVideoRequest =
  (id) => async (dispatch, getState) => {
    const {
      liveClass: { showRequestingAudio, showRequestingVideo },
    } = getState();
    if (showRequestingAudio === id) {
      dispatch(setShowRequestingAudio(false));
    }
    if (showRequestingVideo === id) {
      dispatch(setShowRequestingVideo(false));
    }
    dispatch(removeVideoRequestFromRequestId(id));
    dispatch(removeMicRequestFromRequestId(id));
    dispatch(updateEventStatus(id, "CANCELLED"));
  };

// Teacher live class listeners
const liveClassSocketHandlersForTeachers = (dispatch) => {
  const composeD = (f) => (x) => dispatch(f(x));
  return [
    {
      event: CONNECT,
      handler: composeD(joinLiveClass),
    },
    {
      event: CHAT_ARCHIVE,
      handler: composeD(addChatArchive),
    },
    {
      event: EVENT_ARCHIVE,
      handler: composeD(addEventArchive),
    },
    {
      event: EVENT_REQUESTS_ARCHIVE,
      handler: composeD(addEventRequestsArchive),
    },
    {
      event: CHAT_MESSAGE,
      handler: composeD(addChatMessage),
    },
    {
      event: HAND_RAISE_REQUEST,
      handler: composeD(addHandRaiseRequest),
    },
    {
      event: USER_JOIN_REQUEST,
      handler: composeD(addUserJoinRequest),
    },
    {
      event: USER_JOIN_ACCEPT,
      handler: composeD(handleUserJoinAccept),
    },
    {
      event: USER_JOIN_REJECT,
      handler: composeD(handleUserJoinReject),
    },
    {
      event: USER_JOIN_CLASS_ACCEPT,
      handler: composeD(handleUserJoinClassAccept),
    },
    {
      event: USER_JOIN_CLASS_REJECT,
      handler: composeD(handleUserJoinClassReject),
    },
    {
      event: USER_JOINED,
      handler: composeD(addUserJoinedMessage),
    },
    {
      event: USER_LEFT,
      handler: composeD(addUserLeftMessage),
    },
    {
      event: MIC_ON,
      handler: composeD(handleHandRaiseAccept),
    },
    {
      event: HAND_RAISE_REJECT,
      handler: composeD(handleHandRaiseReject),
    },
    {
      event: SUBMIT_WHITEBOARD,
      handler: composeD(setSubmissionDetails),
    },
    {
      event: WHITEBOARD_ARCHIVE,
      handler: composeD(getWhiteboardArchive),
    },
    {
      event: UPDATE_LIVECLASS_TOPIC,
      handler: composeD(updateLiveclassTopic),
    },
    {
      event: CHANGE_CLASS_SETTINGS,
      handler: composeD(handleUpdateClassSettings),
    },
    {
      event: WHITEBOARD,
      handler: composeD(setStudentLiveClassWhiteboardData),
    },
    {
      event: REQUEST_SCREENSHARE,
      handler: composeD(handleRequestScreenshare),
    },
    {
      event: ACCEPT_SCREENSHARE_REQUEST,
      handler: composeD(handleAcceptScreenshareRequest),
    },
    {
      event: REJECT_SCREENSHARE_REQUEST,
      handler: composeD(handleRejectScreenshareRequest),
    },
    {
      event: CANCEL_SCREENSHARE_REQUEST,
      handler: composeD(handleCancelScreenshareRequest),
    },
    {
      event: REQUEST_MIC_OR_VIDEO,
      handler: composeD(handleRequestMicOrVideo),
    },
    {
      event: ACCEPT_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleAcceptMicOrVideoRequest),
    },
    {
      event: REJECT_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleRejectMicOrVideoRequest),
    },
    {
      event: CANCEL_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleCancelMicOrVideoRequest),
    },
    {
      event: HAND_DOWN,
      handler: composeD(handleHandDown),
    },
    {
      event: HAND_RAISE_TIMEOUT,
      handler: composeD(handleHandRaiseTimeout),
    },
    {
      event: RECORDING_STATE,
      handler: composeD(handleRecordingState),
    },
    {
      event: YOUTUBE_VIDEO_SHARE,
      handler: composeD(setYoutubeVideoControls),
    },
    {
      event: KICK_PARTICIPANT,
      handler: composeD(onKickParticipant),
    },
    {
      event: SLIDESHARE_PREVIEW_AREA_UPDATE,
      handler: composeD(handleFetchNewElementsOfFolder),
    },
    {
      event: FEEDBACK_POPUP,
      handler: composeD(handleFeedbackWarning),
    },
    {
      event: LINK_DEVICE_CONNECTION_REQUEST_APP,
      handler: composeD(onGetConnectionRequest),
    },
    {
      event: SCREEN_SHARE_STARTED_APP,
      handler: composeD(onScreenShareStarted),
    },
  ];
};

export const addLiveClassSocketListenersForTeachers =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveClassSocketHandlersForTeachers(dispatch).forEach(({ event, handler }) =>
      socket.on(event, handler)
    );
  };

export const removeLiveClassSocketListenersForTeachers =
  () => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveClassSocketHandlersForTeachers(dispatch).forEach(({ event, handler }) =>
      socket.off(event, handler)
    );
  };

// Student live class listeners
const liveClassSocketHandlersForStudents = (dispatch, goBackHandler) => {
  const composeD =
    (f, ...args) =>
    (x) =>
      dispatch(f(x, ...args));
  return [
    {
      event: CONNECT,
      handler: composeD(studentJoinLiveClass, goBackHandler),
    },
    {
      event: CHAT_ARCHIVE,
      handler: composeD(addChatArchive),
    },
    {
      event: EVENT_ARCHIVE,
      handler: composeD(addEventArchive),
    },
    {
      event: CHAT_MESSAGE,
      handler: composeD(addChatMessage),
    },
    {
      event: USER_JOIN_REJECT,
      handler: composeD(studentHandleUserJoinReject),
    },
    {
      event: USER_JOIN_ACCEPT,
      handler: composeD(studentHandleUserJoinAccept),
    },
    {
      event: USER_JOIN_CLASS_ACCEPT,
      handler: composeD(studentHandleUserJoinClassAccept),
    },
    {
      event: USER_JOIN_CLASS_REJECT,
      handler: composeD(studentHandleUserJoinClassReject),
    },
    {
      event: USER_JOINED,
      handler: composeD(addUserJoinedMessage),
    },
    {
      event: USER_LEFT,
      handler: composeD(addUserLeftMessage),
    },
    {
      event: MIC_ON,
      handler: composeD(studentHandleMicOn),
    },
    {
      event: HAND_RAISE_REJECT,
      handler: composeD(studentHandleHandRaiseReject),
    },
    {
      event: HAND_RAISE_TIMEOUT,
      handler: composeD(handleHandRaiseTimeout),
    },
    {
      event: UPDATE_LIVECLASS_TOPIC,
      handler: composeD(updateLiveclassTopic),
    },
    {
      event: CHANGE_CLASS_SETTINGS,
      handler: composeD(handleUpdateClassSettings),
    },
    {
      event: HAND_RAISE_ARCHIVE,
      handler: composeD(addHandRaiseArchive),
    },
    {
      event: HAND_RAISE,
      handler: composeD(addHandRaiseMessage),
    },
    {
      event: WHITEBOARD,
      handler: composeD(setStudentLiveClassWhiteboardData),
    },
    {
      event: REQUEST_MIC_OR_VIDEO,
      handler: composeD(handleRequestMicOrVideo),
    },
    {
      event: ACCEPT_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleAcceptMicOrVideoRequest),
    },
    {
      event: REJECT_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleRejectMicOrVideoRequest),
    },
    {
      event: CANCEL_MIC_OR_VIDEO_REQUEST,
      handler: composeD(handleCancelMicOrVideoRequest),
    },
    {
      event: REQUEST_SCREENSHARE,
      handler: composeD(handleRequestScreenshare),
    },
    {
      event: ACCEPT_SCREENSHARE_REQUEST,
      handler: composeD(handleAcceptScreenshareRequest),
    },
    {
      event: REJECT_SCREENSHARE_REQUEST,
      handler: composeD(handleRejectScreenshareRequest),
    },
    {
      event: CANCEL_SCREENSHARE_REQUEST,
      handler: composeD(handleCancelScreenshareRequest),
    },
    {
      event: HAND_DOWN,
      handler: composeD(handleHandDown),
    },
    {
      event: NOTEBOOK_ARCHIVE,
      handler: composeD(handleStudentNotebookArchiveData),
    },
    {
      event: YOUTUBE_VIDEO_SHARE,
      handler: composeD(setYoutubeVideoControls),
    },
    {
      event: FEEDBACK_POPUP,
      handler: composeD(showLiveStudentFeedbackPopup),
    },
    {
      event: KICK_PARTICIPANT,
      handler: composeD(onKickParticipant),
    },
  ];
};

const instantLiveClassSocketHandlersForStudents = (dispatch, goBackHandler) => {
  const composeD =
    (f, ...args) =>
    (x) =>
      dispatch(f(x, ...args));
  return [
    {
      event: CHAT_ARCHIVE,
      handler: composeD(addChatArchive),
    },
    {
      event: EVENT_ARCHIVE,
      handler: composeD(addEventArchive),
    },
    {
      event: CHAT_MESSAGE,
      handler: composeD(addChatMessage),
    },

    {
      event: USER_JOINED,
      handler: composeD(addUserJoinedMessage),
    },
    {
      event: USER_LEFT,
      handler: composeD(addUserLeftMessage),
    },

    {
      event: UPDATE_LIVECLASS_TOPIC,
      handler: composeD(updateLiveclassTopic),
    },
    // {
    //   event: CHANGE_CLASS_SETTINGS,
    //   handler: composeD(handleUpdateClassSettings),
    // },
    {
      event: WHITEBOARD,
      handler: composeD(setStudentLiveClassWhiteboardData),
    },
    {
      event: YOUTUBE_VIDEO_SHARE,
      handler: composeD(setYoutubeVideoControls),
    },
    {
      event: FEEDBACK_POPUP,
      handler: composeD(showLiveStudentFeedbackPopup),
    },
    {
      event: KICK_PARTICIPANT,
      handler: composeD(onKickParticipant),
    },
  ];
};

export const studentJoinLiveClass =
  (goBackHandler = () => {}) =>
  (dispatch, getState) => {
    const {
      socket: { socket },
      liveClass: { classId, lectureId },
      jitsi: { conferenceJoined },
    } = getState();

    if (!socket) {
      return;
    }

    if (!lectureId) return;

    if (!conferenceJoined) {
      dispatch(setStudentLiveClassLoader(true));
    }

    socket.emit(
      JOIN_LIVE_CLASS,
      {
        classId: classId,
        lectureId: lectureId,
        message: liveEvents.messages[liveEvents.userJoined],
      },
      (success, response) => {
        if (!success) {
          console.log(response.message);
          // dispatch(errorAlert(response.message));
          dispatch(cleanupLiveClass(goBackHandler));
          return;
        }
        const { messageObj } = response;
        if (messageObj?.status === "PENDING") {
          dispatch(setStudentLiveClassLoader(false));
          if (messageObj.type === liveEvents.userClassJoin) {
            dispatch(setShowJoinRequestPending(true));
          } else if (messageObj.type === liveEvents.userJoined) {
            if (messageObj.extra?.kicked_out) {
              dispatch(setShowJoinRequestPending(true));
            } else {
              dispatch(setShowRequestPending(true));
            }
          }
        } else {
          if (!conferenceJoined) {
            dispatch(setStudentLiveClassLoader(true));
            dispatch(initJitsiConnection());
          }
          dispatch(getLiveClassArchive());
        }
      }
    );
  };

export const studentHandleUserJoinReject =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      dispatch(setShowRequestPending(false));
      dispatch(setShowJoinRequestPending(false));
      dispatch(setShowRequestDenied(true));
    }
    dispatch(updateEventStatus(requestId, "REJECTED"));
  };

export const studentHandleUserJoinAccept =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
      jitsi: { conferenceJoined },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      if (!conferenceJoined) {
        dispatch(setStudentLiveClassLoader(true));
        dispatch(initJitsiConnection());
      }
      dispatch(getLiveClassArchive());
      dispatch(setShowRequestPending(false));
      dispatch(setShowJoinRequestPending(false));
      dispatch(updateEventStatus(requestId, "ACCEPTED"));
    }
  };

export const studentHandleUserJoinClassReject =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      dispatch(setShowRequestPending(false));
      dispatch(setShowJoinRequestPending(false));
      dispatch(setShowJoinRequestDenied(true));
    }
    dispatch(updateEventStatus(requestId, "REJECTED"));
  };

export const studentHandleUserJoinClassAccept =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
      jitsi: { conferenceJoined },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      if (!conferenceJoined) {
        dispatch(setStudentLiveClassLoader(true));
        dispatch(initJitsiConnection());
      }
      dispatch(getLiveClassArchive());
      dispatch(setShowRequestPending(false));
      dispatch(setShowJoinRequestPending(false));
      dispatch(updateEventStatus(requestId, "ACCEPTED"));
    }
  };

export const studentHandleMicOn = (requestId) => async (dispatch, getState) => {
  const {
    liveClass: { liveEvents },
    user: { id },
  } = getState();
  if (liveEvents[requestId]?.user_id === id) {
    dispatch(setShowHandRaiseTimer(false));
    dispatch(muteMyAudio(false));
  }
  dispatch(removeHandRaise(requestId));
  dispatch(updateEventStatus(requestId, "ACCEPTED"));
};

export const studentHandleHandRaiseReject =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      dispatch(setShowHandRaiseTimer(false));
      dispatch(muteMyAudio(true));
    }
    dispatch(removeHandRaise(requestId));
    dispatch(updateEventStatus(requestId, "REJECTED"));
  };

export const handleHandRaiseTimeout =
  (requestId) => async (dispatch, getState) => {
    const {
      liveClass: { liveEvents },
      user: { id },
    } = getState();
    if (liveEvents[requestId]?.user_id === id) {
      dispatch(setShowHandRaiseTimer(false));
    }
    dispatch(removeHandRaise(requestId));
    dispatch(updateEventStatus(requestId, "TIMED_OUT"));
  };

export const handleRequestMicOrVideo =
  (eventInfo) => async (dispatch, getState) => {
    const {
      user: { id },
    } = getState();
    const { user_id, type } = eventInfo;
    const participant = getParticipantByUserId(user_id);

    if (type === liveEvents.videoOnRequest) {
      if (user_id === id) {
        dispatch(setShowRequestingVideo(eventInfo.id));
      }
      dispatch(addVideoRequest(participant?.participantId, eventInfo));
    }
    if (type === liveEvents.micOnRequest) {
      if (user_id === id) {
        dispatch(setShowRequestingAudio(eventInfo.id));
      }
      dispatch(addMicRequest(participant?.participantId, eventInfo));
    }
    dispatch(addLiveEventMessage(eventInfo));
  };

export const handleRequestScreenshare =
  (eventInfo) => async (dispatch, getState) => {
    const {
      user: { id },
    } = getState();
    const { user_id } = eventInfo;
    const participant = getParticipantByUserId(user_id);
    if (user_id === id) {
      dispatch(setShowRequestingScreenshare(eventInfo.id));
    }
    dispatch(addScreenshareRequest(participant?.participantId, eventInfo));
    dispatch(addLiveEventMessage(eventInfo));
  };

export const addLiveClassSocketListenersForStudents =
  (goBackHandler) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveClassSocketHandlersForStudents(dispatch, goBackHandler).forEach(
      ({ event, handler }) => socket.on(event, handler)
    );
  };

export const removeLiveClassSocketListenersForStudents =
  (goBackHandler) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    liveClassSocketHandlersForStudents(dispatch, goBackHandler).forEach(
      ({ event, handler }) => socket.off(event, handler)
    );
  };

export const addInstantLiveClassSocketListenersForStudents =
  (goBackHandler) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    instantLiveClassSocketHandlersForStudents(dispatch, goBackHandler).forEach(
      ({ event, handler }) => socket.on(event, handler)
    );
  };

export const removeInstantLiveClassSocketListenersForStudents =
  (goBackHandler) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    instantLiveClassSocketHandlersForStudents(dispatch, goBackHandler).forEach(
      ({ event, handler }) => socket.off(event, handler)
    );
  };

export const setDisplayParticipantId = (participantId) => (dispatch) => {
  dispatch({ type: SET_DISPLAY_PARTICIPANT_ID, payload: participantId });
};

export const enterClassroomStudentPresentationView =
  (participantId) => (dispatch) => {
    dispatch(setDisplayParticipantId(participantId));
    dispatch(setTeacherClassroomStudentPresentationView(true));
    dispatch(setLargePeopleTabActive(true));
  };

export const exitClassroomStudentPresentationView = () => (dispatch) => {
  dispatch(setTeacherClassroomStudentPresentationView(false));
  dispatch(setDisplayParticipantId(null));
};

export const setShowDeleteNotebookConfirmationPopup = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DELETE_NOTEBOOK_CONFIRMATION_POPUP,
    payload: show,
  });
};

export const setShowDeleteWhiteboardConfirmationPopup =
  (show) => (dispatch) => {
    dispatch({
      type: SET_SHOW_DELETE_WHITEBOARD_CONFIRMATION_POPUP,
      payload: show,
    });
  };

export const setShowDeleteFileConfirmationPopup = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DELETE_FILE_CONFIRMATION_POPUP,
    payload: show,
  });
};

export const setShowLoadMediapPopup = (show) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LOAD_MEDIA_POPUP,
    payload: show,
  });
};

export const setNotebookDeleteLayerId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_NOTEBOOK_DELETE_LAYER_ID,
    payload: layerId,
  });
};

export const setWhiteboardDeleteLayerId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_DELETE_LAYER_ID,
    payload: layerId,
  });
};

export const setWhiteboardSlideId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_WHITEBOARD_SLIDE_ID,
    payload: layerId,
  });
};

export const setFileDeleteId = (layerId) => (dispatch) => {
  dispatch({
    type: SET_FILE_DELETE_ID,
    payload: layerId,
  });
};

export const updateStudentNotebookIdsWithDeletedLayer =
  (deletedId) => (dispatch, getState) => {
    const {
      liveClass: { studentNotebookIds },
    } = getState();

    const updatedIds = studentNotebookIds.filter((l) => l.id !== deletedId);
    dispatch(updateStudentNotebookIds(updatedIds));
    // find new active layer id
    const deletedIdIndex = studentNotebookIds.findIndex(
      (l) => l.id === deletedId
    );
    let newActiveLayerId;
    if (deletedIdIndex > 0) {
      newActiveLayerId = studentNotebookIds[deletedIdIndex - 1]?.id;
    } else {
      newActiveLayerId = studentNotebookIds[deletedIdIndex + 1]?.id;
    }
    dispatch(setStudentNotebookActiveLayerId(newActiveLayerId));
  };

export const updateWhiteboardIdsWithDeletedLayer =
  (deletedId) => (dispatch, getState) => {
    const {
      liveClass: { whiteboardIds },
      liveClass: { whiteboardActiveLayerId },
    } = getState();

    const updatedIds = whiteboardIds.filter((l) => l !== deletedId);
    const deletedIdIndex = whiteboardIds.findIndex((l) => l === deletedId);
    dispatch(updateWhiteboardIds(updatedIds));
    // find new active layer id

    let newActiveLayerId;
    if (deletedId !== whiteboardActiveLayerId) {
      newActiveLayerId = whiteboardActiveLayerId;
    } else {
      if (deletedIdIndex > 0) {
        newActiveLayerId = whiteboardIds[deletedIdIndex - 1];
      } else {
        newActiveLayerId = whiteboardIds[deletedIdIndex + 1];
      }
    }
    dispatch(setWhiteboardActiveLayerId(newActiveLayerId));
  };

export const updateStudentNotebookIds = (idsArray) => (dispatch) => {
  dispatch({
    type: UPDATE_STUDENT_NOTEBOOK_IDS,
    payload: idsArray,
  });
};

export const updateWhiteboardIds = (idsArray) => (dispatch) => {
  dispatch({
    type: UPDATE_WHITEBOARD_IDS,
    payload: idsArray,
  });
};

export const setStudentNotebookActiveLayerId = (layerId) => (dispatch) => {
  // console.log("setWhiteboardActiveLayerId", { layerId });
  dispatch({
    type: SET_STUDENT_NOTEBOOK_ACTIVE_LAYER_ID,
    payload: layerId,
  });
  dispatch(setPaperActiveLayerId(layerId));
};

export const moveByKSlides = (k) => (dispatch, getState) => {
  const {
    lecture: { slideImageList, currentSlideIndex },
    smartboard: { slideShareState, selected },
  } = getState();
  if (
    slideImageList.length > currentSlideIndex + k &&
    currentSlideIndex + k > -1 &&
    slideShareState === SLIDE_SHARE_STATE.VIEWING &&
    selected === OPTIONS.SLIDESHARE
  ) {
    dispatch(setSlidePopupFor(slidePopupClickedFrom.slideboard));
    dispatch(
      setSlideboardActiveLayerId(slideImageList[currentSlideIndex + k].id)
    );
    dispatch(setCurrentSlideIndex(currentSlideIndex + k));
  }
};

export const goToNextSlide = () => (dispatch) => {
  dispatch(moveByKSlides(1));
};

export const goToPrevSlide = () => (dispatch) => {
  dispatch(moveByKSlides(-1));
};

export const moveByKWhiteboardPage = (k) => (dispatch, getState) => {
  const {
    liveClass: { whiteboardActiveLayerId, whiteboardIds },
    smartboard: { selected },
  } = getState();

  const currentWhiteboardIndex = whiteboardIds?.findIndex(
    (x) => x === whiteboardActiveLayerId
  );

  if (
    whiteboardIds.length > currentWhiteboardIndex + k &&
    currentWhiteboardIndex + k > -1 &&
    selected === OPTIONS.WHITEBOARD
  ) {
    dispatch(
      setWhiteboardActiveLayerId(whiteboardIds[currentWhiteboardIndex + k])
    );
  }
};

export const goToNextWhiteboardPage = () => (dispatch) => {
  dispatch(moveByKWhiteboardPage(1));
};

export const goToPrevWhiteboardPage = () => (dispatch) => {
  dispatch(moveByKWhiteboardPage(-1));
};

export const setStudentNotebookArchiveDataRecieved =
  (isRecieved) => (dispatch) => {
    dispatch({
      type: SET_STUDENT_NOTEBOOK_ARCHIVE_DATA_RECIEVED,
      payload: isRecieved,
    });
  };

export const handleStudentNotebookArchiveData = (archiveData) => (dispatch) => {
  if (!archiveData) {
    // for the very first time in the class archive will be null
    dispatch(setStudentNotebookArchiveDataRecieved(true));
  } else {
    const {
      layers: { data },
    } = archiveData;
    if (data.length > 0) {
      const ids = [];
      data.forEach((l) => {
        ids.push({ id: l.id });
      });
      // only update if archive Data is available
      dispatch(setWhiteboardData(data));
      dispatch(updateStudentNotebookIds(ids));
    }
    dispatch(setStudentNotebookArchiveDataRecieved(true));
  }
};

export const sendChatMessage =
  (inputText, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    const data = {
      message: inputText,
      is_chat: true,
    };

    socket.emit(CHAT_MESSAGE, data, (success, res) => {
      if (success) callback.success(res);
      else callback.error(res);
    });
  };

export const changeClassSettings =
  (setDefault, settings, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(CHANGE_CLASS_SETTINGS, { setDefault, settings }, callback);
  };

export const handRaiseAccept =
  (requestId, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(HAND_RAISE_ACCEPT, requestId, (success, res) => {
      if (success) callback.success(res);
      else callback.error(res);
    });
  };

export const handRaiseReject =
  (requestId, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(HAND_RAISE_REJECT, requestId, (success, res) => {
      if (success) callback.success(res);
      else callback.error(res);
    });
  };

export const userJoinAccept = (requestId, callback) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket) {
    return;
  }

  socket.emit(USER_JOIN_ACCEPT, requestId, callback);
};

export const userJoinReject = (requestId, callback) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket) {
    return;
  }

  socket.emit(USER_JOIN_REJECT, requestId, callback);
};

export const userJoinClassAccept =
  (requestId, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(USER_JOIN_CLASS_ACCEPT, requestId, callback);
  };

export const userJoinClassReject =
  (requestId, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(USER_JOIN_CLASS_REJECT, requestId, callback);
  };

export const submitWhiteboard =
  (width, height, activeLayer, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(
      WHITEBOARD,
      { type: "submission", width, height, data: activeLayer.children },
      callback
    );
  };

const handleRecordingState =
  (state = {}) =>
  (dispatch) => {
    dispatch({ type: UPDATE_RECORDING_STATE, payload: state });
  };

export const socketSendRecordingState = (data) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket) {
    return;
  }

  socket.emit(RECORDING_STATE, data, (success, res) => {
    console.log(success, res);
  });
};

export const changeLiveclassTopic =
  (value, callback) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(UPDATE_LIVECLASS_TOPIC, value, (success, res) => {
      if (success) callback.success(res);
      else callback.error(res);
    });
  };

export const raiseHand = (callback) => (dispatch, getState) => {
  const {
    socket: { socket },
    liveClass: { lectureId },
  } = getState();

  if (!socket) {
    return;
  }

  const message = {
    type: liveEvents.handRaise,
    message: liveEvents.messages[liveEvents.handRaise],
    is_chat: false,
    lecture_id: lectureId,
    status: "PENDING",
  };

  socket.emit(HAND_RAISE, message, (success, res) => {
    if (success) callback.success(res);
    else callback.error(res);
  });
};

export const handDown = (callback) => (dispatch, getState) => {
  const {
    socket: { socket },
    liveClass: { handRaiseRequestId },
  } = getState();

  if (!socket) {
    return;
  }

  if (!handRaiseRequestId) {
    return;
  }

  socket.emit(HAND_DOWN, handRaiseRequestId, (success, res) => {
    if (success) {
      callback.success(res);
      dispatch(setHandRaiseRequestId(null));
    } else callback.error(res);
  });
};

const updateEventStatus = (eventId, status) => (dispatch) => {
  dispatch({ type: UPDATE_EVENT_STATUS, payload: { eventId, status } });
};

export const socketSendAutoSaveWhiteboard = (data) => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { role: userRole },
    liveClass: { lectureId },
  } = getState();

  if (!socket) {
    return;
  }

  const whiteboardObj = {
    lecture_id: lectureId,
    board_type:
      userRole.toUpperCase() === STUDENT
        ? boardType.studentNotebook
        : userRole.toUpperCase() === TEACHER
        ? boardType.teacherWhiteboard
        : null,
    layers: data,
  };

  socket.emit(AUTOSAVE_WHITEBOARD, whiteboardObj, (success, response) => {
    if (!success) {
      console.log(response.message);
      return;
    }
  });
};

export const socketSendYoutubeControls = (controls) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();

  if (!socket) {
    return;
  }

  socket.emit(YOUTUBE_VIDEO_SHARE, controls, (success, response) => {
    if (!success) {
      console.log(response.message);
      return;
    }
  });
};

export const setYoutubeVideoControls = (value) => (dispatch, getState) => {
  let {
    liveClass: { youtubeVideoControls },
  } = getState();
  youtubeVideoControls = youtubeVideoControls || {};
  dispatch({
    type: SET_YOUTUBE_VIDEO_SHARE_CONTROLS,
    payload: { ...youtubeVideoControls, ...value },
  });
};

export const setEmitYoutubeControlsOnUsersJoined =
  (value) => (dispatch, getState) => {
    const {
      jitsi: { currentTeacherParticipant },
      user: { id },
      liveClass: { youtubeVideoControls },
    } = getState();
    if (currentTeacherParticipant) {
      const { userId, broadcastType, mediaShareType } =
        currentTeacherParticipant;
      if (
        userId === id &&
        broadcastType === OPTIONS.SLIDESHARE &&
        mediaShareType === MEDIA_SHARE_OPTIONS.YOUTUBE
      ) {
        // if teacher playing youtube is user himself then only emit controls
        dispatch({
          type: SET_EMIT_YOUTUBE_CONTROLS_ON_USER_JOINED,
          payload: value,
        });

        // if teacher youtube is not mounted while user joins, send the already sent data again
        dispatch(sendControls(youtubeVideoControls));
      }
    }
  };

export const getContinueWhereLeftTimestamp = () => (dispatch, getState) => {
  const {
    liveClass: { youtubeVideoControls },
  } = getState();
  const { delaySyncControls, playVideo } = youtubeVideoControls;
  const { duration } = delaySyncControls;

  /** delaySyncControls will be emitted every 2 sec from teacher, hence this is most accurate */
  const teacherCurrentTime = youtubeVideoControls.delaySyncControls.jumpTo;

  if (playVideo) {
    // if playing then calculate the expected current timestamp
    if (teacherCurrentTime < duration) {
      return { timestamp: teacherCurrentTime, ended: false };
    } else {
      return { timestamp: 0, ended: true };
    }
  } else {
    // if not playing then play from last timestamp
    return { timestamp: teacherCurrentTime, ended: false };
  }
};

export const sendControls =
  (controls = {}, forceEmit = false) =>
  (dispatch, getState) => {
    const {
      smartboard: { broadcast, mediaShareState },
    } = getState();

    if (
      !(
        broadcast === OPTIONS.SLIDESHARE &&
        mediaShareState === MEDIA_SHARE_OPTIONS.YOUTUBE
      ) &&
      !forceEmit
    ) {
      return;
    }
    dispatch(
      socketSendYoutubeControls({
        ...controls,
      })
    );
  };

export const setLiveClassFileSystemIds =
  (currentClassId = null, currentLectureId = null) =>
  (dispatch, getState) => {
    const idsObj = {
      currentClassId,
      currentLectureId,
    };
    dispatch({
      type: SET_LIVE_CLASS_FILESYSTEM_IDS,
      payload: idsObj,
    });
  };

export const setNotificationHeader = (message) => (dispatch, getState) => {
  const {
    liveClass: { headerMessage },
  } = getState();

  if (!_.isEqual(headerMessage, message)) {
    dispatch({ type: SET_NOTIFICATION_HEADER, payload: message });
  }
};

export const onKickParticipant = (messageObj) => (dispatch, getState) => {
  const {
    user: { id },
  } = getState();

  if (messageObj.user_id === id) dispatch(setKickedFromConference());
  dispatch(addLiveEventMessage(messageObj));
};

export const emitSlideSharePreviewAreaUpdatedEvent =
  ({
    folderId,
    classId,
    afterTrashElement = false,
    elementId = undefined,
  } = {}) =>
  (dispatch, getState) => {
    const {
      socket: { socket },
      fileSystem: { currentFolderId },
      liveClass: {
        fileSystem: { currentClassId },
      },
    } = getState();

    /**
     * @param liveClass.fileSystem.currentClassId is specific to live class scope. this isn't suitable when working
     * with library. If this action is being used in library, then make the required changes for classId.
     */

    if (!socket) {
      return;
    }

    folderId = folderId || currentFolderId;
    classId = classId || currentClassId;

    socket.emit(
      SLIDESHARE_PREVIEW_AREA_UPDATE,
      { folderId, classId, afterTrashElement, elementId },
      (success, response) => {
        if (!success) {
          console.log(response.message);
          return;
        }
      }
    );
  };

export const handleFetchNewElementsOfFolder =
  ({ folderId, classId, afterTrashElement, elementId }) =>
  (dispatch) => {
    if (afterTrashElement && elementId) {
      dispatch(removeCurrentFolderChildren(folderId, [elementId]));
    }
    dispatch(getFolderChildElementList(folderId, classId));
  };

export const isBroadcasting = () => (dispatch, getState) => {
  const {
    jitsi: { currentTeacherParticipant },
  } = getState();

  return (
    currentTeacherParticipant &&
    currentTeacherParticipant?.broadcastType !== OPTIONS.VIDEO_OR_DP
  );
};

export const screenIsCasted = () => (dispatch, getState) => {
  const {
    jitsi: { sharedParticipantId },
  } = getState();

  return !!sharedParticipantId;
};

export const handleFeedbackWarning = (response) => (dispatch, getState) => {
  const {
    liveClass: { lectureId },
  } = getState();

  if (parseInt(response.lecture_id) === lectureId) {
    dispatch({
      type: SET_FEEDBACK_AGGREGATE_SCORE,
      payload: response.score,
    });
  }
};

export const askFeedbackNow =
  (frequency, role, threshold) => (dispatch, getState) => {
    const {
      socket: { socket },
    } = getState();

    if (!socket) {
      return;
    }

    socket.emit(
      TRIGGER_FEEDBACK,
      { frequency, role, threshold },
      (success, response) => {
        if (!success) {
          console.log(response.message);
          return;
        }
      }
    );
  };

export const submitLiveFeedback = (score) => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { role },
  } = getState();

  dispatch({
    type: SET_SHOW_FEEDBACK_INPUT,
    payload: false,
  });
  dispatch({
    type: SET_USER_PREVIOUS_FEEDBACK_SCORE,
    payload: score,
  });

  if (!socket) {
    return;
  }
  socket.emit(
    TRIGGER_FEEDBACK,
    { frequency: 0, score, role },
    (success, response) => {
      if (!success) {
        console.log(response.message);
        return;
      }
    }
  );
};

export const showLiveStudentFeedbackPopup = () => (dispatch) => {
  dispatch({
    type: SET_SHOW_FEEDBACK_INPUT,
    payload: true,
  });
};

export const hideLiveStudentFeedbackPopup = () => (dispatch) => {
  dispatch({
    type: SET_SHOW_FEEDBACK_INPUT,
    payload: false,
  });
};

export const setStudentYoutubeInitialized = (bool) => (dispatch, getState) => {
  dispatch({
    type: SET_STUDENT_YOUTUBE_INITIALIZED,
    payload: bool,
  });
};

export const setLastViewedMessageId =
  (lastMessageId) => (dispatch, getState) => {
    dispatch({
      type: SET_LIVECLASS_LAST_VIEWED_MESSAGE,
      payload: lastMessageId,
    });
  };

export const setUnreadMessagesCount = (count) => (dispatch, getState) => {
  dispatch({
    type: SET_LIVECLASS_UNREAD_MESSAGES,
    payload: count,
  });
};

export const cleanupBeforeLeaving = () => (dispatch) => {
  dispatch(resetRecorderState());
  dispatch(removeSiteCloseRestriction());
  dispatch(cleanupLiveClass());
};

const getbroadcastedActivelayerId = () => (dispatch, getState) => {
  const {
    liveClass: { slideboardActiveLayerId, whiteboardActiveLayerId },
    smartboard: { broadcast },
  } = getState();
  if (broadcast === OPTIONS.SLIDESHARE) {
    return slideboardActiveLayerId;
  } else if (broadcast === OPTIONS.WHITEBOARD) {
    return whiteboardActiveLayerId;
  }
};

const getActiveLayerJSON = () => (dispatch, getState) => {
  const {
    liveClass: { allWhiteboardData },
  } = getState();

  const broadcastedLayerId = dispatch(getbroadcastedActivelayerId());
  const layerData = allWhiteboardData.find((l) => l.id === broadcastedLayerId);
  if (layerData) {
    return layerData.layerJSON;
  }

  return null;
};

export const socketSendWhiteboard = (activeLayer) => (dispatch, getState) => {
  const {
    smartboard: { whiteboardContainerHeight, whiteboardContainerWidth },
    socket: { socket },
  } = getState();
  const {
    width,
    height,
    imageWidth,
    imageHeight,
    image,
    viewSize,
    zoom,
    panOffset,
    canvas16x9,
  } = activeLayer;

  socket &&
    socket.emit(
      WHITEBOARD,
      {
        teacher16x9Width: canvas16x9.width,
        teacher16x9Height: canvas16x9.height,
        width,
        height,
        imageWidth,
        imageHeight,
        data: [...activeLayer.backgroundPaths, ...activeLayer.children],
        image: image ?? null,
        layerJSON: dispatch(getActiveLayerJSON()),
        updateVersion: Math.random(),
        isMiroBoard: false,
        viewSize,
        zoom,
        panOffset,
      },
      (success, response) => {
        if (!success) {
          // this.props.errorAlert(response.message);
          return;
        }
      }
    );
};

export const socketSendMiroWhiteboard = (miroBoard) => (dispatch, getState) => {
  const {
    socket: { socket },
  } = getState();
  const { viewLink, id, accessLink } = miroBoard;

  socket &&
    socket.emit(
      WHITEBOARD,
      {
        viewLink,
        id,
        accessLink,
        isMiroBoard: true,
        image: "",
      },
      (success, response) => {
        if (!success) {
          // this.props.errorAlert(response.message);
          return;
        }
      }
    );
};

export const onGetConnectionRequest = (res) => (dispatch) => {
  dispatch(setDeviceInformation(res.device));
  dispatch({
    type: SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP,
    payload: true,
  });
};

export const onScreenShareStarted = (res) => (dispatch) => {
  dispatch(setShowLinkDeviceBroadcastPopup(true));
};

export const setDeviceInformation = (info) => (dispatch) => {
  dispatch({
    type: SET_DEVICE_INFO,
    payload: info,
  });
};

export const setLinkDeviceConnectionRequestPopup = (state) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DEVICE_CONNECTION_REQUEST_POPUP,
    payload: state,
  });
};

export const setShowLinkDeviceBroadcastPopup = (state) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LINK_DEVICE_BROADCAST_POPUP,
    payload: state,
  });
};

export const setStudentRequestsPopup =
  (requestCode, popupState) => (dispatch) => {
    dispatch({
      type: SET_STUDENTS_REQUESTS_POPUP,
      payload: { requestCode, popupState },
    });
  };

export const sendDeviceConnectionRequestResponse =
  (response) => (dispatch, getState) => {
    const {
      socket: { socket },
      user: { id },
    } = getState();

    socket.emit(
      LINK_DEVICE_CONNECTION_RESPONSE,
      {
        message: "device_connection_request",
        user_id: id,
        response: response,
      },
      (success, response) => {
        if (!success) {
          console.log(response.message, "line 34");
          // TODO: alert user
          return;
        }
      }
    );
  };

export const sendDeviceDisconnectionStatus = () => (dispatch, getState) => {
  const {
    socket: { socket },
    user: { id },
  } = getState();
  socket.emit(
    LINK_DEVICE_DISCONNECTED,
    {
      message: "device_disconnection_status",
      user_id: id,
    },
    (success, res) => {
      if (!success) {
        console.log(res.message, "line 34");
        // TODO: alert user
        return;
      }
    }
  );
};
export const getNewParticipantsList =
  ({ classId, lectureId }) =>
  async (dispatch, getState) => {
    let {
      user: { sessionId },
    } = getState();
    const response = await makeApiRequest(
      "GET",
      backendApi,
      "class/startup/new-users", // update URL as per New API
      true,
      { class_id: classId, lecture_id: lectureId }, // re check the body as per NEW API
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }

    const data = {
      newTeachers: response.response.new_teachers,
      newStudents: response.response.new_students,
    };

    dispatch({
      type: SET_NEW_PARTICIPANTS_LIST,
      payload: data,
    });

    return data;
  };

export const onForceReconnect = () => (dispatch, getState) => {
  dispatch(initJitsiConnection(true));
  addSocketListenersForced();
};
