import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css";
import "overlayscrollbars/css/OverlayScrollbars.css";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";

Sentry.init({
  dsn: "https://94d1ce426c6b4f80a5de19bcae51144c@o939196.ingest.sentry.io/5895799",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.3,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
