import makeApiRequest from "../../utils/makeApiRequest";
import { backendApi } from "../../config/constants";
import {
  SET_ADMIN_STARTUP_DETAILS,
  SET_TOTAL_LICENSE,
  UPDATE_INSTITUTE_DETAIL,
  UPDATE_INSTITUTE_TEACHER,
  UPDATE_SUBSCRIPTION_DETAILS,
  UPDATE_USER_SUBSCRIPTION_DETAILS,
  UPDATE_INSTITUTE_REQUEST,
  UPDATE_REMOVE_INSTITUTE_INVITATION,
  ADD_INSTITUTE_INVITATIONS,
  SET_INSTITUTE_TEACHER_BATCH_LIST,
  SET_ADMIN_DASHBOARD_DETAILS,
  SET_CLASS_LECTURE_REPORT,
  RESET_CLASS_LECTURE_REPORT,
  SET_INSTITUTE_STUDENTS,
  SET_ADMIN_LECTURES,
  SET_ADMIN_STUDENT_DETAILS,
  SET_ADMIN_TEACHER_DATA,
  SET_ADMIN_PENDING_REQUEST,
  SET_ADMIN_CLASSES,
  SET_INSTITUTE_CLASSES_LIST,
  SET_INSTITUTE_CLASS_BATCHES_LIST,
  SET_ADMIN_PENDING_INVITES,
  SET_INSTITUTE_CLASSES_OBJECT,
} from "../types";

import {
  customAlert,
  errorAlert,
  getSessionId,
  logoutProcedure,
} from "./utils";
import { getListOfInstitutes } from "./userActions";
import { getEllipsisName } from "../../utils/fileSystem";
import { setClassBatches } from "./classActions";

export const getAdminStartup = () => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "institute/admin/startup",
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  dispatch({
    type: SET_ADMIN_STARTUP_DETAILS,
    payload: { ...response.response, isAdmin: true },
  });
  dispatch({
    type: SET_TOTAL_LICENSE,
    payload: response.response.institute_subscription_details,
  });
};

export const getAdmindashBoard =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "GET",
      backendApi,
      "/institute/admin/dashboard",
      true,
      {
        start_date: startDate,
        end_date: endDate,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return;
    }
    dispatch({
      type: SET_ADMIN_DASHBOARD_DETAILS,
      payload: response.response,
    });
  };

export const editInstituteName = (data) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/modify",
    true,
    data,
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  dispatch({
    type: UPDATE_INSTITUTE_DETAIL,
    payload: response?.response,
  });
  dispatch(getListOfInstitutes());
};

export const removeInstituteTeacher = (data) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "/institute/teacher/remove",
    true,
    data,
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  dispatch({
    type: UPDATE_INSTITUTE_TEACHER,
    payload: response.response.teachers,
  });
};

export const instituteInviteTeacher = (emails, msg) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/invite",
    true,
    {
      domain: window.location.origin,
      emails: emails,
      message: msg,
    },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
};

export const instituteTeacherAcceptRequest = (ids) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "/institute/teacher/request/accept",
    true,
    {
      request_ids: ids,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  ids.map(async (id) => await dispatch(removeRequestFromAdmin(id)));

  return response.response;
};
export const instituteTeacherRejectRequest = (ids) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/request/reject",
    true,
    {
      request_ids: ids,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  ids.map(async (id) => await dispatch(removeRequestFromAdmin(id)));
};

export const instituteTeacherResendInvite = (id) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/invite/resend",
    true,
    {
      domain: window.location.origin,
      invitation_id: id,
    },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
};

export const instituteTeacherCancelInvite = (id) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/invite/cancel",
    true,
    {
      invitation_id: id,
    },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
};

export const instituteAssingSubscription = (subData) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/assign-subscription",
    true,
    subData,
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload: response.response,
  });
  dispatch({
    type: UPDATE_USER_SUBSCRIPTION_DETAILS,
    payload: response.response,
  });
};
export const instituteRemoveSubscription = (subData) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "institute/teacher/remove-subscription",
    true,
    subData,
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload: response.response,
  });
  dispatch({
    type: UPDATE_USER_SUBSCRIPTION_DETAILS,
    payload: response.response,
  });
};

export const setInstituteRequest = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTE_REQUEST,
    payload: data,
  });
};

export const setInstituteInvitations = (data) => (dispatch) => {
  dispatch({
    type: ADD_INSTITUTE_INVITATIONS,
    payload: data,
  });
};

export const setRemoveInstituteInvitation = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_REMOVE_INSTITUTE_INVITATION,
    payload: data,
  });
};

export const setAcceptInstituteInvitation =
  (teachers, inviteId) => (dispatch) => {
    dispatch({
      type: UPDATE_INSTITUTE_TEACHER,
      payload: teachers,
    });
    dispatch({
      type: UPDATE_REMOVE_INSTITUTE_INVITATION,
      payload: inviteId,
    });
  };

export const removeRequestFromAdmin = (requestId) => (dispatch, getState) => {
  let {
    admin: { pending_requests },
  } = getState();
  const pendingRequests = pending_requests.filter(
    (request) => request.request_id !== requestId
  );
  dispatch({
    type: SET_ADMIN_PENDING_REQUEST,
    payload: pendingRequests,
  });
};

export const setRemoveLicense = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload: data,
  });
  dispatch({
    type: SET_TOTAL_LICENSE,
    payload: data.institute_subscription_details,
  });
};

export const setInstituteAllTeachers = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTE_TEACHER,
    payload: data,
  });
};

export const getInstituteTeacherBatchList = () => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "institute/teacher/batch/list",
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return { success: false };
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }

  const batches = response.response.batches;

  const batchesObj = batches.reduce(
    (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
    {}
  );

  dispatch({
    type: SET_INSTITUTE_TEACHER_BATCH_LIST,
    payload: batchesObj,
  });

  return response.response;
};

export const createBatch =
  ({ name, fromDuration, toDuration, description }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "POST",
      backendApi,
      "/institute/admin/batches/create",
      true,
      {
        batch_name: name,
        from_duration: fromDuration,
        to_duration: toDuration,
        description,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }
    return response.response;
  };

export const getClassByBatch = (id) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `/institute/admin/batch/startup?batch_id=${id}`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  return response.response;
};
export const addStudentToBatch =
  ({ emails, batchId }) =>
  async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `/institute/admin/batch/add/students?batch_id=${batchId}`,
      true,
      {
        emails,
      },
      sessionId
    );
    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }
    return response.response;
  };

export const getAllClassesOfbatch = (batchId) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `institute/admin/batch/classes?batch_id=${batchId}`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  return response.response;
};

export const getAdminLectures = (teacherId) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    "/institute/admin/teacher/lectures",
    true,
    {
      teacher_id: teacherId,
    },
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: SET_ADMIN_LECTURES,
    payload: response.response.classes,
  });
};

export const getClassLectureReport = (classId) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `class/attendance/lecture?class_id=${classId}`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  dispatch({
    type: SET_CLASS_LECTURE_REPORT,
    payload: { classId, lectures: response.response?.lectures },
  });
  return response.response;
};

export const getAdminStudentsData = (studentId) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `/institute/admin/student?student_id=${studentId}`,
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }

  dispatch({
    type: SET_ADMIN_STUDENT_DETAILS,
    payload: response.response,
  });
};

export const getAllStudents = () => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `/institute/admin/students`,
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }
  dispatch({
    type: SET_INSTITUTE_STUDENTS,
    payload: {
      ...response.response?.metadata?.batch_students,
      ...response.response?.metadata?.non_batch_students,
    },
  });
  return response.response;
};

export const getBatchDetails = (batchId) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `institute/admin/batch/details?batch_id=${batchId}`,
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
  }
  if (response.error) {
    dispatch(errorAlert(response.message));
    return;
  }
  return response.response;
};

export const getAdminTeacherData = () => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `institute/admin/teachers`,
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return { success: false };
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }

  dispatch({
    type: SET_ADMIN_TEACHER_DATA,
    payload: response.response?.teachers,
  });
  dispatch({
    type: SET_ADMIN_PENDING_REQUEST,
    payload: response.response?.pending_requests,
  });
  dispatch({
    type: SET_ADMIN_PENDING_INVITES,
    payload: response.response?.pending_invitations,
  });
  dispatch({
    type: SET_ADMIN_CLASSES,
    payload: response.response?.classes,
  });
  return response.response;
};

export const resetClassLectureReport = () => (dispatch) => {
  dispatch({
    type: RESET_CLASS_LECTURE_REPORT,
  });
};

export const getInsituteAdminStudent = () => async (dispatch, getState) => {
  const sessionId = await dispatch(getSessionId());
  const { klass } = getState();

  const response = await makeApiRequest(
    "GET",
    backendApi,
    "/institute/admin/students",
    true,
    {},
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
    return;
  }
  if (response.error) {
    dispatch(errorAlert(response.response));
    return;
  }
  const { metadata } = response.response;

  let tableRowsData = await Object.values(metadata.batch_students).reduce(
    (acc, student) => {
      let rowData = { ...student.details };
      rowData["batches"] = student.batch_ids.map((id) => {
        if (metadata.batch_union[id]?.name)
          return metadata.batch_union[id]?.name;
      });
      rowData["classes"] = student.class_ids.map((id) => {
        if (metadata.class_union[id]?.name)
          return metadata.class_union[id]?.name;
      });
      return Object.assign(acc, { [rowData.id]: rowData });
    },
    {}
  );
  let nonBatches = await Object.values(metadata.non_batch_students).reduce(
    (acc, student) => {
      let rowData = { ...student.details };
      rowData["batches"] = [];
      rowData["classes"] = student.class_ids.map(
        (id) => metadata.class_union[id].name
      );
      return Object.assign(acc, { [rowData.id]: rowData });
    },
    {}
  );

  let pendingRequests = await Object.values(metadata.pending_requests)
    .flat()
    .reduce((acc, item) => {
      let obj = { ...item.user, ...item.user_class_request };
      obj["requestedClassroom"] = klass.classes[obj.class_id]?.name;
      return Object.assign(acc, { [obj.id]: obj });
    }, {});

  return {
    tableRowsData: { ...nonBatches, ...tableRowsData },
    pendingRequests,
  };
};

export const deleteStudentFromInstitute = (student_ids) => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "POST",
    backendApi,
    "/institute/admin/student/delete",
    true,
    { student_ids },
    sessionId
  );
  if (response.logout) {
    dispatch(logoutProcedure(false));
    return;
  }
  if (response.error) {
    dispatch(errorAlert(response.response));
    return;
  }
  return true;
};

export const removeStudentsFromBatch =
  (batchId, emails = []) =>
  async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `/institute/admin/batch/remove/student/bulk?batch_id=${batchId}`,
      true,
      {
        emails,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
      return { success: false };
    }

    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }

    return response.response;
  };
export const assignBatchesToClass =
  (classId, batch_ids = []) =>
  async (dispatch) => {
    const sessionId = await dispatch(getSessionId());
    const response = await makeApiRequest(
      "POST",
      backendApi,
      `/institute/class/assign-batch?class_id=${classId}`,
      true,
      {
        batch_ids,
      },
      sessionId
    );

    if (response.logout) {
      dispatch(logoutProcedure(false));
    }
    if (response.error) {
      dispatch(errorAlert(response.message));
      return { success: false };
    }

    return response.response;
  };

export const getAdminInstituteClasses = () => async (dispatch) => {
  const sessionId = await dispatch(getSessionId());
  const response = await makeApiRequest(
    "GET",
    backendApi,
    `institute/admin/classes`,
    true,
    {},
    sessionId
  );

  if (response.logout) {
    dispatch(logoutProcedure(false));
    return { success: false };
  }

  if (response.error) {
    dispatch(errorAlert(response.message));
    return { success: false };
  }

  const classBatches = response.response.class_batches;
  const data = response.response.classes.map((c) => {
    const batches =
      classBatches[c.id]?.length > 0
        ? classBatches[c.id].reduce((accumulator, batch) => {
            if (accumulator === "") {
              return getEllipsisName(batch.batch_name, 24);
            } else {
              return accumulator + ", " + getEllipsisName(batch.batch_name, 24);
            }
          }, "")
        : "-";
    const teachers =
      c.teachers?.length > 0
        ? c.teachers.reduce((accumulator, teacher) => {
            if (accumulator === "") {
              return getEllipsisName(teacher.name, 24);
            } else {
              return accumulator + ", " + getEllipsisName(teacher.name, 24);
            }
          }, "")
        : "-";
    const createdBy = c.created_user?.name;
    return {
      id: c.id,
      className: c.name,
      liveStatus: c.class_is_live,
      batches: batches,
      teachers: teachers,
      students: c.students_count,
      createdBy: createdBy ? getEllipsisName(createdBy, 24) : "-",
      createdDate: c.created_at,
    };
  });

  dispatch({
    type: SET_INSTITUTE_CLASSES_OBJECT,
    payload: response.response.classes.reduce(
      (acc, obj) => Object.assign(acc, { [obj.id]: obj }),
      {}
    ),
  });
  dispatch({
    type: SET_INSTITUTE_CLASSES_LIST,
    payload: data,
  });
  dispatch({
    type: SET_INSTITUTE_CLASS_BATCHES_LIST,
    payload: classBatches,
  });
  dispatch(setClassBatches(response.response.class_batches));
  return response.response;
};
