import {
  SET_SOCKET,
  SET_SOCKET_CONNECTION,
  SET_SERVER_TIME,
  CLEAR_SOCKET_DETAILS,
  SET_CLICKED_TRANSFER,
} from "../types";
const initialState = {
  socket: null,
  connected: false,
  serverTimeDiff: 0,
  clickedTransfer: false,
};

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKET: {
      return {
        ...state,
        socket: action.payload,
      };
    }
    case SET_SOCKET_CONNECTION: {
      return {
        ...state,
        connected: action.payload,
      };
    }
    case SET_SERVER_TIME: {
      const serverTimeDiff =
        (new Date().getTime() - new Date(action.payload).getTime()) / 1000;

      return {
        ...state,
        serverTimeDiff,
      };
    }
    case SET_CLICKED_TRANSFER: {
      return {
        ...state,
        clickedTransfer: action.payload,
      };
    }
    case CLEAR_SOCKET_DETAILS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default socketReducer;
