import React, { Component } from "react";
import { Provider } from "react-redux";

import store from "./store";
import "./App.css";
import AppRouter from "./components/AppRouter/AppRouter";
import ContainerView from "./components/ContainerView/ContainerView";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { initJitsiLib } from "./store/actions/jitsiActions";
import { isMobileBrowser } from "./store/actions/utils";
import { uiVersion } from "./config/constants";
import { retrieveClientBrandingInfo } from "./store/actions/userActions";
import { Toaster } from "react-hot-toast";

class App extends Component {
  async componentDidMount() {
    if (!isMobileBrowser()) {
      store.dispatch(initJitsiLib());
    }
    await store.dispatch(retrieveClientBrandingInfo());

    console.log(`Current UI Version: ${uiVersion}`);

    // Attach custom scrollbar to body
    // OverlayScrollbars(document.querySelectorAll("body"), {});
  }

  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <ContainerView>
            <AppRouter />
          </ContainerView>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              className: "hot_toast_option_styles",
            }}
          />
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;
