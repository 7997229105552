import store from "../../store";
import {
  classIsLive,
  liveClassEnded,
  removeClass,
  addNewInvites,
  removeInvite,
  removeInvitedUsers,
} from "../../store/actions/socketActions";
import {
  addClass,
  addClassInvitation,
  removeClassInvitation,
  updateUserDetails,
} from "../../store/actions/classActions";
import { updatePeople } from "../../store/actions/liveClassActions";
import {
  setInstituteRequest,
  setRemoveInstituteInvitation,
  setAcceptInstituteInvitation,
  removeRequestFromAdmin,
  setRemoveLicense,
  setInstituteAllTeachers,
  setInstituteInvitations,
  getAdminStartup,
} from "../../store/actions/adminAction";
import {
  setInstituteTeacher,
  setTeacherInvitation,
  setTeacherRequest,
} from "../../store/actions/userActions";
import _ from "lodash";

export const onClassIsLive = (data) => {
  store.dispatch(classIsLive(data.classId, data.live_lecture));
};

export const onLiveClassEnded = (data) => {
  store.dispatch(liveClassEnded(data.classId, data.lecture_id));
};

export const onAddClass = (data) => {
  store.dispatch(addClass(data));
};

export const onRemoveClass = (data) => {
  store.dispatch(removeClass(data.classId));
};

export const onNewInvites = (data) => {
  store.dispatch(addNewInvites(data.invitations));
};

export const onRemoveInvite = (data) => {
  store.dispatch(removeInvite(data.invitationId));
};

export const onNewInvitation = (data) => {
  store.dispatch(addClassInvitation(data));
};

export const onRemoveClassInvitation = (data) => {
  store.dispatch(removeClassInvitation(data.classId));
};

export const onRemoveUserInvites = (data) => {
  /**
   * in case of instant live class, this data is undefined
   * and reducers is expecting an array of ids, hence prevent
   * this from being dispatched to reducer if there's no data
   */
  if (data.invite_ids) {
    store.dispatch(removeInvitedUsers(data.invite_ids));
  }
};

export const onUpdateUserDetails = (data) => {
  const { id, ...info } = data;
  store.dispatch(updatePeople(id, info));
  store.dispatch(updateUserDetails(id, info));
};

export const onNewInstituteRequest = (data) => {
  const {
    user: { instituteTeacher },
  } = store.getState();

  if (instituteTeacher?.is_admin) {
    const requestData = {
      ...data.institute_teacher_request,
      request_id: data.institute_teacher_request.id,
    };
    store.dispatch(setInstituteRequest(requestData));
  } else {
    store.dispatch(setTeacherRequest(data.institute_teacher_request));
  }
};

export const onRemoveInstituteInvitation = (data) => {
  const {
    user: { instituteTeacher },
  } = store.getState();

  if (instituteTeacher?.is_admin) {
    store.dispatch(setRemoveInstituteInvitation(data.invite_ids[0]));
  } else {
    store.dispatch(setTeacherInvitation(null));
  }
};

export const onAcceptInstituteInvitation = (data) => {
  const {
    user: { instituteTeacher },
  } = store.getState();

  if (instituteTeacher?.is_admin) {
    store.dispatch(
      setAcceptInstituteInvitation(data.teachers, data.invite_ids[0])
    );
  }
};

export const onTeacherInstituteInvitation = (data) => {
  const { invitation } = data;
  store.dispatch(setTeacherInvitation(invitation));
};

export const onRemoveInstituteRequest = (data) => {
  const { institute_id, request_ids } = data;
  const {
    user: { instituteTeacher },
    admin: { pending_requests },
  } = store.getState();
  const requestObj = _.find(pending_requests, {
    request_id: request_ids[0],
  });

  if (
    instituteTeacher?.is_admin &&
    instituteTeacher?.institute_id === institute_id &&
    requestObj
  ) {
    store.dispatch(removeRequestFromAdmin(request_ids[0]));
  }
  store.dispatch(setTeacherRequest(null));
};

export const onRemoveInstituteTeacher = (data) => {
  const { institute_id, teachers } = data;
  const {
    user: { instituteTeacher },
  } = store.getState();

  if (
    instituteTeacher?.institute_id === institute_id &&
    !instituteTeacher?.is_admin
  ) {
    store.dispatch(setInstituteTeacher(null));
    store.dispatch(setTeacherRequest(null));
  }
  if (
    instituteTeacher?.institute_id === institute_id &&
    instituteTeacher?.is_admin
  ) {
    store.dispatch(setInstituteAllTeachers(teachers));
  }
};

export const onAcceptInstituteRequest = (data) => {
  const { institute_id, request_ids, teachers } = data;
  const {
    user: { instituteTeacher },
    admin: { pending_requests },
  } = store.getState();
  if (instituteTeacher?.is_admin) {
    const requestObj = _.find(pending_requests, {
      id: request_ids[0],
    });

    if (
      instituteTeacher?.is_admin &&
      instituteTeacher?.institute_id === institute_id &&
      requestObj
    ) {
      store.dispatch(removeRequestFromAdmin(request_ids[0]));
      store.dispatch(setInstituteAllTeachers(teachers));
    }
  }
};

export const onJoinInstitute = (data) => {
  const {
    user: { instituteTeacher, id },
  } = store.getState();

  if (!instituteTeacher?.is_admin) {
    const { teachers } = data;

    const instituteDetailsOfTeachers = teachers.map(
      (teacher) => teacher.institute_details
    );

    const teacherObj = _.find(instituteDetailsOfTeachers, {
      user_id: id,
    });
    store.dispatch(setInstituteTeacher(teacherObj));
    store.dispatch(setTeacherRequest(null));
    store.dispatch(setTeacherInvitation(null));
  }
};

export const onRemoveLicense = (data) => {
  store.dispatch(setRemoveLicense(data));
};

export const onAssignLicense = (data) => {
  store.dispatch(setRemoveLicense(data));
};

export const onNewInstituteInvitations = (data) => {
  const { new_invitations } = data;
  const {
    user: { instituteTeacher, id },
  } = store.getState();

  if (instituteTeacher?.is_admin) {
    store.dispatch(setInstituteInvitations(new_invitations));
  }
};
