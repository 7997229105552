import React, { Component } from "react";
import "./StudentLiveClassPopups.css";
import { connect } from "react-redux";
import SoundInputBar from "../SoundInputBar/SoundInputBar";
import { AUDIO } from "../../utils/constants";
import {
  cleanupJitsiPreviewTracks,
  getUserMediaPreview,
  muteMyAudio,
  muteMyAudioPreview,
} from "../../store/actions/jitsiActions";
import {
  acceptMicOrVideoRequest,
  rejectMicOrVideoRequest,
} from "../../store/actions/socketActions";
import { isNil } from "lodash";
import { getParticipantByUserId } from "../../utils/jitsi";
import { UserAvatarDP } from "../Avatar/Avatar";

class AudioRequestPopup extends Component {
  componentDidMount() {
    const { myAudioMuted } = this.props;

    if (!myAudioMuted) {
      this.props.acceptMicOrVideoRequest(this.props.showRequestingAudio);
      return;
    }

    this.props.getUserMediaPreview(AUDIO, false);
  }

  componentWillUnmount() {
    this.props.cleanupJitsiPreviewTracks();
  }

  acceptRequest = () => {
    this.props.muteMyAudio(false);
    this.props.acceptMicOrVideoRequest(this.props.showRequestingAudio);
  };

  rejectRequest = () => {
    this.props.muteMyAudio(true);
    this.props.rejectMicOrVideoRequest(this.props.showRequestingAudio);
  };

  render() {
    const {
      myAudioPreviewMuted,
      myAudioPreviewTrack,
      audioRequestedParticipant,
    } = this.props;
    let requestingTeacherParticipant;
    if (!isNil(audioRequestedParticipant)) {
      requestingTeacherParticipant = getParticipantByUserId(
        audioRequestedParticipant.requesting_teacher_id
      );
    }
    return (
      <div className="student_live_class_popup_background">
        <div className="student_live_class_popup_container audio_request_popup_container">
          <p className="student_live_class_popup_title_font audio_request_popup_title">
            Audio Request
          </p>
          <div className="student_audio_request_popup_user_avatar">
            <UserAvatarDP
              profilePhotoUrl={requestingTeacherParticipant?.profile_photo_url}
              borderRadius="17.5px"
              name={requestingTeacherParticipant?.userName}
              style={{
                border: "2px solid white",
                borderRadius: "17.5px",
                height: "35px",
                minHeight: "35px",
                minWidth: "35px",
                width: "35px",
                fontSize: "var(--font-13)",
              }}
            />
            <div className="student_audio_request_popup_user_avatar_details">
              <div>{requestingTeacherParticipant?.userName}</div>
              <div>{requestingTeacherParticipant?.userEmail}</div>
            </div>
          </div>
          <p className="student_live_class_popup_text_font">
            {`Teacher has requested you to speak`}
          </p>
          <div className="audio_request_popup_sound_input_bar">
            <SoundInputBar trackType="audio-preview" />
          </div>
          <div className="student_live_class_popup_bottom_button_container">
            <button
              style={{
                backgroundColor: myAudioPreviewMuted
                  ? "var(--green)"
                  : "var(--gray)",
              }}
              className="audio_request_popup_button audio_request_popup_button_reject"
              onClick={this.rejectRequest}
            >
              REJECT
            </button>
            <button
              disabled={!myAudioPreviewTrack}
              style={{
                backgroundColor: !!myAudioPreviewTrack
                  ? !myAudioPreviewMuted
                    ? "var(--green)"
                    : "var(--gray)"
                  : "var(--gray)",
              }}
              className="audio_request_popup_button audio_request_popup_button_accept"
              onClick={this.acceptRequest}
            >
              SPEAK
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ liveClass, jitsi }) => ({
  showRequestingAudio: liveClass.showRequestingAudio,
  myAudioMuted: jitsi.myAudioMuted,
  myAudioPreviewTrack: jitsi.myAudioPreviewTrack,
  myAudioPreviewMuted: jitsi.myAudioPreviewMuted,
  audioRequestedParticipant:
    liveClass.micRequestedParticipantIdList[jitsi.myParticipantId],
});

const mapDispatchToProps = {
  acceptMicOrVideoRequest,
  rejectMicOrVideoRequest,
  muteMyAudio,
  getUserMediaPreview,
  muteMyAudioPreview,
  cleanupJitsiPreviewTracks,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioRequestPopup);
