import { backendApi } from "../../config/constants";
import { getSplitName } from "../../utils/fileSystem";
import {
  DEVELOPMENT,
  RECORDING_BOT,
  TEACHER,
  TEMP_TEACHER,
} from "../../utils/constants";
import makeApiRequest from "../../utils/makeApiRequest";
import {
  SET_LOADER,
  CLEAR_USER_DETAILS,
  CLEAR_CLASS_DETAILS,
  CLEAR_FILE_SYSTEM_DETAILS,
  RESET_JITSI_STATE,
  CLEAR_LECTURE_DETAILS,
  CLEAR_LIVECLASS_DETAILS,
  CLEAR_MISCELLANEOUS_DETAILS,
  CLEAR_PEOPLE_DETAILS,
  CLEAR_POSTS_DETAILS,
  CLEAR_RECORDER_DETAILS,
  CLEAR_SECRET_DETAILS,
  CLEAR_SOCKET_DETAILS,
  SET_POPUP_DETAILS,
  SET_PERFORM_LOGOUT,
} from "../types";
import { getEnvironment } from "../../utils/misc";
import dayjs from "dayjs";
import store from "..";
import instantLectureUtils from "../../components/InstantLectureModule/InstantLectureUtils";
import notificationSound from "../../assets/sounds/notif_sound1.mp3";

export const setLoader = (loader) => (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: loader,
  });
};

export const setPerformLogout = (performLogout) => (dispatch) => {
  dispatch({
    type: SET_PERFORM_LOGOUT,
    payload: performLogout,
  });
};

export const logoutProcedure =
  (withApi = true) =>
  async (dispatch, getState) => {
    const {
      user,
      liveClass: { isLiveClassActive },
      socket: { socket },
    } = getState();
    const { sessionId } = user;

    if (isLiveClassActive) {
      dispatch(
        customAlert(
          "Class is going on...",
          "You need to leave the current class before you can logout."
        )
      );
      return;
    }

    localStorage.setItem("sessionId", "");
    localStorage.setItem("trialPopupShownTime", "");
    dispatch(setPerformLogout(true));

    if (socket && socket.connected) {
      socket.disconnect();
    }

    dispatch({
      type: CLEAR_USER_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_CLASS_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_FILE_SYSTEM_DETAILS,
      payload: null,
    });
    dispatch({
      type: RESET_JITSI_STATE,
      payload: null,
    });
    dispatch({
      type: CLEAR_LECTURE_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_MISCELLANEOUS_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_PEOPLE_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_POSTS_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_RECORDER_DETAILS,
      payload: null,
    });
    // dispatch({
    //   type: CLEAR_SECRET_DETAILS,
    //   payload: null,
    // });
    dispatch({
      type: CLEAR_SOCKET_DETAILS,
      payload: null,
    });
    dispatch({
      type: CLEAR_LIVECLASS_DETAILS,
      payload: null,
    });

    if (!withApi) {
      return;
    }

    makeApiRequest("POST", backendApi, "user/logout", true, {}, sessionId);
  };

export const errorAlert = (message) => (dispatch) => {
  dispatch(customAlert("Error", message));
};

export const resetAlert = () => (dispatch) => {
  dispatch(customAlert("", ""));
};

export const customAlert =
  (title, message, messageExtra1 = "") =>
  (dispatch, getState) => {
    const {
      user: { role },
    } = getState();

    if (role?.toUpperCase() === RECORDING_BOT) {
      return;
    }

    dispatch({
      type: SET_POPUP_DETAILS,
      payload: {
        popupTitle: title,
        popupMessage: message,
        popupMessageExtra1: messageExtra1,
      },
    });
  };

export const getSessionId = () => async (dispatch, getState) => {
  let {
    user: { sessionId, iSessionId },
  } = getState();

  if (instantLectureUtils().is().currentPageBelongsToInstantClassroom()) {
    if (!iSessionId) {
      iSessionId = await localStorage.getItem("i_session_id");
    }

    return iSessionId;
  } else {
    if (!sessionId) {
      sessionId = await localStorage.getItem("sessionId");
    }

    return sessionId;
  }
};

/**
 * @returns instant live-class session id
 */
export const getInstantLiveclassSessionId =
  () => async (dispatch, getState) => {
    let {
      user: { iSessionId },
    } = getState();

    if (!iSessionId) {
      iSessionId = await localStorage.getItem("i_session_id");
    }

    return iSessionId;
  };

export const getImageDimension = (url) => {
  return new Promise((resolve, reject) => {
    try {
      let img = new Image();
      img.src = url;
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    } catch (e) {
      reject({ width: 0, height: 0 });
    }
  });
};

export const getWidthHeightFromAspectRatio = (
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight
) => {
  // @containerWidth: maximum available width
  // @containerHeight: max available height
  // @imageWidth: width that needs to fit into @containerWidth
  // @imageHeight: height that needs to fit inot @containerHeight
  if (!imageWidth || !imageHeight) {
    return null;
  }
  const widthRatio = imageWidth / containerWidth;
  const heightRatio = imageHeight / containerHeight;

  const outWidthByWidth = Math.floor(imageWidth / widthRatio);
  const outHeightByWidth = Math.floor(imageHeight / widthRatio);

  const outWidthByHeight = Math.floor(imageWidth / heightRatio);
  const outHeightByHeight = Math.floor(imageHeight / heightRatio);

  if (outWidthByWidth > containerWidth || outHeightByWidth > containerHeight) {
    return { width: outWidthByHeight, height: outHeightByHeight };
  } else {
    return { width: outWidthByWidth, height: outHeightByWidth };
  }
};

export const validateEmail = (email) => {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
};
const validatePhone = (phone) => {
  // TODO: use a module for this
  let re = /^(\+\d{1,3}[- ]?)?\d{10}$/; // \d{1,3} accepts country code with up to 3 digit
  return re.test(phone);
};

export const checkPhoneOrEmail = (value) => {
  if (validateEmail(value)) {
    return "email";
  }
  if (validatePhone(value)) {
    return "phone";
  }

  return "invalid";
};

export const isMobileBrowser = () => {
  return /iphone|ipod|android/i.test(navigator.userAgent);
};

export const sortArrayByDateDescending = (arrayData) => {
  // b.started_at || b.schedule_start || b.created_at => for class data
  // b.created_time => for reference files

  return arrayData?.sort(
    (a, b) =>
      new Date(
        b.started_at || b.schedule_start || b.created_at || b.created_time
      ) -
      new Date(
        a.started_at || a.schedule_start || a.created_at || a.created_time
      )
  );
};
export const sortArrayByDateScending = (arrayData) => {
  return arrayData?.sort(
    (a, b) =>
      new Date(
        a.started_at || a.schedule_start || a.created_at || a.created_time
      ) -
      new Date(
        b.started_at || b.schedule_start || b.created_at || b.created_time
      )
  );
};
export const sortArrayByAlphabeticalOrder = (arrayData) => {
  // b.name => for title name
  // b.name => for reference title name

  return arrayData?.sort((a, b) => a.name.localeCompare(b.name));
};

export const getCssVarWithoutPx = (propertyName) => {
  const stringValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(propertyName);
  if (stringValue === "") {
    /**
     * @param propertyName does not exist
     */
    return null;
  }
  const parsedValue = parseFloat(stringValue.replace("px", ""));
  return parsedValue;
};

export const get16x9WidthAsPerHeight = (height) => {
  const heightBy9 = height / 9;
  const widthAsPerHeight = 16 * heightBy9;
  return widthAsPerHeight;
};

export const get16x9HeightAsPerWidth = (width) => {
  const widthBy16 = width / 16;
  const heightAsPerWidth = widthBy16 * 9;
  return heightAsPerWidth;
};

export const fit16x9AsPerHeight = (height, maxWidth) => {
  let finalWidth, finalHeight;
  const widthAsPerHeight = get16x9WidthAsPerHeight(height);
  finalWidth = widthAsPerHeight;
  finalHeight = height;
  return { width16x9: finalWidth, height16x9: finalHeight };
};

export const fit16x9AsPerWidth = (width, maxHeight) => {
  let finalWidth, finalHeight;
  const heightAsPerWidth = get16x9HeightAsPerWidth(width);
  finalWidth = width;
  finalHeight = heightAsPerWidth;
  return { width16x9: finalWidth, height16x9: finalHeight };
};

export const checkIfBreadCrumbTextOverflowParentBounds = (
  folderNameList,
  containerClassName,
  overflowContainerClassName
) => {
  let breadcrumbWithoutEllipsis = "";
  let breadcrumbWithEllipsis = "";
  const container = document.getElementById(containerClassName);
  const overflowContainer = document.getElementById(overflowContainerClassName);
  if (!container || !overflowContainer) return;
  const containerWidth = container?.offsetWidth;
  folderNameList.forEach((element) => {
    breadcrumbWithoutEllipsis = breadcrumbWithoutEllipsis + element[1] + ">";
    breadcrumbWithEllipsis =
      breadcrumbWithEllipsis + getSplitName(element[1], 20) + ">";
  });
  overflowContainer.innerHTML = breadcrumbWithoutEllipsis;
  const overflowContainerWidth = overflowContainer.offsetWidth;
  overflowContainer.innerHTML = breadcrumbWithEllipsis;
  const breadcrumbWithEllipsisWidth = overflowContainer.offsetWidth;
  overflowContainer.innerHTML = "";
  if (
    overflowContainerWidth > containerWidth &&
    breadcrumbWithEllipsisWidth > containerWidth
  ) {
    return { overflow: true, overflowWithEllipsis: true };
  } else if (breadcrumbWithEllipsisWidth > containerWidth) {
    return { overflow: false, overflowWithEllipsis: true };
  } else if (overflowContainerWidth > containerWidth) {
    return { overflow: true, overflowWithEllipsis: false };
  }
};

export const checkIfNumberIsDecimal = (num) => {
  return !!(num % 1);
};

export const isLicenseExpiredOrRevoked = (userSubscription) => {
  return (
    (userSubscription?.status === "ISSUED" &&
      dayjs(userSubscription?.valid_till).diff(
        dayjs(userSubscription?.current_time),
        "day",
        true
      ) <= 0) ||
    userSubscription?.status === "REVOKED"
  );
};

export const searchClassFilter = (searchWord, data) => {
  if (searchWord == null || searchWord === "") return data;
  else if (
    data.name.toLowerCase().includes(searchWord.toLowerCase()) ||
    data.batch.toLowerCase().includes(searchWord.toLowerCase())
  ) {
    return data;
  }
};

window.connectionStatus = () => {
  const {
    socket: { connected: socketConnected },
    jitsi: {
      connectionDropped: jitsiConnectionDropped,
      conferenceId: jitsiConferenceId,
    },
  } = store.getState();

  console.log(`
    socket: ${socketConnected ? "connected" : "disconnected"}
    jitsi: ${
      jitsiConferenceId && !jitsiConnectionDropped
        ? "connected"
        : "disconnected"
    }
  `);
};

/**
 * @param {"teacher" | "student" | "temp_teacher" | "temp_student"} userRole
 * @returns {boolean} for whether user belongs to teacher role or not
 */
export const isUserATeacher = (userRole) => {
  if ([TEACHER, TEMP_TEACHER].includes(String(userRole).toUpperCase())) {
    return true;
  }
  return false;
};

let liveClassNotificationAudio;
export const playNotificationSound = () => {
  if (!liveClassNotificationAudio) {
    liveClassNotificationAudio = new Audio(notificationSound);
  }
  if (liveClassNotificationAudio.pause) {
    liveClassNotificationAudio.play();
  }
};
