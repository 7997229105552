import React, { Component } from "react";
import "./SoundInputBarStyles.css";
import { connect } from "react-redux";
import { ReactComponent as MicOn } from "../Live-class-files/assets/micOn.svg";
import { ReactComponent as MicOff } from "../Live-class-files/assets/micOff.svg";
import {
  muteMyAudio,
  muteMyAudioPreview,
} from "../../store/actions/jitsiActions";
import SoundLevelBars from "./SoundLevelBars";

window.AudioContext = window.AudioContext || window.webkitAudioContext;

function timeDomainDataToAudioLevel(samples) {
  // lib-jitsi-meet modules/statistics/LocalStatsCollector.js
  let maxVolume = 0;

  const length = samples.length;

  for (let i = 0; i < length; i++) {
    if (maxVolume < samples[i]) {
      maxVolume = samples[i];
    }
  }

  return parseFloat(((maxVolume - 127) / 128).toFixed(3));
}

class SoundInputBar extends Component {
  state = {
    soundLevel: 0,
  };

  mic;
  soundLevelInterval;

  componentDidMount = () => {
    const audioTrack = this.getAudioTrack();
    if (audioTrack) {
      this.startMicLevel();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.trackType !== "audio-preview" &&
      !prevProps.myAudioTrack &&
      this.props.myAudioTrack
    ) {
      this.startMicLevel();
    }

    if (
      this.props.trackType === "audio-preview" &&
      !prevProps.myAudioPreviewTrack &&
      this.props.myAudioPreviewTrack
    ) {
      this.startMicLevel();
    }
  }

  componentWillUnmount() {
    this.stopMicLevel();
  }

  getAudioTrack = () => {
    const { myAudioTrack, myAudioPreviewTrack, trackType } = this.props;

    let audioTrack;

    if (trackType === "audio-preview") {
      audioTrack = myAudioPreviewTrack;
    } else {
      audioTrack = myAudioTrack;
    }

    return audioTrack;
  };

  getAudioMuted = () => {
    const { myAudioMuted, myAudioPreviewMuted, trackType } = this.props;

    let audioMuted;

    if (trackType === "audio-preview") {
      audioMuted = myAudioPreviewMuted;
    } else {
      audioMuted = myAudioMuted;
    }

    return audioMuted;
  };

  startMicLevel = () => {
    if (!window.AudioContext) {
      return;
    }

    const audioTrack = this.getAudioTrack();

    if (!audioTrack) {
      return;
    }

    // https://www.twilio.com/blog/audio-visualisation-web-audio-api--react
    // https://github.com/philnash/react-web-audio
    // lib-jitsi-meet modules/statistics/LocalStatsCollector.js
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(
      audioTrack.getOriginalStream()
    );
    this.source.connect(this.analyser);
    this.audioLevelsInterval = setInterval(() => {
      this.analyser.getByteTimeDomainData(this.dataArray);
      const audioLevel = timeDomainDataToAudioLevel(this.dataArray);
      this.setState({ soundLevel: audioLevel * 100 });
    }, 200);
  };

  stopMicLevel = () => {
    if (this.audioLevelsInterval) {
      clearInterval(this.audioLevelsInterval);
    }

    if (this.analyser) {
      this.analyser.disconnect();
    }

    if (this.source) {
      this.source.disconnect();
    }
  };

  switchOffMic = () => {
    if (this.props.trackType === "audio-preview") {
      this.props.muteMyAudioPreview(true);
    } else {
      this.props.muteMyAudio(true);
    }
  };

  switchOnMic = () => {
    if (this.props.trackType === "audio-preview") {
      this.props.muteMyAudioPreview(false);
    } else {
      this.props.muteMyAudio(false);
    }
  };

  renderMicIcon = () => {
    const audioMuted = this.getAudioMuted();

    return (
      <>
        {!audioMuted && (
          <MicOn
            className="mic-icon-start-now"
            onClick={this.switchOffMic}
            title="Switch Off Mic"
          />
        )}
        {audioMuted && (
          <MicOff
            className="mic-icon-start-now"
            onClick={this.switchOnMic}
            title="Switch On Mic"
          />
        )}
      </>
    );
  };

  render() {
    const { soundLevel } = this.state;

    return (
      <div className="Start_Now_Popup_Mic_Bars_Container">
        {this.renderMicIcon()}
        <SoundLevelBars soundLevel={soundLevel} />
      </div>
    );
  }
}

const mapStateToProps = ({ jitsi }) => ({
  myAudioMuted: jitsi.myAudioMuted,
  myAudioTrack: jitsi.myAudioTrack,
  myAudioPreviewTrack: jitsi.myAudioPreviewTrack,
  myAudioPreviewMuted: jitsi.myAudioPreviewMuted,
});

const mapDispatchToProps = {
  muteMyAudio,
  muteMyAudioPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundInputBar);
