import _ from "lodash";

import store from "..";
import {
  SET_JITSI_CONNECTION_INITIALIZED,
  SET_JITSI_CONFERENCE_OBJECT,
  SET_JITSI_CONNECTION_OBJECT,
  SET_JITSI_CONFERENCE_JOINED,
  SET_MY_JITSI_AUDIO_TRACK,
  SET_MY_JITSI_VIDEO_TRACK,
  SET_MY_JITSI_PARTICIPANT_DETAILS,
  ADD_JITSI_TRACK_TO_PARTICIPANT,
  REMOVE_JITSI_TRACK_FROM_PARTICIPANT,
  ADD_JITSI_PARTICIPANT,
  REMOVE_JITSI_PARTICIPANT,
  SET_JITSI_TRACK_MUTE_CHANGED,
  SET_MY_JITSI_TRACK_MUTE_CHANGED,
  RESET_JITSI_STATE,
  SET_JITSI_TRACKS_INITIALIZED,
  SET_MY_JITSI_DESKTOP_TRACK,
  SET_JITSI_AVAILABLE_MEDIA_DEVICES,
  SET_MY_AUDIO_OUTPUT_DEVICE_ID,
  SET_MY_VIDEO_INPUT_DEVICE_ID,
  SET_MY_AUDIO_INPUT_DEVICE_ID,
  SET_CHANGED_PARTICIPANT_PROPERTY,
  SET_KICKED_FROM_CONFERENCE,
  SET_JITSI_CONFERENCE_ENDED,
  SET_SELECTED_PARTICIPANT_IDS,
  SET_TEACHER_PARTICIPANTS,
  SET_STUDENT_PARTICIPANTS,
  SET_DOMINANT_PARTICIPANT,
  SET_SHARED_PARTICIPANT_DETAILS,
  SET_ALL_PARTICIPANTS,
  SET_CURRENT_TEACHER_PARTICIPANT,
  SET_VOLUME_LEVEL,
  SET_MY_AUDIO_PREVIEW_TRACK,
  SET_MY_VIDEO_PREVIEW_TRACK,
  SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED,
  CLEANUP_JITSI_PREVIEW_TRACKS,
  SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
  SET_JITSI_CONNECTION_DROPPED,
  SET_JITSI_CONNECTION_RECONNECTED,
  SET_DESKTOP_PARTICIPANTS,
  SET_LINK_CONNECT_PARTICIPANTS,
} from "../types";
import {
  STUDENT,
  TEACHER,
  RECORDING_BOT,
  VIDEO_SHARED,
  VIDEO_NOT_SHARED,
  VIDEO_TYPES,
  WHITEBOARD_TYPES,
  CURRENT_DEVICE,
  WEB_BROWSER,
  MOBILE_APP,
  PAGINATION_CONTENT_SIZE,
  VIDEO_OPTIONS,
  OPTIONS,
  VIDEO,
  AUDIO,
  MEDIA_SHARE_OPTIONS,
  STUDENT_VIDEO_QUALITY_VALUES,
  BITRATE_CAPS_ENABLED,
  PARTICIPANT_TYPES,
} from "../../utils/constants";
import JitsiMeetJS from "../../utils/JitsiMeetJS";
import { getUserStartupData, updateStudentPreferences } from "./userActions";
import { muteUserAudioRecording } from "./recorderActions";
import Resolutions from "../../utils/Resolutions";
import findVideoTrack from "../../components/StudentPresentationView/findVideoTrack";
import { studentSort, teacherSort } from "../../utils/participantSorting";
import { turnOff } from "./smartboard";
import { errorAlert } from "./utils";
import { cleanupJitsiX, initJitsiXConnection } from "./jitsiXActions";
import { setConnectionErrorType } from "./miscellaneousActions";
import { connectionError } from "../../utils/tinyUtils";
import instantLectureUtils from "../../components/InstantLectureModule/InstantLectureUtils";
import { instantJitsi } from "./instantLectureJitsiActions";
import { createVirtualBackgroundEffect } from "../../streamEffects/virtualBackground";
import { VirtualBackgrounds } from "../../components/StartNowPopup/utils/VirtualBackgroundList";

const defaultResolutions = {
  [STUDENT]: {
    max: "320",
    min: "180",
  },
  [TEACHER]: {
    max: "640",
    min: "180",
  },
  [RECORDING_BOT]: {
    max: "640",
    min: "320",
  },
};

const jitsiConfig = {
  enableNoAudioDetection: false,
  enableNoisyMicDetection: false,
  useIPv6: false,
  disableAudioLevels: true,
  startSilent: false,
  enableWindowOnErrorHandler: false,
  disableThirdPartyRequests: false,
  enableAnalyticsLogging: false,
  enableLayerSuspension: true,
  disableSimulcast: false,
  videoQuality: {
    preferredCodec: "VP8",
    enforcePreferredCodec: false,
  },
  clientNode: "http://jitsi.org/jitsimeet",
  useNicks: false,
  getWiFiStatsMethod: null,
  useStunTurn: true,
  p2p: {
    enabled: false,
    useStunTurn: true,
    stunServers: [{ urls: "stun:meet-jit-si-turnrelay.jitsi.net:443" }],
  },
  analytics: {},
  deploymentInfo: {},
  e2eping: {
    pingInterval: -1,
  },
  disableRtx: true,
};

const customCommands = {
  MUTE_MIC_YOURSELF: "MUTE_MIC_YOURSELF",
  MUTE_VIDEO_YOURSELF: "MUTE_VIDEO_YOURSELF",
  MUTE_ALL_VIDEOS: "MUTE_ALL_VIDEOS",
  MUTE_ALL_MICS: "MUTE_ALL_MICS",
  START_SHARING_YOUR_VIDEO: "START_SHARING_YOUR_VIDEO",
  STOP_SHARING_YOUR_VIDEO: "STOP_SHARING_YOUR_VIDEO",
  KICK_YOURSELF: "KICK_YOURSELF",
  CONFERENCE_ENDED: "CONFERENCE_ENDED",
};

let CURRENT_USER = TEACHER;

export const initJitsiLib = () => (dispatch) => {
  JitsiMeetJS.init(jitsiConfig);
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
};

export const initJitsiConnection =
  (forceReconnect = false) =>
  (dispatch, getState) => {
    const { jitsi, user } = getState();
    const { jitsiRootDomain, jitsiConnectionInitialized, conferenceId } = jitsi;
    const { role = "" } = user;

    if (jitsiConnectionInitialized && !forceReconnect) {
      return;
    }

    console.log("Jitsi Action initJitsiConnection ***************************");

    dispatch({
      type: SET_JITSI_CONNECTION_INITIALIZED,
      payload: null,
    });

    CURRENT_USER = role.toUpperCase() || TEACHER;

    // jitsi service url and hosts
    jitsiConfig.serviceUrl = `https://${jitsiRootDomain}/http-bind?room=${conferenceId}`;
    jitsiConfig.hosts = {
      domain: jitsiRootDomain,
      muc: `conference.${jitsiRootDomain}`,
    };

    if (BITRATE_CAPS_ENABLED) {
      jitsiConfig.videoQuality.maxBitratesVideo = {
        H264: {
          low: 200000,
          standard: 500000,
          high: 1500000,
        },
        VP8: {
          low: 200000,
          standard: 500000,
          high: 1500000,
        },
        VP9: {
          low: 100000,
          standard: 300000,
          high: 1200000,
        },
      };
    }

    // we are using this line to make sure the initial lastN value is 0. but somehow after we started using the new pagination api, this line is causing new joiners to see grey videos. so instead we are now calling selectParticipants([]) on conference joined.
    // jitsiConfig.channelLastN = 0;

    const jitsiConnection = new JitsiMeetJS.JitsiConnection(
      null,
      null,
      jitsiConfig
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => dispatch(initJitsiConference())
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      (errorCode) => {
        dispatch(onConnectionFailed(errorCode));
      }
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      (...args) => {
        console.log("CONNECTION_DISCONNECTED", { ...args });
      }
    );

    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.WRONG_STATE,
      (...args) => {
        console.log("WRONG_STATE", { ...args });
      }
    );

    jitsiConnection.connect();

    dispatch({
      type: SET_JITSI_CONNECTION_OBJECT,
      payload: jitsiConnection,
    });
  };

const initJitsiConference = () => (dispatch, getState) => {
  const {
    jitsi,
    misc: { connectionErrorType },
    liveClass: { connectionIssue: socketConnectionDropped },
  } = getState();
  const { conferenceId, jitsiConnection, connectionDropped } = jitsi;

  const jitsiConference = jitsiConnection.initJitsiConference(
    conferenceId,
    jitsiConfig
  );

  dispatch(addConferenceEventListeners(jitsiConference));

  jitsiConference.join();

  dispatch({
    type: SET_JITSI_CONFERENCE_OBJECT,
    payload: jitsiConference,
  });

  if (connectionDropped) {
    dispatch(connectionReconnected());
  }

  /**
   * if socket & jitsi both got disconnected, and then jitsi got reconnected
   * but socket is not, then update the errorType to "socket"
   * else just hide the connection error popup
   */
  if (
    connectionErrorType === connectionError.socketAndJitsi &&
    socketConnectionDropped
  ) {
    store.dispatch(setConnectionErrorType(connectionError.socket));
  } else if (connectionErrorType) {
    store.dispatch(setConnectionErrorType(null));
  }
};

const addConferenceEventListeners = (jitsiConference) => (dispatch) => {
  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_FAILED,
    (...args) => {
      console.log("CONFERENCE_FAILED", { ...args });
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.CONFERENCE_ERROR,
    (...args) => {
      console.log("CONFERENCE_ERROR", { ...args });
    }
  );

  jitsiConference.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
    dispatch(onTrackAdded(track));
  });

  jitsiConference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
    dispatch(onTrackRemoved(track));
  });

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
    dispatch(onConferenceJoined());
  });

  jitsiConference.on(JitsiMeetJS.events.conference.CONFERENCE_LEFT, () => {});

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_JOINED,
    (participantId) => {
      dispatch(onParticipantJoined(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.USER_LEFT,
    (participantId) => {
      console.log("user_left", { participantId });
      dispatch(onParticipantLeft(participantId));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
    (track) => {
      dispatch(onTrackMuteChanged(track));
    }
  );

  jitsiConference.on(
    JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
    (user, key, ignore, value) => {
      dispatch(onParticipantPropertyChanged(user.getId(), key, value));
    }
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_MIC_YOURSELF,
    ({ attributes }) => dispatch(onMuteMicYourself(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_VIDEO_YOURSELF,
    ({ attributes }) => dispatch(onMuteVideoYourself(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_ALL_MICS,
    ({ attributes }) =>
      dispatch(
        onMuteAllMics(
          attributes.participantType,
          attributes.initiatorParticipantId
        )
      )
  );

  jitsiConference.addCommandListener(
    customCommands.MUTE_ALL_VIDEOS,
    ({ attributes }) =>
      dispatch(
        onMuteAllVideos(
          attributes.participantType,
          attributes.initiatorParticipantId
        )
      )
  );

  jitsiConference.addCommandListener(
    customCommands.START_SHARING_YOUR_VIDEO,
    ({ attributes }) =>
      dispatch(onStartSharingYourVideo(attributes.participantId))
  );

  jitsiConference.addCommandListener(
    customCommands.STOP_SHARING_YOUR_VIDEO,
    ({ attributes }) =>
      dispatch(onStopSharingYourVideo(attributes.participantId))
  );

  // kicking yourself is being done using socket now. this listener is not useful anymore
  // jitsiConference.addCommandListener(
  //   customCommands.KICK_YOURSELF,
  //   ({ attributes }) => dispatch(onKickYourself(attributes.participantId))
  // );

  jitsiConference.addCommandListener(customCommands.CONFERENCE_ENDED, () => {
    dispatch(onConferenceEnded());
  });

  jitsiConference.on(
    JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
    (participantId) => {
      dispatch(onDominantSpeakerChanged(participantId));
    }
  );

  JitsiMeetJS.mediaDevices.addEventListener(
    JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
    onDeviceListChangedListener
  );
};

export const onConnectionFailed = (errorCode) => (dispatch) => {
  console.log("CONNECTION_FAILED", errorCode);

  dispatch({
    type: SET_JITSI_CONNECTION_DROPPED,
    payload: null,
  });

  if (!global.jitsiReconnectionAttemptCount)
    global.jitsiReconnectionAttemptCount = 1;
  else global.jitsiReconnectionAttemptCount += 1;

  global.jitsiReconnectionTimeout = setTimeout(() => {
    dispatch(initJitsiReConnection());
  }, 5000);
};

export const initJitsiReConnection = () => (dispatch, getState) => {
  const {
    jitsi,
    misc: { connectionErrorType },
  } = getState();
  const { connectionDropped } = jitsi;
  if (!connectionDropped) {
    // if jitsi gets reconnected, hide the connection error popup
    dispatch(setConnectionErrorType(null));
    dispatch(connectionReconnected());
  } else {
    // 9 iteration with 5sec of timeout equals 45sec
    if (global.jitsiReconnectionAttemptCount === 9) {
      /**
       * if socket is already disconnected and then jitsi gets disconnected, then
       * set the connectionErrorType should be both "socketAndJitsi" else "jitsi"
       */
      if (connectionErrorType === connectionError.socket) {
        store.dispatch(setConnectionErrorType(connectionError.socketAndJitsi));
      } else {
        store.dispatch(setConnectionErrorType(connectionError.jitsi));
      }
    }
    dispatch(initJitsiConnection());
  }
};

export const connectionReconnected = () => (dispatch) => {
  dispatch(clearJitsiReconnectionTimeout());

  dispatch({
    type: SET_JITSI_CONNECTION_RECONNECTED,
    payload: null,
  });
};

const clearJitsiReconnectionTimeout = () => (dispatch) => {
  if (global.jitsiReconnectionTimeout) {
    clearTimeout(global.jitsiReconnectionTimeout);
    global.jitsiReconnectionTimeout = undefined;
    global.jitsiReconnectionAttemptCount = 0;
  }
};

export const cleanupJitsi = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const {
    jitsiConnection,
    jitsiConference,
    myVideoTrack,
    myAudioTrack,
    myDesktopTrack,
  } = jitsi;

  dispatch(cleanupJitsiPreviewTracks());

  if (global.finishedWaitingTimeout) {
    clearTimeout(global.finishedWaitingTimeout);
    global.finishedWaitingTimeout = undefined;
  }

  if (global.calculateParticipantsListTimeout) {
    clearTimeout(global.calculateParticipantsListTimeout);
    global.calculateParticipantsListTimeout = undefined;
  }

  dispatch(clearJitsiReconnectionTimeout());

  dispatch({
    type: RESET_JITSI_STATE,
    payload: null,
  });

  JitsiMeetJS.mediaDevices.removeEventListener(
    JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
    onDeviceListChangedListener
  );

  if (jitsiConference) {
    jitsiConference.leave();
  }

  if (jitsiConnection) {
    jitsiConnection.disconnect();
  }

  if (myVideoTrack) {
    myVideoTrack.dispose();
  }

  if (myAudioTrack) {
    myAudioTrack.dispose();
  }

  if (myDesktopTrack) {
    myDesktopTrack.dispose();
  }
};

export const cleanupJitsiPreviewTracks = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myVideoPreviewTrack, myAudioPreviewTrack } = jitsi;

  if (myVideoPreviewTrack) {
    myVideoPreviewTrack.dispose();
  }

  if (myAudioPreviewTrack) {
    myAudioPreviewTrack.dispose();
  }

  dispatch({
    type: CLEANUP_JITSI_PREVIEW_TRACKS,
    payload: null,
  });
};

export const extractResolutions = () => (dispatch, getState) => {
  let currentClassDetails;
  if (CURRENT_DEVICE === WEB_BROWSER) {
    const { klass, liveClass } = getState();
    const { classes } = klass;
    const { classId } = liveClass;
    currentClassDetails = classes[classId] || {};
  } else {
    const { classes } = getState();
    const { classDetailsMap, currentClass } = classes;
    currentClassDetails = classDetailsMap[currentClass] || {};
  }
  dispatch(setJitsiResolutions(currentClassDetails));
};

const setJitsiResolutions = (classDetails) => (dispatch) => {
  const {
    student_video_resolution_max,
    student_video_resolution_min,
    teacher_video_resolution_max,
    teacher_video_resolution_min,
  } = classDetails;

  let maxHeight, maxWidth, minHeight, minWidth, resolution;
  if (CURRENT_USER === STUDENT) {
    resolution = student_video_resolution_max;
    let maxResolutionDetails = Resolutions[resolution];

    if (!maxResolutionDetails) {
      resolution = defaultResolutions[STUDENT].max;
      maxResolutionDetails = Resolutions[resolution];
    }

    const minResolutionDetails =
      Resolutions[student_video_resolution_min] ||
      Resolutions[defaultResolutions[STUDENT].min];

    maxHeight = maxResolutionDetails.height;
    maxWidth = maxResolutionDetails.width;

    minHeight = minResolutionDetails.height;
    minWidth = minResolutionDetails.width;
  } else {
    resolution = teacher_video_resolution_max;
    let maxResolutionDetails = Resolutions[resolution];

    if (!maxResolutionDetails) {
      resolution = defaultResolutions[TEACHER].max;
      maxResolutionDetails = Resolutions[resolution];
    }

    const minResolutionDetails =
      Resolutions[teacher_video_resolution_min] ||
      Resolutions[defaultResolutions[TEACHER].min];

    maxHeight = maxResolutionDetails.height;
    maxWidth = maxResolutionDetails.width;

    minHeight = minResolutionDetails.height;
    minWidth = minResolutionDetails.width;
  }

  jitsiConfig.resolution = resolution;
  jitsiConfig.constraints = {
    video: {
      frameRate: {
        max: 30,
        min: 10,
      },
      height: {
        ideal: maxHeight,
        max: maxHeight,
        min: minHeight,
      },
      width: {
        ideal: maxWidth,
        max: maxWidth,
        min: minWidth,
      },
    },
  };
};

export const getUserMedia =
  (muteVideo = false, muteAudio = false) =>
  async (dispatch) => {
    const videoTracksOptions = {
      devices: ["video"],
    };

    const audioTracksOptions = {
      devices: ["audio"],
    };

    if (CURRENT_DEVICE === MOBILE_APP) {
      // videoTracksOptions.resolution = jitsiConfig.resolution;
      videoTracksOptions.resolution = 360;
    }

    // audio track
    let audioTracks;

    try {
      audioTracks = await JitsiMeetJS.createLocalTracks(audioTracksOptions);
    } catch (e) {
      console.log(e);
    }

    audioTracks = audioTracks || [];

    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (muteAudio) {
        try {
          await audioTrack.mute();
        } catch (e) {
          console.log(e);
        }
      }

      dispatch(setMyJitsiAudioTrack(audioTrack));
    }

    // video track
    let videoTracks;

    try {
      videoTracks = await JitsiMeetJS.createLocalTracks(videoTracksOptions);
    } catch (e) {
      console.log(e);
    }

    videoTracks = videoTracks || [];

    const videoTrack = videoTracks[0];

    if (videoTrack) {
      if (muteVideo) {
        try {
          await videoTrack.mute();
        } catch (e) {
          console.log(e);
        }
      }

      dispatch(setMyJitsiVideoTrack(videoTrack));
    }

    dispatch({
      type: SET_JITSI_TRACKS_INITIALIZED,
      payload: null,
    });
  };

export const setMyJitsiUserName = (userName) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (!myParticipantId) {
    return;
  }

  dispatch(setJitsiParticipantProperty(myParticipantId, "userName", userName));
};

export const setMyJitsiUserEmail = (userEmail) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (!myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userEmail", userEmail)
  );
};

const jitsiConferenceAddTrack = (track) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference, participants, myParticipantId } = jitsi;

  if (!track) {
    return;
  }

  if (!jitsiConference) {
    return;
  }

  try {
    await jitsiConference.addTrack(track);
  } catch (error) {
    console.log(error);
    if (
      error &&
      error.message &&
      error.message.includes("second video track")
    ) {
      const participant = participants[myParticipantId];
      if (participant && participant.tracks) {
        const [toRemoveVideoTrack] = (participant.tracks || []).filter(
          (t) => t.type !== "audio"
        );

        if (!toRemoveVideoTrack || !toRemoveVideoTrack.track) {
          return;
        }

        try {
          await jitsiConference.removeTrack(toRemoveVideoTrack.track);
          await toRemoveVideoTrack.track.dispose();
          await dispatch(jitsiConferenceAddTrack(track));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
};

const onConferenceJoined = () => async (dispatch, getState) => {
  const { jitsi, user } = getState();
  const {
    jitsiConference,
    myVideoTrack,
    myAudioTrack,
    myDesktopTrack,
    myVideoMuted,
  } = jitsi;

  if (!jitsiConference) {
    return;
  }

  dispatch({
    type: SET_JITSI_CONFERENCE_JOINED,
    payload: null,
  });

  global.finishedWaitingTimeout = setTimeout(() => {
    dispatch({
      type: SET_FINISHED_WAITING_AFTER_JITSI_CONFERENCE_JOINED,
    });
    global.finishedWaitingTimeout = undefined;
  }, 5000);

  const myParticipantId = jitsiConference.myUserId();

  dispatch({
    type: SET_MY_JITSI_PARTICIPANT_DETAILS,
    payload: {
      myParticipantId,
    },
  });

  if (myVideoTrack) {
    await dispatch(jitsiConferenceAddTrack(myVideoTrack));
  } else if (myDesktopTrack) {
    await dispatch(jitsiConferenceAddTrack(myDesktopTrack));
  }

  if (myAudioTrack) {
    await dispatch(jitsiConferenceAddTrack(myAudioTrack));
  }

  dispatch(getAvailableMediaDevices());

  dispatch(setJitsiParticipantProperty(myParticipantId, "userId", user.id));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "participantType",
      PARTICIPANT_TYPES.USER
    )
  );
  dispatch(setMyJitsiUserName(user.name));
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "userImage",
      user.profile_photo_url || user.profilePhotoUrl
    )
  );
  dispatch(setMyJitsiUserEmail(user.email));
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "userType", CURRENT_USER)
  );
  dispatch(
    setMyVideoType(
      myVideoMuted ? VIDEO_TYPES.VIDEO_OFF : VIDEO_TYPES.VIDEO_NORMAL
    )
  );
  dispatch(setMyVideoState());
  if (CURRENT_USER === TEACHER) {
    dispatch(setMyWhiteboardType(WHITEBOARD_TYPES.WHITEBOARD_OFF));
  }
  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "lastStartedSpeaking",
      new Date(0).toISOString()
    )
  );

  dispatch(selectParticipants([]));

  if (CURRENT_DEVICE === MOBILE_APP) {
    jitsiConference.setSenderVideoConstraint(360);
  }
};

const onParticipantJoined = (participantId) => (dispatch) => {
  dispatch({
    type: ADD_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

const setJitsiParticipantProperty =
  (myParticipantId, key, value) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference } = jitsi;

    if (!jitsiConference) {
      return;
    }

    jitsiConference.setLocalParticipantProperty(key, value);
    dispatch(onParticipantPropertyChanged(myParticipantId, key, value));
  };

const onParticipantPropertyChanged =
  (participantId, key, value) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { myParticipantId, participants } = jitsi;

    setTimeout(() => {
      dispatch({
        type: SET_CHANGED_PARTICIPANT_PROPERTY,
        payload: {
          participantId,
          key,
          value: key === "userId" ? Number(value) : value,
        },
      });

      dispatch(calculateParticipantsList());
    }, 1000);

    /* *
    Stop my own broadcasting if:
      1. some other user has started broadcasting mediashare, whiteboard or screenshare
      or
      2. Teacher casted someone else's video/screenshare to class
    */
    if (
      participantId !== myParticipantId &&
      key === "videoShared" &&
      value === VIDEO_SHARED
    ) {
      if (CURRENT_USER === TEACHER) {
        dispatch(turnOff());
        dispatch(stopDesktopSharing());
      }
    }

    if (
      participantId !== myParticipantId &&
      key === "broadcastType" &&
      [OPTIONS.SLIDESHARE, OPTIONS.WHITEBOARD, OPTIONS.SCREENSHARE].includes(
        value
      )
    ) {
      if (CURRENT_USER === TEACHER) {
        dispatch(turnOff());
        dispatch(stopDesktopSharing());
      }
    }
  };

const onParticipantLeft = (participantId) => (dispatch) => {
  dispatch({
    type: REMOVE_JITSI_PARTICIPANT,
    payload: participantId,
  });

  dispatch(calculateParticipantsList());
};

const onTrackAdded = (track) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (!track) return;

  let participantId;

  if (track.isLocal()) {
    participantId = myParticipantId;
  } else {
    participantId = track.getParticipantId();
  }

  dispatch({
    type: ADD_JITSI_TRACK_TO_PARTICIPANT,
    payload: {
      participantId,
      track,
    },
  });

  dispatch(calculateParticipantsList());
};

const onTrackRemoved = (track) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  let participantId;
  if (track.isLocal()) {
    participantId = myParticipantId;
  } else {
    participantId = track.getParticipantId();
  }

  dispatch({
    type: REMOVE_JITSI_TRACK_FROM_PARTICIPANT,
    payload: {
      participantId,
      track,
    },
  });

  dispatch(calculateParticipantsList());
};

const onTrackMuteChanged = (track) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  const trackParticipantId = track.getParticipantId();

  dispatch({
    type: SET_JITSI_TRACK_MUTE_CHANGED,
    payload: {
      participantId: trackParticipantId,
      track,
    },
  });

  if (myParticipantId === trackParticipantId) {
    dispatch(setMyJitsiTrackMuteChanged());
  }

  dispatch(calculateParticipantsList());
};

export const muteMyVideo = (mute) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { myVideoTrack, myDesktopTrack } = jitsi;

  if (mute) {
    dispatch(unshareSelfVideo());
  }

  if (!mute && myDesktopTrack) {
    try {
      await dispatch(stopDesktopSharing(false));
    } catch (e) {
      console.log(e);
    }
  }

  if (myVideoTrack) {
    try {
      mute ? await myVideoTrack.mute() : await myVideoTrack.unmute();
    } catch (e) {
      console.log("failed to video track", e);
    }
  }

  dispatch(setMyJitsiTrackMuteChanged());
};

export const muteMyAudio = (mute) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { myAudioTrack } = jitsi;

  if (!myAudioTrack) {
    return;
  }

  try {
    mute ? await myAudioTrack.mute() : await myAudioTrack.unmute();
  } catch (e) {
    console.log(e);
  }

  dispatch(setMyJitsiTrackMuteChanged());

  dispatch(muteUserAudioRecording(mute));
};

export const startDesktopSharing = () => async (dispatch, getState) => {
  dispatch(initJitsiXConnection());
};

export const stopDesktopSharing =
  (muteVideoTrack = true) =>
  async (dispatch, getState) => {
    await dispatch(cleanupJitsiX());
  };

const setJitsiAvailableMediaDevices = (devices) => (dispatch) => {
  dispatch({
    type: SET_JITSI_AVAILABLE_MEDIA_DEVICES,
    payload: devices,
  });
};

export const getAvailableMediaDevices =
  (mediaDevices = []) =>
  (dispatch, getState) => {
    if (mediaDevices.length === 0) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(async () => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              dispatch(setJitsiAvailableMediaDevices(devices));
            })
            .catch((error) => {
              console.error("Error enumerating devices:", error);
            });
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    } else {
      dispatch(setJitsiAvailableMediaDevices(mediaDevices));
    }

    const { jitsi } = getState();
    const { myVideoTrack, myAudioTrack } = jitsi;

    if (myVideoTrack) {
      dispatch(setMyVideoInputDeviceId(myVideoTrack.getDeviceId()));
    }

    if (myAudioTrack) {
      dispatch(setMyAudioInputDeviceId(myAudioTrack.getDeviceId()));
    }

    dispatch(
      setMyAudioOutputDeviceId(JitsiMeetJS.mediaDevices.getAudioOutputDevice())
    );
  };

export const changeMyJitsiVideoVirtualBackground =
  (backgroundId) => async (dispatch, getState) => {
    const { jitsi } = getState();
    const { myVideoTrack } = jitsi;

    myVideoTrack.setEffect(
      await createVirtualBackgroundEffect(
        {
          backgroundEffectEnabled: true,
          backgroundType: "image",
          blurValue: 1,
          selectedThumbnail: VirtualBackgrounds[backgroundId].path,
          virtualSource: VirtualBackgrounds[backgroundId].path,
        },
        dispatch
      )
    );

    dispatch(setMyJitsiVideoTrack(myVideoTrack));
  };

export const changeMyVideoInputDevice =
  (deviceId) => async (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, myDesktopTrack, myVideoTrack, myVideoMuted } =
      jitsi;

    dispatch(setMyVideoInputDeviceId(deviceId));

    if (myDesktopTrack) {
      return;
    }

    const mediaTracksOptions = {
      devices: ["video"],
      cameraDeviceId: deviceId,
    };

    if (CURRENT_DEVICE === MOBILE_APP) {
      // mediaTracksOptions.resolution = jitsiConfig.resolution;
      mediaTracksOptions.resolution = 360;
    }

    let videoTracks;

    try {
      videoTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    videoTracks = videoTracks || [];

    const videoTrack = videoTracks[0];

    if (!videoTrack) {
      return;
    }

    dispatch(setMyJitsiVideoTrack(videoTrack));

    if (!jitsiConference) {
      return;
    }

    try {
      await jitsiConference.removeTrack(myVideoTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myVideoTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    if (myVideoMuted) {
      dispatch(muteMyVideo(true));
    }

    await dispatch(jitsiConferenceAddTrack(videoTrack));
  };

export const changeMyAudioInputDevice =
  (deviceId) => async (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, myAudioTrack, myAudioMuted } = jitsi;

    dispatch(setMyAudioInputDeviceId(deviceId));

    const mediaTracksOptions = {
      devices: ["audio"],
      micDeviceId: deviceId,
    };

    let audioTracks;

    try {
      audioTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    audioTracks = audioTracks || [];

    const audioTrack = audioTracks[0];

    if (!audioTrack) {
      return;
    }

    dispatch(setMyJitsiAudioTrack(audioTrack));

    if (!jitsiConference) {
      return;
    }

    try {
      await jitsiConference.removeTrack(myAudioTrack);
    } catch (e) {
      console.log(e);
    }

    try {
      await myAudioTrack.dispose();
    } catch (e) {
      console.log(e);
    }

    // this fixes mic issues related to headphone plugin plugout
    dispatch(muteMyAudio(true)).then(() => {
      if (!myAudioMuted) {
        dispatch(muteMyAudio(false));
      }
    });

    await dispatch(jitsiConferenceAddTrack(audioTrack));
  };

export const changeMyAudioOutputDevice = (deviceId) => (dispatch) => {
  dispatch(setMyAudioOutputDeviceId(deviceId));
  JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId);
};

export const muteParticipantMic = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.MUTE_MIC_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

const onDeviceListChangedListener = (mediaDevices) => {
  store.dispatch(onDeviceListChanged(mediaDevices));
};

const onDeviceListChanged =
  (mediaDevices = []) =>
  (dispatch, getState) => {
    const { jitsi } = getState();
    const { availableMediaDevices } = jitsi;

    dispatch(getAvailableMediaDevices(mediaDevices));

    let prevAudioInputs = availableMediaDevices.filter(
      (d) => d.kind === "audioinput"
    );
    let prevVideoInputs = availableMediaDevices.filter(
      (d) => d.kind === "videoinput"
    );
    let audioInputs = mediaDevices.filter((d) => d.kind === "audioinput");
    let videoInputs = mediaDevices.filter((d) => d.kind === "videoinput");

    if (
      prevAudioInputs.length !== audioInputs.length &&
      audioInputs.length > 0
    ) {
      dispatch(changeMyAudioInputDevice(audioInputs[0].deviceId));
    }

    if (
      prevVideoInputs.length !== videoInputs.length &&
      videoInputs.length > 0
    ) {
      dispatch(changeMyVideoInputDevice(videoInputs[0].deviceId));
    }
  };

export const muteParticipantVideo = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.MUTE_VIDEO_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

const onMuteMicYourself = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId === myParticipantId) {
    dispatch(muteMyAudio(true));
  }
};

const onMuteVideoYourself = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId === myParticipantId) {
    dispatch(muteAnyOfMyVideo());
  }
};

const muteAnyOfMyVideo = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myDesktopTrack } = jitsi;

  if (myDesktopTrack) {
    dispatch(stopDesktopSharing());
    return;
  }

  dispatch(muteMyVideo(true));
};

export const shareParticipantVideo =
  (participantId) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, participantIds, participants } = jitsi;

    if (!jitsiConference) {
      return;
    }

    const sharedParticipantIds = participantIds
      .filter((pid) => !!participants[pid])
      .filter((pid) => participants[pid].videoShared === VIDEO_SHARED);

    let sendShareCommand = true;

    sharedParticipantIds.forEach((pid) => {
      if (pid === participantId) {
        sendShareCommand = false;
        return;
      }

      jitsiConference.sendCommandOnce(customCommands.STOP_SHARING_YOUR_VIDEO, {
        attributes: {
          participantId: pid,
        },
      });
    });

    if (sendShareCommand) {
      jitsiConference.sendCommandOnce(customCommands.START_SHARING_YOUR_VIDEO, {
        attributes: {
          participantId,
        },
      });
    }
  };

export const unshareParticipantVideo = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference, participantIds, participants } = jitsi;

  if (!jitsiConference) {
    return;
  }

  const sharedParticipantIds = participantIds
    .filter((pid) => !!participants[pid])
    .filter((pid) => participants[pid].videoShared === VIDEO_SHARED);

  sharedParticipantIds.forEach((pid) => {
    jitsiConference.sendCommandOnce(customCommands.STOP_SHARING_YOUR_VIDEO, {
      attributes: {
        participantId: pid,
      },
    });
  });
};

const onStartSharingYourVideo = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoShared", VIDEO_SHARED)
  );
};

const onStopSharingYourVideo = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "videoShared",
      VIDEO_NOT_SHARED
    )
  );
};

export const sendKickParticipant = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.KICK_YOURSELF, {
    attributes: {
      participantId,
    },
  });
};

// kicking yourself is being done using socket now. this function is not useful anymore
const onKickYourself = (participantId) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId !== myParticipantId) {
    return;
  }

  dispatch(setKickedFromConference());
};

export const setKickedFromConference = () => (dispatch) => {
  dispatch({
    type: SET_KICKED_FROM_CONFERENCE,
    payload: true,
  });
};

export const endConferenceForAll = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference } = jitsi;

  if (!jitsiConference) {
    return;
  }

  jitsiConference.sendCommandOnce(customCommands.CONFERENCE_ENDED, {
    attributes: {},
  });
};

const onConferenceEnded = () => (dispatch) => {
  dispatch({
    type: SET_JITSI_CONFERENCE_ENDED,
    payload: true,
  });

  if (CURRENT_DEVICE === MOBILE_APP) {
    dispatch(getUserStartupData("student"));
  }
};

const setMyJitsiAudioTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_AUDIO_TRACK,
    payload: track,
  });
};

const setMyJitsiVideoTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_VIDEO_TRACK,
    payload: track,
  });
};

const setMyJitsiDesktopTrack = (track) => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_DESKTOP_TRACK,
    payload: track,
  });
};

const setMyJitsiTrackMuteChanged = () => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_TRACK_MUTE_CHANGED,
    payload: null,
  });
};

const setMyAudioOutputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_AUDIO_OUTPUT_DEVICE_ID,
    payload: deviceId,
  });
};

const setMyAudioInputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_AUDIO_INPUT_DEVICE_ID,
    payload: deviceId,
  });
};

const setMyVideoInputDeviceId = (deviceId) => (dispatch) => {
  dispatch({
    type: SET_MY_VIDEO_INPUT_DEVICE_ID,
    payload: deviceId,
  });
};

export const switchCameraTo =
  (facingMode, muteVideo = false) =>
  async (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, myVideoTrack } = jitsi;

    if (!jitsiConference) {
      return;
    }

    if (facingMode !== "user" && facingMode !== "environment") {
      return;
    }

    if (myVideoTrack) {
      try {
        await jitsiConference.removeTrack(myVideoTrack);
      } catch (e) {
        console.log(e);
      }

      try {
        await myVideoTrack.dispose();
      } catch (e) {
        console.log(e);
      }

      dispatch(setMyJitsiVideoTrack(null));
    }

    const mediaTracksOptions = {
      devices: ["video"],
      facingMode,
    };

    if (CURRENT_DEVICE === MOBILE_APP) {
      // mediaTracksOptions.resolution = jitsiConfig.resolution;
      mediaTracksOptions.resolution = 360;
    }

    let videoTracks;

    try {
      videoTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    videoTracks = videoTracks || [];

    const videoTrack = videoTracks[0];

    if (!videoTrack) {
      return;
    }

    if (muteVideo) {
      try {
        await videoTrack.mute();
      } catch (e) {
        console.log(e);
      }
    }

    dispatch(setMyJitsiVideoTrack(videoTrack));

    await dispatch(jitsiConferenceAddTrack(videoTrack));
  };

const setLastN =
  (n = -1) =>
  (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference } = jitsi;

    if (jitsiConference) {
      jitsiConference.setLastN(n);
    }
  };

export const selectParticipants =
  (participantIds = [], qualityToSet) =>
  (dispatch, getState) => {
    const { jitsi, liveClass, user } = getState();
    const { jitsiConference } = jitsi;

    if (jitsiConference) {
      dispatch({
        type: SET_SELECTED_PARTICIPANT_IDS,
        payload: participantIds,
      });

      let maxHeightToSet;

      if (CURRENT_USER === TEACHER) {
        maxHeightToSet = 480;
      } else if (CURRENT_DEVICE === MOBILE_APP) {
        maxHeightToSet = 480;
      } else {
        if (qualityToSet) {
          maxHeightToSet = STUDENT_VIDEO_QUALITY_VALUES[qualityToSet] || 720;
        } else {
          const { student_preferences } = user;
          const { quality } = student_preferences;
          maxHeightToSet = STUDENT_VIDEO_QUALITY_VALUES[quality] || 720;
        }
      }

      // new pagination api by lib-jitsi-meet
      jitsiConference.setReceiverConstraints({
        lastN: participantIds.length,
        selectedEndpoints: participantIds,
        defaultConstraints: { maxHeight: maxHeightToSet },
      });

      // commenting our old way to achieve pagination using lib-jitsi-meet
      // jitsiConference.selectParticipants(participantIds);
      // dispatch(setLastN(participantIds.length));
    }
  };

export const setMyVideoType = (videoType) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoType", videoType)
  );
};

export const setMyWhiteboardType = (whiteboardType) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "whiteboardType",
      whiteboardType
    )
  );
};

export const setMyVideoPosition = (x, y) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { myParticipantId } = jitsi;
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoPosition", `${x},${y}`)
  );
};

export const setMyVideoResizedDimension =
  (detailsObj) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { myParticipantId } = jitsi;
    dispatch(
      setJitsiParticipantProperty(
        myParticipantId,
        "videoDimension",
        JSON.stringify(detailsObj)
      )
    );
  };

export const resetMyVideoPosition = () => (dispatch) => {
  dispatch(setMyVideoPosition(0, 0));
};

export const setMyVideoState = (videoState) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsi, smartboard } = getState();
  const { myParticipantId } = jitsi;
  const { videoState: videoStateInStore } = smartboard;

  if (!videoState) {
    videoState = videoStateInStore;
  }

  /*
    videoState values
    - SMALL
    - BIG
    - OFF
  */

  dispatch(
    setJitsiParticipantProperty(myParticipantId, "videoState", videoState)
  );
};

export const setMyBroadcastType = (broadcastType) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  /*
    broadcastType values
    - SLIDESHARE
    - WHITEBOARD
    - SCREENSHARE
    - VIDEO_OR_DP
  */
  dispatch(
    setJitsiParticipantProperty(myParticipantId, "broadcastType", broadcastType)
  );

  // If someone else's screen is casted, unshare them when I've started broadcasting
  if (
    [OPTIONS.SLIDESHARE, OPTIONS.WHITEBOARD, OPTIONS.SCREENSHARE].includes(
      broadcastType
    )
  ) {
    dispatch(unshareParticipantVideo());
  }
};

export const setMyMediaShareType = (mediaShareType) => (dispatch, getState) => {
  if (CURRENT_USER !== TEACHER) {
    return;
  }

  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  /*
    mediaShareType values
    - SLIDES
    - YOUTUBE
  */

  dispatch(
    setJitsiParticipantProperty(
      myParticipantId,
      "mediaShareType",
      mediaShareType
    )
  );
};

const calculateParticipantDetails =
  (participantId, participants, handRaises) => (dispatch, getState) => {
    const participant = participants[participantId];
    const desktopParticipant = dispatch(getDesktopParticipant(participant));

    const [audioTrack] = (participant.tracks || []).filter(
      (t) => t.type === "audio"
    );
    const [videoTrack] = (participant.tracks || []).filter(
      (t) => t.type !== "audio"
    );
    const [desktopVideoTrack] = (desktopParticipant?.tracks || []).filter(
      (t) => t.type !== "audio"
    );

    const videoMuted = videoTrack ? videoTrack.isMuted : true;

    return {
      videoState: videoMuted ? VIDEO_OPTIONS.off : VIDEO_OPTIONS.big,
      broadcastType: OPTIONS.VIDEO_OR_DP,
      mediaShareType: MEDIA_SHARE_OPTIONS.SLIDES,
      lastStartedSpeaking: new Date(0).toISOString(),
      ...participant,
      videoMuted,
      videoTrack: videoTrack || null,
      audioMuted: audioTrack ? audioTrack.isMuted : true,
      audioTrack: audioTrack || null,
      handRaised: handRaises.has(participant.userId),
      isVideoShared: !!(
        participant.videoShared === VIDEO_SHARED &&
        ((videoTrack && !videoTrack.isMuted) ||
          (desktopParticipant &&
            desktopVideoTrack &&
            !desktopVideoTrack.isMuted))
      ),
    };
  };

const findCurrentTeacherParticipant =
  (allTeacherParticipants) => (dispatch) => {
    const allTeacherSortedParticipants = _.sortBy(
      allTeacherParticipants,
      teacherSort
    );

    dispatch({
      type: SET_CURRENT_TEACHER_PARTICIPANT,
      payload: allTeacherSortedParticipants[0],
    });
  };

export const calculateParticipantsList =
  (timedOut) => async (dispatch, getState) => {
    const isInstantLecture = instantLectureUtils()
      .is()
      .currentPageBelongsToInstantClassroom();

    if (isInstantLecture) {
      /**
       * If any property needs to be changed for instant classroom
       * then use the instantJitsiaction for modifying them.
       * As the instantJitsiAction takes care of the scenario of instant lecture.
       *
       * NOTE: If we use @calculateParticipantsList from this file to update property for
       * instant classroom, there would be issues like students not being visible etc.
       */
      const instantJitsiAction = await dispatch(instantJitsi());
      instantJitsiAction.calculateParticipantsList();
      return;
    }

    // added a 500ms timeout to batch the function call.
    // this function gets called lot of times during conference join that might lead to some slowing up of the app and sometimes making it unresponsive.
    if (!global.calculateParticipantsListTimeout) {
      global.calculateParticipantsListTimeout = setTimeout(
        () => {
          dispatch(calculateParticipantsList(true));
          global.calculateParticipantsListTimeout = undefined;
        },
        CURRENT_DEVICE === MOBILE_APP ? 1000 : 500
      );
    }

    if (!timedOut) {
      return;
    }

    const { jitsi, liveClass, user } = getState();
    const { myParticipantId, participantIds, participants } = jitsi;
    const { handRaises } = liveClass;

    const allParticipants = {};
    const desktopParticipants = {};
    const linkConnectParticipants = {};

    participantIds.forEach((pid) => {
      if (
        participants[pid] &&
        participants[pid].userId &&
        participants[pid].participantType === PARTICIPANT_TYPES.DESKTOP &&
        participants[pid]?.tracks?.length > 0
      ) {
        desktopParticipants[participants[pid].userId] = pid;
      }
      if (
        participants[pid] &&
        participants[pid].userId &&
        participants[pid].participantType === PARTICIPANT_TYPES.LINK_CONNECT &&
        participants[pid]?.tracks?.length > 0
      ) {
        linkConnectParticipants[participants[pid].userId] = pid;
      }
      if (
        participants[pid] &&
        participants[pid].userId &&
        pid !== myParticipantId &&
        participants[pid].userId !== user.id &&
        participants[pid].participantType === PARTICIPANT_TYPES.USER &&
        participants[pid]?.tracks?.length > 0
      ) {
        allParticipants[participants[pid].userId] = pid;
      }
    });

    const tempParticipantIds = Object.values(allParticipants);

    const tempParticipants = tempParticipantIds.map((pid) =>
      dispatch(calculateParticipantDetails(pid, participants, handRaises))
    );

    const teacherParticipants = tempParticipants.filter(
      (participant) => participant.userType === TEACHER
    );

    const studentParticipants = tempParticipants.filter(
      (participant) => participant.userType === STUDENT
    );

    const teacherSortedParticipants = _.sortBy(
      teacherParticipants,
      teacherSort
    );

    const studentSortedParticipants = _.sortBy(
      studentParticipants,
      studentSort
    );

    dispatch({
      type: SET_TEACHER_PARTICIPANTS,
      payload: teacherSortedParticipants,
    });

    dispatch({
      type: SET_STUDENT_PARTICIPANTS,
      payload: studentSortedParticipants,
    });

    dispatch({
      type: SET_ALL_PARTICIPANTS,
      payload: tempParticipants,
    });

    dispatch({
      type: SET_DESKTOP_PARTICIPANTS,
      payload: Object.values(desktopParticipants).map((pid) =>
        dispatch(calculateParticipantDetails(pid, participants, handRaises))
      ),
    });

    const linkConnectParticipantsList = Object.values(
      linkConnectParticipants
    ).map((pid) =>
      dispatch(calculateParticipantDetails(pid, participants, handRaises))
    );

    dispatch({
      type: SET_LINK_CONNECT_PARTICIPANTS,
      payload: linkConnectParticipantsList,
    });

    let myParticipantDetails;

    if (
      myParticipantId &&
      participants[myParticipantId] &&
      participants[myParticipantId].userType
    ) {
      myParticipantDetails = dispatch(
        calculateParticipantDetails(myParticipantId, participants, handRaises)
      );
    }

    let allTeacherParticipants;
    if (CURRENT_USER === TEACHER && myParticipantDetails) {
      allTeacherParticipants = [
        ...teacherSortedParticipants,
        myParticipantDetails,
      ];
    } else {
      allTeacherParticipants = [...teacherSortedParticipants];
    }

    dispatch(findCurrentTeacherParticipant(allTeacherParticipants));

    let sharedParticipantId = null;
    let sharedParticipantVideoTrack = null;

    const allTempParticipants = [...tempParticipants];
    if (myParticipantDetails) {
      allTempParticipants.push(myParticipantDetails);
    }

    const [sharedParticipant] = [
      ...allTempParticipants,
      ...linkConnectParticipantsList,
    ].filter((participant) => participant.isVideoShared);

    if (sharedParticipant) {
      sharedParticipantId = sharedParticipant.participantId;

      if (sharedParticipantId && !sharedParticipant.videoTrack) {
        const allDesktopParticipants = Object.values(desktopParticipants).map(
          (pid) =>
            dispatch(calculateParticipantDetails(pid, participants, handRaises))
        );
        const sharedDesktopParticipant = allDesktopParticipants.find(
          (participant) => participant.userId === sharedParticipant.userId
        );
        sharedParticipantVideoTrack = sharedDesktopParticipant.videoTrack;
      } else {
        sharedParticipantVideoTrack = sharedParticipant.videoTrack;
      }
    }

    if (
      jitsi.sharedParticipantId !== sharedParticipantId ||
      jitsi.sharedParticipantVideoTrack !== sharedParticipantVideoTrack
    ) {
      dispatch({
        type: SET_SHARED_PARTICIPANT_DETAILS,
        payload: {
          sharedParticipantId,
          sharedParticipantVideoTrack,
        },
      });
    }
  };

const onDominantSpeakerChanged = (participantId) => (dispatch, getState) => {
  dispatch({
    type: SET_DOMINANT_PARTICIPANT,
    payload: participantId,
  });

  const { jitsi } = getState();
  const { myParticipantId } = jitsi;

  if (participantId === myParticipantId) {
    dispatch(
      setJitsiParticipantProperty(
        myParticipantId,
        "lastStartedSpeaking",
        new Date().toISOString()
      )
    );
  }
};

export const unshareSelfVideo = () => (dispatch, getState) => {
  const { jitsi } = getState();
  const { jitsiConference, participantIds, participants, myParticipantId } =
    jitsi;

  if (!jitsiConference) {
    return;
  }

  if (
    participants[myParticipantId] &&
    participants[myParticipantId].videoShared === VIDEO_SHARED
  ) {
    dispatch(onStopSharingYourVideo(myParticipantId));
  }
};

export const muteAllMics =
  (participantType = STUDENT) =>
  (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, myParticipantId } = jitsi;

    if (!jitsiConference || !myParticipantId) {
      return;
    }

    jitsiConference.sendCommandOnce(customCommands.MUTE_ALL_MICS, {
      attributes: {
        participantType,
        initiatorParticipantId: myParticipantId,
      },
    });
  };

export const muteAllVideos =
  (participantType = STUDENT) =>
  (dispatch, getState) => {
    const { jitsi } = getState();
    const { jitsiConference, myParticipantId } = jitsi;

    if (!jitsiConference || !myParticipantId) {
      return;
    }

    jitsiConference.sendCommandOnce(customCommands.MUTE_ALL_VIDEOS, {
      attributes: {
        participantType,
        initiatorParticipantId: myParticipantId,
      },
    });
  };

const onMuteAllMics =
  (participantType, initiatorParticipantId) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { myParticipantId } = jitsi;

    if (
      participantType === CURRENT_USER &&
      myParticipantId !== initiatorParticipantId
    ) {
      dispatch(muteMyAudio(true));
    }
  };

const onMuteAllVideos =
  (participantType, initiatorParticipantId) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { myParticipantId } = jitsi;

    if (
      participantType === CURRENT_USER &&
      myParticipantId !== initiatorParticipantId
    ) {
      dispatch(muteAnyOfMyVideo());
    }
  };

export const getDesktopParticipant = (participant) => (dispatch, getState) => {
  const { jitsi } = getState();
  const { desktopParticipants } = jitsi;
  if (!participant) return null;

  return desktopParticipants.find(
    (dParticipant) => dParticipant.userId === participant.userId
  );
};

export const getLinkConnectParticipant =
  (participant) => (dispatch, getState) => {
    const { jitsi } = getState();
    const { linkConnectParticipants } = jitsi;
    if (!participant) return null;

    return linkConnectParticipants.find(
      (dParticipant) => dParticipant.userId === participant.userId
    );
  };

export const calculatePaginatedParticipants =
  (currentPageNumber) => (dispatch, getState) => {
    const { jitsi, liveClass } = getState();
    const {
      teacherParticipants,
      studentParticipants,
      desktopParticipants,
      allParticipants,
      participants,
      sharedParticipantId,
      sharedParticipantVideoTrack,
      currentTeacherParticipant,
      selectedParticipantIds,
      myParticipantId,
      dominantParticipantId,
      linkConnectParticipants,
    } = jitsi;

    const { displayParticipantId } = liveClass;

    let pageNumber = currentPageNumber;
    let showUpButton = false;
    let showDownButton = false;

    const allSortedParticipants = [
      ...teacherParticipants,
      ...studentParticipants,
      ...desktopParticipants,
    ];

    const videoParticipants = allSortedParticipants.filter(
      (participant) => !participant.videoMuted
    );

    const totalPages = Math.ceil(
      videoParticipants.length / PAGINATION_CONTENT_SIZE
    );

    if (pageNumber > 0 && pageNumber > totalPages - 1) {
      pageNumber = totalPages - 1;
    }

    if (pageNumber < 0) {
      pageNumber = 0;
    }

    if (pageNumber > 0) {
      showUpButton = true;
    }

    if (pageNumber < totalPages - 1) {
      showDownButton = true;
    }

    let startIndex;
    let endIndex;

    if (totalPages === 0 || totalPages === 1) {
      startIndex = 0;
      endIndex = Infinity;
    } else {
      startIndex = PAGINATION_CONTENT_SIZE * pageNumber;
      endIndex = startIndex + PAGINATION_CONTENT_SIZE;
    }

    const paginatedParticipantIds = [];
    const toSelectParticipantIds = new Set();

    const tempVideoPartipantIds = [];
    allSortedParticipants.forEach((participant) => {
      if (!participant.videoMuted) {
        tempVideoPartipantIds.push(participant.participantId);
      }

      if (
        (!tempVideoPartipantIds.length && !startIndex) ||
        (tempVideoPartipantIds.length > startIndex &&
          tempVideoPartipantIds.length <= endIndex)
      ) {
        if (!participant.videoMuted) {
          toSelectParticipantIds.add(participant.participantId);
        }

        paginatedParticipantIds.push(participant.participantId);
      }
    });

    const currentTeacherDesktopParticipant = currentTeacherParticipant
      ? dispatch(getDesktopParticipant(currentTeacherParticipant))
      : null;

    const { toSelectParticipantId, requestVideoTrack } = findVideoTrack(
      sharedParticipantVideoTrack,
      sharedParticipantId,
      currentTeacherParticipant,
      currentTeacherDesktopParticipant
    );

    if (
      toSelectParticipantId &&
      toSelectParticipantId !== myParticipantId &&
      requestVideoTrack &&
      !toSelectParticipantIds.has(toSelectParticipantId)
    ) {
      toSelectParticipantIds.add(toSelectParticipantId);
    }

    if (
      displayParticipantId &&
      displayParticipantId !== myParticipantId &&
      !toSelectParticipantIds.has(displayParticipantId)
    ) {
      toSelectParticipantIds.add(displayParticipantId);
    }

    const dominantSpeaker = dispatch(getDominantSpeaker());

    if (
      dominantParticipantId &&
      dominantParticipantId !== myParticipantId &&
      !toSelectParticipantIds.has(dominantParticipantId) &&
      !dominantSpeaker?.videoMuted
    ) {
      toSelectParticipantIds.add(dominantParticipantId);
    }

    [...toSelectParticipantIds].forEach((participantId) => {
      const desktopParticipant = dispatch(
        getDesktopParticipant(participants[participantId])
      );
      if (desktopParticipant) {
        // if desktopParticipant is there for a user, we only show that video and not user's web cam video.
        // so no need of requesting that video. just get desktop video

        // Q: why add participant id and then remove it here?
        // A: becuase we want to find the participants list first. then we can pick
        //    desktop participant id of those participants (if desktop participant is there for them)
        toSelectParticipantIds.delete(participantId);
        toSelectParticipantIds.add(desktopParticipant.participantId);
      }
    });

    linkConnectParticipants.forEach((participant) => {
      toSelectParticipantIds.add(participant.participantId);
    });

    if (
      !_.isEqual(
        _.sortBy(selectedParticipantIds),
        _.sortBy([...toSelectParticipantIds])
      )
    ) {
      dispatch(selectParticipants([...toSelectParticipantIds]));
    }

    return {
      pageNumber,
      totalPages,
      paginatedParticipantIds,
      showUpButton,
      showDownButton,
    };
  };

export const setVolumeLevel = (volumeLevel) => (dispatch) => {
  dispatch({
    type: SET_VOLUME_LEVEL,
    payload: volumeLevel,
  });
};

export const setStudentQualityOption =
  (quality) => async (dispatch, getState) => {
    const { jitsi } = getState();
    const { selectedParticipantIds } = jitsi;

    dispatch(selectParticipants(selectedParticipantIds, quality));

    dispatch(updateStudentPreferences({ quality }));
  };

// This is only used for request video and request audio popups
export const getUserMediaPreview =
  (type = VIDEO, mute = false) =>
  async (dispatch) => {
    const mediaTracksOptions = {
      devices: [type],
    };

    let mediaTracks;

    try {
      mediaTracks = await JitsiMeetJS.createLocalTracks(mediaTracksOptions);
    } catch (e) {
      console.log(e);
    }

    mediaTracks = mediaTracks || [];

    const mediaTrack = mediaTracks[0];

    if (!mediaTrack) {
      return;
    }

    if (mute) {
      try {
        await mediaTrack.mute();
      } catch (e) {
        console.log(e);
      }
    }

    if (type === AUDIO) {
      dispatch({
        type: SET_MY_AUDIO_PREVIEW_TRACK,
        payload: mediaTrack,
      });
    } else {
      dispatch({
        type: SET_MY_VIDEO_PREVIEW_TRACK,
        payload: mediaTrack,
      });
    }

    dispatch(setMyJitsiPreviewTrackMuteChanged());
  };

const setMyJitsiPreviewTrackMuteChanged = () => (dispatch) => {
  dispatch({
    type: SET_MY_JITSI_PREVIEW_TRACK_MUTE_CHANGED,
    payload: null,
  });
};

export const muteMyVideoPreview = (mute) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { myVideoPreviewTrack } = jitsi;

  if (myVideoPreviewTrack) {
    try {
      mute
        ? await myVideoPreviewTrack.mute()
        : await myVideoPreviewTrack.unmute();
    } catch (e) {
      console.log(e);
    }
  }

  dispatch(setMyJitsiPreviewTrackMuteChanged());
};

export const muteMyAudioPreview = (mute) => async (dispatch, getState) => {
  const { jitsi } = getState();
  const { myAudioPreviewTrack } = jitsi;

  if (myAudioPreviewTrack) {
    try {
      mute
        ? await myAudioPreviewTrack.mute()
        : await myAudioPreviewTrack.unmute();
    } catch (e) {
      console.log(e);
    }
  }

  dispatch(setMyJitsiPreviewTrackMuteChanged());
};

export const getDominantSpeaker = () => (dispatch, getState) => {
  const {
    liveClass,
    jitsi: { dominantParticipantId, participants },
  } = getState();
  const { handRaises } = liveClass;

  if (!dominantParticipantId) return null;
  if (!participants[dominantParticipantId]) return null;
  return dispatch(
    calculateParticipantDetails(dominantParticipantId, participants, handRaises)
  );
};

export const getMyParticipant = () => (dispatch, getState) => {
  const {
    liveClass,
    jitsi: { myParticipantId, participants },
  } = getState();
  const { handRaises } = liveClass;

  if (!myParticipantId) return null;

  if (!participants[myParticipantId]) return null;
  return dispatch(
    calculateParticipantDetails(myParticipantId, participants, handRaises)
  );
};
